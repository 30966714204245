import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMenuButtons } from "../../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import { WedIconPlusAdd } from "../../../../../components/wed-icon/generated/WedIconSvg";
import { withProjectActions, InjectedProjectActionsProps } from "../../../../../hoc/withProjectActions";
import ProjectListActionButton from "../ProjectListActionButton/ProjectListActionButton";
import { ISelectItem } from "../../../../../components/status-action-button/StatusActionButton";

interface PropsInterface extends WrappedComponentProps, InjectedProjectActionsProps {
    selectedProjects: ISelectItem[];
    createProjectClick: () => void;
    onStatusChange: () => void;
    setIsLoading: () => void;
}

interface StateInterface {}

class ProjectListButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { createProjectClick, selectedProjects, setIsLoading, onStatusChange } = this.props;

        return (
            <WedMenuButtons>
                <Button onClick={createProjectClick} type="submit" startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}>
                    <FormattedMessage id="app.projectListButtons.createProject" />
                </Button>
                <ProjectListActionButton selectedProjects={selectedProjects} setIsLoading={setIsLoading} actionCallback={onStatusChange} />
            </WedMenuButtons>
        );
    }
}

export default withProjectActions(injectIntl(ProjectListButtons));
