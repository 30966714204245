import React, { Component } from "react";
import classNames from "classnames";
import "./grid-tiles.scss";

interface PropsInterface {
    children?: React.ReactNode | React.ReactNodeArray;
    className?: string;
    title?: string;
}
interface StateInterface {}

export default class GridTiles extends Component<PropsInterface, StateInterface> {
    render() {
        const { title } = this.props;
        return (
            <div className={classNames("container-grid", ...(this.props.className ? [this.props.className] : []))}>
                {this.props.title ? <h3 className="container-grid__header">{title}</h3> : ""}
                <div className="container-grid__tiles">{this.props.children}</div>
            </div>
        );
    }
}
