import React, { Component } from "react";
import container from "../../../../container";
import { ICurrentPeriod } from "../../../../services/WedApi/Models/User";
import { LinearProgress } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import AdminPanelContainerTextTable from "../../components/AdminPanelContainerTextTable/AdminPanelContainerTextTable";
import "./AdminPanelBillingCurrentPeriod.scss";

interface PropsInterface {}

interface StateInterface {
    period?: ICurrentPeriod;
}

export default class AdminPanelBillingCurrentPeriod extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            period: undefined,
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            period: await container.adminService.getCurrentPeriod(),
        });
    }

    render() {
        return (
            <div className="admin-panel-billing-current-period">
                {!this.state.period ? (
                    <LinearProgress />
                ) : (
                    <AdminPanelContainerTextTable>
                        <div className="admin-panel-billing-current-period-content">
                            <table>
                                <tr>
                                    <td className="admin-panel-billing-current-period">
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.billingCycle" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.cycle}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.lastPayment" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.lastPayment}</td>
                                    <td>
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.lastValue" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.lastValue}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.nextPayment" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.nextPayment}</td>
                                    <td>
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.nextValue" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.nextValue}</td>
                                </tr>
                                <tr className="admin-panel-billing-current-period--last-row">
                                    <td>
                                        <label>
                                            <FormattedMessage id="app.AdminPanelBillingCurrentPeriod.subscriptionPlan" />
                                        </label>
                                    </td>
                                    <td>{this.state.period.subscription}</td>
                                </tr>
                            </table>
                        </div>
                    </AdminPanelContainerTextTable>
                )}
            </div>
        );
    }
}
