import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import LocaleEnum from "../../enums/Locale/LocaleEnum";
import { changeLocaleAction } from "../../store/Actions";
import en from "../../translations/en.json";
import { ApplicationState } from "../../../../store";

interface PropsInterface extends RouteComponentProps {
    locale: LocaleEnum;
    changeLocaleAction: typeof changeLocaleAction;
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {
    locale: LocaleEnum;
    init: boolean;
    ownUpdate: boolean;
}

class LocaleProvider extends Component<PropsInterface> {
    static defaultProps = {
        locale: LocaleEnum.EN,
    };

    static getDerivedStateFromProps(props: PropsInterface, state: StateInterface) {
        if (state.ownUpdate) {
            return { ...state, ownUpdate: false };
        } else if (props.locale !== state.locale) {
            return { ...state, locale: props.locale };
        }
        return null;
    }

    readonly state: StateInterface = {
        locale: this.props.locale,
        init: false,
        ownUpdate: false,
    };

    componentDidMount() {
        this.setState({
            locale: this.props.locale,
            init: true,
            ownUpdate: true,
        });
    }

    getAvailableLocales() {
        return Object.values(LocaleEnum);
    }

    render() {
        if (!this.state.init) return null;

        const messages: { [key: string]: { [key: string]: string } } = {
            en,
        };

        return (
            <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps, { changeLocaleAction })(withRouter(LocaleProvider));
