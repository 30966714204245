import React, { Component } from "react";
import { ClientDetailLoaderProps } from "../../../components/client/ClientDetailLoader/ClientDetailLoader";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import { IClientContact } from "../../../services/WedApi/Models/ClientContact";
import EditClientAccountPopup from "../../../components/client/EditClientAccountPopup/EditClientAccountPopup";
import ClientMenu from "../../../components/main-menu/ClientMenu/ClientMenu";
import ClientContactsListButtons from "./ClientContactsPageHeader/ClientContactsListButtons/ClientContactsListButtons";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import DeleteClientAccountPopup from "../../../components/client/DeleteClientAccountPopup/DeleteClientAccountPopup";
import container from "../../../container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ApplicationState } from "../../../store";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ActionTile from "../../../components/tile/ActionTile/ActionTile";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import ClientContactTile from "../../../components/tile/ClientContactTile/ClientContactTile";
import ClientContactsActionButton from "./ClientContactsPageHeader/ClientContactsActionButton/ClientContactsActionButton";
import { ISelectItem, ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { SizeType } from "../../../components/wed-icon/WedIcon";
import { WedTable } from "../../../components/wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import classNames from "classnames";
import Checkbox from "@material-ui/core/Checkbox";
import WedDate from "../../../components/formatter/WedDate";
import Initials from "../../../components/tile/components/Initials/Initials";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { IClientExtended } from "../../../services/WedApi/Models/Client";
import "./ClientContactsPage.scss";
import PhoneNumber from "../../../components/tile/components/PhoneNumber/PhoneNumber";

interface BasePropsInterface extends ClientDetailLoaderProps {}

interface PropsInterface extends BasePropsInterface, RouteComponentProps {
    client: IClientExtended;
    isTableMode: boolean;
}

interface StateInterface {
    contacts: IClientContact[];
    isLoading: boolean;
    selectedItems: ISelectItem[];
    popup?: {
        createContact?: { open: boolean };
        editContact?: { clientContact: IClientContact };
        deleteContact?: { clientContact: IClientContact };
    };
}

class ClientContactsPage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            selectedItems: [],
            contacts: [],
            isLoading: true,
        };
        this.closePopup = this.closePopup.bind(this);
        this.createContactClick = this.createContactClick.bind(this);
        this.editContactClick = this.editContactClick.bind(this);
        this.deleteContactClick = this.deleteContactClick.bind(this);
        this.viewProfileClick = this.viewProfileClick.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        const contacts = await container.clientContactService.getList(this.props.client.id, { withCountProjects: true });
        this.setState({
            isLoading: false,
            selectedItems: [],
            contacts: contacts,
        });
    }

    private async closePopup(updated?: boolean) {
        this.setState({ popup: undefined });
        if (updated) {
            this.setState({ isLoading: true });
            await this.fetchData();
        }
    }

    private viewProfileClick(clientContact: IClientContact) {
        this.props.history.push(
            generatePath(RoutesEnum.CLIENTS_CONTACTS_DETAIL, {
                clientId: this.props.client.id,
                contactId: clientContact.id,
            })
        );
    }

    private createContactClick() {
        this.setState({ popup: { createContact: { open: true } } });
    }

    private editContactClick(clientContact: IClientContact) {
        this.setState({ popup: { editContact: { clientContact } } });
    }

    private deleteContactClick(clientContact: IClientContact) {
        this.setState({ popup: { deleteContact: { clientContact } } });
    }

    private handleSelectItem(item: IClientContact) {
        let selectedItems = [...this.state.selectedItems];
        const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id == item.id);

        if (selectedItemIndex == -1) {
            const newItem = {
                id: item.id,
                userIsOwner: false,
                object: item,
            };
            selectedItems = [newItem];
        } else {
            selectedItems.splice(selectedItemIndex, 1);
        }
        this.setState({ selectedItems });
    }

    private isSelected(id: number): boolean {
        const { selectedItems } = this.state;

        return !!selectedItems.find((item) => item.id === id);
    }

    render() {
        return (
            <div className="client-contacts-page">
                {this.state.popup?.createContact?.open && (
                    <EditClientAccountPopup client={this.props.client} clientContact={undefined} onClickClose={this.closePopup} />
                )}
                {this.state.popup?.editContact?.clientContact && (
                    <EditClientAccountPopup
                        client={this.props.client}
                        clientContact={this.state.popup.editContact.clientContact}
                        onClickClose={this.closePopup}
                    />
                )}
                {this.state.popup?.deleteContact?.clientContact && (
                    <DeleteClientAccountPopup clientContact={this.state.popup.deleteContact.clientContact} onClickClose={this.closePopup} />
                )}
                <WedMainMenuContainer
                    menu={<ClientMenu clientId={this.props.client.id} activeTab="contacts" />}
                    buttons={
                        <ClientContactsListButtons
                            client={this.props.client}
                            selectedItems={this.state.selectedItems}
                            createContactClick={this.createContactClick}
                            editContactClick={this.editContactClick}
                            deleteContactClick={this.deleteContactClick}
                        />
                    }
                    sidebar={<MenuControlIcons noDisplayTrash={true} disableSidebarOpen={true} />}
                />
                {this.state.isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className="client-contacts-page--container">
                        {this.props.isTableMode ? (
                            <WedTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell colSpan={2}>
                                            <FormattedMessage id="app.ClientContactsPage.table.name" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.ClientContactsPage.table.position" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.ClientContactsPage.table.email" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.ClientContactsPage.table.phone" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.ClientContactsPage.table.added" />
                                        </TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.contacts.map((clientContact, i) => (
                                        <TableRow
                                            className={classNames({
                                                "table-row--active": this.isSelected(clientContact.id),
                                            })}
                                            hover
                                            role="checkbox"
                                            key={i}
                                            onClick={() => {
                                                this.handleSelectItem(clientContact);
                                            }}
                                            onDoubleClick={() => {
                                                this.viewProfileClick(clientContact);
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={() => {
                                                        this.handleSelectItem(clientContact);
                                                    }}
                                                    checked={this.isSelected(clientContact.id)}
                                                />
                                            </TableCell>
                                            <TableCell className="client-contacts-page--wed-table-name-cell-initials">
                                                <Initials title={clientContact.initials} />
                                            </TableCell>
                                            <TableCell>{clientContact.name}</TableCell>
                                            <TableCell>{clientContact.position}</TableCell>
                                            <TableCell>{clientContact.email}</TableCell>
                                            <TableCell>
                                                {!!clientContact.phone && <PhoneNumber phone={clientContact.phone} cCode={clientContact.cCode} />}
                                            </TableCell>
                                            <TableCell>
                                                {clientContact.created && <WedDate date={clientContact.created} />}
                                                {clientContact.createdBy && (
                                                    <>
                                                        <span className="client-contacts-page--wed-table-name-cell-create-by">
                                                            <FormattedMessage id="app.global.by" />
                                                        </span>
                                                        {`${clientContact.createdBy.firstName} ${clientContact.createdBy.lastName}`}
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                <ClientContactsActionButton
                                                    selectedItems={[
                                                        {
                                                            id: clientContact.id,
                                                            type: ISelectItemType.clientContact,
                                                            userIsOwner: this.props.client.userIsOwner,
                                                            object: clientContact,
                                                        },
                                                    ]}
                                                    icon={<MoreVertIcon />}
                                                    viewProfileClick={() => {
                                                        this.viewProfileClick(clientContact);
                                                    }}
                                                    editContactClick={() => {
                                                        this.editContactClick(clientContact);
                                                    }}
                                                    deleteContactClick={() => {
                                                        this.deleteContactClick(clientContact);
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </WedTable>
                        ) : (
                            <GridTiles>
                                {this.state.contacts.map((clientContact, i) => (
                                    <ClientContactTile
                                        key={i}
                                        clientId={this.props.client.id}
                                        clientContact={clientContact}
                                        selected={this.isSelected(clientContact.id)}
                                        onClick={() => {
                                            this.handleSelectItem(clientContact);
                                        }}
                                        onDoubleClick={() => {
                                            this.viewProfileClick(clientContact);
                                        }}
                                        actionButton={
                                            <ClientContactsActionButton
                                                selectedItems={[
                                                    {
                                                        id: clientContact.id,
                                                        type: ISelectItemType.clientContact,
                                                        userIsOwner: this.props.client.userIsOwner,
                                                        object: clientContact,
                                                    },
                                                ]}
                                                icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                                viewProfileClick={() => {
                                                    this.viewProfileClick(clientContact);
                                                }}
                                                editContactClick={() => {
                                                    this.editContactClick(clientContact);
                                                }}
                                                deleteContactClick={() => {
                                                    this.deleteContactClick(clientContact);
                                                }}
                                            />
                                        }
                                    />
                                ))}
                                {this.props.client.userIsOwner && <FormattedMessage id="app.tile.createClientContact">
                                    {(message) => (
                                        <ActionTile
                                            className="client-contacts-tile__create-client-contact"
                                            onClick={this.createContactClick}
                                            title={message as string}
                                        />
                                    )}
                                </FormattedMessage>
                                }
                            </GridTiles>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
});

export default connect(mapStateToProps)(withRouter(ClientContactsPage));
