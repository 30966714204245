import React from "react";
import "../count-icon.scss";
import { WedIconUserProfile8 } from "../../wed-icon/generated/WedIconSvg";
import { WedIcon } from "../../wed-icon/WedIcon";

interface PropsInterface {
    count: number;
}

interface StateInterface {}

export default class ExternalTeamCountIcon extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { count } = this.props;

        return (
            <div className="count-icon team-count-icon">
                <WedIcon icon={<WedIconUserProfile8 />} />
                <span className="count-icon__content">{count}</span>
            </div>
        );
    }
}
