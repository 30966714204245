import { Action } from "redux";
import LocaleEnum from "../enums/Locale/LocaleEnum";

export enum LocaleActionTypesEnum {
    CHANGE_LOCALE = "CHANGE_LOCALE",
}

export interface LocaleStateInterface {
    locale: LocaleEnum;
}

interface ChangeLocaleActionInterface extends Action<LocaleActionTypesEnum.CHANGE_LOCALE> {
    locale: LocaleEnum;
}

export type LocaleActionTypes = ChangeLocaleActionInterface;
