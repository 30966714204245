import * as React from "react";
import { generatePath } from "react-router-dom";
import classNames from "classnames";
import { WedMainMenu } from "../WedMainMenu/WedMainMenu";
import { WedMainMenuItem } from "../WedMainMenuItem/WedMainMenuItem";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "./ClientMenu.scss";

interface PropsInterface {
    clientId: number;
    activeTab: "summary" | "project-active" | "project-closed" | "contacts";
}

interface StateInterface {}

class ClientMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMainMenu>
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "summary" })}
                    path={generatePath(RoutesEnum.CLIENTS_SUMMARY, { clientId: this.props.clientId })}
                    label="app.ClientMenu.summary"
                />
                <WedMainMenuItem
                    className={classNames("client-menu__projects", { "main-menu__active": this.props.activeTab === "project-active" })}
                    path={generatePath(RoutesEnum.CLIENTS_PROJECTS_ACTIVE, { clientId: this.props.clientId })}
                    label="app.ClientMenu.projectActive"
                />
                <WedMainMenuItem
                    className={classNames("client-menu__projects", { "main-menu__active": this.props.activeTab === "project-closed" })}
                    path={generatePath(RoutesEnum.CLIENTS_PROJECTS_CLOSED, { clientId: this.props.clientId })}
                    label="app.ClientMenu.projectClosed"
                />
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "contacts" })}
                    path={generatePath(RoutesEnum.CLIENTS_CONTACTS, { clientId: this.props.clientId })}
                    label="app.ClientMenu.contacts"
                />
            </WedMainMenu>
        );
    }
}

export default ClientMenu;
