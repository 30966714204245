import React, { Component } from "react";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import { ICollection } from "../../../services/WedApi/Models/Collection";
import FilesPage from "../../../components/template/FilesPage/FilesPage";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";

interface PropsInterface extends ProjectDetailLoaderProps {
    collectionId?: number;
    collection?: ICollection;
    fileType: FileTypeEnum;
}

interface StateInterface {}

class ProjectFilesPage extends Component<PropsInterface, StateInterface> {
    render() {
        const { collectionId, collection, project, fileType } = this.props;

        return <FilesPage collectionId={collectionId} collection={collection} project={project} fileType={fileType} />;
    }
}

export default ProjectFilesPage;
