import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import container from "../../../container";
import { IClient } from "../../../services/WedApi/Models/Client";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    client: IClient;
    onClickClose: (updated?: boolean) => void;
}

interface StateInterface {}

class DeleteClientPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {};
        this.doIt = this.doIt.bind(this);
    }

    private async doIt() {
        try {
            await container.clientService.deleteClient(this.props.client.id);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.DeleteClientPopup.success.deleted" }), { variant: "success" });
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            return false;
        }
    }

    render() {
        return (
            <PopupConfirmButton
                maxWidth="sm"
                open={true}
                close={this.props.onClickClose}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.standard.popup.button.delete"} />}
                noDisplayCancel={true}
                header={<FormattedMessage id={"app.DeleteClientPopup.header.title"} />}
                content={<div dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "app.DeleteClientPopup.content" }) }} />}
            />
        );
    }
}

export default withSnackbar(injectIntl(DeleteClientPopup));
