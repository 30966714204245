import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiCollection, IApiCollectionDetails, IApiCollectionListItem } from "./Models/Collection";
import { IListResult } from "./Models/Common";

export default class CollectionActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public getDownloadUrl(collectionId: number): string {
        return this.getUrl(`/xapi/col/${collectionId}/download`);
    }

    public async getCollections(projectId: number): Promise<IListResult<IApiCollectionListItem>> {
        const response = await this.get(`/xapi/prs/${projectId}/cols`);
        return response.data;
    }

    public async getMyLibraryCollections(): Promise<IListResult<IApiCollectionListItem>> {
        const response = await this.get(`/xapi/library/cols`);
        return response.data;
    }

    public async getCollectionDetail(collectionId: number): Promise<IApiCollectionDetails> {
        const response = await this.get(`/xapi/col/${collectionId}`);
        return response.data;
    }

    public async createCollection(data: Pick<IApiCollection, "name"> & { pid?: number; desc?: string; objectIDs?: number[] }): Promise<number> {
        const response = await this.postWithCSRFJson("/xapi/col/create", data);
        return response.data["collection"]["id"];
    }

    public async deleteCollection(collectionId: number): Promise<void> {
        const data = { ids: [collectionId] };
        await this.postWithCSRFJson("/xapi/col/delete", data);
    }

    public async lockCollection(collectionId: number, locked: boolean): Promise<void> {
        const data = { id: collectionId, locked: locked };
        await this.postWithCSRFJson("/xapi/col/lock", data);
    }

    public async shareCollection(collectionId: number, baUsers: number[], sharedTo: number[]): Promise<void> {
        const data = { id: collectionId, baUsers, sharedTo };
        await this.postWithCSRFJson("/xapi/col/share", data);
    }

    public async renameCollection(collectionId: number, name: string): Promise<void> {
        const data = { id: collectionId, name };
        await this.postWithCSRFJson("/xapi/col/rename", data);
    }

    public async changeDescriptionCollection(collectionId: number, description: string): Promise<void> {
        const data = { id: collectionId, desc: description };
        await this.postWithCSRFJson("/xapi/col/desc", data);
    }

    public async updateCollectionFiles(collectionId: number, addFilesIds: number[], removeFilesIds: number[] = []): Promise<void> {
        await this.postWithCSRFJson("/xapi/col/object/update", {
            id: collectionId,
            objectsAdd: addFilesIds,
            objectsRemove: removeFilesIds,
        });
    }

    public async createApprovalRequest(cid: number, oid: number, ecContactIDs: number[]) {
        await this.postWithCSRFJson("/xapi/obj/requestapproval", { cid, oid, ecContactIDs });
    }

    public async removeApprovalRequest(cid: number, oid: number, ecContactIDs: number[]) {
        await this.postWithCSRFJson("/xapi/obj/cancelrequest", { cid, oid, ecContactIDs });
    }
}
