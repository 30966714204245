export function downloadFile(url: string) {
    const link = document.createElement("a");
    link.href = url + (url.indexOf("?") === -1 ? "?" : "&") + "disposition=attachment";
    link.style.position = "absolute";
    link.style.top = "0";
    link.style.left = "0";
    link.style.width = "0";
    link.style.height = "0";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        document.body.removeChild(link);
    }, 10);
}
