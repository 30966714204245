import React, { MouseEvent, ReactElement } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import { WedIconEye } from "../../../../../components/wed-icon/generated/WedIconSvg";
import { ISelectItem, StatusActionButton } from "../../../../../components/status-action-button/StatusActionButton";
import { ProviderContext, withSnackbar } from "notistack";
import { generatePath, withRouter, RouteComponentProps } from "react-router-dom";
import RoutesEnum from "../../../../../services/Routes/RoutesEnum";

interface PropsInterface extends WrappedComponentProps, ProviderContext, RouteComponentProps {
    selectedItems: ISelectItem[];
    icon?: ReactElement;
}

interface StateInterface {}

class ClientListActionButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {};
        this.openClient = this.openClient.bind(this);
    }

    private openClient(clientId: number): void {
        this.props.history.push(generatePath(RoutesEnum.CLIENTS_DETAIL, { clientId }));
    }

    render() {
        const { selectedItems, icon } = this.props;
        return (
            <>
                <StatusActionButton
                    selectedItems={selectedItems}
                    menuItems={[
                        {
                            multiply: false,
                            items: [
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.client.open" }),
                                    icon: <WedIcon icon={<WedIconEye />} size={SizeType.SMALL} />,
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.openClient(selectedItems[0].id);
                                    },
                                },
                            ],
                        },
                    ]}
                    icon={icon}
                />
            </>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(ClientListActionButton)));
