import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import { ProjectStatusChangeEnum } from "../../../../services/WedApi/Models/Project";
import { LogicError } from "../../../../errors/error-app";

interface StateInterface {
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: () => void;
    callback?: () => void;
    status: ProjectStatusChangeEnum | null;
}

class ChangeStatusProject extends React.Component<PropsInterface, StateInterface> {
    readonly state = {
        loading: false,
    };

    deleteCollection = async () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });
        if (this.props.callback) {
            this.props.callback();
        }
        this.props.close();
    };

    render() {
        if (this.props.status === null) {
            return null;
        }

        return (
            <div className="change-status">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id={this.getTitleByStatus()} />
                </PopupHeader>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id={this.getContentByStatus()} values={{ br: <br /> }} />
                        <br />
                    </DialogContentText>
                </DialogContent>
                <PopupAction>
                    <Button type="submit" onClick={this.deleteCollection} className="button--more-padding" disabled={this.state.loading}>
                        <FormattedMessage id={this.getButtonActionByStatus()} />
                    </Button>
                    <Button type="submit" onClick={this.props.close} className="button--more-padding">
                        <FormattedMessage id="app.standard.popup.button.cancel" />
                    </Button>
                </PopupAction>
            </div>
        );
    }

    private getTitleByStatus() {
        if (this.props.status === ProjectStatusChangeEnum.close) {
            return "app.changeStatus.close.header.title";
        }
        if (this.props.status === ProjectStatusChangeEnum.delete) {
            return "app.changeStatus.delete.header.title";
        }
        if (this.props.status === ProjectStatusChangeEnum.restore) {
            return "app.changeStatus.restore.header.title";
        }
        if (this.props.status === ProjectStatusChangeEnum.archive) {
            return "app.changeStatus.archive.header.title";
        }
        if (this.props.status === ProjectStatusChangeEnum.reactivate) {
            return "app.changeStatus.reactivate.header.title";
        }

        throw new LogicError(`Not found translate for title by status: ${this.props.status}`);
    }

    private getContentByStatus() {
        if (this.props.status === ProjectStatusChangeEnum.close) {
            return "app.changeStatus.close.content";
        }
        if (this.props.status === ProjectStatusChangeEnum.delete) {
            return "app.changeStatus.delete.content";
        }
        if (this.props.status === ProjectStatusChangeEnum.restore) {
            return "app.changeStatus.restore.content";
        }
        if (this.props.status === ProjectStatusChangeEnum.archive) {
            return "app.changeStatus.archive.content";
        }
        if (this.props.status === ProjectStatusChangeEnum.reactivate) {
            return "app.changeStatus.reactivate.content";
        }

        throw new LogicError(`Not found translate for content by status: ${this.props.status}`);
    }

    private getButtonActionByStatus() {
        if (this.props.status === ProjectStatusChangeEnum.close) {
            return "app.changeStatus.close.button.action";
        }
        if (this.props.status === ProjectStatusChangeEnum.delete) {
            return "app.changeStatus.delete.button.action";
        }
        if (this.props.status === ProjectStatusChangeEnum.restore) {
            return "app.changeStatus.restore.button.action";
        }
        if (this.props.status === ProjectStatusChangeEnum.archive) {
            return "app.changeStatus.archive.button.action";
        }
        if (this.props.status === ProjectStatusChangeEnum.reactivate) {
            return "app.changeStatus.reactivate.button.action";
        }

        throw new LogicError(`Not found translate for button action by status: ${this.props.status}`);
    }
}

export default withSnackbar(injectIntl(ChangeStatusProject));
