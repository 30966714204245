import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import { Button } from "@material-ui/core";
import { IFile } from "../../../../services/WedApi/Models/File";
import LinearProgress from "@material-ui/core/LinearProgress";
import classNames from "classnames";
import container from "../../../../container";
import { IClientContact } from "../../../../services/WedApi/Models/ClientContact";
import "./ApprovalRequest.scss";

interface StateInterface {
    loadSharing: boolean;
    loading: boolean;
    sharedItems: IClientContact[];
    selectedIds: number[];
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    file: IFile;
    clientId: number;
    collectionId: number;
    close: () => void;
    onSuccess: () => void;
}

class ApprovalRequest extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            loadSharing: true,
            sharedItems: [],
            selectedIds: [],
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        const [collectionData, contacts] = await Promise.all([
            container.collectionService.getNativeCollectionDetails(this.props.collectionId),
            container.clientContactService.getList(this.props.clientId),
        ]);

        this.setState({
            sharedItems: contacts.filter((contact) => (collectionData.collection.sharedTo || []).includes(contact.id)),
            selectedIds: await container.collectionService.getFileApprovalRequest(collectionData, this.props.file.id),
            loadSharing: false,
        });
    }

    private async handleSubmit() {
        this.setState({ loading: true });
        try {
            await container.collectionService.setFileApprovalRequest(this.props.collectionId, this.props.file.id, this.state.selectedIds);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ loading: false });
        }
        this.props.onSuccess();
    }

    private changeSelected(id: number) {
        const isShared = this.state.selectedIds.includes(id);
        this.setState({
            selectedIds: isShared ? this.state.selectedIds.filter((sharedId) => sharedId !== id) : [...this.state.selectedIds, id],
        });
    }

    private isSelected(id: number): boolean {
        return this.state.selectedIds.includes(id);
    }

    render() {
        return (
            <div className="approval-request">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.ApprovalRequest.header.title" values={{ fileName: this.props.file.name }} />
                </PopupHeader>
                {this.state.loadSharing ? (
                    <LinearProgress />
                ) : (
                    <div className="approval-request--content">
                        <div className="approval-request--row approval-request--row-header">
                            <div className="approval-request--row-col-left">
                                <span className="approval-request--row-cell">
                                    <FormattedMessage id="app.ApprovalRequest.button.customerContactName" />
                                </span>
                            </div>
                            <div className="approval-request--row-col-right">
                                <span className="approval-request--row-cell">
                                    <FormattedMessage id="app.ApprovalRequest.button.approvalRequired" />
                                </span>
                            </div>
                        </div>
                        <>
                            {this.state.sharedItems.map((shareItem, i) => {
                                return (
                                    <div key={i} className="approval-request--row approval-request--row">
                                        <div className="approval-request--row-col-left">
                                            <span className="approval-request--row-cell">{`${shareItem.firstName} ${shareItem.lastName}`}</span>
                                        </div>
                                        <div className="approval-request--row-col-right">
                                            <span className="approval-request--row-cell">
                                                <Checkbox
                                                    onChange={() => {
                                                        this.changeSelected(shareItem.id);
                                                    }}
                                                    checked={this.isSelected(shareItem.id)}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    </div>
                )}
                <PopupAction className={classNames({ "approval-request--popup-action-hide": this.state.loadSharing })}>
                    <Button onClick={this.handleSubmit} type="submit" className="button--more-padding" disabled={this.state.loading}>
                        <FormattedMessage id="app.ApprovalRequest.button.save" />
                    </Button>
                    <Button onClick={this.props.close} className="button--more-padding">
                        <FormattedMessage id="app.standard.popup.button.cancel" />
                    </Button>
                </PopupAction>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(ApprovalRequest));
