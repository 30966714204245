import React from "react";
import { Breadcrumbs as MaterialBreadcrumbs, createStyles, withStyles, WithStyles } from "@material-ui/core";
import Separator, { SeparatorParentItems } from "./components/Separator/Separator";
import Collapse from "./components/Collapse/Collapse";
import Item from "./components/Item/Item";
import "./Breadcrumbs.scss";
import classNames from "classnames";

const styles = createStyles({
    root: {
        padding: "24px 0",
    },
});

export interface BreadcrumbSubItem {
    label: string;
    click?: () => void;
    link?: string;
    id: number;
}

export interface BreadcrumbItem {
    id?: number;
    title?: string;
    children?: React.ReactNode | React.ReactNodeArray;
    click?: () => void;
    link?: string;
    subItems?: BreadcrumbSubItem[];
}

interface PropsInterface extends WithStyles {
    selectedItemId?: number;
    items: BreadcrumbItem[];
    maxLength?: number;
    disabledActionButtons?: boolean;
    handleBreadcrumbsClick?: (link?: string, dirId?: number) => void;
    actionButtons?: React.ReactElement;
}

interface StateInterface {}

const MAX_ITEMS = 4;

class Breadcrumbs extends React.Component<PropsInterface, StateInterface> {
    parseBreadcrumbItems() {
        const { items, maxLength } = this.props;
        const needCollapse = items.length > MAX_ITEMS;

        const parsedItems = maxLength
            ? items.map((item) => {
                  if (item.title && item.title.length > maxLength) {
                      item.title = item.title.substr(0, 10) + "...";
                  }

                  return item;
              })
            : items;

        if (!needCollapse) {
            return parsedItems;
        }

        return [
            parsedItems[0],
            parsedItems.slice(1, parsedItems.length - 2),
            parsedItems[parsedItems.length - 2],
            parsedItems[parsedItems.length - 1],
        ];
    }

    renderBreadCrumb(data: BreadcrumbItem | BreadcrumbItem[], key: number, oneItem: boolean, isLast: boolean) {
        if (Array.isArray(data)) {
            return <Collapse items={data} />;
        }

        const itemId = data.id !== undefined ? `breadcrumb-item-id-${data.id}` : undefined;
        const click = this.props.handleBreadcrumbsClick
            ? () => {
                  this.props.handleBreadcrumbsClick && this.props.handleBreadcrumbsClick(data.link, data.id);
                  return false;
              }
            : undefined;

        return data.children ? (
            <Item
                id={itemId}
                key={key}
                title={data.title}
                click={click}
                href={data.link}
                disabledActionButtons={this.props.disabledActionButtons}
                withDropdown={!oneItem && isLast}
                actionButtons={this.props.actionButtons}
            >
                {data.children}
            </Item>
        ) : (
            <Item
                id={itemId}
                key={key}
                title={data.title}
                click={click}
                href={data.link}
                disabledActionButtons={this.props.disabledActionButtons}
                withDropdown={!oneItem && isLast}
                actionButtons={this.props.actionButtons}
            />
        );
    }

    render() {
        const { classes, selectedItemId } = this.props;
        const items = this.parseBreadcrumbItems();
        const subItems: SeparatorParentItems[] = [];
        this.props.items.forEach((item) => {
            if (item.id !== undefined) {
                const itemSubItems = item.subItems || [];
                subItems.push({
                    items: this.props.handleBreadcrumbsClick
                        ? itemSubItems.map((item) => {
                              return {
                                  ...item,
                                  click: () => {
                                      this.props.handleBreadcrumbsClick && this.props.handleBreadcrumbsClick(item.link, item.id);
                                  },
                              };
                          })
                        : itemSubItems,
                    parentId: item.id as number,
                });
            }
        });

        return (
            <MaterialBreadcrumbs
                className={classNames(classes.root, "wed-breadcrumbs")}
                separator={<Separator selectedItemId={selectedItemId} items={subItems} />}
                aria-label="breadcrumb"
            >
                {items.map((item, index) => {
                    return this.renderBreadCrumb(item, index, items.length === 1, index === items.length - 1);
                })}
            </MaterialBreadcrumbs>
        );
    }
}

export default withStyles(styles)(Breadcrumbs);
