import React from "react";
import PopupConfirmButton from "../../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { Button, TextField } from "@material-ui/core";
import "./FileEditTagsPopup.scss";
import TagList from "../../../tile/components/TagList/TagList";
import container from "../../../../container";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    objectId: number;
    tags: string[];
    onClickClose: (updated?: boolean) => void;
}

interface StateInterface {
    tag: string;
    fields: {
        tags: string[];
    };
}

const TAG_MAX_LENGTH = 30;
const TAG_MAX_ITEMS = 10;

class FileEditTagsPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            tag: "",
            fields: { tags: props.tags },
        };
        this.doIt = this.doIt.bind(this);
        this.handleChangeTag = this.handleChangeTag.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
    }

    private async doIt() {
        try {
            await container.filesService.updateObjectTags(this.props.objectId, this.state.fields.tags);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.FileEditTagsPopup.success" }), { variant: "success" });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_OBJECT_INVALID) {
                keyTranslate = "app.FileEditTagsPopup.error.errObjectInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_OBJECT_TAGS_INVALID) {
                keyTranslate = "app.FileEditTagsPopup.error.errObjectTagsInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }
    }

    private handleRemoveTag(removeTag: string) {
        this.setState({ fields: { tags: this.state.fields.tags.filter((tag) => tag.toLowerCase() !== removeTag.toLowerCase()) } });
    }

    private handleAddTag() {
        if (this.state.fields.tags.length >= TAG_MAX_ITEMS) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.FileEditTagsPopup.form.maxTagsExceeded" }, { tag: this.state.tag }), {
                variant: "error",
            });
            return;
        }
        if (this.state.tag.length === 0 || this.state.tag.length > TAG_MAX_LENGTH) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.FileEditTagsPopup.form.enterInvalidTag" }), { variant: "error" });
            return;
        }
        const tagExisted = this.state.fields.tags.find((tag) => tag.toLowerCase() === this.state.tag.toLowerCase());
        if (!tagExisted) {
            this.setState((prevState) => ({
                ...{},
                fields: { ...prevState.fields, tags: [...prevState.fields.tags, this.state.tag] },
                tag: "",
            }));
        } else {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.FileEditTagsPopup.form.existedTag" }, { tag: this.state.tag }), {
                variant: "error",
            });
        }
    }

    private handleChangeTag(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        this.setState(() => ({ tag: value }));
    }

    render() {
        return (
            <PopupConfirmButton
                maxWidth="sm"
                open={true}
                close={this.props.onClickClose}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.standard.popup.button.save"} />}
                noDisplayCancel={true}
                header={<FormattedMessage id={"app.FileEditTagsPopup.header.title"} />}
                disableDialogWrapper={true}
                content={
                    <>
                        <div className="file-edit-tags-popup--add-new-tag-row">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="tags"
                                label={<FormattedMessage id={"app.FileEditTagsPopup.form.enterNewTag"} />}
                                name="tags"
                                autoComplete="off"
                                value={this.state.tag}
                                onChange={this.handleChangeTag}
                                onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        this.handleAddTag();
                                    }
                                }}
                                inputProps={{ maxLength: TAG_MAX_LENGTH }}
                                disabled={this.state.fields.tags.length >= TAG_MAX_ITEMS}
                            />
                            <Button
                                className="button--more-padding"
                                onClick={this.handleAddTag}
                                disabled={this.state.fields.tags.length >= TAG_MAX_ITEMS}
                            >
                                <FormattedMessage id={"app.FileEditTagsPopup.form.addTag"} />
                            </Button>
                        </div>
                        <div className="file-edit-tags-popup--add-new-tag-row">
                            <TagList tags={this.state.fields.tags} handleRemoveClick={this.handleRemoveTag} />
                        </div>
                    </>
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(FileEditTagsPopup));
