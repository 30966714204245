import * as React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import container from "../../../../../container";
import { WedMenuButtons } from "../../../../main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../wed-icon/WedIcon";
import {
    WedIconAddCirlcePlus,
    WedIconFolderGroupPlusAdd,
    WedIconPlusAdd,
    WedIconShare,
    WedIconShare2,
    WedIconTrashDeleteRemove,
} from "../../../../wed-icon/generated/WedIconSvg";
import { ISelectItem, ISelectItemType } from "../../../../status-action-button/StatusActionButton";
import FilesActionButton from "../FilesActionButton/FilesActionButton";
import { ICollection } from "../../../../../services/WedApi/Models/Collection";
import { IDirectory, IFile } from "../../../../../services/WedApi/Models/File";
import ButtonAddToCollection from "../../../../project/Collection/ButtonAddToCollection/ButtonAddToCollection";
import { IProject } from "../../../../../services/WedApi/Models/Project";
import {isEditableProject, isMyLibraryFileTypeContext} from "../../../../../services/WedApi/Utils/IsEditableProject";
import { FileTypeEnum } from "../../../Type/FileTypeEnum";
import { ApplicationState } from "../../../../../store";
import { connect } from "react-redux";
import { hasWriteAccessToSharedMyLibraryFolder } from "../../../../../services/WedApi/Utils/AccessMyLibrary";
import "./FilesButtons.scss";

interface PropsInterface extends WrappedComponentProps {
    project?: IProject;
    directory?: IDirectory;
    projectName: string;
    dirId: number;
    rootDir?: number;
    createFolder: () => void;
    uploadFiles?: () => void;
    addToCollection?: () => void;
    removeToTrash: (items: ISelectItem[]) => void;
    selectedItems: ISelectItem[];
    actionCallback: () => void;
    collection?: ICollection | undefined;
    handleOpenFile?: (file: IFile, dirId: number) => void;
    handleOpenFolder?: (directory: IDirectory) => void;
    onInitActionCallback?: () => void;
    onErrorActionCallback?: () => void;
    createCollection?: () => void;
    deleteCollection?: () => void;
    openSharing?: () => void;
    openAddFileToCollection?: () => void;
    openUploadFile?: () => void;
    fileType: FileTypeEnum;
    userId?: number;
}

interface StateInterface {}

class FilesButtons extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.isAllSelectedOwner = this.isAllSelectedOwner.bind(this);
    }

    private isAllSelectedOwner() {
        const { selectedItems } = this.props;

        return selectedItems.length && selectedItems.length == selectedItems.filter((item) => item.userIsOwner === true).length;
    }

    private isSelectedItemsOwner() {
        const { selectedItems } = this.props;

        return (
            selectedItems.length &&
            selectedItems.length ==
                selectedItems.filter((item) => {
                    if (item.userIsOwner === true) {
                        return true;
                    }

                    if (item.type !== ISelectItemType.directory) {
                        return true;
                    }

                    const object = item.object as IDirectory;

                    if (object.writeAccessIds.includes(this.props.userId || 0)) {
                        return true;
                    }
                    return false;
                }).length
        );
    }

    private disabledMyLibrarySharedWithWriteAccess() {
        if (this.props.fileType !== FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME) {
            return false;
        }
        return !hasWriteAccessToSharedMyLibraryFolder(this.props.directory, this.props.userId);
    }

    render() {
        const {
            selectedItems,
            project,
            dirId,
            collection,
            rootDir,
            actionCallback,
            removeToTrash,
            projectName,
            handleOpenFile,
            handleOpenFolder,
            onInitActionCallback,
            onErrorActionCallback,
            openUploadFile,
        } = this.props;

        return (
            <WedMenuButtons>
                {dirId && (
                    <Button
                        disabled={!isEditableProject(this.props.fileType, project) || this.disabledMyLibrarySharedWithWriteAccess()}
                        onClick={this.props.createFolder}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconFolderGroupPlusAdd />} />}
                    >
                        <FormattedMessage id="app.ProjectFilesButtons.createFolder" />
                    </Button>
                )}
                {dirId && (
                    <Button
                        disabled={
                            !isEditableProject(this.props.fileType, project) || !openUploadFile || this.disabledMyLibrarySharedWithWriteAccess()
                        }
                        onClick={openUploadFile}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare />} />}
                    >
                        <FormattedMessage id="app.ProjectFilesButtons.uploadFiles" />
                    </Button>
                )}
                {dirId && (
                    <ButtonAddToCollection
                        disabled={!isEditableProject(this.props.fileType, project)}
                        objectItems={container.collectionAddFilesService.convertSelectItemsToObjectItems(selectedItems)}
                        projectId={project?.id || 0}
                        fileType={this.props.fileType}
                    />
                )}
                {dirId && (
                    <Button
                        onClick={() => {
                            removeToTrash(selectedItems);
                        }}
                        disabled={!(
                            (
                                !isMyLibraryFileTypeContext(this.props.fileType) &&
                                isEditableProject(this.props.fileType, project) &&
                                this.props.selectedItems.length > 0
                            )
                            ||
                            (
                                isMyLibraryFileTypeContext(this.props.fileType) &&
                                (this.isSelectedItemsOwner() || !this.disabledMyLibrarySharedWithWriteAccess())
                            )
                        )}
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                    >
                        <FormattedMessage id="app.ProjectFilesButtons.removeToTrash" />
                    </Button>
                )}
                {collection && (
                    <Button
                        disabled={!isEditableProject(this.props.fileType, project)}
                        onClick={this.props.createCollection}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}
                    >
                        <FormattedMessage id="app.projectDetailButtons.createCollection" />
                    </Button>
                )}
                {collection && (
                    <Button
                        onClick={() => {
                            this.props.openSharing && this.props.openSharing();
                        }}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare2 />} />}
                        disabled={!isEditableProject(this.props.fileType, project) || !collection.userIsOwner || collection.locked}
                    >
                        <FormattedMessage id="app.projectDetailButtons.sharing" />
                    </Button>
                )}
                {collection && (
                    <Button
                        onClick={() => {
                            this.props.openAddFileToCollection && this.props.openAddFileToCollection();
                        }}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconAddCirlcePlus />} />}
                        disabled={!isEditableProject(this.props.fileType, project) || !collection.userIsOwner || collection.locked}
                    >
                        <FormattedMessage id="app.projectDetailButtons.addFiles" />
                    </Button>
                )}
                {collection && (
                    <Button
                        onClick={() => {
                            this.props.deleteCollection && this.props.deleteCollection();
                        }}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                        disabled={!isEditableProject(this.props.fileType, project) || !collection.userIsOwner}
                    >
                        <FormattedMessage id="app.projectDetailButtons.deleteCollection" />
                    </Button>
                )}

                <FilesActionButton
                    selectedItems={selectedItems}
                    // eslint-disable-next-line
                    project={project || ({} as any)}
                    dirId={dirId}
                    rootDir={rootDir}
                    rootDirName={projectName}
                    collection={collection}
                    actionCallback={actionCallback}
                    removeToTrash={removeToTrash}
                    handleOpenFile={handleOpenFile}
                    handleOpenFolder={handleOpenFolder}
                    onInitActionCallback={onInitActionCallback}
                    onErrorActionCallback={onErrorActionCallback}
                    fileType={this.props.fileType}
                />
            </WedMenuButtons>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: PropsInterface) => ({
    ...props,
    userId: store.user.data?.id,
});

export default injectIntl(connect(mapStateToProps)(FilesButtons));
