import React from "react";
import { INote, INoteAnnotation, NoteObject } from "../../../services/WedApi/Models/Note";
import "./CommentNote.scss";
import Initials, { InitialsUserType } from "../../tile/components/Initials/Initials";
import { getInitialsAndOwner, InitialsAndOwnerInterface } from "../../../services/WedApi/Utils/Initials";
import WedDate from "../../formatter/WedDate";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconReplyShareCircle } from "../../wed-icon/generated/WedIconSvg";
import classNames from "classnames";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { setWedImageAnnotation } from "../../../store/WedImageGalleryReducer";

interface PropsInterface {
    disabled?: boolean;
    className?: string;
    object: NoteObject;
    note: INote;
    replyClick?: () => void;
    setWedImageAnnotation: (annotation?: INoteAnnotation) => void;
}

interface StateInterface {}

class CommentNote extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.showAnnotation = this.showAnnotation.bind(this);
        this.hideAnnotation = this.hideAnnotation.bind(this);
    }

    private showAnnotation() {
        this.props.setWedImageAnnotation(this.props.note.annotation);
    }

    private hideAnnotation() {
        this.props.setWedImageAnnotation(undefined);
    }

    componentWillUnmount() {
        this.props.setWedImageAnnotation(undefined);
    }

    render() {
        const userInitials: InitialsAndOwnerInterface | undefined = this.props.note.createdBy
            ? getInitialsAndOwner(this.props.note.createdBy)
            : this.props.note.ecContact
            ? getInitialsAndOwner(this.props.note.ecContact)
            : undefined;

        return (
            <div className={classNames("comment-note", this.props.className)}>
                <div
                    className="comment-note--container"
                    onMouseEnter={this.props.note.annotation ? this.showAnnotation : undefined}
                    onMouseLeave={this.props.note.annotation ? this.hideAnnotation : undefined}
                >
                    <div className="comment-note--container-line-wrapper comment-note--container-line-wrapper-top">
                        <div className="comment-note--container-line" />
                    </div>
                    <div className="comment-note--wrapper">
                        <div className="comment-note--initials">
                            <Initials
                                userType={
                                    this.props.note.createdBy
                                        ? InitialsUserType.INTERNAL
                                        : this.props.note.ecContact
                                        ? InitialsUserType.EXTERNAL
                                        : undefined
                                }
                                title={userInitials ? userInitials.initials : ""}
                                fullTitle={userInitials ? userInitials.owner : undefined}
                            />
                        </div>
                        <div className="comment-note--content">
                            <div className="comment-note--content-top">
                                <div className="comment-note--content-user">
                                    <div className="comment-note--content-header">{userInitials ? userInitials.owner : ""}</div>
                                    <div className="comment-note--content-created">
                                        {this.props.note.created && <WedDate date={this.props.note.created} fullTime={true} />}
                                    </div>
                                </div>
                                {this.props.replyClick && (
                                    <div className="comment-note--content-actions">
                                        <button disabled={this.props.disabled} onClick={this.props.replyClick}>
                                            <WedIcon icon={<WedIconReplyShareCircle />} size={SizeType.SMALL} />
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="comment-note--content-description">{this.props.note.content}</div>
                        </div>
                    </div>
                    <div className="comment-note--container-line-wrapper comment-note--container-line-wrapper-bottom">
                        <div className="comment-note--container-line" />
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setWedImageAnnotation: setWedImageAnnotation(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(CommentNote);
