import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { FormattedMessage } from "react-intl";

interface PropsInterface {
    initObjectId: number;
    mediaData: { objectId: number; url: string }[];
    onCloseRequest: (objectId?: number) => void;
}

interface StateInterface {
    objectId: number;
    url?: string;
}

class WedLightBox extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            objectId: props.initObjectId,
        };
    }

    render() {
        const itemIdx = this.props.mediaData.findIndex((mediaItem) => mediaItem.objectId === this.state.objectId);
        if (itemIdx < 0) {
            return <></>;
        }

        const currentItem = this.props.mediaData[itemIdx];
        const nextItem = this.props.mediaData[itemIdx + 1];
        const prevItem = this.props.mediaData[itemIdx - 1];

        return (
            <Lightbox
                mainSrc={currentItem.url}
                nextSrc={nextItem ? nextItem.url : undefined}
                prevSrc={prevItem ? prevItem.url : undefined}
                imageLoadErrorMessage={<FormattedMessage id="app.WedLightBox.imageLoadErrorMessage" />}
                onMoveNextRequest={() => {
                    this.setState({ objectId: nextItem.objectId });
                }}
                onMovePrevRequest={() => {
                    this.setState({ objectId: prevItem.objectId });
                }}
                onCloseRequest={() => {
                    this.props.onCloseRequest(this.state.objectId === this.props.initObjectId ? undefined : this.state.objectId);
                }}
            />
        );
    }
}

export default WedLightBox;
