import React from "react";
import WedImageLogo from "./WedImageLogo";

interface PropsInterface {}

interface StateInterface {}

class WedImageLogo160x160 extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <WedImageLogo width={160} height={160} file={"mlogo-160-160.png"} />;
    }
}

export default WedImageLogo160x160;
