import React, { Component, ReactElement } from "react";
import { Button, ClickAwayListener } from "@material-ui/core";
import Uniq from "lodash/uniq";
import classnames from "classnames";
import isEqual from "lodash/isEqual";
import { DropDownFolderListMenu } from "../drop-down-menu/FolderListMenu/FolderListMenu";
import { IMenuItem } from "../wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import { SizeType, WedIcon } from "../wed-icon/WedIcon";
import { WedIconDotsMenu } from "../wed-icon/generated/WedIconSvg";
import { IDirectory, IFile } from "../../services/WedApi/Models/File";
import { IProject } from "../../services/WedApi/Models/Project";
import { ICollection } from "../../services/WedApi/Models/Collection";
import { IClient } from "../../services/WedApi/Models/Client";
import BreadcrumbsActionButtons from "../breadcrumbs/components/ActionButtons/BreadcrumbsActionButtons";
import "./status-action-button.scss";
import { IClientContact } from "../../services/WedApi/Models/ClientContact";
import { IUserTeam } from "../../services/WedApi/Models/User";
import { IShareCollection } from "../../services/WedApi/Models/ShareCollection";

export enum ISelectItemType {
    project = "project",
    collection = "collection",
    directory = "directory",
    file = "file",
    client = "client",
    clientContact = "clientContact",
    userTeam = "userTeam",
}

export interface ISelectItem {
    id: number;
    type?: ISelectItemType;
    status?: string;
    userIsOwner?: boolean;
    parentFolder?: IDirectory;
    object?: IFile | IDirectory | IProject | ICollection | IClient | IClientContact | IUserTeam | IShareCollection;
}

export interface IBreadcrumbActionButtons {
    id?: string;
    title: string;
}

interface PropsInterface {
    breadcrumbItem?: IBreadcrumbActionButtons;
    menuItems: {
        items: IMenuItem[]; // menu items list
        statuses?: string[]; // selected items statuses list filter [active, deleted ...]
        types?: ISelectItemType[]; // selected items types list filter [project, file ...]
        multiply?: boolean; // for selected items more than 1 or only one
    }[];
    selectedItems: ISelectItem[];
    icon?: ReactElement;
    closeCallback?: () => void;
}

interface StateInterface {
    showMenu: boolean;
    moveToLeft: boolean;
}

export class StatusActionButton extends Component<PropsInterface, StateInterface> {
    private dropdownRef = React.createRef<HTMLDivElement>();

    constructor(props: PropsInterface) {
        super(props);
        this.state = { showMenu: false, moveToLeft: false };
    }

    handleClick = () => {
        const { showMenu } = this.state;

        if (showMenu) {
            this.closeCallback();
        }

        const newShowMenu = !showMenu;
        this.setState(
            {
                showMenu: newShowMenu,
                moveToLeft: false,
            },
            () => {
                if (newShowMenu) {
                    this.fixDropdownPosition();
                }
            }
        );
    };

    handleClickOutside = () => {
        this.setState({
            showMenu: false,
            moveToLeft: false,
        });
        this.closeCallback();
    };

    handleMouseOver = () => {
        const { showMenu } = this.state;

        if (!showMenu) {
            this.setState({
                showMenu: true,
            });
        }
    };

    fixDropdownPosition = () => {
        const leftPos = this.dropdownRef.current?.getBoundingClientRect().left;
        if (leftPos !== undefined && leftPos < 0) {
            this.setState({
                moveToLeft: true,
            });
        }
    };

    onMouseLeave = () => {
        const { showMenu } = this.state;

        if (showMenu) {
            this.setState({
                showMenu: false,
            });
            this.closeCallback();
        }
    };

    closeCallback = () => {
        const { closeCallback } = this.props;

        closeCallback && closeCallback();
    };

    getActionList() {
        const { selectedItems, menuItems } = this.props;

        const selectedMultiply = selectedItems.length > 1 ? true : selectedItems.length == 1 ? false : undefined;
        const selectedStatuses = Uniq(selectedItems.filter((item) => item.status != undefined).map((item) => item.status)).sort();
        const selectedTypes = Uniq(selectedItems.filter((item) => item.type != undefined).map((item) => item.type)).sort();

        // search for first matching menu item
        for (const menuItem of menuItems) {
            if (!(menuItem.multiply === undefined || menuItem.multiply == selectedMultiply)) {
                continue;
            }

            if (!(menuItem.statuses === undefined || isEqual(selectedStatuses, menuItem.statuses.sort()))) {
                continue;
            }

            if (!(menuItem.types === undefined || isEqual(selectedTypes, menuItem.types))) {
                continue;
            }

            return menuItem.items;
        }

        return [];
    }

    render() {
        const { showMenu, moveToLeft } = this.state;
        const { icon } = this.props;
        const actions = this.getActionList();
        const disabled = actions.length == 0;

        return (
            <ClickAwayListener
                onClickAway={() => {
                    this.handleClickOutside();
                }}
                disableReactTree={true}
            >
                {this.props.breadcrumbItem ? (
                    <BreadcrumbsActionButtons
                        childRef={this.dropdownRef}
                        handleClick={this.handleClick}
                        title={this.props.breadcrumbItem.title}
                        id={this.props.breadcrumbItem.id}
                        showMenu={showMenu}
                        moveToLeft={moveToLeft}
                        listMenu={<DropDownFolderListMenu items={actions} />}
                    />
                ) : (
                    <div
                        onClick={() => {
                            this.handleClick();
                        }}
                        className={classnames("status-action-button", { "status-action-button__active": showMenu })}
                    >
                        {icon || (
                            <Button type="button" disabled={disabled}>
                                <WedIcon size={SizeType.SMALL} icon={icon || <WedIconDotsMenu />} />
                            </Button>
                        )}
                        {!disabled && (
                            <div
                                ref={this.dropdownRef}
                                className={classnames("status-action-button__menu", { "status-action-button__menu--active": showMenu })}
                            >
                                <DropDownFolderListMenu items={actions} />
                            </div>
                        )}
                    </div>
                )}
            </ClickAwayListener>
        );
    }
}
