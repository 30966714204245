import { IUser, IUserMyProfile, IUserTeam, IUserTeamDetail } from "../Models/User";
import UserActionApiService from "../UserActionApiService";
import { IListResult } from "../Models/Common";
import { convertStrToDate } from "../Utils/DateConverter";
import { AppNotFoundItemError } from "../../../errors/error-app";
import { ICountry } from "../Models/Country";
import ProjectActionApiService from "../ProjectActionApiService";
import { ProjectService } from "./ProjectService";
import { ProjectStatusEnum } from "../Models/Project";
import { ConvertApiFileContextEnum, convertIApiObjectToIFile, convertUserRoleId } from "./BaseConverter";
import { IFile } from "../Models/File";
import { uniq } from "lodash";
import { TokenFormData } from "./CaptchaService";

export class UserService {
    constructor(private readonly userActionApiService: UserActionApiService, private readonly projectActionApiService: ProjectActionApiService) {}

    async login(tokenForm: TokenFormData | undefined, email: string, password: string): Promise<void> {
        await this.userActionApiService.login(tokenForm, email, password);
    }

    async register(tokenForm: TokenFormData | undefined, email: string, password: string, firstName: string, lastName: string): Promise<void> {
        await this.userActionApiService.register(tokenForm, email, password, firstName, lastName);
    }

    async signupInvitation(tokenForm: TokenFormData | undefined, token: string, password: string, firstName: string, lastName: string): Promise<void> {
        await this.userActionApiService.signupInvitation(tokenForm, token, password, firstName, lastName);
    }

    async updateUserProfile(
        id: number,
        params: {
            firstName: string;
            lastName: string;
            country: ICountry | null;
            phone: string;
        }
    ) {
        await this.userActionApiService.updateUserProfile(
            id,
            params.firstName,
            params.lastName,
            params.country?.id || undefined,
            params.phone || undefined
        );
    }

    async updateUserPassword(currentPassword: string, newPassword: string): Promise<void> {
        await this.userActionApiService.updateUserPassword(currentPassword, newPassword);
    }

    async activateUser(email: string, token: string): Promise<boolean> {
        try {
            await this.userActionApiService.activateUser(email, token);
            return true;
        } catch (e) {
            if (["ErrUserActicationFormInvalid", "ErrUserEmalInvalid", "ErrActivationTokenInvalid"].includes(e.code)) {
                return false;
            }
            throw e;
        }
    }

    async logout(): Promise<void> {
        await this.userActionApiService.logout();
    }

    async me(): Promise<IUserMyProfile> {
        const apiUser = await this.userActionApiService.me();
        return {
            id: apiUser.id,
            firstName: apiUser.firstName,
            lastName: apiUser.lastName,
            email: apiUser.email,
            cCode: apiUser.cCode,
            phone: apiUser.phone,
            role: convertUserRoleId(apiUser.roleID),
            created: convertStrToDate(apiUser.createdAt),
        };
    }

    async getByIds(ids: number[]): Promise<IUser[]> {
        const users: IListResult<IUser> = await this.userActionApiService.getByIds(ids);

        return users.items;
    }

    async getUsers(options?: { withProjectCounter?: boolean }): Promise<IUserTeam[]> {
        let users: IUserTeam[] = await this.userActionApiService.getUsers();
        if (options?.withProjectCounter) {
            const projectIds: number[] = [];
            users.forEach((user) => {
                projectIds.push(...user.projectIDs);
            });
            const projects = await Promise.all(uniq(projectIds).map((projectId) => this.projectActionApiService.getProject(projectId)));
            users = users.map((user) => {
                const userProjects = projects.filter((project) => user.projectIDs.includes(project.Pr.id));
                return {
                    ...user,
                    counters: {
                        projectActive: userProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.active)
                            .length,
                        projectClosed: userProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.closed)
                            .length,
                    },
                };
            });
        }

        return users;
    }

    async populateCountersUserDetail(user: IUserTeamDetail): Promise<IUserTeamDetail> {
        if (user.counters) {
            return user;
        }
        const userProjects = await Promise.all(user.projectIDs.map((projectId) => this.projectActionApiService.getProject(projectId)));
        return {
            ...user,
            counters: {
                projectActive: userProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.active).length,
                projectClosed: userProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.closed).length,
            },
        };
    }

    async getUserDetail(id: number): Promise<IUserTeamDetail> {
        const users: IUserTeam[] = await this.userActionApiService.getUsers();
        const user = users.find((user) => user.id === id);
        if (user) {
            return user;
        }
        throw new AppNotFoundItemError("userTeam", id);
    }

    public async forgot(tokenForm: TokenFormData | undefined, email: string): Promise<void> {
        return this.userActionApiService.forgot(tokenForm, email);
    }

    public async forgotUpdate(token: string, password: string): Promise<void> {
        return this.userActionApiService.forgotUpdate(token, password);
    }

    async registerCompanyData(
        name: string,
        shortName: string | null,
        taxID: string,
        streetAddress1: string,
        streetAddress2: string | null,
        zipCode: string,
        city: string,
        state: string,
        countryID: number,
        planID: number,
    ): Promise<{ redirectUrl: string }> {
        return this.userActionApiService.registerCompanyData(name, shortName, taxID, streetAddress1, streetAddress2, zipCode, city, state, countryID, planID);
    }

    async getProjectWorks(userId: number): Promise<IFile[]> {
        return (await this.userActionApiService.getProjectWorks(userId)).map((object) => {
            return convertIApiObjectToIFile(object, [], ConvertApiFileContextEnum.projectWork);
        });
    }

    async getPrivatePortfolio(userId: number): Promise<IFile[]> {
        return (await this.userActionApiService.getPrivatePortfolio(userId)).map((object) => {
            return convertIApiObjectToIFile(object, [], ConvertApiFileContextEnum.privatePortfolio);
        });
    }

    public async addToPrivatePortfolio(fileIds: number[]): Promise<void> {
        if (fileIds.length > 0) {
            await this.userActionApiService.updatePrivatePortfolio([], fileIds);
        }
    }

    public async removeFromPrivatePortfolio(fileIds: number[]): Promise<void> {
        if (fileIds.length > 0) {
            await this.userActionApiService.updatePrivatePortfolio(fileIds, []);
        }
    }
}
