export function getScrollbarYStyle(options?: { right?: number }) {
    return {
        position: "absolute",
        width: 6,
        right: options?.right ?? 8,
        bottom: 2,
        top: 2,
        borderRadius: 3,
    };
}

export function getScrollbarIconYStyle() {
    return {
        cursor: "pointer",
        borderRadius: "inherit",
        backgroundColor: "rgb(255,255,255,0.6)",
    };
}

export function getScrollbarXStyle(options?: { bottom?: number }) {
    return {
        position: "absolute",
        height: 6,
        right: 2,
        bottom: options?.bottom ?? 10,
        left: 2,
        borderRadius: 3,
    };
}

export function getScrollbarIconXStyle() {
    return {
        position: "relative",
        display: "block",
        height: "100%",
        cursor: "pointer",
        borderRadius: "inherit",
        backgroundColor: "rgb(255,255,255,0.6)",
        transform: "translateX(0px)",
    };
}
