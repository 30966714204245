import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {ProviderContext, withSnackbar} from "notistack";
import container from "../../../container";
import {ICountry} from "../../../services/WedApi/Models/Country";
import {TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IClientAddress} from "../../../services/WedApi/Models/Client";
import {getRandomString} from "../../../services/WedApi/Utils/GetRandomString";
import JSONSchemaValidator, {ValidationErrors} from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import {ICreateUpdateClient} from "../../../services/WedApi/Services/ClientService";
import "./EditClientPopup.scss";
import {formatFormValues, FormatType} from "../../../services/WedApi/Utils/FormFormatters";

interface FormValues {
    name: string;
    desc: string;
    website: string;
    streetAddress1: string;
    streetAddress2: string;
    zipCode: string;
    city: string;
    state: string;
    country: ICountry | null;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    clientAddress?: IClientAddress;
    onClickClose: (updated?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
    countries: ICountry[];
    formValues: FormValues;
}

class EditClientPopup extends React.Component<PropsInterface, StateInterface> {
    fieldFormCountry: React.RefObject<HTMLInputElement>;
    schema = {
        type: "object",
        properties: {
            name: {
                type: "string",
                minLength: 3,
                maxLength: 100,
            },
            desc: {
                type: "string",
                maxLength: 500,
            },
            website: {
                type: "string",
                maxLength: 200,
            },
            streetAddress1: {
                type: "string",
                maxLength: 100,
                minLength: 1,
            },
            streetAddress2: {
                type: "string",
                maxLength: 100,
            },
            phone: {
                type: "string",
                maxLength: 20,
            },
            zipCode: {
                type: "string",
                maxLength: 20,
                minLength: 1,
            },
            city: {
                type: "string",
                maxLength: 100,
                minLength: 1,
            },
            state: {
                type: "string",
                maxLength: 100,
            },
            country: {
                type: "object",
            },
        },
        required: ["name", "streetAddress1", "zipCode", "city"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                name: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.name" }),
                desc: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.desc" }),
                website: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.website" }),
                streetAddress1: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.streetAddress1" }),
                streetAddress2: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.streetAddress2" }),
                phone: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.phone" }),
                zipCode: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.zipCode" }),
                city: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.city" }),
                state: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.state" }),
                country: this.props.intl.formatMessage({ id: "app.EditClientPopup.error.countryID" }),
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            countries: [],
            formValues: {
                name: "",
                desc: "",
                website: "",
                streetAddress1: "",
                streetAddress2: "",
                zipCode: "",
                city: "",
                state: "",
                country: null,
            },
        };
        this.fieldFormCountry = React.createRef();
        this.preDoIt = this.preDoIt.bind(this);
        this.doIt = this.doIt.bind(this);
    }

    private validate(): { valid: boolean; formErrors: ValidationErrors } {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    }

    private validateForm(): ValidationErrors {
        const { errors } = JSONSchemaValidator.validate(
            {
                ...this.state.formValues,
                country: this.state.formValues.country || undefined,
            },
            this.schema
        );

        return errors;
    }

    async componentDidMount() {
        const countries = await container.countryService.getCountries();
        this.setState({
            loading: false,
            countries,
            formValues: {
                name: this.props.clientAddress?.name || "",
                desc: this.props.clientAddress?.desc || "",
                website: this.props.clientAddress?.website || "",
                streetAddress1: this.props.clientAddress?.streetAddress1 || "",
                streetAddress2: this.props.clientAddress?.streetAddress2 || "",
                zipCode: this.props.clientAddress?.zipCode || "",
                city: this.props.clientAddress?.city || "",
                state: this.props.clientAddress?.state || "",
                country: this.props.clientAddress?.countryID
                    ? countries.find((country) => country.id === this.props.clientAddress?.countryID) || null
                    : null,
            },
        });
        if (this.fieldFormCountry && this.fieldFormCountry.current) {
            const inputs = this.fieldFormCountry.current.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].autocomplete = getRandomString();
            }
        }
    }

    // eslint-disable-next-line
    private changeValue(name: string, value: any) {
        if (Object.keys(this.state.formValues).includes(name)) {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    [name]: value,
                },
            });
        }
    }

    private preDoIt() {
        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return false;
        }
    }

    private getFormValues(): ICreateUpdateClient {
        return formatFormValues(
            {
                name: this.state.formValues.name,
                desc: this.state.formValues.desc,
                website: this.state.formValues.website,
                streetAddress1: this.state.formValues.streetAddress1,
                streetAddress2: this.state.formValues.streetAddress2,
                zipCode: this.state.formValues.zipCode,
                city: this.state.formValues.city,
                state: this.state.formValues.state,
                countryID: this.state.formValues.country ? this.state.formValues.country.id : undefined,
            },
            {website: FormatType.website}
        );
    }

    private async doIt() {
        try {
            if (this.props.clientAddress) {
                await container.clientService.updateClient(this.props.clientAddress.id, this.getFormValues());
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.EditClientPopup.success.updated" }), { variant: "success" });
            } else {
                await container.clientService.createClient(this.getFormValues());
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.EditClientPopup.success.created" }), { variant: "success" });
            }
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === "ErrECNameInvalid") {
                keyTranslate = "app.EditClientPopup.error.name";
            } else if (e.code === "ErrECDescriptionInvalid") {
                keyTranslate = "app.EditClientPopup.error.desc";
            } else if (e.code === "ErrECWebsiteInvalid") {
                keyTranslate = "app.EditClientPopup.error.website";
            } else if (e.code === "ErrStreetAddressInvalid") {
                keyTranslate = "app.EditClientPopup.error.streetAddress1";
            } else if (e.code === "ErrZipCodeInvalid") {
                keyTranslate = "app.EditClientPopup.error.zipCode";
            } else if (e.code === "ErrStateInvalid") {
                keyTranslate = "app.EditClientPopup.error.state";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }
    }

    render() {
        return (
            <PopupConfirmButton
                maxWidth="lg"
                open={true}
                close={this.props.onClickClose}
                className={"edit-client-popup"}
                preDoIt={this.preDoIt}
                doIt={this.doIt}
                confirmButtonLabel={
                    <FormattedMessage id={this.props.clientAddress ? "app.EditClientPopup.button.save" : "app.EditClientPopup.button.create"} />
                }
                noDisplayCancel={true}
                header={
                    <FormattedMessage
                        id={this.props.clientAddress ? "app.EditClientPopup.header.editTitle" : "app.EditClientPopup.header.createTitle"}
                    />
                }
                disableDialogWrapper={true}
                loadingContent={this.state.loading}
                content={
                    <div className="edit-client-popup--content">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={<FormattedMessage id={"app.EditClientPopup.form.name"} />}
                            autoComplete={getRandomString()}
                            value={this.state.formValues.name}
                            onChange={(event) => {
                                this.changeValue("name", event.target.value);
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.EditClientPopup.form.desc"} />}
                            autoComplete={getRandomString()}
                            value={this.state.formValues.desc}
                            onChange={(event) => {
                                this.changeValue("desc", event.target.value);
                            }}
                            multiline={true}
                            rows={5}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.EditClientPopup.form.website"} />}
                            autoComplete={getRandomString()}
                            value={this.state.formValues.website}
                            onChange={(event) => {
                                this.changeValue("website", event.target.value);
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label={<FormattedMessage id={"app.EditClientPopup.form.streetAddress1"} />}
                            autoComplete={getRandomString()}
                            value={this.state.formValues.streetAddress1}
                            onChange={(event) => {
                                this.changeValue("streetAddress1", event.target.value);
                            }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.EditClientPopup.form.streetAddress2"} />}
                            autoComplete={getRandomString()}
                            value={this.state.formValues.streetAddress2}
                            onChange={(event) => {
                                this.changeValue("streetAddress2", event.target.value);
                            }}
                        />
                        <div className="edit-client-popup--columns">
                            <div className="edit-client-popup--column-item">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={<FormattedMessage id={"app.EditClientPopup.form.city"} />}
                                    autoComplete={getRandomString()}
                                    value={this.state.formValues.city}
                                    onChange={(event) => {
                                        this.changeValue("city", event.target.value);
                                    }}
                                />
                            </div>
                            <div className="edit-client-popup--column-item">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={<FormattedMessage id={"app.EditClientPopup.form.state"} />}
                                    autoComplete={getRandomString()}
                                    value={this.state.formValues.state}
                                    onChange={(event) => {
                                        this.changeValue("state", event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="edit-client-popup--columns">
                            <div className="edit-client-popup--column-item">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label={<FormattedMessage id={"app.EditClientPopup.form.zipCode"} />}
                                    autoComplete={getRandomString()}
                                    value={this.state.formValues.zipCode}
                                    onChange={(event) => {
                                        this.changeValue("zipCode", event.target.value);
                                    }}
                                />
                            </div>
                            <div className="edit-client-popup--column-item">
                                <Autocomplete
                                    fullWidth
                                    options={this.state.countries}
                                    getOptionLabel={(option: ICountry) => option.countryName}
                                    value={this.state.formValues.country}
                                    onChange={(_, value: ICountry | null) => {
                                        this.changeValue("country", value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label={<FormattedMessage id={"app.EditClientPopup.form.country"} />}
                                            margin="normal"
                                            variant="outlined"
                                            ref={this.fieldFormCountry}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(EditClientPopup));
