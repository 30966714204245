import React from "react";
import classNames from "classnames";
import MenuList from "@material-ui/core/MenuList";
import "./WedDropDownMenu.scss";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
    className?: string;
    onClickAway?: () => void;
}

interface StateInterface {}

export class WedDropDownMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <MenuList className={classNames("wed-drop-down-menu", ...(this.props.className ? [this.props.className] : []))}>
                {this.props.children}
            </MenuList>
        );
    }
}
