import React, { ReactElement } from "react";
import "./title.scss";

interface PropsInterface {
    title: string;
    icon?: ReactElement;
}

interface StateInterface {}

export default class Title extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { title, icon } = this.props;

        return (
            <div className="tile-title">
                {icon}
                {title}
            </div>
        );
    }
}
