import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApplicationState } from "../../../store";
import "./PageInfoBox.scss";

interface PropsInterface extends RouteComponentProps {
    title?: string;
    subTitle?: string;
}

class PageInfoBox extends React.Component<PropsInterface> {
    render() {
        return (
            <h1 className="page-info-box">
                {this.props.title || <>&nbsp;</>}
                {this.props.subTitle && <small>{this.props.subTitle}</small>}
            </h1>
        );
    }
}

const mapStateToProps = (store: ApplicationState): Partial<PropsInterface> => ({
    title: store.pageInfoBox?.title,
    subTitle: store.pageInfoBox?.subTitle,
});

export default connect(mapStateToProps)(withRouter(PageInfoBox));
