import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import container from "../../../../container";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import JSONSchemaValidator, { ValidationErrors } from "../../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import { IProject } from "../../../../services/WedApi/Models/Project";

interface StateInterface {
    loading: boolean;
    fieldProjectName: string;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: (isUpdate?: boolean) => void;
    project: IProject;
}

class RenameProject extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            fieldProjectName: {
                type: "string",
                minLength: 3,
                maxLength: 100,
            },
        },
        required: ["fieldProjectName"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                fieldProjectName: this.props.intl.formatMessage({ id: "app.createProject.error.projectName" }),
            },
        },
    };

    readonly state = {
        loading: false,
        fieldProjectName: "",
    };

    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        if (!this.props.project) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            return;
        }

        this.setState({ fieldProjectName: this.props.project.name });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ fieldProjectName: value });
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ fieldProjectName: this.state.fieldProjectName }, this.schema);

        return errors;
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            await container.projectService.renameProject(this.props.project.id, this.state.fieldProjectName);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.RenameProject.success.rename" }), { variant: "success" });
            this.props.close(true);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_ID_INVALID) {
                keyTranslate = "app.error.errProjectIDInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_NAME_INVALID) {
                keyTranslate = "app.error.errProjectNameInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            this.setState({ loading: false });
        }
    };

    private closePopup() {
        this.props.close();
    }

    render() {
        return (
            <div className="rename-project">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.RenameProject.header.title" />
                </PopupHeader>
                <form onSubmit={this.handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="renameProject"
                        label={<FormattedMessage id={"app.createProject.form.projectName"} />}
                        name="projectName"
                        autoComplete="off"
                        value={this.state.fieldProjectName}
                        onChange={this.handleChange}
                    />
                    <PopupAction>
                        <Button type="submit" className="button--more-padding" disabled={this.state.loading}>
                            <FormattedMessage id="app.standard.popup.button.save" />
                        </Button>
                        <Button onClick={this.closePopup} className="button--more-padding">
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(RenameProject));
