import React from "react";
import { FormattedMessage } from "react-intl";
import { WedImageGalleryDataProps } from "../../../../store/WedImageGalleryReducer";
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import { Button, CircularProgress, ClickAwayListener } from "@material-ui/core";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconAddCirlcePlus } from "../../../wed-icon/generated/WedIconSvg";
import classnames from "classnames";
import ListCollectionForObjectItems from "../ListCollectionForObjectItems/ListCollectionForObjectItems";
import container from "../../../../container";
import { ICollectionForObjectItem, IObjectItem } from "../../../../services/WedApi/Services/CollectionAddFilesService";
import { FileTypeEnum } from "../../../template/Type/FileTypeEnum";

interface BasePropsInterface {
    projectId: number;
    objectItems: IObjectItem[];
    disabled?: boolean;
    fileType: FileTypeEnum;
}

interface PropsInterface extends BasePropsInterface, WedImageGalleryDataProps {}

interface StateInterface {
    collectionForObjectItem?: ICollectionForObjectItem;
    loadingCollections: boolean;
    showCollectionsMenu: boolean;
    showCreateCollectionPopup: boolean;
}

class ButtonAddToCollection extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            collectionForObjectItem: undefined,
            loadingCollections: false,
            showCollectionsMenu: false,
            showCreateCollectionPopup: false,
        };
        this.toggleDisplayCollectionsMenu = this.toggleDisplayCollectionsMenu.bind(this);
        this.hideDisplayCollectionsMenu = this.hideDisplayCollectionsMenu.bind(this);
        this.showAddToCollectionPopup = this.showAddToCollectionPopup.bind(this);
    }

    private async showAddToCollectionPopup() {
        this.setState({
            showCreateCollectionPopup: true,
        });
    }

    private async toggleDisplayCollectionsMenu() {
        const { objectItems, projectId, fileType } = this.props;
        const { showCollectionsMenu, loadingCollections } = this.state;

        if (!showCollectionsMenu && !loadingCollections) {
            this.setState({ loadingCollections: true });
            this.setState({ loadingCollections: true });

            const loadedCollections = await container.collectionAddFilesService.getCollectionForObjectItems(objectItems, fileType, projectId);

            this.setState({
                collectionForObjectItem: loadedCollections,
                loadingCollections: false,
                showCollectionsMenu: true,
            });
        } else {
            this.hideDisplayCollectionsMenu();
        }
    }

    private hideDisplayCollectionsMenu() {
        this.setState({
            collectionForObjectItem: undefined,
            showCollectionsMenu: false,
        });
    }

    render() {
        return (
            <ClickAwayListener onClickAway={this.hideDisplayCollectionsMenu}>
                <div className="project-files-buttons__collections">
                    <Button
                        disabled={this.props.disabled || this.props.objectItems.length === 0}
                        type="submit"
                        startIcon={
                            this.state.loadingCollections && this.props.objectItems.length > 0 ? (
                                <CircularProgress size={24} />
                            ) : (
                                <WedIcon size={SizeType.SMALL} icon={<WedIconAddCirlcePlus />} />
                            )
                        }
                        onClick={this.toggleDisplayCollectionsMenu}
                    >
                        <FormattedMessage id="app.ProjectFilesButtons.addToCollection" />
                    </Button>
                    <div
                        className={classnames("project-files-buttons__collections-menu", {
                            "project-files-buttons__collections-menu--active": this.state.showCollectionsMenu,
                        })}
                    >
                        {this.state.collectionForObjectItem && (
                            <ListCollectionForObjectItems
                                projectId={this.props.projectId}
                                collections={this.state.collectionForObjectItem}
                                handleHideCollectionMenu={() => {
                                    this.setState({ showCollectionsMenu: false });
                                }}
                                fileType={this.props.fileType}
                            />
                        )}
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        ...(state.wedImageGalleryData || {}),
    };
};

export default connect(mapStateToProps)(ButtonAddToCollection);
