import React from "react";
import WedImageLogo from "./WedImageLogo";

interface PropsInterface {}

interface StateInterface {}

class WedImageLogo40x120 extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <WedImageLogo width={120} height={40} file={"mlogo-40-120.png"} />;
    }
}

export default WedImageLogo40x120;
