import React, { MouseEvent } from "react";
import classNames from "classnames";
import "./WedIcon.scss";

export enum SizeType {
    SMALL = "small",
    NORMAL = "normal",
    LARGE = "large",
    FULL = "full",
}

export enum FunctionIcon {
    PRESENTATION = "presentation",
    ACTION = "action",
}

interface PropsInterface {
    icon: React.ReactElement<React.Component>;
    size?: SizeType;
    className?: string;
    onClick?: (e: MouseEvent) => void;
    functionIcon?: FunctionIcon;
}

export class WedIcon extends React.Component<PropsInterface> {
    private getSizeClassName(): string {
        switch (this.props.size) {
            case SizeType.SMALL:
                return "wed-icon-size-small";
            case SizeType.LARGE:
                return "wed-icon-size-large";
            case SizeType.FULL:
                return "wed-icon-size-full";
        }
        return "wed-icon-size-normal";
    }

    private getFunctionClassName(): string {
        switch (this.props.functionIcon) {
            case FunctionIcon.ACTION:
                return "wed-icon-action";
        }
        return "";
    }

    private getClassesName(): string {
        const sizeClass = this.getSizeClassName();
        const functionClass = this.getFunctionClassName();

        if (functionClass) {
            return `${sizeClass} ${functionClass}`;
        }

        return sizeClass;
    }

    render() {
        return (
            <span onClick={this.props.onClick} className={classNames("wed-icon", this.getClassesName(), this.props.className || null)}>
                <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    {this.props.icon}
                </svg>
            </span>
        );
    }
}
