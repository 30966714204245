import React from "react";
import classnames from "classnames";
import LabelTitleWrapper from "../components/LabelTitleWrapper/LabelTitleWrapper";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Initials, { InitialsUserType } from "../components/Initials/Initials";
import { InitialsAndOwnerInterface } from "../../../services/WedApi/Utils/Initials";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { CommentsAndProofingDocTypeEnum } from "../../../services/WedApi/Models/CommentsAndProofing";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconInteractionTeamworkGroup, WedIconItemsGroupElements } from "../../wed-icon/generated/WedIconSvg";
import ItemDotStatus from "../components/ItemDotStatus/ItemDotStatus";
import { WedImage } from "../../wed-image/WedImage";
import { INoteFile } from "../../../services/WedApi/Models/Note";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import container from "../../../container";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { ItemDotStatusColor } from "../../../services/WedApi/Utils/CollectionDotStatusColor";
import WedDate from "../../formatter/WedDate";
import "../tile.scss";
import "./CommentsAndProofingTile.scss";

interface PropsInterface extends WrappedComponentProps, RouteComponentProps {
    docType: CommentsAndProofingDocTypeEnum;
    name: string;
    initialsAndOwner?: InitialsAndOwnerInterface;
    initialsUserType?: InitialsUserType;
    subName?: string;
    file?: INoteFile;
    text?: string;
    created?: Date;
    link: string;
    approvedBy?: string;
    approvalStatusColor?: ItemDotStatusColor;
    approvedVersion?: number;
}

interface StateInterface {
    active: boolean;
}

class CommentsAndProofingTile extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            active: false,
        };
        this.toggleActive = this.toggleActive.bind(this);
        this.goToLink = this.goToLink.bind(this);
    }

    private goToLink() {
        if (this.props.link) {
            this.props.history.push(this.props.link);
        }
    }

    toggleActive() {
        const currentState = this.state.active;
        this.setState({ active: !currentState });
    }

    render() {
        const {
            docType,
            subName,
            file,
            name,
            initialsAndOwner,
            initialsUserType,
            text,
            created,
            link,
            approvalStatusColor,
            approvedBy,
            approvedVersion,
        } = this.props;
        const { active } = this.state;

        return (
            <div
                className={classnames("tile comments-and-proofing-tile", {
                    "tile--active": active,
                })}
                onClick={this.toggleActive}
                onDoubleClick={this.goToLink}
            >
                <div className="comments-and-proofing-tile__image">
                    {docType === CommentsAndProofingDocTypeEnum.file && file?.extension && isFileHtmlImageExtension(file.extension) ? (
                        <WedImage
                            src={container.filesService.getFileUrl(file.id as number, file.newestVersionId as number, {
                                size: SizeImageEnum.THUMB,
                            })}
                            radiusBottomLeft={true}
                            radiusTopLeft={true}
                            radiusTopRight={true}
                            radiusBottomRight={true}
                        />
                    ) : docType === CommentsAndProofingDocTypeEnum.file && file ? (
                        <div className="comments-and-proofing-tile__image-icon-container tile--content-overlay">
                            <WedIcon icon={<FileTypeIcon extension={file.extension} fileName={name} />} />
                        </div>
                    ) : docType === CommentsAndProofingDocTypeEnum.collection ? (
                        <div className="comments-and-proofing-tile__image-icon-container tile--content-overlay">
                            <WedIcon icon={<WedIconItemsGroupElements />} />
                        </div>
                    ) : docType === CommentsAndProofingDocTypeEnum.project ? (
                        <div className="comments-and-proofing-tile__image-icon-container tile--content-overlay">
                            <WedIcon icon={<WedIconInteractionTeamworkGroup />} />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="comments-and-proofing-tile__title">
                    {docType === "file" ? (
                        <>
                            <LabelTitleWrapper label={this.props.intl.formatMessage({ id: "app.commentsAndProofingList.file" })} title={name} />
                            {subName && (
                                <LabelTitleWrapper
                                    label={this.props.intl.formatMessage({ id: "app.commentsAndProofingList.collection" })}
                                    title={subName}
                                />
                            )}
                        </>
                    ) : docType === "collection" ? (
                        <LabelTitleWrapper label={this.props.intl.formatMessage({ id: "app.commentsAndProofingList.collection" })} title={name} />
                    ) : docType === "project" ? (
                        <LabelTitleWrapper label={this.props.intl.formatMessage({ id: "app.commentsAndProofingList.project" })} title={name} />
                    ) : (
                        ""
                    )}
                </div>
                <div className="comments-and-proofing-tile__created-by">
                    {initialsAndOwner && (
                        <Initials title={initialsAndOwner.initials} fullTitle={initialsAndOwner.owner} userType={initialsUserType} />
                    )}
                </div>
                <div className="comments-and-proofing-tile__description">
                    {approvedBy ? (
                        <div className="comments-and-proofing-tile__description-approved-by">
                            <FormattedMessage id="app.commentsAndProofingTile.approvedVersion" />
                            &nbsp;
                            <span>{approvedVersion ? approvedVersion : ""}</span>
                            <div className="comments-and-proofing-tile__description-approved-by-name">
                                {approvalStatusColor && <ItemDotStatus color={approvalStatusColor} />}
                                {approvalStatusColor === ItemDotStatusColor.REQUESTED ? (
                                    <>
                                        <FormattedMessage id="app.commentsAndProofingTile.text.requested.message" />
                                        &nbsp;
                                        <span>{approvedBy}</span>
                                    </>
                                ) : approvalStatusColor === ItemDotStatusColor.APPROVED ? (
                                    <>
                                        <FormattedMessage id="app.commentsAndProofingTile.text.approved.message" />
                                        &nbsp;
                                        <span>{approvedBy}</span>
                                    </>
                                ) : approvalStatusColor === ItemDotStatusColor.REJECTED ? (
                                    <>
                                        <FormattedMessage id="app.commentsAndProofingTile.text.rejected.message" />
                                        &nbsp;
                                        <span>{approvedBy}</span>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    ) : text ? (
                        <div className="comments-and-proofing-tile__description-texts">{text}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="comments-and-proofing-tile__actions">
                    <div className="comments-and-proofing-tile__actions-date">{created && <WedDate date={created} fullTime={true} />}</div>
                    <div className="comments-and-proofing-tile__actions-link">
                        <Link to={link}>
                            <FormattedMessage id="app.commentsAndProofingTile.linkView" />
                            &nbsp;
                            <span>{name}</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(CommentsAndProofingTile));
