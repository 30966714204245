import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import Collection from "../../../../domain/Collection";
import container from "../../../../container";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import { IProject } from "../../../../services/WedApi/Models/Project";
import { IUserBase } from "../../../../services/WedApi/Models/User";
import SharingTeamMembersCollection, { typeSharingCollection } from "./SharingTeamMembersCollection";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import { uniq } from "lodash";
import "./SharingCollection.scss";

interface StateInterface {
    initialized: boolean;
    loading: boolean;
    users: IUserBase[];
    contacts: IUserBase[];
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: (isUpdate?: boolean) => void;
    collection: Collection;
    project?: IProject;
    onChange: () => void;
}

class SharingCollection extends React.Component<PropsInterface, StateInterface> {
    private baUsersIds: number[] = [];
    private sharedUsersClientIds: number[] = [];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            initialized: false,
            loading: false,
            users: [] as IUserBase[],
            contacts: [] as IUserBase[],
        };
        this.closePopup = this.closePopup.bind(this);
        this.setBaUsersIds = this.setBaUsersIds.bind(this);
        this.setSharedUsersClientIds = this.setSharedUsersClientIds.bind(this);
    }

    async componentDidMount() {
        const [users, contacts] = await Promise.all([
            this.props.project
                ? container.userService.getByIds(uniq([...this.props.project.membersIds, ...this.props.project.ownersIds]))
                : container.userService.getUsers(),
            this.props.project?.ecOrg.id ? container.clientService.getUsersClientByIdClient(this.props.project.ecOrg.id) : [],
        ]);

        this.setState({
            users,
            contacts,
            initialized: true,
        });

        this.baUsersIds = this.props.collection.getBaUsers();
        this.sharedUsersClientIds = this.props.project ? this.props.collection.getSharedTo() : [];
    }

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        try {
            const baUsers: number[] = this.baUsersIds;
            await container.collectionActionApiService.shareCollection(this.props.collection.getId(), baUsers, this.sharedUsersClientIds);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.renameCollection.success.rename" }), { variant: "success" });
            this.props.onChange();
            this.props.close(true);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_COLLECTION_ID_INVALID) {
                keyTranslate = "app.error.errCollectionIDInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_COLLECTION_NAME_INVALID) {
                keyTranslate = "app.error.errCollectionNameInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            this.setState({ loading: false });
        }
    };

    private closePopup() {
        this.props.close();
    }

    private setBaUsersIds(usersIds: number[]) {
        this.baUsersIds = usersIds;
    }

    private setSharedUsersClientIds(sharedUsersClientIds: number[]) {
        this.sharedUsersClientIds = sharedUsersClientIds;
    }

    render() {
        return (
            <div className="sharing-collection">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.sharingCollection.header.title" values={{ collectionName: this.props.collection.getName() }} />
                </PopupHeader>
                {this.state.initialized && (
                    <form onSubmit={this.handleSubmit} noValidate>
                        <SharingTeamMembersCollection
                            selectedMembersIds={this.props.collection.getBaUsers()}
                            items={this.state.users.filter((user) => {
                                const collectionOwnerId = this.props.collection.getCreatedById();
                                if (collectionOwnerId && collectionOwnerId === user.id) {
                                    return false;
                                }
                                return true;
                            })}
                            setFieldsMembers={this.setBaUsersIds}
                            type={typeSharingCollection.MEMBERS}
                            clientId={this.props.project?.ecOrg.id}
                            labelName={"app.sharingCollection.header.titleTeamMembers"}
                        />
                        {this.props.project && (
                            <SharingTeamMembersCollection
                                selectedMembersIds={this.props.collection.getSharedTo()}
                                items={this.state.contacts.filter(contact => (this.props.project?.ecUsersIds || []).includes(contact.id))}
                                setFieldsMembers={this.setSharedUsersClientIds}
                                type={typeSharingCollection.EXTERNAL_MEMBERS}
                                clientId={this.props.project.ecOrg.id}
                                labelName={"app.sharingCollection.header.titleCustomerContacts"}
                            />
                        )}
                        <PopupAction>
                            <Button type="submit" className="button--more-padding" disabled={this.state.loading}>
                                <FormattedMessage id="app.SharingCollection.button.save" />
                            </Button>
                            <Button onClick={this.closePopup} className="button--more-padding">
                                <FormattedMessage id="app.standard.popup.button.cancel" />
                            </Button>
                        </PopupAction>
                    </form>
                )}
            </div>
        );
    }
}

export default withSnackbar(injectIntl(SharingCollection));
