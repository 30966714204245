import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import throttle from "lodash/throttle";
import { ApplicationState } from "../../../store";
import { IUserModeSidebarEnum } from "../../../services/WedApi/Models/User";
import Sidebar, { SidebarType } from "../Sidebar/Sidebar";
import { IProject } from "../../../services/WedApi/Models/Project";
import { FileTypeEnum, isTeamUserFileType } from "../../template/Type/FileTypeEnum";
import { isEditableProject } from "../../../services/WedApi/Utils/IsEditableProject";
import SidebarShare from "../../../pages/share/components/ShareSidebar/SidebarShare";
import { IShareCollection } from "../../../services/WedApi/Models/ShareCollection";
import "./SidebarWrapper.scss";

interface PropsShareSidebar {
    token: string;
    loading: boolean;
    collection?: IShareCollection;
    fileId?: number;
    versionId?: number;
    reducer?: boolean;
}

interface PropsInterface {
    shareSidebar?: PropsShareSidebar;
    disabledNoItemSelected?: boolean;
    children: React.ReactNode | React.ReactNodeArray;
    contextProject?: IProject;
    type?: SidebarType;
    open?: boolean;
    id?: number;
    versionId?: number;
    dirId?: number;
    collectionId?: number;
    fileType?: FileTypeEnum;
    handleSidebarChanges: () => void;
    disabled?: boolean;
}

interface StateInterface {
    hasMinWidth: boolean;
}

const DISPLAY_SIDEBAR_MIN_WIDTH = 1800;
const THROTTLE_TIME = 1000;

class SidebarWrapper extends Component<PropsInterface, StateInterface> {
    private readonly throttledResizeCallback: () => void;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            hasMinWidth: this.checkWidth(),
        };
        this.throttledResizeCallback = throttle(this.handleResize, THROTTLE_TIME);
    }

    componentDidMount() {
        window.addEventListener("resize", this.throttledResizeCallback);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.throttledResizeCallback);
    }

    checkWidth = () => {
        return window.innerWidth >= DISPLAY_SIDEBAR_MIN_WIDTH;
    };

    handleResize = () => {
        this.setState({
            hasMinWidth: this.checkWidth(),
        });
    };

    renderShareSidebar = (shareSidebar: PropsShareSidebar) => {
        const data = {
            collection: shareSidebar?.collection,
            fileId: shareSidebar.fileId || this.props.id,
            versionId: shareSidebar.versionId || this.props.versionId,
        };
        return (
            <SidebarShare
                token={shareSidebar.token}
                data={data}
                loading={!!this.props.shareSidebar?.loading || (!!shareSidebar.reducer && (!this.props.id || !this.props.versionId))}
                handleSidebarChanges={this.props.handleSidebarChanges}
            />
        );
    };

    render() {
        const {
            handleSidebarChanges,
            disabledNoItemSelected,
            open,
            type,
            id,
            versionId,
            dirId,
            collectionId,
            contextProject,
            fileType,
            disabled,
            shareSidebar,
        } = this.props;
        const { hasMinWidth } = this.state;
        const isTeamUserContent = !!fileType && isTeamUserFileType(fileType);
        const displaySidebar = hasMinWidth && (open || false) && !isTeamUserContent;

        return (
            <div className={classNames("sidebar-wrapper", { "sidebar-wrapper--active": displaySidebar })}>
                <div className="sidebar-wrapper__page-content">{this.props.children}</div>
                <div className="sidebar-wrapper__sidebar">
                    {displaySidebar && (
                        <>
                            {shareSidebar ? (
                                this.renderShareSidebar(shareSidebar)
                            ) : (
                                <Sidebar
                                    disabled={disabled}
                                    handleSidebarChanges={handleSidebarChanges}
                                    disabledNoItemSelected={disabledNoItemSelected}
                                    type={type}
                                    id={id}
                                    versionId={versionId}
                                    dirId={dirId}
                                    collectionId={collectionId}
                                    contextProject={contextProject}
                                    fileType={fileType}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: PropsInterface): PropsInterface => {
    let wedImageGalleryDataProps: Partial<PropsInterface> = {};
    if (store.wedImageGalleryData && store.wedImageGalleryData.objectId && store.wedImageGalleryData.dirId) {
        wedImageGalleryDataProps = {
            type: SidebarType.file,
            dirId: store.wedImageGalleryData.dirId,
            id: store.wedImageGalleryData.objectId,
            versionId: store.wedImageGalleryData.versionId,
        };
    } else if (store.wedImageGalleryData && store.wedImageGalleryData.objectId && store.wedImageGalleryData.collectionId) {
        wedImageGalleryDataProps = {
            type: SidebarType.collectionFile,
            id: store.wedImageGalleryData.objectId,
            versionId: store.wedImageGalleryData.versionId,
            collectionId: store.wedImageGalleryData.collectionId,
            disabled: !(
                props.fileType &&
                isEditableProject(props.fileType, props.contextProject) &&
                store.wedImageGalleryData.collectionData &&
                !store.wedImageGalleryData.collectionData.locked
            ),
        };
    }

    return {
        ...props,
        open: store.user.sidebarOpen === IUserModeSidebarEnum.open,
        ...wedImageGalleryDataProps,
    };
};

export default connect(mapStateToProps)(SidebarWrapper);
