import React from "react";
import classNames from "classnames";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import "./SidebarButtonIcon.scss";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@material-ui/core";

interface PropsInterface {
    className?: string;
    tooltipValue?: string;
    disabled?: boolean;
    onClick?: () => void;
    icon: React.ReactElement;
    size?: SizeType;
}

interface StateInterface {}

export class SidebarButtonIcon extends React.Component<PropsInterface, StateInterface> {
    render() {
        const value = (
            <button className={classNames("sidebar-button-icon", this.props.className)} disabled={this.props.disabled} onClick={this.props.onClick}>
                <WedIcon icon={this.props.icon} size={this.props.size || SizeType.SMALL} />
            </button>
        );

        return this.props.tooltipValue ? <Tooltip title={<FormattedMessage id={this.props.tooltipValue} />}>{value}</Tooltip> : <>{value}</>;
    }
}
