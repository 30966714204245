import React from "react";
import { IFile, IFileVersion } from "../../../services/WedApi/Models/File";
import { IProjectExtended, ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import container from "../../../container";
import WedMediaGalleryStrip from "../WedMediaGalleryStrip/WedMediaGalleryStrip";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import WedMediaGalleryMainContent from "../WedMediaGalleryMainContent/WedMediaGalleryMainContent";
import { FileTypeEnum, isTeamUserFileType } from "../../template/Type/FileTypeEnum";
import DataFilesInterface from "../../template/FilesPage/Service/DataFilesByType/DataFilesInterface";
import DataFilesFactory from "../../template/FilesPage/Service/DataFilesByType/DataFilesFactory";
import "./WedMediaGallery.scss";
import { ICollection } from "../../../services/WedApi/Models/Collection";
import { WedImageGalleryDataCollectionProps } from "../../../store/WedImageGalleryReducer";
import { IUserTeamDetail } from "../../../services/WedApi/Models/User";
import { LogicError } from "../../../errors/error-app";
import { RefreshMediaGalleryParams } from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";

export interface WedMediaGalleryProps {
    project?: IProjectExtended;
    collectionId?: number;
    dirId?: number;
    token?: string;
    userTeam?: IUserTeamDetail;
    fileType: FileTypeEnum;
    versionId?: number;
    userTeamType?: boolean;
    onRefreshMediaGallery: (params?: RefreshMediaGalleryParams) => void;
}

interface PropsInterface extends WedMediaGalleryProps, RouteComponentProps {}

interface StateInterface {
    collection?: Pick<ICollection, "locked">;
    files?: IFile[];
    versionId?: number;
    currentFileId?: number;
    collectionData?: WedImageGalleryDataCollectionProps;
}

class WedMediaGallery extends React.Component<PropsInterface, StateInterface> {
    private filesDataService: DataFilesInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.filesDataService = DataFilesFactory.getFileType(this.props.fileType);

        this.state = {
            collection: undefined,
            files: undefined,
            versionId: props.versionId,
            collectionData: undefined,
        };
        this.changeFile = this.changeFile.bind(this);
    }

    private sortApiObjectVersion(versionA: IFileVersion, versionB: IFileVersion): number {
        const timeA: number = versionA.created && versionB.created ? versionA.created.getTime() : 0;
        const timeB: number = versionA.created && versionB.created ? versionB.created.getTime() : 0;
        if (timeA > timeB) {
            return -1;
        }
        if (timeA < timeB) {
            return 1;
        }
        if (versionA.id > versionB.id) {
            return -1;
        }
        if (versionA.id < versionB.id) {
            return 1;
        }
        return 0;
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (
            this.props.collectionId !== prevProps.collectionId ||
            this.props.dirId !== prevProps.dirId ||
            this.props.fileType !== prevProps.fileType ||
            !this.props.project !== !prevProps.project ||
            (this.props.project && prevProps.project && this.props.project.id !== prevProps.project.id)
        ) {
            await this.fetchData();
        }
    }

    private async fetchData(): Promise<void> {
        if (this.props.collectionId) {
            let collectionResult;

            if (this.props.token) {
                collectionResult = await container.shareCollectionService.getCollectionDetails(this.props.token, this.props.collectionId);
            } else {
                collectionResult = await container.collectionService.getCollectionDetail(this.props.collectionId);
            }

            this.setState({
                files: collectionResult.files.map((file) => ({
                    ...file,
                    versions: file.versions.sort(this.sortApiObjectVersion),
                })),
                versionId: this.props.versionId,
                collectionData: { locked: collectionResult.locked },
            });
        } else if (isTeamUserFileType(this.props.fileType)) {
            if (!this.props.userTeam) {
                throw new LogicError(`Component required userTeam for fileType: ${this.props.fileType}`);
            }
            const files =
                this.props.fileType === FileTypeEnum.TEAM_USER_PROJECT_WORK
                    ? await container.userService.getProjectWorks(this.props.userTeam.id)
                    : this.props.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO
                    ? await container.userService.getPrivatePortfolio(this.props.userTeam.id)
                    : [];
            this.setState({
                files: files.map((file) => ({
                    ...file,
                    versions: file.versions.sort(this.sortApiObjectVersion),
                })),
                versionId: this.props.versionId,
            });
        } else {
            const dirId = this.props.dirId || this.props.project?.rootDirectory;
            const files = (await container.filesService.getFolder(this.props.fileType, dirId)).files;
            this.setState({
                files: files.map((file) => ({
                    ...file,
                    versions: file.versions.sort(this.sortApiObjectVersion),
                })),
                versionId: this.props.versionId,
            });
        }
    }

    private changeFile(fileId: number): void {
        this.props.history.push(
            this.filesDataService.openFile({
                projectId: this.props.project?.id,
                collectionId: this.props.collectionId,
                objectId: fileId,
                dirId: this.props.dirId,
                userTeamId: this.props.userTeam?.id,
                token: this.props.token,
            })
        );

        this.setState({ currentFileId: fileId, versionId: undefined });
    }

    render() {
        let isEditable = true;
        if (this.props.project && this.props.project.status !== ProjectStatusEnum.active) {
            isEditable = false;
        }
        if (this.state.collectionData && this.state.collectionData.locked) {
            isEditable = false;
        }

        return (
            <div className="wed-media-gallery">
                <div className="wed-media-gallery__dashboard">
                    <Route
                        exact
                        path={[
                            RoutesEnum.FILES_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.COLLECTION_FILES_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.MY_LIBRARY_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.MY_LIBRARY_SHARED_WITH_ME_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.SHARE_COLLECTION_FILE_MEDIA_GALLERY_DETAILS,
                            RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PROJECT_WORK,
                            RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PRIVATE_PORTFOLIO,
                        ]}
                        component={(route: RouteComponentProps<{ objectId: string }>) => (
                            <WedMediaGalleryMainContent
                                fileType={this.props.fileType}
                                projectId={this.props.project?.id}
                                isEditable={isEditable}
                                collectionId={this.props.collectionId}
                                collectionData={this.state.collectionData}
                                userTeamId={this.props.userTeam?.id}
                                dirId={this.props.dirId}
                                onChangeFile={this.changeFile}
                                fileId={parseInt(route.match.params.objectId)}
                                versionId={this.state.versionId}
                                files={this.state.files}
                                token={this.props.token}
                                onRefreshMediaGallery={this.props.onRefreshMediaGallery}
                                userTeamType={this.props.userTeamType}
                            />
                        )}
                    />
                    <div className="wed-media-gallery__box wed-media-gallery__strip">
                        <WedMediaGalleryStrip
                            fileType={this.props.fileType}
                            currentFileId={this.state.currentFileId}
                            files={this.state.files}
                            onChangeFile={this.changeFile}
                            collectionId={this.props.collectionId}
                            userTeamId={this.props.userTeam?.id}
                            token={this.props.token}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(WedMediaGallery);
