import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { injectIntl, WrappedComponentProps } from "react-intl";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import { ICountry } from "../../../services/WedApi/Models/Country";
import UserBaseCompanyDataForm, {
    CompanyDataForm,
    UserBaseCompanyDataFormType,
} from "../../../components/user/UserBaseCompanyDataForm/UserBaseCompanyDataForm";
import { IOrganizationCompanyData } from "../../../services/WedApi/Models/User";
import container from "../../../container";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    countries: ICountry[];
    companyId: number;
    companyData: IOrganizationCompanyData;
    onFinished: () => void;
}

interface StateInterface {}

class AdminPanelCompanyDataForm extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.submit = this.submit.bind(this);
    }

    private async submit(data: CompanyDataForm): Promise<boolean | undefined> {
        try {
            await container.adminService.updateCompanyData(
                this.props.companyId,
                data.companyName,
                data.companyShortName,
                data.taxId,
                data.street,
                data.street2,
                data.zipCode,
                data.city,
                data.state,
                data.country
            );
            this.props.onFinished();
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_BA_NAME_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.errBANameInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_BA_TAX_ID_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.taxId";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_STREET_ADDRESS_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.street";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_ZIP_CODE_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.zipCode";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_STATE_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.state";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CITY_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.city";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }
    }

    render() {
        return (
            <UserBaseCompanyDataForm
                companyId={this.props.companyId}
                formType={UserBaseCompanyDataFormType.EDITABLE}
                fields={{
                    companyName: this.props.companyData.companyName,
                    companyShortName: this.props.companyData.companyShortName,
                    taxId: this.props.companyData.taxId,
                    street: this.props.companyData.street,
                    street2: this.props.companyData.street2,
                    city: this.props.companyData.city,
                    state: this.props.companyData.state,
                    zipCode: this.props.companyData.zipCode,
                    country: this.props.companyData.country,
                }}
                countries={this.props.countries}
                submit={this.submit}
                canDisabled={true}
            />
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelCompanyDataForm));
