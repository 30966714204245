import * as React from "react";
import "./WedSubMenuContainer.scss";

interface PropsInterface {
    left?: React.ReactElement;
    right?: React.ReactElement;
}

export class WedSubMenuContainer extends React.Component<PropsInterface> {
    render() {
        return (
            <div className="wed-sub-menu-container">
                {this.props.left && <div className="wed-sub-menu-container__left">{this.props.left}</div>}
                {this.props.right && <div className="wed-sub-menu-container__right">{this.props.right}</div>}
            </div>
        );
    }
}
