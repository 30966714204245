import React, { Component } from "react";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { Dispatch } from "redux";
import { setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import FilesPage from "../../../components/template/FilesPage/FilesPage";

interface PropsInterface extends SetPageInfoBoxTitlesActionProps, WrappedComponentProps {
    collectionId: number;
}

interface StateInterface {}

class MyLibraryCollectionFilesPage extends Component<PropsInterface, StateInterface> {
    componentDidMount() {
        this.props.setPageInfoBoxTitlesAction({
            title: this.props.intl.formatMessage({ id: "app.myLibraryMenu.collections" }),
        });
    }

    render() {
        return <FilesPage collectionId={this.props.collectionId} fileType={FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES} />;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(injectIntl(MyLibraryCollectionFilesPage));
