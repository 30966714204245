// eslint-disable-next-line
declare var grecaptcha: any;

interface TokenResponse {
    valid: boolean;
    token?: string;
}

export interface TokenFormData {
    recaptcha_token: string;
    action: string;
}

export class CaptchaService {
    constructor(private readonly siteKey: string | null) {}

    async valid(actionName: string): Promise<TokenResponse> {
        if (!this.siteKey) {
            return { valid: true };
        }

        const captchaAction = new CaptchaActionService(this.siteKey);
        return captchaAction.valid(actionName);
    }
}

class CaptchaActionService {
    constructor(private readonly siteKey: string) {}

    private static async loadScript(siteKey: string): Promise<void> {
        if (document.getElementById("recaptcha-render-script")) {
            return;
        }

        return new Promise((resolve, reject) => {
            const timer = setTimeout(() => {
                reject(new Error("cannot load google recaptcha library"));
            }, 2500);

            const url = `https://www.google.com/recaptcha/api.js?render=${siteKey}&hl=en`;
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = "recaptcha-render-script";
            script.onload = function () {
                try {
                    clearTimeout(timer);
                } catch (e) {
                    //
                }
                resolve();
            };
            document.body.appendChild(script);
        });
    }

    async valid(actionName: string): Promise<TokenResponse> {
        const token = await this.getToken(actionName);
        return {
            valid: true,
            token: token,
        };
    }

    private async getToken(actionName: string): Promise<string> {
        await CaptchaActionService.loadScript(this.siteKey);
        const siteKey = this.siteKey;
        return new Promise((resolve, reject) => {
            grecaptcha.ready(function () {
                // eslint-disable-next-line
                grecaptcha
                    .execute(siteKey, { action: actionName })
                    .then(function (token: string) {
                        resolve(token);
                        grecaptcha.reset();
                    })
                    .catch(reject);
            });
        });
    }
}
