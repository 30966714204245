import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconGridLayout12, WedIconGridLayout6, WedIconInfoInfornation, WedIconTrashDeleteBinSquare } from "../../wed-icon/generated/WedIconSvg";
import { changeSidebarOpen, changeViewMode } from "../../../store/UserReducer";
import { ApplicationState } from "../../../store";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { TrashPopup } from "../../trash/TrashPopup/TrashPopup";
import { IProject } from "../../../services/WedApi/Models/Project";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import DataFilesInterface from "../../template/FilesPage/Service/DataFilesByType/DataFilesInterface";
import DataFilesFactory from "../../template/FilesPage/Service/DataFilesByType/DataFilesFactory";
import "./MenuControlIcons.scss";
import { LogicError } from "../../../errors/error-app";
import { FormattedMessage } from "react-intl";
import { Tooltip } from "@material-ui/core";

interface BasePropsInterface {
    project?: IProject;
    disableChangeViewMode?: boolean;
    noDisplayTrash?: boolean;
    disableSidebarOpen?: boolean;
    onTrashClose?: () => void;
    fileType?: FileTypeEnum;
}

interface PropsInterface extends BasePropsInterface, RouteComponentProps {
    changeViewMode: () => void;
    changeSidebarOpen: () => void;
    isTableMode?: boolean;
}

interface StateInterface {
    trashOpen: boolean;
}

class MenuControlIcons extends Component<PropsInterface, StateInterface> {
    private filesDataService?: DataFilesInterface;

    constructor(props: PropsInterface) {
        super(props);
        if (!this.props.noDisplayTrash && !this.props.fileType) {
            throw new LogicError("props.fileType is required.");
        }
        if (this.props.fileType) {
            this.filesDataService = DataFilesFactory.getFileType(this.props.fileType);
        }
        this.state = {
            trashOpen: false,
        };
        this.closeTrash = this.closeTrash.bind(this);
    }

    private closeTrash() {
        this.props.onTrashClose && this.props.onTrashClose();
        this.setState({ trashOpen: false });
    }

    render() {
        return (
            <div className="project-control-icons">
                <ul>
                    {this.filesDataService && !this.props.noDisplayTrash && (
                        <li>
                            <Tooltip title={<FormattedMessage id={"app.ico.trash.title"} />}>
                                <span>
                                    <button
                                        className="wed-icon-button"
                                        disabled={!this.filesDataService.showTrash(this.props.project)}
                                        onClick={() => {
                                            this.setState({ trashOpen: true });
                                        }}
                                    >
                                        <WedIcon icon={<WedIconTrashDeleteBinSquare />} />
                                    </button>
                                </span>
                            </Tooltip>
                        </li>
                    )}
                    <li>
                        <Tooltip title={<FormattedMessage id={this.props.isTableMode ? "app.ico.cardsView.title" : "app.ico.tableView.title"} />}>
                            <span>
                                <button disabled={this.props.disableChangeViewMode} className="wed-icon-button" onClick={this.props.changeViewMode}>
                                    <WedIcon icon={this.props.isTableMode ? <WedIconGridLayout12 /> : <WedIconGridLayout6 />} />
                                </button>
                            </span>
                        </Tooltip>
                    </li>
                    <li className="project-control-icons__big-width-screen">
                        <Tooltip title={<FormattedMessage id={"app.ico.sidebar.title"} />}>
                            <span>
                                <button disabled={this.props.disableSidebarOpen} className="wed-icon-button" onClick={this.props.changeSidebarOpen}>
                                    <WedIcon icon={<WedIconInfoInfornation />} />
                                </button>
                            </span>
                        </Tooltip>
                    </li>
                </ul>
                <TrashPopup
                    open={this.state.trashOpen}
                    handleClose={this.closeTrash}
                    project={this.props.project}
                    fileType={this.props.fileType || FileTypeEnum.PROJECT_FILES}
                />
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface): Partial<PropsInterface> => {
    return {
        ...props,
        isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        changeSidebarOpen: changeSidebarOpen(dispatch),
        changeViewMode: changeViewMode(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuControlIcons));
