import React, { Component } from "react";
import { UserTeamDetailLoaderProps } from "../../../components/user-team/UserTeamDetailLoader/UserTeamDetailLoader";
import { ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import ListProjectPage from "../../../components/template/ListProjectPage/ListProjectPage";

interface PropsInterface extends UserTeamDetailLoaderProps {
    projectStatus: ProjectStatusEnum;
}

interface StateInterface {}

class UserTeamUserProjectsPage extends Component<PropsInterface, StateInterface> {
    render() {
        return <ListProjectPage contextUserTeam={{ userTeam: this.props.userTeam, projectStatus: this.props.projectStatus }} />;
    }
}

export default UserTeamUserProjectsPage;
