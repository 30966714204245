import React, { Component } from "react";
import Switch from "react-switch";
import classNames from "classnames";
import "./AdminPanelSwitchButton.scss";

interface PropsInterface {
    className?: string;
    disabled?: boolean;
    checked: boolean;
    onChange: (status: boolean) => void;
}

interface StateInterface {}

class AdminPanelSwitchButton extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className={classNames("admin-panel-switch-button", this.props.className)}>
                <Switch
                    onColor={"#3c5f75"}
                    height={22}
                    width={44}
                    disabled={this.props.disabled}
                    onChange={this.props.onChange}
                    checked={this.props.checked}
                />
            </div>
        );
    }
}

export default AdminPanelSwitchButton;
