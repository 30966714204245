import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import _size from "lodash/size";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import container from "../../../../container";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import JSONSchemaValidator, { ValidationErrors } from "../../../../services/JSONSchemaValidator/JSONSchemaValidator";
import { IProject } from "../../../../services/WedApi/Models/Project";

interface StateInterface {
    loading: boolean;
    fieldProjectDescription: string;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: (isUpdate?: boolean) => void;
    project: IProject;
}

class ChangeDescriptionProject extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            fieldProjectDescription: {
                type: "string",
                maxLength: 500,
            },
        },
        required: ["fieldProjectDescription"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                fieldProjectDescription: this.props.intl.formatMessage({ id: "app.createProject.error.projectDescription" }),
            },
        },
    };

    readonly state = {
        loading: false,
        fieldProjectDescription: "",
    };

    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        this.setState({ fieldProjectDescription: this.props.project.desc });
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState({ fieldProjectDescription: value });
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ fieldProjectDescription: this.state.fieldProjectDescription }, this.schema);

        return errors;
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            await container.projectService.changeProjectDescription(this.props.project.id, this.state.fieldProjectDescription);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.changeDescriptionProject.success.rename" }), {
                variant: "success",
            });
            this.props.close(true);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_ID_INVALID) {
                keyTranslate = "app.error.errProjectIDInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_DESCRIPTION_INVALID) {
                keyTranslate = "app.error.errProjectDescriptionInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            this.setState({ loading: false });
        }
    };

    private closePopup() {
        this.props.close();
    }

    render() {
        return (
            <div className="change-description-project">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.changeDescriptionProject.header.title" />
                </PopupHeader>
                <form onSubmit={this.handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="changeDescriptionProject"
                        label={<FormattedMessage id={"app.createProject.form.projectDescription"} />}
                        name="changeDescription"
                        autoComplete="off"
                        value={this.state.fieldProjectDescription}
                        onChange={this.handleChange}
                        multiline
                        rows={5}
                    />
                    <PopupAction>
                        <Button type="submit" className="button--more-padding" disabled={this.state.loading}>
                            <FormattedMessage id="app.standard.popup.button.save" />
                        </Button>
                        <Button onClick={this.closePopup} className="button--more-padding">
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(ChangeDescriptionProject));
