import classnames from "classnames";
import { WedIconShare2 } from "../wed-icon/generated/WedIconSvg";
import { SizeType, WedIcon } from "../wed-icon/WedIcon";
import React from "react";
import Collection from "../../domain/Collection";
import "./CollectionShareIcon.scss";

interface PropsInterface {
    collection: Collection;
    size?: SizeType;
}

interface StateInterface {}

export class CollectionShareIcon extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { collection, size } = this.props;

        return (
            <WedIcon
                className={classnames(
                    "collection_share-icon",
                    { "collection_share-icon__share-icon-shared-external": collection.isSharingContactsUsers() },
                    {
                        "collection_share-icon__share-icon-shared-internal":
                            !collection.isSharingContactsUsers() && collection.isSharingBackendUsers(),
                    }
                )}
                icon={<WedIconShare2 />}
                size={size}
            />
        );
    }
}
