import React, { ReactElement } from "react";
import classnames from "classnames";
import _ from "lodash";
import Title from "../components/Title/Title";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import FilesCountIcon from "../../count-icon/FilesCountIcon/FilesCountIcon";
import InternalTeamCountIcon from "../../count-icon/InternalTeamCountIcon/InternalTeamCountIcon";
import CommentsCountIcon from "../../count-icon/CommentsCountIcon/CommentsCountIcon";
import WedDate from "../../formatter/WedDate";
import { WedIcon } from "../../wed-icon/WedIcon";
import ExternalTeamCountIcon from "../../count-icon/ExternalTeamCountIcon/ExternalTeamCountIcon";
import { WedIconLock3 } from "../../wed-icon/generated/WedIconSvg";
import ItemDotStatus from "../components/ItemDotStatus/ItemDotStatus";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import Collection from "../../../domain/Collection";
import { CollectionShareIcon } from "../../collection/CollectionShareIcon";
import CollectionPreviewMedia from "../components/CollectionPreviewMedia/CollectionPreviewMedia";
import "./CollectionTile.scss";
import "../tile.scss";
import { CollectionRouteGenerator } from "../../template/CollectionPage/helper/CollectionRouteGenerator";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    showCollectionDotStatusColor,
} from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends RouteComponentProps, WrappedComponentProps {
    collection: Collection;
    selected: boolean;
    onClick: () => void;
    actionButton?: ReactElement;
    projectId?: number;
    token?: string;
    fileType: FileTypeEnum;
}

interface StateInterface {
    previewImages: string[];
    isHover: boolean;
}

class CollectionTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["onClick", "key", "get", "actionButton"];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            previewImages: [],
            isHover: false,
        };
        this.goToLink = this.goToLink.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick() {
        this.props.onClick();
    }

    private getLink(collectionId: number): string {
        if (this.props.token) {
            return `${generatePath(RoutesEnum.SHARE_COLLECTION_FILES, {
                collectionId: this.props.collection.getId(),
            })}?token=${this.props.token}`;
        }
        return CollectionRouteGenerator.getUrl(this.props.fileType, { collectionId, projectId: this.props.projectId });
    }

    private goToLink() {
        this.props.history.push(this.getLink(this.props.collection.getId()));
    }

    render() {
        const { collection, selected, actionButton, token, fileType } = this.props;
        const date = collection.getCreatedDate();

        return (
            <div
                className={classnames("tile collection-tile", {
                    "tile--active": selected,
                })}
                onMouseEnter={() => {
                    this.setState({ isHover: true });
                }}
            >
                {this.state.isHover && actionButton}
                <div className="tile-container" onClick={this.onClick} onDoubleClick={this.goToLink}>
                    <CollectionPreviewMedia
                        urls={collection.getPreviewTile({
                            shareToken: token,
                            collectionId: collection.getId(),
                            fileType,
                        })}
                    />
                    <div className="collection-tile__title">
                        <Title
                            icon={collection.isLocked() ? <WedIcon className="collection-tile__locked-icon" icon={<WedIconLock3 />} /> : undefined}
                            title={collection.getName()}
                        />
                    </div>
                    <div className="collection-tile__details">
                        {showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, collection.getData()) && (
                            <ItemDotStatus color={getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, collection.getData())} />
                        )}
                        <FormattedMessage id={"app.tile.created"} />
                        <span className="collection-tile__user">{date && <WedDate date={date} />}</span>
                        <FormattedMessage id={"app.global.by"} />
                        <span className="collection-tile__date">{collection.getAuthor()}</span>
                    </div>
                    {!token && (
                        <div className="collection-tile__share">
                            <CollectionShareIcon collection={collection} />
                        </div>
                    )}
                    <div className="collection-tile__footer">
                        <CommentsCountIcon count={collection.getPublicNotesCount()} />
                        <InternalTeamCountIcon count={collection.getBaUsersCount()} />
                        <ExternalTeamCountIcon count={collection.getSharedToCount()} />
                        <FilesCountIcon count={collection.getFilesCount()} />
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(withRouter(CollectionTile));
