import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import SidebarInformationFiles from "../../../components/sidebar/SidebarInformationFiles/SidebarInformationFiles";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import { ICollection } from "../../../services/WedApi/Models/Collection";
import SidebarInitials from "../../../components/sidebar/SidebarInitials/SidebarInitials";
import { ChangeDescriptionCollectionPopup } from "../../../components/project/Collection/ChangeDescriptionCollectionPopup/ChangeDescriptionCollectionPopup";
import Collection from "../../../domain/Collection";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import { WedIconItemsGroupElements, WedIconLock3 } from "../../../components/wed-icon/generated/WedIconSvg";
import { CollectionShareIcon } from "../../../components/collection/CollectionShareIcon";
import { SizeType } from "../../../components/wed-icon/WedIcon";
import WedDate from "../../../components/formatter/WedDate";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { INote, NoteObjectType } from "../../../services/WedApi/Models/Note";
import CommentBox from "../../../components/comments/CommentBox/CommentBox";
import { IActivitiesObjectType } from "../../../services/WedApi/Services/ActivitiesService";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    showCollectionDotStatusColor,
} from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends WrappedComponentProps {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    collectionId: number;
    clientId?: number;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    collection: ICollection | undefined;
    showChangeDescriptionPopup: boolean;
    notes: INote[] | undefined;
    activities: IActivities[];
}

class CollectionSidebar extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            collection: undefined,
            showChangeDescriptionPopup: false,
            notes: undefined,
            activities: [],
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>, prevState: Readonly<StateInterface>) {
        const withLoading = prevState.showChangeDescriptionPopup == this.state.showChangeDescriptionPopup;

        await this.fetchData(withLoading);
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.collectionId != this.props.collectionId ||
            nextState?.collection?.id != this.state?.collection?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup
        );
    }

    fetchData = async (withLoadingCallback = true) => {
        const { collectionId, loadingCallback, clientId } = this.props;
        const { collection, showChangeDescriptionPopup } = this.state;

        if (!(collection?.id != collectionId || showChangeDescriptionPopup)) {
            return;
        }

        try {
            if (withLoadingCallback) loadingCallback(true);

            const [collection, notes, activities] = await Promise.all([
                container.collectionService.getCollectionDetailForSidebar(collectionId, clientId),
                container.noteService.getCollectionNotes(collectionId, clientId),
                container.activitiesService.getActivities(IActivitiesObjectType.COLLECTION, collectionId),
            ]);

            this.setState({ collection, notes, activities }, () => {
                if (withLoadingCallback && collection.id == this.props.collectionId) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ collection: undefined, notes: undefined });
        }
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            this.props.handleSidebarChanges();
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    render() {
        if (!this.state.collection || this.state.collection.id != this.props.collectionId) {
            return null;
        }

        const { intl, disabled, clientId } = this.props;
        const { collection, showChangeDescriptionPopup } = this.state;

        return (
            <div className="collection-sidebar sidebar--container">
                <SidebarTitle title={collection.name} icon={collection?.locked ? <WedIconLock3 /> : undefined} />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarInformationFiles
                                statusWedIcon={<CollectionShareIcon collection={new Collection(collection)} size={SizeType.LARGE} />}
                                icon={<WedIconItemsGroupElements />}
                                filesCount={collection.files.length}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: collection.created ? <WedDate date={collection.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${collection.createdBy?.firstName} ${collection.createdBy?.lastName}` },
                                        ],
                                    },
                                    ...(collection?.updatedBy && collection.updated
                                        ? [
                                              {
                                                  label: intl.formatMessage({ id: "app.sidebar.details.updated" }),
                                                  values: [
                                                      { text: <WedDate date={collection.updated} /> },
                                                      {
                                                          text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                          labelFormat: true,
                                                      },
                                                      { text: `${collection.updatedBy.firstName} ${collection.updatedBy.lastName}` },
                                                  ],
                                              },
                                          ]
                                        : []),
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.documents" }),
                                        values: [{ text: collection.files.length.toString() }],
                                    },
                                    ...(showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, collection)
                                        ? [
                                              {
                                                  label: intl.formatMessage({ id: "app.sidebar.details.approval" }),
                                                  values: [
                                                      {
                                                          text: (
                                                              <ItemDotStatus
                                                                  color={getCollectionDotStatusColor(
                                                                      CollectionDotStatusColorItemType.COLLECTION,
                                                                      collection
                                                                  )}
                                                              />
                                                          ),
                                                      },
                                                  ],
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                            <SidebarDescription
                                allowToAdd={!disabled && collection.userIsOwner}
                                description={collection.description}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            <SidebarInitials
                                title={intl.formatMessage({ id: "app.sidebar.sharedWith.title" })}
                                items={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.shared.baUsers" }),
                                        userType: InitialsUserType.INTERNAL,
                                        data: (collection.baUsersItems || []).map((user) => {
                                            const userInitialName = getInitialsAndOwner(user);
                                            return {
                                                initials: userInitialName.initials,
                                                fullName: userInitialName.owner,
                                            };
                                        }),
                                    },

                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.shared.sharedTo" }),
                                        userType: InitialsUserType.EXTERNAL,
                                        data: (collection.sharedToItems || []).map((user) => {
                                            const userInitialName = getInitialsAndOwner(user);
                                            return {
                                                initials: userInitialName.initials,
                                                fullName: userInitialName.owner,
                                            };
                                        }),
                                    },
                                ]}
                            />
                            <CommentBox
                                disabled={disabled}
                                object={{
                                    id: collection.id,
                                    type: NoteObjectType.collection,
                                    data: { clientId: clientId || 0 },
                                }}
                                notes={this.state.notes || []}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={this.state.activities} />
                        </>,
                    ]}
                />
                <ChangeDescriptionCollectionPopup
                    open={showChangeDescriptionPopup}
                    handleClose={this.onCloseChangeDescriptionPopup}
                    collection={new Collection(collection)}
                />
            </div>
        );
    }
}

export default injectIntl(CollectionSidebar);
