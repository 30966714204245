import ShareCollectionActionApiService from "../ShareCollectionActionApiService";
import { IShareCollection } from "../Models/ShareCollection";
import { IAddFileNoteOptions, IAddNoteOptions } from "./NoteService";
import { IActivities } from "../Models/Activities";
import { ActivitiesService } from "./ActivitiesService";

export class ShareCollectionService {
    constructor(private readonly shareCollectionActionApiService: ShareCollectionActionApiService) {}

    public async getCollectionDetails(token: string, id: number): Promise<IShareCollection> {
        return this.shareCollectionActionApiService.getCollectionDetails(token, id);
    }

    public async getCollectionList(token: string): Promise<IShareCollection[]> {
        return this.shareCollectionActionApiService.getCollectionList(token);
    }

    public async addCollectionFileNote(
        token: string,
        objectId: number,
        versionId: number,
        collectionId: number,
        content: string,
        options?: IAddFileNoteOptions
    ): Promise<void> {
        return this.shareCollectionActionApiService.addNote(token, collectionId, objectId, versionId, options?.parentNodeId || 0, content, {
            annotation: options?.annotation,
        });
    }

    public async addCollectionNote(token: string, collectionId: number, content: string, options?: IAddNoteOptions): Promise<void> {
        return this.shareCollectionActionApiService.addNote(token, collectionId, 0, 0, options?.parentNodeId || 0, content);
    }

    public async makeDecision(token: string, collectionId: number, objectId: number, versionId: number, approved: boolean) {
        await this.shareCollectionActionApiService.makeDecision(token, collectionId, objectId, versionId, approved);
    }

    public getDownloadCollectionUrl(token: string, collectionId: number): string {
        return this.shareCollectionActionApiService.getDownloadCollectionUrl(token, collectionId);
    }

    public async getCollectionActivities(token: string, collectionId: number): Promise<IActivities[]> {
        const listResult = await ActivitiesService.convertListResultTime(
            this.shareCollectionActionApiService.getCollectionActivities(token, collectionId)
        );
        return listResult.map(ActivitiesService.convertCollection);
    }

    public async getCollectionFileActivities(token: string, objectId: number, collectionId: number): Promise<IActivities[]> {
        const listResult = await ActivitiesService.convertListResultTime(
            this.shareCollectionActionApiService.getCollectionFileActivities(token, objectId, collectionId)
        );
        return listResult.map(ActivitiesService.convertCollectionFile);
    }
}
