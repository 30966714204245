import { PropsInterface } from "../../../../FilesPage/FilesPage";
import { getQueryIntParam } from "../../../../../../services/WedApi/Utils/QueryParameter";
import { parse } from "query-string";
import { IFolder } from "../../../../../../services/WedApi/Models/File";
import container from "../../../../../../container";
import FetchDataReturnInterface from "./FetchDataReturnInterface";
import DataFilesInterface from "../DataFilesInterface";
import OpenFileParamsInterface from "./ParamsInterface/OpenFileParamsInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import { generatePath } from "react-router-dom";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";
import { BreadcrumbItem } from "../../../../../breadcrumbs/Breadcrumbs";
import { SizeType, WedIcon } from "../../../../../wed-icon/WedIcon";
import { WedIconHomeMenuMore } from "../../../../../wed-icon/generated/WedIconSvg";
import React from "react";
import BreadcrumbsParamsInterface from "./ParamsInterface/BreadcrumbsParamsInterface";
import { IProject, IProjectExtended } from "../../../../../../services/WedApi/Models/Project";
import OpenSectionParamsInterface from "./ParamsInterface/OpenSectionParamsInterface";
import { isEditableProject } from "../../../../../../services/WedApi/Utils/IsEditableProject";
import { LogicError } from "../../../../../../errors/error-app";

export default class ProjectFilesData implements DataFilesInterface {
    async fetch(props: PropsInterface): Promise<FetchDataReturnInterface> {
        const dirId = getQueryIntParam(parse(props.location.search), "dir") || props.project?.rootDirectory || 0;

        return this.getFolder(dirId, props.userId as number);
    }

    // eslint-disable-next-line
    async getFolder(dirId: number, userId: number): Promise<FetchDataReturnInterface> {
        const folder: IFolder = await container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, dirId);

        return {
            isLoading: false,
            directories: folder.directories,
            files: folder.files,
            parentFolder: folder.directory,
            collection: undefined,
            dir: {
                id: dirId,
                folder: folder,
            },
        };
    }

    didUpdate(prevProps: PropsInterface, currentProps: PropsInterface): boolean {
        if (prevProps.location.search !== currentProps.location.search) {
            const prevDir = getQueryIntParam(parse(prevProps.location.search), "dir");
            const dirId = getQueryIntParam(parse(currentProps.location.search), "dir");
            if ((!prevDir && dirId) || (prevDir && prevDir !== dirId)) {
                return true;
            }
        }

        return false;
    }

    openFile(params: OpenFileParamsInterface): string {
        if (!params.projectId) {
            throw new LogicError(`projectId is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }

        return `${generatePath(RoutesEnum.FILES_MEDIA_GALLERY_DETAILS, {
            projectId: params.projectId,
            objectId: params.objectId,
        })}${params.dirId ? `?dir=${params.dirId}` : ""}`;
    }

    breadcrumbs(breadcrumbsParams: BreadcrumbsParamsInterface): BreadcrumbItem[] {
        if (!breadcrumbsParams.project) {
            throw new LogicError(`project is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }
        if (!breadcrumbsParams.dir) {
            throw new LogicError(`dir is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }

        const projectId = breadcrumbsParams.project.id;
        const folder = breadcrumbsParams.dir.folder;
        const paths = folder.directory.paths;
        return paths.map((path, i) => {
            return {
                id: path.id,
                title: i === 0 ? undefined : path.name,
                children: i === 0 ? <WedIcon size={SizeType.SMALL} icon={<WedIconHomeMenuMore />} /> : undefined,
                link: i === 0 ? `/projects/${projectId}/files` : `/projects/${projectId}/files?dir=${path.id}`,
                subItems:
                    paths[i + 1] && paths[i + 1].nexts?.length > 0
                        ? paths[i + 1].nexts
                              .filter((subItem) => !paths.find((path) => path.id === subItem.id))
                              .map((subItem) => {
                                  return {
                                      id: subItem.id,
                                      label: subItem.name,
                                      link: generatePath(RoutesEnum.FILES, { projectId: projectId }) + `?dir=${subItem.id}`,
                                  };
                              })
                        : folder.directories.map((directory) => {
                              return {
                                  id: directory.id,
                                  label: directory.name,
                                  link: generatePath(RoutesEnum.FILES, { projectId: projectId }) + `?dir=${directory.id}`,
                              };
                          }),
            };
        });
    }

    openSection(params: OpenSectionParamsInterface): string {
        if (!params.projectId) {
            throw new LogicError(`projectId is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }

        return `${generatePath(RoutesEnum.FILES, { projectId: params.projectId })}${params.dirId ? `?dir=${params.dirId}` : ""}`;
    }

    getRootDirName(project?: IProjectExtended): string {
        if (!project) {
            throw new LogicError(`project is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }

        return project.name;
    }

    showTrash(project?: IProject): boolean {
        return isEditableProject(FileTypeEnum.PROJECT_FILES, project);
    }
}
