import { WedTable } from "../../../../components/wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import React, { Component } from "react";
import classNames from "classnames";
import "./AdminPanelTable.scss";

type RowPropsInterface = Array<string | React.ReactElement | undefined>;

interface PropsInterface {
    headers: Array<string | undefined>;
    rows: Array<RowPropsInterface>;
    centerIdx?: number[];
}

export class AdminPanelTable extends Component<PropsInterface> {
    private renderRow(row: RowPropsInterface, key: number) {
        return (
            <TableRow key={key}>
                {row.map((data, j) => (
                    <TableCell className={classNames({ "admin-panel-table--center": this.props.centerIdx?.includes(j) })} key={j}>
                        {data}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    render() {
        return (
            <div className="admin-panel-table">
                <WedTable>
                    <TableHead>
                        <TableRow>
                            {this.props.headers.map((label, i) => (
                                <TableCell key={i} className={classNames({ "admin-panel-table--center": this.props.centerIdx?.includes(i) })}>
                                    {label ? <FormattedMessage id={label} /> : ""}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>{this.props.rows.map((row, i) => this.renderRow(row, i))}</TableBody>
                </WedTable>
            </div>
        );
    }
}
