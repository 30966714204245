import React, { Component } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import MyLibraryFilesPage from "../../../pages/my-library/MyLibraryFilesPage/MyLibraryFilesPage";
import FilesMediaGalleryPage from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import LayoutPage from "../../layout/LayoutPage/LayoutPage";
import Redirect404 from "../../error/Redirect404/Redirect404";
import MyLibrarySharedWithMePage from "../../../pages/my-library/MyLibrarySharedWithMePage/MyLibrarySharedWithMePage";
import MyLibraryCollectionPage from "../../../pages/my-library/MyLibraryCollectionPage/MyLibraryCollectionPage";
import MyLibraryCollectionFilesPage from "../../../pages/my-library/MyLibraryCollectionFilesPage/MyLibraryCollectionFilesPage";

interface PropsInterface {}

interface StateInterface {}

class MyLibraryDetailLoader extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            project: undefined,
        };
    }

    render() {
        return (
            <LayoutPage>
                <Switch>
                    <Route exact path={RoutesEnum.MY_LIBRARY_FILES} component={MyLibraryFilesPage} />
                    <Route exact path={RoutesEnum.MY_LIBRARY_SHARED_WITH_ME} component={MyLibrarySharedWithMePage} />
                    <Route exact path={RoutesEnum.MY_LIBRARY_COLLECTIONS} component={MyLibraryCollectionPage} />
                    <Route
                        exact
                        path={RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES}
                        component={(route: RouteComponentProps<{ collectionId: string }>) => {
                            return <MyLibraryCollectionFilesPage collectionId={parseInt(route.match.params.collectionId)} />;
                        }}
                    />
                    <Route
                        path={RoutesEnum.MY_LIBRARY_MEDIA_GALLERY_DETAILS}
                        component={() => {
                            return <FilesMediaGalleryPage fileType={FileTypeEnum.MY_LIBRARY_FILES} />;
                        }}
                    />
                    <Route
                        path={RoutesEnum.MY_LIBRARY_SHARED_WITH_ME_MEDIA_GALLERY_DETAILS}
                        component={() => {
                            return <FilesMediaGalleryPage fileType={FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME} />;
                        }}
                    />
                    <Route
                        path={RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES_MEDIA_GALLERY_DETAILS}
                        component={(route: RouteComponentProps<{ collectionId: string }>) => {
                            return (
                                <FilesMediaGalleryPage
                                    fileType={FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}
                                    collectionId={parseInt(route.match.params.collectionId)}
                                />
                            );
                        }}
                    />
                    <Route component={Redirect404} />
                </Switch>
            </LayoutPage>
        );
    }
}

export default MyLibraryDetailLoader;
