import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent, { MarginSizeType } from "../../popup/PopupContent/PopupContent";
import AddFileToData from "../AddFileToData/AddFileToData";
import { IProjectExtended } from "../../../services/WedApi/Models/Project";
import "./AddFileToDataPopup.scss";

interface PropsInterface {
    open: boolean;
    handleClose: (updated?: boolean) => void;
    contextCollection?: {
        collectionId: number;
        project?: IProjectExtended;
    };
    contextMyPrivatePortfolio?: { active: boolean };
}

interface StateInterface {}

export class AddFileToDataPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup
                open={this.props.open}
                handleClose={() => {
                    this.props.handleClose();
                }}
                maxWidth={"xl"}
                className="add-file-to-data-popup"
            >
                <PopupContent marginSize={MarginSizeType.SMALL} withBackground={true}>
                    <AddFileToData
                        contextCollection={this.props.contextCollection}
                        contextMyPrivatePortfolio={this.props.contextMyPrivatePortfolio}
                        handleClose={this.props.handleClose}
                    />
                </PopupContent>
            </Popup>
        );
    }
}
