import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IClientContact } from "../../../services/WedApi/Models/ClientContact";
import container from "../../../container";
import { ICountry } from "../../../services/WedApi/Models/Country";
import { TextField } from "@material-ui/core";
import "./EditClientAccountPopup.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IClient } from "../../../services/WedApi/Models/Client";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import { ICreateUpdateClientCustomerContact } from "../../../services/WedApi/Services/ClientContactService";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconSingleUserSquare } from "../../wed-icon/generated/WedIconSvg";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    position: string;
    country: ICountry | null;
    phone: string;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    client: IClient;
    clientContact: IClientContact | undefined;
    onClickClose: (updated?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
    countries: ICountry[];
    formValues: FormValues;
}

class EditClientAccountPopup extends React.Component<PropsInterface, StateInterface> {
    fieldFormCountry: React.RefObject<HTMLInputElement>;

    schema = {
        type: "object",
        properties: {
            firstName: {
                type: "string",
                minLength: 1,
                maxLength: 50,
            },
            lastName: {
                type: "string",
                minLength: 1,
                maxLength: 50,
            },
            email: {
                type: "string",
                maxLength: 100,
            },
            position: {
                type: "string",
                maxLength: 50,
            },
            country: {
                type: "object",
            },
            phone: {
                type: "string",
                maxLength: 20,
            },
        },
        required: ["firstName", "lastName", "email"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                firstName: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.firstName" }),
                lastName: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.lastName" }),
                email: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.email" }),
                position: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.position" }),
                country: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.country" }),
                phone: this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.error.phone" }),
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            countries: [],
            formValues: {
                firstName: "",
                lastName: "",
                email: "",
                position: "",
                country: null,
                phone: "",
            },
        };
        this.fieldFormCountry = React.createRef();
        this.preDoIt = this.preDoIt.bind(this);
        this.doIt = this.doIt.bind(this);
    }

    private validate(): { valid: boolean; formErrors: ValidationErrors } {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    }

    private validateForm(): ValidationErrors {
        const { errors } = JSONSchemaValidator.validate(
            {
                ...this.state.formValues,
                country: this.state.formValues || undefined,
            },
            this.schema
        );

        return errors;
    }

    async componentDidMount() {
        const countries = await container.countryService.getCountries();
        this.setState({
            loading: false,
            countries: countries,
            formValues: {
                firstName: this.props.clientContact?.firstName || "",
                lastName: this.props.clientContact?.lastName || "",
                email: this.props.clientContact?.email || "",
                position: this.props.clientContact?.position || "",
                country:
                    (this.props.clientContact?.cCode
                        ? countries.find((country) => country.countryPhoneCode === this.props.clientContact?.cCode?.toString())
                        : undefined) || null,
                phone: this.props.clientContact?.phone || "",
            },
        });
        if (this.fieldFormCountry && this.fieldFormCountry.current) {
            const inputs = this.fieldFormCountry.current.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].autocomplete = getRandomString();
            }
        }
    }

    // eslint-disable-next-line
    private changeValue(name: string, value: any) {
        if (Object.keys(this.state.formValues).includes(name)) {
            this.setState({
                formValues: {
                    ...this.state.formValues,
                    [name]: value,
                },
            });
        }
    }

    private preDoIt() {
        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return false;
        }
    }

    private getFormValues(): ICreateUpdateClientCustomerContact {
        return {
            firstName: this.state.formValues.firstName,
            lastName: this.state.formValues.lastName,
            email: this.state.formValues.email,
            position: this.state.formValues.position || undefined,
            countryPhoneCode: this.state.formValues.country?.countryPhoneCode
                ? // eslint-disable-next-line
                  (parseInt(this.state.formValues.country.countryPhoneCode) as any)
                : undefined,
            phone: this.state.formValues.phone || undefined,
        };
    }

    private async doIt() {
        try {
            if (this.props.clientContact) {
                await container.clientContactService.updateClientCustomerContact(this.props.clientContact.id, this.getFormValues());
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.success.updated" }), {
                    variant: "success",
                });
            } else {
                await container.clientContactService.createClientCustomerContact(this.props.client.id, this.getFormValues());
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.EditClientAccountPopup.success.created" }), {
                    variant: "success",
                });
            }
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_NAME_INVALID) {
                keyTranslate = "app.EditClientAccountPopup.error.lastName";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_PHONE_INVALID) {
                keyTranslate = "app.EditClientAccountPopup.error.phone";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_COUNTRY_CODE_INVALID) {
                keyTranslate = "app.EditClientAccountPopup.error.country";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_EMAIL_INVALID) {
                keyTranslate = "app.EditClientAccountPopup.error.email";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_EMAIL_USED) {
                keyTranslate = "app.EditClientAccountPopup.error.emailUsed";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CLIENT_ACCOUNT_POSITION_INVALID) {
                keyTranslate = "app.EditClientAccountPopup.error.position";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }
    }

    render() {
        return (
            <PopupConfirmButton
                maxWidth="lg"
                open={true}
                close={this.props.onClickClose}
                className={"edit-client-account-popup"}
                preDoIt={this.preDoIt}
                doIt={this.doIt}
                confirmButtonLabel={
                    <FormattedMessage
                        id={this.props.clientContact ? "app.EditClientAccountPopup.button.save" : "app.EditClientAccountPopup.button.create"}
                    />
                }
                noDisplayCancel={true}
                header={
                    <FormattedMessage
                        id={
                            this.props.clientContact ? "app.EditClientAccountPopup.header.editTitle" : "app.EditClientAccountPopup.header.createTitle"
                        }
                    />
                }
                disableDialogWrapper={true}
                loadingContent={this.state.loading}
                content={
                    <div className="edit-client-account-popup--content">
                        <div className="edit-client-account-popup--form">
                            <div className="edit-client-account-popup--header">
                                <label>
                                    <FormattedMessage id={"app.EditClientAccountPopup.header.clientName"} />
                                </label>
                                {this.props.client.name}
                            </div>
                            <div className="edit-client-account-popup--columns">
                                <div className="edit-client-account-popup--column-item">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label={<FormattedMessage id={"app.EditClientAccountPopup.form.firstName"} />}
                                        autoComplete={getRandomString()}
                                        value={this.state.formValues.firstName}
                                        onChange={(event) => {
                                            this.changeValue("firstName", event.target.value);
                                        }}
                                    />
                                </div>
                                <div className="edit-client-account-popup--column-item">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        label={<FormattedMessage id={"app.EditClientAccountPopup.form.lastName"} />}
                                        autoComplete={getRandomString()}
                                        value={this.state.formValues.lastName}
                                        onChange={(event) => {
                                            this.changeValue("lastName", event.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label={<FormattedMessage id={"app.EditClientAccountPopup.form.email"} />}
                                autoComplete={getRandomString()}
                                value={this.state.formValues.email}
                                onChange={(event) => {
                                    this.changeValue("email", event.target.value);
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={<FormattedMessage id={"app.EditClientAccountPopup.form.position"} />}
                                autoComplete={getRandomString()}
                                value={this.state.formValues.position}
                                onChange={(event) => {
                                    this.changeValue("position", event.target.value);
                                }}
                            />
                            <div className="edit-client-account-popup--columns">
                                <div className="edit-client-account-popup--column-item">
                                    <Autocomplete
                                        fullWidth
                                        options={this.state.countries}
                                        getOptionLabel={(option: ICountry) =>
                                            this.props.intl.formatMessage(
                                                { id: "app.EditClientAccountPopup.form.cc.optionLabel" },
                                                { countryPhoneCode: option.countryPhoneCode, countryName: option.countryName }
                                            )
                                        }
                                        value={this.state.formValues.country}
                                        onChange={(_, value: ICountry | null) => {
                                            this.changeValue("country", value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                label={<FormattedMessage id={"app.EditClientAccountPopup.form.country"} />}
                                                margin="normal"
                                                variant="outlined"
                                                ref={this.fieldFormCountry}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="edit-client-account-popup--column-item">
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={<FormattedMessage id={"app.EditClientAccountPopup.form.phone"} />}
                                        autoComplete={getRandomString()}
                                        value={this.state.formValues.phone}
                                        onChange={(event) => {
                                            this.changeValue("phone", event.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="edit-client-account-popup--icon-image">
                            <WedIcon icon={<WedIconSingleUserSquare />} />
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(EditClientAccountPopup));
