import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import TagList from "../../../../components/tile/components/TagList/TagList";
import { getEmailSchemaValidator } from "../../../../services/JSONSchemaValidator/SchemaValidators";
import JSONSchemaValidator from "../../../../services/JSONSchemaValidator/JSONSchemaValidator";
import "./AdminPanelInviteNewUser.scss";
import container from "../../../../container";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface PropsInterface extends ProviderContext, WrappedComponentProps {}

interface StateInterface {
    loading: boolean;
    tag: string;
    fields: {
        tags: string[];
    };
}

const EMAIL_SCHEMA_VALIDATOR = getEmailSchemaValidator();
const TAG_MAX_ITEMS = 20;

const INITIAL_STATE: StateInterface = {
    loading: false,
    tag: "",
    fields: {
        tags: [],
    },
};

class AdminPanelInviteNewUsers extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleChangeTag = this.handleChangeTag.bind(this);
        this.handleAddTag = this.handleAddTag.bind(this);
        this.handleRemoveTag = this.handleRemoveTag.bind(this);
        this.sendInvite = this.sendInvite.bind(this);
    }

    private handleChangeTag(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        this.setState(() => ({ tag: value }));
    }

    private handleAddTag() {
        const { valid, errors } = JSONSchemaValidator.validate(
            { email: this.state.tag },
            {
                type: "object",
                properties: {
                    email: getEmailSchemaValidator(),
                },
                required: ["email"],
                additionalProperties: false,
                errorMessage: {
                    properties: {
                        email: "app.AdminPanelInviteNewUsers.error.errInvitationEmalInvalid",
                    },
                },
            }
        );
        if (!valid) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: errors[Object.keys(errors)[0]] }), { variant: "error" });
        } else if (valid && !!this.state.fields.tags.find((tag) => tag.toLowerCase() === this.state.tag.toLowerCase())) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelInviteNewUsers.error.errErrUserEmailEntered" }), {
                variant: "error",
            });
        } else {
            this.setState({
                tag: "",
                fields: {
                    tags: [...this.state.fields.tags, this.state.tag.toLowerCase()],
                },
            });
        }
    }

    private handleRemoveTag(removeTag: string) {
        this.setState({ fields: { tags: this.state.fields.tags.filter((tag) => tag.toLowerCase() !== removeTag.toLowerCase()) } });
    }

    private async sendInvite() {
        this.setState({ loading: true });
        try {
            await container.adminService.sendInvites(this.state.fields.tags);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelInviteNewUsers.success" }), { variant: "success" });
            this.setState(INITIAL_STATE);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_INVITATION_EMAL_INVALID) {
                keyTranslate = "app.AdminPanelInviteNewUsers.error.errInvitationEmalInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_EMAIL_USED) {
                keyTranslate = "app.AdminPanelInviteNewUsers.error.errErrUserEmailUsed";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div className="admin-panel-invite-new-users">
                <div className="file-edit-tags-popup--add-new-tag-row">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="tags"
                        label={<FormattedMessage id={"app.AdminPanelInviteNewUsers.form.enterNewTag"} />}
                        name="tags"
                        autoComplete="off"
                        value={this.state.tag}
                        onChange={this.handleChangeTag}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                this.handleAddTag();
                            }
                        }}
                        inputProps={{ ...(EMAIL_SCHEMA_VALIDATOR.maxLength ? { maxLength: EMAIL_SCHEMA_VALIDATOR.maxLength } : {}) }}
                        disabled={this.state.loading || this.state.fields.tags.length >= TAG_MAX_ITEMS}
                    />
                    <Button
                        className="button--more-padding"
                        onClick={this.handleAddTag}
                        disabled={this.state.loading || this.state.fields.tags.length >= TAG_MAX_ITEMS}
                    >
                        <FormattedMessage id={"app.AdminPanelInviteNewUsers.form.addTag"} />
                    </Button>
                </div>
                <div className="file-edit-tags-popup--add-new-tag-row admin-panel-invite-new-users-without-margin">
                    <TagList tags={this.state.fields.tags} handleRemoveClick={this.handleRemoveTag} />
                </div>
                <div>
                    <Button
                        className="button--more-padding"
                        onClick={this.sendInvite}
                        disabled={this.state.loading || this.state.fields.tags.length === 0}
                    >
                        <FormattedMessage id={"app.AdminPanelInviteNewUsers.form.sendInvite"} />
                    </Button>
                </div>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelInviteNewUsers));
