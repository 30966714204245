import React, { Component } from "react";
import classNames from "classnames";
import { SizeType, WedIcon } from "../../../../components/wed-icon/WedIcon";
import "./AdminPanelIconButton.scss";

interface PropsInterface {
    className?: string;
    disabled?: boolean;
    onClick: () => void;
    icon: React.ReactElement<React.Component>;
    size?: SizeType;
}

interface StateInterface {}

class AdminPanelIconButton extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className={classNames("admin-panel-icon-button", this.props.className)}>
                <button disabled={this.props.disabled} className="wed-icon-button" onClick={this.props.onClick}>
                    <WedIcon size={this.props.size} icon={this.props.icon} />
                </button>
            </div>
        );
    }
}

export default AdminPanelIconButton;
