import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IListResult } from "./Models/Common";
import { IApiClient, IApiClientAddress, IApiUserClient, IManagerClientAccessParameters } from "./Models/Client";

type IApiCreateUpdateClientCustomerContact = Pick<IApiUserClient, "first_name" | "last_name" | "email"> &
    Partial<Pick<IApiUserClient, "position" | "phone"> & { ccode: number }>;

export type IApiCreateClientCustomerContact = IApiCreateUpdateClientCustomerContact & { ecOrgID: IApiUserClient["ecOrgID"] };

export type IApiUpdateClientCustomerContact = IApiCreateUpdateClientCustomerContact & { id: IApiUserClient["id"] };

export interface IApiBaseCreateUpdateClient {
    name: string;
    desc: string;
    website?: string;
    streetAddress1?: string;
    streetAddress2?: string;
    zipCode?: string;
    city?: string;
    state?: string;
    countryID?: number;
}

export default class ClientActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getClients(): Promise<IListResult<IApiClient>> {
        const response = await this.get("/xapi/ecs");

        return response.data;
    }

    public async getClientAddress(clientId: number): Promise<IApiClientAddress> {
        const response = await this.get(`/xapi/ecs/${clientId}/edit`);

        return response.data;
    }

    public async createClient(data: IApiBaseCreateUpdateClient): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/ecs/create", data);

        return response.data;
    }

    public async updateClient(data: IApiBaseCreateUpdateClient & { id: number }): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/ecs/update", data);

        return response.data;
    }

    public async deleteClient(id: number): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/ecs/delete", { id });

        return response.data;
    }

    public async getUsersClientByIdClient(idClient: number): Promise<IListResult<IApiUserClient>> {
        const response = await this.get(`/xapi/ecs/${idClient}/ecc`);

        return response.data;
    }

    public async getClientCustomerContact(clientId: number, customerContactId: number): Promise<IApiUserClient> {
        const response = await this.get(`/xapi/ecs/${clientId}/ecc/${customerContactId}`);

        return response.data;
    }

    // eslint-disable-next-line
    public async createClientCustomerContact(data: IApiCreateClientCustomerContact): Promise<IApiUserClient> {
        const response = await this.postWithCSRFJson("/xapi/ecc/create", data);

        return response.data;
    }

    public async updateClientCustomerContact(data: IApiUpdateClientCustomerContact): Promise<IApiUserClient> {
        const response = await this.postWithCSRFJson("/xapi/ecc/update", data);

        return response.data;
    }

    public async removeClientCustomerContact(id: number): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/ecc/delete", { id });

        return response.data;
    }

    public async setClientAccess(parameters: IManagerClientAccessParameters): Promise<void> {
        await this.postWithCSRFJson("/xapi/ecs/ams", parameters);
    }
}
