export async function redirectPage(url: string): Promise<void> {
    return goTo(() => {
        // eslint-disable-next-line
        window.location = url as any;
    });
}

async function goTo(func: () => void): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            func();
        }, 10);
        setTimeout(() => {
            reject(new Error("Error redirect page"));
        }, 10000);
    });
}
