import React, { Component } from "react";
import "./AdminPanelContainerTextTable.scss";

interface PropsInterface {
    children?: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {}

export default class AdminPanelContainerTextTable extends Component<PropsInterface, StateInterface> {
    render() {
        return <div className="admin-panel-container-text-table">{this.props.children}</div>;
    }
}
