import React from "react";
import { ReactZoomPanPinchRef, TransformWrapper } from "react-zoom-pan-pinch";
import classNames from "classnames";
import { WedIcon } from "../../wed-icon/WedIcon";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { isFileHtmlImageExtension, isFileHtmlVideoExtension, isPdfFileExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import { WedIconChatMessagesEdit, WedIconEye, WedIconMinus1, WedIconPlusAdd3 } from "../../wed-icon/generated/WedIconSvg";
import WedMediaGalleryPreviewMenu from "../WedMediaGalleryPreviewMenu/WedMediaGalleryPreviewMenu";
import WedLightBox from "../WedLightBox/WedLightBox";
import { WedImageGalleryDataProps } from "../../../store/WedImageGalleryReducer";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import { AppNoticeError, LogicError } from "../../../errors/error-app";
import container from "../../../container";
import WedMediaGalleryImageAnnotation from "../WedMediaGalleryImageAnnotation/WedMediaGalleryImageAnnotation";
import WedMediaGalleryImageTransformComponent from "./WedMediaGalleryImageTransformComponent";
import { RefreshMediaGalleryParams } from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";
import "./WedMediaGalleryImage.scss";
import { VariantType } from "notistack";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";

export interface WedMediaGalleryImageData {
    name: string;
}

interface BasePropsInterface {
    projectId?: number;
    className?: string;
    isEditable?: boolean;
    link: string;
    orgLink?: string;
    extension?: string;
    extensionFile: string;
    data?: WedMediaGalleryImageData;
    mainMedia?: boolean;
    onComplete?: (err?: Error) => void;
    onClick?: () => void;
    collectionId?: number;
    userTeamId?: number;
    dirId?: number;
    token?: string;
    fileType: FileTypeEnum;
    onRefreshMediaGallery?: (params?: RefreshMediaGalleryParams) => void;
    userTeamType?: boolean;
    enqueueSnackbar?: (variant: VariantType, id: string, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => void;
}

interface PropsInterface extends BasePropsInterface, WedImageGalleryDataProps {}

interface StateInterface {
    pdfPageNumber: number;
    openLightbox: boolean;
    annotationMode: boolean;
    hideComponent: boolean;
    orgWidth: number;
    orgHeight: number;
    width: number;
    height: number;
}

class WedMediaGalleryImage extends React.Component<PropsInterface, StateInterface> {
    // eslint-disable-next-line
    private loadedTimerNonImage: any;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            orgWidth: 0,
            orgHeight: 0,
            pdfPageNumber: 1,
            openLightbox: false,
            annotationMode: false,
            hideComponent: false,
        };
        this.onLoad = this.onLoad.bind(this);
        this.onError = this.onError.bind(this);
        this.onWrapperImageLoad = this.onWrapperImageLoad.bind(this);
        this.escFunction = this.escFunction.bind(this);
        this.calculateAnnotationComponentSize = this.calculateAnnotationComponentSize.bind(this);
        this.handleCloseAnnotation = this.handleCloseAnnotation.bind(this);
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);
        window.addEventListener("resize", this.calculateAnnotationComponentSize);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
        window.removeEventListener("resize", this.calculateAnnotationComponentSize);
        this.clearAnnotationComponentSize();
    }

    private onLoad() {
        this.props.onComplete && this.props.onComplete();
    }

    private escFunction(event: KeyboardEvent) {
        if (this.state.annotationMode && event.key === "Escape") {
            this.disableAnnotationMode();
        }
    }

    private onError() {
        this.props.onComplete && this.props.onComplete(new AppNoticeError(`Error loading image link ${this.props.link}`));
    }

    private onWrapperImageLoad() {
        // eslint-disable-next-line
        const img = document.getElementById("wed-media-gallery-image") as any;
        img._loaded = true;
        this.initImage(img);
    }

    // eslint-disable-next-line
    private initImage(img: any) {
        if (!img._loaded || !img._zoom) {
            return;
        }
        const zoom: ReactZoomPanPinchRef = img._zoom;
        setTimeout(() => {
            zoom.zoomToElement(img, undefined, 0);
            setTimeout(() => {
                this.props.onComplete && this.props.onComplete();
            }, 10);
        }, 100);
    }

    private refreshMediaGallery() {
        if (!this.props.onRefreshMediaGallery) {
            throw new LogicError("Cannot initialize refresh media gallery when not pass onRefreshMediaGallery");
        }
        const funcRef = this.props.onRefreshMediaGallery;
        setTimeout(() => {
            funcRef({
                versionId: this.props.versionId,
            });
        }, 10);
    }

    private handleCloseAnnotation(updated?: boolean) {
        if (updated) {
            this.refreshMediaGallery();
        } else {
            this.disableAnnotationMode();
        }
    }

    private calculateAnnotationComponentSize() {
        if (!this.state.annotationMode) {
            return;
        }

        // eslint-disable-next-line
        const annotationContainer = document.getElementById("wed-media-image-annotation-container") as any | HTMLElement;
        // eslint-disable-next-line
        const imageContainer = document.getElementById("wed-media-image-annotation-container--image") as any | HTMLElement;
        if (!annotationContainer || !imageContainer) {
            return;
        }
        const htmlImage: HTMLImageElement = imageContainer.getElementsByTagName("img")[0] as HTMLImageElement;
        if (!htmlImage) {
            return;
        }

        const containerBounding = annotationContainer.getBoundingClientRect();
        const imgWidth = (htmlImage.naturalWidth * containerBounding.height) / htmlImage.naturalHeight;
        const imgHeight = (htmlImage.naturalHeight * containerBounding.width) / htmlImage.naturalWidth;

        if (imgWidth > containerBounding.width) {
            imageContainer.style.width = `${containerBounding.width}px`;
            imageContainer.style.height = `${imgHeight}px`;
            this.setState({
                orgWidth: htmlImage.naturalWidth,
                orgHeight: htmlImage.naturalHeight,
                width: containerBounding.width,
                height: imgHeight,
            });
        } else {
            imageContainer.style.width = `${imgWidth}px`;
            imageContainer.style.height = `${containerBounding.height}px`;
            this.setState({
                orgWidth: htmlImage.naturalWidth,
                orgHeight: htmlImage.naturalHeight,
                width: imgWidth,
                height: containerBounding.height,
            });
        }
    }

    private clearAnnotationComponentSize() {
        // eslint-disable-next-line
        const imageContainer = document.getElementById("wed-media-image-annotation-container--image") as any | HTMLElement;
        if (imageContainer) {
            imageContainer.style.width = "";
            imageContainer.style.height = "";
        }
    }

    private enableAnnotationMode() {
        if (this.state.annotationMode) {
            return;
        }
        // eslint-disable-next-line
        const img = document.getElementById("wed-media-gallery-image") as any | HTMLElement;
        // eslint-disable-next-line
        const imageContainer = document.getElementById("wed-media-image-annotation-container--image") as any | HTMLElement;
        if (!img || !imageContainer) {
            return;
        }

        img.__zoomParentNode = img.parentNode;
        imageContainer.appendChild(img);

        this.setState({ annotationMode: true }, () => {
            this.calculateAnnotationComponentSize();
        });
        this.props.enqueueSnackbar && this.props.enqueueSnackbar("success", "app.WedMediaGalleryImageAnnotationPopup.initAction");
    }

    private disableAnnotationMode() {
        if (!this.state.annotationMode) {
            return;
        }

        // eslint-disable-next-line
        const img = document.getElementById("wed-media-gallery-image") as any | HTMLElement;
        if (!img || !img.__zoomParentNode) {
            return;
        }

        img.__zoomParentNode.appendChild(img);

        this.setState({ annotationMode: false });
        this.clearAnnotationComponentSize();
    }

    render() {
        const { link, extensionFile, orgLink, mainMedia } = this.props;
        const isPdf = extensionFile ? isPdfFileExtension(extensionFile) : false;
        const isImage = extensionFile ? isFileHtmlImageExtension(extensionFile) : false;
        const isMovie = extensionFile ? isFileHtmlVideoExtension(extensionFile) : false;

        if (!isImage) {
            if (!this.loadedTimerNonImage) {
                this.loadedTimerNonImage = setTimeout(() => {
                    this.props.onComplete && this.props.onComplete();
                }, 100);
            }
        }

        return (
            <div
                onClick={this.props.onClick}
                className={classNames(
                    "wed-media-gallery-image",
                    { "wed-media-gallery-image__clickable": !!this.props.onClick },
                    { "wed-media-gallery-image__hide-component": this.state.hideComponent },
                    this.props.className
                )}
            >
                {!mainMedia && isImage ? (
                    <>
                        <div className="wed-media-gallery-image__blank" />
                        <img className="wed-media-gallery-image__img" src={link} onLoad={this.onLoad} onError={this.onError} alt="" />
                    </>
                ) : mainMedia && isImage ? (
                    <div className="wed-media-gallery-image--main-media-container">
                        <div
                            className={classNames("wed-media-gallery-image--main-media-annotation", {
                                "wed-media-gallery-image--main-media-no-display": !this.state.annotationMode,
                            })}
                        >
                            <WedMediaGalleryImageAnnotation
                                orgWidth={this.state.orgWidth}
                                orgHeight={this.state.orgHeight}
                                width={this.state.width}
                                height={this.state.height}
                                containerId={"wed-media-image-annotation-container"}
                                handleClose={this.handleCloseAnnotation}
                                fileType={this.props.fileType}
                                objectId={this.props.objectId as number}
                                versionId={this.props.versionId as number}
                                collectionId={this.props.collectionId}
                                token={this.props.token}
                            />
                        </div>
                        <div
                            className={classNames("wed-media-gallery-image--main-media-transform", {
                                "wed-media-gallery-image--main-media-no-display": this.state.annotationMode,
                            })}
                        >
                            <TransformWrapper
                                minScale={0.1}
                                maxScale={5}
                                centerOnInit={true}
                                onInit={(ref) => {
                                    // eslint-disable-next-line
                                    const img = document.getElementById("wed-media-gallery-image") as any;
                                    img._zoom = ref;
                                    this.initImage(img);
                                }}
                            >
                                {({ zoomIn, zoomOut }) => (
                                    <>
                                        <WedMediaGalleryPreviewMenu
                                            fileType={this.props.fileType}
                                            projectId={this.props.projectId}
                                            collectionId={this.props.collectionId}
                                            userTeamId={this.props.userTeamId}
                                            dirId={this.props.dirId}
                                            token={this.props.token}
                                        >
                                            {!this.props.userTeamType && (
                                                <button
                                                    className="wed-icon-button"
                                                    disabled={!this.props.isEditable}
                                                    onClick={() => {
                                                        this.enableAnnotationMode();
                                                    }}
                                                >
                                                    <WedIcon icon={<WedIconChatMessagesEdit />} />
                                                </button>
                                            )}
                                            <button
                                                className="wed-icon-button"
                                                onClick={() => {
                                                    this.setState({ openLightbox: true });
                                                }}
                                            >
                                                <WedIcon icon={<WedIconEye />} />
                                            </button>
                                            <button
                                                className="wed-icon-button"
                                                onClick={() => {
                                                    zoomIn();
                                                }}
                                            >
                                                <WedIcon icon={<WedIconPlusAdd3 />} />
                                            </button>
                                            <button
                                                className="wed-icon-button"
                                                onClick={() => {
                                                    zoomOut();
                                                }}
                                            >
                                                <WedIcon icon={<WedIconMinus1 />} />
                                            </button>
                                        </WedMediaGalleryPreviewMenu>
                                        <WedMediaGalleryImageTransformComponent
                                            link={link}
                                            onWrapperImageLoad={this.onWrapperImageLoad}
                                            onError={this.onError}
                                        />
                                    </>
                                )}
                            </TransformWrapper>
                        </div>
                    </div>
                ) : mainMedia && orgLink && isMovie ? (
                    <>
                        <WedMediaGalleryPreviewMenu
                            fileType={this.props.fileType}
                            projectId={this.props.projectId}
                            collectionId={this.props.collectionId}
                            dirId={this.props.dirId}
                            token={this.props.token}
                        />
                        <video className="wed-media-gallery-image__video" controls={true}>
                            <source src={orgLink} />
                        </video>
                    </>
                ) : mainMedia && orgLink && isPdf ? (
                    <div className="wed-media-gallery-image__pdf">
                        <WedMediaGalleryPreviewMenu
                            fileType={this.props.fileType}
                            projectId={this.props.projectId}
                            collectionId={this.props.collectionId}
                            dirId={this.props.dirId}
                            token={this.props.token}
                        />
                        <div className="wed-media-gallery-image__pdf-container">
                            <iframe allowFullScreen={true} frameBorder="0" src={container.publicUrlService.getPdfViewerUrl(orgLink)} />
                        </div>
                    </div>
                ) : (
                    <div className="wed-media-gallery-image__icon">
                        {mainMedia && (
                            <WedMediaGalleryPreviewMenu
                                fileType={this.props.fileType}
                                projectId={this.props.projectId}
                                collectionId={this.props.collectionId}
                                dirId={this.props.dirId}
                                token={this.props.token}
                            />
                        )}
                        <WedIcon className="wed-icon-media-gallery-preview" icon={<FileTypeIcon extension={extensionFile} />} />
                        {this.props.data && (
                            <div className="wed-media-gallery-image__icon-data">
                                <span className="wed-media-gallery-image__icon-file-name">{this.props.data.name}</span>
                            </div>
                        )}
                    </div>
                )}
                {this.state.openLightbox && this.props.mediaData && this.props.objectId && (
                    <WedLightBox
                        mediaData={this.props.mediaData}
                        initObjectId={this.props.objectId}
                        onCloseRequest={(changeObjectId?: number) => {
                            this.setState({ openLightbox: false });
                            if (changeObjectId) {
                                this.props.onChangeFile && this.props.onChangeFile(changeObjectId);
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        ...(state.wedImageGalleryData || {}),
    };
};

export default connect(mapStateToProps)(WedMediaGalleryImage);
