import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { Grid, TextField } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import RegexpPatterns from "../../../services/JSONSchemaValidator/RegexpPatterns";
import Redirect from "../../../services/Redirect/Redirect";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import NotLoggedFormPage from "../../../components/template/NotLoggedFormPage/NotLoggedFormPage";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { generatePath, Link, RouteComponentProps, withRouter } from "react-router-dom";
import "./LoginPage.scss";
import WedImageLogo40x40 from "../../../components/wed-image-logo/WedImageLogo40x40";

interface PropsInterface extends ProviderContext, WrappedComponentProps, RouteComponentProps {}

interface StateInterface {
    fields: {
        email: string;
        password: string;
    };
}

const CAPTCHA_ACTION = "signup";

class LoginPage extends Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            email: {
                type: "string",
                pattern: RegexpPatterns.EMAIL,
                maxLength: 254,
            },
            password: {
                type: "string",
                minLength: 1,
            },
        },
        required: ["email", "password"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                email: "app.loginForm.errorLogin",
                password: "app.loginForm.errorPassword",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            fields: {
                email: "",
                password: "",
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // eslint-disable-next-line
    private async handleSubmit(_: any, token: string | undefined) {
        try {
            await container.userService.login(
                token ? { recaptcha_token: token, action: CAPTCHA_ACTION } : undefined,
                this.state.fields.email,
                this.state.fields.password
            );
            return Redirect.to(RoutesEnum.PROJECTS);
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_AUTHENTICATION_FAILED) {
                keyTranslate = "app.loginForm.badLoginOrPassword";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_INACTIVE_ACCOUNT) {
                keyTranslate = "app.loginForm.accountInactive";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CAPTCHA_VERIFIED) {
                keyTranslate = "app.global.captchaVerifiedError";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        return (
            <NotLoggedFormPage
                maxWidth="xs"
                className="login-page"
                handleSubmit={this.handleSubmit}
                labelSubmit={"app.loginForm.signIn"}
                iconSubmit={<ExitToAppOutlinedIcon />}
                beforeHeader={
                    <div className="login-page--avatar">
                        <WedImageLogo40x40 />
                    </div>
                }
                header={<FormattedMessage id={"app.loginForm.signIn"} />}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <Link to={generatePath(RoutesEnum.FORGOT_PASSWORD)}>
                                <FormattedMessage id={"app.loginForm.forgotPassword"} />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link to={generatePath(RoutesEnum.REGISTER)}>
                                <FormattedMessage id={"app.loginForm.signUp"} />
                            </Link>
                        </Grid>
                    </Grid>
                }
                formSchema={this.schema}
                formData={this.state.fields}
                withCaptcha={CAPTCHA_ACTION}
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={this.state.fields.email}
                    onChange={this.handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.fields.password}
                    onChange={this.handleChange}
                />
            </NotLoggedFormPage>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(LoginPage)));
