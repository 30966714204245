import React from "react";
import { WedIconArrow } from "./generated/WedIconSvg";
import { SizeType, WedIcon } from "./WedIcon";

interface PropsInterface {}

export class WedIconDropDown extends React.Component<PropsInterface> {
    render() {
        return <WedIcon className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" size={SizeType.SMALL} icon={<WedIconArrow />} />;
    }
}
