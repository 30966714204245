import React, { Component } from "react";
import { parse } from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ProjectListMenu } from "./ProjectListPageHeader/ProjectListMenu/ProjectListMenu";
import { WedMainMenuContainer } from "../../main-menu/WedMainMenuContainer/WedMainMenuContainer";
import ProjectListButtons from "./ProjectListPageHeader/ProjectListButtons/ProjectListButtons";
import { FilterFormProjectList } from "../../filter-form/FilterFormProjectList/FilterFormProjectList";
import { WedSubMenuContainer } from "../../main-menu/WedSubMenuContainer/WedSubMenuContainer";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import container from "../../../container";
import { IProject, ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import { CreateProjectPopup } from "../../project/Project/CreateProjectPopup/CreateProjectPopup";
import ProjectsFilterInterface from "../../../services/Filter/Projects/ProjectsFilterInterface";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { PageInfoBoxProps, setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { ApplicationState } from "../../../store";
import { InjectedCtrlKeyDetectorProps, withCtrlKeyDetector } from "../../../hoc/withCtrlKeyDetector";
import { ISelectItem } from "../../status-action-button/StatusActionButton";
import { FileTypeEnum } from "../Type/FileTypeEnum";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import ClientMenu from "../../main-menu/ClientMenu/ClientMenu";
import { IListProjectPageContextClient } from "./ListProjectPageContextClient";
import { SidebarType } from "../../sidebar/Sidebar/Sidebar";
import ListProjectContent from "../ListProjectContent/ListProjectContent";
import { LogicError } from "../../../errors/error-app";
import { IListProjectPageContextUserTeam } from "./ListProjectPageContextUserTeam";
import UserTeamMenu from "../../main-menu/UserTeamMenu/UserTeamMenu";
import { isEditableProject } from "../../../services/WedApi/Utils/IsEditableProject";

interface BasePropsInterface {
    contextClient?: IListProjectPageContextClient;
    contextUserTeam?: IListProjectPageContextUserTeam;
}

interface PropsInterface
    extends BasePropsInterface,
        SetPageInfoBoxTitlesActionProps,
        RouteComponentProps,
        WrappedComponentProps,
        InjectedCtrlKeyDetectorProps {
    isTableMode?: boolean;
    userId?: number;
}

interface StateInterface {
    createProjectOpen: boolean;
    filterProjects: IProject[];
    selectedProjects: ISelectItem[];
    isLoading: boolean;
    filterClients: {id: number, name: string}[]
}

class ListProjectPage extends Component<PropsInterface, StateInterface> {
    private filters: ProjectsFilterInterface;
    private favourite: boolean;
    private projects: IProject[];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            createProjectOpen: false,
            filterProjects: [],
            selectedProjects: [],
            isLoading: true,
            filterClients: [],
        };
        this.projects = [];
        this.filters = {
            status: ProjectStatusEnum.active,
        };
        this.favourite = false;
        this.handleClickOpenCreateProject = this.handleClickOpenCreateProject.bind(this);
        this.handleCloseCreateProject = this.handleCloseCreateProject.bind(this);
        this.handleFiltersChange = this.handleFiltersChange.bind(this);
        this.handleChangeFavorite = this.handleChangeFavorite.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.setIsLoading = this.setIsLoading.bind(this);
        this.handleSelectProject = this.handleSelectProject.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.refreshAfterChanges = this.refreshAfterChanges.bind(this);
    }

    handleFiltersChange(filters: ProjectsFilterInterface) {
        this.filters = filters;
        this.filterData();
    }

    private handleSelectProject(id: number, forceMultiply = false) {
        let selectedProjects = [...this.state.selectedProjects];
        const { ctrlKeyPressed } = this.props;
        const selectedProjectsIndex = selectedProjects.findIndex((item) => item.id === id);

        if (selectedProjectsIndex > -1) {
            selectedProjects.splice(selectedProjectsIndex, 1);
        } else {
            const project = container.projectsFilterService.findById(this.projects, id);

            if (project) {
                const newItem = {
                    id: project.id,
                    status: project.status,
                    userIsOwner: project.userIsOwner,
                    object: project
                };

                selectedProjects = forceMultiply || ctrlKeyPressed ? selectedProjects.concat([newItem]) : [newItem];
            }
        }

        this.setState({
            ...this.state,
            selectedProjects,
        });
    }

    private handleSelectAll() {
        const { selectedProjects, filterProjects } = this.state;

        this.setState({
            ...this.state,
            selectedProjects:
                selectedProjects.length === filterProjects.length
                    ? []
                    : filterProjects.map((item) => {
                          return {
                              id: item.id,
                              status: item.status,
                          };
                      }),
        });
    }

    filterData() {
        const filters = Object.assign(
            {},
            this.filters,
            { favourite: this.favourite },
            { userId: this.props.userId },
            this.props.contextClient
                ? {
                      status: this.props.contextClient.projectStatus,
                      customer: this.props.contextClient.client.id,
                  }
                : {},
            this.props.contextUserTeam
                ? {
                      status: this.props.contextUserTeam.projectStatus,
                  }
                : {}
        );
        const filterProjects = container.projectsFilterService.filter(this.projects, filters);
        this.setState({
            ...this.state,
            filterProjects: filterProjects,
            selectedProjects: [],
            isLoading: false,
        });
    }

    handleChangeFavorite(projectId: number) {
        for (const project of this.projects) {
            if (project.id === projectId) {
                project.isFavourite = !project.isFavourite;
                break;
            }
        }

        this.filterData();
    }

    private handleClickOpenCreateProject() {
        this.setState({
            ...this.state,
            createProjectOpen: true,
        });
    }

    private async handleCloseCreateProject() {
        this.setState({
            ...this.state,
            createProjectOpen: false,
        });
    }

    private checkFavouriteProject() {
        const qs = parse(this.props.location.search);
        this.favourite = !!qs.favorites;
        this.filterData();
    }

    private handleStatusChange = async () => {
        await this.fetchData();
    };

    private async fetchData() {
        this.projects = await this.getProjectsByContext();
        this.checkFavouriteProject();
        this.filterData();
        const filterClients: { [key: string]: { id: number, name: string } } = {};
        this.projects.forEach(project => {
            filterClients[`${project.ecOrg.id}`] = {
                id: project.ecOrg.id,
                name: project.ecOrg.name || ''
            };
        });
        this.setState({ filterClients: Object.values(filterClients) });
    }

    private async refreshAfterChanges() {
        this.setIsLoading();
        await this.fetchData();
    }

    private async getProjectsByContext(): Promise<IProject[]> {
        if (this.props.contextUserTeam) {
            return (await container.projectService.getProjectListByIds(this.props.contextUserTeam.userTeam.projectIDs)).projects;
        }
        if (this.props.contextClient) {
            return (await container.projectService.getProjectList({ status: this.props.contextClient.projectStatus })).projects;
        }
        return (await container.projectService.getProjectList()).projects;
    }

    private setIsLoading() {
        this.setState({ isLoading: true });
    }

    private getMenuTitleHeaders(): PageInfoBoxProps {
        const { selectedProjects } = this.state;

        if (selectedProjects.length === 1) {
            const project = container.projectsFilterService.findById(this.projects, selectedProjects[0].id);

            if (project) {
                return {
                    title: project.name,
                    subTitle: project.ecOrg.name,
                };
            }
        }

        return {
            title: this.props.intl.formatMessage({ id: this.favourite ? "app.pageInfoBox.favourite" : "app.pageInfoBox.all" }),
            subTitle: undefined,
        };
    }

    private getClientProjectRouteActiveTab() {
        switch (this.props.contextClient?.projectStatus) {
            case ProjectStatusEnum.active:
                return "project-active";
            case ProjectStatusEnum.closed:
                return "project-closed";
        }
        throw new LogicError("Logic error, client project can be only in status active or closed");
    }

    private getUserTeamProjectRouteActiveTab() {
        switch (this.props.contextUserTeam?.projectStatus) {
            case ProjectStatusEnum.active:
                return "project-active";
            case ProjectStatusEnum.closed:
                return "project-closed";
        }
        throw new LogicError("Logic error, client project can be only in status active or closed");
    }

    async componentDidMount() {
        if (!this.props.contextClient && !this.props.contextUserTeam) {
            this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
        }

        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.contextUserTeam) {
            if (this.props.contextUserTeam.projectStatus !== prevProps.contextUserTeam?.projectStatus) {
                this.setIsLoading();
                await this.fetchData();
            }
        } else if (this.props.contextClient) {
            if (this.props.contextClient.projectStatus !== prevProps.contextClient?.projectStatus) {
                this.setIsLoading();
                await this.fetchData();
            }
        } else {
            this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
            if (prevProps.location.search !== this.props.location.search) {
                this.checkFavouriteProject();
            }
        }
    }

    render() {
        const { filterProjects: projects, selectedProjects, isLoading } = this.state;

        const firstSelected = container.projectsFilterService.findById(this.projects, selectedProjects[0]?.id || 0);

        return (
            <div className="project-list-page">
                <CreateProjectPopup
                    open={this.state.createProjectOpen}
                    handleClose={this.handleCloseCreateProject}
                    customerAccount={this.props.contextClient?.client}
                />
                <WedMainMenuContainer
                    menu={
                        this.props.contextClient ? (
                            <ClientMenu clientId={this.props.contextClient.client.id} activeTab={this.getClientProjectRouteActiveTab()} />
                        ) : this.props.contextUserTeam ? (
                            <UserTeamMenu userTeamId={this.props.contextUserTeam.userTeam.id} activeTab={this.getUserTeamProjectRouteActiveTab()} />
                        ) : (
                            <ProjectListMenu activeTab={this.favourite ? "favorites" : "all"} />
                        )
                    }
                    buttons={
                        <ProjectListButtons
                            createProjectClick={this.handleClickOpenCreateProject}
                            onStatusChange={this.handleStatusChange}
                            selectedProjects={selectedProjects}
                            setIsLoading={this.setIsLoading}
                        />
                    }
                    sidebar={<MenuControlIcons project={firstSelected} fileType={FileTypeEnum.PROJECT_FILES} />}
                />
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <SidebarWrapper
                        handleSidebarChanges={this.refreshAfterChanges}
                        type={firstSelected ? SidebarType.project : SidebarType.client}
                        id={firstSelected?.id || this.props.contextClient?.client.id}
                        disabled={!isEditableProject(FileTypeEnum.PROJECT_FILES, firstSelected)}
                    >
                        <WedSubMenuContainer
                            right={
                                <FilterFormProjectList
                                    currentStatus={this.filters.status}
                                    contextUserTeam={this.props.contextUserTeam}
                                    contextClient={this.props.contextClient}
                                    onFiltersChange={this.handleFiltersChange}
                                    filterClients={this.state.filterClients}
                                />
                            }
                        />
                        <ListProjectContent
                            isTableMode={this.props.isTableMode as boolean}
                            handleSelectAll={this.handleSelectAll}
                            projects={projects}
                            selectedProjects={selectedProjects}
                            handleSelectProject={this.handleSelectProject}
                            handleChangeFavorite={this.handleChangeFavorite}
                            handleStatusChange={this.handleStatusChange}
                            setIsLoading={this.setIsLoading}
                            handleClickOpenCreateProject={this.handleClickOpenCreateProject}
                        />
                    </SidebarWrapper>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface): Partial<PropsInterface> => {
    return {
        ...props,
        isTableMode: store.user.viewMode === IUserViewModeEnum.table,
        userId: store.user.data?.id,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(withCtrlKeyDetector(ListProjectPage))));
