import { Action, Dispatch } from "redux";
import { IUserModeSidebarEnum, IUserMyProfile, IUserViewModeEnum } from "../services/WedApi/Models/User";

export interface SetLoggedUserProps {
    refreshLoginUser: () => void;
    setLoggedUser: (user: IUserMyProfile) => void;
    changeViewMode: () => void;
    changeSidebarOpen: () => void;
}

export interface LoggedUserProps {
    data?: IUserMyProfile;
    viewMode: IUserViewModeEnum;
    sidebarOpen: IUserModeSidebarEnum;
}

const initialState: LoggedUserProps = {
    data: undefined,
    viewMode: IUserViewModeEnum.tile,
    sidebarOpen: IUserModeSidebarEnum.close,
};

function getPreferListView(): IUserViewModeEnum {
    try {
        if (localStorage.getItem("wedPreferView") === IUserViewModeEnum.table) {
            return IUserViewModeEnum.table;
        }
    } catch (err) {
        return initialState.viewMode;
    }
    return initialState.viewMode;
}

function setPreferListView(view: IUserViewModeEnum) {
    try {
        localStorage.setItem("wedPreferView", view);
        // eslint-disable-next-line no-empty
    } catch (e) {}
}

function changePreferListView(): IUserViewModeEnum {
    const preferMode = getPreferListView() === IUserViewModeEnum.tile ? IUserViewModeEnum.table : IUserViewModeEnum.tile;
    setPreferListView(preferMode);

    return preferMode;
}

function getPreferSidebarOpen(): IUserModeSidebarEnum {
    try {
        if (localStorage.getItem("wedPreferSidebarOpen") === IUserModeSidebarEnum.open) {
            return IUserModeSidebarEnum.open;
        }
    } catch (err) {
        return initialState.sidebarOpen;
    }
    return initialState.sidebarOpen;
}

function changePreferSidebarOpen(): IUserModeSidebarEnum {
    const preferMode = getPreferSidebarOpen() === IUserModeSidebarEnum.open ? IUserModeSidebarEnum.close : IUserModeSidebarEnum.open;
    try {
        localStorage.setItem("wedPreferSidebarOpen", preferMode);
    } catch (err) {
        //
    }
    return preferMode;
}

export function setLoggedUser(dispatch: Dispatch): (user?: IUserMyProfile) => void {
    return (user?: IUserMyProfile) => {
        dispatch({
            type: UserReducerAction.SET_LOGGED_USER,
            payload: {
                data: user,
                viewMode: getPreferListView(),
                sidebarOpen: getPreferSidebarOpen(),
            },
        });
    };
}

export function initTokenUser(dispatch: Dispatch): (user?: IUserMyProfile) => void {
    return () => {
        dispatch({
            type: UserReducerAction.INIT_TOKEN_USER,
            payload: {
                viewMode: getPreferListView(),
                sidebarOpen: getPreferSidebarOpen(),
            },
        });
    };
}

export function changeViewMode(dispatch: Dispatch): () => void {
    return () => {
        dispatch({
            type: UserReducerAction.CHANGE_VIEW_MODE,
            payload: {},
        });
    };
}

export function changeSidebarOpen(dispatch: Dispatch): () => void {
    return () => {
        dispatch({
            type: UserReducerAction.CHANGE_SIDEBAR_OPEN,
            payload: {},
        });
    };
}

export enum UserReducerAction {
    SET_LOGGED_USER = "UserReducerAction/SET_LOGGED_USER",
    INIT_TOKEN_USER = "UserReducerAction/INIT_TOKEN_USER",
    CHANGE_VIEW_MODE = "UserReducerAction/CHANGE_VIEW_MODE",
    CHANGE_SIDEBAR_OPEN = "UserReducerAction/CHANGE_SIDEBAR_OPEN",
}

// eslint-disable-next-line
export const UserReducer = (state: LoggedUserProps = initialState, action: Action & { payload: any }) => {
    switch (action.type) {
        case UserReducerAction.SET_LOGGED_USER:
            return { ...state, ...action.payload };
        case UserReducerAction.INIT_TOKEN_USER:
            return { ...state, ...action.payload };
        case UserReducerAction.CHANGE_VIEW_MODE:
            return { ...state, viewMode: changePreferListView() };
        case UserReducerAction.CHANGE_SIDEBAR_OPEN:
            return { ...state, sidebarOpen: changePreferSidebarOpen() };
    }
    return state;
};
