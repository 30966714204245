import React from "react";
import { ICommentNote } from "../../../services/WedApi/Models/Note";
import "./ProjectSummaryPageRecentCommentsBox.scss";
import CommentNoteSummaryBox from "../../../components/comments/CommentNoteSummaryBox/CommentNoteSummaryBox";

interface PropsInterface {
    comments: ICommentNote[];
}

class ProjectSummaryPageProofingBox extends React.Component<PropsInterface> {
    constructor(props: PropsInterface) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="summary-page-recent-comments__container">
                    {this.props.comments.map((data, key) => (
                        <div key={key} className="summary-page-recent-comments__item">
                            <CommentNoteSummaryBox key={key} object={data}></CommentNoteSummaryBox>
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default ProjectSummaryPageProofingBox;
