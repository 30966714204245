import { IAccountTeamUser, IAccountTeamUserProjectRole, IOwner, ITeamUser, ITeamUserProjectRole, IUser } from "../Models/User";
import { IClient } from "../Models/Client";

export function getProjectTeamMembers(owners: IOwner[], members: IOwner[]): ITeamUser[] {
    return ([] as ITeamUser[])
        .concat(
            owners.map((owner) => {
                return { ...owner, role: ITeamUserProjectRole.ROLE_OWNER } as ITeamUser;
            }),
            members.map((owner) => {
                return { ...owner, role: ITeamUserProjectRole.ROLE_MEMBER } as ITeamUser;
            })
        )
        .sort(comparableUserLastname);
}

export function getClientAccountTeamMembers(client: IClient, teamUsers: IUser[]): IAccountTeamUser[] {
    return teamUsers
        .map((user) => {
            const result: Partial<IAccountTeamUser> = {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                created: user.created,
                role: client.ownerIDs.includes(user.id)
                    ? IAccountTeamUserProjectRole.ROLE_OWNER
                    : client.membersIds.includes(user.id)
                    ? IAccountTeamUserProjectRole.ROLE_MEMBER
                    : undefined,
            };
            return result;
        })
        .filter((user) => {
            return !!user.role;
        }) as IAccountTeamUser[];
}

export function getProjectCustomerContacts(ecUsers: IUser[]): IUser[] {
    return ecUsers.sort(comparableUserLastname);
}

function comparableUserLastname(a: { lastName?: string }, b: { lastName?: string }) {
    if (!a.lastName) {
        return 1;
    }
    if (!b.lastName) {
        return -1;
    }
    return a.lastName.localeCompare(b.lastName);
}
