import React from "react";
import container from "../../container";

interface PropsInterface {
    width: number;
    height: number;
    file: string;
}

interface StateInterface {}

class WedImageLogo extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <img
                style={{ width: this.props.width, height: this.props.height }}
                width={this.props.width}
                height={this.props.height}
                src={container.publicUrlService.getImageUrl(this.props.file)}
                alt=""
            />
        );
    }
}

export default WedImageLogo;
