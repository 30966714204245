import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import _size from "lodash/size";
import { generatePath, Redirect } from "react-router-dom";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import container from "../../../../container";
import { IClientSimple } from "../../../../services/WedApi/Models/Client";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import JSONSchemaValidator, { ValidationErrors } from "../../../../services/JSONSchemaValidator/JSONSchemaValidator";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";

interface StateInterface {
    fields: {
        customerAccount: IClientSimple | null;
        projectName: string;
        projectDescription: string;
    };
    clients: IClientSimple[];
    loading: boolean;
    redirect: string;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    customerAccount?: IClientSimple;
    close: () => void;
}

class CreateProject extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            customerAccount: {
                type: "object",
            },
            projectName: {
                type: "string",
                minLength: 3,
                maxLength: 100,
            },
            projectDescription: {
                type: "string",
                maxLength: 500,
            },
        },
        required: ["customerAccount", "projectName"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                customerAccount: this.props.intl.formatMessage({ id: "app.createProject.error.customerAccount" }),
                projectName: this.props.intl.formatMessage({ id: "app.createProject.error.projectName" }),
                projectDescription: this.props.intl.formatMessage({ id: "app.createProject.error.projectDescription" }),
            },
        },
    };

    readonly state = {
        fields: {
            customerAccount: this.props.customerAccount || null,
            projectName: "",
            projectDescription: "",
        },
        clients: [],
        loading: false,
        redirect: "",
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            // eslint-disable-next-line
            const customerAccount: IClientSimple = this.state.fields.customerAccount as any as IClientSimple;
            const projectId: number = await container.projectActionApiService.createProject({
                desc: this.state.fields.projectDescription || undefined,
                name: this.state.fields.projectName,
                ecOrgID: customerAccount.id,
            });

            this.props.close();
            return this.setState({ redirect: generatePath(RoutesEnum.SUMMARY, { projectId: projectId }) });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_NAME_INVALID) {
                keyTranslate = "app.createProject.error.projectName";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PROJECT_DESCRIPTION_INVALID) {
                keyTranslate = "app.createProject.error.projectDescription";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }

        this.setState({ loading: false });
    };

    async componentDidMount() {
        this.setState({ clients: await container.clientService.getSimpleList() });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <div className="create-project">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.createProject.header.title" />
                </PopupHeader>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div>
                        <Autocomplete
                            fullWidth
                            id="customerAccount"
                            options={this.state.clients}
                            getOptionLabel={(option: IClientSimple) => option.name}
                            value={this.state.fields.customerAccount}
                            disabled={!!this.props.customerAccount}
                            // eslint-disable-next-line
                            onChange={(event: React.ChangeEvent<any>, value: IClientSimple | null) => {
                                this.setState((prevState) => ({
                                    fields: {
                                        ...prevState.fields,
                                        ["customerAccount"]: value,
                                    },
                                }));
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    required
                                    label={<FormattedMessage id={"app.createProject.form.customerAccount"} />}
                                    name="customerAccount"
                                    margin="normal"
                                    variant="outlined"
                                    autoComplete="off"
                                />
                            )}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="projectName"
                            label={<FormattedMessage id={"app.createProject.form.projectName"} />}
                            name="projectName"
                            autoComplete="off"
                            value={this.state.fields.projectName}
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="projectDescription"
                            label={<FormattedMessage id={"app.createProject.form.projectDescription"} />}
                            name="projectDescription"
                            autoComplete="off"
                            multiline
                            rows={5}
                            value={this.state.fields.projectDescription}
                            onChange={this.handleChange}
                        />
                    </div>
                    <PopupAction>
                        <Button type="submit">
                            <FormattedMessage id="app.createProject.button.create" />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(CreateProject));
