import SearchActionApiService from "../SearchActionApiService";
import { ISearchResult } from "../Models/Search";
import { convertStrToDate } from "../Utils/DateConverter";

export class SearchService {
    constructor(private readonly searchActionApiService: SearchActionApiService) {}

    public async getSearchResult(query: string): Promise<ISearchResult[]> {
        if (query.length === 0) {
            return [];
        }

        let value = "";
        query
            .split(/[\s\n\t]+/)
            .filter((tag) => tag.length > 0 && tag.length < 50)
            .forEach((tag) => {
                if (value.length + tag.length < 500) {
                    const prefix = value.length > 0 ? "," : "";
                    value += `${prefix}${tag}`;
                }
            });

        if (value.length === 0) {
            return [];
        }

        const data = await this.searchActionApiService.getSearchResult(value);
        return data.items.map((item) => {
            const obj = {
                ...item.Value,
                date: convertStrToDate(item.Value.date),
                dirId: item.Value.parentID || undefined,
            };
            if (!obj.name && (obj.firstName || obj.lastName)) {
                obj.name = `${obj.firstName} ${obj.lastName}`;
            }
            return {
                type: item.Type,
                value: obj,
            };
        });
    }
}
