import React, { Component } from "react";
import { IShareCollection } from "../../../../services/WedApi/Models/ShareCollection";
import { IActivities } from "../../../../services/WedApi/Models/Activities";
import { injectIntl, WrappedComponentProps } from "react-intl";
import SidebarTitle from "../../../../components/sidebar/SidebarTitle/SidebarTitle";
import SidebarMenu from "../../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarPreview from "../../../../components/sidebar/SidebarPreview/SidebarPreview";
import container from "../../../../container";
import { SizeImageEnum } from "../../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import SidebarInformationDetails from "../../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import { FileSize } from "../../../../components/file/FileSize/FileSize";
import WedDate from "../../../../components/formatter/WedDate";
import ItemDotStatus from "../../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import SidebarDescription from "../../../../components/sidebar/SidebarDescription/SidebarDescription";
import SidebarApprovalStatus from "../../../../components/sidebar/SidebarApprovalStatus/SidebarApprovalStatus";
import CommentBox from "../../../../components/comments/CommentBox/CommentBox";
import { NoteObjectType } from "../../../../services/WedApi/Models/Note";
import SidebarActivities from "../../../../components/sidebar/SidebarActivities/SidebarActivities";
import { AppNotFoundItemError } from "../../../../errors/error-app";
import "../../../../components/sidebar/Sidebar/Sidebar.scss";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    getFileVersionProofs,
    showCollectionDotStatusColor,
} from "../../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends WrappedComponentProps {
    token: string;
    collection: IShareCollection;
    fileId: number;
    versionId?: number;
    activities: IActivities[];
    disabled?: boolean;
}

interface StateInterface {}

class CollectionFileSidebarShare extends Component<PropsInterface, StateInterface> {
    render() {
        const { intl, disabled, collection, fileId, token, activities, versionId } = this.props;
        const file = collection.files.find((file) => file.id === fileId);
        if (!file) {
            throw new AppNotFoundItemError("IShareFile", fileId, `Not found share file id ${fileId}`);
        }
        const fileVersionId = versionId || file?.newestVersionId || undefined;
        const fileVersionNo = fileVersionId ? file?.versions.find((version) => version.id === fileVersionId)?.versionNumber : undefined;
        const approvalStatusOptions = {
            approvalRequired: file.approvalRequired,
            approvalFlag: file.approvalFlag,
            versionId: this.props.versionId,
            file: file,
        };
        const proofs = getFileVersionProofs(approvalStatusOptions);

        return (
            <div className="collection-file-sidebar sidebar--container">
                <SidebarTitle
                    title={file.name}
                    suffix={
                        fileVersionNo ? intl.formatMessage({ id: "app.sidebar.menu.fileVersion" }, { fileVersionNo: `${fileVersionNo}` }) : undefined
                    }
                />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarPreview
                                url={container.filesService.getSharedFileUrl(
                                    token,
                                    file.id,
                                    file.newestVersionId,
                                    collection.id,
                                    SizeImageEnum.THUMB
                                )}
                                extension={file.extension}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.type" }),
                                        values: [
                                            { text: file.extension.toUpperCase() },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.size" }),
                                                labelFormat: true,
                                            },
                                            {
                                                key: "sidebar-collection-file-size",
                                                text: <FileSize size={file.size} />,
                                            },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.location" }),
                                        values: [{ text: collection.name }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.updated" }),
                                        values: [
                                            { text: file.updated ? <WedDate date={file.updated} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${file.updatedBy?.firstName} ${file.updatedBy?.lastName}` },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: file.created ? <WedDate date={file.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${file.createdBy?.firstName} ${file.createdBy?.lastName}` },
                                        ],
                                    },
                                ].concat(
                                    ...[
                                        showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, approvalStatusOptions)
                                            ? [
                                                  {
                                                      label: intl.formatMessage({ id: "app.sidebar.details.approval" }),
                                                      values: [
                                                          {
                                                              text: (
                                                                  <ItemDotStatus
                                                                      color={getCollectionDotStatusColor(
                                                                          CollectionDotStatusColorItemType.COLLECTION_FILE,
                                                                          approvalStatusOptions
                                                                      )}
                                                                  />
                                                              ),
                                                          },
                                                      ],
                                                  },
                                              ]
                                            : [],
                                    ]
                                )}
                            />
                            <SidebarDescription allowToAdd={false} description={file.description} />
                            {proofs && proofs.length > 0 && (
                                <SidebarApprovalStatus title={intl.formatMessage({ id: "app.sidebar.approvalStatus.title" })} proofs={proofs} />
                            )}
                            <CommentBox
                                token={token}
                                disabled={disabled}
                                object={{
                                    id: file.id,
                                    type: NoteObjectType.file,
                                    data: {
                                        fileVersionId: this.props.versionId || file.newestVersionId,
                                        collectionId: collection.id,
                                    },
                                }}
                                notes={file.publicNotes}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={activities} />
                        </>,
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(CollectionFileSidebarShare);
