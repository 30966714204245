import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import { IFolder } from "../../../services/WedApi/Models/File";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import { FileTypeEnum, isMyLibraryFileType } from "../../../components/template/Type/FileTypeEnum";
import SidebarInformationFiles from "../../../components/sidebar/SidebarInformationFiles/SidebarInformationFiles";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import ChangeDescriptionPopup from "../../../components/project/Files/ChangeDescriptionPopup";
import { ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import SidebarInitials from "../../../components/sidebar/SidebarInitials/SidebarInitials";
import { WedIconFolderBlank } from "../../../components/wed-icon/generated/WedIconSvg";
import WedDate from "../../../components/formatter/WedDate";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import { IActivitiesObjectType } from "../../../services/WedApi/Services/ActivitiesService";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";

interface PropsInterface extends WrappedComponentProps {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    directoryId: number;
    fileType?: FileTypeEnum;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    folder: IFolder | undefined;
    showChangeDescriptionPopup: boolean;
    activities: IActivities[];
}

class FolderSidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        folder: undefined,
        showChangeDescriptionPopup: false,
        activities: [],
    };

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate() {
        await this.fetchData();
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.directoryId != this.props.directoryId ||
            nextState?.folder?.directory?.id != this.state?.folder?.directory?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup
        );
    }

    fetchData = async () => {
        const { directoryId, loadingCallback } = this.props;
        const { folder, showChangeDescriptionPopup } = this.state;

        if (!(folder?.directory.id != directoryId || showChangeDescriptionPopup)) {
            return;
        }

        try {
            loadingCallback(true);
            const [folder, activities] = await Promise.all([
                container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, directoryId, { withAccess: true }),
                container.activitiesService.getActivities(IActivitiesObjectType.FOLDER, directoryId),
            ]);
            this.setState({ folder: folder, activities }, () => {
                if (this.props.directoryId == folder.directory.id) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ folder: undefined });
        }
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            this.props.handleSidebarChanges();
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    render() {
        if (!this.state.folder || this.state.folder.directory.id != this.props.directoryId) {
            return null;
        }

        const { intl, fileType, disabled } = this.props;
        const {
            folder: { directory, directories },
            showChangeDescriptionPopup,
        } = this.state;

        return (
            <div className="folder-sidebar sidebar--container">
                <SidebarTitle title={directory.name} />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarInformationFiles
                                icon={<WedIconFolderBlank />}
                                filesCount={directory.numberOfFiles || 0}
                                folderCount={directories?.length || 0}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.type" }),
                                        values: [{ text: intl.formatMessage({ id: "app.sidebar.details.folder" }) }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.files" }),
                                        values: [{ text: directory.numberOfFiles.toString() }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            {
                                                text: directory.created ? <WedDate date={directory.created} /> : <></>,
                                            },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.owner" }),
                                        values: [{ text: `${directory.createdBy?.firstName} ${directory.createdBy?.lastName}` }],
                                    },
                                ]}
                            />
                            <SidebarDescription
                                allowToAdd={!disabled}
                                description={directory.description}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            {fileType && isMyLibraryFileType(fileType) && (
                                <SidebarInitials
                                    title={intl.formatMessage({ id: "app.sidebar.access.title" })}
                                    items={[
                                        {
                                            label: intl.formatMessage({ id: "app.sidebar.initials.readAccess" }),
                                            userType: InitialsUserType.INTERNAL,
                                            data:
                                                directory.readAccess?.map((user) => {
                                                    const data = getInitialsAndOwner(user);
                                                    return { fullName: data.owner, initials: data.initials };
                                                }) || [],
                                        },
                                        {
                                            label: intl.formatMessage({ id: "app.sidebar.initials.writeAccess" }),
                                            userType: InitialsUserType.INTERNAL,
                                            data:
                                                directory.writeAccess?.map((user) => {
                                                    const data = getInitialsAndOwner(user);
                                                    return { fullName: data.owner, initials: data.initials };
                                                }) || [],
                                        },
                                    ]}
                                />
                            )}
                        </>,
                        <>
                            <SidebarActivities activities={this.state.activities} />
                        </>,
                    ]}
                />
                {showChangeDescriptionPopup && (
                    <ChangeDescriptionPopup
                        id={directory.id}
                        type={ISelectItemType.directory}
                        object={directory}
                        handleClose={() => this.onCloseChangeDescriptionPopup(false)}
                        onSuccess={() => this.onCloseChangeDescriptionPopup(true)}
                    />
                )}
            </div>
        );
    }
}

export default injectIntl(FolderSidebar);
