import React from "react";
import WedDropDownMenuItem, { IMenuItem } from "../../wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import { WedDropDownMenu } from "../../wed-drop-down-menu/WedDropDownMenu/WedDropDownMenu";
import "./FolderListMenu.scss";

interface PropsInterface {
    items: IMenuItem[];
}

interface StateInterface {}

export class DropDownFolderListMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { items } = this.props;
        return (
            <WedDropDownMenu className="folder-list-menu">
                {items.map((item) => {
                    return <WedDropDownMenuItem key={`${item.keyPrefix}-menu-item-${item.label}`} item={item} noTranslate={true} />;
                })}
            </WedDropDownMenu>
        );
    }
}
