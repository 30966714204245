import React, {Component} from "react";
import {ClientDetailLoaderProps} from "../../../components/client/ClientDetailLoader/ClientDetailLoader";
import {WedMainMenuContainer} from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import ClientMenu from "../../../components/main-menu/ClientMenu/ClientMenu";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IClientExtended} from "../../../services/WedApi/Models/Client";
import container from "../../../container";
import {IClientContact, IClientContactExtended} from "../../../services/WedApi/Models/ClientContact";
import ClientContactTile from "../../../components/tile/ClientContactTile/ClientContactTile";
import {ISelectItemType} from "../../../components/status-action-button/StatusActionButton";
import EditClientAccountPopup from "../../../components/client/EditClientAccountPopup/EditClientAccountPopup";
import DeleteClientAccountPopup from "../../../components/client/DeleteClientAccountPopup/DeleteClientAccountPopup";
import ListProjectContent from "../../../components/template/ListProjectContent/ListProjectContent";
import {ApplicationState} from "../../../store";
import {IUserViewModeEnum} from "../../../services/WedApi/Models/User";
import {connect} from "react-redux";
import "./ClientContactsDetailsPage.scss";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import {FormattedMessage} from "react-intl";
import ClientContactsListButtons
    from "../ClientContactsPage/ClientContactsPageHeader/ClientContactsListButtons/ClientContactsListButtons";
import ProfileWithProjects from "../../../components/template/ProfileWithProjects/ProfileWithProjects";
import {generatePath, withRouter, RouteComponentProps} from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";

interface BasePropsInterface extends ClientDetailLoaderProps, RouteComponentProps {
    client: IClientExtended;
    clientContactId: number;
}

interface PropsInterface extends BasePropsInterface {
    isTableMode?: boolean;
}

interface StateInterface {
    isLoading: boolean;
    clientContact?: IClientContactExtended;
    projectTabs: "active" | "closed";
    popup?: {
        createContact?: { open: boolean };
        editContact?: { clientContact: IClientContact };
        deleteContact?: { clientContact: IClientContact };
    };
}

class ClientContactsDetailsPage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            clientContact: undefined,
            projectTabs: "active",
        };
        this.closePopup = this.closePopup.bind(this);
        this.createContactClick = this.createContactClick.bind(this);
        this.editContactClick = this.editContactClick.bind(this);
        this.deleteContactClick = this.deleteContactClick.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.setIsLoading = this.setIsLoading.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.clientContactId !== prevProps.clientContactId) {
            this.setState({
                isLoading: true,
                clientContact: undefined,
                projectTabs: "active",
            });
            await this.fetchData();
        }
    }

    private async fetchData() {
        const clientContact = await container.clientContactService.getClientContactDetails(this.props.client.id, this.props.clientContactId);
        this.setState({
            isLoading: false,
            clientContact,
        });
    }

    private async closePopup(updated?: boolean) {
        this.setState({popup: undefined});
        if (updated) {
            this.setState({isLoading: true});
            await this.fetchData();
        }
    }

    private createContactClick() {
        this.setState({popup: {createContact: {open: true}}});
    }

    private editContactClick(clientContact: IClientContact) {
        this.setState({popup: {editContact: {clientContact}}});
    }

    private deleteContactClick(clientContact: IClientContact) {
        this.setState({popup: {deleteContact: {clientContact}}});
    }

    private setIsLoading() {
        this.setState({isLoading: true});
    }

    private async handleStatusChange() {
        await this.fetchData();
    }

    render() {
        return (
            <div className="client-contacts-page-details-page">
                {this.state.popup?.createContact?.open && (
                    <EditClientAccountPopup client={this.props.client} clientContact={undefined}
                                            onClickClose={this.closePopup}/>
                )}
                {this.state.popup?.editContact?.clientContact && (
                    <EditClientAccountPopup
                        client={this.props.client}
                        clientContact={this.state.popup.editContact.clientContact}
                        onClickClose={this.closePopup}
                    />
                )}
                {this.state.popup?.deleteContact?.clientContact && (
                    <DeleteClientAccountPopup clientContact={this.state.popup.deleteContact.clientContact}
                                              onClickClose={async (updated?: boolean) => {
                                                  if (updated) {
                                                      this.props.history.push(generatePath(RoutesEnum.CLIENTS_CONTACTS, {clientId: this.props.client.id}));
                                                      return;
                                                  }
                                                  await this.closePopup(false);
                                              }}/>
                )}
                <WedMainMenuContainer
                    menu={<ClientMenu clientId={this.props.client.id} activeTab="contacts"/>}
                    buttons={
                        this.state.clientContact ? (
                            <ClientContactsListButtons
                                client={this.props.client}
                                selectedItems={[
                                    {
                                        id: this.state.clientContact.id,
                                        userIsOwner: this.props.client.userIsOwner,
                                        type: ISelectItemType.clientContact,
                                        object: this.state.clientContact,
                                    },
                                ]}
                                createContactClick={() => {
                                    this.createContactClick();
                                }}
                                editContactClick={() => {
                                    this.state.clientContact && this.editContactClick(this.state.clientContact);
                                }}
                                deleteContactClick={() => {
                                    this.state.clientContact && this.deleteContactClick(this.state.clientContact);
                                }}
                            />
                        ) : undefined
                    }
                    sidebar={<MenuControlIcons noDisplayTrash={true} disableSidebarOpen={true}/>}
                />
                {this.state.isLoading || !this.state.clientContact ? (
                    <LinearProgress/>
                ) : (
                    <div className="client-contacts-page-details-page--container">
                        <ProfileWithProjects
                            profile={
                                <ClientContactTile
                                    withInitialsColor={true}
                                    disableHover={true}
                                    clientId={this.props.client.id}
                                    clientContact={this.state.clientContact}
                                />
                            }
                            projects={[
                                {
                                    id: "active",
                                    label: <FormattedMessage id="app.ClientContactsDetailsPage.activeTab"/>,
                                    content: (
                                        <ListProjectContent
                                            isTableMode={this.props.isTableMode as boolean}
                                            handleSelectAll={undefined}
                                            projects={this.state.clientContact.activeProjects}
                                            selectedProjects={[]}
                                            handleSelectProject={undefined}
                                            handleChangeFavorite={undefined}
                                            handleStatusChange={this.handleStatusChange}
                                            setIsLoading={this.setIsLoading}
                                            handleClickOpenCreateProject={undefined}
                                        />
                                    ),
                                },
                                {
                                    id: "closed",
                                    label: <FormattedMessage id="app.ClientContactsDetailsPage.closedTab"/>,
                                    content: (
                                        <ListProjectContent
                                            isTableMode={this.props.isTableMode as boolean}
                                            handleSelectAll={undefined}
                                            projects={this.state.clientContact.closedProjects}
                                            selectedProjects={[]}
                                            handleSelectProject={undefined}
                                            handleChangeFavorite={undefined}
                                            handleStatusChange={this.handleStatusChange}
                                            setIsLoading={this.setIsLoading}
                                            handleClickOpenCreateProject={undefined}
                                        />
                                    ),
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    };
};

export default withRouter(connect(mapStateToProps)(ClientContactsDetailsPage));
