import React, { Component } from "react";
import AdminPageSecondaryNavigation from "../AdminPageSecondaryNavigation/AdminPageSecondaryNavigation";
import "./AdminPanelContent.scss";
import classNames from "classnames";

interface PropsInterface {
    className?: string;
    menuItems?: {
        path: string;
        label: string;
    }[];
    children: React.ReactNode | React.ReactNodeArray;
}

export class AdminPanelContent extends Component<PropsInterface> {
    render() {
        return (
            <div className={classNames("admin-panel-content", this.props.className)}>
                <AdminPageSecondaryNavigation menuItems={this.props.menuItems?.map((item) => ({ label: item.label, path: item.path })) || []} />
                <div className="admin-panel-content--container">{this.props.children}</div>
            </div>
        );
    }
}
