import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import ApprovalRequest from "../ApprovalRequest/ApprovalRequest";
import { IFile } from "../../../../services/WedApi/Models/File";

interface PropsInterface {
    file: IFile;
    clientId: number;
    collectionId: number;
    open: boolean;
    handleClose: (isUpdate?: boolean) => void;
}

interface StateInterface {}

export class ApprovalRequestPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    private handleClose() {
        this.props.handleClose(false);
    }

    private handleSuccess() {
        this.props.handleClose(true);
    }

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.handleClose} maxWidth="sm">
                <PopupContent>
                    <ApprovalRequest
                        file={this.props.file}
                        clientId={this.props.clientId}
                        collectionId={this.props.collectionId}
                        close={this.handleClose}
                        onSuccess={this.handleSuccess}
                    />
                </PopupContent>
            </Popup>
        );
    }
}
