import { Action, Dispatch } from "redux";
import { ICollection } from "../services/WedApi/Models/Collection";
import { INoteAnnotation } from "../services/WedApi/Models/Note";

export interface SetWedImageGalleryDataProps {
    setWedImageGalleryData: (data: WedImageGalleryDataProps) => void;
    clearWedImageGalleryData: () => void;
}

export type WedImageGalleryDataCollectionProps = Pick<ICollection, "locked">;

export interface WedImageGalleryDataProps {
    collectionId?: number;
    collectionData?: WedImageGalleryDataCollectionProps;
    dirId?: number;
    objectId?: number;
    objectName?: string;
    versionId?: number;
    numOfVersions?: number;
    extension?: string;
    mediaData?: { objectId: number; url: string }[];
    url?: string;
    onChangeFile?: (fileId: number) => void;
    annotation?: INoteAnnotation;
}

const initialState: WedImageGalleryDataProps = {
    collectionId: undefined,
    dirId: undefined,
    objectId: undefined,
    objectName: undefined,
    versionId: undefined,
    numOfVersions: undefined,
    extension: undefined,
    mediaData: undefined,
    url: undefined,
    onChangeFile: undefined,
    annotation: undefined,
};

export function setWedImageAnnotation(dispatch: Dispatch): (data?: INoteAnnotation) => void {
    return (data?: INoteAnnotation) => {
        dispatch({
            type: WedImageGalleryAction.SET_MEDIA_ANNOTATION,
            payload: {
                annotation: data,
            },
        });
    };
}

export function setWedImageGalleryData(dispatch: Dispatch): (data?: WedImageGalleryDataProps) => void {
    return (data?: WedImageGalleryDataProps) => {
        dispatch({
            type: WedImageGalleryAction.SET_MEDIA,
            payload: {
                ...data,
            },
        });
    };
}

export function clearWedImageGalleryData(dispatch: Dispatch): () => void {
    return () => {
        dispatch({
            type: WedImageGalleryAction.CLEAR_MEDIA,
            payload: {},
        });
    };
}

export enum WedImageGalleryAction {
    SET_MEDIA = "WedImageGalleryAction/SET_MEDIA",
    SET_MEDIA_ANNOTATION = "WedImageGalleryAction/SET_MEDIA_ANNOTATION",
    CLEAR_MEDIA = "WedImageGalleryAction/CLEAR_MEDIA",
}

// eslint-disable-next-line
export const WedImageGalleryDataReducer = (state: WedImageGalleryDataProps = initialState, action: Action & { payload: any }) => {
    switch (action.type) {
        case WedImageGalleryAction.SET_MEDIA:
            return { ...state, ...action.payload, annotation: undefined };
        case WedImageGalleryAction.SET_MEDIA_ANNOTATION:
            return { ...state, annotation: action.payload.annotation };
        case WedImageGalleryAction.CLEAR_MEDIA:
            return { ...initialState };
    }
    return state;
};
