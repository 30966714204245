import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import CreateFolder from "../CreateFolder/CreateFolder";

interface PropsInterface {
    open: boolean;
    handleClose: () => void;
    dirId: number;
}

interface StateInterface {}

export class CreateFolderPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose} maxWidth="sm">
                <PopupContent>
                    <CreateFolder dirId={this.props.dirId} handleClose={this.props.handleClose} />
                </PopupContent>
            </Popup>
        );
    }
}
