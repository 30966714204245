import React from "react";
import classnames from "classnames";
import "./SidebarMenu.scss";
import SidebarScrollbars from "../SidebarScrollbars/SidebarScrollbars";

interface PropsInterface {
    labels: string[];
    contents: React.ReactNode[];
}

interface StateInterface {
    displayIndex: number;
}

const getDefaultTabIndex = () => {
    const idx = window.sessionStorage.getItem("sidebarTabIndex");
    if (idx) {
        const tabIndex = parseInt(idx);
        if (!isNaN(tabIndex)) {
            return tabIndex;
        }
    }
    return 0;
};

class SidebarMenu extends React.Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        displayIndex: getDefaultTabIndex(),
    };

    onMenuClick = (index: number) => {
        try {
            window.sessionStorage.setItem("sidebarTabIndex", index.toString());
        } catch (err) {
            //
        }
        this.setState({
            displayIndex: index,
        });
    };

    render() {
        const { labels, contents } = this.props;
        const { displayIndex } = this.state;

        const displayContent = contents[displayIndex];

        return (
            <div className="sidebar-menu">
                <ul className="sidebar-menu__container">
                    {labels.map((label, index) => (
                        <li
                            onClick={() => this.onMenuClick(index)}
                            key={label}
                            className={classnames("sidebar-menu__label", {
                                "sidebar-menu__label--active": displayIndex == index,
                            })}
                        >
                            {label}
                        </li>
                    ))}
                </ul>
                <div className="sidebar-menu__content">
                    <SidebarScrollbars>{displayContent}</SidebarScrollbars>
                </div>
            </div>
        );
    }
}

export default SidebarMenu;
