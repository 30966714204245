import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconPlusAdd } from "../../../wed-icon/generated/WedIconSvg";
import { ICollectionForObjectItem } from "../../../../services/WedApi/Services/CollectionAddFilesService";
import { DropDownFolderListMenu } from "../../../drop-down-menu/FolderListMenu/FolderListMenu";
import { CreateCollectionPopup } from "../CreateCollectionPopup/CreateCollectionPopup";
import container from "../../../../container";
import { FileTypeEnum } from "../../../template/Type/FileTypeEnum";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    projectId: number;
    collections: ICollectionForObjectItem;
    handleHideCollectionMenu: () => void;
    fileType: FileTypeEnum;
}

interface StateInterface {
    showCreateCollectionPopup: boolean;
}

class ListCollectionForObjectItems extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            showCreateCollectionPopup: false,
        };
        this.onCloseAddCollectionPopup = this.onCloseAddCollectionPopup.bind(this);
    }

    // eslint-disable-next-line
    private async onCloseAddCollectionPopup(collectionId?: any) {
        if (collectionId && typeof collectionId === "number") {
            await this.moveFileToCollection(collectionId, true);
        }

        this.setState({
            showCreateCollectionPopup: false,
        });
        this.props.handleHideCollectionMenu && this.props.handleHideCollectionMenu();
    }

    private showAddToCollectionPopup() {
        this.props.handleHideCollectionMenu && this.props.handleHideCollectionMenu();
        this.setState({
            showCreateCollectionPopup: true,
        });
    }

    private async moveFileToCollection(collectionId: number, isNewCollection?: boolean): Promise<void> {
        this.props.handleHideCollectionMenu && this.props.handleHideCollectionMenu();

        try {
            await container.collectionAddFilesService.updateCollectionByObjectItems(
                collectionId,
                this.props.collections.selectedItems,
                this.props.fileType
            );
            this.props.enqueueSnackbar(
                this.props.intl.formatMessage({
                    id: isNewCollection
                        ? "app.projectFilesActionButton.collectionCreate.success"
                        : "app.projectFilesActionButton.success.collection.files",
                }),
                {
                    variant: "success",
                }
            );
        } catch (err) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), {
                variant: "error",
            });
        }
    }

    render() {
        const items = [
            {
                label: this.props.intl.formatMessage({ id: "app.statusActionButton.createNewCollection" }),
                icon: <WedIcon icon={<WedIconPlusAdd />} size={SizeType.SMALL} />,
                click: () => {
                    this.showAddToCollectionPopup();
                },
            },
            ...this.props.collections.collection.map((collection) => {
                return {
                    label: collection.name,
                    click: async () => {
                        await this.moveFileToCollection(collection.id);
                    },
                    disabled: collection.locked || !collection.userIsOwner || collection.filesToAdd.length === 0,
                };
            }),
        ];
        return (
            <>
                <DropDownFolderListMenu items={items} />
                <CreateCollectionPopup
                    open={this.state.showCreateCollectionPopup}
                    handleClose={this.onCloseAddCollectionPopup}
                    projectId={this.props.projectId}
                    redirectToCollection={false}
                />
            </>
        );
    }
}

export default withSnackbar(injectIntl(ListCollectionForObjectItems));
