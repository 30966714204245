import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IAccountTeamUser, IAccountTeamUserProjectRole } from "../../../services/WedApi/Models/User";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    clientId: number;
    userMember?: IAccountTeamUser;
    newRole?: IAccountTeamUserProjectRole;
    close: (changed?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
}

class UserClientChangeRolePopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.setNewRole = this.setNewRole.bind(this);
    }

    private async setNewRole() {
        if (this.props.userMember && this.props.newRole) {
            await container.clientService.updateTeamUserRole(this.props.clientId, this.props.userMember.id, this.props.newRole);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.user.UserClientChangeRolePopup.success" }), {
                variant: "success",
            });
        }
    }

    render() {
        return (
            <PopupConfirmButton
                open={!!this.props.userMember && !!this.props.newRole}
                close={this.props.close}
                doIt={this.setNewRole}
                header={<FormattedMessage id={"app.user.UserClientChangeRolePopup.header.title"} />}
                content={
                    this.props.userMember && this.props.newRole ? (
                        <FormattedMessage
                            id={"app.user.UserClientChangeRolePopup.header.content"}
                            values={{
                                roleName: this.props.intl.formatMessage({ id: `app.account.role.${this.props.newRole}` }),
                                firstName: this.props.userMember.firstName,
                                lastName: this.props.userMember.lastName,
                            }}
                        />
                    ) : undefined
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(UserClientChangeRolePopup));
