import React from "react";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import "./action-tile.scss";
import classNames from "classnames";

interface PropsInterface {
    title: string;
    className?: string;
    onClick?: () => void;
}

interface StateInterface {}

export default class ActionTile extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { title, onClick } = this.props;

        return (
            <div onClick={onClick} className={classNames("tile action-tile", ...(this.props.className ? [this.props.className] : []))}>
                <div className="action-tile__icon">
                    <AddOutlinedIcon />
                </div>
                <p className="action-tile__title">{title}</p>
            </div>
        );
    }
}
