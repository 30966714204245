import { AdminActionApiService } from "../AdminActionApiService";
import {
    IApiOrganizationCompanyData,
    IApiOrganizationUser,
    IApiSubscriptionPlan,
    IBillingHistory,
    ICurrentPeriod,
    IOrganizationCompanyData,
    IOrganizationUser,
    IPaymentMethodInfo,
    ISubscriptionPlan,
    IUser,
    IUserMyProfileRole,
} from "../Models/User";
import { convertStrToDate } from "../Utils/DateConverter";
import { convertUserRoleId } from "./BaseConverter";
import { IInvitationEmail } from "../Models/InvitationEmail";
import UserActionApiService from "../UserActionApiService";
import { LogicError } from "../../../errors/error-app";
import { ICountry } from "../Models/Country";
import { CountryService } from "./CountryService";
import { uniq } from "lodash";

export class AdminService {
    constructor(
        private readonly adminActionApiService: AdminActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly countryService: CountryService
    ) {}

    private static convertIApiOrganizationUserToIOrganizationUser(item: IApiOrganizationUser): IOrganizationUser {
        return {
            id: item.id,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            created: convertStrToDate(item.createdAt),
            active: item.active,
            role: convertUserRoleId(item.roleID),
            cCode: item.cCode,
            phone: item.phone,
        };
    }

    private static convertIApiOrganizationCompanyDataToIOrganizationCompanyData(
        data: IApiOrganizationCompanyData,
        countries: ICountry[]
    ): IOrganizationCompanyData {
        return {
            id: data.id,
            companyName: data.name,
            companyShortName: data.shortName,
            taxId: data.taxID,
            street: data.streetAddress1,
            street2: data.streetAddress2,
            zipCode: data.zipCode,
            city: data.city,
            country: data.countryID ? countries.find((country) => country.id === data.countryID) || null : null,
            state: data.state,
        };
    }

    private static convertIApiSubscriptionPlanToISubscriptionPlan(data: IApiSubscriptionPlan, users: IUser[]): ISubscriptionPlan {
        return {
            ...data,
            activatedBy: users.find((user) => user.id === data.activatedBy),
            activationTime: convertStrToDate(data.activationTime),
            expiresAt: data.expiresAt ? convertStrToDate(data.expiresAt) : undefined,
        };
    }

    public async getOrganizationUsers(): Promise<IOrganizationUser[]> {
        const users = await this.adminActionApiService.getOrganizationUsers();
        return users.items.map(AdminService.convertIApiOrganizationUserToIOrganizationUser);
    }

    public async sendInvites(emails: string[]): Promise<void> {
        await this.adminActionApiService.sendInvites(emails);
    }

    public async getPendingInvitations(): Promise<IInvitationEmail[]> {
        const data = await this.adminActionApiService.getPendingInvitations();
        const userIds: number[] = [];
        data.items.forEach((item) => userIds.push(item.invitedBy));
        const users: IUser[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(userIds)).items : [];

        return data.items.map((item) => {
            return {
                created: convertStrToDate(item.createdAt),
                email: item.email,
                expirationTime: convertStrToDate(item.expirationTime),
                invitedBy: users.find((user) => user.id === item.invitedBy),
            };
        });
    }

    public async removePendingInvitation(email: string): Promise<void> {
        await this.adminActionApiService.removePendingInvitation(email);
    }

    public async setUserStatus(userId: number, status: boolean): Promise<void> {
        await this.adminActionApiService.setUserStatus(userId, status);
    }

    public async setUserRole(userId: number, role: IUserMyProfileRole) {
        if (role === IUserMyProfileRole.ADMIN_USER) {
            await this.adminActionApiService.setUserRole(userId, 1);
        } else if (role === IUserMyProfileRole.REGULAR_USER) {
            await this.adminActionApiService.setUserRole(userId, 2);
        } else {
            throw new LogicError(`Not supported role value ${role}`);
        }
    }

    public async removeUser(userId: number): Promise<void> {
        await this.adminActionApiService.removeUser(userId);
    }

    public async getCompanyData(countries?: ICountry[]): Promise<IOrganizationCompanyData> {
        if (countries === undefined) {
            countries = await this.countryService.getCountries();
        }
        return AdminService.convertIApiOrganizationCompanyDataToIOrganizationCompanyData(
            await this.adminActionApiService.getCompanyData(),
            countries
        );
    }

    public async getApiCompanyData(): Promise<IApiOrganizationCompanyData> {
        const data = await this.adminActionApiService.getCompanyData();
        return data;
    }

    public async updateCompanyData(
        id: number,
        companyName: string,
        companyShortName: string,
        taxId: string,
        street: string,
        street2: string,
        zipCode: string,
        city: string,
        state: string,
        country: ICountry | null
    ): Promise<void> {
        await this.adminActionApiService.updateCompanyData(
            id,
            companyName,
            companyShortName,
            taxId,
            street,
            street2,
            zipCode,
            city,
            state,
            country ? country.id : 0
        );
    }

    public async getBillingHistory(): Promise<IBillingHistory[]> {
        return (await this.adminActionApiService.getBillingHistory()).items.map((item) => ({
            ...item,
            issueDate: convertStrToDate(item.issueDate),
        }));
    }

    public async getAllInvoiceUrl(): Promise<string> {
        return this.adminActionApiService.getAllInvoiceUrl();
    }

    public async getInvoiceUrl(id: number): Promise<string> {
        return this.adminActionApiService.getInvoiceUrl(id);
    }

    public async getCurrentPeriod(): Promise<ICurrentPeriod> {
        const data = await this.adminActionApiService.getCurrentPeriod();
        return {
            ...data,
            lastPayment: data.lastPayment,
            nextPayment: data.nextPayment,
        };
    }

    public async getCurrentPaymentMethod(): Promise<IPaymentMethodInfo> {
        const data = await this.adminActionApiService.getCurrentPaymentMethod();
        return {
            ...data,
            updatedAt: convertStrToDate(data.updatedAt),
        };
    }

    public async getSubscriptionPlans(): Promise<ISubscriptionPlan[]> {
        const items = (await this.adminActionApiService.getSubscriptionPlans()).plans;
        const userIds: number[] = [];
        items.forEach((item) => {
            if (item.activatedBy) {
                userIds.push(item.activatedBy);
            }
        });
        const users: IUser[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniq(userIds))).items : [];
        return items.map((item) => {
            return AdminService.convertIApiSubscriptionPlanToISubscriptionPlan(item, users);
        });
    }

    public async reactivateSubscription(planId: number): Promise<{ redirectUrl: string }> {
        return await this.adminActionApiService.reactivateSubscription(planId);
    }

    public async updateSubscriptionPlan(planId: number): Promise<void> {
        await this.adminActionApiService.updateSubscriptionPlan(planId);
    }

    public async initCancelSubscriptionPlan(): Promise<void> {
        await this.adminActionApiService.cancelSubscriptionPlan(true);
    }

    public async revokeCancelSubscriptionPlan(): Promise<void> {
        await this.adminActionApiService.cancelSubscriptionPlan(false);
    }
}
