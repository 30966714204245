import React from "react";
import DeleteCollection from "../DeleteCollection/DeleteCollection";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import Collection from "../../../../domain/Collection";

interface PropsInterface {
    open: boolean;
    handleClose: () => void;
    collection: Collection | undefined;
    successDelete: (collectionId: number) => void;
}

interface StateInterface {}

export class DeleteCollectionPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { collection, successDelete, open, handleClose } = this.props;

        if (!collection) {
            return "";
        }

        return (
            <Popup open={open} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <DeleteCollection close={handleClose} collection={collection} successDelete={successDelete} />
                </PopupContent>
            </Popup>
        );
    }
}
