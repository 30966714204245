import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import { IClientAddress, IClientExtended } from "../../../services/WedApi/Models/Client";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import SidebarSummaryInfo from "../../../components/sidebar/SidebarSummaryInfo/SidebarSummaryInfo";
import { WedIconGroupUser1 } from "../../../components/wed-icon/generated/WedIconSvg";
import SidebarInitials from "../../../components/sidebar/SidebarInitials/SidebarInitials";
import ChangeClientDescriptionPopup from "../../../components/client/ChangeClientDescriptionPopup/ChangeClientDescriptionPopup";
import WedDate from "../../../components/formatter/WedDate";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { IUser } from "../../../services/WedApi/Models/User";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";

interface PropsInterface extends WrappedComponentProps {
    handleSidebarChanges: () => void;
    clientId: number;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    client: (IClientExtended & IClientAddress) | undefined;
    showChangeDescriptionPopup: boolean;
}

class ClientSidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        client: undefined,
        showChangeDescriptionPopup: false,
    };

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>, prevState: Readonly<StateInterface>) {
        const withLoading = prevState.showChangeDescriptionPopup == this.state.showChangeDescriptionPopup;

        await this.fetchData(withLoading);
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.clientId != this.props.clientId ||
            nextState?.client?.id != this.state?.client?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup
        );
    }

    fetchData = async (withLoadingCallback = true) => {
        const { clientId, loadingCallback } = this.props;
        const { client, showChangeDescriptionPopup } = this.state;

        if (!(clientId != client?.id || showChangeDescriptionPopup)) {
            return;
        }

        try {
            withLoadingCallback && loadingCallback(true);
            const [client, clientAddress] = await Promise.all([
                await container.clientService.getClientDetail(clientId, {
                    withMembers: true,
                    withOwners: true,
                    withContacts: true,
                }),
                await container.clientService.getClientAddress(clientId),
            ]);

            this.setState({ client: { ...client, ...clientAddress } }, () => {
                if (withLoadingCallback && this.props.clientId == client.id) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ client: undefined });
        }
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            await this.fetchData(true);
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    render() {
        if (!this.state.client || this.state.client.id != this.props.clientId) {
            return null;
        }

        const { intl } = this.props;
        const { client } = this.state;

        return (
            <div className="project-sidebar sidebar--container">
                <SidebarTitle title={client.name} />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarSummaryInfo
                                icon={<WedIconGroupUser1 />}
                                text={`${client.projectNumOfActive || "-"} / ${client.projectNumOfClosed || "-"} / ${
                                    client.projectNumOfArchived || "-"
                                }`}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.type" }),
                                        values: [{ text: intl.formatMessage({ id: "app.sidebar.details.customerAccount" }) }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.accountId" }),
                                        values: [{ text: client.id.toString() }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: client.created ? <WedDate date={client.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${client.createdBy?.firstName} ${client.createdBy?.lastName}` },
                                        ],
                                    },
                                ]}
                            />
                            <SidebarDescription
                                allowToAdd={client.userIsOwner}
                                description={client.desc}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            <SidebarInitials
                                title={intl.formatMessage({ id: "app.sidebar.accountTeam.title" })}
                                items={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.initials.members" }),
                                        userType: InitialsUserType.INTERNAL,
                                        data:
                                            ([] as IUser[]).concat(...(client.members || []), ...(client.owners || []))?.map((user) => {
                                                const userData = getInitialsAndOwner(user);
                                                return {
                                                    fullName: userData.owner,
                                                    initials: userData.initials,
                                                };
                                            }) || [],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.initials.customers" }),
                                        userType: InitialsUserType.EXTERNAL,
                                        data:
                                            client.contacts?.map((contact) => {
                                                const contactData = getInitialsAndOwner(contact);
                                                return {
                                                    fullName: contactData.owner,
                                                    initials: contactData.initials,
                                                };
                                            }) || [],
                                    },
                                ]}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={[]} />
                        </>,
                    ]}
                />
                <ChangeClientDescriptionPopup
                    client={client}
                    handleClose={this.onCloseChangeDescriptionPopup}
                    open={this.state.showChangeDescriptionPopup}
                    initialDescription={client.desc}
                />
            </div>
        );
    }
}

export default injectIntl(ClientSidebar);
