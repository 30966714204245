import React from "react";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import ProjectInformationSummaryViewDetails from "../ProjectInformationSummaryViewDetails/ProjectInformationSummaryViewDetails";
import { IProjectExtended } from "../../../../services/WedApi/Models/Project";
import InformationSummary from "../../../summary/InformationSummary/InformationSummary";

interface PropsInterface {
    className?: string;
    project: IProjectExtended;
}

interface StateInterface {}

class ProjectInformationSummary extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { project } = this.props;

        return (
            <InformationSummary
                className={this.props.className}
                header={<FormattedMessage id={"app.ProjectInformationSummary.header"} />}
                leftSide={
                    <>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.ProjectInformationSummary.label.projectName"} />}
                            value={project.name || " "}
                            inputProps={{ readOnly: true }}
                            disabled={true}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.ProjectInformationSummary.label.customerName"} />}
                            value={project.ecOrg?.name || " "}
                            inputProps={{ readOnly: true }}
                            disabled={true}
                        />
                    </>
                }
                rightSide={<ProjectInformationSummaryViewDetails project={project} />}
                footer={
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="renameCollection"
                        label={<FormattedMessage id={"app.ProjectInformationSummary.label.description"} />}
                        autoComplete="off"
                        value={project.desc || " "}
                        multiline
                        inputProps={{ readOnly: true }}
                        rows={7}
                        disabled={true}
                    />
                }
            />
        );
    }
}

export default ProjectInformationSummary;
