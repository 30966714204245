import React from "react";
import { Dialog } from "@material-ui/core";
import PopupCloseIcon from "../PopupCloseIcon/PopupCloseIcon";
import "./Popup.scss";
import classNames from "classnames";

interface PropsInterface {
    open: boolean;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    children: React.ReactNode | React.ReactNodeArray;
    handleClose: () => void;
    fullWindow?: boolean;
    className?: string;
}

interface StateInterface {}

export default class Popup extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { open, handleClose, children, maxWidth, fullWindow, className } = this.props;

        return (
            <Dialog
                className={classNames({ "popup-max-height": fullWindow }, className)}
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={fullWindow ? false : maxWidth || "lg"}
                scroll="body"
                fullWidth={true}
            >
                <PopupCloseIcon onClick={this.props.handleClose} />
                {children}
            </Dialog>
        );
    }
}
