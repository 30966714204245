import * as React from "react";

interface PropsInterface {
    size: number;
    inBytes?: boolean;
}

interface StateInterface {}

const SIZE: { label: string; size: number }[] = [
    { label: "KB", size: 1024 },
    { label: "MB", size: 1024 * 1024 },
    { label: "GB", size: 1024 * 1024 * 1024 },
    { label: "TB", size: 1024 * 1024 * 1024 * 1024 },
];

export class FileSize extends React.Component<PropsInterface, StateInterface> {
    render() {
        if (!this.props.size) {
            return <>0&nbsp;KB</>;
        }

        const fileSize = this.props.inBytes ? this.props.size : this.props.size * 1024;
        for (let i = SIZE.length - 1; i >= 0; i--) {
            if (fileSize > SIZE[i].size) {
                return (
                    <>
                        {Math.round(fileSize / SIZE[i].size)}&nbsp;{SIZE[i].label}
                    </>
                );
            }
        }
        return <>1&nbsp;KB</>;
    }
}
