import React from "react";
import { INote, NoteObject, NoteObjectType } from "../../../services/WedApi/Models/Note";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import { TextField } from "@material-ui/core";
import classNames from "classnames";
import container from "../../../container";
import { ProviderContext, withSnackbar } from "notistack";
import isEqual from "lodash/isEqual";
import "./CommentForm.scss";
import { LogicError } from "../../../errors/error-app";
import { WedIconSendMessage } from "../../wed-icon/generated/WedIconSvg";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    token?: string;
    disabled?: boolean;
    reload: () => Promise<void>;
    object: NoteObject;
    note?: INote;
}

interface StateInterface {
    content: string;
    isLoading: boolean;
}

class CommentForm extends React.Component<PropsInterface, StateInterface> {
    COMMENT_MAX_LENGTH: number = 1000;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            content: "",
            isLoading: false,
        };
        this.sendComment = this.sendComment.bind(this);
    }

    componentDidMount() {
        this.setState({ content: "" });
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(prevProps.object, this.props.object) || prevProps.note?.id !== this.props.note?.id) {
            this.setState({ content: "" });
        }
    }

    private updateContent(value: string) {
        this.setState({ content: value.substr(0, this.COMMENT_MAX_LENGTH) || "" });
    }

    private async sendComment() {
        this.setState({ isLoading: true });
        if (this.props.token) {
            return await this.sendCommentByToken(this.props.token);
        }
        try {
            switch (this.props.object.type) {
                case NoteObjectType.file:
                    if (this.props.object.data?.collectionId) {
                        await container.noteService.addCollectionFileNote(
                            this.props.object.id,
                            this.props.object.data?.fileVersionId as number,
                            this.props.object.data?.collectionId,
                            this.state.content,
                            {
                                parentNodeId: this.props.object.data?.parentNoteId || undefined,
                            }
                        );
                        break;
                    } else {
                        await container.noteService.addFileNote(
                            this.props.object.id,
                            this.props.object.data?.fileVersionId as number,
                            this.state.content,
                            {
                                parentNodeId: this.props.object.data?.parentNoteId || undefined,
                            }
                        );
                        break;
                    }
                case NoteObjectType.project:
                    await container.noteService.addProjectNote(this.props.object.id, this.state.content, {
                        parentNodeId: this.props.object.data?.parentNoteId || undefined,
                    });
                    break;
                case NoteObjectType.collection:
                    await container.noteService.addCollectionNote(this.props.object.id, this.state.content, {
                        parentNodeId: this.props.object.data?.parentNoteId || undefined,
                    });
                    break;
                default:
                    throw new LogicError(`Not supported create (internal) new comment for object type: ${this.props.object.type}`);
            }
            await this.props.reload();
            this.setState({ isLoading: false, content: "" });
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            this.setState({ isLoading: false });
        }
    }

    private async sendCommentByToken(token: string) {
        try {
            switch (this.props.object.type) {
                case NoteObjectType.collection:
                    await container.shareCollectionService.addCollectionNote(token, this.props.object.id, this.state.content, {
                        parentNodeId: this.props.object.data?.parentNoteId || undefined,
                    });
                    break;
                case NoteObjectType.file:
                    if (!this.props.object.data?.collectionId) {
                        throw new LogicError(`Not supported create (external) new comment for file object without collection id`);
                    }
                    if (!this.props.object.data.fileVersionId) {
                        throw new LogicError(`Not supported create (external) new comment for file object without collection id`);
                    }
                    await container.shareCollectionService.addCollectionFileNote(
                        token,
                        this.props.object.id,
                        this.props.object.data.fileVersionId,
                        this.props.object.data.collectionId,
                        this.state.content,
                        {
                            parentNodeId: this.props.object.data?.parentNoteId || undefined,
                        }
                    );
                    break;
                default:
                    throw new LogicError(`Not supported create (external) new comment for object type: ${this.props.object.type}`);
            }
            await this.props.reload();
            this.setState({ isLoading: false, content: "" });
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className={classNames("comment-form", { "comment-form-root": !this.props.note })}>
                <div className="comment-form--fields">
                    <TextField
                        disabled={this.props.disabled}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={<FormattedMessage id="app.CommentForm.typeYourComment" />}
                        autoComplete={getRandomString()}
                        value={this.state.content}
                        onChange={(event) => {
                            this.updateContent(event.target.value);
                        }}
                        multiline={true}
                        rows={3}
                    />
                </div>
                <div className="comment-form--action">
                    <button
                        disabled={
                            this.props.disabled ||
                            !(this.state.content.length > 0 && this.state.content.length <= this.COMMENT_MAX_LENGTH) ||
                            this.state.isLoading
                        }
                        onClick={this.sendComment}
                    >
                        <WedIcon icon={<WedIconSendMessage />} size={SizeType.SMALL} />
                    </button>
                </div>
            </div>
        );
    }
}

export default injectIntl(withSnackbar(CommentForm));
