import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMenuButtons } from "../../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import {
    WedIconPlusAdd,
    WedIconSettings6,
    WedIconSingleUserAddPlus1,
    WedIconTrashDeleteRemove,
} from "../../../../../components/wed-icon/generated/WedIconSvg";
import { IClient, IClientAddress } from "../../../../../services/WedApi/Models/Client";

interface PropsInterface extends WrappedComponentProps {
    client: IClient;
    clientAddress?: IClientAddress;
    createClientClick: () => void;
    editClientClick: (clientAddress: IClientAddress) => void;
    deleteClientClick: (client: IClient) => void;
    addClientMembersClick: (client: IClient) => void;
}

interface StateInterface {}

class ClientSummaryButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMenuButtons>
                <Button onClick={this.props.createClientClick} type="submit" startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}>
                    <FormattedMessage id="app.ClientSummaryButtons.createClient" />
                </Button>
                <Button
                    disabled={!this.props.client.userIsOwner || !this.props.clientAddress}
                    onClick={() => {
                        this.props.clientAddress && this.props.editClientClick(this.props.clientAddress);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconSettings6 />} />}
                >
                    <FormattedMessage id="app.ClientSummaryButtons.editClient" />
                </Button>
                <Button
                    disabled={!this.props.client.userIsOwner}
                    onClick={() => {
                        this.props.deleteClientClick(this.props.client);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                >
                    <FormattedMessage id="app.ClientSummaryButtons.deleteClient" />
                </Button>
                <Button
                    disabled={!this.props.client.userIsOwner}
                    onClick={() => {
                        this.props.addClientMembersClick(this.props.client);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconSingleUserAddPlus1 />} />}
                >
                    <FormattedMessage id="app.ClientSummaryButtons.addAccountTeamMembers" />
                </Button>
            </WedMenuButtons>
        );
    }
}

export default injectIntl(ClientSummaryButtons);
