import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IListResult } from "./Models/Common";
import { IApiProject, IApiProjectDetail, IManagerProjectAccessParameters, ProjectStatusEnum } from "./Models/Project";

export default class ProjectActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getProjects(filters?: { ecOrgID?: number; status?: ProjectStatusEnum }): Promise<IListResult<IApiProject>> {
        const params = [];
        if (filters?.ecOrgID) {
            params.push(`ecOrgID=${filters.ecOrgID}`);
        }
        if (filters?.status) {
            params.push(`status=${filters.status}`);
        }
        const response = await this.get(`/xapi/prs${params.length > 0 ? `?${params.join("&")}` : ""}`);
        return response.data;
    }

    public async getProject(projectId: number): Promise<IApiProjectDetail> {
        const response = await this.get(`/xapi/prs/${projectId}`);
        return response.data;
    }

    public async renameProject(pid: number, name: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/prs/rename", { pid, name });
    }

    public async changeProjectDescription(pid: number, desc: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/prs/desc", { pid, desc });
    }

    public async createProject(data: Pick<IApiProject, "ecOrgID" | "name"> & { desc?: string }): Promise<number> {
        const response = await this.postWithCSRFJson("/xapi/prs/create", data);
        return response.data["Pr"]["id"];
    }

    public async favourite(projectId: number, isFavourite: boolean) {
        await this.postWithCSRFJson("/xapi/prs/favourite", { pid: projectId, favourite: isFavourite });
    }

    public async changeStatus(projectId: number, status: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/prs/status", { id: projectId, status });
    }

    public async setProjectAccess(parameters: IManagerProjectAccessParameters): Promise<void> {
        await this.postWithCSRFJson("/xapi/prs/ams", parameters);
    }
}
