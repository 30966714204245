import React from "react";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import PopupAction from "../../popup/PopupAction/PopupAction";
import container from "../../../container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { IDirectory, IFile, IFolder } from "../../../services/WedApi/Models/File";
import { IProjectExtended } from "../../../services/WedApi/Models/Project";
import FilesPageContent from "../../project/Files/FilesPageContent/FilesPageContent";
import { ProviderContext, withSnackbar } from "notistack";
import { ISelectItem, ISelectItemType } from "../../status-action-button/StatusActionButton";
import { Scrollbars } from "react-custom-scrollbars";
import { getScrollbarIconYStyle, getScrollbarYStyle } from "../../../services/WedApi/Utils/ScrollbarsStyles";
import uniq from "lodash/uniq";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import { ApplicationState } from "../../../store";
import { SelectedItemsProps } from "../../../store/SelectedItemsReducer";
import { connect } from "react-redux";
import { LogicError } from "../../../errors/error-app";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import "./AddFileToData.scss";
import FetchDataReturnInterface from "../../template/FilesPage/Service/DataFilesByType/FilesData/FetchDataReturnInterface";

interface BasePropsInterface {
    contextCollection?: {
        collectionId: number;
        project?: IProjectExtended;
    };
    contextMyPrivatePortfolio?: { active: boolean };
    handleClose: (updated?: boolean) => void;
}

interface PropsInterface extends BasePropsInterface, ProviderContext, WrappedComponentProps, SelectedItemsProps {
    userId: number;
}

interface StateInterface {
    isLoading: boolean;
    currentFileIds: number[];
    dirId: number;
    files: IFile[];
    directories: IDirectory[];
    dir?: {
        id: number;
        folder: IFolder;
    };
}

class AddFileToData extends React.Component<PropsInterface, StateInterface> {
    private readonly fileType: FileTypeEnum;
    private readonly translatePrefix: string;

    constructor(props: PropsInterface) {
        super(props);
        if (!this.props.contextCollection?.collectionId && !this.props.contextMyPrivatePortfolio?.active) {
            throw new LogicError(`AddFileToData required props contextCollection.collectionId or contextMyPrivatePortfolio.active`);
        }
        this.fileType = this.props.contextCollection?.project ? FileTypeEnum.PROJECT_FILES : FileTypeEnum.MY_LIBRARY_FILES;
        this.translatePrefix = this.props.contextCollection?.collectionId
            ? "app.addFileToData.addFilesToCollection"
            : this.props.contextCollection
            ? "app.addFileToData.addFilesToCollection"
            : "app.addFileToData.addFilesToMyLibrary";
        this.state = {
            isLoading: true,
            currentFileIds: [],
            // eslint-disable-next-line
            dirId: undefined as any,
            files: [],
            directories: [],
            // eslint-disable-next-line
            dir: undefined,
        };
        this.handleOpenFolder = this.handleOpenFolder.bind(this);
        this.handleBreadcrumbsClick = this.handleBreadcrumbsClick.bind(this);
        this.addFilesToData = this.addFilesToData.bind(this);
        this.notExistFileInPrivatePortfolio = this.notExistFileInPrivatePortfolio.bind(this);
    }

    async componentDidMount() {
        this.setState({
            currentFileIds: await this.getCurrentFileIds(),
        });
        await this.fetchData(this.props.contextCollection?.project?.rootDirectory || 0);
    }

    private async getCurrentFileIds(): Promise<number[]> {
        return (
            this.props.contextCollection
                ? (await container.collectionService.getCollectionDetail(this.props.contextCollection.collectionId)).files
                : await container.userService.getPrivatePortfolio(this.props.userId)
        ).map((file) => file.id);
    }

    private async handleOpenFolder(directory: IDirectory) {
        this.setState({ isLoading: true });
        await this.fetchData(directory.id);
    }

    private async handleBreadcrumbsClick(link?: string, dirId?: number) {
        if (dirId) {
            this.setState({ isLoading: true });
            await this.fetchData(dirId);
        }
    }

    private trans(id: string): string {
        return `${this.translatePrefix}.${id}`;
    }

    private async fetchData(dirId: number) {
        if (this.fileType === FileTypeEnum.PROJECT_FILES) {
            const folder: IFolder = await container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, dirId);
            this.setState({
                isLoading: false,
                files: folder.files,
                directories: folder.directories,
                dir: {
                    id: folder.directory.id,
                    folder: folder,
                },
            });
        } else if (this.fileType === FileTypeEnum.MY_LIBRARY_FILES) {
            const data: FetchDataReturnInterface = await container.myLibraryFilesData.getFolder(dirId, this.props.userId);
            this.setState({
                isLoading: false,
                files: this.props.contextMyPrivatePortfolio
                    ? data.files.filter(item => item.userIsOwner && this.props.userId && item.createdBy?.id === this.props.userId)
                    : data.files,
                directories: data.directories,
                dir: data.dir,
            });
        } else {
            throw new LogicError("Component AddFileToData fetchData not supported file type");
        }
    }

    private notExistFileInPrivatePortfolio(file: IFile): boolean {
        return !this.state.currentFileIds.includes(file.id);
    }

    private async addFilesToData() {
        this.setState({ isLoading: true });
        const directoryPromise: Array<Promise<IFolder>> = [];
        const objectIds = this.props.selectedItems.reduce((objectIds: number[], selectedItem: ISelectItem) => {
            if (selectedItem.type === ISelectItemType.file) {
                return [...objectIds, selectedItem.id];
            }
            if (selectedItem.type === ISelectItemType.directory) {
                directoryPromise.push(container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, selectedItem.id));
            }
            return objectIds;
        }, [] as number[]);
        if (directoryPromise.length > 0) {
            const directories = await Promise.all(directoryPromise);
            directories.map((folder: IFolder) => {
                objectIds.push(...folder.files.map((file) => file.id));
            });
        }
        const currentFileIds = await this.getCurrentFileIds();
        const objectIdsToAdd = uniq(objectIds).filter((objectId) => !currentFileIds.includes(objectId));

        try {
            if (this.props.contextCollection) {
                if (objectIdsToAdd.length > 0) {
                    await container.collectionService.addFilesToCollection(this.props.contextCollection.collectionId, objectIdsToAdd);
                }
            } else if (this.props.contextMyPrivatePortfolio) {
                if (objectIdsToAdd.length > 0) {
                    await container.userService.addToPrivatePortfolio(objectIdsToAdd);
                }
            } else {
                throw new LogicError("Component AddFileToData not supported file type");
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: this.trans("success") }), {
                variant: "success",
            });
            this.props.handleClose(true);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_OBJECT_INVALID) {
                keyTranslate = this.trans("error.ErrObjectInvalid");
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private isDisableSubmitButton(): boolean {
        if (this.state.isLoading) {
            return true;
        }
        if (this.props.selectedItems.length === 0) {
            return true;
        }
        const notEmptyDirOrFiles = this.props.selectedItems.filter(
            (selectedItem) =>
                selectedItem.type == ISelectItemType.file ||
                (selectedItem.type === ISelectItemType.directory && (selectedItem.object as IDirectory).numberOfFiles > 0)
        );
        return notEmptyDirOrFiles.length === 0;
    }

    render() {
        return (
            <div className="add-file-to-data">
                <PopupHeader positionHeader={PositionTitle.LEFT}>
                    <FormattedMessage id={this.trans("header")} />
                </PopupHeader>
                <div className="popup-content-margin add-file-to-data--container">
                    <Scrollbars
                        universal
                        renderTrackVertical={(props) => {
                            return React.createElement("div", {
                                ...props,
                                style: getScrollbarYStyle(),
                            });
                        }}
                        renderThumbVertical={(props) => {
                            return React.createElement("div", {
                                ...props,
                                style: getScrollbarIconYStyle(),
                            });
                        }}
                    >
                        <div className="add-file-to-data--container-body">
                            {this.state.isLoading ? (
                                <LinearProgress />
                            ) : (
                                <div className="add-file-to-data--body">
                                    <FilesPageContent
                                        fileType={this.fileType}
                                        dir={this.state.dir}
                                        // eslint-disable-next-line
                                        project={this.props.contextCollection?.project || ({} as any)}
                                        isTableMode={false}
                                        files={this.state.files.filter(this.notExistFileInPrivatePortfolio)}
                                        directories={this.state.directories}
                                        disabledActionButtons={true}
                                        callbacks={{
                                            handleBreadcrumbsClick: this.handleBreadcrumbsClick,
                                            handleOpenFolder: this.handleOpenFolder,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </Scrollbars>
                </div>
                <PopupAction>
                    <Button type="submit" onClick={this.addFilesToData} disabled={this.isDisableSubmitButton()}>
                        <FormattedMessage id={this.trans("addFiles")} />
                    </Button>
                    <Button
                        type="submit"
                        onClick={() => {
                            this.props.handleClose();
                        }}
                    >
                        <FormattedMessage id={this.trans("cancel")} />
                    </Button>
                </PopupAction>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        selectedItems: state.selectedItems.selectedItems || [],
        userId: state.user.data?.id as number,
    };
};

export default connect(mapStateToProps)(injectIntl(withSnackbar(AddFileToData)));
