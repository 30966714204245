import { IApiProject, IManagerProjectAccessParameters, IProject, IProjectExtended, ProjectStatusEnum } from "../Models/Project";
import ProjectActionApiService from "../ProjectActionApiService";
import ClientActionApiService from "../ClientActionApiService";
import { convertStrToDate } from "../Utils/DateConverter";
import { findInCollection, uniqueValues } from "../Utils/ItemCollection";
import UserActionApiService from "../UserActionApiService";
import { IOwner, ITeamUser, ITeamUserProjectRole, IUser } from "../Models/User";
import { IApiClient, IClientSimple } from "../Models/Client";
import uniq from "lodash/uniq";
import { convertIApiClientToIClientSimple } from "./BaseConverter";
import { AppNotFoundItemError } from "../../../errors/error-app";

export interface IProjectList {
    projects: IProject[];
}

export type ProjectCreateValues = Pick<IProject, "name"> & { desc?: string } & { ecOrg: IClientSimple };

export class ProjectService {
    constructor(
        private readonly projectActionApiService: ProjectActionApiService,
        private readonly clientActionApiService: ClientActionApiService,
        private readonly userActionApiService: UserActionApiService
    ) {}

    public static getStatus(status: number): ProjectStatusEnum {
        switch (status) {
            case 1:
                return ProjectStatusEnum.active;
            case 2:
                return ProjectStatusEnum.closed;
            case 3:
                return ProjectStatusEnum.archived;
        }
        return ProjectStatusEnum.noStatus;
    }

    public static convertProjectApi(project: IApiProject, clients: IApiClient[], users: IOwner[]): IProject {
        const client = findInCollection(project.ecOrgID, clients);
        const createdByUser = users.find((user) => user.id === project.createdBy);
        const updatedByUser = users.find((user) => user.id === project.updatedBy);

        return {
            id: project.id,
            name: project.name,
            desc: project.desc,
            ecOrg: client
                ? convertIApiClientToIClientSimple(client)
                : {
                      id: project.ecOrgID,
                  },
            created: createdByUser ? convertStrToDate(project.createTS) : undefined,
            createdBy: createdByUser,
            updated: updatedByUser ? convertStrToDate(project.updatedTS) : undefined,
            updatedBy: updatedByUser,
            status: ProjectService.getStatus(project.status),
            isFavourite: project.isFavourite,
            allCollections: project.collectionIDs.length,
            approvedCollections: project.collectionsSummary?.approved || 0,
            rejectedCollections: project.collectionsSummary?.rejected || 0,
            numOfOwners: project.ownerIDs?.length || 0,
            numOfMembers: project.members?.length || 0,
            numOfECUsers: project.ecContactIDs?.length || 0,
            userIsOwner: project.userIsOwner,
            membersIds: project.members,
            ownersIds: project.ownerIDs,
            ecUsersIds: project.ecContactIDs,
        };
    }

    public async getProjectDetail(projectId: string | number): Promise<IProjectExtended> {
        const [project, clients] = await Promise.all([
            this.projectActionApiService.getProject(parseInt(projectId.toString())),
            this.clientActionApiService.getClients(),
        ]);
        if (project) {
            const userIds: number[] = uniq([
                ...(project.Pr.createdBy ? [project.Pr.createdBy] : []),
                ...(project.Pr.updatedBy ? [project.Pr.updatedBy] : []),
                ...(project.Pr.members ? project.Pr.members : []),
                ...(project.Pr.ownerIDs ? project.Pr.ownerIDs : []),
            ]);

            const users: IUser[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(userIds)).items : [];
            const contacts: IUser[] = (await this.clientActionApiService.getUsersClientByIdClient(project.Pr.ecOrgID)).items
                .filter((apiContact) => project.Pr.ecContactIDs.includes(apiContact.id))
                .map((apiContact) => ({
                    id: apiContact.id,
                    email: apiContact.email,
                    firstName: apiContact.first_name,
                    lastName: apiContact.last_name,
                    created: convertStrToDate(apiContact.createdAt),
                }));

            return {
                ...ProjectService.convertProjectApi(project.Pr, clients.items, users),
                rootDirectory: project.Lib.fRootID,
                rootSize: project.Lib.size,
                rootFiles: project.Lib.files,
                members: this.getUsersByIds(project.Pr.members, users),
                owners: this.getUsersByIds(project.Pr.ownerIDs, users),
                ecUsers: contacts,
            };
        }
        throw new AppNotFoundItemError("project", projectId || 0);
    }

    public async getProjectList(filters?: { clientId?: number; status?: ProjectStatusEnum }): Promise<IProjectList> {
        const [projects, clients] = await Promise.all([
            this.projectActionApiService.getProjects({
                status: filters?.status,
                ecOrgID: filters?.clientId,
            }),
            this.clientActionApiService.getClients(),
        ]);

        return this.convertProjectsClientToProjectList(projects.items, clients.items);
    }

    public async getProjectListByIds(ids: number[]): Promise<IProjectList> {
        if (ids.length === 0) {
            return { projects: [] };
        }
        const [clients, projects] = await Promise.all([
            this.clientActionApiService.getClients(),
            Promise.all(ids.map((projectId) => this.projectActionApiService.getProject(projectId))),
        ]);

        return this.convertProjectsClientToProjectList(
            projects.map((project) => project.Pr),
            clients.items
        );
    }

    public renameProject(id: number, name: string): Promise<void> {
        return this.projectActionApiService.renameProject(id, name);
    }

    public changeProjectDescription(id: number, name: string): Promise<void> {
        return this.projectActionApiService.changeProjectDescription(id, name);
    }

    public async setTeamUsersRole(projectId: number, userTeamRoles: ITeamUser[], customerContacts: IUser[]): Promise<void> {
        const project = await this.getProjectDetail(projectId);
        const newOwnerIds = userTeamRoles
            .filter((userTeamRole) => userTeamRole.role === ITeamUserProjectRole.ROLE_OWNER)
            .map((userTeamRole) => userTeamRole.id);
        const newMemberIds = userTeamRoles
            .filter((userTeamRole) => userTeamRole.role === ITeamUserProjectRole.ROLE_MEMBER)
            .map((userTeamRole) => userTeamRole.id);
        const newCustomerContactIds = customerContacts.map((user) => user.id);

        const parameters: IManagerProjectAccessParameters = {
            pid: project.id,
        };
        const changes: Omit<IManagerProjectAccessParameters, "pid"> = {
            ownersAdd: newOwnerIds.filter((newOwnerId) => !project.ownersIds.includes(newOwnerId)),
            ownersRemove: project.ownersIds.filter((ownerId) => !newOwnerIds.includes(ownerId)),
            membersAdd: newMemberIds.filter((newMemberId) => !project.membersIds.includes(newMemberId)),
            membersRemove: project.membersIds.filter((membersId) => !newMemberIds.includes(membersId)),
            ecContactsAdd: newCustomerContactIds.filter((newCustomerContactId) => !project.ecUsersIds.includes(newCustomerContactId)),
            ecContactsRemove: project.ecUsersIds.filter((customerContactId) => !newCustomerContactIds.includes(customerContactId)),
        };
        Object.keys(changes).forEach((changeKey: string) => {
            // eslint-disable-next-line
            if ((changes as any)[changeKey] && (changes as any)[changeKey].length > 0) {
                // eslint-disable-next-line
                (parameters as any)[changeKey] = (changes as any)[changeKey];
            }
        });
        if (Object.keys(parameters).length > 0) {
            await this.projectActionApiService.setProjectAccess(parameters);
        }
    }

    public async updateTeamUserRole(projectId: number, userId: number, role: ITeamUserProjectRole): Promise<void> {
        const project = await this.getProjectDetail(projectId);
        const isOwner = project.ownersIds.includes(userId);
        const isMembers = project.membersIds.includes(userId);
        const parameters: IManagerProjectAccessParameters = {
            pid: project.id,
        };
        if (isOwner && role !== ITeamUserProjectRole.ROLE_OWNER) {
            parameters.ownersRemove = [userId];
        }
        if (isMembers && role !== ITeamUserProjectRole.ROLE_MEMBER) {
            parameters.membersRemove = [userId];
        }
        switch (role) {
            case ITeamUserProjectRole.ROLE_OWNER:
                parameters.ownersAdd = [userId];
                break;
            case ITeamUserProjectRole.ROLE_MEMBER:
                parameters.membersAdd = [userId];
                break;
        }
        if (Object.keys(parameters).length > 1) {
            await this.projectActionApiService.setProjectAccess(parameters);
        }
    }

    public async dennyAccessTeamUser(projectId: number, userId: number): Promise<void> {
        const project = await this.getProjectDetail(projectId);
        if (project.ownersIds.includes(userId) || project.membersIds.includes(userId)) {
            const parameters: IManagerProjectAccessParameters = {
                pid: project.id,
                membersRemove: [userId],
                ownersRemove: [userId],
            };
            await this.projectActionApiService.setProjectAccess(parameters);
        }
    }

    public async denyAccessCustomerContact(projectId: number, customerContactId: number): Promise<void> {
        const project = await this.getProjectDetail(projectId);
        if (project.ecUsersIds.includes(customerContactId)) {
            const parameters: IManagerProjectAccessParameters = {
                pid: project.id,
                ecContactsRemove: [customerContactId],
            };
            await this.projectActionApiService.setProjectAccess(parameters);
        }
    }

    private async convertProjectsClientToProjectList(projects: IApiProject[], clients: IApiClient[]): Promise<IProjectList> {
        const userIds: number[] = projects.reduce((acc: number[], project: IApiProject) => {
            if (project.updatedBy) {
                acc.push(project.updatedBy);
            }
            if (project.createdBy) {
                acc.push(project.createdBy);
            }
            return acc;
        }, [] as number[]);
        const users: IOwner[] = uniqueValues(userIds.length > 0 ? (await this.userActionApiService.getByIds(userIds)).items : []);

        return {
            projects: projects.map((project) => {
                return ProjectService.convertProjectApi(project, clients, users);
            }),
        };
    }

    private getUsersByIds<T extends IUser | IOwner>(userIds: number[], users: T[]): T[] {
        return userIds.map((userId) => users.find((user) => user.id == userId)).filter((user: IOwner | undefined) => !!user) as T[];
    }
}
