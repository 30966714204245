export class PublicUrlService {
    public getUrl(url: string): string {
        return `${url}`;
    }

    public getImageUrl(image: string): string {
        return this.getUrl(`/images/${image}`);
    }

    public getPdfViewerUrl(pdfLink: string): string {
        return this.getUrl(`/pdf-viewer/viewer.html#${pdfLink}`);
    }
}
