import React from "react";
import { IOwner } from "../../../services/WedApi/Models/User";
import Initials, { InitialsUserType } from "../../tile/components/Initials/Initials";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import "./UserCard.scss";

interface PropsInterface {
    user: IOwner;
    userType?: InitialsUserType;
}

class UserCard extends React.Component<PropsInterface> {
    render() {
        const { initials, owner } = getInitialsAndOwner(this.props.user);

        return (
            <div className="user-card">
                <div className="user-card--initials">
                    <Initials userType={this.props.userType} title={initials} />
                </div>
                <div className="user-card--content">
                    <div className="user-card--header">{owner}</div>
                    <div className="user-card--email">{this.props.user.email}</div>
                </div>
            </div>
        );
    }
}

export default UserCard;
