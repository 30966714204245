import { uniq } from "lodash";
import UserActionApiService from "../UserActionApiService";
import { convertIApiNoteToINote, listToTreeNotes } from "./BaseConverter";
import NoteActionApiService from "../NoteActionApiService";
import { IApiCommentNote, IApiNote, ICommentNote, ICommentNoteType, INote, INoteAnnotation } from "../Models/Note";
import { IUser } from "../Models/User";
import { convertStrToDate } from "../Utils/DateConverter";
import { LogicError } from "../../../errors/error-app";
import ClientActionApiService from "../ClientActionApiService";
import ProjectActionApiService from "../ProjectActionApiService";
import CollectionActionApiService from "../CollectionActionApiService";
import FilesActionApiService from "../FilesActionApiService";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";

export interface IAddNoteOptions {
    parentNodeId?: number;
}

export interface IAddFileNoteOptions extends IAddNoteOptions {
    annotation?: INoteAnnotation;
}

export class NoteService {
    constructor(
        private readonly noteActionApiService: NoteActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly clientActionApiService: ClientActionApiService,
        private readonly projectActionApiService: ProjectActionApiService,
        private readonly collectionActionApiService: CollectionActionApiService,
        private readonly filesActionApiService: FilesActionApiService
    ) {}

    public async addProjectNote(projectId: number, content: string, options?: IAddNoteOptions): Promise<void> {
        return this.noteActionApiService.addProjectNote(projectId, content, { tid: options?.parentNodeId });
    }

    public async getProjectNotes(projectId: number): Promise<INote[]> {
        const notes = (await this.noteActionApiService.getProjectNotes(projectId)).items;
        const data = await this.convertIApiNoteToNote(notes);
        return listToTreeNotes(data);
    }

    public async addFileNote(objectId: number, versionId: number, content: string, options?: IAddFileNoteOptions): Promise<void> {
        return this.noteActionApiService.addFileNote(objectId, versionId, content, {
            tid: options?.parentNodeId,
            annotation: !!options?.annotation,
            x0: options?.annotation?.x || undefined,
            y0: options?.annotation?.y || undefined,
            width: options?.annotation?.width || undefined,
            height: options?.annotation?.height || undefined,
        });
    }

    public async getFileNotes(objectId: number): Promise<INote[]> {
        const notes = (await this.noteActionApiService.getFileNotes(objectId)).items;
        const data = await this.convertIApiNoteToNote(notes);
        return listToTreeNotes(data);
    }

    public async addCollectionFileNote(
        objectId: number,
        versionId: number,
        collectionId: number,
        content: string,
        options?: IAddFileNoteOptions
    ): Promise<void> {
        return this.noteActionApiService.addCollectionFileNote(objectId, versionId, collectionId, content, {
            tid: options?.parentNodeId,
            annotation: !!options?.annotation,
            x0: options?.annotation?.x || undefined,
            y0: options?.annotation?.y || undefined,
            width: options?.annotation?.width || undefined,
            height: options?.annotation?.height || undefined,
        });
    }

    public async getCollectionFileNotes(objectId: number, collectionId: number, clientId?: number): Promise<INote[]> {
        const notes = (await this.noteActionApiService.getCollectionFileNotes(objectId, collectionId)).items;
        const data = await this.convertIApiNoteToNote(notes, clientId);
        return listToTreeNotes(data);
    }

    public async addCollectionNote(collectionId: number, content: string, options?: IAddNoteOptions): Promise<void> {
        return this.noteActionApiService.addCollectionNote(collectionId, content, { tid: options?.parentNodeId });
    }

    public async getCollectionNotes(collectionId: number, clientId?: number): Promise<INote[]> {
        const notes = (await this.noteActionApiService.getCollectionNotes(collectionId)).items;
        const data = await this.convertIApiNoteToNote(notes, clientId);
        return listToTreeNotes(data);
    }

    private async getCommentNotesDataTransformObject(items: IApiCommentNote[], projectId: number, options: { ecUsers?: IUser[]; ecOrgId?: number }) {
        const userIds: number[] = [];
        const fileIds: number[] = [];
        const projectIds: number[] = [projectId];
        const collectionIds: number[] = [];

        items = items.map((item) => {
            if (item.Value.baAuthorID) {
                userIds.push(item.Value.baAuthorID);
            }
            if (item.Value.baUserID) {
                userIds.push(item.Value.baUserID);
            }
            if (item.Value.oid) {
                fileIds.push(item.Value.oid);
            }
            if (item.Value.pid) {
                projectIds.push(item.Value.pid);
            }
            if (item.Value.cid) {
                collectionIds.push(item.Value.cid);
            }
            return {
                ...item,
                Value: {
                    ...item.Value,
                    pid: projectId,
                },
            };
        });

        const [projects, collections, objects, users] = await Promise.all([
            projectIds.length > 0 ? Promise.all(uniq(projectIds).map((projectId) => this.projectActionApiService.getProject(projectId))) : [],
            collectionIds.length > 0
                ? Promise.all(uniq(collectionIds).map((collectionId) => this.collectionActionApiService.getCollectionDetail(collectionId)))
                : [],
            fileIds.length > 0 ? this.filesActionApiService.getNoteFileByIds(uniq(fileIds)) : [],
            userIds.length > 0 ? (await this.userActionApiService.getByIds(uniq(userIds))).items : [],
        ]);
        const ecUsers: IUser[] =
            options?.ecUsers ||
            (await this.clientActionApiService.getUsersClientByIdClient(options.ecOrgId as number)).items.map((user) => {
                return {
                    id: user.id,
                    email: user.email,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    created: convertStrToDate(user.createdAt),
                };
            });
        return items
            .map((item) => {
                const project = projects.find((project) => project.Pr.id === item.Value.pid);
                const collection = collections.find((collection) => collection.collection.id === item.Value.cid);
                const object = objects.find((object) => object.id === item.Value.oid);
                const objectVersion = item.Value.vid && object ? object.versions.find((version) => version.id === item.Value.vid) : undefined;
                if (
                    (item.Value.pid && !project) ||
                    (item.Value.oid && !object) ||
                    (item.Value.cid && !collection) ||
                    (item.Value.vid && !objectVersion)
                ) {
                    // eslint-disable-next-line
                    console.warn("Get note with invalid id", item, project, collection, object);
                    return undefined;
                }

                let createdBy = undefined;
                let userType = undefined;
                if (!createdBy && item.Value.ecContactID) {
                    createdBy = ecUsers.find((user) => user.id === item.Value.ecContactID);
                    userType = InitialsUserType.EXTERNAL;
                }
                if (!createdBy && item.Value.ecAuthorID) {
                    createdBy = ecUsers.find((user) => user.id === item.Value.ecAuthorID);
                    userType = InitialsUserType.EXTERNAL;
                }
                if (!createdBy && item.Value.baUserID) {
                    createdBy = users.find((user) => user.id === item.Value.baUserID);
                    userType = InitialsUserType.INTERNAL;
                }
                if (!createdBy && item.Value.baAuthorID) {
                    createdBy = users.find((user) => user.id === item.Value.baAuthorID);
                    userType = InitialsUserType.INTERNAL;
                }

                return {
                    type: item.Type,
                    item: {
                        ...item.Value,
                        file:
                            object && objectVersion
                                ? {
                                      id: item.Value.oid as number,
                                      dirId: item.Value.objectParentID as number,
                                      newestVersionId: item.Value.vid || object.newestVersionId,
                                      name: object.name,
                                      versionNumber: objectVersion.VersionNumber,
                                      extension: object.versions.find((version) => (version.id == item.Value.vid || object.newestVersionId))?.type
                                          ?.extension,
                                  }
                                : undefined,
                        project:
                            item.Value.pid && project
                                ? {
                                      id: project.Pr.id,
                                      name: project.Pr.name,
                                  }
                                : undefined,
                        collection:
                            item.Value.cid && collection
                                ? {
                                      id: collection.collection.id,
                                      name: collection.collection.name,
                                  }
                                : undefined,
                        approvedStatus: item.Value.flag === 2 ? false : item.Value.flag === 3 ? true : undefined,
                        createdBy: createdBy,
                        userType: userType,
                        created: item.Value.createdAt
                            ? convertStrToDate(item.Value.createdAt)
                            : item.Value.updatedAt
                            ? convertStrToDate(item.Value.updatedAt)
                            : undefined,
                    },
                };
            })
            .filter((item) => !!item) as ICommentNote[];
    }

    public async getCommentNotesSummaryPage(projectId: number, options: { ecUsers?: IUser[]; ecOrgId?: number }): Promise<ICommentNote[]> {
        if (!options.ecOrgId && !options.ecUsers) {
            throw new LogicError("Method required options ecUsers or ecOrgId");
        }

        const items = (await this.noteActionApiService.getCommentNotes(projectId)).items || [];
        const itemsSummaryPage = [];
        let countApprovals = 0;
        let countRecentComments = 0;

        for (const item of items) {
            if (countApprovals < 2 && item.Type === ICommentNoteType.OBJECT_PROOF) {
                itemsSummaryPage.push(item);
                countApprovals++;
            } else if (countRecentComments < 1 && item.Type !== ICommentNoteType.OBJECT_PROOF) {
                itemsSummaryPage.push(item);
                countRecentComments++;
            }
        }

        return this.getCommentNotesDataTransformObject(itemsSummaryPage, projectId, options);
    }

    public async getCommentNotes(projectId: number, options: { ecUsers?: IUser[]; ecOrgId?: number }): Promise<ICommentNote[]> {
        if (!options.ecOrgId && !options.ecUsers) {
            throw new LogicError("Method required options ecUsers or ecOrgId");
        }
        const items = (await this.noteActionApiService.getCommentNotes(projectId)).items || [];
        return this.getCommentNotesDataTransformObject(items, projectId, options);
    }

    private async convertIApiNoteToNote(notes: IApiNote[], clientId?: number): Promise<INote[]> {
        const userIds: number[] = [];
        const ecUserIds: number[] = [];
        notes.forEach((note) => {
            if (note.baAuthorID && !userIds.includes(note.baAuthorID)) {
                userIds.push(note.baAuthorID);
            }
            if (note.ecAuthorID && !ecUserIds.includes(note.ecAuthorID)) {
                ecUserIds.push(note.ecAuthorID);
            }
        });
        const [resultUser, resultContacts] = await Promise.all([
            this.userActionApiService.getByIds(userIds),
            ecUserIds.length > 0 && clientId ? this.clientActionApiService.getUsersClientByIdClient(clientId) : { items: [] },
        ]);
        return notes.map((note) => convertIApiNoteToINote(note, resultUser.items, resultContacts.items));
    }
}
