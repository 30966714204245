import React from "react";
import WedImageLogo40x40 from "../../wed-image-logo/WedImageLogo40x40";
import PopupCloseIcon from "../../popup/PopupCloseIcon/PopupCloseIcon";
import { FormattedMessage } from "react-intl";
import "./AdminHeader.scss";
import "../Header.scss";

interface PropsInterface {
    onCloseClick: () => void;
}

export class AdminHeader extends React.Component<PropsInterface> {
    render() {
        const { onCloseClick } = this.props;

        return (
            <header className="header header--admin-panel">
                <div className="header__container">
                    <nav className="navbar">
                        <a className="navbar__icon">
                            <WedImageLogo40x40 />
                        </a>
                        <div className="header--admin-panel-title">
                            <FormattedMessage id={"app.AdminHeader.header.title"} />
                        </div>
                        <PopupCloseIcon onClick={onCloseClick} />
                    </nav>
                </div>
            </header>
        );
    }
}
