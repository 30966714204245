import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Button } from "@material-ui/core";
import Popup from "../Popup/Popup";
import PopupContent from "../PopupContent/PopupContent";
import PopupHeader, { PositionTitle } from "../PopupHeader/PopupHeader";
import PopupAction from "../PopupAction/PopupAction";
import { ProviderContext, withSnackbar } from "notistack";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    open: boolean;
    className?: string;
    header: string | React.ReactElement;
    content?: string | React.ReactElement;
    disableDialogWrapper?: boolean;
    close: (changed?: boolean) => void;
    // eslint-disable-next-line
    preDoIt?: () => any;
    doIt: () => Promise<void | boolean>;
    noDisplayCancel?: boolean;
    confirmButtonLabel?: string | React.ReactElement;
    maxWidth?: false | "xs" | "sm" | "md" | "lg" | "xl" | undefined;
    loadingContent?: boolean;
    doItErrorHandler?: (err: Error) => void;
}

interface StateInterface {
    loading: boolean;
}

class PopupConfirmButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    render() {
        return (
            <Popup
                open={this.props.open}
                maxWidth={this.props.maxWidth || "sm"}
                handleClose={() => {
                    this.props.close();
                }}
            >
                <PopupContent className={this.props.className}>
                    <div>
                        <PopupHeader positionHeader={PositionTitle.CENTER}>{this.props.header}</PopupHeader>
                        {this.props.loadingContent ? (
                            <LinearProgress />
                        ) : (
                            <>
                                <DialogContent>
                                    {this.props.disableDialogWrapper && this.props.content}
                                    {!this.props.disableDialogWrapper && (
                                        <DialogContentText id="alert-dialog-description">
                                            <br />
                                            {this.props.content || ""}
                                        </DialogContentText>
                                    )}
                                </DialogContent>

                                <PopupAction>
                                    <Button
                                        type="submit"
                                        onClick={async () => {
                                            this.setState({
                                                loading: true,
                                            });
                                            let changed = false;
                                            if (this.props.preDoIt) {
                                                if (this.props.preDoIt() === false) {
                                                    this.setState({
                                                        loading: false,
                                                    });
                                                    return;
                                                }
                                            }
                                            try {
                                                if ((await this.props.doIt()) === false) {
                                                    this.setState({
                                                        loading: false,
                                                    });
                                                    return;
                                                }
                                                changed = true;
                                            } catch (err) {
                                                if (this.props.doItErrorHandler) {
                                                    this.props.doItErrorHandler(err);
                                                } else {
                                                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), {
                                                        variant: "error",
                                                    });
                                                }
                                            }
                                            this.setState({
                                                loading: false,
                                            });
                                            this.props.close(changed);
                                            return;
                                        }}
                                        className="button--more-padding"
                                        disabled={this.state.loading}
                                    >
                                        {this.props.confirmButtonLabel ? (
                                            this.props.confirmButtonLabel
                                        ) : (
                                            <FormattedMessage id="app.standard.popup.button.ok" />
                                        )}
                                    </Button>
                                    {!this.props.noDisplayCancel && (
                                        <Button
                                            type="submit"
                                            onClick={() => {
                                                this.props.close();
                                            }}
                                            className="button--more-padding"
                                        >
                                            <FormattedMessage id="app.standard.popup.button.cancel" />
                                        </Button>
                                    )}
                                </PopupAction>
                            </>
                        )}
                    </div>
                </PopupContent>
            </Popup>
        );
    }
}

export default injectIntl(withSnackbar(PopupConfirmButton));
