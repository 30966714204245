import React, { MouseEvent, ReactElement } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import {
    WedIconDownloadSaveUpload1,
    WedIconEye,
    WedIconFolderGroupPlusAdd,
    WedIconLock3,
    WedIconLockUnlock,
    WedIconPenEdit17,
    WedIconPenEditCreate3,
    WedIconShare2,
    WedIconSlideDisableDelite,
    WedIconTrashDeleteRemove,
} from "../../../../../components/wed-icon/generated/WedIconSvg";
import { ISelectItem, StatusActionButton } from "../../../../../components/status-action-button/StatusActionButton";
import { RenameCollectionPopup } from "../../../../../components/project/Collection/RenameCollectionPopup/RenameCollectionPopup";
import Collection from "../../../../../domain/Collection";
import { ChangeDescriptionCollectionPopup } from "../../../../../components/project/Collection/ChangeDescriptionCollectionPopup/ChangeDescriptionCollectionPopup";
import { LockUnlockCollectionPopup } from "../../../../../components/project/Collection/LockUnlockCollectionPopup/LockUnlockCollectionPopup";
import { UnshareCollectionPopup } from "../../../../../components/project/Collection/UnshareCollectionPopup/UnshareCollectionPopup";
import { downloadFile } from "../../../../../services/WedApi/Utils/DownloadFile";
import container from "../../../../../container";
import { ProviderContext, withSnackbar } from "notistack";
import { ICollection } from "../../../../../services/WedApi/Models/Collection";
import { IProject } from "../../../../../services/WedApi/Models/Project";
import { isEditableProject } from "../../../../../services/WedApi/Utils/IsEditableProject";
import { FileTypeEnum } from "../../../../../components/template/Type/FileTypeEnum";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    project?: IProject;
    selectedItems: ISelectItem[];
    icon?: ReactElement;
    collection: Collection | undefined;
    onChange: () => void;
    goToCollectionFiles: (collectionId: number) => void;
    openSharing: (collection: Collection) => void;
    deleteCollection: (collection: Collection) => void;
    openAddFileToCollection: (collectionId: number) => void;
    fileType: FileTypeEnum;
}

interface StateInterface {
    renameCollectionOpen: boolean;
    changeDescriptionCollectionOpen: boolean;
    lockUnlockCollectionOpen: boolean;
    unshareCollectionOpen: boolean;
}

class CollectionActionButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            renameCollectionOpen: false,
            changeDescriptionCollectionOpen: false,
            lockUnlockCollectionOpen: false,
            unshareCollectionOpen: false,
        };
    }

    isSelectedItemOwner = () => {
        const { selectedItems } = this.props;

        return selectedItems.length == 1 && selectedItems[0].userIsOwner;
    };

    isSelectedItemLocked = () => {
        const { selectedItems } = this.props;

        if (selectedItems.length == 1) {
            const object: ICollection | undefined = selectedItems[0].object as ICollection;
            if (object) {
                return object.locked;
            }
        }

        return false;
    };

    isSharingCollection = () => {
        return this.props.collection?.isSharing();
    };

    private handleCloseCollectionPopup = async (isUpdate: boolean = false) => {
        this.setState({
            ...this.state,
            renameCollectionOpen: false,
            changeDescriptionCollectionOpen: false,
            lockUnlockCollectionOpen: false,
            unshareCollectionOpen: false,
        });

        if (isUpdate) {
            this.props.onChange();
        }
    };

    render() {
        const { selectedItems, icon, collection } = this.props;
        return (
            <>
                <StatusActionButton
                    selectedItems={selectedItems}
                    menuItems={[
                        {
                            multiply: false,
                            items: [
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.preview" }),
                                    icon: <WedIcon icon={<WedIconEye />} size={SizeType.SMALL} />,
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.props.goToCollectionFiles(selectedItems[0].id);
                                    },
                                    divider: true,
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.rename" }),
                                    icon: <WedIcon icon={<WedIconPenEdit17 />} size={SizeType.SMALL} />,
                                    disabled:
                                        !isEditableProject(this.props.fileType, this.props.project) ||
                                        !this.isSelectedItemOwner() ||
                                        this.isSelectedItemLocked(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.setState({ renameCollectionOpen: true });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.description" }),
                                    icon: <WedIcon icon={<WedIconPenEditCreate3 />} size={SizeType.SMALL} />,
                                    divider: true,
                                    disabled:
                                        !isEditableProject(this.props.fileType, this.props.project) ||
                                        !this.isSelectedItemOwner() ||
                                        this.isSelectedItemLocked(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.setState({ changeDescriptionCollectionOpen: true });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.sharing" }),
                                    icon: <WedIcon icon={<WedIconShare2 />} size={SizeType.SMALL} />,
                                    disabled:
                                        !isEditableProject(this.props.fileType, this.props.project) ||
                                        !this.isSelectedItemOwner() ||
                                        this.isSelectedItemLocked(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        collection && this.props.openSharing(collection);
                                    },
                                },
                                {
                                    label:
                                        collection && collection.isLocked()
                                            ? this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.unlock" })
                                            : this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.lock" }),
                                    icon:
                                        collection && collection.isLocked() ? (
                                            <WedIcon icon={<WedIconLockUnlock />} size={SizeType.SMALL} />
                                        ) : (
                                            <WedIcon icon={<WedIconLock3 />} size={SizeType.SMALL} />
                                        ),
                                    divider: false,
                                    disabled: !isEditableProject(this.props.fileType, this.props.project) || !this.isSelectedItemOwner(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.setState({ lockUnlockCollectionOpen: true });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.unshare" }),
                                    icon: <WedIcon icon={<WedIconSlideDisableDelite />} size={SizeType.SMALL} />,
                                    divider: true,
                                    disabled:
                                        !isEditableProject(this.props.fileType, this.props.project) ||
                                        !this.isSelectedItemOwner() ||
                                        !this.isSharingCollection() ||
                                        this.isSelectedItemLocked(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.setState({ unshareCollectionOpen: true });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.addFiles" }),
                                    icon: <WedIcon icon={<WedIconFolderGroupPlusAdd />} size={SizeType.SMALL} />,
                                    divider: true,
                                    disabled:
                                        !isEditableProject(this.props.fileType, this.props.project) ||
                                        !this.isSelectedItemOwner() ||
                                        this.isSelectedItemLocked(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.props.openAddFileToCollection(selectedItems[0].id);
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.download" }),
                                    icon: <WedIcon icon={<WedIconDownloadSaveUpload1 />} size={SizeType.SMALL} />,
                                    divider: true,
                                    disabled: !(selectedItems.length > 0 && (selectedItems[0].object as ICollection)?.files?.length > 0),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: `app.projectFilesActionButton.download` }), {
                                            variant: "success",
                                            autoHideDuration: 4000,
                                        });
                                        downloadFile(container.collectionService.getDownloadUrl(selectedItems[0].id));
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.dropDownMenu.collection.removeCollection" }),
                                    icon: <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />,
                                    disabled: !isEditableProject(this.props.fileType, this.props.project) || !this.isSelectedItemOwner(),
                                    click: async (event: MouseEvent) => {
                                        event.stopPropagation();
                                        collection && this.props.deleteCollection(collection);
                                    },
                                },
                            ],
                        },
                    ]}
                    icon={icon}
                />
                {!!collection && (
                    <RenameCollectionPopup
                        open={this.state.renameCollectionOpen}
                        handleClose={this.handleCloseCollectionPopup}
                        collection={collection}
                    />
                )}
                {!!collection && (
                    <ChangeDescriptionCollectionPopup
                        open={this.state.changeDescriptionCollectionOpen}
                        handleClose={this.handleCloseCollectionPopup}
                        collection={collection}
                    />
                )}
                {!!collection && (
                    <LockUnlockCollectionPopup
                        open={this.state.lockUnlockCollectionOpen}
                        handleClose={this.handleCloseCollectionPopup}
                        collection={collection}
                    />
                )}
                {!!collection && (
                    <UnshareCollectionPopup
                        open={this.state.unshareCollectionOpen}
                        handleClose={this.handleCloseCollectionPopup}
                        collection={collection}
                    />
                )}
            </>
        );
    }
}

export default withSnackbar(injectIntl(CollectionActionButton));
