import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import RenameCollection from "../RenameCollection/RenameCollection";
import Collection from "../../../../domain/Collection";

interface PropsInterface {
    open: boolean;
    handleClose: (isUpdate?: boolean) => void;
    collection: Collection;
}

interface StateInterface {}

export class RenameCollectionPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    private closePopup() {
        this.props.handleClose();
    }

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.closePopup} maxWidth="sm">
                <PopupContent>
                    <RenameCollection close={this.props.handleClose} collection={this.props.collection} />
                </PopupContent>
            </Popup>
        );
    }
}
