import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import SidebarSummaryInfo from "../SidebarSummaryInfo/SidebarSummaryInfo";
import { FileSize } from "../../file/FileSize/FileSize";

interface PropsInterface extends WrappedComponentProps {
    statusWedIcon?: React.ReactElement;
    icon: React.ReactElement;
    filesCount: number;
    folderCount?: number;
    totalSize?: number;
}

interface StateInterface {}

class SidebarInformationFiles extends React.Component<PropsInterface, StateInterface> {
    getDescription(type: "files" | "subFolder", count: number) {
        const { intl } = this.props;

        switch (count) {
            case 0:
                return intl.formatMessage({ id: `app.sidebar.${type}.description.count0` });
            case 1:
                return intl.formatMessage({ id: `app.sidebar.${type}.description.count1` });
            case 2:
            case 3:
            case 4:
                return intl.formatMessage({ id: `app.sidebar.${type}.description.count234` });
            default:
                return intl.formatMessage({ id: `app.sidebar.${type}.description.countMore` });
        }
    }

    render() {
        const { icon, statusWedIcon, filesCount, folderCount, totalSize } = this.props;
        const texts: Array<React.ReactElement | string> = [];
        texts.push(`${filesCount} ${this.getDescription("files", filesCount)}`);
        if (folderCount !== undefined) {
            texts.push(", ");
            texts.push(`${folderCount} ${this.getDescription("subFolder", folderCount)}`);
        }
        if (totalSize) {
            texts.push(", ");
            texts.push(<FileSize size={totalSize} />);
        }

        return (
            <SidebarSummaryInfo
                statusWedIcon={statusWedIcon}
                icon={icon}
                text={
                    <>
                        {texts.map((text, i) => (
                            <span key={i}>{text}</span>
                        ))}
                    </>
                }
            />
        );
    }
}

export default injectIntl(SidebarInformationFiles);
