import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import UserBaseProfilePopup from "../UserBaseProfilePopup/UserBaseProfilePopup";
import { TextField } from "@material-ui/core";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import container from "../../../container";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import "./UserMyChangePasswordPopup.scss";
import {PASSWORD_PATTERN} from "../../../services/WedApi/Utils/Password";

interface BasePropsInterface extends WrappedComponentProps, ProviderContext {
    close: () => void;
}

interface PropsInterface extends BasePropsInterface {
    userEmail: string;
}

interface StateInterface {
    fields: {
        currentPassword: string;
        password: string;
        password2: string;
    };
}

class UserMyChangePasswordPopup extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            currentPassword: {
                type: "string",
            },
            password: {
                type: "string",
                pattern: PASSWORD_PATTERN,
            },
            password2: {
                type: "string",
            },
        },
        required: ["currentPassword", "password", "password2"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                currentPassword: "app.UserMyChangePasswordPopup.error.currentPassword",
                password: "app.UserMyChangePasswordPopup.error.password",
                password2: "app.UserMyChangePasswordPopup.error.password2",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            fields: {
                currentPassword: "",
                password: "",
                password2: "",
            },
        };
        this.doIt = this.doIt.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    private async doIt(): Promise<boolean> {
        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: formErrors[firstKey] }), { variant: "error" });
            return false;
        }
        if (this.state.fields.password !== this.state.fields.password2) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserMyChangePasswordPopup.error.password2" }), { variant: "error" });
            return false;
        }

        try {
            await container.userService.updateUserPassword(this.state.fields.currentPassword, this.state.fields.password);

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserMyChangePasswordPopup.success.message" }), {
                variant: "success",
            });
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_PASSWORD_INVALID) {
                keyTranslate = "app.UserMyChangePasswordPopup.error.errPasswordInvalid";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }

        return true;
    }

    private validateForm(): ValidationErrors {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    }

    private validate(): { valid: boolean; formErrors: ValidationErrors } {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        return (
            <UserBaseProfilePopup
                open={true}
                close={this.props.close}
                className={"user-my-change-password-popup"}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.UserMyChangePasswordPopup.button.save"} />}
                header={<FormattedMessage id={"app.UserMyChangePasswordPopup.header"} />}
                content={
                    <div className="user-my-change-password-popup--content">
                        <div className="user-my-change-password-popup--content-user-info">
                            <div className="user-my-change-password-popup--content-user-info-row">
                                <div className="user-my-change-password-popup--content-user-info-row-label">
                                    <FormattedMessage id={"app.UserMyChangePasswordPopup.subHeader.emailAddress"} />
                                </div>
                                <div className="user-my-change-password-popup--content-user-info-row-value">{this.props.userEmail}</div>
                            </div>
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id={getRandomString()}
                            label={this.props.intl.formatMessage({ id: "app.UserMyChangePasswordPopup.label.currentPassword" })}
                            name="currentPassword"
                            autoComplete={getRandomString()}
                            autoFocus
                            value={this.state.fields.currentPassword}
                            onChange={this.handleChange}
                            type="password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id={getRandomString()}
                            label={this.props.intl.formatMessage({ id: "app.UserMyChangePasswordPopup.label.password" })}
                            name="password"
                            autoComplete={getRandomString()}
                            autoFocus
                            value={this.state.fields.password}
                            onChange={this.handleChange}
                            type="password"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id={getRandomString()}
                            label={this.props.intl.formatMessage({ id: "app.UserMyChangePasswordPopup.label.password2" })}
                            name="password2"
                            autoComplete={getRandomString()}
                            autoFocus
                            value={this.state.fields.password2}
                            onChange={this.handleChange}
                            type="password"
                        />
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userEmail: state.user.data?.email || "",
    } as PropsInterface;
};

export default withSnackbar(injectIntl(connect(mapStateToProps)(UserMyChangePasswordPopup)));
