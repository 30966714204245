import { IProject, ProjectStatusEnum } from "../Models/Project";
import ClientActionApiService, { IApiBaseCreateUpdateClient } from "../ClientActionApiService";
import {
    IApiClient,
    IApiUserClient,
    IClient,
    IClientAddress,
    IClientExtended,
    IClientSimple,
    IManagerClientAccessParameters,
} from "../Models/Client";
import { IAccountTeamUser, IAccountTeamUserProjectRole, IUser, IUserBase } from "../Models/User";
import { convertIApiClientToIClientSimple } from "./BaseConverter";
import uniq from "lodash/uniq";
import UserActionApiService from "../UserActionApiService";
import ProjectActionApiService from "../ProjectActionApiService";
import { ProjectService } from "./ProjectService";
import { AppNotFoundItemError } from "../../../errors/error-app";

export interface IProjectList {
    projects: IProject[];
}

export type ICreateUpdateClient = IApiBaseCreateUpdateClient;

export class ClientService {
    constructor(
        private readonly clientActionApiService: ClientActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly projectActionApiService: ProjectActionApiService
    ) {}

    public static convertClientContact(userClient: IApiUserClient): IUserBase {
        return {
            firstName: userClient.first_name,
            lastName: userClient.last_name,
            email: userClient.email,
            id: userClient.id,
        };
    }

    public async getClientDetail(
        clientId: number,
        options?: { withMembers?: boolean; withOwners?: boolean; withContacts?: boolean }
    ): Promise<IClientExtended> {
        const client = (await this.getList()).find((client) => client.id === clientId);
        if (client) {
            const userIds: number[] = [];
            const contactIds: number[] = [];
            if (options?.withMembers && client.membersIds?.length > 0) {
                userIds.push(...client.membersIds);
            }
            if (options?.withOwners && client.ownerIDs?.length > 0) {
                userIds.push(...client.ownerIDs);
            }
            if (options?.withContacts) {
                contactIds.push(...client.contactIDs);
            }
            const users: IUser[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(userIds)).items : [];
            const contacts: IUser[] = (await this.getUsersClientByIdClient(client.id)) as IUser[];
            return {
                ...client,
                ...(options?.withMembers
                    ? {
                          members: client.membersIds.map((userId) => users.find((user) => userId === user.id)).filter((user) => !!user) as IUser[],
                      }
                    : {}),
                ...(options?.withOwners
                    ? {
                          owners: client.ownerIDs.map((userId) => users.find((user) => userId === user.id)).filter((user) => !!user) as IUser[],
                      }
                    : {}),
                ...(options?.withContacts
                    ? {
                          contacts: client.contactIDs
                              .map((contactId) => contacts.find((contact) => contactId === contact.id))
                              .filter((contact) => !!contact) as IUser[],
                      }
                    : {}),
            };
        }

        throw new AppNotFoundItemError("client", clientId);
    }

    public async getList(): Promise<IClient[]> {
        const [clients, projects] = await Promise.all([this.clientActionApiService.getClients(), this.projectActionApiService.getProjects()]);

        const userIds: number[] = [];
        clients.items.forEach((client) => {
            if (client.createdBy) {
                userIds.push(client.createdBy);
            }
        });
        const users = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniq(userIds))).items : [];

        const clientProjects = projects.items.map((project) => {
            return ProjectService.convertProjectApi(project, clients.items, []);
        });

        return clients.items.map((client: IApiClient) => {
            const clientSimple = convertIApiClientToIClientSimple(client);
            const clientItem = {
                ...clientSimple,
                createdBy: client.createdBy ? users.find((user) => user.id === client.createdBy) : undefined,
                projectNumOfActive: clientProjects.filter((project) => project.ecOrg.id === client.id && project.status === ProjectStatusEnum.active)
                    .length,
                projectNumOfClosed: clientProjects.filter((project) => project.ecOrg.id === client.id && project.status === ProjectStatusEnum.closed)
                    .length,
                projectNumOfArchived: clientProjects.filter(
                    (project) => project.ecOrg.id === client.id && project.status === ProjectStatusEnum.archived
                ).length,
            } as IClient;
            return clientItem;
        });
    }

    public async getSimpleList(): Promise<IClientSimple[]> {
        const clients = await this.clientActionApiService.getClients();

        return clients.items.map(convertIApiClientToIClientSimple);
    }

    public async createClient(data: ICreateUpdateClient): Promise<void> {
        await this.clientActionApiService.createClient(data);
    }

    public async getClientAddress(clientId: number): Promise<IClientAddress> {
        return this.clientActionApiService.getClientAddress(clientId);
    }

    public async updateClient(id: number, data: ICreateUpdateClient): Promise<void> {
        await this.clientActionApiService.updateClient({
            ...data,
            id: id,
        });
    }

    public async deleteClient(id: number): Promise<void> {
        return this.clientActionApiService.deleteClient(id);
    }

    public async getUsersClientByIdClient(idClient: number): Promise<IUserBase[]> {
        const usersClient = await this.clientActionApiService.getUsersClientByIdClient(idClient);

        return usersClient.items.map((userClient) => {
            return ClientService.convertClientContact(userClient);
        });
    }

    public async setTeamUsersRole(clientId: number, userTeamRoles: IAccountTeamUser[]): Promise<void> {
        const client = await this.getClientDetailForEditAccess(clientId);
        const parameters = this.createAccessObjectParameters(clientId);
        const newOwnerIds = userTeamRoles
            .filter((userTeamRole) => userTeamRole.role === IAccountTeamUserProjectRole.ROLE_OWNER)
            .map((userTeamRole) => userTeamRole.id);
        const newMemberIds = userTeamRoles
            .filter((userTeamRole) => userTeamRole.role === IAccountTeamUserProjectRole.ROLE_MEMBER)
            .map((userTeamRole) => userTeamRole.id);

        parameters.ownersAdd = newOwnerIds.filter((newOwnerId) => !client.ownerIDs.includes(newOwnerId));
        parameters.ownersRemove = client.ownerIDs.filter((ownerId) => !newOwnerIds.includes(ownerId));
        parameters.membersAdd = newMemberIds.filter((newMemberId) => !client.baUserIDs.includes(newMemberId));
        parameters.membersRemove = client.baUserIDs.filter((membersId) => !newMemberIds.includes(membersId));

        await this.updateClientAccessIfHasChanges(parameters);
    }

    public async updateTeamUserRole(clientId: number, userId: number, role: IAccountTeamUserProjectRole): Promise<void> {
        const client = await this.getClientDetailForEditAccess(clientId);
        const parameters = this.createAccessObjectParameters(clientId);
        switch (role) {
            case IAccountTeamUserProjectRole.ROLE_MEMBER:
                if (client.ownerIDs.includes(userId)) {
                    parameters.ownersRemove.push(userId);
                }
                if (!client.baUserIDs.includes(userId)) {
                    parameters.membersAdd.push(userId);
                }
                break;
            case IAccountTeamUserProjectRole.ROLE_OWNER:
                if (client.baUserIDs.includes(userId)) {
                    parameters.membersRemove.push(userId);
                }
                if (!client.ownerIDs.includes(userId)) {
                    parameters.ownersAdd.push(userId);
                }
                break;
        }
        await this.updateClientAccessIfHasChanges(parameters);
    }

    public async dennyAccessTeamUser(clientId: number, userId: number): Promise<void> {
        const client = await this.getClientDetailForEditAccess(clientId);
        const parameters = this.createAccessObjectParameters(clientId);
        if (client.ownerIDs.includes(userId)) {
            parameters.ownersRemove.push(userId);
        }
        if (client.baUserIDs.includes(userId)) {
            parameters.membersRemove.push(userId);
        }
        await this.updateClientAccessIfHasChanges(parameters);
    }

    private createAccessObjectParameters(clientId: number): IManagerClientAccessParameters {
        return {
            id: clientId,
            ownersAdd: [] as number[],
            ownersRemove: [] as number[],
            membersAdd: [] as number[],
            membersRemove: [] as number[],
        };
    }

    private async updateClientAccessIfHasChanges(parameters: IManagerClientAccessParameters): Promise<void> {
        if (
            parameters.ownersRemove.length > 0 ||
            parameters.ownersAdd.length > 0 ||
            parameters.membersRemove.length > 0 ||
            parameters.membersAdd.length > 0
        ) {
            await this.clientActionApiService.setClientAccess(parameters);
        }
    }

    private async getClientDetailForEditAccess(clientId: number): Promise<IApiClient> {
        const client = (await this.clientActionApiService.getClients()).items.find((client) => client.id === clientId);
        if (client) {
            return client;
        }
        throw new AppNotFoundItemError("client", clientId);
    }
}
