import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMenuButtons } from "../../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import { WedIconPlusAdd, WedIconSettings6, WedIconTrashDeleteRemove } from "../../../../../components/wed-icon/generated/WedIconSvg";
import { ISelectItem } from "../../../../../components/status-action-button/StatusActionButton";
import { IClient } from "../../../../../services/WedApi/Models/Client";
import { IClientContact } from "../../../../../services/WedApi/Models/ClientContact";

interface PropsInterface extends WrappedComponentProps {
    client: IClient;
    selectedItems: ISelectItem[];
    createContactClick: () => void;
    editContactClick: (clientContact: IClientContact) => void;
    deleteContactClick: (clientContact: IClientContact) => void;
}

interface StateInterface {}

class ClientContactsListButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMenuButtons>
                <Button
                    disabled={!this.props.client.userIsOwner}
                    onClick={this.props.createContactClick}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}
                >
                    <FormattedMessage id="app.ClientContactsListButtons.createContact" />
                </Button>
                <Button
                    disabled={this.props.selectedItems.length !== 1 || !this.props.client.userIsOwner}
                    onClick={() => {
                        this.props.editContactClick(this.props.selectedItems[0].object as IClientContact);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconSettings6 />} />}
                >
                    <FormattedMessage id="app.ClientContactsListButtons.editContact" />
                </Button>
                <Button
                    disabled={this.props.selectedItems.length !== 1 || !this.props.client.userIsOwner}
                    onClick={() => {
                        this.props.deleteContactClick(this.props.selectedItems[0].object as IClientContact);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                >
                    <FormattedMessage id="app.ClientContactsListButtons.deleteContact" />
                </Button>
            </WedMenuButtons>
        );
    }
}

export default injectIntl(ClientContactsListButtons);
