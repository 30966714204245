import React, { Component } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ProjectTile from "../../../components/tile/ProjectTile/ProjectTile";
import ActionTile from "../../../components/tile/ActionTile/ActionTile";
import { IProject, ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import { WedTable } from "../../wed-table/WedTable";
import WedDate from "../../formatter/WedDate";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import Favorite from "../../../components/tile/components/Favorite/Favorite";
import { SizeType } from "../../wed-icon/WedIcon";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { ISelectItem } from "../../status-action-button/StatusActionButton";
import ProjectListActionButton from "../ListProjectPage/ProjectListPageHeader/ProjectListActionButton/ProjectListActionButton";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import "./ListProjectContent.scss";
import StatsCollection from "../../project/Project/StatsCollection/StatsCollection";

interface PropsInterface extends RouteComponentProps {
    isTableMode: boolean;
    handleSelectAll?: () => void;
    projects: IProject[];
    selectedProjects: ISelectItem[];
    handleSelectProject?: (id: number, forceMultiply?: boolean) => void;
    handleChangeFavorite?: (projectId: number) => void;
    handleStatusChange: () => void;
    setIsLoading: () => void;
    handleClickOpenCreateProject?: () => void;
}

interface StateInterface {}

class ListProjectContent extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.isSelected = this.isSelected.bind(this);
        this.goToProject = this.goToProject.bind(this);
    }

    private isSelected(id: number) {
        return !!this.props.selectedProjects.find((item) => item.id === id);
    }

    private goToProject(projectId: number) {
        this.props.history.push(generatePath(RoutesEnum.SUMMARY, { projectId }));
    }

    render() {
        return (
            <div className="list-project-content">
                {this.props.isTableMode ? (
                    <WedTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {this.props.handleSelectAll && (
                                        <Checkbox
                                            onChange={this.props.handleSelectAll}
                                            checked={this.props.projects.length === this.props.selectedProjects.length}
                                        />
                                    )}
                                </TableCell>
                                <TableCell />
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.projectId" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.projectName" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.customerName" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.status" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.createDate" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.createdBy" />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="app.projectList.table.ofCollections" />
                                </TableCell>
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.projects.map((project, i) => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    key={i}
                                    onClick={
                                        this.props.handleSelectProject
                                            ? () => {
                                                  this.props.handleSelectProject && this.props.handleSelectProject(project.id);
                                              }
                                            : undefined
                                    }
                                    onDoubleClick={
                                        project.status !== ProjectStatusEnum.archived
                                            ? () => {
                                                  this.goToProject(project.id);
                                              }
                                            : undefined
                                    }
                                    className={classNames({ "table-row--active": this.isSelected(project.id) })}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            onChange={
                                                this.props.handleSelectProject
                                                    ? () => {
                                                          this.props.handleSelectProject && this.props.handleSelectProject(project.id, true);
                                                      }
                                                    : undefined
                                            }
                                            checked={this.isSelected(project.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {this.props.handleChangeFavorite && (
                                            <Favorite
                                                isFavorite={project.isFavourite}
                                                projectId={project.id}
                                                handleChangeFavorite={this.props.handleChangeFavorite}
                                                sizeIcon={SizeType.SMALL}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell>{project.id}</TableCell>
                                    <TableCell>{project.name}</TableCell>
                                    <TableCell>{project.ecOrg.name || ""}</TableCell>
                                    <TableCell>
                                        <FormattedMessage id={`app.project.status.${project.status}`} />
                                    </TableCell>
                                    <TableCell>{project.created && <WedDate date={project.created} />}</TableCell>
                                    <TableCell>{project.createdBy ? `${project.createdBy.firstName} ${project.createdBy.lastName}` : ""}</TableCell>
                                    <TableCell>
                                        <StatsCollection value={project.rejectedCollections} className="project-stat__count--error" />
                                        &nbsp;/&nbsp;
                                        <StatsCollection value={project.approvedCollections} className="project-stat__count--success" />
                                        &nbsp;/&nbsp;
                                        <StatsCollection value={project.allCollections} className="project-stat__count--warning" />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ProjectListActionButton
                                            selectedProjects={[
                                                {
                                                    id: project.id,
                                                    status: project.status,
                                                    userIsOwner: project.userIsOwner,
                                                    object: project,
                                                },
                                            ]}
                                            setIsLoading={this.props.setIsLoading}
                                            actionCallback={this.props.handleStatusChange}
                                            icon={<MoreVertIcon />}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </WedTable>
                ) : (
                    <GridTiles>
                        {this.props.projects.map((project, i) => (
                            <ProjectTile
                                key={i}
                                projectId={project.id}
                                project={project.name}
                                client={project.ecOrg.name || ""}
                                accepted={project.approvedCollections}
                                rejected={project.rejectedCollections}
                                date={project.created}
                                author={`${project.createdBy?.firstName} ${project.createdBy?.lastName}`}
                                status={project.status}
                                internalTeam={project.numOfOwners + project.numOfMembers}
                                externalTeam={project.numOfECUsers}
                                isFavourite={project.isFavourite}
                                handleChangeFavorite={this.props.handleChangeFavorite}
                                selected={this.isSelected(project.id)}
                                onClick={
                                    this.props.handleSelectProject
                                        ? () => {
                                              this.props.handleSelectProject && this.props.handleSelectProject(project.id);
                                          }
                                        : undefined
                                }
                                onDoubleClick={
                                    project.status !== ProjectStatusEnum.archived
                                        ? () => {
                                              this.goToProject(project.id);
                                          }
                                        : undefined
                                }
                                actionButton={
                                    <ProjectListActionButton
                                        selectedProjects={[
                                            {
                                                id: project.id,
                                                status: project.status,
                                                userIsOwner: project.userIsOwner,
                                                object: project,
                                            },
                                        ]}
                                        setIsLoading={this.props.setIsLoading}
                                        actionCallback={this.props.handleStatusChange}
                                        icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                    />
                                }
                            />
                        ))}
                        {this.props.handleClickOpenCreateProject && (
                            <FormattedMessage id="app.tile.createProject">
                                {(message) => (
                                    <ActionTile
                                        className="project-tile__create-project"
                                        onClick={this.props.handleClickOpenCreateProject}
                                        title={message as string}
                                    />
                                )}
                            </FormattedMessage>
                        )}
                    </GridTiles>
                )}
            </div>
        );
    }
}

export default withRouter(ListProjectContent);
