import * as React from "react";
import "./WedMainMenu.scss";
import classNames from "classnames";

interface PropsInterface {
    className?: string;
    children?: React.ReactElement<WedMainMenu>[];
}

export class WedMainMenu extends React.Component<PropsInterface> {
    render() {
        return (
            <nav className={classNames("main-menu", this.props.className)}>
                {this.props.children && this.props.children.length > 0 && <ul>{this.props.children}</ul>}
            </nav>
        );
    }
}
