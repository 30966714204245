import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import SidebarNoSelected from "../../../../components/sidebar/SidebarNoSelected/SidebarNoSelected";
import CollectionFileSidebarShare from "./CollectionFileSidebarShare";
import CollectionSidebarShare from "./CollectionSidebarShare";
import { IShareCollection } from "../../../../services/WedApi/Models/ShareCollection";
import { INote } from "../../../../services/WedApi/Models/Note";
import { isEditableShareCollection } from "../../../../services/WedApi/Utils/IsEditableProject";
import container from "../../../../container";
import { IActivities } from "../../../../services/WedApi/Models/Activities";
import "../../../../components/sidebar/Sidebar/Sidebar.scss";

interface PropsInterface {
    token: string;
    loading: boolean;
    handleSidebarChanges: () => void;
    notes?: INote[];
    data?: {
        collection?: IShareCollection;
        fileId?: number;
        versionId?: number;
    };
}

interface StateInterface {
    loading: boolean;
    activities: IActivities[];
}

class SidebarShare extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            activities: [],
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (
            prevProps.data?.collection?.id !== this.props.data?.collection?.id ||
            prevProps.data?.fileId !== this.props.data?.fileId ||
            prevProps.data?.versionId !== this.props.data?.versionId
        ) {
            this.setState({ loading: true });
            await this.fetchData();
        }
    }

    private async getActivities(): Promise<IActivities[]> {
        if (this.props.data?.fileId && this.props.data?.collection?.id) {
            return container.shareCollectionService.getCollectionFileActivities(
                this.props.token,
                this.props.data.fileId,
                this.props.data.collection.id
            );
        }
        if (this.props.data?.collection?.id) {
            return container.shareCollectionService.getCollectionActivities(this.props.token, this.props.data.collection?.id);
        }
        return [];
    }

    private async fetchData() {
        this.setState({
            loading: false,
            activities: await this.getActivities(),
        });
    }

    private getLoadingContent() {
        return (
            <div className="sidebar__loader">
                <CircularProgress />
            </div>
        );
    }

    private displaySidebarVariant() {
        if (this.props.data?.collection && this.props.data?.fileId) {
            return (
                <CollectionFileSidebarShare
                    activities={this.state.activities}
                    token={this.props.token}
                    collection={this.props.data.collection}
                    fileId={this.props.data.fileId}
                    versionId={this.props.data.versionId}
                    disabled={!isEditableShareCollection(this.props.data.collection)}
                />
            );
        }
        if (this.props.data?.collection) {
            return (
                <CollectionSidebarShare
                    activities={this.state.activities}
                    token={this.props.token}
                    collection={this.props.data.collection}
                    disabled={!isEditableShareCollection(this.props.data.collection)}
                />
            );
        }
        return <SidebarNoSelected />;
    }

    render() {
        return <div className="sidebar">{this.props.loading || this.state.loading ? this.getLoadingContent() : this.displaySidebarVariant()}</div>;
    }
}

export default SidebarShare;
