import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import container from "../../../container";
import { setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { generatePath, Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import LinearProgress from "@material-ui/core/LinearProgress";
import Redirect404 from "../../error/Redirect404/Redirect404";
import LayoutPage from "../../layout/LayoutPage/LayoutPage";
import { IUserTeamDetail } from "../../../services/WedApi/Models/User";
import UserTeamUserClientsPage from "../../../pages/user-team/UserTeamUserClientsPage/UserTeamUserClientsPage";
import UserTeamUserProfilePage from "../../../pages/user-team/UserTeamUserProfilePage/UserTeamUserProfilePage";
import UserTeamUserProjectsPage from "../../../pages/user-team/UserTeamUserProjectsPage/UserTeamUserProjectsPage";
import { ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import FilesMediaGalleryPage from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";

export interface UserTeamDetailLoaderProps {
    userTeam: IUserTeamDetail;
}

interface PropsInterface extends SetPageInfoBoxTitlesActionProps {
    userTeamId: number;
}

interface StateInterface {
    userTeam?: IUserTeamDetail;
}

class UserTeamDetailLoader extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userTeam: undefined,
        };
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (prevProps.userTeamId !== this.props.userTeamId) {
            this.setState({ userTeam: undefined });
            await this.fetchData();
        }
    }

    private async fetchData() {
        this.props.setPageInfoBoxTitlesAction({});

        const userTeam = await container.userService.getUserDetail(this.props.userTeamId);

        this.setState({ userTeam });

        this.props.setPageInfoBoxTitlesAction({
            title: userTeam.name,
            subTitle: userTeam.email,
        });
    }

    render() {
        return (
            <LayoutPage>
                {this.state.userTeam ? (
                    <Switch>
                        <Route exact path={RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE}>
                            <UserTeamUserProfilePage userTeam={this.state.userTeam} />
                        </Route>
                        <Route exact path={RoutesEnum.USER_TEAM_DETAILS_CLIENTS}>
                            <UserTeamUserClientsPage userTeam={this.state.userTeam} />
                        </Route>
                        <Route exact path={RoutesEnum.USER_TEAM_DETAILS_PROJECTS_ACTIVE}>
                            <UserTeamUserProjectsPage userTeam={this.state.userTeam} projectStatus={ProjectStatusEnum.active} />
                        </Route>
                        <Route exact path={RoutesEnum.USER_TEAM_DETAILS_PROJECTS_CLOSED}>
                            <UserTeamUserProjectsPage userTeam={this.state.userTeam} projectStatus={ProjectStatusEnum.closed} />
                        </Route>
                        <Route
                            exact
                            path={RoutesEnum.USER_TEAM_DETAILS}
                            component={(route: RouteComponentProps<{ teamId: string }>) => {
                                return (
                                    <Redirect to={generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE, { teamId: route.match.params.teamId })} />
                                );
                            }}
                        />
                        <Route
                            path={RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PRIVATE_PORTFOLIO}
                            component={() => {
                                return <FilesMediaGalleryPage fileType={FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO} userTeam={this.state.userTeam} />;
                            }}
                        />
                        <Route
                            path={RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PROJECT_WORK}
                            component={() => {
                                return <FilesMediaGalleryPage fileType={FileTypeEnum.TEAM_USER_PROJECT_WORK} userTeam={this.state.userTeam} />;
                            }}
                        />
                        <Route component={Redirect404} />
                    </Switch>
                ) : (
                    <LinearProgress />
                )}
            </LayoutPage>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(UserTeamDetailLoader);
