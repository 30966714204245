import React from "react";
import { WedTable } from "../../wed-table/WedTable";
import { Button, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FormattedMessage } from "react-intl";
import PopupAction from "../../popup/PopupAction/PopupAction";
import PopupHeader from "../../popup/PopupHeader/PopupHeader";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconBackwardRearwardBackRemove, WedIconFolderBlank, WedIconTrashDeleteRemove } from "../../wed-icon/generated/WedIconSvg";
import { IDirectory, IFile, IPath } from "../../../services/WedApi/Models/File";
import { ProviderContext, withSnackbar } from "notistack";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import { IProject } from "../../../services/WedApi/Models/Project";
import { FileSize } from "../../file/FileSize/FileSize";
import WedDate from "../../formatter/WedDate";
import Initials from "../../tile/components/Initials/Initials";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import isEqual from "lodash/isEqual";
import { uniqueValues } from "../../../services/WedApi/Utils/ItemCollection";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import "./Trash.scss";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    project?: IProject;
    fileType: FileTypeEnum;
}

interface StateInterface {
    directories: IDirectory[];
    files: IFile[];
    isLoading: boolean;
    selectedDirectories: number[];
    selectedFiles: number[];
}

class Trash extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            directories: [],
            files: [],
            isLoading: true,
            selectedDirectories: [],
            selectedFiles: [],
        };
        this.selectUnselectAll = this.selectUnselectAll.bind(this);
        this.selectUnselectFile = this.selectUnselectFile.bind(this);
        this.selectUnselectDirectory = this.selectUnselectDirectory.bind(this);
        this.restoreSelected = this.restoreSelected.bind(this);
        this.restoreAll = this.restoreAll.bind(this);
        this.removeAllFromTrash = this.removeAllFromTrash.bind(this);
    }

    async componentDidMount() {
        await this.reloadTrash();
    }

    private async restoreSelected(): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.restoreFromTrash(this.state.selectedDirectories, this.state.selectedFiles);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async restoreAll(): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.restoreFromTrash(
                this.state.directories.map((dir) => dir.id),
                this.state.files.map((file) => file.id)
            );
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async restoreFile(id: number): Promise<void> {
        this.setState({ isLoading: true });
        try {
           await container.trashService.restoreFromTrash([], [id]);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async restoreDirectory(id: number): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.restoreFromTrash([id], []);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async removeAllFromTrash(): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.deleteFromTrash(
                this.state.directories.map((dir) => dir.id),
                []
            );
            const reloadedData = await container.trashService.getTrash(this.props.fileType, this.props.project?.id);
            await container.trashService.deleteFromTrash(
                [],
                reloadedData.files.map((file) => file.id)
            );
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async removeFileFromTrash(id: number): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.deleteFromTrash([], [id]);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async removeDirectoryFromTrash(id: number): Promise<void> {
        this.setState({ isLoading: true });
        try {
            await container.trashService.deleteFromTrash([id], []);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({ isLoading: false });
        }
        await this.reloadTrash();
    }

    private async reloadTrash() {
        const trashData = await container.trashService.getTrash(this.props.fileType, this.props.project?.id);
        this.setState({
            directories: trashData.directories,
            files: trashData.files,
            isLoading: false,
            selectedDirectories: [],
            selectedFiles: [],
        });
    }

    private isDisabledRestoreSelected(): boolean {
        return this.state.selectedFiles.length === 0 && this.state.selectedDirectories.length === 0;
    }

    private selectUnselectAll(): void {
        if (this.isSelectedAll()) {
            this.setState({
                selectedDirectories: [],
                selectedFiles: [],
            });
            return;
        }
        const selectAllIds = this.prepareSelectedAllIds();
        this.setState({
            selectedDirectories: selectAllIds.directories,
            selectedFiles: selectAllIds.files,
        });
    }

    private selectUnselectFile(fileId: number) {
        this.setState({
            selectedFiles: uniqueValues(this.prepareSelectUnselectIds(this.state.selectedFiles, fileId)),
        });
    }

    private selectUnselectDirectory(directoryId: number) {
        this.setState({
            selectedDirectories: uniqueValues(this.prepareSelectUnselectIds(this.state.selectedDirectories, directoryId)),
        });
    }

    private prepareSelectUnselectIds(stateIds: number[], changeId: number): number[] {
        let isSelected: boolean = false;
        const ids: number[] = [];
        stateIds.forEach((selectedId) => {
            if (selectedId === changeId) {
                isSelected = true;
            } else {
                ids.push(selectedId);
            }
        });
        if (!isSelected) {
            ids.push(changeId);
        }
        return ids;
    }

    private isSelectedAll(): boolean {
        return isEqual(this.prepareSelectedAllIds(), {
            files: this.state.selectedFiles,
            directories: this.state.selectedDirectories,
        });
    }

    private prepareSelectedAllIds(): { files: number[]; directories: number[] } {
        return {
            directories: uniqueValues(this.state.directories.map((directory) => directory.id)),
            files: uniqueValues(this.state.files.map((file) => file.id)),
        };
    }

    render() {
        const { isLoading, directories, files } = this.state;

        return (
            <div className="trash">
                <PopupHeader>
                    <FormattedMessage id="app.trash.header" />
                </PopupHeader>
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className="trash__table-container">
                        {directories.length === 0 && files.length === 0 ? (
                            <>
                                <div className="trash__table-empty-content">
                                    <FormattedMessage id="app.trash.emptyContent" />
                                </div>
                            </>
                        ) : (
                            <>
                                <WedTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="trash__hide-cell-480 trash__cell-with-2-icons">
                                                <Checkbox
                                                    onClick={this.selectUnselectAll}
                                                    className="trash__hide-cell-480"
                                                    checked={this.isSelectedAll()}
                                                />
                                                <button
                                                    className="wed-icon-button"
                                                    disabled={this.isDisabledRestoreSelected()}
                                                    onClick={this.restoreSelected}
                                                >
                                                    <WedIcon icon={<WedIconBackwardRearwardBackRemove />} size={SizeType.SMALL} />
                                                </button>
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-640 trash__cell-with-folder-icon"></TableCell>
                                            <TableCell className="trash__header-file-name {">
                                                <FormattedMessage id="app.fileList.fileName" />
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-1280">
                                                <FormattedMessage id="app.fileList.localization" />
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-960">
                                                <FormattedMessage id="app.fileList.size" />
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-640">
                                                <FormattedMessage id="app.fileList.fileType" />
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-960">
                                                <FormattedMessage id="app.fileList.lastActivity" />
                                            </TableCell>
                                            <TableCell className="trash__hide-cell-1280 trash__cell-initials">
                                                <FormattedMessage id="app.fileList.deletedBy" />
                                            </TableCell>
                                            <TableCell className="trash__cell-action-icons"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.directories.map((directory: IDirectory, i) => {
                                            const removedUser = directory.removedBy ? getInitialsAndOwner(directory.removedBy) : undefined;
                                            return (
                                                <TableRow hover role="checkbox" key={`dir${i}`}>
                                                    <TableCell className="trash__hide-cell-480" padding="checkbox">
                                                        <Checkbox
                                                            onClick={() => {
                                                                this.selectUnselectDirectory(directory.id);
                                                            }}
                                                            checked={this.state.selectedDirectories.includes(directory.id)}
                                                            inputProps={{ "aria-labelledby": "xxx" }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-640">
                                                        <WedIcon icon={<WedIconFolderBlank />} size={SizeType.SMALL} />
                                                    </TableCell>
                                                    <TableCell className="trash__cell-file-name">{directory.name}</TableCell>
                                                    <TableCell className="trash__hide-cell-1280 trash__cell-localization">
                                                        {(directory.paths.length > 1 ? directory.paths.slice(1) : [])
                                                            .map((path: IPath) => path.name)
                                                            .join("/")}
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-960"></TableCell>
                                                    <TableCell className="trash__hide-cell-640">
                                                        <FormattedMessage id="app.trash.typeFolder" />
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-960">
                                                        {directory.removed && <WedDate date={directory.removed} />}
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-1280 trash__cell-initials">
                                                        {removedUser && <Initials title={removedUser.initials} fullTitle={removedUser.owner} />}
                                                    </TableCell>
                                                    <TableCell className="trash__action-icons" align="right">
                                                        <button
                                                            className="wed-icon-button"
                                                            onClick={async () => {
                                                                await this.restoreDirectory(directory.id);
                                                            }}
                                                        >
                                                            <WedIcon icon={<WedIconBackwardRearwardBackRemove />} size={SizeType.SMALL} />
                                                        </button>
                                                        <button
                                                            className="wed-icon-button"
                                                            onClick={async () => {
                                                                await this.removeDirectoryFromTrash(directory.id);
                                                            }}
                                                        >
                                                            <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {this.state.files.map((file: IFile, i) => {
                                            const removedUser = file.removedBy ? getInitialsAndOwner(file.removedBy) : undefined;
                                            return (
                                                <TableRow hover role="checkbox" key={`file${i}`}>
                                                    <TableCell className="trash__hide-cell-480" padding="checkbox">
                                                        <Checkbox
                                                            onClick={() => {
                                                                this.selectUnselectFile(file.id);
                                                            }}
                                                            checked={this.state.selectedFiles.includes(file.id)}
                                                            inputProps={{ "aria-labelledby": "xxx" }}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-640">
                                                        <WedIcon
                                                            icon={<FileTypeIcon extension={file.extension} fileName={file.name} />}
                                                            size={SizeType.SMALL}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="trash__cell-file-name">{file.name}</TableCell>
                                                    <TableCell className="trash__hide-cell-1280"></TableCell>
                                                    <TableCell className="trash__hide-cell-960">
                                                        <FileSize size={file.size} />
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-640">
                                                        {file.extension ? file.extension.toUpperCase() : ""}
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-960">
                                                        {file.removed && <WedDate date={file.removed} />}
                                                    </TableCell>
                                                    <TableCell className="trash__hide-cell-1280 trash__cell-initials">
                                                        {removedUser && <Initials title={removedUser.initials} fullTitle={removedUser.owner} />}
                                                    </TableCell>
                                                    <TableCell className="trash__action-icons" align="right">
                                                        <button
                                                            className="wed-icon-button"
                                                            onClick={async () => {
                                                                await this.restoreFile(file.id);
                                                            }}
                                                        >
                                                            <WedIcon icon={<WedIconBackwardRearwardBackRemove />} size={SizeType.SMALL} />
                                                        </button>
                                                        <button
                                                            className="wed-icon-button"
                                                            onClick={async () => {
                                                                await this.removeFileFromTrash(file.id);
                                                            }}
                                                        >
                                                            <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />
                                                        </button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </WedTable>
                                <PopupAction>
                                    <Button type="submit" disabled={this.isDisabledRestoreSelected()} onClick={this.restoreSelected}>
                                        <FormattedMessage id="app.trash.button.restore" />
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={this.state.files.length === 0 && this.state.directories.length === 0}
                                        onClick={this.restoreAll}
                                    >
                                        <FormattedMessage id="app.trash.button.restoreAll" />
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={this.state.files.length === 0 && this.state.directories.length === 0}
                                        onClick={this.removeAllFromTrash}
                                    >
                                        <FormattedMessage id="app.trash.button.emptyRecycleBin" />
                                    </Button>
                                </PopupAction>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withSnackbar(injectIntl(Trash));
