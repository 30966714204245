import React, {MouseEvent, ReactElement} from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import {ProviderContext, withSnackbar} from "notistack";
import classnames from "classnames";
import {SizeType, WedIcon} from "../../../../wed-icon/WedIcon";
import {
    WedIconAddCirlcePlus,
    WedIconArrow,
    WedIconDoneCheck2,
    WedIconDownloadSaveUpload1,
    WedIconEye,
    WedIconFolderMoveArrowRight,
    WedIconFolderOpen,
    WedIconPenEdit17,
    WedIconPenEditCreate3,
    WedIconPlusAdd,
    WedIconTrashDeleteRemove,
} from "../../../../wed-icon/generated/WedIconSvg";
import {
    IBreadcrumbActionButtons,
    ISelectItem,
    ISelectItemType,
    StatusActionButton
} from "../../../../status-action-button/StatusActionButton";
import container from "../../../../../container";
import RenamePopup from "../../../../../components/project/Files/RenamePopup";
import ChangeDescriptionPopup from "../../../../../components/project/Files/ChangeDescriptionPopup";
import {IDirectory, IFile} from "../../../../../services/WedApi/Models/File";
import {IMenuItem} from "../../../../wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import FrontApiErrorCodeEnum from "../../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import {CreateCollectionPopup} from "../../../../project/Collection/CreateCollectionPopup/CreateCollectionPopup";
import {downloadFile} from "../../../../../services/WedApi/Utils/DownloadFile";
import {SizeImageEnum} from "../../../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import RemoveFromCollectionPopup
    from "../../../../project/Collection/RemoveFromCollectionPopup/RemoveFromCollectionPopup";
import {ICollectionForObjectItem, IObjectItem} from "../../../../../services/WedApi/Services/CollectionAddFilesService";
import {ICollection, ICollectionExtended} from "../../../../../services/WedApi/Models/Collection";
import {IProject, ProjectStatusEnum} from "../../../../../services/WedApi/Models/Project";

import "./FilesActionButton.scss";
import {FileTypeEnum} from "../../../Type/FileTypeEnum";
import {isEditableProject, isMyLibraryFileTypeContext} from "../../../../../services/WedApi/Utils/IsEditableProject";
import {LogicError} from "../../../../../errors/error-app";
import {ApprovalRequestPopup} from "../../../../project/Collection/ApprovalRequestPopup/ApprovalRequestPopup";
import ManagerFolderAccessPopup from "../../../../my-library/ManagerFolderAccessPopup/ManagerFolderAccessPopup";
import {ApplicationState} from "../../../../../store";
import {connect} from "react-redux";
import DataFilesFactory from "../../Service/DataFilesByType/DataFilesFactory";
import FetchDataReturnInterface from "../../Service/DataFilesByType/FilesData/FetchDataReturnInterface";

enum ACTION_TYPES {
    RENAME,
    CHANGE_DESCRIPTION,
    CREATE_COLLECTION,
    REMOVE_FROM_COLLECTION,
    APPROVAL_REQUEST_COLLECTION,
    MANAGER_FOLDER_ACCESS,
}

interface MapDir {
    id: number;
    name: string;
    loaded: boolean;
    level: number;
    parentId: number | undefined;
    access: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    project: IProject;
    dirId: number;
    rootDir?: number;
    rootDirName: string;
    selectedItems: ISelectItem[];
    collection?: ICollectionExtended;
    icon?: ReactElement;
    actionCallback?: () => void;
    onInitActionCallback?: () => void;
    onErrorActionCallback?: () => void;
    removeToTrash?: (items: ISelectItem[]) => void;
    handleOpenFile?: (file: IFile, dirId: number) => void;
    handleOpenFolder?: (directory: IDirectory) => void;
    breadcrumbItem?: IBreadcrumbActionButtons;
    noDisplay?: {
        openFolder?: boolean;
    };
    fileType: FileTypeEnum;
    userId?: number;
}

interface StateInterface {
    loadingCollections: boolean;
    collectionForObjectItem?: ICollectionForObjectItem;
    actionParameters:
        | {
        actionType: ACTION_TYPES;
        id: number;
        type: ISelectItemType;
        object?: IFile | IDirectory;
        // eslint-disable-next-line
        extra?: any;
    }
        | undefined;
    moveToDirMap: MapDir[];
    loadingDirId: number;
    folderFilesIds: number[] | undefined;
}

class FilesActionButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loadingCollections: false,
            collectionForObjectItem: undefined,
            actionParameters: undefined,
            moveToDirMap: [],
            loadingDirId: 0,
            folderFilesIds: undefined,
        };
    }

    fetchFolderFilesIds = async () => {
        const {selectedItems} = this.props;

        this.setState({loadingCollections: true});
        if (selectedItems.length == 1 && selectedItems[0].type === ISelectItemType.directory) {
            const folder = await container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, selectedItems[0].id);
            const filesIds = folder.files.map((file) => file.id);

            this.setState({
                folderFilesIds: filesIds,
                loadingCollections: false,
            });

            return filesIds;
        }

        return [];
    };

    getSelectedItemForCollection = (): IObjectItem[] => {
        const {selectedItems} = this.props;
        return selectedItems.reduce((objectItems: IObjectItem[], selectedItem: ISelectItem) => {
            if (selectedItem.type === ISelectItemType.directory) {
                objectItems.push({type: "directory", id: selectedItem.id});
            } else if (selectedItem.type === ISelectItemType.file) {
                objectItems.push({type: "file", id: selectedItem.id});
            }
            return objectItems;
        }, [] as IObjectItem[]);
    };

    onInitActionCallback = () => {
        const {onInitActionCallback} = this.props;

        onInitActionCallback && onInitActionCallback();
    };

    onErrorActionCallback = () => {
        const {onErrorActionCallback} = this.props;

        onErrorActionCallback && onErrorActionCallback();
    };

    actionCallback = () => {
        const {actionCallback} = this.props;

        actionCallback && actionCallback();
    };

    isSelectedItemOwner = () => {
        const {selectedItems} = this.props;

        return selectedItems.length == 1 && selectedItems[0].userIsOwner;
    };

    fetchCollections = async () => {
        const {selectedItems} = this.props;
        const {loadingCollections, collectionForObjectItem} = this.state;

        if (loadingCollections && selectedItems.length == 1) {
            return;
        }

        if (collectionForObjectItem !== undefined) {
            this.setState({
                collectionForObjectItem: undefined,
            });
        } else {
            this.setState({loadingCollections: true});
            this.setState({
                collectionForObjectItem: await container.collectionAddFilesService.getCollectionForObjectItems(
                    this.getSelectedItemForCollection(),
                    this.props.fileType,
                    this.props.project.id
                ),
                loadingCollections: false,
            });
        }
    };

    onOpenFile = (id: number, type: ISelectItemType, object?: IFile | IDirectory) => {
        this.props.handleOpenFile && this.props.handleOpenFile(object as IFile, this.props.dirId);
    };

    showRenamePopup = (id: number, type: ISelectItemType, object?: IFile | IDirectory) => {
        this.setState({
            actionParameters: {actionType: ACTION_TYPES.RENAME, id, type, object},
        });
    };

    showChangeDescriptionPopup = (id: number, type: ISelectItemType, object?: IFile | IDirectory) => {
        this.setState({
            actionParameters: {actionType: ACTION_TYPES.CHANGE_DESCRIPTION, id, type, object},
        });
    };

    showManagerFolderAccessPopup = (id: number, type: ISelectItemType, object?: IDirectory) => {
        this.setState({
            actionParameters: {actionType: ACTION_TYPES.MANAGER_FOLDER_ACCESS, id, type, object},
        });
    };

    showAddToCollectionPopup = (type: ISelectItemType) => {
        this.setState({
            actionParameters: {actionType: ACTION_TYPES.CREATE_COLLECTION, id: this.props.selectedItems[0].id, type},
        });
    };

    showRemoveFromCollectionPopup = (type: ISelectItemType) => {
        this.setState({
            actionParameters: {
                actionType: ACTION_TYPES.REMOVE_FROM_COLLECTION,
                id: this.props.selectedItems[0].id,
                type,
            },
        });
    };

    showApprovalRequestCollectionPopup = (type: ISelectItemType) => {
        this.setState({
            actionParameters: {
                actionType: ACTION_TYPES.APPROVAL_REQUEST_COLLECTION,
                id: this.props.selectedItems[0].id,
                type,
            },
        });
    };

    onCloseAddCollectionPopup = async () => {
        this.onClosePopup();
    };

    onClosePopup = () => {
        this.setState({actionParameters: undefined});
    };

    onSuccess = (withCallback = true) => {
        this.onClosePopup();

        if (withCallback) {
            this.actionCallback();
        }
    };

    displayCollections = (type: ISelectItemType, items: IObjectItem[]) => {
        const {collectionForObjectItem} = this.state;

        if (!collectionForObjectItem) {
            return [];
        }

        return [
            {
                label: this.props.intl.formatMessage({id: "app.statusActionButton.createNewCollection"}),
                icon: <WedIcon icon={<WedIconPlusAdd/>} size={SizeType.SMALL}/>,
                level: 1,
                divider: collectionForObjectItem.collection.length === 0,
                click: () => {
                    this.showAddToCollectionPopup(type);
                },
            },
            ...collectionForObjectItem.collection.map((collection, index) => {
                return {
                    label: collection.name,
                    level: 1,
                    divider: index === collectionForObjectItem.collection.length - 1,
                    click: async () => {
                        await this.moveFilesToCollection(collection.id, items);
                    },
                    disabled: collection.locked || collection.filesToAdd.length === 0 || !collection.userIsOwner,
                };
            }),
        ];
    };

    moveFilesToCollection = async (collectionId: number, items: IObjectItem[]) => {
        if (items.length > 0)
            try {
                await container.collectionAddFilesService.updateCollectionByObjectItems(collectionId, items, this.props.fileType);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({id: `app.projectFilesActionButton.success.collection.files`}), {
                    variant: "success",
                });
            } catch (error) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            }
    };

    displayActionPopup = () => {
        const {actionParameters} = this.state;

        if (!actionParameters) {
            return null;
        }

        switch (actionParameters.actionType) {
            case ACTION_TYPES.RENAME:
                return (
                    <RenamePopup
                        id={actionParameters.id}
                        type={actionParameters.type}
                        object={actionParameters.object}
                        handleClose={this.onClosePopup}
                        onSuccess={this.onSuccess}
                    />
                );
            case ACTION_TYPES.CHANGE_DESCRIPTION:
                return (
                    <ChangeDescriptionPopup
                        id={actionParameters.id}
                        type={actionParameters.type}
                        object={actionParameters.object}
                        handleClose={this.onClosePopup}
                        extra={{collection: this.props.collection}}
                        onSuccess={this.onSuccess}
                    />
                );
            case ACTION_TYPES.MANAGER_FOLDER_ACCESS:
                return (
                    <ManagerFolderAccessPopup
                        object={actionParameters.object as IDirectory}
                        handleClose={this.onClosePopup}
                        onSuccess={this.onSuccess}
                        close={this.onCloseAddCollectionPopup}
                    />
                );
            case ACTION_TYPES.CREATE_COLLECTION:
                return (
                    <CreateCollectionPopup
                        open={true}
                        handleClose={this.onCloseAddCollectionPopup}
                        projectId={this.props.project.id}
                        objectIds={
                            actionParameters.type == ISelectItemType.file
                                ? this.props.selectedItems.map((selectedItem) => selectedItem.id)
                                : this.state.folderFilesIds
                        }
                        redirectToCollection={false}
                    />
                );
            case ACTION_TYPES.REMOVE_FROM_COLLECTION:
                return (
                    <RemoveFromCollectionPopup
                        open={true}
                        handleClose={this.onClosePopup}
                        onSuccess={this.onSuccess}
                        files={
                            this.props.selectedItems
                                .filter((selectedItem) => selectedItem.type === ISelectItemType.file)
                                .map((selectedItem) => selectedItem.object) as IFile[]
                        }
                        collectionId={this.props.collection?.id}
                    />
                );
            case ACTION_TYPES.APPROVAL_REQUEST_COLLECTION:
                return (
                    <ApprovalRequestPopup
                        file={this.props.selectedItems[0].object as IFile}
                        clientId={this.props.project.ecOrg.id}
                        collectionId={(this.props.collection as ICollection).id}
                        open={true}
                        handleClose={this.onSuccess}
                    />
                );
            default:
                return null;
        }
    };

    getAllChildrenRecursiveIds = (dirId: number): number[] => {
        const childrenIds = this.state.moveToDirMap.filter((item) => item.parentId === dirId).map((item) => item.id);
        return childrenIds.concat(...childrenIds.map((childrenId) => this.getAllChildrenRecursiveIds(childrenId)));
    };

    loadDir = async (dirId: number) => {
        const {rootDir, rootDirName} = this.props;
        const {moveToDirMap} = this.state;

        if (!rootDir) {
            throw new LogicError("Props rootDir is required");
        }

        const item = moveToDirMap.find((item) => item.id == dirId);

        if (item && item.loaded) {
            if (item.loaded) {
                if (!item.parentId) {
                    this.setState({
                        moveToDirMap: [],
                    });
                    return;
                }
                const childrenIds = this.getAllChildrenRecursiveIds(dirId);
                const filteredList = moveToDirMap
                    .filter((item) => !childrenIds.includes(item.id))
                    .map((item) => {
                        if (item.id === dirId) {
                            return {
                                ...item,
                                loaded: false,
                            };
                        }
                        return item;
                    });
                this.setState({
                    moveToDirMap: filteredList,
                });
            }
            return;
        }

        const data: FetchDataReturnInterface = await DataFilesFactory.getFileType(this.props.fileType).getFolder(dirId, this.props.userId as number);

        const loadedItems = data.directories.map((item) => {
            return {
                id: item.id,
                name: item.name,
                loaded: false,
                parentId: dirId,
                level: data.parentFolder?.paths.length ?? 0,
                access:
                    this.props.fileType !== FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME ||
                    item.userIsOwner ||
                    item.writeAccessIds.includes(this.props.userId || 0),
            };
        });

        if (!this.state.moveToDirMap.length) {
            this.setState({
                moveToDirMap: [
                    {
                        id: rootDir,
                        name: rootDirName,
                        loaded: true,
                        parentId: 0,
                        level: 0,
                        access: true,
                    },
                    ...loadedItems,
                ],
            });
        } else {
            const dirMap = [...this.state.moveToDirMap];
            const insertIndex = dirMap.findIndex((item) => item.id == dirId);

            if (insertIndex > -1) {
                dirMap[insertIndex].loaded = true;
                dirMap.splice(insertIndex + 1, 0, ...loadedItems);

                this.setState({moveToDirMap: dirMap});
            }
        }
    };

    moveDirArrowClickHandler = (e: MouseEvent, itemId: number) => {
        e.stopPropagation();

        if (!itemId) {
            return;
        }

        this.setState({loadingDirId: itemId}, () => {
            this.loadDir(itemId).then(() => {
                this.setState({loadingDirId: 0});
            });
        });
    };

    moveToDir = async (destinationId: number) => {
        const {dirId, selectedItems} = this.props;

        if (dirId == destinationId) {
            return;
        }

        this.onInitActionCallback();
        try {
            const selectedId = selectedItems[0].id;
            let messageId;
            if (selectedItems[0].type == ISelectItemType.file) {
                await container.filesService.moveFile([selectedId], destinationId);
                messageId = "app.projectFilesActionButton.success.file";
            } else {
                await container.filesService.moveFolder([selectedId], destinationId);
                messageId = "app.projectFilesActionButton.success.folder";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: messageId}), {variant: "success"});
            this.actionCallback();
        } catch (e) {
            let keyTranslate;

            switch (e.code) {
                case FrontApiErrorCodeEnum.ERR_FOLDER_ID_INVALID:
                    keyTranslate = "app.error.errFolderIDInvalid";
                    break;
                case FrontApiErrorCodeEnum.ERR_OBJECT_INVALID:
                    keyTranslate = "app.error.errObjectInvalid";
                    break;
                default:
                    keyTranslate = "app.error.undefined";
            }

            this.onErrorActionCallback();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: keyTranslate}), {variant: "error"});
        }
    };

    displayMoveToDirsOptions = (): IMenuItem[] => {
        const {dirId, selectedItems} = this.props;
        if (selectedItems.length === 0) {
            return [];
        }
        const {moveToDirMap, loadingDirId} = this.state;
        const selectedType = selectedItems[0].type;
        const selectedId = selectedItems[0].id;

        return moveToDirMap.map((item) => {
            return {
                label: item.name,
                icon: (
                    <WedIcon
                        className={classnames("move-directory-icon", {
                            "move-directory-icon--expanded": item.loaded,
                        })}
                        onClick={(e) => {
                            this.moveDirArrowClickHandler(e, item.id);
                        }}
                        size={SizeType.SMALL}
                        icon={<WedIconArrow/>}
                    />
                ),
                level: item.level,
                loading: loadingDirId == item.id,
                click: async () => {
                    await this.moveToDir(item.id);
                },
                disabled: (selectedType == ISelectItemType.directory && item.id == selectedId) || !item.access,
                disabledLabel:
                    (selectedType == ISelectItemType.file && item.id == dirId) ||
                    (selectedType == ISelectItemType.directory && item.id == dirId) ||
                    !item.access,
            };
        });
    };

    addRemoveToTrash = (disabled?: boolean) => {
        const {removeToTrash, selectedItems, dirId} = this.props;

        if (!removeToTrash || !dirId) {
            return [];
        }

        return [
            {
                label: this.props.intl.formatMessage({id: "app.ProjectFilesButtons.removeToTrash"}),
                icon: <WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove/>}/>,
                disabled: disabled || !(
                    (
                        !isMyLibraryFileTypeContext(this.props.fileType) &&
                        isEditableProject(this.props.fileType, this.props.project) &&
                        selectedItems.length > 0
                    )
                    ||
                    (
                        isMyLibraryFileTypeContext(this.props.fileType) &&
                        (this.isSelectedItemOwner() || this.accessWriteFile())
                    )
                ),
                click: () => {
                    removeToTrash(selectedItems);
                },
            },
        ];
    };

    addManagerFolderAccess = () => {
        const {selectedItems} = this.props;

        if (![FileTypeEnum.MY_LIBRARY_FILES, FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME].includes(this.props.fileType) || selectedItems.length === 0) {
            return [];
        }

        return [
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.manageReadWriteAccess"}),
                icon: <WedIcon icon={<WedIconPenEditCreate3/>} size={SizeType.SMALL}/>,
                divider: true,
                disabled: !selectedItems[0].userIsOwner,
                click: () => {
                    this.showManagerFolderAccessPopup(selectedItems[0].id, ISelectItemType.directory, selectedItems[0].object as IDirectory);
                },
            },
        ];
    };

    getFileItems = () => {
        const {selectedItems, rootDir, dirId, collection, project} = this.props;
        const {moveToDirMap} = this.state;

        return [
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.preview"}),
                icon: <WedIcon icon={<WedIconEye/>} size={SizeType.SMALL}/>,
                divider: true,
                disabled: !this.props.handleOpenFile || selectedItems.length !== 1,
                click: () => {
                    this.onOpenFile(selectedItems[0].id, ISelectItemType.file, selectedItems[0].object as IFile);
                },
            },
            dirId
                ? {
                    label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.rename"}),
                    icon: <WedIcon icon={<WedIconPenEdit17/>} size={SizeType.SMALL}/>,
                    disabled: !(
                        (!isMyLibraryFileTypeContext(this.props.fileType) && isEditableProject(this.props.fileType, this.props.project)) ||
                        this.isSelectedItemOwner() ||
                        this.accessWriteFile()
                    ),
                    click: () => {
                        this.showRenamePopup(selectedItems[0].id, ISelectItemType.file, selectedItems[0].object as IFile);
                    },
                }
                : undefined,
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.description"}),
                icon: <WedIcon icon={<WedIconPenEditCreate3/>} size={SizeType.SMALL}/>,
                divider: true,
                disabled:
                    (!isMyLibraryFileTypeContext(this.props.fileType) && !isEditableProject(this.props.fileType, this.props.project)) ||
                    selectedItems.length !== 1 ||
                    !(
                        (dirId && (this.isSelectedItemOwner() || this.accessWriteFile())) ||
                        (collection && collection.userIsOwner && !collection.locked && project.status === ProjectStatusEnum.active)
                    ),
                click: () => {
                    this.showChangeDescriptionPopup(selectedItems[0].id, ISelectItemType.file, selectedItems[0].object as IFile);
                },
            },
            dirId && rootDir
                ? {
                    label: this.props.intl.formatMessage({id: "app.statusActionButton.moveTo"}),
                    keyPrefix: "move-file",
                    icon: <WedIcon icon={<WedIconFolderMoveArrowRight/>} size={SizeType.SMALL}/>,
                    divider: moveToDirMap.length == 0,
                    loading: this.state.loadingDirId == rootDir,
                    disabled:
                        (!isMyLibraryFileTypeContext(this.props.fileType) && !isEditableProject(this.props.fileType, this.props.project)) ||
                        selectedItems.length !== 1 ||
                        !(this.isSelectedItemOwner() || this.accessWriteFile()),
                    click: (e: MouseEvent) => {
                        this.moveDirArrowClickHandler(e, rootDir);
                    },
                }
                : undefined,
            ...this.displayMoveToDirsOptions(),
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.download"}),
                icon: <WedIcon icon={<WedIconDownloadSaveUpload1/>} size={SizeType.SMALL}/>,
                divider: true,
                disabled: selectedItems.length !== 1,
                click: () => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({id: `app.projectFilesActionButton.download`}), {
                        variant: "success",
                        autoHideDuration: 4000,
                    });
                    const file: IFile = selectedItems[0].object as IFile;
                    downloadFile(container.filesService.getFileUrl(file.id, file.newestVersionId, {size: SizeImageEnum.ORG}));
                },
            },
            dirId
                ? {
                    label: this.props.intl.formatMessage({id: "app.statusActionButton.addFileToCollection"}),
                    icon: <WedIcon icon={<WedIconAddCirlcePlus/>} size={SizeType.SMALL}/>,
                    loading: this.state.loadingCollections,
                    disabled: !isMyLibraryFileTypeContext(this.props.fileType) && !isEditableProject(this.props.fileType, this.props.project),
                    click: async (event: MouseEvent) => {
                        event.stopPropagation();
                        await this.fetchCollections();
                    },
                    divider: !this.state.collectionForObjectItem,
                }
                : undefined,
            ...this.displayCollections(ISelectItemType.file, this.getSelectedItemForCollection()),
            ...this.addRemoveToTrash(),
            ...(collection
                ? [
                    {
                        label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.approvalRequest"}),
                        icon: <WedIcon icon={<WedIconDoneCheck2/>} size={SizeType.SMALL}/>,
                        disabled: isMyLibraryFileTypeContext(this.props.fileType) || !(
                            collection.userIsOwner &&
                            selectedItems.length === 1 &&
                            !collection.locked &&
                            (collection.baUsers.length > 0 || collection.sharedTo.length > 0) &&
                            isEditableProject(this.props.fileType, this.props.project)
                        ),
                        click: () => {
                            this.showApprovalRequestCollectionPopup(ISelectItemType.file);
                        },
                        divider: true,
                    },
                    {
                        label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.removeFromCollection"}),
                        icon: <WedIcon icon={<WedIconTrashDeleteRemove/>} size={SizeType.SMALL}/>,
                        disabled: !(collection.userIsOwner && !collection.locked && isEditableProject(this.props.fileType, this.props.project)),
                        click: () => {
                            this.showRemoveFromCollectionPopup(ISelectItemType.file);
                        },
                    },
                ]
                : []),
        ].filter((item) => item !== undefined);
    };

    getDirectoryItems = () => {
        const {selectedItems, handleOpenFolder, rootDir} = this.props;

        return [
            ...(this.props.noDisplay?.openFolder
                ? []
                : [
                    {
                        label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.open"}),
                        icon: <WedIcon icon={<WedIconFolderOpen/>} size={SizeType.SMALL}/>,
                        divider: true,
                        click: () => handleOpenFolder && handleOpenFolder(selectedItems[0].object as IDirectory),
                    },
                ]),
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.rename"}),
                icon: <WedIcon icon={<WedIconPenEdit17/>} size={SizeType.SMALL}/>,
                disabled: !(!isMyLibraryFileTypeContext(this.props.fileType) && isEditableProject(this.props.fileType, this.props.project) ||
                    this.accessWriteFolder()),
                click: () => {
                    this.showRenamePopup(selectedItems[0].id, ISelectItemType.directory, selectedItems[0].object as IDirectory);
                },
            },
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.description"}),
                icon: <WedIcon icon={<WedIconPenEditCreate3/>} size={SizeType.SMALL}/>,
                divider: true,
                disabled: !(!isMyLibraryFileTypeContext(this.props.fileType) && isEditableProject(this.props.fileType, this.props.project) ||
                    this.accessWriteFolder()),
                click: () => {
                    this.showChangeDescriptionPopup(selectedItems[0].id, ISelectItemType.directory, selectedItems[0].object as IDirectory);
                },
            },
            {
                label: this.props.intl.formatMessage({id: "app.statusActionButton.moveTo"}),
                keyPrefix: "move-folder",
                icon: <WedIcon icon={<WedIconFolderMoveArrowRight/>} size={SizeType.SMALL}/>,
                loading: this.state.loadingDirId == rootDir,
                disabled: !(!isMyLibraryFileTypeContext(this.props.fileType) && isEditableProject(this.props.fileType, this.props.project) ||
                    this.accessWriteFolder()),
                click: (e: MouseEvent) => {
                    if (!rootDir) {
                        throw new LogicError("Props rootDir is required");
                    }
                    this.moveDirArrowClickHandler(e, rootDir);
                },
            },
            ...this.displayMoveToDirsOptions(),
            {
                label: this.props.intl.formatMessage({id: "app.statusActionButton.downloadFolder"}),
                icon: <WedIcon icon={<WedIconDownloadSaveUpload1/>} size={SizeType.SMALL}/>,
                // eslint-disable-next-line
                disabled:
                    selectedItems[0] &&
                    selectedItems[0].type === ISelectItemType.directory &&
                    (selectedItems[0].object as IDirectory)?.numberOfFiles !== undefined &&
                    (selectedItems[0].object as IDirectory).numberOfFiles === 0,
                click: () => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({id: `app.projectFilesActionButton.download`}), {
                        variant: "success",
                        autoHideDuration: 4000,
                    });
                    downloadFile(container.filesService.getDirectoryDownloadUrl(selectedItems[0].id));
                },
                divider: true,
            },
            ...this.addManagerFolderAccess(),
            {
                label: this.props.intl.formatMessage({id: "app.dropDownMenu.folder.addFolderFilesToCollection"}),
                icon: <WedIcon icon={<WedIconAddCirlcePlus/>} size={SizeType.SMALL}/>,
                loading: this.state.loadingCollections,
                click: async (event: MouseEvent) => {
                    event.stopPropagation();
                    await this.fetchFolderFilesIds();
                    await this.fetchCollections();
                },
                disabled: !isEditableProject(this.props.fileType, this.props.project) || this.getSelectedItemForCollection().length === 0,
                divider: !this.state.collectionForObjectItem,
            },
            ...this.displayCollections(ISelectItemType.directory, this.getSelectedItemForCollection()),
            ...this.addRemoveToTrash(!(
                !isMyLibraryFileTypeContext(this.props.fileType) && isEditableProject(this.props.fileType, this.props.project) ||
                this.accessWriteFolder()
            )),
        ];
    };

    private accessWriteFolder() {
        if (![FileTypeEnum.MY_LIBRARY_FILES, FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME].includes(this.props.fileType)) {
            return false;
        }

        if (this.props.selectedItems.length !== 1) {
            return false;
        }

        if (this.props.selectedItems[0].type !== ISelectItemType.directory) {
            return false;
        }

        const object = this.props.selectedItems[0].object as IDirectory;

        return object.userIsOwner || object.writeAccessIds.includes(this.props.userId || 0);
    }

    private accessWriteFile() {
        if (![FileTypeEnum.MY_LIBRARY_FILES, FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME].includes(this.props.fileType)) {
            return false;
        }

        if (this.props.selectedItems.length !== 1) {
            return false;
        }
        if (!this.props.selectedItems[0].parentFolder) {
            return false;
        }

        return this.props.selectedItems[0].parentFolder.writeAccessIds.includes(this.props.userId || 0);
    }

    render() {
        const {selectedItems, icon, breadcrumbItem} = this.props;

        const directoryItems = this.getDirectoryItems() as IMenuItem[];
        const fileItems = this.getFileItems() as IMenuItem[];

        return (
            <>
                <StatusActionButton
                    breadcrumbItem={breadcrumbItem}
                    selectedItems={selectedItems}
                    menuItems={[
                        {
                            types: [ISelectItemType.directory],
                            multiply: false,
                            items: directoryItems,
                        },
                        {
                            types: [ISelectItemType.file],
                            multiply: false,
                            items: fileItems,
                        },
                        {
                            types: [ISelectItemType.file],
                            multiply: true,
                            items: fileItems,
                        },
                    ]}
                    icon={icon}
                    closeCallback={() => {
                        this.setState({moveToDirMap: [], collectionForObjectItem: undefined});
                    }}
                />
                {this.displayActionPopup()}
            </>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: PropsInterface) => ({
    ...props,
    userId: store.user.data?.id,
});

export default withSnackbar(injectIntl(connect(mapStateToProps)(FilesActionButton)));
