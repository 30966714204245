import Fuse from "fuse.js";

const scoreLimit = 0.2;

export function searchTextFilter<T>(query: string, items: T[], properties: { name: string; weight: number }[]): T[] {
    const fuseSearch = new Fuse(items, {
        keys: properties,
        includeScore: true,
    });
    return fuseSearch
        .search(query)
        .filter((item) => {
            return (item.score as number) < scoreLimit;
        })
        .map((result) => result.item);
}
