import React, { ReactElement } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { SizeType, WedIcon } from "../../../../components/wed-icon/WedIcon";
import { WedIconDownloadSaveUpload1, WedIconEye, WedIconTrashDeleteRemove } from "../../../../components/wed-icon/generated/WedIconSvg";
import { ISelectItem, ISelectItemType, StatusActionButton } from "../../../../components/status-action-button/StatusActionButton";
import { IMenuItem } from "../../../../components/wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import { IFile } from "../../../../services/WedApi/Models/File";
import RemoveFromPortfolioPopup from "./RemoveFromPortfolioPopup/RemoveFromPortfolioPopup";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    icon?: ReactElement;
    onOpenFile: (file: IFile) => void;
    onDownloadFile: (file: IFile) => void;
    selectedItems: ISelectItem[];
    isMe?: boolean;
    refreshPage?: () => void;
}

interface StateInterface {
    removeFilePopup?: { files: Pick<IFile, "id" | "name">[] };
}

class UserFileListActionButtons extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            removeFilePopup: undefined,
        };
    }

    handleClosePopup = (updated?: boolean) => {
        this.setState({ removeFilePopup: undefined });
        if (updated) {
            this.props.refreshPage && this.props.refreshPage();
        }
    };

    getFileItems = () => {
        const { selectedItems } = this.props;

        return [
            {
                label: this.props.intl.formatMessage({ id: "app.dropDownMenu.folder.preview" }),
                icon: <WedIcon icon={<WedIconEye />} size={SizeType.SMALL} />,
                divider: true,
                disabled: selectedItems.length !== 1,
                click: () => {
                    this.props.onOpenFile(selectedItems[0].object as IFile);
                },
            },
            {
                label: this.props.intl.formatMessage({ id: "app.dropDownMenu.folder.download" }),
                icon: <WedIcon icon={<WedIconDownloadSaveUpload1 />} size={SizeType.SMALL} />,
                disabled: selectedItems.length !== 1,
                divider: !!this.props.isMe,
                click: () => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: `app.UserFileListActionButtons.download` }), {
                        variant: "success",
                        autoHideDuration: 4000,
                    });
                    this.props.onDownloadFile(selectedItems[0].object as IFile);
                },
            },
            ...(this.props.isMe
                ? [
                      {
                          label: this.props.intl.formatMessage({ id: "app.UserFileListActionButtons.removeFromPrivatePortfolio" }),
                          icon: <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />,
                          click: () => {
                              this.setState({
                                  removeFilePopup: {
                                      files: [
                                          {
                                              id: selectedItems[0].id,
                                              name: selectedItems[0].object?.name || "",
                                          },
                                      ],
                                  },
                              });
                          },
                      },
                  ]
                : []),
        ].filter((item) => item !== undefined);
    };

    render() {
        const { selectedItems, icon } = this.props;

        const fileItems = this.getFileItems() as IMenuItem[];

        return (
            <>
                {this.state.removeFilePopup && (
                    <RemoveFromPortfolioPopup handleClose={this.handleClosePopup} files={this.state.removeFilePopup.files} />
                )}
                <StatusActionButton
                    selectedItems={selectedItems}
                    menuItems={[
                        {
                            types: [ISelectItemType.file],
                            multiply: false,
                            items: fileItems,
                        },
                    ]}
                    icon={icon}
                />
            </>
        );
    }
}

export default withSnackbar(injectIntl(UserFileListActionButtons));
