import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { parse } from "query-string";
import ProjectListPage from "./pages/project/ProjectListPage/ProjectListPage";
import Layout404 from "./components/layout/Layout404/Layout404";
import RoutesEnum from "./services/Routes/RoutesEnum";
import ProjectDetailLoader from "./components/project/ProjectDetailLoader/ProjectDetailLoader";
import MyLibraryDetailLoader from "./components/my-library/MyLibraryDetailLoader/MyLibraryDetailLoader";
import ClientListPage from "./pages/client/ClientListPage/ClientListPage";
import ClientDetailLoader from "./components/client/ClientDetailLoader/ClientDetailLoader";
import Redirect404 from "./components/error/Redirect404/Redirect404";
import LayoutError from "./components/layout/Layout500/Layout500";
import UserTeamListPage from "./pages/user-team/UserTeamListPage/UserTeamListPage";
import UserTeamDetailLoader from "./components/user-team/UserTeamDetailLoader/UserTeamDetailLoader";
import ShareCollectionListPage from "./pages/share/ShareCollectionListPage/ShareCollectionListPage";
import ShareCollectionDetailPage from "./pages/share/ShareCollectionDetailPage/ShareCollectionDetailPage";
import ShareCollectionFilePage from "./pages/share/ShareCollectionFilePage/ShareCollectionFilePage";
import LoginPage from "./pages/not-logged-pages/login-page/LoginPage";
import RegisterPage from "./pages/not-logged-pages/register-page/RegisterPage";
import ForgotPasswordPage from "./pages/not-logged-pages/forgot-password-page/ForgotPasswordPage";
import ActivateUserPage from "./pages/not-logged-pages/activate-user-page/ActivateUserPage";
import ResetPasswordPage from "./pages/not-logged-pages/reset-password-page/ResetPasswordPage";
import CompanyDataPage from "./pages/not-logged-pages/company-data-page/CompanyDataPage";
import AdminPanel from "./pages/admin-panel/AdminPanel/AdminPanel";
import CompanyReactivatePage from "./pages/not-logged-pages/company-reactivate-page/CompanyReactivatePage";
import SignupInvitationPage from "./pages/not-logged-pages/signup-invitation/SignupInvitationPage";

const Routes: React.FC = () => (
    <Switch>
        <Route exact path={RoutesEnum.LOGIN} component={LoginPage} />
        <Route exact path={RoutesEnum.REGISTER} component={RegisterPage} />
        <Route exact path={RoutesEnum.FORGOT_PASSWORD} component={ForgotPasswordPage} />
        <Route exact path={RoutesEnum.ACTIVATE_USER} component={ActivateUserPage} />
        <Route exact path={RoutesEnum.RESET_PASSWORD} component={ResetPasswordPage} />
        <Route exact path={RoutesEnum.COMPANY_DATA} component={CompanyDataPage} />
        <Route exact path={RoutesEnum.COMPANY_REACTIVATE} component={CompanyReactivatePage} />
        <Route exact path={RoutesEnum.SIGNUP_INVITATION} component={SignupInvitationPage} />
        <Route
            exact
            path="/"
            component={() => {
                return <Redirect to={RoutesEnum.PROJECTS} />;
            }}
        />
        <Route
            exact
            path={RoutesEnum.SHARE_COLLECTIONS}
            component={(route: RouteComponentProps) => {
                const queryParams = route.location.search ? parse(route.location.search) : {};
                return <ShareCollectionListPage token={queryParams["token"] as string} />;
            }}
        />
        <Route
            exact
            path={RoutesEnum.SHARE_COLLECTION_FILES}
            component={(route: RouteComponentProps<{ collectionId: string }>) => {
                const queryParams = route.location.search ? parse(route.location.search) : {};
                return <ShareCollectionDetailPage token={queryParams["token"] as string} collectionId={parseInt(route.match.params.collectionId)} />;
            }}
        />
        <Route
            exact
            path={RoutesEnum.SHARE_COLLECTION_FILE_MEDIA_GALLERY_DETAILS}
            component={(route: RouteComponentProps<{ collectionId: string; fileId: string }>) => {
                const queryParams = route.location.search ? parse(route.location.search) : {};
                return (
                    <ShareCollectionFilePage
                        token={queryParams["token"] as string}
                        collectionId={parseInt(route.match.params.collectionId)}
                        fileId={parseInt(route.match.params.fileId)}
                    />
                );
            }}
        />
        <Route exact path={RoutesEnum.PROJECTS} component={ProjectListPage} />
        <Route
            path={RoutesEnum.PROJECTS_DETAIL}
            component={(route: RouteComponentProps<{ projectId: string }>) => {
                return <ProjectDetailLoader projectId={parseInt(route.match.params.projectId)} />;
            }}
        />
        <Route path={RoutesEnum.MY_LIBRARY} component={MyLibraryDetailLoader} />
        <Route exact path={RoutesEnum.CLIENTS} component={ClientListPage} />
        <Route
            path={RoutesEnum.CLIENTS_DETAIL}
            component={(route: RouteComponentProps<{ clientId: string }>) => {
                return <ClientDetailLoader clientId={parseInt(route.match.params.clientId)} />;
            }}
        />
        <Route exact path={RoutesEnum.USER_TEAM} component={UserTeamListPage} />
        <Route
            path={RoutesEnum.USER_TEAM_DETAILS}
            component={(route: RouteComponentProps<{ teamId: string }>) => {
                return <UserTeamDetailLoader userTeamId={parseInt(route.match.params.teamId)} />;
            }}
        />
        <Route
            exact
            path={RoutesEnum.ADMIN_PANEL}
            component={() => {
                return <Redirect to={RoutesEnum.ADMIN_PANEL_MANAGE_USERS_CURRENT_USERS} />;
            }}
        />
        <Route path={RoutesEnum.ADMIN_PANEL} component={AdminPanel} />
        <Route exact path={RoutesEnum.ERROR_INTERNAL} component={LayoutError} />
        <Route exact path={RoutesEnum.ERROR_PAGE_NOT_FOUND} component={Layout404} />
        <Route component={Redirect404} />
    </Switch>
);

export default Routes;
