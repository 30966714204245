import { IOwner } from "./User";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";

export interface NoteObject {
    id: number;
    type: NoteObjectType;
    data?: {
        parentNoteId?: number;
        fileVersionId?: number;
        collectionId?: number;
        clientId?: number;
    };
}

export enum NoteObjectType {
    project = "project",
    file = "file",
    collection = "collection",
}

export interface IApiNote {
    id: number;
    baAuthorID: number;
    baUserID: number;
    ecAuthorID: number;
    ecContactID: number;
    content: string;
    createdAt: string;
    updatedAt: string;
    flag?: number;
    objectParentID?: number;
    oid?: number;
    vid?: number;
    pid?: number;
    tid?: number;
    cid?: number;
    x0?: number;
    y0?: number;
    width?: number;
    height?: number;
    annotation?: boolean;
}

export interface INoteAnnotation {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface INote {
    id: number;
    parentNodeId?: number;
    created?: Date;
    createdBy?: IOwner;
    ecContact?: IOwner;
    content: string;
    items: INote[];
    annotation?: INoteAnnotation;
    versionId?: number;
}

export enum ICommentNoteType {
    PROJECT_PRIVATE_NOTE = "projectPrivateNote",
    OBJECT_PRIVATE_NOTE = "objectPrivateNote",
    COLLECTION_NOTE = "collectionNote",
    OBJECT_PUBLIC_NOTE = "objectPublicNote",
    OBJECT_PROOF = "objectProof",
}

export interface IApiCommentNote {
    Type: ICommentNoteType;
    Value: IApiNote;
}

export interface INoteFile {
    id: number;
    dirId: number;
    newestVersionId: number;
    name: string;
    extension: string;
    versionNumber: number;
}

export interface ICommentNoteItem extends Omit<IApiNote, "createdAt"> {
    created?: Date;
    createdBy?: IOwner;
    userType?: InitialsUserType;
    file?: INoteFile;
    approvedStatus?: boolean;
    project?: {
        id: number;
        name: string;
    };
    collection?: {
        id: number;
        name: string;
    };
}

export interface ICommentNote {
    type: ICommentNoteType;
    item: ICommentNoteItem;
}
