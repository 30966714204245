import React from "react";
import classNames from "classnames";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import "./UserBaseProfilePopup.scss";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconSingleUserSquare } from "../../wed-icon/generated/WedIconSvg";

interface BasePropsInterface {
    close: () => void;
    open: boolean;
    doIt: () => Promise<void | boolean>;
    className?: string;
    header: string | React.ReactElement;
    confirmButtonLabel?: string | React.ReactElement;
    content?: string | React.ReactElement;
}

interface PropsInterface extends BasePropsInterface {}

interface StateInterface {
    fields: {
        firstName: string;
        lastName: string;
        countryCode: number;
        number: string;
    };
}

class UserBaseProfilePopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <PopupConfirmButton
                maxWidth="lg"
                open={this.props.open}
                close={this.props.close}
                className={classNames("user-base-profile-popup", this.props.className)}
                doIt={this.props.doIt}
                confirmButtonLabel={this.props.confirmButtonLabel}
                noDisplayCancel={true}
                header={this.props.header}
                disableDialogWrapper={true}
                content={
                    <div className={"user-base-profile-popup--container"}>
                        <div className={"user-base-profile-popup--content"}>{this.props.content}</div>
                        <div className={"user-base-profile-popup--icon"}>
                            <WedIcon icon={<WedIconSingleUserSquare />} />
                        </div>
                    </div>
                }
            />
        );
    }
}

export default UserBaseProfilePopup;
