import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import Collection from "../../../../domain/Collection";
import container from "../../../../container";

interface StateInterface {
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: (isUpdate?: boolean) => void;
    collection: Collection;
}

class LockUnlockCollection extends React.Component<PropsInterface, StateInterface> {
    readonly state = {
        loading: false,
    };

    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    lockUnlockCollection = async () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });

        try {
            await container.collectionActionApiService.lockCollection(this.props.collection.getId(), !this.props.collection.isLocked());

            if (this.props.collection.isLocked()) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.collection.unlock.success" }), { variant: "success" });
            } else {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.collection.lock.success" }), { variant: "success" });
            }
            this.props.close(true);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            this.setState({ loading: false });
        }
    };

    private closePopup() {
        this.props.close();
    }

    render() {
        return (
            <div className="lock-unlock-collection">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    {this.props.collection.isLocked() ? (
                        <FormattedMessage id="app.collection.unlock.title" />
                    ) : (
                        <FormattedMessage id="app.collection.lock.title" />
                    )}
                </PopupHeader>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <br />
                        {this.props.collection.isLocked() ? (
                            <FormattedMessage id={"app.collection.unlock.description"} values={{ collectionName: this.props.collection.getName() }} />
                        ) : (
                            <FormattedMessage id={"app.collection.lock.description"} values={{ collectionName: this.props.collection.getName() }} />
                        )}
                    </DialogContentText>
                </DialogContent>
                <PopupAction>
                    <Button type="submit" onClick={this.lockUnlockCollection} className="button--more-padding" disabled={this.state.loading}>
                        {this.props.collection.isLocked() ? (
                            <FormattedMessage id="app.collection.unlock" />
                        ) : (
                            <FormattedMessage id="app.collection.lock" />
                        )}
                    </Button>
                    <Button type="submit" onClick={this.closePopup} className="button--more-padding">
                        <FormattedMessage id="app.standard.popup.button.cancel" />
                    </Button>
                </PopupAction>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(LockUnlockCollection));
