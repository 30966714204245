import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import { History } from "history";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiPublicNote, IApiShareCollection, IShareCollection } from "./Models/ShareCollection";
import { INote, INoteAnnotation } from "./Models/Note";
import { CollectionStatusEnum } from "./Models/Collection";
import { convertStrToDate } from "./Utils/DateConverter";
import { listToTreeNotes } from "./Services/BaseConverter";
import { IListResult } from "./Models/Common";
import { IApiCollectionActivities } from "./Models/Activities";

export default class ShareCollectionActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getCollectionDetails(token: string, id: number): Promise<IShareCollection> {
        const response = await this.httpClient.get(`/xapi/share/col?id=${id}&token=${token}`);

        return this.convertShareCollectionApi(response.data);
    }

    public async getCollectionList(token: string): Promise<IShareCollection[]> {
        const response: { data: { items: IApiShareCollection[] } } = await this.httpClient.get(`/xapi/share/index?token=${token}`);

        const collections = [];
        for (const collection of response.data.items) {
            collections.push(this.convertShareCollectionApi(collection));
        }

        return collections;
    }

    public async makeDecision(token: string, cid: number, oid: number, vid: number, approved: boolean) {
        await this.postWithCSRFJson("/xapi/share/proof", {
            token,
            cid,
            oid,
            vid,
            flag: approved ? 2 : 3,
        });
    }

    public getDownloadCollectionUrl(token: string, collectionId: number): string {
        return this.getUrl(`/xapi/share/col/download?id=${collectionId}&token=${token}`);
    }

    public async addNote(
        token: string,
        cid: number,
        oid: number,
        vid: number,
        tid: number,
        content: string,
        options?: { annotation?: INoteAnnotation }
    ): Promise<void> {
        await this.postWithCSRFJson("/xapi/share/col/addnote", {
            token,
            cid,
            oid,
            vid,
            content,
            ...(tid ? { tid } : {}),
            ...(options?.annotation
                ? {
                      annotation: true,
                      x0: options.annotation.x,
                      y0: options.annotation.y,
                      width: options.annotation.width,
                      height: options.annotation.height,
                  }
                : {
                      annotation: false,
                  }),
        });
    }

    public async getCollectionActivities(token: string, collectionId: number): Promise<IListResult<IApiCollectionActivities>> {
        return (await this.httpClient.get(`/xapi/share/col/activities?id=${collectionId}&token=${token}`)).data || [];
    }

    public async getCollectionFileActivities(token: string, objectId: number, collectionId: number): Promise<IListResult<IApiCollectionActivities>> {
        return (await this.httpClient.get(`/xapi/share/obj/activities?oid=${objectId}&cid=${collectionId}&token=${token}`)).data || [];
    }

    private convertListNote(publicNotes: IApiPublicNote[]): INote[] {
        return listToTreeNotes(
            publicNotes.map((publicNote) => {
                return {
                    id: publicNote.id,
                    items: [],
                    ecContact:
                        publicNote.ecAuthor && (publicNote.ecAuthor.firstName || publicNote.ecAuthor.lastName)
                            ? {
                                  id: 0,
                                  email: publicNote.ecAuthor.email,
                                  firstName: publicNote.ecAuthor.firstName,
                                  lastName: publicNote.ecAuthor.lastName,
                              }
                            : undefined,
                    createdBy:
                        publicNote.baAuthor && (publicNote.baAuthor.firstName || publicNote.baAuthor.lastName)
                            ? {
                                  id: 0,
                                  email: publicNote.baAuthor.email,
                                  firstName: publicNote.baAuthor.firstName,
                                  lastName: publicNote.baAuthor.lastName,
                              }
                            : undefined,
                    created: convertStrToDate(publicNote.createdAt),
                    parentNodeId: publicNote.tid || undefined,
                    versionId: publicNote.vid || undefined,
                    annotation:
                        publicNote.annotation &&
                        publicNote.x0 !== undefined &&
                        publicNote.y0 !== undefined &&
                        publicNote.width !== undefined &&
                        publicNote.height !== undefined
                            ? {
                                  x: publicNote.x0,
                                  y: publicNote.y0,
                                  width: publicNote.width,
                                  height: publicNote.height,
                              }
                            : undefined,
                    content: publicNote.content,
                };
            })
        );
    }

    private convertShareCollectionApi(data: IApiShareCollection): IShareCollection {
        const {
            collection: {
                id,
                updatedBy,
                updatedAt,
                sharedTo,
                locked,
                approvalRequired,
                approvalFlag,
                baUsers,
                createdAt,
                createdBy,
                name,
                description,
            },
            userEmail,
            companyName,
            objects,
            publicNotes,
        } = data;

        return {
            userEmail,
            companyName,
            id,
            name,
            description,
            baUsers,
            sharedTo,
            locked,
            approvalRequired,
            approvalFlag,
            created: convertStrToDate(createdAt),
            createdBy: createdBy,
            updated: updatedAt && updatedAt.length ? new Date(updatedAt) : undefined,
            updatedBy: updatedBy?.email ? updatedBy : undefined,
            userIsOwner: false,
            baUsersItems: baUsers,
            sharedToItems: sharedTo,
            status: locked ? CollectionStatusEnum.locked : CollectionStatusEnum.unlocked,
            publicNotes: this.convertListNote(publicNotes),
            files: objects.map((object) => {
                const {
                    id,
                    name,
                    description,
                    versions,
                    size,
                    type,
                    newestVersionID,
                    approvalFlag,
                    approvalRequired,
                    createdAt,
                    createdBy,
                    updatedAt,
                    updatedBy,
                    publicNotes,
                    ownerIDs,
                } = object;

                return {
                    id,
                    name,
                    description,
                    approvalFlag,
                    approvalRequired,
                    size,
                    createdBy,
                    updatedBy,
                    versions: versions.map((version) => {
                        const { id, createdAt, type, proofs, approvalFlag } = version;
                        return {
                            id,
                            name: version.VersionNumber.toString(),
                            versionNumber: version.VersionNumber,
                            ...type,
                            created: createdAt ? new Date(createdAt) : undefined,
                            approvalFlag: approvalFlag === undefined ? undefined : approvalFlag,
                            proofs: proofs
                                ? proofs
                                      .filter((proof) => !!proof.ecContact?.email)
                                      .map((proof) => ({
                                          firstName: proof.ecContact.firstName,
                                          lastName: proof.ecContact.lastName,
                                          email: proof.ecContact.email,
                                          created: convertStrToDate(proof.updatedAt),
                                          flag: proof.flag,
                                      }))
                                : [],
                        };
                    }),
                    userIsOwner: false,
                    owners: [],
                    tags: [],
                    newestVersionId: newestVersionID,
                    extension: type.extension,
                    removed: undefined,
                    removedBy: undefined,
                    created: createdAt ? new Date(createdAt) : undefined,
                    updated: updatedAt ? new Date(updatedAt) : undefined,
                    publicNotes: this.convertListNote(publicNotes),
                    ownerIDs,
                };
            }),
        };
    }
}
