import React from "react";
import { ProjectMenu } from "../../../main-menu/ProjectMenu/ProjectMenu";
import { FileTypeEnum } from "../../Type/FileTypeEnum";
import { MyLibraryMenu } from "../../../main-menu/MyLibraryMenu/MyLibraryMenu";
import { LogicError } from "../../../../errors/error-app";
import UserTeamMenu from "../../../main-menu/UserTeamMenu/UserTeamMenu";

export default class SubmenuFactory {
    static getSubmenu(type: FileTypeEnum, objectId?: number) {
        switch (type) {
            case FileTypeEnum.PROJECT_FILES: {
                return <ProjectMenu projectId={objectId as number} activeTab={"files"} />;
            }
            case FileTypeEnum.PROJECT_COLLECTION: {
                return <ProjectMenu projectId={objectId as number} activeTab="collections" />;
            }
            case FileTypeEnum.PROJECT_COLLECTION_FILES: {
                return <ProjectMenu projectId={objectId as number} activeTab={"collections"} />;
            }
            case FileTypeEnum.MY_LIBRARY_FILES: {
                return <MyLibraryMenu activeTab={"files"} />;
            }
            case FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME: {
                return <MyLibraryMenu activeTab={"sharedWithMe"} />;
            }
            case FileTypeEnum.MY_LIBRARY_COLLECTIONS:
            case FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES: {
                return <MyLibraryMenu activeTab={"collections"} />;
            }
            case FileTypeEnum.TEAM_USER_PROJECT_WORK: {
                return <UserTeamMenu userTeamId={objectId as number} activeTab="user-profile" />;
            }
            case FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO: {
                return <UserTeamMenu userTeamId={objectId as number} activeTab="user-profile" />;
            }
            default: {
                throw new LogicError(`Not implemented submenu for type: ${type}`);
            }
        }
    }
}
