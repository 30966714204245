import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { WedMenuButtons } from "../../../../main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../wed-icon/WedIcon";
import { WedIconAddCirlcePlus, WedIconPlusAdd, WedIconShare2, WedIconTrashDeleteRemove } from "../../../../wed-icon/generated/WedIconSvg";
import { ISelectItem } from "../../../../status-action-button/StatusActionButton";
import Collection from "../../../../../domain/Collection";
import { ICollection } from "../../../../../services/WedApi/Models/Collection";
import { IProject } from "../../../../../services/WedApi/Models/Project";
import { isEditableProject } from "../../../../../services/WedApi/Utils/IsEditableProject";
import { FileTypeEnum } from "../../../Type/FileTypeEnum";
import CollectionActionButton from "../CollectionActionButton/CollectionActionButton";

interface PropsInterface {
    project?: IProject;
    createCollection?: () => void;
    deleteCollection: (collection: Collection) => void;
    selectedItems: ISelectItem[];
    collection: Collection | undefined;
    onChange: () => void;
    goToCollectionFiles: (collectionId: number) => void;
    openSharing: (collection: Collection) => void;
    openAddFileToCollection: (collectionId: number) => void;
    fileType: FileTypeEnum;
}

interface StateInterface {}

export class CollectionPageButtons extends React.Component<PropsInterface, StateInterface> {
    checkEnabledButton() {
        if (this.props.selectedItems && this.props.selectedItems.length) {
            const firstSelectedItems = this.props.selectedItems[0];
            return firstSelectedItems.userIsOwner;
        }
        return false;
    }

    checkIsLocked = () => {
        if (this.props.selectedItems && this.props.selectedItems.length) {
            const firstSelectedItems = this.props.selectedItems[0];
            const object: ICollection | undefined = firstSelectedItems.object as ICollection;
            if (object) {
                return object.locked;
            }
        }

        return false;
    };

    render() {
        return (
            <WedMenuButtons>
                <Button
                    disabled={!isEditableProject(this.props.fileType, this.props.project)}
                    onClick={this.props.createCollection}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}
                >
                    <FormattedMessage id="app.projectDetailButtons.createCollection" />
                </Button>
                <Button
                    onClick={() => {
                        this.props.collection && this.props.openSharing(this.props.collection);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare2 />} />}
                    disabled={!isEditableProject(this.props.fileType, this.props.project) || !this.checkEnabledButton() || this.checkIsLocked()}
                >
                    <FormattedMessage id="app.projectDetailButtons.sharing" />
                </Button>
                <Button
                    onClick={() => {
                        this.props.collection && this.props.openAddFileToCollection(this.props.collection.getId());
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconAddCirlcePlus />} />}
                    disabled={!isEditableProject(this.props.fileType, this.props.project) || !this.checkEnabledButton() || this.checkIsLocked()}
                >
                    <FormattedMessage id="app.projectDetailButtons.addFiles" />
                </Button>
                <Button
                    onClick={() => {
                        this.props.collection && this.props.deleteCollection(this.props.collection);
                    }}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                    disabled={!isEditableProject(this.props.fileType, this.props.project) || !this.checkEnabledButton()}
                >
                    <FormattedMessage id="app.projectDetailButtons.deleteCollection" />
                </Button>
                <CollectionActionButton
                    project={this.props.project}
                    selectedItems={this.props.selectedItems}
                    collection={this.props.collection}
                    onChange={this.props.onChange}
                    goToCollectionFiles={this.props.goToCollectionFiles}
                    openSharing={this.props.openSharing}
                    deleteCollection={this.props.deleteCollection}
                    openAddFileToCollection={this.props.openAddFileToCollection}
                    fileType={this.props.fileType}
                />
            </WedMenuButtons>
        );
    }
}
