import React from "react";
import "./WedMediaGalleryImageAnnotation.scss";
import { AppError } from "../../../errors/error-app";
import { INoteAnnotation } from "../../../services/WedApi/Models/Note";
import WedMediaGalleryImageAnnotationPopup from "./WedMediaGalleryImageAnnotationPopup";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import classNames from "classnames";

interface PropsInterface {
    orgWidth: number;
    orgHeight: number;
    width: number;
    height: number;
    containerId: string;
    handleClose: (updated?: boolean) => void;
    fileType: FileTypeEnum;
    objectId: number;
    versionId: number;
    collectionId?: number;
    token?: string;
}

interface StateInterface {
    popup: boolean;
}

class WedMediaGalleryImageAnnotation extends React.Component<PropsInterface, StateInterface> {
    private noteAnnotation?: INoteAnnotation;
    private startX: number = 0;
    private startY: number = 0;
    private width: number = 0;
    private height: number = 0;

    constructor(props: PropsInterface) {
        super(props);
        this.state = { popup: false };
        this.startDrag = this.startDrag.bind(this);
        this.stopDrag = this.stopDrag.bind(this);
        this.moveDrag = this.moveDrag.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.getSvg().addEventListener("pointerdown", this.startDrag);
    }

    componentWillUnmount() {
        this.getSvg().removeEventListener("pointerdown", this.startDrag);
    }

    private handleClose(updated?: boolean) {
        this.noteAnnotation = undefined;
        this.startX = 0;
        this.startY = 0;
        this.width = 0;
        this.height = 0;
        this.setState({ popup: false });
        this.props.handleClose(updated);
        this.getSvg().innerHTML = "";
    }

    // eslint-disable-next-line
    private startDrag(ev: any) {
        if (this.state.popup) {
            return;
        }
        if (ev.button === 1) {
            return;
        }
        const screenshot = document.getElementById(`${this.props.containerId}--rect`) as HTMLDivElement;
        this.startX = ev.layerX;
        this.startY = ev.layerY;
        window.addEventListener("pointerup", this.stopDrag);
        screenshot.addEventListener("pointermove", this.moveDrag);
    }

    private stopDrag() {
        if (this.state.popup) {
            return;
        }
        const screenshot = document.getElementById(`${this.props.containerId}--rect`) as HTMLDivElement;
        window.removeEventListener("pointerup", this.stopDrag);
        screenshot.removeEventListener("pointermove", this.moveDrag);
        const realData = this.getRealData({
            x: this.startX,
            y: this.startY,
            width: this.width,
            height: this.height,
        });
        if (realData.width > 0 && realData.height > 0) {
            this.noteAnnotation = realData;
            this.drawRect(this.noteAnnotation);
            this.setState({ popup: true });
        } else {
            this.removeRect();
        }
    }

    // eslint-disable-next-line
    private moveDrag(ev: any) {
        if (this.state.popup) {
            return;
        }
        const x = ev.layerX;
        const y = ev.layerY;
        this.width = x - this.startX;
        this.height = y - this.startY;
        this.drawRect({
            x: this.startX,
            y: this.startY,
            width: this.width,
            height: this.height,
        });
    }

    private getRealData(data: INoteAnnotation): INoteAnnotation {
        let x = data.x;
        let y = data.y;
        let width = data.width;
        let height = data.height;
        if (width < 0) {
            width *= -1;
            x -= width;
        }
        if (height < 0) {
            height *= -1;
            y -= height;
        }

        return {
            x,
            y,
            width,
            height,
        };
    }

    private drawRect(data: INoteAnnotation) {
        const { x, y, width, height } = this.getRealData(data);

        this.getSvg().innerHTML = `<rect width="${width}" height="${height}" x="${x}" y="${y}"></rect>`;
    }

    private removeRect() {
        const rect = document.getElementById(`${this.props.containerId}--rect-box`);
        if (rect && rect.parentNode) {
            rect.parentNode.removeChild(rect);
        }
    }

    private getSvg(): SVGElement {
        // eslint-disable-next-line
        const image = document.getElementById(`${this.props.containerId}--rect`) as any as SVGElement;
        if (image) {
            return image;
        }
        throw new AppError(`Cannot get annotation image "${this.props.containerId}--rect"`);
    }

    private recalculateAnnotation(annotation: INoteAnnotation): INoteAnnotation {
        let x = Math.floor((this.props.orgWidth * annotation.x) / this.props.width);
        let y = Math.floor((this.props.orgHeight * annotation.y) / this.props.height);
        let width = Math.floor((this.props.orgWidth * annotation.width) / this.props.width);
        let height = Math.floor((this.props.orgHeight * annotation.height) / this.props.height);
        if (x >= this.props.orgWidth) {
            x = this.props.orgWidth - 1;
        }
        if (x < 0) {
            x = 0;
        }
        if (y >= this.props.orgHeight) {
            y = this.props.orgHeight - 1;
        }
        if (y < 0) {
            y = 0;
        }
        if (width <= 0) {
            width = 1;
        }
        if (x + width > this.props.orgWidth) {
            width = this.props.orgWidth - x;
        }
        if (height <= 0) {
            height = 1;
        }
        if (y + height > this.props.orgHeight) {
            height = this.props.orgHeight - y;
        }

        return { x, y, width, height };
    }

    render() {
        return (
            <div
                className={classNames("wed-media-gallery-image-annotation", { "wed-media-gallery-image-annotation--draw-mode": !this.state.popup })}
                id={this.props.containerId}
            >
                {this.noteAnnotation && this.state.popup && (
                    <WedMediaGalleryImageAnnotationPopup
                        handleClose={this.handleClose}
                        annotation={this.recalculateAnnotation(this.noteAnnotation)}
                        fileType={this.props.fileType}
                        objectId={this.props.objectId}
                        versionId={this.props.versionId}
                        collectionId={this.props.collectionId}
                        token={this.props.token}
                    />
                )}
                <div className="wed-media-gallery-image-annotation--image-container" id={`${this.props.containerId}--image`}></div>
                <svg
                    style={{ width: `${this.props.width}px`, height: `${this.props.height}px` }}
                    id={`${this.props.containerId}--rect`}
                    width={this.props.width}
                    height={this.props.height}
                    viewBox={`0 0 ${this.props.width} ${this.props.height}`}
                    xmlns="http://www.w3.org/2000/svg"
                ></svg>
            </div>
        );
    }
}

export default WedMediaGalleryImageAnnotation;
