import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IAccountTeamUser, ITeamUser, ITeamUserProjectRole, IUser } from "../../../services/WedApi/Models/User";
import isEqual from "lodash/isEqual";
import container from "../../../container";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import UserEditTeamMembersPopup from "../UserEditTeamMembersPopup/UserEditTeamMembersPopup";

interface BasePropsInterface extends WrappedComponentProps, ProviderContext {
    projectId: number;
    close: (changed?: boolean) => void;
    userMember?: ITeamUser[];
    clientUserMembers: IUser[];
    customerContacts?: IUser[];
    clientCustomerContacts: IUser[];
}

interface PropsInterface extends BasePropsInterface {
    userId?: number;
}

interface StateInterface {
    userMember: ITeamUser[];
    customerContacts: IUser[];
}

class UserProjectEditTeamMembersPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userMember: props.userMember || [],
            customerContacts: props.customerContacts || [],
        };
        this.doIt = this.doIt.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (
            !isEqual(this.props.userMember, prevProps.userMember) ||
            !isEqual(this.props.customerContacts, prevProps.customerContacts) ||
            !isEqual(this.props.clientUserMembers, prevProps.clientUserMembers) ||
            !isEqual(this.props.clientCustomerContacts, prevProps.clientCustomerContacts)
        ) {
            this.setState({
                userMember: this.props.userMember || [],
                customerContacts: this.props.customerContacts || [],
            });
        }
    }

    private async doIt(userMember: ITeamUser[], customerContacts: IUser[]): Promise<void> {
        await container.projectService.setTeamUsersRole(this.props.projectId, userMember, customerContacts);
    }

    render() {
        return (
            <UserEditTeamMembersPopup
                close={this.props.close}
                userMember={this.props.userMember}
                clientUserMembers={this.props.clientUserMembers}
                customerContacts={this.props.customerContacts}
                clientCustomerContacts={this.props.clientCustomerContacts}
                addDefaultTeamRole={ITeamUserProjectRole.ROLE_MEMBER}
                teamType={"PROJECT"}
                doIt={this.doIt as (userMember: Array<ITeamUser | IAccountTeamUser>, customerContacts: IUser[]) => Promise<void>}
                labels={{
                    header: <FormattedMessage id={"app.user.UserProjectEditTeamMembersPopup.header.title"} />,
                    addUser: <FormattedMessage id={"app.user.UserProjectEditTeamMembersPopup.buttons.addUser"} />,
                    addContacts: <FormattedMessage id={"app.user.UserProjectEditTeamMembersPopup.buttons.addContacts"} />,
                }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id,
    };
};

export default withSnackbar(injectIntl(connect(mapStateToProps)(UserProjectEditTeamMembersPopup)));
