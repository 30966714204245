import React from "react";
import { FilterFormBox } from "../FilterFormBox/FilterFormBox";
import FormControl from "@material-ui/core/FormControl";
import { FilterFormBoxSearchText } from "../FilterFormBoxSearchText/FilterFormBoxSearchText";
import FilesFilterInterface from "../../../services/Filter/Files/FilesFilterInterface";

interface PropsInterface {
    onFiltersChange: (filters: FilesFilterInterface) => void;
}

export class FilterFormShareCollection extends React.Component<PropsInterface> {
    private readonly filters: FilesFilterInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.filters = {};
    }

    changeFilterSearchText = (value: string) => {
        this.changeFilter("searchText", value);
    };

    changeFilter(name: string, value: string | number) {
        this.filters[name] = value;
        this.props.onFiltersChange(this.filters);
    }

    render() {
        return (
            <FilterFormBox className="filter-form-share-collection">
                <FormControl variant="outlined">
                    <FilterFormBoxSearchText onChange={this.changeFilterSearchText} />
                </FormControl>
            </FilterFormBox>
        );
    }
}
