import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";
import AbstractRestApiConstructorInterface from "./AbstractRestApiOptionsInterface";
import { stringify } from "query-string";

abstract class AbstractRestApi<T> {
    protected httpClient: AxiosInstance;
    private baseURL: string;

    protected constructor(options: AbstractRestApiConstructorInterface) {
        const { host } = options;
        const { defaults = {} } = options;

        this.baseURL = host.replace(/[/]+$/, "");

        this.httpClient = axios.create({
            baseURL: host,
        });
        this.httpClient.defaults = defaults;
    }

    addInterceptors = (
        responseErrorTransformer?: (error: AxiosError) => void,
        requestTransformer?: (req: AxiosRequestConfig) => Promise<AxiosRequestConfig> | AxiosRequestConfig
    ) => {
        if (responseErrorTransformer) {
            this.httpClient.interceptors.response.use(
                (response) => response,
                (error) => responseErrorTransformer(error)
            );
        }

        if (requestTransformer) {
            this.httpClient.interceptors.request.use(requestTransformer);
        }
    };

    protected getUrl(path: string): string {
        return `${this.baseURL}/${path.replace(/^[/]+/, "")}`;
    }

    protected async get(path: string, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.get(path, _.merge({ withCredentials: true }, options));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async post(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.post(path, body, _.merge({ withCredentials: true }, options));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async postWithCSRFJson(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        const response = await this.get("/xapi/csrf", _.merge({ withCredentials: true }));
        const CSRFToken = response.headers["x-csrf-token"];

        if (!CSRFToken) {
            throw Error("Header x-csrf-token is missing.");
        }

        return this.post(
            path,
            JSON.stringify(body),
            _.merge(
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": CSRFToken,
                        "content-type": "application/json",
                    },
                },
                options
            )
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async postWithCSRFXWwwForm(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        const response = await this.get("/xapi/csrf", _.merge({ withCredentials: true }));
        const CSRFToken = response.headers["x-csrf-token"];

        if (!CSRFToken) {
            throw Error("Header x-csrf-token is missing.");
        }

        return this.post(
            path,
            stringify(body),
            _.merge(
                {
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": CSRFToken,
                        "content-type": "application/x-www-form-urlencoded",
                    },
                },
                options
            )
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async put(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.put(path, body, options);
    }

    protected async delete(path: string, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.delete(path, options);
    }
}

export default AbstractRestApi;
