import React, { ReactElement } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { SizeType, WedIcon } from "../../../../wed-icon/WedIcon";
import {
    WedIconBackRefreshReloadLoadingCheckmark,
    WedIconBackwardRearwardBackRemove,
    WedIconChatMessagesBubbleCheckMark,
    WedIconFolderGroup11,
    WedIconFolderOpen,
    WedIconItemsGroupElements,
    WedIconMailboxInboxArchive,
    WedIconPenEdit17,
    WedIconPenEditCreate3,
    WedIconSignClosed,
    WedIconTrashDeleteRemove,
} from "../../../../wed-icon/generated/WedIconSvg";
import { InjectedProjectActionsProps, withProjectActions } from "../../../../../hoc/withProjectActions";
import { IProject, ProjectStatusChangeEnum, ProjectStatusEnum } from "../../../../../services/WedApi/Models/Project";
import { ISelectItem, StatusActionButton } from "../../../../status-action-button/StatusActionButton";
import { ChangeStatusProjectPopup } from "../../../../project/Project/ChangeStatusProjectPopup/ChangeStatusProjectPopup";
import { IMenuItem } from "../../../../wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import { ChangeDescriptionProjectPopup } from "../../../../project/Project/ChangeDescriptionProjectPopup/ChangeDescriptionProjectPopup";
import { RenameProjectPopup } from "../../../../project/Project/RenameProjectPopup/RenameProjectPopup";
import { generatePath } from "react-router-dom";
import RoutesEnum from "../../../../../services/Routes/RoutesEnum";

interface PropsInterface extends WrappedComponentProps, InjectedProjectActionsProps, ProviderContext {
    selectedProjects: ISelectItem[];
    setIsLoading: () => void;
    actionCallback: () => void;
    archiveCallback?: () => void;
    icon?: ReactElement;
    detailMode?: boolean;
}

interface StateInterface {
    changeStatusProject: {
        openPopup: boolean;
        changeStatusFunction?: () => void;
        status: ProjectStatusChangeEnum | null;
    };
    renameProjectPopup: {
        openPopup: boolean;
    };
    changeDescriptionProjectPopup: {
        openPopup: boolean;
    };
}

class ProjectListActionButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            changeStatusProject: {
                openPopup: false,
                status: null,
            },
            renameProjectPopup: {
                openPopup: false,
            },
            changeDescriptionProjectPopup: {
                openPopup: false,
            },
        };
    }

    actionCallback = () => {
        const { actionCallback } = this.props;

        actionCallback && actionCallback();
    };

    archiveCallback = () => {
        const { actionCallback, archiveCallback } = this.props;

        if (archiveCallback) {
            archiveCallback();
        } else if (actionCallback) {
            actionCallback();
        }
    };

    isSelectedProjectOwner = (): boolean => {
        const { selectedProjects } = this.props;

        if (selectedProjects.length == 0) {
            return false;
        }
        let isOwner: boolean = true;

        for (const project of selectedProjects) {
            if (!project.userIsOwner) {
                isOwner = false;
                break;
            }
        }

        return isOwner;
    };

    private handleClosePopup = async () => {
        this.setState({
            ...this.state,
            changeStatusProject: {
                openPopup: false,
                status: null,
                changeStatusFunction: undefined,
            },
            renameProjectPopup: {
                openPopup: false,
            },
            changeDescriptionProjectPopup: {
                openPopup: false,
            },
        });
    };

    private handleCloseUpdated = async (isUpdate?: boolean) => {
        await this.handleClosePopup();
        if (isUpdate) {
            this.props.setIsLoading();
            this.props.actionCallback();
        }
    };

    commonAction(projectId: number): IMenuItem[] {
        const { detailMode, goToProject, goToCollections, goToComments, goToFiles } = this.props;

        if (detailMode) {
            return this.modifyProjectAction();
        }

        let action: IMenuItem[] = [];
        action.push({
            label: this.props.intl.formatMessage({ id: "app.statusActionButton.open" }),
            icon: <WedIcon icon={<WedIconFolderOpen />} size={SizeType.SMALL} />,
            divider: true,
            click: () => {
                goToProject(projectId);
            },
        });
        action = action.concat(this.modifyProjectAction());
        action.push({
                label: this.props.intl.formatMessage({ id: "app.statusActionButton.commentsProofing" }),
                icon: <WedIcon icon={<WedIconChatMessagesBubbleCheckMark />} size={SizeType.SMALL} />,
                click: () => {
                    goToComments(projectId);
                }
            },
            {
                label: this.props.intl.formatMessage({ id: "app.statusActionButton.files" }),
                icon: <WedIcon icon={<WedIconFolderGroup11 />} size={SizeType.SMALL} />,
                click: () => {
                    goToFiles(projectId);
                }
            },
            {
                label: this.props.intl.formatMessage({ id: "app.statusActionButton.collections" }),
                icon: <WedIcon icon={<WedIconItemsGroupElements />} size={SizeType.SMALL} />,
                divider: true,
                click: () => {
                    goToCollections(projectId);
                }
            });

        return action;
    }

    modifyProjectAction() {
        const { selectedProjects } = this.props;

        if (selectedProjects.length === 1 && selectedProjects[0].status === ProjectStatusEnum.active) {
            return [
                {
                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.changeProjectTitle" }),
                    disabled: !this.isSelectedProjectOwner(),
                    icon: <WedIcon icon={<WedIconPenEdit17 />} size={SizeType.SMALL} />,
                    click: () => {
                        this.setState({
                            renameProjectPopup: {
                                openPopup: true,
                            },
                        });
                    },
                },
                {
                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.changeProjectDescription" }),
                    disabled: !this.isSelectedProjectOwner(),
                    icon: <WedIcon icon={<WedIconPenEditCreate3 />} size={SizeType.SMALL} />,
                    divider: true,
                    click: () => {
                        this.setState({
                            changeDescriptionProjectPopup: {
                                openPopup: true,
                            },
                        });
                    },
                },
            ];
        }

        return [];
    }

    render() {
        const { selectedProjects, closeProjects, archiveProjects, restoreProjects, reactivateProjects, deleteProjects, icon } = this.props;
        const selectedProjectsIds = selectedProjects.map((project) => project.id);

        return (
            <>
                <StatusActionButton
                    selectedItems={selectedProjects}
                    menuItems={[
                        {
                            statuses: [ProjectStatusEnum.active],
                            multiply: false,
                            items: this.commonAction(selectedProjectsIds[0]).concat([
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.closeProject" }),
                                    icon: <WedIcon icon={<WedIconSignClosed />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.close,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await closeProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                            ]),
                        },
                        {
                            statuses: [ProjectStatusEnum.active],
                            multiply: true,
                            items: [
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.closeSelectedProjects" }),
                                    icon: <WedIcon icon={<WedIconSignClosed />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.close,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await closeProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                            ],
                        },
                        {
                            statuses: [ProjectStatusEnum.closed],
                            multiply: false,
                            items: this.commonAction(selectedProjectsIds[0]).concat([
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.reactivateProject" }),
                                    icon: <WedIcon icon={<WedIconBackRefreshReloadLoadingCheckmark />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.reactivate,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await reactivateProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.archiveProject" }),
                                    icon: <WedIcon icon={<WedIconMailboxInboxArchive />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.archive,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await archiveProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.archiveCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                            ]),
                        },
                        {
                            statuses: [ProjectStatusEnum.closed],
                            multiply: true,
                            items: [
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.reactivateSelectedProjects" }),
                                    icon: <WedIcon icon={<WedIconBackRefreshReloadLoadingCheckmark />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.reactivate,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await reactivateProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.archiveSelectedProjects" }),
                                    icon: <WedIcon icon={<WedIconMailboxInboxArchive />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.archive,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await archiveProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                            ],
                        },
                        {
                            statuses: [ProjectStatusEnum.archived],
                            items: [
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.restore" }),
                                    icon: <WedIcon icon={<WedIconBackwardRearwardBackRemove />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.restore,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await restoreProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    this.actionCallback();
                                                },
                                            },
                                        });
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.statusActionButton.delete" }),
                                    icon: <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />,
                                    disabled: !this.isSelectedProjectOwner(),
                                    click: async () => {
                                        this.setState({
                                            changeStatusProject: {
                                                openPopup: true,
                                                status: ProjectStatusChangeEnum.delete,
                                                changeStatusFunction: async () => {
                                                    this.props.setIsLoading();
                                                    try {
                                                        await deleteProjects(selectedProjectsIds);
                                                    } catch (err) {
                                                        this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
                                                    }
                                                    if (window.location.pathname === RoutesEnum.PROJECTS) {
                                                        return this.actionCallback();
                                                    }
                                                    this.props.history.push(generatePath(RoutesEnum.PROJECTS));
                                                },
                                            },
                                        });
                                    },
                                },
                            ],
                        },
                    ]}
                    icon={icon}
                />
                {!!selectedProjects && (
                    <ChangeStatusProjectPopup
                        callback={this.state.changeStatusProject.changeStatusFunction}
                        handleClose={this.handleClosePopup}
                        open={this.state.changeStatusProject.openPopup}
                        status={this.state.changeStatusProject.status}
                    />
                )}
                {selectedProjects && selectedProjects.length === 1 && selectedProjects[0].object && (
                    <>
                        <ChangeDescriptionProjectPopup
                            open={this.state.changeDescriptionProjectPopup.openPopup}
                            project={selectedProjects[0].object as IProject}
                            handleClose={this.handleCloseUpdated}
                        />
                        <RenameProjectPopup
                            open={this.state.renameProjectPopup.openPopup}
                            project={selectedProjects[0].object as IProject}
                            handleClose={this.handleCloseUpdated}
                        />
                    </>
                )}
            </>
        );
    }
}

export default withSnackbar(withProjectActions(injectIntl(ProjectListActionButton)));
