import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import WedMediaGallery from "../../components/wed-media-gallery/WedMediaGallery/WedMediaGallery";
import { getQueryIntParam } from "../../services/WedApi/Utils/QueryParameter";
import { parse } from "query-string";
import { WedMainMenuContainer } from "../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import FilesMediaGalleryButtons from "./FilesMediaGalleryHeader/FilesMediaGalleryButtons/FilesMediaGalleryButtons";
import SidebarWrapper from "../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { FileTypeEnum, isMyLibraryFileType } from "../../components/template/Type/FileTypeEnum";
import { IProjectExtended } from "../../services/WedApi/Models/Project";
import SubmenuFactory from "../../components/template/Submenu/Service/SubmenuFactory";
import MenuControlIcons from "../../components/main-menu/MenuControlIcons/MenuControlIcons";
import "./FilesMediaGalleryPage.scss";
import { IUserTeamDetail } from "../../services/WedApi/Models/User";
import { isEditableProject } from "../../services/WedApi/Utils/IsEditableProject";

export interface RefreshMediaGalleryParams {
    versionId?: number;
}

interface PropsInterface extends RouteComponentProps {
    fileType: FileTypeEnum;
    collectionId?: number;
    project?: IProjectExtended;
    userTeam?: IUserTeamDetail;
}

interface StateInterface {
    reloadingMediaGallery: boolean;
    reloadingVersionId?: number;
}

class FilesMediaGalleryPage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            reloadingMediaGallery: false,
        };
        this.onRefreshMediaGallery = this.onRefreshMediaGallery.bind(this);
    }

    private getDir(): number | undefined {
        if (this.props.collectionId) {
            return undefined;
        }
        return getQueryIntParam(parse(this.props.location.search), "dir") || this.props.project?.rootDirectory;
    }

    private onRefreshMediaGallery(params?: RefreshMediaGalleryParams) {
        this.setState({
            reloadingMediaGallery: true,
        });
        setTimeout(() => {
            this.setState({
                reloadingMediaGallery: false,
                reloadingVersionId: params?.versionId,
            });
        }, 10);
    }

    private isUserTeamFileType(): boolean {
        switch (this.props.fileType) {
            case FileTypeEnum.TEAM_USER_PROJECT_WORK:
            case FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO:
                return true;
        }
        return false;
    }

    private getParentObjectId(): number | undefined {
        if (this.isUserTeamFileType()) {
            return this.props.userTeam?.id;
        }
        return this.props.project?.id;
    }

    render() {
        const { project, fileType } = this.props;
        const userTeamType = this.isUserTeamFileType();

        return (
            <div className="project-files-media-gallery-page">
                <WedMainMenuContainer
                    menu={SubmenuFactory.getSubmenu(fileType, this.getParentObjectId())}
                    buttons={
                        <FilesMediaGalleryButtons project={project} refreshMediaGallery={this.onRefreshMediaGallery} fileType={this.props.fileType} />
                    }
                    sidebar={
                        <MenuControlIcons
                            disableChangeViewMode={true}
                            disableSidebarOpen={userTeamType}
                            project={this.props.project}
                            fileType={fileType}
                        />
                    }
                />
                <SidebarWrapper
                    fileType={fileType}
                    handleSidebarChanges={this.onRefreshMediaGallery}
                    disabledNoItemSelected={true}
                    contextProject={!isMyLibraryFileType(this.props.fileType) ? this.props.project : undefined}
                    disabled={this.props.project && !isEditableProject(this.props.fileType, this.props.project)}
                >
                    {!this.state.reloadingMediaGallery && (
                        <WedMediaGallery
                            project={this.props.project}
                            collectionId={this.props.collectionId}
                            userTeam={this.props.userTeam}
                            dirId={this.getDir()}
                            fileType={fileType}
                            versionId={this.state.reloadingVersionId}
                            onRefreshMediaGallery={this.onRefreshMediaGallery}
                            userTeamType={userTeamType}
                        />
                    )}
                </SidebarWrapper>
            </div>
        );
    }
}

export default withRouter(FilesMediaGalleryPage);
