import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedIconPenEditCreate3 } from "../../wed-icon/generated/WedIconSvg";
import { SidebarButtonIcon } from "../SidebarButtonIcon/SidebarButtonIcon";
import "./SidebarDescription.scss";

interface PropsInterface extends WrappedComponentProps {
    description: string | undefined;
    allowToAdd: boolean;
    onOpenChangeDescriptionPopup?: () => void;
}

interface StateInterface {
    showChangeDescriptionPopup: boolean;
}

class SidebarDescription extends React.Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        showChangeDescriptionPopup: false,
    };

    render() {
        const { description, allowToAdd, onOpenChangeDescriptionPopup } = this.props;
        const hasDescription = description && description.length;

        return (
            <div className="sidebar-description">
                {!onOpenChangeDescriptionPopup ? (
                    <div className="sidebar-description__edit">{description}</div>
                ) : hasDescription ? (
                    <div className="sidebar-description__edit">
                        <SidebarButtonIcon
                            className="sidebar-description__edit-button"
                            icon={<WedIconPenEditCreate3 />}
                            disabled={!allowToAdd}
                            onClick={onOpenChangeDescriptionPopup}
                            tooltipValue={"app.ico.sidebar.editDescription"}
                        />
                        {description}
                    </div>
                ) : (
                    <div className="sidebar-description__add">
                        <span>
                            <FormattedMessage id="app.sidebar.description.add" />
                        </span>
                        <SidebarButtonIcon
                            className="sidebar-description__edit-button"
                            icon={<WedIconPenEditCreate3 />}
                            disabled={!allowToAdd}
                            onClick={onOpenChangeDescriptionPopup}
                            tooltipValue={"app.ico.sidebar.editDescription"}
                        />
                    </div>
                )}
            </div>
        );
    }
}

export default injectIntl(SidebarDescription);
