import { ICountry } from "./Country";

export enum ITeamUserProjectRole {
    ROLE_MEMBER = "member",
    ROLE_OWNER = "owner",
}

export enum IAccountTeamUserProjectRole {
    ROLE_MEMBER = "member",
    ROLE_OWNER = "owner",
}

export enum IUserTeamRole {
    REGULAR_USER = "regular",
    ADMIN_USER = "admin",
}

export enum IFolderAccessAction {
    ACCESS_READ = "read",
    ACCESS_WRITE = "write",
}

export enum IUserMyProfileRole {
    UNDEFINED = "undefined",
    ADMIN_USER = "admin",
    REGULAR_USER = "regular",
}

export interface IUserBase {
    id: number;
    firstName?: string;
    lastName?: string;
    email?: string;
}

export interface IOwner extends IUserBase {}

export interface IUserMyProfile extends IUser {
    cCode: number;
    phone: string;
    role: IUserMyProfileRole;
}

export interface IUser extends IUserBase {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    created: Date;
}

export interface IUserTeam extends IUser {
    name: string;
    email: string;
    phone: string;
    customerAccountIDs: number[];
    projectIDs: number[];
    role?: IUserTeamRole;
    counters?: {
        projectActive: number;
        projectClosed: number;
    };
}

export interface IUserTeamDetail extends IUserTeam {}

export interface ITeamUser extends IUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: ITeamUserProjectRole;
}

export interface IAccountTeamUser extends IUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    role: IAccountTeamUserProjectRole;
}

export interface IAccessFolderUser extends IUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    access: IFolderAccessAction;
}

export enum IUserViewModeEnum {
    table = "table",
    tile = "tile",
}

export enum IUserModeSidebarEnum {
    open = "open",
    close = "close",
}

export interface IApiUser {
    id: number;
    cCode: number;
    active: boolean;
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    lang: number;
    name: string;
    phone: string;
    roleID: number;
    tz: { Name: string; Abbrev: string; UTCoffset: number };
}

export interface IApiOrganizationUser extends IApiUser {}

export interface IOrganizationUser extends IUser {
    cCode: number;
    phone: string;
    role: IUserMyProfileRole;
    active: boolean;
}

export interface IApiOrganizationCompanyData {
    id: number;
    name: string;
    shortName: string;
    taxID: string;
    streetAddress1: string;
    streetAddress2: string;
    zipCode: string;
    city: string;
    countryID: number;
    state: string;
}

export interface IOrganizationCompanyData {
    id: number;
    companyName: string;
    companyShortName: string;
    taxId: string;
    street: string;
    street2: string;
    zipCode: string;
    city: string;
    state: string;
    country: ICountry | null;
}

export interface IApiSubscriptionPlan {
    activatedBy: number;
    activationTime: string;
    active: boolean;
    capacityUsage: number;
    id: number;
    price: number;
    isAvailable: boolean;
    name: string;
    registeredUsers: string;
    totalCapacity: number;
    totalUsers: string;
    usedCapacity: number;
    pendingCancellation: boolean;
    expiresAt: string;
}

export interface IApiBillingHistory {
    id: number;
    issueDate: string;
    number: string;
    netValue: string;
    vatRate: string;
    vatValue: string;
    totalValue: string;
}

export interface IBillingHistory extends Omit<IApiBillingHistory, "issueDate"> {
    issueDate: Date;
}

export interface IApiCurrentPeriod {
    cycle: string;
    lastPayment: string;
    lastValue: string;
    nextPayment: string;
    nextValue: string;
    subscription: string;
}

export interface ICurrentPeriod extends Omit<IApiCurrentPeriod, "lastPayment" | "nextPayment"> {
    lastPayment: string;
    nextPayment: string;
}

export interface IApiPaymentMethodInfo {
    mathod: string;
    details: string;
    expires: string;
    updatedAt: string;
    updatedBy: string;
}

export interface IPaymentMethodInfo extends Omit<IApiPaymentMethodInfo, "updatedAt"> {
    updatedAt: Date;
}

export interface ISubscriptionPlan extends Omit<IApiSubscriptionPlan, "activationTime" | "activatedBy" | "expiresAt"> {
    activatedBy?: IUser;
    activationTime?: Date;
    expiresAt?: Date;
}
