import React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import classNames from "classnames";
import { ProviderContext, withSnackbar } from "notistack";
import container from "../../../container";
import ShareLayoutPage from "../components/ShareLayoutPage/ShareLayoutPage";
import SharedHeader from "../../../components/header/ShareHeader/SharedHeader";
import { IShareCollection } from "../../../services/WedApi/Models/ShareCollection";
import { LinearProgress, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { AppNotFoundItemError } from "../../../errors/error-app";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { ApplicationState } from "../../../store";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import ShareContentHeader from "../../../components/share/ShareContentHeader/ShareContentHeader";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { SizeType, WedIcon } from "../../../components/wed-icon/WedIcon";
import { StatusActionButton } from "../../../components/status-action-button/StatusActionButton";
import Collection from "../../../domain/Collection";
import CollectionTile from "../../../components/tile/CollectionTile/CollectionTile";
import { SelectedItemsProps, setSelectedItems, SetSelectedItemsProps } from "../../../store/SelectedItemsReducer";
import {
    WedIconDots,
    WedIconDownloadSaveUpload1,
    WedIconFolderOpen,
    WedIconLock3,
    WedIconLockUnlock,
} from "../../../components/wed-icon/generated/WedIconSvg";
import { downloadFile } from "../../../services/WedApi/Utils/DownloadFile";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import WedDate from "../../../components/formatter/WedDate";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import { WedTable } from "../../../components/wed-table/WedTable";
import FilesFilterInterface from "../../../services/Filter/Files/FilesFilterInterface";
import "./ShareCollectionListPage.scss";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { CollectionDotStatusColorItemType, getCollectionDotStatusColor } from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface BasePropsInterface {
    token: string;
}

interface PropsInterface
    extends BasePropsInterface,
        WrappedComponentProps,
        ProviderContext,
        RouteComponentProps,
        SelectedItemsProps,
        SetSelectedItemsProps {
    isTableMode: boolean;
}

interface StateInterface {
    collections: IShareCollection[] | undefined;
    isLoading: boolean;
    searchText: string | undefined;
}

class ShareCollectionListPage extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            collections: undefined,
            isLoading: true,
            searchText: undefined,
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentWillUnmount() {
        this.props.setSelectedItems({ selectedItems: [] });
    }

    fetchData = async () => {
        const { token } = this.props;

        try {
            const collections = await container.shareCollectionService.getCollectionList(token);

            this.setState({ collections });
        } finally {
            this.setState({ isLoading: false, searchText: undefined });
        }
    };

    handleSelectItem = (collection: IShareCollection) => {
        const { selectedItems } = this.props;

        if (selectedItems.length && selectedItems[0].id === collection.id) {
            this.props.setSelectedItems({ selectedItems: [] });
        } else {
            this.props.setSelectedItems({
                selectedItems: [
                    {
                        id: collection.id,
                        object: collection,
                    },
                ],
            });
        }
    };

    isSelected = (id: number): boolean => {
        return !!this.props.selectedItems.find((selectedItem) => selectedItem.id === id);
    };

    getHeaderData = () => {
        const { collections } = this.state;
        const { selectedItems } = this.props;

        if (!collections?.length) {
            return {};
        }

        if (selectedItems.length == 1) {
            const collection = selectedItems[0].object as IShareCollection;

            return {
                companyName: collection.companyName,
                sharedBy: collection.createdBy,
                collectionName: collection.name,
            };
        }

        return { companyName: collections[0].companyName };
    };

    goToCollectionPage = (id: number) => {
        const { token, history } = this.props;

        history.push(
            `${generatePath(RoutesEnum.SHARE_COLLECTION_FILES, {
                collectionId: id,
            })}?token=${token}`
        );
    };

    getActionButton = (collection: IShareCollection) => {
        const { token, isTableMode, intl, enqueueSnackbar } = this.props;

        return (
            <StatusActionButton
                selectedItems={[]}
                menuItems={[
                    {
                        items: [
                            {
                                label: intl.formatMessage({ id: "app.dropDownMenu.folder.open" }),
                                icon: <WedIcon icon={<WedIconFolderOpen />} size={SizeType.NORMAL} />,
                                click: () => {
                                    this.goToCollectionPage(collection.id);
                                },
                            },
                            {
                                label: this.props.intl.formatMessage({ id: "app.dropDownMenu.folder.download" }),
                                icon: <WedIcon icon={<WedIconDownloadSaveUpload1 />} size={SizeType.SMALL} />,
                                click: () => {
                                    enqueueSnackbar(intl.formatMessage({ id: `app.projectFilesActionButton.download` }), {
                                        variant: "success",
                                        autoHideDuration: 4000,
                                    });

                                    downloadFile(container.shareCollectionService.getDownloadCollectionUrl(token, collection.id));
                                },
                            },
                        ],
                    },
                ]}
                icon={isTableMode ? <WedIcon size={SizeType.SMALL} icon={<WedIconDots />} /> : <TileMenu sizeIcon={SizeType.NORMAL} />}
            />
        );
    };

    onFiltersChange = (filters: FilesFilterInterface) => {
        const { searchText } = filters;

        this.setState({ searchText });
    };

    filterBySearchText = () => {
        const { collections, searchText } = this.state;

        if (!collections) {
            return [];
        }

        if (!searchText?.length) {
            return collections;
        }

        return collections.filter((collection) => collection.name.toLowerCase().includes(searchText.toLowerCase()));
    };

    getSelectedItem = (): IShareCollection | undefined => {
        const { selectedItems } = this.props;

        if (this.state.collections && selectedItems.length === 1) {
            for (let i = 0; i < this.state.collections.length; i++) {
                if (this.state.collections[i].id === selectedItems[0].id) {
                    return this.state.collections[i];
                }
            }
        }
        return undefined;
    };

    render() {
        const { isLoading } = this.state;
        const { token, selectedItems, isTableMode } = this.props;

        if (isLoading) {
            return <LinearProgress />;
        }

        if (!(Array.isArray(this.state.collections) && this.state.collections.length)) {
            throw new AppNotFoundItemError("shared collections items empty or not found!");
        }

        const collections = this.filterBySearchText();

        return (
            <ShareLayoutPage
                className="shared-collection-list"
                header={
                    <SharedHeader
                        buttons={{ download: true }}
                        collection={selectedItems[0]?.object as IShareCollection}
                        token={token}
                        refreshHandle={async () => {
                            await this.fetchData();
                        }}
                    />
                }
            >
                <SidebarWrapper
                    shareSidebar={{
                        token: token,
                        loading: isLoading,
                        collection: this.getSelectedItem(),
                    }}
                    handleSidebarChanges={async () => {
                        await this.fetchData();
                    }}
                >
                    <div className="shared-collection-list__content">
                        <ShareContentHeader {...this.getHeaderData()} onFiltersChange={this.onFiltersChange} />
                        {isTableMode ? (
                            <WedTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.collectionName" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.owner" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.created" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.locked" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.numOfFiles" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.approvalStatus" />
                                        </TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {collections.map((collectionObj, i) => {
                                        const collection = new Collection(collectionObj);

                                        return (
                                            <TableRow
                                                className={classNames({
                                                    "table-row--active": this.isSelected(collection.getId()),
                                                })}
                                                hover
                                                role="checkbox"
                                                key={i}
                                                onClick={() => {
                                                    this.handleSelectItem(collectionObj);
                                                }}
                                                onDoubleClick={() => {
                                                    this.goToCollectionPage(collection.getId());
                                                }}
                                            >
                                                <TableCell />
                                                <TableCell>{collection.getName()}</TableCell>
                                                <TableCell>{collection.getAuthor()}</TableCell>
                                                <TableCell>
                                                    <WedDate date={collection.getCreatedDate()} />
                                                </TableCell>
                                                <TableCell>
                                                    {collection.isLocked() ? (
                                                        <WedIcon size={SizeType.SMALL} icon={<WedIconLock3 />} />
                                                    ) : (
                                                        <WedIcon size={SizeType.SMALL} icon={<WedIconLockUnlock />} />
                                                    )}
                                                </TableCell>
                                                <TableCell>{collection.getFilesCount()}</TableCell>
                                                <TableCell>
                                                    <ItemDotStatus
                                                        color={getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, {
                                                            approvalFlag: collection.getData().approvalFlag,
                                                            approvalRequired: collection.getData().approvalRequired,
                                                        })}
                                                    />
                                                </TableCell>
                                                <TableCell align="right">{this.getActionButton(collectionObj)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </WedTable>
                        ) : (
                            <GridTiles>
                                {collections.map((collection, i) => (
                                    <CollectionTile
                                        fileType={FileTypeEnum.PROJECT_COLLECTION}
                                        key={i}
                                        token={token}
                                        collection={new Collection(collection)}
                                        selected={this.isSelected(collection.id)}
                                        onClick={() => {
                                            this.handleSelectItem(collection);
                                        }}
                                        actionButton={this.getActionButton(collection)}
                                    />
                                ))}
                            </GridTiles>
                        )}
                    </div>
                </SidebarWrapper>
            </ShareLayoutPage>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    selectedItems: store.selectedItems.selectedItems || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedItems: setSelectedItems(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(injectIntl(ShareCollectionListPage))));
