import React, { MouseEvent } from "react";
import classnames from "classnames";
import { Collapse } from "@material-ui/core";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconArrow } from "../../wed-icon/generated/WedIconSvg";
import { SidebarButtonIcon } from "../SidebarButtonIcon/SidebarButtonIcon";
import { WedHtmDomElementUtils } from "../../../services/WedApi/Utils/WedHtmDomElementUtils";
import "./SidebarSubContent.scss";

interface PropsInterface {
    className?: string;
    rightIcon?: React.ReactElement;
    handleClickIcon?: () => void;
    disabledClickIcon?: boolean;
    title: string | React.ReactElement;
    content: React.ReactElement;
}

interface StateInterface {
    hide: boolean;
}

class SidebarSubContent extends React.Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        hide: false,
    };

    toggleCollapse = (event?: MouseEvent) => {
        if (
            event &&
            event.target &&
            !!WedHtmDomElementUtils.findParentNodeByClassName(event.target as HTMLElement, "sidebar-sub-content--right-icon-button")
        ) {
            return;
        }
        const { hide } = this.state;
        this.setState({ hide: !hide });
    };

    render() {
        const { className, title, content, rightIcon, disabledClickIcon, handleClickIcon } = this.props;
        const { hide } = this.state;

        return (
            <div className={classnames("sidebar-sub-content", className)}>
                <h3
                    onClick={this.toggleCollapse}
                    className={classnames("sidebar-sub-content__title", "sidebar-sub-content--with-collapse", {
                        "sidebar-sub-content--hide": hide,
                    })}
                >
                    {title}
                    {<WedIcon className="sidebar-sub-content--arrow" icon={<WedIconArrow />} size={SizeType.NORMAL} />}
                    {rightIcon && (
                        <SidebarButtonIcon
                            className="sidebar-sub-content--right-icon-button"
                            icon={rightIcon}
                            disabled={disabledClickIcon}
                            onClick={handleClickIcon}
                            tooltipValue={"app.ico.sidebar.editTags"}
                        />
                    )}
                </h3>
                <Collapse in={!hide} timeout="auto" unmountOnExit>
                    {content}
                </Collapse>
            </div>
        );
    }
}

export default SidebarSubContent;
