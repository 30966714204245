import React from "react";
import "./popup-header.scss";

export enum PositionTitle {
    LEFT = "LEFT",
    CENTER = "CENTER",
}

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
    positionHeader?: PositionTitle;
}

interface StateInterface {}

export default class PopupHeader extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { children, positionHeader } = this.props;
        let nameClasses = "popup-header";
        if (PositionTitle.CENTER === positionHeader) {
            nameClasses += ` popup-header--center`;
        }

        return <h1 className={nameClasses}>{children}</h1>;
    }
}
