import React, { ReactElement } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import { WedIconEye, WedIconSettings6, WedIconTrashDeleteRemove } from "../../../../../components/wed-icon/generated/WedIconSvg";
import { ISelectItem, StatusActionButton } from "../../../../../components/status-action-button/StatusActionButton";
import { ProviderContext, withSnackbar } from "notistack";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    selectedItems: ISelectItem[];
    icon?: ReactElement;
    viewProfileClick?: () => void;
    editContactClick?: () => void;
    deleteContactClick?: () => void;
    hideViewProfile?: boolean;
}

interface StateInterface {}

class ClientContactsActionButton extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {};
    }

    render() {
        const { selectedItems, icon } = this.props;
        return (
            <>
                <StatusActionButton
                    selectedItems={selectedItems}
                    menuItems={[
                        {
                            multiply: false,
                            items: [
                                ...(this.props.hideViewProfile
                                    ? []
                                    : [
                                          {
                                              label: this.props.intl.formatMessage({
                                                  id: "app.ClientContactsActionButton.clientContact.viewProfile",
                                              }),
                                              icon: <WedIcon icon={<WedIconEye />} size={SizeType.SMALL} />,
                                              disabled: selectedItems.length !== 1,
                                              click: async () => {
                                                  this.props.viewProfileClick && this.props.viewProfileClick();
                                              },
                                              divider: true,
                                          },
                                      ]),
                                {
                                    label: this.props.intl.formatMessage({ id: "app.ClientContactsActionButton.clientContact.edit" }),
                                    icon: <WedIcon icon={<WedIconSettings6 />} size={SizeType.SMALL} />,
                                    disabled: !this.props.editContactClick || !this.props.selectedItems[0].userIsOwner,
                                    click: async () => {
                                        this.props.editContactClick && this.props.editContactClick();
                                    },
                                },
                                {
                                    label: this.props.intl.formatMessage({ id: "app.ClientContactsActionButton.clientContact.delete" }),
                                    icon: <WedIcon icon={<WedIconTrashDeleteRemove />} size={SizeType.SMALL} />,
                                    disabled: !this.props.deleteContactClick || !this.props.selectedItems[0].userIsOwner,
                                    click: async () => {
                                        this.props.deleteContactClick && this.props.deleteContactClick();
                                    },
                                },
                            ],
                        },
                    ]}
                    icon={icon}
                />
            </>
        );
    }
}

export default withSnackbar(injectIntl(ClientContactsActionButton));
