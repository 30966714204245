import { IProject, ProjectStatusEnum } from "../Models/Project";
import { IShareCollection } from "../Models/ShareCollection";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";

export function isMyLibraryFileTypeContext(fileType: FileTypeEnum): boolean {
    if (
        [
            FileTypeEnum.MY_LIBRARY_FILES,
            FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME,
            FileTypeEnum.MY_LIBRARY_COLLECTIONS,
            FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES,
        ].includes(fileType)
    ) {
        return true;
    }
    return false;
}

export function isEditableProject(fileType: FileTypeEnum, project?: IProject): boolean {
    if (isMyLibraryFileTypeContext(fileType)) {
        return true;
    }
    if (!project) {
        return false;
    }
    return project.status === ProjectStatusEnum.active;
}

export function isEditableShareCollection(collection?: IShareCollection): boolean {
    return !!collection && !collection.locked;
}
