import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import Dropzone from "react-dropzone";
import { Alert } from "@material-ui/lab";
import { Box, Button } from "@material-ui/core";
import { CircularProgressbar } from "react-circular-progressbar";
import container from "../../../container";
import { Scrollbars } from "react-custom-scrollbars";
import { getScrollbarIconYStyle, getScrollbarYStyle } from "../../../services/WedApi/Utils/ScrollbarsStyles";
import classNames from "classnames";
import { FileSize } from "../FileSize/FileSize";
import "react-circular-progressbar/dist/styles.css";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconShare } from "../../wed-icon/generated/WedIconSvg";
import PopupAction from "../../popup/PopupAction/PopupAction";
import "./UploadFile.scss";
import { LogicError } from "../../../errors/error-app";

interface PropsInterface extends WrappedComponentProps, ProviderContext, RouteComponentProps {
    close: () => void;
    uploaded: () => void;
    dirId: number;
    objectId?: number;
}

interface StateInterface {
    files: File[];
    loading: boolean;
    redirect: string;
    loadedBytes: number;
    uploading: boolean;
    uploaded: boolean;
}

class UploadFile extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            redirect: "",
            files: [],
            uploading: false,
            uploaded: false,
            loadedBytes: 0,
        };
        this.removeFromList = this.removeFromList.bind(this);
        this.uploadForm = this.uploadForm.bind(this);
    }

    private async uploadFormFiles() {
        this.setState({
            uploading: true,
        });

        const formData = new FormData();
        this.state.files.forEach((file, idx) => {
            formData.append("objects", this.state.files[idx], this.state.files[idx].name);
        });
        formData.append("parentID", this.props.dirId.toString());

        await container.filesService.uploadFile(formData, {
            onProgressHandle: (loadedBytes) => {
                this.setState({
                    loadedBytes: loadedBytes,
                });
            },
        });
        this.setState({
            uploaded: true,
        });
        this.props.uploaded();
    }

    private async uploadFormVersion() {
        if (this.state.files.length !== 1) {
            throw new LogicError("Logic exception, cannot click upload when not add 1 file");
        }
        const formData = new FormData();
        formData.append("oid", (this.props.objectId as number).toString());
        formData.append("objectVersion", this.state.files[0], this.state.files[0].name);

        await container.filesService.uploadFileVersion(formData, {
            onProgressHandle: (loadedBytes) => {
                this.setState({
                    loadedBytes: loadedBytes,
                });
            },
        });
    }

    // eslint-disable-next-line
    private async uploadForm(event: any) {
        event.preventDefault();
        this.setState({
            uploading: true,
        });

        try {
            if (this.props.objectId) {
                await this.uploadFormVersion();
            } else {
                await this.uploadFormFiles();
            }
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
            return this.setState({
                uploading: false,
                uploaded: false,
            })
        }

        this.setState({
            uploaded: true,
        });
        this.props.uploaded();
    }

    private removeFromList(idx: number) {
        if (idx < this.state.files.length) {
            this.setState({
                files: [...this.state.files.slice(0, idx), ...this.state.files.slice(idx + 1)],
            });
        }
    }

    private getPercentByFile(idx: number): number {
        if (this.state.uploaded) {
            return 1;
        }

        if (idx > this.state.files.length) {
            return 0;
        }

        let startValue = 0;
        let endValue = 0;
        for (let i = 0; i <= idx; i++) {
            if (i !== idx) {
                startValue = this.state.files[i].size;
            }
            endValue = this.state.files[i].size;
        }

        if (this.state.loadedBytes < startValue) {
            return 0;
        }
        if (this.state.loadedBytes > endValue) {
            return 1;
        }
        return (this.state.loadedBytes - startValue) / (endValue - startValue);
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <div className="upload-file">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id={this.props.objectId ? "app.UploadFilePopup.Version.header" : "app.UploadFilePopup.Files.header"} />
                </PopupHeader>
                <form onSubmit={this.uploadForm} noValidate>
                    <Box sx={{ p: 2, border: "2px dashed rgba(186, 186, 186, 0.5)" }}>
                        <Dropzone
                            multiple={!this.props.objectId}
                            onDrop={(acceptedFiles) => {
                                const files: File[] = this.state.files;
                                this.setState({ files: this.props.objectId ? [...acceptedFiles] : [...files, ...acceptedFiles] });
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="upload-file__box-center">
                                            <FormattedMessage
                                                id={
                                                    this.props.objectId
                                                        ? "app.UploadFilePopup.Version.dropZone"
                                                        : "app.UploadFilePopup.Files.dropZone"
                                                }
                                            />
                                        </div>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Box>
                    {this.state.files.length > 0 && (
                        <div className={`upload-file__list upload-file__list_${this.state.files.length}`}>
                            <Scrollbars
                                universal
                                renderTrackVertical={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarYStyle(),
                                    });
                                }}
                                renderThumbVertical={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarIconYStyle(),
                                    });
                                }}
                            >
                                <div className={classNames({ "upload-file__list-content-scrollable": this.state.files.length > 3 })}>
                                    {this.state.files.map((file, idx) => {
                                        const loadedValue = this.getPercentByFile(idx);
                                        return (
                                            <Alert
                                                key={idx}
                                                severity={loadedValue === 1 ? "success" : "info"}
                                                icon={<CircularProgressbar value={loadedValue} maxValue={1} />}
                                                className={classNames("upload-file__list-item", {
                                                    "upload-file__list-item-uploading": this.state.uploading,
                                                })}
                                                onClose={() => {
                                                    this.removeFromList(idx);
                                                }}
                                            >
                                                <span className="upload-file__list-item-file-name">{file.name}</span>
                                                <span className="upload-file__list-item-file-size">
                                                    (<FileSize size={file.size} inBytes={true} />)
                                                </span>
                                            </Alert>
                                        );
                                    })}
                                </div>
                            </Scrollbars>
                        </div>
                    )}
                    <PopupAction>
                        <Button
                            disabled={this.state.uploading || this.state.files.length === 0}
                            type="submit"
                            startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare />} />}
                        >
                            <FormattedMessage
                                id={this.props.objectId ? "app.UploadFilePopup.Version.uploadButton" : "app.UploadFilePopup.Files.uploadButton"}
                            />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(UploadFile)));
