import CountryActionApiService from "../CountryActionApiService";
import { ICountry } from "../Models/Country";

export class CountryService {
    constructor(private readonly countryActionApiService: CountryActionApiService) {}

    async getCountries(): Promise<ICountry[]> {
        return (await this.countryActionApiService.getCountries()).filter((country) => country.id > 0);
    }
}
