import React from "react";
import classNames from "classnames";
import "./ShareLayoutPage.scss";
import { Dispatch } from "redux";
import { initTokenUser } from "../../../../store/UserReducer";
import { connect } from "react-redux";

interface StateInterface {}

interface PropsInterface {
    className: string;
    header: React.ReactElement;
    children?: React.ReactNode | React.ReactNodeArray;
    initTokenUser: () => void;
}

class ShareLayoutPage extends React.Component<PropsInterface, StateInterface> {
    componentDidMount() {
        this.props.initTokenUser();
    }

    render() {
        return (
            <div className={classNames("share-layout-page", this.props.className)}>
                {this.props.header}
                <div className="share-layout-page__content">{this.props.children}</div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    initTokenUser: initTokenUser(dispatch),
});

export default connect(undefined, mapDispatchToProps)(ShareLayoutPage);
