import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { WedIconPlusAdd, WedIconSingleUserAddPlus1 } from "../../../../components/wed-icon/generated/WedIconSvg";
import { SizeType, WedIcon } from "../../../../components/wed-icon/WedIcon";
import { WedMenuButtons } from "../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import { IProject } from "../../../../services/WedApi/Models/Project";
import ProjectListActionButton from "../../../../components/template/ListProjectPage/ProjectListPageHeader/ProjectListActionButton/ProjectListActionButton";
import { ISelectItemType } from "../../../../components/status-action-button/StatusActionButton";
import { isEditableProject } from "../../../../services/WedApi/Utils/IsEditableProject";
import { FileTypeEnum } from "../../../../components/template/Type/FileTypeEnum";
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";

interface PropsInterface extends RouteComponentProps {
    setIsLoading: () => void;
    actionCallback: () => void;
    project: IProject;
    createProjectClick?: () => void;
    addProjectMembersClick?: () => void;
}

interface StateInterface {}

class ProjectSummaryButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { createProjectClick, addProjectMembersClick, project, setIsLoading, actionCallback } = this.props;

        return (
            <WedMenuButtons>
                <Button
                    disabled={!createProjectClick}
                    onClick={createProjectClick}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}
                >
                    <FormattedMessage id="app.projectSummaryButtons.createProject" />
                </Button>
                <Button
                    disabled={!addProjectMembersClick || !isEditableProject(FileTypeEnum.PROJECT_FILES, project)}
                    onClick={addProjectMembersClick}
                    type="submit"
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconSingleUserAddPlus1 />} />}
                >
                    <FormattedMessage id="app.projectSummaryButtons.addProjectMembers" />
                </Button>
                <ProjectListActionButton
                    detailMode={true}
                    selectedProjects={[
                        {
                            object: project,
                            status: project.status,
                            type: ISelectItemType.project,
                            id: project.id,
                            userIsOwner: project.userIsOwner,
                        },
                    ]}
                    setIsLoading={setIsLoading}
                    actionCallback={actionCallback}
                    archiveCallback={()=>{
                        this.props.history.push(generatePath(RoutesEnum.PROJECTS, { }));
                    }}
                />
            </WedMenuButtons>
        );
    }
}

export default withRouter(ProjectSummaryButtons);
