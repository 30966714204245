import config from "./config";
import history from "./store/history";
import AbstractRestApiConstructorInterface from "./services/AbstractRest/AbstractRestApiOptionsInterface";
import UserActionApiService from "./services/WedApi/UserActionApiService";
import ProjectActionApiService from "./services/WedApi/ProjectActionApiService";
import { ProjectService } from "./services/WedApi/Services/ProjectService";
import { ClientService } from "./services/WedApi/Services/ClientService";
import { FilesService } from "./services/WedApi/Services/FilesService";
import FilesActionApiService from "./services/WedApi/FilesActionApiService";
import ClientActionApiService from "./services/WedApi/ClientActionApiService";
import ProjectsFilterService from "./services/Filter/Projects/ProjectsFilterService";
import CollectionActionApiService from "./services/WedApi/CollectionActionApiService";
import { UserService } from "./services/WedApi/Services/UserService";
import { CollectionService } from "./services/WedApi/Services/CollectionService";
import CollectionFilterService from "./services/Filter/Collection/CollectionFilterService";
import TrashActionApiService from "./services/WedApi/TrashActionApiService";
import { TrashService } from "./services/WedApi/Services/TrashService";
import FilesFilterService from "./services/Filter/Files/FilesFilterService";
import { CollectionAddFilesService } from "./services/WedApi/Services/CollectionAddFilesService";
import ProjectFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/ProjectFilesData";
import ProjectCollectionFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/ProjectCollectionFilesData";
import MyLibraryFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/MyLibraryFilesData";
import MyLibraryFilesActionApiService from "./services/WedApi/MyLibraryFilesActionApiService";
import ProjectFilesActionApiService from "./services/WedApi/ProjectFilesActionApiService";
import ClientFilterService from "./services/Filter/Client/ClientFilterService";
import CountryActionApiService from "./services/WedApi/CountryActionApiService";
import { CountryService } from "./services/WedApi/Services/CountryService";
import MyLibrarySharedWithMeData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/MyLibrarySharedWithMeData";
import { ClientContactService } from "./services/WedApi/Services/ClientContactService";
import { NoteService } from "./services/WedApi/Services/NoteService";
import NoteActionApiService from "./services/WedApi/NoteActionApiService";
import ShareCollectionActionApiService from "./services/WedApi/ShareCollectionActionApiService";
import { ShareCollectionService } from "./services/WedApi/Services/ShareCollectionService";
import ActivitiesActionApiService from "./services/WedApi/ActivitiesActionApiService";
import { ActivitiesService } from "./services/WedApi/Services/ActivitiesService";
import SharedCollectionFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/SharedCollectionFilesData";
import { PublicUrlService } from "./services/WedApi/Services/PublicUrlService";
import UserTeamPrivatePortfolioFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/UserTeamPrivatePortfolioFilesData";
import UserTeamProjectWorkFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/UserTeamProjectWorkFilesData";
import { AdminActionApiService } from "./services/WedApi/AdminActionApiService";
import { AdminService } from "./services/WedApi/Services/AdminService";
import NotifsActionApiService from "./services/WedApi/NotifsActionApiService";
import { NotifsService } from "./services/WedApi/Services/NotifsService";
import { CaptchaService } from "./services/WedApi/Services/CaptchaService";
import SearchActionApiService from "./services/WedApi/SearchActionApiService";
import { SearchService } from "./services/WedApi/Services/SearchService";
import MyLibraryCollectionFilesData from "./components/template/FilesPage/Service/DataFilesByType/FilesData/MyLibraryCollectionFilesData";
import PaymentActionApiService from "./services/WedApi/PaymentActionApiService";
import { PaymentService } from "./services/WedApi/Services/PaymentService";

const options: AbstractRestApiConstructorInterface = { host: config.apiUrl };
const userActionApiService = new UserActionApiService(options, history);
userActionApiService.addInterceptor();
const projectActionApiService = new ProjectActionApiService(options, history);
projectActionApiService.addInterceptor();
const clientActionApiService = new ClientActionApiService(options, history);
clientActionApiService.addInterceptor();
const filesActionApiService = new FilesActionApiService(options, history);
filesActionApiService.addInterceptor();
const myLibraryFilesActionApiService = new MyLibraryFilesActionApiService(options, history);
myLibraryFilesActionApiService.addInterceptor();
const projectFilesActionApiService = new ProjectFilesActionApiService(options, history);
projectFilesActionApiService.addInterceptor();
const collectionActionApiService = new CollectionActionApiService(options, history);
collectionActionApiService.addInterceptor();
const trashActionApiService = new TrashActionApiService(options, history);
collectionActionApiService.addInterceptor();
const countryActionApiService = new CountryActionApiService(options, history);
countryActionApiService.addInterceptor();
const noteActionApiService = new NoteActionApiService(options, history);
noteActionApiService.addInterceptor();
const activitiesActionApiService = new ActivitiesActionApiService(options, history);
activitiesActionApiService.addInterceptor();
const adminActionApiService = new AdminActionApiService(options, history);
adminActionApiService.addInterceptor();
const notifsActionApiService = new NotifsActionApiService(options, history);
const searchActionApiService = new SearchActionApiService(options, history);
notifsActionApiService.addInterceptor();
const shareCollectionActionApiService = new ShareCollectionActionApiService(options, history);
shareCollectionActionApiService.addInterceptor();
const paymentActionApiService = new PaymentActionApiService(options, history);
notifsActionApiService.addInterceptor();

const collectionService = new CollectionService(collectionActionApiService, userActionApiService, clientActionApiService);
const filesService = new FilesService(filesActionApiService, userActionApiService, projectFilesActionApiService, myLibraryFilesActionApiService);

const projectFilesData = new ProjectFilesData();
const projectCollectionFilesData = new ProjectCollectionFilesData();
const myLibraryFilesData = new MyLibraryFilesData();
const myLibrarySharedWithMeData = new MyLibrarySharedWithMeData();
const myLibraryCollectionFilesData = new MyLibraryCollectionFilesData();
const userTeamPrivatePortfolioFilesData = new UserTeamPrivatePortfolioFilesData();
const userTeamProjectWorkFilesData = new UserTeamProjectWorkFilesData();

const countryService = new CountryService(countryActionApiService);
const adminService = new AdminService(adminActionApiService, userActionApiService, countryService);

const activitiesService = new ActivitiesService(activitiesActionApiService);
const notifsService = new NotifsService(notifsActionApiService);
const paymentService = new PaymentService(paymentActionApiService);

const searchService = new SearchService(searchActionApiService);

const sharedCollectionFilesData = new SharedCollectionFilesData();
const shareCollectionService = new ShareCollectionService(shareCollectionActionApiService);

const container = {
    projectActionApiService,
    collectionActionApiService,
    projectService: new ProjectService(projectActionApiService, clientActionApiService, userActionApiService),
    clientService: new ClientService(clientActionApiService, userActionApiService, projectActionApiService),
    clientContactService: new ClientContactService(clientActionApiService, userActionApiService, projectActionApiService),
    clientFilterService: new ClientFilterService(),
    filesService: filesService,
    projectsFilterService: new ProjectsFilterService(),
    collectionsFilterService: new CollectionFilterService(),
    filesFilterService: new FilesFilterService(),
    userService: new UserService(userActionApiService, projectActionApiService),
    collectionService: collectionService,
    collectionAddFilesService: new CollectionAddFilesService(collectionService, filesService),
    trashService: new TrashService(trashActionApiService, filesActionApiService, userActionApiService),
    countryService,
    noteService: new NoteService(
        noteActionApiService,
        userActionApiService,
        clientActionApiService,
        projectActionApiService,
        collectionActionApiService,
        filesActionApiService
    ),
    publicUrlService: new PublicUrlService(),
    history,
    projectFilesData,
    projectCollectionFilesData,
    myLibraryFilesData,
    myLibrarySharedWithMeData,
    myLibraryCollectionFilesData,
    userTeamPrivatePortfolioFilesData,
    userTeamProjectWorkFilesData,
    adminService,
    activitiesService,
    notifsService,
    searchService,
    paymentService,
    shareCollectionService,
    sharedCollectionFilesData,
    captchaService: new CaptchaService(config.gCaptchaSiteKey),
};

export default container;
