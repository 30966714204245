import {
    IApiBillingHistory,
    IApiCurrentPeriod,
    IApiOrganizationCompanyData,
    IApiOrganizationUser,
    IApiPaymentMethodInfo,
    IApiSubscriptionPlan,
} from "./Models/User";
import { IListResult } from "./Models/Common";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import { History } from "history";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiInvitationEmail } from "./Models/InvitationEmail";

export class AdminActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    async getOrganizationUsers(): Promise<IListResult<IApiOrganizationUser>> {
        const response = await this.get("/xapi/bas/users");
        return response.data;
    }

    public async sendInvites(emails: string[]): Promise<void> {
        if (emails.length > 0) {
            await this.postWithCSRFJson("/xapi/user/invite", { emails: emails });
        }
    }

    public async getPendingInvitations(): Promise<IListResult<IApiInvitationEmail>> {
        const response = await this.get("/xapi/user/invitations");
        return response.data;
    }

    public async removePendingInvitation(email: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/disinvite", { email });
        return response.data;
    }

    public async setUserStatus(uid: number, status: boolean): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/active", { uid, status });
        return response.data;
    }

    public async setUserRole(uid: number, role: number) {
        const response = await this.postWithCSRFJson("/xapi/user/role", { uid, role });
        return response.data;
    }

    public async removeUser(uid: number): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/remove", { uid });
        return response.data;
    }

    public async getCompanyData(): Promise<IApiOrganizationCompanyData> {
        const response = await this.get("/xapi/bas/edit");
        return response.data;
    }

    public async updateCompanyData(
        id: number,
        name: string,
        shortName: string,
        taxID: string,
        streetAddress1: string,
        streetAddress2: string,
        zipCode: string,
        city: string,
        state: string,
        countryID: number
    ): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/bas/update", {
            id,
            name,
            shortName,
            taxID,
            streetAddress1,
            streetAddress2,
            zipCode,
            city,
            state,
            countryID,
        });
        return response.data;
    }

    public async getSubscriptionPlans(): Promise<{ plans: IApiSubscriptionPlan[] }> {
        const response = await this.get("/xapi/bas/plan");
        return response.data;
    }

    public async reactivateSubscription(planID: number): Promise<{ redirectUrl: string }> {
        const response = await this.postWithCSRFJson("/xapi/bas/sub/reactivate", { planID });
        return response.data;
    }

    public async getAllInvoiceUrl(): Promise<string> {
        return this.getUrl("/xapi/bas/invoice/getall");
    }

    public async getInvoiceUrl(id: number): Promise<string> {
        return this.getUrl(`/xapi/bas/invoice/get?id=${id}`);
    }

    public async getBillingHistory(): Promise<IListResult<IApiBillingHistory>> {
        const response = await this.get("/xapi/bas/billinghistory");
        return response.data;
    }

    public async getCurrentPeriod(): Promise<IApiCurrentPeriod> {
        const response = await this.get("/xapi/bas/cperiod");
        return response.data;
    }

    public async getCurrentPaymentMethod(): Promise<IApiPaymentMethodInfo> {
        const response = await this.get("/xapi/bas/pmethod");
        return response.data;
    }

    public async updateSubscriptionPlan(planID: number): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/bas/planupdate", { planID });
        return response.data;
    }

    public async cancelSubscriptionPlan(cancel: boolean): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/bas/sub/cancel", { cancel });
        return response.data;
    }
}
