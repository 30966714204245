import React from "react";
import Select from "@material-ui/core/Select";
import { FormattedMessage } from "react-intl";
import { FilterFormBox } from "../FilterFormBox/FilterFormBox";
import FormControl from "@material-ui/core/FormControl";
import { WedIconDropDown } from "../../wed-icon/WedIconDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import ClientFilterInterface from "../../../services/Filter/Client/ClientFilterInterface";
import { SortEnum } from "../../../services/Filter/SortEnum";

enum ClientUserOwner {
    all = "all",
    isOwner = "isOwner",
    isNotOwner = "isNotOwner",
}

interface PropsInterface {
    onFiltersChange: (filters: ClientFilterInterface) => void;
}

interface StateInterface {
    orderBy: SortEnum;
    isOwner?: ClientUserOwner;
}

export class FilterFormClientList extends React.Component<PropsInterface, StateInterface> {
    private readonly filters: ClientFilterInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.changeOrder = this.changeOrder.bind(this);
        this.changeIsOwner = this.changeIsOwner.bind(this);
        this.filters = {};
        this.state = {
            orderBy: SortEnum.NAME,
            isOwner: ClientUserOwner.all,
        };
    }

    private changeOrder(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const orderBy: SortEnum = event.target.value as SortEnum;
        this.setState({ orderBy });
        this.changeFilter("order", orderBy);
    }

    private changeIsOwner(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        let filterValue: undefined | boolean;
        const value: ClientUserOwner = event.target.value as ClientUserOwner;
        this.setState({ isOwner: value });

        switch (value) {
            case ClientUserOwner.isOwner:
                filterValue = true;
                break;
            case ClientUserOwner.isNotOwner:
                filterValue = false;
                break;
            default:
                filterValue = undefined;
        }

        this.changeFilter("isOwner", filterValue);
    }

    private changeFilter(name: string, value: string | number | boolean | undefined) {
        this.filters[name] = value;
        this.props.onFiltersChange(this.filters);
    }

    render() {
        return (
            <FilterFormBox className="filter-form-collection">
                <FormControl variant="outlined">
                    <Select
                        value={this.state.orderBy}
                        style={{ width: 140 }}
                        displayEmpty
                        IconComponent={WedIconDropDown}
                        onChange={this.changeOrder}
                    >
                        <MenuItem value={SortEnum.NAME}>
                            <FormattedMessage id="app.FilterFormClientList.orderBy.az" />
                        </MenuItem>
                        <MenuItem value={SortEnum.CREATED}>
                            <FormattedMessage id="app.FilterFormClientList.orderBy.createdAt" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined">
                    <Select
                        value={this.state.isOwner}
                        style={{ width: 190 }}
                        displayEmpty
                        onChange={this.changeIsOwner}
                        IconComponent={WedIconDropDown}
                    >
                        <MenuItem value={ClientUserOwner.all}>
                            <FormattedMessage id="app.FilterFormClientList.isOwner.allCustomerAccounts" />
                        </MenuItem>
                        <MenuItem value={ClientUserOwner.isOwner}>
                            <FormattedMessage id="app.FilterFormClientList.isOwner.isOwner" />
                        </MenuItem>
                        <MenuItem value={ClientUserOwner.isNotOwner}>
                            <FormattedMessage id="app.FilterFormClientList.isOwner.isNotOwner" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </FilterFormBox>
        );
    }
}
