import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";
import SidebarNoSelected from "../SidebarNoSelected/SidebarNoSelected";
import ProjectSidebar from "../../../pages/project/sidebars/ProjectSidebar";
import FolderSidebar from "../../../pages/project/sidebars/FolderSidebar";
import FileSidebar from "../../../pages/project/sidebars/FileSidebar";
import CollectionSidebar from "../../../pages/project/sidebars/CollectionSidebar";
import CollectionFileSidebar from "../../../pages/project/sidebars/CollectionFileSidebar";
import ClientSidebar from "../../../pages/project/sidebars/ClientSidebar";
import "./Sidebar.scss";
import { IProject } from "../../../services/WedApi/Models/Project";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";

export enum SidebarType {
    project = "project",
    folder = "folder",
    file = "file",
    collection = "collection",
    collectionFile = "collectionFile",
    client = "client",
}

interface PropsInterface {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    disabledNoItemSelected?: boolean;
    type?: SidebarType;
    id?: number;
    versionId?: number;
    dirId?: number;
    collectionId?: number;
    contextProject?: IProject;
    fileType?: FileTypeEnum;
}

interface StateInterface {
    loading: boolean;
}

class Sidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        loading: false,
    };

    onLoadingChange = (status: boolean) => {
        this.setState({ loading: status });
    };

    getLoadingContent = () => {
        return (
            <div className="sidebar__loader">
                <CircularProgress />
            </div>
        );
    };

    displaySidebarVariant = () => {
        const { type, disabledNoItemSelected, id, versionId, dirId, collectionId, fileType } = this.props;

        if (!id) {
            if (disabledNoItemSelected) {
                return this.getLoadingContent();
            }
            return <SidebarNoSelected />;
        }
        switch (type) {
            case SidebarType.project:
                return (
                    <ProjectSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        projectId={id}
                        loadingCallback={this.onLoadingChange}
                    />
                );
            case SidebarType.folder:
                return (
                    <FolderSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        fileType={fileType}
                        directoryId={id}
                        loadingCallback={this.onLoadingChange}
                    />
                );
            case SidebarType.file:
                return (
                    <FileSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        fileId={id}
                        versionId={versionId}
                        dirId={dirId}
                        loadingCallback={this.onLoadingChange}
                    />
                );
            case SidebarType.collection:
                return this.props.contextProject ? (
                    <CollectionSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        collectionId={id}
                        clientId={this.props.contextProject.ecOrg.id}
                        loadingCallback={this.onLoadingChange}
                    />
                ) : this.props.fileType &&
                  [FileTypeEnum.MY_LIBRARY_COLLECTIONS, FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES].includes(this.props.fileType) ? (
                    <CollectionSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        collectionId={id}
                        clientId={undefined}
                        loadingCallback={this.onLoadingChange}
                    />
                ) : (
                    <></>
                );
            case SidebarType.collectionFile:
                return this.props.contextProject ? (
                    <CollectionFileSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        fileId={id}
                        versionId={versionId}
                        collectionId={collectionId}
                        clientId={this.props.contextProject.ecOrg.id}
                        loadingCallback={this.onLoadingChange}
                    />
                ) : this.props.fileType === FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES ? (
                    <CollectionFileSidebar
                        disabled={this.props.disabled}
                        handleSidebarChanges={this.props.handleSidebarChanges}
                        fileType={this.props.fileType}
                        fileId={id}
                        versionId={versionId}
                        collectionId={collectionId}
                        clientId={undefined}
                        loadingCallback={this.onLoadingChange}
                    />
                ) : (
                    <></>
                );
            case SidebarType.client:
                return <ClientSidebar handleSidebarChanges={this.props.handleSidebarChanges} clientId={id} loadingCallback={this.onLoadingChange} />;
        }
    };

    render() {
        const { loading } = this.state;

        return (
            <div className="sidebar">
                {loading && this.getLoadingContent()}
                {this.displaySidebarVariant()}
            </div>
        );
    }
}

export default Sidebar;
