export default class TermsAndConditionsText {


    public static html = `<link
        href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400;500;700&display=swap"
        rel="stylesheet">
        <div class="container-extended-blog">
    
            <h2>Terms and Conditions</h2>
            <h2>Last updated: May 15, 2022</h2>
    
    <p>
    Contents .....
    </p>
    
        </div>
    `;
}
