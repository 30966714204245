import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import {Checkbox, Link, TextField} from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import NotLoggedFormPage from "../../../components/template/NotLoggedFormPage/NotLoggedFormPage";
import "./SignupInvitationPage.scss";
import container from "../../../container";
import {PASSWORD_PATTERN} from "../../../services/WedApi/Utils/Password";
import {parse} from "query-string";
import {isObject, isString} from "lodash";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Redirect404 from "../../../components/error/Redirect404/Redirect404";
import Redirect from "../../../services/Redirect/Redirect";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import {
    UserRegisterTermsAndConditionsPopup
} from "../../../components/user/UserRegisterTermsAndConditionsPopup/UserRegisterTermsAndConditionsPopup";

interface PropsInterface extends ProviderContext, WrappedComponentProps, RouteComponentProps {}

interface StateInterface {
    success: boolean;
    termsAndConditionsPopup: boolean;
    fields: {
        firstName: string;
        lastName: string;
        password: string;
        password2: string;
        tc: boolean;
    };
}

const CAPTCHA_ACTION = "signup_invitation";

class SignupInvitationPage extends Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            firstName: {
                type: "string",
                minLength: 2,
                maxLength: 30,
            },
            lastName: {
                type: "string",
                minLength: 2,
                maxLength: 50,
            },
            password: {
                type: "string",
                pattern: PASSWORD_PATTERN,
            },
            password2: {
                type: "string",
            },
            tc: {
                type: "boolean",
            },
        },
        required: ["firstName", "lastName", "password", "password2", "tc"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                firstName: "app.SignupInvitationPage.errorFirstName",
                lastName: "app.SignupInvitationPage.errorLastName",
                password: "app.SignupInvitationPage.errorPassword",
                password2: "app.SignupInvitationPage.errorPassword2",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            success: false,
            termsAndConditionsPopup: false,
            fields: {
                firstName: "",
                lastName: "",
                password: "",
                password2: "",
                tc: false,
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    private getInvitationData(): { email: string, token: string } | undefined {
        if (this.props.location.search) {
            const data = parse(this.props.location.search);
            if (data && isObject(data) &&
                data.token && isString(data.token) && data.token.length > 0 &&
                data.email && isString(data.email) && data.email.length > 0
            ) {
                return { email: data.email, token: data.token };
            }
        }
    }

    // eslint-disable-next-line
    private async handleSubmit(_: any, token: string | undefined) {
        if (this.state.fields.password !== this.state.fields.password2) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.SignupInvitationPage.errorPassword2" }), { variant: "error" });
            return;
        }
        if (!this.state.fields.tc) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.SignupInvitationPage.errorTC" }), { variant: "error" });
            return;
        }
        const tokenData = this.getInvitationData();
        if (!tokenData) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.internalError" }), { variant: "error" });
            return;
        }

        try {
            await container.userService.signupInvitation(
                token ? { recaptcha_token: token, action: CAPTCHA_ACTION } : undefined,
                tokenData.token,
                this.state.fields.password,
                this.state.fields.firstName,
                this.state.fields.lastName
            );
            return Redirect.to(RoutesEnum.PROJECTS);
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_SIGNUP_FORM_INVALID) {
                keyTranslate = "app.SignupInvitationPage.errorUserSignupFormInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_NAME_INVALID) {
                keyTranslate = "app.SignupInvitationPage.errorUserNameInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_INVITATION_TOKEN_INVALID) {
                keyTranslate = "app.SignupInvitationPage.errorInvitationTokenInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PASSWORD_INVALID) {
                keyTranslate = "app.SignupInvitationPage.errorPasswordInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CAPTCHA_VERIFIED) {
                keyTranslate = "app.global.captchaVerifiedError";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        if (name === "tc") {
            this.setState({
                fields: {
                    ...this.state.fields,
                    tc: !this.state.fields.tc,
                },
            });
        } else {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: value,
                },
            });
        }
    }

    render() {
        const tokenData = this.getInvitationData();
        if (!tokenData) {
            return <Redirect404 />
        }

        return (
            <NotLoggedFormPage
                maxWidth="lg"
                className="signup-invitation-page"
                handleSubmit={this.handleSubmit}
                labelSubmit={"app.SignupInvitationPage.submit"}
                header={<FormattedMessage id={"app.SignupInvitationPage.header"} />}
                withLogo={true}
                formData={this.state.fields}
                formSchema={this.schema}
                noDisplayButtons={this.state.success}
                withCaptcha={CAPTCHA_ACTION}
            >
                <div className="signup-invitation-page--email-row">
                    <span className="signup-invitation-page--email-row-label">
                        <FormattedMessage id="app.SignupInvitationPage.label.email" />
                    </span>
                    <span className="signup-invitation-page--email-row-email">{tokenData.email}</span>
                </div>
                <div className="not-logged-form-page--form-row">
                    <div className="not-logged-form-page--form-row-column50">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label={this.props.intl.formatMessage({ id: "app.SignupInvitationPage.label.firstName" })}
                            name="firstName"
                            autoComplete="firstName"
                            autoFocus
                            value={this.state.fields.firstName}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="not-logged-form-page--form-row-column50">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="lastName"
                            label={this.props.intl.formatMessage({ id: "app.SignupInvitationPage.label.lastName" })}
                            id="lastName"
                            autoComplete="lastName"
                            value={this.state.fields.lastName}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="not-logged-form-page--form-row">
                    <div className="not-logged-form-page--form-row-column50">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={this.props.intl.formatMessage({ id: "app.SignupInvitationPage.label.password" })}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={this.state.fields.password}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="not-logged-form-page--form-row-column50">
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password2"
                            label={this.props.intl.formatMessage({ id: "app.SignupInvitationPage.label.password2" })}
                            type="password"
                            id="password2"
                            autoComplete="current-password2"
                            value={this.state.fields.password2}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <div className="register-page--input-tc">
                    <Checkbox required name="tc" id="tc" checked={this.state.fields.tc} onChange={this.handleChange} />
                    <FormattedMessage id={"app.RegisterPage.label.tc"} />
                    <Link
                        className="register-page--label-tc"
                        onClick={() => {
                            this.setState({ termsAndConditionsPopup: true });
                        }}
                    >
                        <FormattedMessage id={"app.RegisterPage.label.tcText"} />
                    </Link>
                </div>
                <UserRegisterTermsAndConditionsPopup
                    open={this.state.termsAndConditionsPopup}
                    handleClose={() => {
                        this.setState({ termsAndConditionsPopup: false });
                    }}
                    handleSuccess={() => {
                        this.setState({
                            termsAndConditionsPopup: false,
                            fields: {
                                ...this.state.fields,
                                tc: true,
                            },
                        });
                    }}
                />
            </NotLoggedFormPage>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(SignupInvitationPage)));
