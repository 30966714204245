import { ICollection } from "../services/WedApi/Models/Collection";
import container from "../container";
import { SizeImageEnum } from "../services/WedApi/Services/DataParameters/SizeImageEnum";
import { FileTypeEnum } from "../components/template/Type/FileTypeEnum";

export default class Collection {
    private readonly collection: ICollection;

    constructor(collection: ICollection) {
        this.collection = collection;
    }

    getId() {
        return this.collection.id;
    }

    getName() {
        return this.collection.name;
    }

    getDescription() {
        return this.collection.description;
    }

    getAuthor() {
        return this.collection.createdBy ? `${this.collection.createdBy.firstName} ${this.collection.createdBy.lastName}` : "";
    }

    getCreatedDate() {
        return this.collection.created;
    }

    getCreatedById(): number | undefined {
        return this.collection.createdBy ? this.collection.createdBy.id : undefined;
    }

    getBaUsers() {
        return this.collection.baUsers;
    }

    getSharedTo() {
        return this.collection.sharedTo;
    }

    getBaUsersCount() {
        return this.collection.baUsers.length;
    }

    getSharedToCount() {
        return this.collection.sharedTo.length;
    }

    getPublicNotesCount() {
        return this.collection.publicNotes.length;
    }

    getFilesCount() {
        return this.collection.files.length;
    }

    getPreviewTile(options?: { shareToken?: string; collectionId?: number; fileType?: FileTypeEnum }): string[] {
        const files = this.collection.files.slice(0, 3);

        if (!files.length) {
            return [];
        }

        return files.map((file) => {
            if (options?.shareToken) {
                return container.filesService.getSharedFileUrl(
                    options.shareToken,
                    file.id,
                    file.newestVersionId,
                    this.collection.id,
                    SizeImageEnum.THUMB
                );
            }

            return container.filesService.getFileUrl(file.id, file.newestVersionId, {
                size: SizeImageEnum.THUMB,
                fileType: options?.fileType,
                collectionId: options?.collectionId,
            });
        });
    }

    isShowStatus() {
        return this.collection.approvalRequired;
    }

    isApproval() {
        return this.collection.approvalFlag;
    }

    isLocked() {
        return this.collection.locked;
    }

    userIsOwner() {
        return this.collection.userIsOwner;
    }

    isSharing() {
        return this.isSharingBackendUsers() || this.isSharingContactsUsers();
    }

    isSharingBackendUsers() {
        return this.collection.baUsers.length > 0;
    }

    isSharingContactsUsers() {
        return this.collection.sharedTo.length > 0;
    }

    getData(): ICollection {
        return this.collection;
    }
}
