import React, { Component } from "react";
import WedInsideFormElement from "../../../../components/form/WedInsideFormElement/WedInsideFormElement";
import Select from "@material-ui/core/Select";
import { WedIconDropDown } from "../../../../components/wed-icon/WedIconDropDown";
import classNames from "classnames";
import "./AdminPanelDropdown.scss";

interface PropsInterface {
    disabled?: boolean;
    // eslint-disable-next-line
    onChange: (value: any) => void;
    children: React.ReactNodeArray;
    // eslint-disable-next-line
    value: any;
    className?: string;
}

interface StateInterface {}

class AdminPanelDropdown extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className={classNames("admin-panel-dropdown", this.props.className)}>
                <WedInsideFormElement>
                    <Select
                        IconComponent={WedIconDropDown}
                        disabled={this.props.disabled}
                        // eslint-disable-next-line
                        onChange={(event: React.FormEvent<any>) => {
                            // eslint-disable-next-line
                            this.props.onChange((event.target as any).value);
                        }}
                        value={this.props.value}
                    >
                        {this.props.children}
                    </Select>
                </WedInsideFormElement>
            </div>
        );
    }
}

export default AdminPanelDropdown;
