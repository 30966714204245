import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface PropsInterface {
    className?: string;
    path: string;
    label: string;
}

export class WedMainMenuItem extends React.Component<PropsInterface> {
    render() {
        return (
            <li className={this.props.className}>
                <Link to={this.props.path}>
                    <span>
                        <FormattedMessage id={this.props.label} />
                    </span>
                </Link>
            </li>
        );
    }
}
