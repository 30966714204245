import React, {Component} from "react";
import {AdminPanelContent} from "../components/AdminPanelContent/AdminPanelContent";
import container from "../../../container";
import {Button, LinearProgress} from "@material-ui/core";
import {ISubscriptionPlan} from "../../../services/WedApi/Models/User";
import {AdminPanelTable} from "../components/AdminPanelTable/AdminPanelTable";
import WedDate from "../../../components/formatter/WedDate";
import {ProviderContext, withSnackbar} from "notistack";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import classNames from "classnames";
import Radio from "@material-ui/core/Radio";
import "./AdminPanelSubscription.scss";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import {AdminPanelActionButton} from "../components/AdminPanelActionButton/AdminPanelActionButton";
import PopupContent from "../../../components/popup/PopupContent/PopupContent";
import Popup from "../../../components/popup/Popup/Popup";
import PopupHeader, {PositionTitle} from "../../../components/popup/PopupHeader/PopupHeader";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupAction from "../../../components/popup/PopupAction/PopupAction";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
}

interface StateInterface {
    cancel: boolean;
    canceling: boolean;
    confirmChange: boolean;
    confirming: boolean;
    loading: boolean;
    plans: ISubscriptionPlan[];
    currentPlan: number;
    selectedPlan: number;
}

const TABLE_HEADERS = [
    "app.AdminPanelSubscription.header.name",
    "app.AdminPanelSubscription.header.activated",
    "app.AdminPanelSubscription.header.description",
    "app.AdminPanelSubscription.header.price",
    undefined,
];

class AdminPanelSubscription extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            cancel: false,
            canceling: false,
            confirmChange: false,
            confirming: false,
            loading: true,
            plans: [],
            currentPlan: 0,
            selectedPlan: 0,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        const plans = await container.adminService.getSubscriptionPlans();
        const activePlan = plans.find((plan) => plan.active);
        this.setState({
            loading: false,
            plans: plans,
            currentPlan: activePlan?.id || 0,
            selectedPlan: activePlan?.id || 0,
        });
    }

    private async handleSubmit() {
        try {
            this.setState({
                loading: true,
            });
            await container.adminService.updateSubscriptionPlan(this.state.selectedPlan);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.AdminPanelSubscription.updateSuccess"}), {variant: "success"});
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_BA_SUBSCRIPTION_PLAN_INVALID) {
                keyTranslate = "app.AdminPanelSubscription.error.errBASubscriptionPlanInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: keyTranslate}), {variant: "error"});
        }
        await this.fetchData();
    }

    private async canceling() {
        this.setState({canceling: true});
        try {
            await container.adminService.initCancelSubscriptionPlan();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.AdminPanelSubscription.cancelingSuccess"}), {variant: "success"});
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
        }
        this.setState({
            cancel: false,
            canceling: false,
            loading: true,
        });
        await this.fetchData();
    }

    private async confirming() {
        this.setState({confirming: true});
        this.setState({
            confirmChange: false,
            confirming: false,
            loading: true,
        });
        await this.handleSubmit();
    }

    private async revokeSubmit() {
        this.setState({
            canceling: true,
        });
        try {
            await container.adminService.revokeCancelSubscriptionPlan();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.AdminPanelSubscription.revokePendingCancellation.success"}), {variant: "success"});
        } catch (err) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({id: "app.error.undefined"}), {variant: "error"});
        }
        this.setState({
            cancel: false,
            canceling: false,
            loading: true,
        });
        await this.fetchData();
    }

    private isAvailablePlan(planId: number): boolean {
        return !!this.state.plans.find((plan) => plan.id === planId)?.isAvailable;
    }

    private convertHtml(key: string, params: { [key: string]: string }, className?: string): React.ReactElement {
        return (
            <span
                className={classNames(className)}
                dangerouslySetInnerHTML={{__html: this.props.intl.formatMessage({id: key}, params).toString().split("|\n|").join("<br />")}}
            />
        );
    }

    private getRow(plan: ISubscriptionPlan): Array<string | React.ReactElement | undefined> {
        const params: { [key: string]: string } = {
            firstName: plan.activatedBy?.firstName || "",
            lastName: plan.activatedBy?.lastName || "",
            totalCapacity: plan.totalCapacity.toString(),
            totalUsers: plan.totalUsers.toString(),
            usedCapacity: plan.usedCapacity.toString(),
            capacityUsage: plan.capacityUsage.toString(),
            registeredUsers: plan.registeredUsers.toString(),
        };

        return [
            plan.name,
            plan.active ? (
                <div className={"admin-panel-subscription--column-text"}>
                    {plan.activationTime && (
                        <div>
                            <WedDate date={plan.activationTime} fullTime={true}/>
                        </div>
                    )}
                    {this.convertHtml("app.AdminPanelSubscription.columnActivated.statusActive", params)}
                </div>
            ) : (
                this.convertHtml("app.AdminPanelSubscription.columnActivated.statusInactive", params, "admin-panel-subscription--column-text")
            ),
            this.convertHtml(
                plan.active
                    ? "app.AdminPanelSubscription.columnDescription.statusActive"
                    : "app.AdminPanelSubscription.columnDescription.statusInactive",
                params,
                "admin-panel-subscription--column-text"
            ),
            <div className={"admin-panel-subscription--column-price"} key={3}>
                <FormattedMessage
                    id="app.AdminPanelSubscription.columnPrice.value"
                    values={{price: plan.price}}
                />
            </div>,
            <Radio
                key={4}
                disabled={this.inPendingCancellationState()}
                checked={this.state.selectedPlan === plan.id}
                onChange={() => {
                    this.setState({selectedPlan: plan.id});
                }}
            />,
        ];
    }

    private inPendingCancellationState(): boolean {
        return !!this.state.plans.find(plan => plan.active && plan.pendingCancellation);
    }

    private renderRevokePendingCancellation() {
        const plan = this.state.plans.find(plan => plan.active && plan.pendingCancellation);
        if (plan) {
            return <div className="admin-panel-subscription--revoke-pending-cancellation">
                <FormattedMessage
                    id="app.AdminPanelSubscription.revokePendingCancellation.content"
                    values={{
                        br: <br/>,
                        label: <b><FormattedMessage
                            id="app.AdminPanelSubscription.revokePendingCancellation.button"/></b>,
                        ...(plan.expiresAt ? {expiresAt: <WedDate date={plan.expiresAt}/>} : {}),
                    }}
                />
                <div className="admin-panel-subscription--revoke-pending-cancellation-button">
                    <Button
                        type="submit"
                        disabled={this.state.canceling}
                        onClick={async () => {
                            await this.revokeSubmit();
                        }}
                        className="button--more-padding"
                    >
                        <FormattedMessage id="app.AdminPanelSubscription.revokePendingCancellation.button"/>
                    </Button>
                </div>
            </div>
        }
    }

    private renderExtraAction() {
        if (this.state.plans.find(plan => plan.active && !plan.pendingCancellation)) {
            return <span className="admin-panel-subscription--extra-cancel-box">
                <a href="#" onClick={() => {
                    this.setState({cancel: true});
                }}><FormattedMessage id="app.AdminPanelSubscription.button.cancelPlanLink"/></a>
                &nbsp;
                <FormattedMessage id="app.AdminPanelSubscription.button.cancelPlanText"/>
            </span>
        }
    }

    render() {
        return (
            <AdminPanelContent className="admin-panel-subscription">
                {this.state.loading ? (
                    <LinearProgress/>
                ) : (
                    <div>
                        <Popup open={this.state.cancel} handleClose={() => {
                            this.setState({cancel: false})
                        }} maxWidth="sm">
                            <PopupContent>
                                <PopupHeader positionHeader={PositionTitle.CENTER}>
                                    <FormattedMessage id="app.AdminPanelSubscription.confirmCancelPopup.header"/>
                                </PopupHeader>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormattedMessage id="app.AdminPanelSubscription.confirmCancelPopup.content"
                                                          values={{br: <br/>}}/>
                                        <br/>
                                    </DialogContentText>
                                </DialogContent>
                                <PopupAction>
                                    <Button type="submit"
                                            onClick={async () => {
                                                await this.canceling();
                                            }}
                                            className="button--more-padding"
                                            disabled={this.state.canceling}
                                    >
                                        <FormattedMessage id="app.AdminPanelSubscription.confirmCancelPopup.confirm"/>
                                    </Button>
                                    <Button type="submit"
                                            onClick={() => {
                                                this.setState({cancel: false});
                                            }}
                                            className="button--more-padding"
                                    >
                                        <FormattedMessage id="app.standard.popup.button.cancel"/>
                                    </Button>
                                </PopupAction>
                            </PopupContent>
                        </Popup>
                        <Popup open={this.state.confirmChange} handleClose={() => {
                            this.setState({confirmChange: false})
                        }} maxWidth="sm">
                            <PopupContent>
                                <PopupHeader positionHeader={PositionTitle.CENTER}>
                                    <FormattedMessage id="app.AdminPanelSubscription.confirmChangePopup.header"/>
                                </PopupHeader>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        <FormattedMessage id="app.AdminPanelSubscription.confirmChangePopup.content"
                                                          values={{br: <br/>}}/>
                                        <br/>
                                    </DialogContentText>
                                </DialogContent>
                                <PopupAction>
                                    <Button type="submit"
                                            onClick={async () => {
                                                await this.confirming();
                                            }}
                                            className="button--more-padding"
                                            disabled={this.state.confirming}
                                    >
                                        <FormattedMessage id="app.AdminPanelSubscription.confirmChangePopup.confirm"/>
                                    </Button>
                                    <Button type="submit"
                                            onClick={() => {
                                                this.setState({confirmChange: false});
                                            }}
                                            className="button--more-padding"
                                    >
                                        <FormattedMessage id="app.standard.popup.button.cancel"/>
                                    </Button>
                                </PopupAction>
                            </PopupContent>
                        </Popup>
                        <AdminPanelTable headers={TABLE_HEADERS} centerIdx={[3, 4]}
                                         rows={this.state.plans.map((plan) => this.getRow(plan))}/>
                        <AdminPanelActionButton extraAction={this.renderExtraAction()}>
                            <Button
                                type="submit"
                                disabled={this.state.currentPlan === this.state.selectedPlan ||
                                    !this.isAvailablePlan(this.state.selectedPlan) ||
                                    this.inPendingCancellationState()}
                                onClick={() => {
                                    this.setState({confirmChange: true});
                                }}
                                className="button--more-padding"
                            >
                                <FormattedMessage id="app.AdminPanelSubscription.button.updatePlan"/>
                            </Button>
                        </AdminPanelActionButton>
                        {this.renderRevokePendingCancellation()}
                    </div>
                )}
            </AdminPanelContent>
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelSubscription));
