import * as React from "react";
import "./WedMainMenuContainer.scss";
import { WedMainMenu } from "../WedMainMenu/WedMainMenu";

interface PropsInterface {
    buttons?: React.ReactElement;
    menu?: React.ReactElement<WedMainMenu>;
    sidebar?: React.ReactElement;
}

export class WedMainMenuContainer extends React.Component<PropsInterface> {
    render() {
        return (
            <div className="wed-main-menu-container">
                {(this.props.menu || this.props.buttons) && (
                    <div className="wed-main-menu-container__content">
                        {this.props.menu && <div className="wed-main-menu-container__menu">{this.props.menu}</div>}
                        {this.props.buttons && <div className="wed-main-menu-container__buttons">{this.props.buttons}</div>}
                    </div>
                )}
                {this.props.sidebar && <div className="wed-main-menu-container__sidebar">{this.props.sidebar}</div>}
            </div>
        );
    }
}
