import React, { Component } from "react";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import ListProjectPage from "../../../components/template/ListProjectPage/ListProjectPage";
import LayoutPage from "../../../components/layout/LayoutPage/LayoutPage";

interface PropsInterface extends ProjectDetailLoaderProps {}

interface StateInterface {}

class ProjectListPage extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <LayoutPage>
                <ListProjectPage />
            </LayoutPage>
        );
    }
}

export default ProjectListPage;
