import React from "react";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { ProviderContext, withSnackbar } from "notistack";
import classNames from "classnames";
import { LinearProgress, TableBody, TableCell, TableRow } from "@material-ui/core";
import container from "../../../container";
import { ApplicationState } from "../../../store";
import { SelectedItemsProps, setSelectedItems, SetSelectedItemsProps } from "../../../store/SelectedItemsReducer";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import FileTile from "../../../components/tile/FileTile/FileTile";
import SharedHeader from "../../../components/header/ShareHeader/SharedHeader";
import { SizeType, WedIcon } from "../../../components/wed-icon/WedIcon";
import { WedIconDots, WedIconDownloadSaveUpload1, WedIconEye } from "../../../components/wed-icon/generated/WedIconSvg";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { FileTypeIcon } from "../../../components/file/FileTypeIcon/FileTypeIcon";
import WedDate from "../../../components/formatter/WedDate";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import { FileSize } from "../../../components/file/FileSize/FileSize";
import { WedTable } from "../../../components/wed-table/WedTable";
import { IShareCollection, IShareFile } from "../../../services/WedApi/Models/ShareCollection";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import { StatusActionButton } from "../../../components/status-action-button/StatusActionButton";
import { AppNotFoundItemError } from "../../../errors/error-app";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { downloadFile } from "../../../services/WedApi/Utils/DownloadFile";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { IFile } from "../../../services/WedApi/Models/File";
import ShareContentHeader from "../../../components/share/ShareContentHeader/ShareContentHeader";
import ShareLayoutPage from "../components/ShareLayoutPage/ShareLayoutPage";
import Breadcrumbs from "../../../components/breadcrumbs/Breadcrumbs";
import FilesFilterInterface from "../../../services/Filter/Files/FilesFilterInterface";
import "./ShareCollectionDetailPage.scss";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    showCollectionDotStatusColor,
} from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface BasePropsInterface {
    token: string;
    collectionId: number;
}

interface PropsInterface
    extends BasePropsInterface,
        WrappedComponentProps,
        ProviderContext,
        RouteComponentProps,
        SelectedItemsProps,
        SetSelectedItemsProps {
    isTableMode: boolean;
}

interface StateInterface {
    collection: IShareCollection | undefined;
    isLoading: boolean;
    searchText: string | undefined;
}

class ShareCollectionDetailPage extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            collection: undefined,
            searchText: undefined,
        };
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentWillUnmount() {
        this.props.setSelectedItems({ selectedItems: [] });
    }

    private async fetchData() {
        this.setState({ isLoading: true });
        const collection = await container.shareCollectionService.getCollectionDetails(this.props.token, this.props.collectionId);
        this.setState({ collection, isLoading: false });
    }

    openFile = (file: IFile) => {
        const { collectionId, token, history } = this.props;

        history.push(
            `${generatePath(RoutesEnum.SHARE_COLLECTION_FILE_MEDIA_GALLERY_DETAILS, {
                collectionId: collectionId,
                objectId: file.id,
            })}?token=${token}`
        );
    };

    handleSelectItem = (file: IShareFile) => {
        const { selectedItems } = this.props;

        if (selectedItems.length && selectedItems[0].id === file.id) {
            this.props.setSelectedItems({ selectedItems: [] });
        } else {
            this.props.setSelectedItems({
                selectedItems: [
                    {
                        id: file.id,
                        object: file,
                    },
                ],
            });
        }
    };

    isSelected = (id: number) => {
        const { selectedItems } = this.props;

        return selectedItems[0]?.id === id;
    };

    getActionButton = (file: IFile, breadcrumb = false) => {
        const { collection } = this.state;
        const { token, enqueueSnackbar, intl, isTableMode } = this.props;

        if (!collection) return undefined;

        const breadcrumbItem = breadcrumb
            ? {
                  id: `breadcrumb-item-id-${file.id}`,
                  title: file.name,
              }
            : undefined;

        return (
            <StatusActionButton
                selectedItems={[]}
                breadcrumbItem={breadcrumbItem}
                menuItems={[
                    {
                        items: [
                            {
                                label: this.props.intl.formatMessage({ id: "app.dropDownMenu.folder.preview" }),
                                icon: <WedIcon icon={<WedIconEye />} size={SizeType.SMALL} />,
                                click: () => {
                                    this.openFile(file);
                                },
                            },
                            {
                                label: this.props.intl.formatMessage({ id: "app.dropDownMenu.folder.download" }),
                                icon: <WedIcon icon={<WedIconDownloadSaveUpload1 />} size={SizeType.SMALL} />,
                                click: () => {
                                    enqueueSnackbar(intl.formatMessage({ id: `app.projectFilesActionButton.download` }), {
                                        variant: "success",
                                        autoHideDuration: 4000,
                                    });
                                    downloadFile(
                                        container.filesService.getSharedFileUrl(
                                            token,
                                            file.id,
                                            file.newestVersionId,
                                            collection.id,
                                            SizeImageEnum.ORG
                                        )
                                    );
                                },
                            },
                        ],
                    },
                ]}
                icon={isTableMode ? <WedIcon size={SizeType.SMALL} icon={<WedIconDots />} /> : <TileMenu sizeIcon={SizeType.NORMAL} />}
            />
        );
    };

    getBreadcrumb = () => {
        const { selectedItems } = this.props;
        const { collection } = this.state;
        const selectedItem = selectedItems.length ? selectedItems[0] : undefined;

        if (!(selectedItem && collection)) return null;

        const file = selectedItem.object as IShareFile;

        return <Breadcrumbs items={[{ title: collection.name }, { title: file.name }]} actionButtons={this.getActionButton(file, true)} />;
    };

    onFiltersChange = (filters: FilesFilterInterface) => {
        const { searchText } = filters;

        this.setState({ searchText });
    };

    filterBySearchText = () => {
        const { collection, searchText } = this.state;

        if (!collection || !searchText?.length) {
            return collection?.files || [];
        }

        return collection.files.filter((file) => file.name.toLowerCase().includes(searchText.toLowerCase()));
    };

    render() {
        const { isLoading, collection } = this.state;
        const { isTableMode, token, collectionId } = this.props;

        if (isLoading) {
            return <LinearProgress />;
        }

        if (!collection) {
            throw new AppNotFoundItemError("collection", collectionId);
        }

        const files = this.filterBySearchText();

        return (
            <ShareLayoutPage
                className="shared-collection"
                header={
                    <SharedHeader
                        token={token}
                        buttons={{ download: true, collectionNavigation: true }}
                        collection={collection}
                        refreshHandle={this.fetchData}
                    />
                }
            >
                <SidebarWrapper
                    shareSidebar={{
                        token: this.props.token,
                        loading: isLoading,
                        collection,
                        fileId: this.props.selectedItems.length === 1 ? this.props.selectedItems[0].id : undefined,
                    }}
                    disabled={false}
                    handleSidebarChanges={async () => {
                        await this.fetchData();
                    }}
                >
                    <div className="shared-collection__content">
                        <ShareContentHeader
                            companyName={collection.companyName}
                            collectionName={collection.name}
                            sharedBy={collection.createdBy}
                            breadcrumbs={this.getBreadcrumb()}
                            onFiltersChange={this.onFiltersChange}
                        />
                        {isTableMode ? (
                            <WedTable>
                                <TableBody>
                                    {files.map((file, i) => (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            key={`file${i}`}
                                            onDoubleClick={() => {
                                                this.openFile(file);
                                            }}
                                            className={classNames({ "table-row--active": false })}
                                        >
                                            <TableCell>
                                                <WedIcon
                                                    className="wed-icon-file-type-icon"
                                                    size={SizeType.SMALL}
                                                    icon={<FileTypeIcon extension={file.extension} fileName={file.name} />}
                                                />
                                            </TableCell>
                                            <TableCell>{file.name}</TableCell>
                                            <TableCell>{file.created && <WedDate date={file.created} />}</TableCell>
                                            <TableCell>{file.createdBy ? `${file.createdBy.firstName} ${file.createdBy.lastName}` : ""}</TableCell>
                                            {collection && (
                                                <TableCell>
                                                    {showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, file) && (
                                                        <div className="file-tile__dot-status">
                                                            <ItemDotStatus
                                                                color={getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, file)}
                                                            />
                                                        </div>
                                                    )}
                                                </TableCell>
                                            )}
                                            <TableCell>{file.extension}</TableCell>
                                            <TableCell>
                                                <FileSize size={file.size} />
                                            </TableCell>
                                            <TableCell align="right">{this.getActionButton(file)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </WedTable>
                        ) : (
                            <GridTiles>
                                {files.map((file) => (
                                    <FileTile
                                        onClick={() => {
                                            this.handleSelectItem(file);
                                        }}
                                        onDoubleClick={() => {
                                            this.openFile(file);
                                        }}
                                        key={file.id}
                                        url={container.filesService.getSharedFileUrl(
                                            token,
                                            file.id,
                                            file.newestVersionId,
                                            collection.id,
                                            SizeImageEnum.THUMB
                                        )}
                                        collection={collection}
                                        preview={isFileHtmlImageExtension(file.extension)}
                                        title={file.name}
                                        initialsAndOwner={[getInitialsAndOwner(file.createdBy)]}
                                        extension={file.extension}
                                        size={file.size}
                                        approvalRequired={file.approvalRequired}
                                        approvalFlag={file.approvalFlag}
                                        selected={this.isSelected(file.id)}
                                        actionButton={this.getActionButton(file)}
                                    />
                                ))}
                            </GridTiles>
                        )}
                    </div>
                </SidebarWrapper>
            </ShareLayoutPage>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    selectedItems: store.selectedItems.selectedItems || [],
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedItems: setSelectedItems(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSnackbar(injectIntl(ShareCollectionDetailPage))));
