import React from "react";
import classNames from "classnames";
import "./WedImage.scss";

interface PropsInterface {
    className?: string;
    src: string;
    alt?: string;
    radiusTopLeft?: boolean;
    radiusTopRight?: boolean;
    radiusBottomLeft?: boolean;
    radiusBottomRight?: boolean;
}

interface StateInterface {}

export class WedImage extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <span
                className={classNames("wed-image", this.props.className, {
                    "wed-image--radius-top-left": this.props.radiusTopLeft,
                    "wed-image--radius-top-right": this.props.radiusTopRight,
                    "wed-image--radius-bottom-left": this.props.radiusBottomLeft,
                    "wed-image--radius-bottom-right": this.props.radiusBottomRight,
                })}
                style={{ backgroundImage: `url(${this.props.src})` }}
            >
                <span className="wed-image--overlay" />
            </span>
        );
    }
}
