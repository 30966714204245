import React, { Component } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { connect } from "react-redux";
import { generatePath, NavLink } from "react-router-dom";
import { withSnackbar, ProviderContext } from "notistack";
import { ApplicationState } from "../../../store";
import { Button } from "@material-ui/core";
import MenuControlIcons from "../../main-menu/MenuControlIcons/MenuControlIcons";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconDoneCheck2, WedIconDownloadSaveUpload1 } from "../../wed-icon/generated/WedIconSvg";
import { WedMenuButtons } from "../../main-menu/WedMenuButtons/WedMenuButtons";
import { downloadFile } from "../../../services/WedApi/Utils/DownloadFile";
import container from "../../../container";
import WedImageLogo40x120 from "../../wed-image-logo/WedImageLogo40x120";
import ShareMakeDecisionPopup from "../../../pages/share/components/ShareMakeDecisionPopup/ShareMakeDecisionPopup";
import { WedImageGalleryDataProps } from "../../../store/WedImageGalleryReducer";
import { IShareCollection } from "../../../services/WedApi/Models/ShareCollection";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "../Header.scss";
import "./SharedHeader.scss";
import { isEditableShareCollection } from "../../../services/WedApi/Utils/IsEditableProject";

interface BasePropsInterface {
    disableChangeViewMode?: boolean;
    refreshHandle: () => void;
    token: string;
    buttons?: {
        makeDecision?: boolean;
        download?: boolean;
        collectionNavigation?: boolean;
    };
    collection?: IShareCollection;
    imageData?: WedImageGalleryDataProps;
}

interface PropsInterface extends BasePropsInterface, WrappedComponentProps, ProviderContext {}

interface StateInterface {
    makeDecisionPopup: boolean;
}

class SharedHeader extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            makeDecisionPopup: false,
        };
    }

    closeMakeDecision = (updated?: boolean) => {
        this.setState({ makeDecisionPopup: false });
        if (updated) {
            this.props.refreshHandle();
        }
    };

    showMakeDecision = () => {
        this.setState({ makeDecisionPopup: true });
    };

    canMakeDecision = () => {
        if (!this.props.imageData?.collectionId || !this.props.imageData?.objectId || !this.props.imageData?.versionId) {
            return false;
        }
        if (!this.props.collection?.userEmail || this.props.collection.id !== this.props.imageData.collectionId) {
            return false;
        }
        if (!isEditableShareCollection(this.props.collection)) {
            return false;
        }
        const object = this.props.collection.files.find((file) => file.id === this.props.imageData?.objectId);
        if (!object) {
            return false;
        }
        const version = object.versions.find((version) => version.id === this.props.imageData?.versionId);
        if (!version) {
            return false;
        }
        return this.props.collection?.userEmail ? !!version.proofs?.find((proof) => proof.email === this.props.collection?.userEmail) : false;
    };

    downloadCollection = async () => {
        const { enqueueSnackbar, intl, token, collection } = this.props;

        if (!collection?.id) {
            return;
        }

        enqueueSnackbar(intl.formatMessage({ id: `app.projectFilesActionButton.download` }), {
            variant: "success",
            autoHideDuration: 4000,
        });

        downloadFile(container.shareCollectionService.getDownloadCollectionUrl(token, collection.id));
    };

    render() {
        const { buttons, collection, token } = this.props;
        const hasImageData = collection?.userEmail;

        return (
            <header className="header header--shared">
                <div className="header__container">
                    <nav className="navbar">
                        <a className="navbar__icon">
                            <WedImageLogo40x120 />
                        </a>
                        <div className="main-menu">
                            <ul className="header--shared-nav-menu">
                                {buttons?.collectionNavigation && !!collection && (
                                    <li
                                        key="selected-collection"
                                        className="header--shared-nav-menu-item header--shared-nav-menu-item-current-collection"
                                    >
                                        <NavLink
                                            className="navbar__link"
                                            to={`${generatePath(RoutesEnum.SHARE_COLLECTION_FILES, { collectionId: collection.id })}?token=${token}`}
                                            activeClassName="active"
                                        >
                                            <span>{collection.name}</span>
                                        </NavLink>
                                    </li>
                                )}
                                <li key="all-collections" className="header--shared-nav-menu-item header--shared-nav-menu-item-all-collection">
                                    <NavLink
                                        className="navbar__link"
                                        to={`${generatePath(RoutesEnum.SHARE_COLLECTIONS)}?token=${token}`}
                                        activeClassName="active"
                                        isActive={(match) => !!match?.isExact}
                                    >
                                        <span>
                                            <FormattedMessage id={"app.ShareHeader.allCollections"} />
                                        </span>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <WedMenuButtons>
                            {buttons?.makeDecision && (
                                <Button
                                    disabled={!this.canMakeDecision()}
                                    onClick={this.showMakeDecision}
                                    type="button"
                                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconDoneCheck2 />} />}
                                >
                                    <FormattedMessage id="app.sharedCollection.makeDecision" />
                                </Button>
                            )}
                            {buttons?.download && (
                                <Button
                                    disabled={!collection}
                                    onClick={this.downloadCollection}
                                    type="button"
                                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconDownloadSaveUpload1 />} />}
                                >
                                    <FormattedMessage id="app.sharedCollection.downloadCollection" />
                                </Button>
                            )}
                        </WedMenuButtons>
                        <MenuControlIcons noDisplayTrash={true} disableChangeViewMode={this.props.disableChangeViewMode} />
                    </nav>
                </div>
                {this.state.makeDecisionPopup && hasImageData && (
                    <ShareMakeDecisionPopup
                        token={this.props.token}
                        collectionId={this.props.imageData?.collectionId as number}
                        objectId={this.props.imageData?.objectId as number}
                        versionId={this.props.imageData?.versionId as number}
                        handleClose={this.closeMakeDecision}
                    />
                )}
            </header>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface): BasePropsInterface => {
    return {
        ...props,
        imageData: store.wedImageGalleryData,
    };
};

export default connect(mapStateToProps)(injectIntl(withSnackbar(SharedHeader)));
