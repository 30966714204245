import React, { ErrorInfo } from "react";
import FrontApiError from "../../../services/WedApi/Error/FrontApiError";
import { AppNotFoundItemError } from "../../../errors/error-app";
import { RouteComponentProps, withRouter } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { noticeLogger } from "../../../services/WedApi/Utils/NoticeLogger";
import { getRouteByError } from "../../../services/WedApi/Utils/RedirectErrorTransform";

interface PropsInterface extends RouteComponentProps {
    error?: Error;
    info?: ErrorInfo;
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {
    error?: Error;
    info?: ErrorInfo;
}

class ErrorBoundary extends React.Component<PropsInterface> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private stackErrorTimer: any;

    readonly state: StateInterface = {
        error: undefined,
        info: undefined,
    };

    constructor(props: PropsInterface) {
        super(props);
        this.promiseRejectionHandler = this.promiseRejectionHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener("unhandledrejection", this.promiseRejectionHandler);
    }

    componentWillUnmount() {
        window.removeEventListener("unhandledrejection", this.promiseRejectionHandler);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    promiseRejectionHandler(rejection: any) {
        try {
            if (rejection.reason instanceof Error) {
                this.processError({
                    error: rejection.reason,
                    info: {
                        componentStack: (rejection.reason as Error).stack || "unknown",
                    },
                });
            } else if (rejection.reason instanceof FrontApiError) {
                this.processError({
                    error: rejection.reason,
                });
            } else {
                this.processError({
                    error: new Error("Rejection without error"),
                });
            }
        } catch (err) {
            this.processError({
                error: new Error("Rejection handle internal error"),
                info: {
                    componentStack: err.stack,
                },
            });
        }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        this.processError({ error, info });
    }

    private processError(data: StateInterface) {
        noticeLogger("HANDLE ERROR - processError", data);
        if (data?.error) {
            const redirectRoute = getRouteByError(data.error);
            if (data.error instanceof AppNotFoundItemError) {
                noticeLogger("HANDLE ERROR - redirect - 404");
                this.props.history.push(RoutesEnum.ERROR_PAGE_NOT_FOUND);
                return;
            }
            if (redirectRoute) {
                noticeLogger("HANDLE ERROR - redirect route", redirectRoute);
                this.props.history.push(redirectRoute);
                return;
            }
        }
        if (this.blockStackError()) {
            noticeLogger("HANDLE ERROR - blockStackError");
            return;
        }
        if (this.isRejectionWithoutError(data.error)) {
            noticeLogger("HANDLE ERROR - isRejectionWithoutError");
            return;
        }
        if (data.error) {
            noticeLogger("HANDLE ERROR - redirect - 500");
            this.props.history.push(RoutesEnum.ERROR_INTERNAL);
        }
    }

    private blockStackError(): boolean {
        const hasBlock = !!this.stackErrorTimer;

        try {
            clearTimeout(this.stackErrorTimer);
        } catch (err) {
            //
        }

        this.stackErrorTimer = setTimeout(() => {
            this.stackErrorTimer = undefined;
        }, 250);

        return hasBlock;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private isRejectionWithoutError(err: any): boolean {
        try {
            return err?.message === "Rejection without error";
        } catch (e) {
            return false;
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
