import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMainMenuContainer } from "../../main-menu/WedMainMenuContainer/WedMainMenuContainer";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { WedSubMenuContainer } from "../../main-menu/WedSubMenuContainer/WedSubMenuContainer";
import container from "../../../container";
import CollectionFilterInterface from "../../../services/Filter/Collection/CollectionFilterInterface";
import LinearProgress from "@material-ui/core/LinearProgress";
import { WedTable } from "../../wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ApplicationState } from "../../../store";
import { IUserTeamDetail, IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { connect } from "react-redux";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { ISelectItem, ISelectItemType } from "../../status-action-button/StatusActionButton";
import { IClient } from "../../../services/WedApi/Models/Client";
import { FilterFormClientList } from "../../filter-form/FilterFormClientList/FilterFormClientList";
import ClientFilterInterface from "../../../services/Filter/Client/ClientFilterInterface";
import classNames from "classnames";
import WedDate from "../../formatter/WedDate";
import Checkbox from "@material-ui/core/Checkbox";
import ActionTile from "../../../components/tile/ActionTile/ActionTile";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import ClientTile from "../../../components/tile/ClientTile/ClientTile";
import { Dispatch } from "redux";
import { PageInfoBoxProps, setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import ClientListButtons from "./ClientsPageHeader/ClientListButtons/ClientListButtons";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { WedMainMenu } from "../../main-menu/WedMainMenu/WedMainMenu";
import EditClientPopup from "../../../components/client/EditClientPopup/EditClientPopup";
import LayoutPage from "../../../components/layout/LayoutPage/LayoutPage";
import ClientListActionButton from "./ClientsPageHeader/ClientListActionButton/ClientListActionButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { SizeType } from "../../wed-icon/WedIcon";
import UserTeamMenu from "../../main-menu/UserTeamMenu/UserTeamMenu";
import { SidebarType } from "../../sidebar/Sidebar/Sidebar";
import "./ClientsPage.scss";

interface BasePropsInterface {
    contextUserTeam?: IUserTeamDetail;
}

interface PropsInterface extends SetPageInfoBoxTitlesActionProps, BasePropsInterface, WrappedComponentProps, RouteComponentProps {
    isTableMode: boolean;
}

interface StateInterface {
    filterClients: IClient[];
    selectedItems: ISelectItem[];
    isLoading: boolean;
    createClientPopup: boolean;
}

class ClientListPage extends React.Component<PropsInterface, StateInterface> {
    private filters: CollectionFilterInterface;
    private clients: IClient[];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            filterClients: [],
            selectedItems: [],
            isLoading: true,
            createClientPopup: false,
        };
        this.clients = [];
        this.filters = {};
        this.handleFiltersChange = this.handleFiltersChange.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.handleClickOpenCreateClient = this.handleClickOpenCreateClient.bind(this);
        this.refreshAfterChanges = this.refreshAfterChanges.bind(this);
    }

    async componentDidMount() {
        if (!this.props.contextUserTeam) {
            this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
        }

        await this.refreshAfterChanges();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.contextUserTeam?.id !== prevProps.contextUserTeam?.id) {
            this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
            await this.refreshAfterChanges();
        }
    }

    private getMenuTitleHeaders(): PageInfoBoxProps {
        const { selectedItems } = this.state;

        if (selectedItems.length === 1) {
            const client = container.clientFilterService.findById(this.clients, selectedItems[0].id);

            if (client) {
                return {
                    title: client.name,
                    subTitle: undefined,
                };
            }
        }

        return {
            title: this.props.intl.formatMessage({ id: "app.ClientListPage.header.allCustomerAccounts" }),
            subTitle: undefined,
        };
    }

    private async refreshAfterChanges() {
        this.setState({ isLoading: true });
        await this.reloadData();
    }

    private async reloadData() {
        this.clients = (await container.clientService.getList()).filter((customerClient) => {
            if (!this.props.contextUserTeam) {
                return true;
            }
            return this.props.contextUserTeam.customerAccountIDs.includes(customerClient.id);
        });
        this.filterData();
    }

    private handleFiltersChange(filters: ClientFilterInterface) {
        this.filters = filters;
        this.filterData();
    }

    private async handleClosePopup(changed?: boolean) {
        this.setState({
            createClientPopup: false,
        });
        if (changed === true) {
            await this.refreshAfterChanges();
        }
    }

    private handleClickOpenCreateClient() {
        this.setState({
            createClientPopup: true,
        });
    }

    private filterData() {
        this.setState({
            filterClients: container.clientFilterService.filter(this.clients, Object.assign({}, this.filters)),
            selectedItems: [],
            isLoading: false,
        });
    }

    private handleSelectItem(item: IClient) {
        let selectedItems = [...this.state.selectedItems];
        const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id == item.id);

        if (selectedItemIndex == -1) {
            const newItem: ISelectItem = {
                id: item.id,
                userIsOwner: item.userIsOwner,
                type: ISelectItemType.client,
                object: item,
            };
            selectedItems = [newItem];
        } else {
            selectedItems.splice(selectedItemIndex, 1);
        }

        this.setState({ selectedItems });
    }

    private handleOpenItem(item: IClient) {
        this.props.history.push(generatePath(RoutesEnum.CLIENTS_SUMMARY, { clientId: item.id }));
    }

    isSelected = (id: number) => {
        const { selectedItems } = this.state;

        return !!selectedItems.find((item) => item.id === id);
    };

    render() {
        const { isLoading, createClientPopup, selectedItems } = this.state;
        const firstSelected = selectedItems.length == 1 ? selectedItems[0] : undefined;

        return (
            <LayoutPage>
                <div className="client-list-page">
                    {createClientPopup && <EditClientPopup onClickClose={this.handleClosePopup} />}
                    <WedMainMenuContainer
                        menu={
                            this.props.contextUserTeam ? (
                                <UserTeamMenu userTeamId={this.props.contextUserTeam.id} activeTab="customer-accounts" />
                            ) : (
                                <WedMainMenu />
                            )
                        }
                        buttons={<ClientListButtons createClientClick={this.handleClickOpenCreateClient} selectedItems={selectedItems} />}
                        sidebar={<MenuControlIcons noDisplayTrash={true} />}
                    />
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <SidebarWrapper handleSidebarChanges={this.refreshAfterChanges} type={SidebarType.client} id={firstSelected?.id}>
                            <WedSubMenuContainer right={<FilterFormClientList onFiltersChange={this.handleFiltersChange} />} />
                            {this.props.isTableMode ? (
                                <WedTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.accountId" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.accountName" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.created" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.createdBy" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.accountTeam" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.contacts" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.ClientListPage.table.projects" />
                                            </TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.filterClients.map((client: IClient, i) => (
                                            <TableRow
                                                className={classNames({
                                                    "table-row--active": this.isSelected(client.id),
                                                })}
                                                hover
                                                role="checkbox"
                                                key={i}
                                                onClick={() => {
                                                    this.handleSelectItem(client);
                                                }}
                                                onDoubleClick={() => {
                                                    this.handleOpenItem(client);
                                                }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onChange={() => {
                                                            this.handleSelectItem(client);
                                                        }}
                                                        checked={this.isSelected(client.id)}
                                                    />
                                                </TableCell>
                                                <TableCell>{client.id}</TableCell>
                                                <TableCell>{client.name}</TableCell>
                                                <TableCell>{client.created && <WedDate date={client.created} />}</TableCell>
                                                <TableCell>
                                                    {client.createdBy ? `${client.createdBy.firstName} ${client.createdBy.lastName}` : ""}
                                                </TableCell>
                                                <TableCell>{client.membersIds.length + client.ownerIDs.length }</TableCell>
                                                <TableCell>{client.contactIDs.length}</TableCell>
                                                <TableCell>
                                                    <span className={`client-list-page--table-cell-active-project-${client.projectNumOfActive > 0 ? 'exists' : 'not-exists'}`}>
                                                        {client.projectNumOfActive}
                                                    </span>
                                                    /
                                                    <span className={`client-list-page--table-cell-closed-project-${client.projectNumOfClosed > 0 ? 'exists' : 'not-exists'}`}>
                                                        {client.projectNumOfClosed}
                                                    </span>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ClientListActionButton
                                                        selectedItems={[
                                                            {
                                                                id: client.id,
                                                                object: client,
                                                                userIsOwner: client.userIsOwner,
                                                                type: ISelectItemType.client,
                                                            },
                                                        ]}
                                                        icon={<MoreVertIcon />}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </WedTable>
                            ) : (
                                <GridTiles>
                                    {this.state.filterClients.map((client, i) => (
                                        <ClientTile
                                            key={i}
                                            numOfClosedProjects={client.projectNumOfClosed}
                                            numOfActiveProjects={client.projectNumOfActive}
                                            client={client.name}
                                            date={client.created}
                                            author={`${client.createdBy?.firstName} ${client.createdBy?.lastName}`}
                                            internalTeam={client.membersIds.length + client.ownerIDs.length}
                                            externalTeam={client.contactIDs.length}
                                            selected={this.isSelected(client.id)}
                                            onClick={() => {
                                                this.handleSelectItem(client);
                                            }}
                                            onDoubleClick={() => {
                                                this.handleOpenItem(client);
                                            }}
                                            actionButton={
                                                <ClientListActionButton
                                                    selectedItems={[
                                                        {
                                                            id: client.id,
                                                            object: client,
                                                            userIsOwner: client.userIsOwner,
                                                            type: ISelectItemType.client,
                                                        },
                                                    ]}
                                                    icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                                />
                                            }
                                        />
                                    ))}
                                    <FormattedMessage id="app.ClientListPage.grid.createClient">
                                        {(message) => (
                                            <ActionTile
                                                className="client-tile__create-client"
                                                onClick={this.handleClickOpenCreateClient}
                                                title={message as string}
                                            />
                                        )}
                                    </FormattedMessage>
                                </GridTiles>
                            )}
                        </SidebarWrapper>
                    )}
                </div>
            </LayoutPage>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ClientListPage)));
