import { generatePath } from "react-router-dom";
import { BreadcrumbItem } from "../../../../../breadcrumbs/Breadcrumbs";
import { PropsInterface } from "../../../FilesPage";
import FetchDataReturnInterface from "./FetchDataReturnInterface";
import OpenFileParamsInterface from "./ParamsInterface/OpenFileParamsInterface";
import OpenSectionParamsInterface from "./ParamsInterface/OpenSectionParamsInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import { LogicError } from "../../../../../../errors/error-app";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";
import { IFile } from "../../../../../../services/WedApi/Models/File";

export default abstract class UserTeamAbstractFilesData {
    protected abstract getFileTypeEnum(): FileTypeEnum;

    protected abstract getRoutesEnum(): RoutesEnum;

    protected abstract getFiles(userTeamId: number): Promise<IFile[]>;

    protected getBackRoutesHashParams(): string | undefined {
        return undefined;
    }

    async fetch(props: PropsInterface): Promise<FetchDataReturnInterface> {
        if (!props.userTeam?.id) {
            throw new LogicError(`UserTeamId is required for type: ${this.getFileTypeEnum()}`);
        }

        return {
            isLoading: false,
            directories: [],
            files: await this.getFiles(props.userTeam.id),
            collection: undefined,
            dir: undefined,
        };
    }

    getFolder(): Promise<FetchDataReturnInterface> {
        throw new LogicError(`Not supported method for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
    }

    openFile(params: OpenFileParamsInterface): string {
        const { userTeamId, objectId } = params;

        if (!userTeamId || !objectId) {
            throw new LogicError(`incomplete params for open file in type: ${this.getFileTypeEnum()}`);
        }

        return generatePath(this.getRoutesEnum(), {
            teamId: userTeamId,
            objectId,
        });
    }

    openSection(params: OpenSectionParamsInterface): string {
        const { userTeamId } = params;

        if (!userTeamId) {
            throw new LogicError(`incomplete params for open section in type: ${this.getFileTypeEnum()}`);
        }

        const hashParams = this.getBackRoutesHashParams();
        const url = generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE, {
            teamId: userTeamId,
        });

        if (hashParams) {
            return `${url}#${hashParams}`;
        }
        return url;
    }

    breadcrumbs(): BreadcrumbItem[] {
        return [];
    }

    didUpdate(): boolean {
        return false;
    }

    getRootDirName(): string {
        return "";
    }

    showTrash(): boolean {
        return false;
    }
}
