import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiCommentNote, IApiNote } from "./Models/Note";
import { IListResult } from "./Models/Common";

interface WedApiAddNoteOptions {
    tid?: number;
}

interface WedApiAddFileNoteOptions extends WedApiAddNoteOptions {
    x0?: number;
    y0?: number;
    width?: number;
    height?: number;
    annotation?: boolean;
}

export default class NoteActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async addProjectNote(pid: number, content: string, options?: WedApiAddNoteOptions): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/prs/addnote", {
            ...(options || []),
            pid,
            content,
        });

        return response.data;
    }

    public async getProjectNotes(pid: number): Promise<IListResult<IApiNote>> {
        const response = await this.get(`/xapi/prs/${pid}/privatenotes`);

        return response.data;
    }

    public async addFileNote(oid: number, vid: number, content: string, options?: WedApiAddFileNoteOptions): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/obj/privatenote/add", {
            ...(options || []),
            oid,
            vid,
            content,
        });

        return response.data;
    }

    public async getFileNotes(oid: number): Promise<IListResult<IApiNote>> {
        const response = await this.get(`/xapi/obj/${oid}/privatenotes`);

        return response.data;
    }

    public async addCollectionFileNote(oid: number, vid: number, cid: number, content: string, options?: WedApiAddFileNoteOptions): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/obj/publicnote/add", {
            ...(options || []),
            oid,
            vid,
            cid,
            content,
        });

        return response.data;
    }

    public async getCollectionFileNotes(oid: number, cid: number): Promise<IListResult<IApiNote>> {
        const response = await this.get(`/xapi/col/${cid}/publicnotes/${oid}`);

        return response.data;
    }

    public async addCollectionNote(cid: number, content: string, options?: WedApiAddNoteOptions): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/col/publicnote/add", {
            ...(options || []),
            cid,
            content,
        });

        return response.data;
    }

    public async getCollectionNotes(cid: number): Promise<IListResult<IApiNote>> {
        const response = await this.get(`/xapi/col/${cid}/publicnotes`);
        return response.data;
    }

    public async getCommentNotes(pid: number): Promise<IListResult<IApiCommentNote>> {
        const response = await this.get(`/xapi/prs/${pid}/comms`);
        return response.data;
    }
}
