import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface PropsInterface extends WrappedComponentProps {
    date: Date;
    fullTime?: boolean;
}

interface StateInterface {
}

class WedDate extends React.Component<PropsInterface, StateInterface> {
    private static twoDigit(val: number): string {
        if (val < 10) {
            return `0${val}`;
        }
        return `${val}`;
    }

    private convertMonth(month: number): string {
        switch (month) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
                return this.props.intl.formatMessage({ id: `app.date.month${month}` });
            default:
                return month.toString();
        }
    }

    private formatDate(date: Date): string {
        const dayOfTheMonth = date.getDate();
        const month = this.convertMonth(date.getMonth());
        const year = date.getFullYear();

        return `${WedDate.twoDigit(dayOfTheMonth)}.${month}.${year}`;
    }

    render() {
        const date = `${this.formatDate(this.props.date)}`;

        if (!this.props.fullTime) {
            return date;
        }

        return `${date}, ${("0" + this.props.date.getHours()).slice(-2)}:${("0" + this.props.date.getMinutes()).slice(-2)}`;
    }
}

export default injectIntl(WedDate);
