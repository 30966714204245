import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import UploadFile from "../UploadFile/UploadFile";

interface PropsInterface {
    open: boolean;
    uploaded: () => void;
    handleClose: () => void;
    dirId: number;
    objectId?: number;
}

interface StateInterface {}

export class UploadFilePopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose} maxWidth="md">
                <PopupContent>
                    <UploadFile
                        objectId={this.props.objectId}
                        dirId={this.props.dirId}
                        uploaded={this.props.uploaded}
                        close={this.props.handleClose}
                    />
                </PopupContent>
            </Popup>
        );
    }
}
