import { IOwner, IUser, IUserMyProfileRole } from "../Models/User";
import { IApiCurrentDirectory, IApiDirectory, IApiObject, IApiObjectVersion, IApiPath, IDirectory, IFile, IFileVersion, IPath } from "../Models/File";
import { convertStrToDate } from "../Utils/DateConverter";
import { IApiClient, IApiUserClient, IClientSimple } from "../Models/Client";
import { IClientContact } from "../Models/ClientContact";
import { getInitialsAndOwner } from "../Utils/Initials";
import { IApiProjectDetail, ProjectStatusEnum } from "../Models/Project";
import { ProjectService } from "./ProjectService";
import { IApiNote, INote } from "../Models/Note";
import { ApiResultError } from "../../../errors/error-app";

export enum ConvertApiFileContextEnum {
    projectWork = "projectWork",
    privatePortfolio = "privatePortfolio",
    library = "library",
    collection = "collection",
}

export function convertIApiCurrentDirectoryToIPath(path: IApiPath): IPath {
    return {
        id: path.NodeID,
        name: path.NodeName,
        nexts:
            path.nexts?.map((nextItem) => ({
                id: nextItem.id,
                name: nextItem.name,
            })) || [],
    };
}

export function convertIApiObjectVersionToIFileVersion(objectVersion: IApiObjectVersion, contacts: IOwner[]): IFileVersion {
    return {
        id: objectVersion.id,
        name: objectVersion.name,
        extension: objectVersion.type.extension,
        mimeType: objectVersion.type.mimeType,
        category: objectVersion.type.category,
        created: convertStrToDate(objectVersion.createdAt),
        approvalFlag: objectVersion.approvalFlag,
        versionNumber: objectVersion.VersionNumber,
        proofs: objectVersion.proofs
            ? objectVersion.proofs
                  .map((proof) => {
                      const ecContact = contacts.find((contact) => contact.id === proof.ecContactID);
                      return {
                          email: ecContact?.email || "",
                          firstName: ecContact?.firstName || "",
                          lastName: ecContact?.lastName || "",
                          flag: proof.flag,
                          created: convertStrToDate(proof.updatedAt),
                      };
                  })
                  .filter((proof) => {
                      return proof.email.length > 0;
                  })
            : [],
    };
}

export function convertIApiObjectToIFile(object: IApiObject, users: IOwner[], context: ConvertApiFileContextEnum, contacts?: IOwner[]): IFile {
    const removedBy = users.find((user) => user.id === object.moveToBinBy);

    return {
        id: object.id,
        name: object.name,
        description:
            context === ConvertApiFileContextEnum.library
                ? object.descinternal
                : context === ConvertApiFileContextEnum.collection
                ? object.descpublic
                : "",
        newestVersionId: object.newestVersionID,
        size: object.size,
        extension: object.type.extension,
        createdBy: users.find((user) => user.id === object.createdByID),
        created: object.createdAt ? convertStrToDate(object.createdAt) : undefined,
        removedBy: removedBy,
        removed: object.moveToBinTS ? convertStrToDate(object.moveToBinTS) : undefined,
        updatedBy: users.find((user) => user.id === object.updatedByID),
        updated: object.updatedAt ? convertStrToDate(object.updatedAt) : undefined,
        approvalRequired: object.approvalRequired,
        approvalFlag: object.approvalFlag,
        owners: object.ownerIDs
            .map((ownerId) => {
                return users.find((user) => user.id === ownerId);
            })
            .filter((owner) => !!owner) as IOwner[],
        userIsOwner: object.userIsOwner,
        tags: object.tags || [],
        versions: object.versions.map((version) => convertIApiObjectVersionToIFileVersion(version, contacts || [])),
        ownerIDs: object.ownerIDs,
    };
}

export function convertIApiFolderToIFolder(
    folder: IApiDirectory | IApiCurrentDirectory,
    users: IOwner[],
    options?: { withAccess?: boolean }
): IDirectory {
    const owner = users.find((user) => user.id === folder.ownerID);
    const removedBy = users.find((user) => user.id === folder.moveToBinBy);
    const topObjects = (folder as IApiDirectory).topObjects as IApiDirectory["topObjects"] | undefined;

    return {
        id: folder.id,
        name: folder.name,
        description: folder.desc,
        numberOfFiles: folder.numberOfFiles,
        createdBy: owner,
        created: folder.createTS ? convertStrToDate(folder.createTS) : undefined,
        removedBy: removedBy,
        removed: folder.moveToBinTS ? convertStrToDate(folder.moveToBinTS) : undefined,
        paths: folder.fpath?.map(convertIApiCurrentDirectoryToIPath) || [],
        writeAccessIds: folder.writeAccess ? folder.writeAccess : [],
        readAccessIds: folder.readAccess ? folder.readAccess : [],
        writeAccess: options?.withAccess
            ? (folder.writeAccess?.map((userId) => users.find((user) => user.id === userId)).filter((user) => !!user) as IUser[])
            : undefined,
        readAccess: options?.withAccess
            ? (folder.readAccess?.map((userId) => users.find((user) => user.id === userId)).filter((user) => !!user) as IUser[])
            : undefined,
        topObjects: topObjects
            ? topObjects.map((object) => {
                  return {
                      id: object.id,
                      name: object.name,
                      extension: object.type.extension,
                      newestVersionId: object.newestVersionID,
                  };
              })
            : [],
        userIsOwner: folder.userIsOwner,
        ownerID: folder.ownerID,
    };
}

export function convertIApiClientToIClientSimple(client: IApiClient): IClientSimple {
    return {
        id: client.id,
        name: client.name,
        created: client.createTS ? convertStrToDate(client.createTS) : undefined,
        membersIds: client.baUserIDs,
        contactIDs: client.contactIDs,
        ownerIDs: client.ownerIDs,
        userIsOwner: client.userIsOwner,
    };
}

export function convertIApiUserClientToIClientContact(contact: IApiUserClient, users: IOwner[], projects: IApiProjectDetail[]): IClientContact {
    const initialsUser = getInitialsAndOwner({
        firstName: contact.first_name,
        lastName: contact.last_name,
    });
    const clientProjects: IApiProjectDetail[] = projects.filter((project) => contact.projectIDs.includes(project.Pr.id));
    return {
        id: contact.id,
        name: initialsUser.owner,
        initials: initialsUser.initials,
        firstName: contact.first_name,
        lastName: contact.last_name,
        email: contact.email,
        position: contact.position,
        cCode: contact.cCode,
        phone: contact.phone,
        created: convertStrToDate(contact.createdAt),
        createdBy: users.find((user) => user.id === contact.createdBy),
        numOfActiveProjects: clientProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.active).length,
        numOfClosedProjects: clientProjects.filter((project) => ProjectService.getStatus(project.Pr.status) === ProjectStatusEnum.closed).length,
    };
}

export function convertIApiNoteToINote(note: IApiNote, users: IOwner[], contacts: IApiUserClient[]): INote {
    const contact = contacts.find((contact) => contact.id === note.ecAuthorID);
    return {
        id: note.id,
        parentNodeId: note.tid || undefined,
        created: note.createdAt ? convertStrToDate(note.createdAt) : undefined,
        createdBy: users.find((user) => user.id === note.baAuthorID),
        ecContact: contact ? convertClientContactToIOwner(contact) : undefined,
        content: note.content,
        versionId: note.vid || undefined,
        annotation:
            note.annotation && note.x0 !== undefined && note.y0 !== undefined && note.width !== undefined && note.height !== undefined
                ? {
                      x: note.x0,
                      y: note.y0,
                      width: note.width,
                      height: note.height,
                  }
                : undefined,
        items: [],
    };
}

export function convertClientContactToIOwner(contact: IApiUserClient): IOwner {
    return {
        id: contact.id,
        firstName: contact.first_name,
        lastName: contact.last_name,
        email: contact.email,
    };
}

export function listToTreeNotes(list: INote[]): INote[] {
    const roots: INote[] = [];
    // eslint-disable-next-line
    const map: any = {};
    let node, i;
    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        list[i].items = [];
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentNodeId) {
            if (!list[map[node.parentNodeId]]) {
                // eslint-disable-next-line
                console.error(
                    new ApiResultError(
                        `Recv Object Note id ${node.id} with property tid/parentNodeId ${node.parentNodeId}, but not found in result Note fot this file version in list`
                    )
                );
                continue;
            }
            list[map[node.parentNodeId]].items.push(node);
        } else {
            roots.push(node);
        }
    }
    return roots;
}

export function convertUserRoleId(roleId: number): IUserMyProfileRole {
    return roleId === 1 ? IUserMyProfileRole.ADMIN_USER : roleId === 2 ? IUserMyProfileRole.REGULAR_USER : IUserMyProfileRole.UNDEFINED;
}
