import React from "react";
import TagItem from "../TagItem/TagItem";
import "./TagList.scss";

interface PropsInterface {
    tags: string[];
    handleRemoveClick?: (tag: string) => void;
}

interface StateInterface {}

export default class TagList extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className="tag-list">
                {this.props.tags.map((tag, i) => (
                    <TagItem key={i} tag={tag} handleRemoveClick={this.props.handleRemoveClick} />
                ))}
            </div>
        );
    }
}
