import React from "react";
import classnames from "classnames";
import "./SidebarInformationDetails.scss";

export interface SidebarInformationDetail {
    label: string;
    values: {
        text: string | React.ReactNode;
        key?: string;
        labelFormat?: boolean;
    }[];
}

interface PropsInterface {
    data: SidebarInformationDetail[];
}

class SidebarInformationDetails extends React.Component<PropsInterface> {
    render() {
        const { data } = this.props;

        return (
            <div className="sidebar-information-details">
                {data.map((item) => (
                    <div key={item.label} className="sidebar-information-details__row">
                        <div className="sidebar-information-details__label">{item.label}</div>
                        <div className="sidebar-information-details__text">
                            {item.values.map((value) => {
                                const key: string = value.key || (value.text || "").toString();

                                return (
                                    <span
                                        key={key}
                                        className={classnames({
                                            "sidebar-information-details__label": value.labelFormat,
                                        })}
                                    >
                                        {value.text}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default SidebarInformationDetails;
