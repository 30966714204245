import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../popup/PopupAction/PopupAction";
import container from "../../../container";
import { ISelectItemType } from "../../status-action-button/StatusActionButton";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import { IDirectory, IFile } from "../../../services/WedApi/Models/File";
import { ICollection } from "../../../services/WedApi/Models/Collection";

interface StateInterface {
    fields: {
        description: string;
    };
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    id: number;
    type: ISelectItemType;
    object?: IFile | IDirectory;
    extra?: {
        collection?: ICollection;
    };
    handleClose: () => void;
    onSuccess: () => void;
}

class ChangeDescriptionPopup extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            description: {
                type: "string",
                maxLength: 500,
            },
        },
        required: [],
        additionalProperties: false,
        errorMessage: {
            properties: {
                description: this.props.intl.formatMessage({ id: "app.changeDescription.error.description" }),
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        props.object;
        this.state = {
            loading: false,
            fields: {
                description: props.object?.description || "",
            },
        };
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.object?.description !== prevProps.object?.description) {
            this.setState({ fields: { description: this.props.object?.description || "" } });
        }
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const {
            loading,
            fields: { description },
        } = this.state;

        if (loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            const { id, type } = this.props;

            switch (type) {
                case ISelectItemType.file:
                    await container.filesService.changeFileDescription(id, description, !!this.props.extra?.collection);
                    break;
                case ISelectItemType.directory:
                    await container.filesService.changeFolderDescription(id, description);
                    break;
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.changeDescription.success" }), { variant: "success" });
            this.props.onSuccess();
        } catch (e) {
            let keyTranslate;

            switch (e.code) {
                case FrontApiErrorCodeEnum.ERR_OBJECT_INVALID:
                    keyTranslate = "app.error.errObjectInvalid";
                    break;
                case FrontApiErrorCodeEnum.ERR_OBJECT_DESCRIPTION_INVALID:
                    keyTranslate = "app.error.errObjectDescriptionInvalid";
                    break;
                case FrontApiErrorCodeEnum.ERR_FOLDER_ID_INVALID:
                    keyTranslate = "app.error.errFolderIDInvalid";
                    break;
                case FrontApiErrorCodeEnum.ERR_FOLDER_DESCRIPTION_INVALID:
                    keyTranslate = "app.error.errFolderDescriptionInvalid";
                    break;
                default:
                    keyTranslate = "app.error.undefined";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }

        this.setState({ loading: false });
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    };

    render() {
        const { type } = this.props;

        return (
            <Popup open={true} handleClose={this.props.handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.changeDescription.${type}.title`} />
                    </PopupHeader>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="description"
                                label={<FormattedMessage id={"app.changeDescription.form.description"} />}
                                name="description"
                                autoComplete="off"
                                value={this.state.fields.description}
                                onChange={this.handleChange}
                                multiline
                                rows={5}
                            />
                        </div>
                        <PopupAction>
                            <Button type="submit">
                                <FormattedMessage id="app.standard.popup.button.save" />
                            </Button>
                        </PopupAction>
                    </form>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(ChangeDescriptionPopup));
