import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import ChangeDescriptionProject from "../ChangeDescriptionProject/ChangeDescriptionProject";
import { IProject } from "../../../../services/WedApi/Models/Project";

interface PropsInterface {
    open: boolean;
    handleClose: (isUpdate?: boolean) => void;
    project: IProject;
}

interface StateInterface {}

export class ChangeDescriptionProjectPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    private closePopup() {
        this.props.handleClose();
    }

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.closePopup} maxWidth="sm">
                <PopupContent>
                    <ChangeDescriptionProject close={this.props.handleClose} project={this.props.project} />
                </PopupContent>
            </Popup>
        );
    }
}
