import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import SidebarTitle from "../../../../components/sidebar/SidebarTitle/SidebarTitle";
import { WedIconItemsGroupElements, WedIconLock3 } from "../../../../components/wed-icon/generated/WedIconSvg";
import SidebarMenu from "../../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarInformationFiles from "../../../../components/sidebar/SidebarInformationFiles/SidebarInformationFiles";
import { CollectionShareIcon } from "../../../../components/collection/CollectionShareIcon";
import Collection from "../../../../domain/Collection";
import { SizeType } from "../../../../components/wed-icon/WedIcon";
import SidebarInformationDetails from "../../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import WedDate from "../../../../components/formatter/WedDate";
import ItemDotStatus from "../../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import SidebarDescription from "../../../../components/sidebar/SidebarDescription/SidebarDescription";
import SidebarInitials from "../../../../components/sidebar/SidebarInitials/SidebarInitials";
import { InitialsUserType } from "../../../../components/tile/components/Initials/Initials";
import { getInitialsAndOwner } from "../../../../services/WedApi/Utils/Initials";
import CommentBox from "../../../../components/comments/CommentBox/CommentBox";
import { INote, NoteObjectType } from "../../../../services/WedApi/Models/Note";
import SidebarActivities from "../../../../components/sidebar/SidebarActivities/SidebarActivities";
import { IShareCollection } from "../../../../services/WedApi/Models/ShareCollection";
import "../../../../components/sidebar/Sidebar/Sidebar.scss";
import { IActivities } from "../../../../services/WedApi/Models/Activities";
import { CollectionDotStatusColorItemType, getCollectionDotStatusColor } from "../../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends WrappedComponentProps {
    token: string;
    collection: IShareCollection;
    activities: IActivities[];
    disabled?: boolean;
}

interface StateInterface {}

class CollectionSidebarShare extends Component<PropsInterface, StateInterface> {
    render() {
        const { intl, token, collection, disabled } = this.props;

        return (
            <div className="collection-sidebar sidebar--container">
                <SidebarTitle title={collection.name} icon={collection?.locked ? <WedIconLock3 /> : undefined} />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarInformationFiles
                                statusWedIcon={<CollectionShareIcon collection={new Collection(collection)} size={SizeType.LARGE} />}
                                icon={<WedIconItemsGroupElements />}
                                filesCount={collection.files.length}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: collection.created ? <WedDate date={collection.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${collection.createdBy?.firstName} ${collection.createdBy?.lastName}` },
                                        ],
                                    },
                                    ...(collection?.updatedBy && collection.updated
                                        ? [
                                              {
                                                  label: intl.formatMessage({ id: "app.sidebar.details.updated" }),
                                                  values: [
                                                      { text: <WedDate date={collection.updated} /> },
                                                      {
                                                          text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                          labelFormat: true,
                                                      },
                                                      { text: `${collection.updatedBy.firstName} ${collection.updatedBy.lastName}` },
                                                  ],
                                              },
                                          ]
                                        : []),
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.documents" }),
                                        values: [{ text: collection.files.length.toString() }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.approval" }),
                                        values: [
                                            {
                                                text: (
                                                    <ItemDotStatus
                                                        color={getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION, collection)}
                                                    />
                                                ),
                                            },
                                        ],
                                    },
                                ]}
                            />
                            <SidebarDescription allowToAdd={false} description={collection.description} />
                            <SidebarInitials
                                title={intl.formatMessage({ id: "app.sidebar.sharedWith.title" })}
                                items={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.shared.baUsers" }),
                                        userType: InitialsUserType.INTERNAL,
                                        data: (collection.baUsersItems || []).map((user) => {
                                            const userInitialName = getInitialsAndOwner(user);
                                            return {
                                                initials: userInitialName.initials,
                                                fullName: userInitialName.owner,
                                            };
                                        }),
                                    },

                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.shared.sharedTo" }),
                                        userType: InitialsUserType.EXTERNAL,
                                        data: (collection.sharedToItems || []).map((user) => {
                                            const userInitialName = getInitialsAndOwner(user);
                                            return {
                                                initials: userInitialName.initials,
                                                fullName: userInitialName.owner,
                                            };
                                        }),
                                    },
                                ]}
                            />
                            <CommentBox
                                token={token}
                                disabled={disabled}
                                object={{ id: collection.id, type: NoteObjectType.collection }}
                                notes={this.props.collection.publicNotes as INote[]}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={this.props.activities} />
                        </>,
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(CollectionSidebarShare);
