import NotifsActionApiService from "../NotifsActionApiService";
import { IApiNotifsItem, INotifsItem } from "../Models/Notifs";
import { convertStrToDate } from "../Utils/DateConverter";

export class NotifsService {
    constructor(private readonly notifsActionApiService: NotifsActionApiService) {}

    private static convertIApiNotifsItemToINotifsItem(item: IApiNotifsItem): INotifsItem {
        return {
            id: item.id,
            uid: item.uid,
            typeId: item.typeID,
            created: convertStrToDate(item.createdAt),
            byUserId: item.byUserID,
            byUserName: item.byUserName,
            byUserEmail: item.byUserEmail,
            privateNoteId: item.privateNoteID,
            publicNoteId: item.publicNoteID,
            collectionId: item.cid,
            objectId: item.oid,
            versionNum: item.vnum,
            projectId: item.pid,
            byEcContactName: item.byEcContactName,
            byEcContactEmail: item.byEcContactEmail,
            projectName: item.projectName,
            collectionName: item.collectionName,
            objectName: item.objectName,
            folderName: item.folderName,
        };
    }

    public async getList(): Promise<INotifsItem[]> {
        const items = (await this.notifsActionApiService.getList()).items;
        return items.map((item) => NotifsService.convertIApiNotifsItemToINotifsItem(item));
    }

    public async removeItem(id: number): Promise<void> {
        await this.notifsActionApiService.removeItem(id);
    }

    public async removeAll(): Promise<void> {
        await this.notifsActionApiService.removeAll();
    }
}
