import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";

interface PropsInterface {}

interface StateInterface {}

class Redirect404 extends Component<PropsInterface, StateInterface> {
    render() {
        return <Redirect to={RoutesEnum.ERROR_PAGE_NOT_FOUND} />;
    }
}

export default Redirect404;
