import React, { Component } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { generatePath, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { IApiSearchResultType, ISearchResult, ISearchResultType } from "../../../services/WedApi/Models/Search";
import container from "../../../container";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import SidebarScrollbars from "../../sidebar/SidebarScrollbars/SidebarScrollbars";
import PopupAction from "../../popup/PopupAction/PopupAction";
import { Button } from "@material-ui/core";
import { LogicError } from "../../../errors/error-app";
import classNames from "classnames";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedImage } from "../../wed-image/WedImage";
import {
    WedIconFolderBlank,
    WedIconGroupUser1,
    WedIconInteractionTeamworkGroup,
    WedIconItemsGroupElements,
    WedIconSingleUserSquare,
    WedIconUserProfile8,
} from "../../wed-icon/generated/WedIconSvg";
import LabelTitleWrapper from "../../tile/components/LabelTitleWrapper/LabelTitleWrapper";
import WedDate from "../../formatter/WedDate";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "./SearchResult.scss";

interface PropsInterface extends WrappedComponentProps, RouteComponentProps {
    handleClose: (linkClicked?: boolean) => void;
    query: string;
}

interface StateInterface {
    items?: ISearchResult[];
}

class SearchResult extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.query !== prevProps.query) {
            this.setState({ items: undefined });
            await this.fetchData();
        }
    }

    private async fetchData(): Promise<void> {
        const items = await container.searchService.getSearchResult(this.props.query);
        this.setState({ items });
    }

    private resetSearchPopup() {
        this.props.handleClose(true);
    }

    private renderItem(item: ISearchResult): React.ReactElement {
        const imageUrl: string | undefined = this.getTileImageUrl(item);
        const imageIcon: React.ReactElement | undefined = this.getTileImageIcon(item);
        const titleLabels: Array<{ label: string; title: string }> = this.getTileLabelTitles(item);
        const titleExtra: string | undefined = this.getTitleLabelExtra(item);
        const link: string = this.getTileLink(item);

        return (
            <div
                onDoubleClick={() => {
                    this.resetSearchPopup();
                    this.props.history.push(link);
                }}
                className={classNames("search-result--item", "tile", this.getTileItemClassName(item.type))}
            >
                <div className="search-result--item-image">
                    {imageUrl ? (
                        <WedImage src={imageUrl} radiusBottomLeft={true} radiusTopLeft={true} radiusTopRight={true} radiusBottomRight={true} />
                    ) : imageIcon ? (
                        <div className="search-result--image-icon-container tile--content-overlay">{imageIcon}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="search-result--title">
                    {titleLabels.map((titleLabel, i) => {
                        return <LabelTitleWrapper key={i} label={this.props.intl.formatMessage({ id: titleLabel.label })} title={titleLabel.title} />;
                    })}
                    {titleExtra && <span className="search-result--title-extra-label">{titleExtra}</span>}
                </div>
                <div className="search-result--description">
                    <div className="search-result--description-texts">{item.value.desc}</div>
                </div>
                <div className="search-result--actions">
                    <div className="search-result--actions-date">{item.value.date && <WedDate date={item.value.date} />}</div>
                    <div className="search-result--actions-link">
                        <Link
                            to={link}
                            onClick={() => {
                                this.resetSearchPopup();
                            }}
                        >
                            <FormattedMessage id="app.SearchResult.linkView" />
                            &nbsp;
                            <span>{item.value.name}</span>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    private getTileLink(item: ISearchResult): string {
        switch (item.type) {
            case IApiSearchResultType.PROJECT:
                return generatePath(RoutesEnum.PROJECTS_DETAIL, { projectId: item.value.id });
            case IApiSearchResultType.PROJECT_FILE:
                return (
                    generatePath(RoutesEnum.FILES_MEDIA_GALLERY_DETAILS, {
                        projectId: item.value.projectID,
                        objectId: item.value.id,
                    }) + (item.value.dirId ? `?dir=${item.value.dirId}` : "")
                );
            case IApiSearchResultType.PROJECT_FOLDER:
                return generatePath(RoutesEnum.FILES, { projectId: item.value.projectID }) + `?dir=${item.value.id}`;
            case IApiSearchResultType.PROJECT_COLLECTION:
                return generatePath(RoutesEnum.COLLECTION_FILES, {
                    projectId: item.value.projectID,
                    collectionId: item.value.id,
                });
            case IApiSearchResultType.MY_LIBRARY_FILE:
                return (
                    generatePath(RoutesEnum.MY_LIBRARY_MEDIA_GALLERY_DETAILS, { objectId: item.value.id }) +
                    (item.value.dirId ? `?dir=${item.value.dirId}` : "")
                );
            case IApiSearchResultType.MY_LIBRARY_FOLDER:
                return generatePath(RoutesEnum.MY_LIBRARY_FILES, {}) + `?dir=${item.value.id}`;
            case IApiSearchResultType.MY_LIBRARY_COLLECTION:
                return generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS, {});
            case IApiSearchResultType.CUSTOMER_ACCOUNT:
                return generatePath(RoutesEnum.CLIENTS_DETAIL, { clientId: item.value.id });
            case IApiSearchResultType.CUSTOMER_CONTACT:
                return generatePath(RoutesEnum.CLIENTS_CONTACTS_DETAIL, {
                    clientId: item.value.customerAccountID,
                    contactId: item.value.id,
                });
            case IApiSearchResultType.USER:
                return generatePath(RoutesEnum.USER_TEAM_DETAILS, { teamId: item.value.id });
            default:
                throw new LogicError(`Not supported create link for item search result: ${item.type}`);
        }
    }

    private getTitleLabelExtra(item: ISearchResult): string | undefined {
        switch (item.type) {
            case IApiSearchResultType.CUSTOMER_CONTACT:
                return item.value.customerAccountName;
            default:
                return undefined;
        }
    }

    private getTileLabelTitles(item: ISearchResult): Array<{ label: string; title: string }> {
        switch (item.type) {
            case IApiSearchResultType.PROJECT:
                return [{ label: "app.SearchResult.title.project", title: item.value.name }];
            case IApiSearchResultType.PROJECT_FILE:
                return [
                    { label: "app.SearchResult.title.projectFile", title: item.value.name },
                    { label: "app.SearchResult.subTitle.projectName", title: item.value.projectName },
                ];
            case IApiSearchResultType.PROJECT_FOLDER:
                return [
                    { label: "app.SearchResult.title.projectFolder", title: item.value.name },
                    { label: "app.SearchResult.subTitle.projectName", title: item.value.projectName },
                ];
            case IApiSearchResultType.PROJECT_COLLECTION:
                return [
                    { label: "app.SearchResult.title.projectCollection", title: item.value.name },
                    { label: "app.SearchResult.subTitle.projectName", title: item.value.projectName },
                ];
            case IApiSearchResultType.MY_LIBRARY_FILE:
                return [{ label: "app.SearchResult.title.myLibraryFile", title: item.value.name }];
            case IApiSearchResultType.MY_LIBRARY_FOLDER:
                return [{ label: "app.SearchResult.title.myLibraryFolder", title: item.value.name }];
            case IApiSearchResultType.MY_LIBRARY_COLLECTION:
                return [{ label: "app.SearchResult.title.myLibraryCollection", title: item.value.name }];
            case IApiSearchResultType.CUSTOMER_ACCOUNT:
                return [{ label: "app.SearchResult.title.customerAccount", title: item.value.name }];
            case IApiSearchResultType.CUSTOMER_CONTACT:
                return [
                    { label: "app.SearchResult.title.customerContact", title: item.value.name },
                    { label: "app.SearchResult.subTitle.email", title: item.value.email },
                ];
            case IApiSearchResultType.USER:
                return [
                    { label: "app.SearchResult.title.user", title: item.value.name },
                    { label: "app.SearchResult.subTitle.email", title: item.value.email },
                ];
            default:
                return [];
        }
    }

    private getTileImageIcon(item: ISearchResult): React.ReactElement | undefined {
        switch (item.type) {
            case IApiSearchResultType.PROJECT:
                return <WedIcon icon={<WedIconInteractionTeamworkGroup />} />;
            case IApiSearchResultType.PROJECT_FILE:
            case IApiSearchResultType.MY_LIBRARY_FILE:
                return <WedIcon icon={<FileTypeIcon extension={item.value.type?.extension} fileName={item.value.name} />} />;
            case IApiSearchResultType.PROJECT_FOLDER:
            case IApiSearchResultType.MY_LIBRARY_FOLDER:
                return <WedIcon icon={<WedIconFolderBlank />} />;
            case IApiSearchResultType.PROJECT_COLLECTION:
            case IApiSearchResultType.MY_LIBRARY_COLLECTION:
                return <WedIcon icon={<WedIconItemsGroupElements />} />;
            case IApiSearchResultType.CUSTOMER_ACCOUNT:
                return <WedIcon icon={<WedIconGroupUser1 />} />;
            case IApiSearchResultType.CUSTOMER_CONTACT:
                return <WedIcon icon={<WedIconUserProfile8 />} />;
            case IApiSearchResultType.USER:
                return <WedIcon icon={<WedIconSingleUserSquare />} />;
            default:
                return undefined;
        }
    }

    private getTileImageUrl(item: ISearchResult): string | undefined {
        switch (item.type) {
            case IApiSearchResultType.PROJECT_FILE:
            case IApiSearchResultType.MY_LIBRARY_FILE:
                return isFileHtmlImageExtension(item.value.type?.extension)
                    ? container.filesService.getFileUrl(item.value.id, item.value.vid, { size: SizeImageEnum.THUMB })
                    : undefined;
            default:
                return undefined;
        }
    }

    private getTileItemClassName(type: ISearchResultType): string {
        switch (type) {
            case IApiSearchResultType.PROJECT:
                return "search-result--item-project";
            case IApiSearchResultType.PROJECT_FOLDER:
                return "search-result--item-project-folder";
            case IApiSearchResultType.PROJECT_FILE:
                return "search-result--item-project-file";
            case IApiSearchResultType.PROJECT_COLLECTION:
                return "search-result--item-project-collection";
            case IApiSearchResultType.MY_LIBRARY_FOLDER:
                return "search-result--item-my-library-folder";
            case IApiSearchResultType.MY_LIBRARY_FILE:
                return "search-result--item-my-library-file";
            case IApiSearchResultType.MY_LIBRARY_COLLECTION:
                return "search-result--item-my-library-collection";
            case IApiSearchResultType.CUSTOMER_ACCOUNT:
                return "search-result--item-customer-account";
            case IApiSearchResultType.CUSTOMER_CONTACT:
                return "search-result--item-customer-contact";
            case IApiSearchResultType.USER:
                return "search-result--item-user";
            default:
                throw new LogicError(`Not supported search result item type ${type}`);
        }
    }

    render() {
        return (
            <div className="search-result">
                <PopupHeader positionHeader={PositionTitle.LEFT}>
                    <FormattedMessage id="app.SearchResult.header.title" />
                </PopupHeader>
                <div className="popup-content-margin search-result--container">
                    {this.state.items === undefined ? (
                        <LinearProgress />
                    ) : (
                        <SidebarScrollbars>
                            <div className="search-result--container-body">
                                {this.state.items.length === 0 ? (
                                    <div className="search-result--container-no-results">
                                        <FormattedMessage id={"app.SearchResult.noResults"} values={{ query: this.props.query }} />
                                    </div>
                                ) : (
                                    <div className="search-result--container-results">
                                        {this.state.items.map((item, i) => {
                                            return (
                                                <div key={i} className="search-result--item-container">
                                                    {this.renderItem(item)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        </SidebarScrollbars>
                    )}
                </div>
                <PopupAction>
                    <Button
                        type="submit"
                        onClick={() => {
                            this.props.handleClose();
                        }}
                    >
                        <FormattedMessage id="app.SearchResult.action.closeButton" />
                    </Button>
                </PopupAction>
            </div>
        );
    }
}

export default withRouter(injectIntl(SearchResult));
