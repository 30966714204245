import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IListResult } from "./Models/Common";
import {
    IApiCollectionActivities,
    IApiCollectionFileActivities,
    IApiFileActivities,
    IApiFolderActivities,
    IApiProjectActivities,
} from "./Models/Activities";

export default class ActivitiesActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getProject(projectId: number): Promise<IListResult<IApiProjectActivities>> {
        const response = await this.get(`/xapi/prs/${projectId}/activities`);

        return response.data;
    }

    public async getCollection(collectionId: number): Promise<IListResult<IApiCollectionActivities>> {
        const response = await this.get(`/xapi/col/${collectionId}/activities`);

        return response.data;
    }

    public async getCollectionFile(objectId: number, collectionId: number): Promise<IListResult<IApiCollectionFileActivities>> {
        const response = await this.get(`/xapi/col/${collectionId}/activities/${objectId}`);

        return response.data;
    }

    public async getFolder(folderId: number): Promise<IListResult<IApiFolderActivities>> {
        const response = await this.get(`/xapi/lib/${folderId}/activities`);

        return response.data;
    }

    public async getFile(fileId: number): Promise<IListResult<IApiFileActivities>> {
        const response = await this.get(`/xapi/obj/${fileId}/activities`);

        return response.data;
    }
}
