import React from "react";
import { FormattedMessage } from "react-intl";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import WedDate from "../../formatter/WedDate";
import Initials from "../../tile/components/Initials/Initials";
import { getInitialsByName } from "../../../services/WedApi/Utils/Initials";
import "./SidebarActivities.scss";

interface PropsInterface {
    activities: IActivities[];
}

interface StateInterface {}

interface DateGroupActivities {
    dateKey: string;
    activities: IActivities[];
}

class SidebarActivities extends React.Component<PropsInterface, StateInterface> {
    private groupByDate(activities: IActivities[]): DateGroupActivities[] {
        const mapData: { [key: string]: IActivities[] } = {};
        const now = new Date();
        const today = `${now.getFullYear()}${now.getMonth()}${now.getDate()}}`;
        const thisMonth = `${now.getFullYear()}${now.getMonth()}`;

        activities.forEach((activity) => {
            let currentLabel = "app.SidebarActivities.date.older";
            if (
                activity.created.getTime() >= now.getTime() ||
                `${activity.created.getFullYear()}${activity.created.getMonth()}${activity.created.getDate()}}` === today
            ) {
                currentLabel = "app.SidebarActivities.date.today";
            } else if (`${activity.created.getFullYear()}${activity.created.getMonth()}` === thisMonth) {
                currentLabel = "app.SidebarActivities.date.lastMonth";
            }
            if (!mapData[currentLabel]) {
                mapData[currentLabel] = [];
            }
            mapData[currentLabel].push(activity);
        });

        return Object.keys(mapData).map((dateKey) => {
            return {
                dateKey: dateKey,
                activities: mapData[dateKey],
            };
        });
    }

    render() {
        const { activities } = this.props;

        return (
            <div className="sidebar-activities">
                {this.groupByDate(activities).map((dateActivities, i) => (
                    <div key={i} className="sidebar-activities--date-group">
                        <div className="sidebar-activities--date-header">
                            <FormattedMessage id={dateActivities.dateKey} />
                        </div>
                        <div className="sidebar-activities--date-content">
                            {dateActivities.activities.map((activity, j) => {
                                // eslint-disable-next-line
                                const initials = activity?.message?.params?.byUserName
                                    ? getInitialsByName(activity.message.params.byUserName)
                                    : activity?.message?.params?.byEcContactName
                                    ? getInitialsByName(activity.message.params.byEcContactName)
                                    : undefined;

                                return (
                                    <div key={j} className="sidebar-activities--date-activity-item">
                                        <div className="sidebar-activities--date-activity-item-created">
                                            <WedDate date={activity.created} fullTime={true}/>
                                        </div>
                                        <div className="sidebar-activities--date-activity-item-message">
                                            <div className="sidebar-activities--date-activity-item-message-author">
                                                {initials && <Initials title={initials.initials} fullTitle={initials.owner} />}
                                            </div>
                                            <div className="sidebar-activities--date-activity-item-message-body">
                                                <FormattedMessage id={activity.message.key} values={activity.message.params} />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default SidebarActivities;
