import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import { IFile } from "../../../services/WedApi/Models/File";
import FileTile from "../../../components/tile/FileTile/FileTile";
import container from "../../../container";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { SizeType } from "../../../components/wed-icon/WedIcon";
import React, { Component } from "react";
import UserFileListActionButtons from "./UserFileListActionButtons/UserFileListActionButtons";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { LogicError } from "../../../errors/error-app";
import { downloadFile } from "../../../services/WedApi/Utils/DownloadFile";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";

interface BasePropsInterface {
    userTeamId: number;
    isMe?: boolean;
    files: IFile[];
    fileType: FileTypeEnum.TEAM_USER_PROJECT_WORK | FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO;
    refreshPage?: () => void;
}

interface PropsInterface extends BasePropsInterface, RouteComponentProps {}

interface StateInterface {}

class UserTeamUserFileList extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.onOpenFile = this.onOpenFile.bind(this);
        this.onDownloadFile = this.onDownloadFile.bind(this);
    }

    private onOpenFile(file: IFile) {
        switch (this.props.fileType) {
            case FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO:
                this.props.history.push(
                    generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PRIVATE_PORTFOLIO, {
                        teamId: this.props.userTeamId,
                        objectId: file.id,
                    })
                );
                break;
            case FileTypeEnum.TEAM_USER_PROJECT_WORK:
                this.props.history.push(
                    generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PROJECT_WORK, {
                        teamId: this.props.userTeamId,
                        objectId: file.id,
                    })
                );
                break;
            default:
                throw new LogicError(`This component not supported fileType: ${this.props.fileType}`);
        }
    }

    private onDownloadFile(file: IFile) {
        switch (this.props.fileType) {
            case FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO:
                downloadFile(
                    container.filesService.getFileUrl(file.id, file.newestVersionId, {
                        size: SizeImageEnum.ORG,
                        fileType: FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO,
                        userId: this.props.userTeamId,
                    })
                );
                break;
            case FileTypeEnum.TEAM_USER_PROJECT_WORK:
                downloadFile(
                    container.filesService.getFileUrl(file.id, file.newestVersionId, {
                        size: SizeImageEnum.ORG,
                        fileType: FileTypeEnum.TEAM_USER_PROJECT_WORK,
                    })
                );
                break;
            default:
                throw new LogicError(`This component not supported fileType: ${this.props.fileType}`);
        }
    }

    render() {
        return (
            <GridTiles>
                {this.props.files.map((file: IFile, i) => (
                    <FileTile
                        key={i}
                        title={file.name}
                        url={container.filesService.getFileUrl(file.id, file.newestVersionId, {
                            size: SizeImageEnum.THUMB,
                            fileType: this.props.fileType,
                            userId: this.props.userTeamId,
                        })}
                        preview={isFileHtmlImageExtension(file.extension)}
                        initialsAndOwner={file.owners.map((owner) => getInitialsAndOwner(owner))}
                        size={file.size}
                        date={file.created}
                        extension={file.extension}
                        approvalRequired={file.approvalRequired}
                        approvalFlag={file.approvalFlag}
                        selected={false}
                        onDoubleClick={() => {
                            this.onOpenFile(file);
                        }}
                        actionButton={
                            <UserFileListActionButtons
                                icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                selectedItems={[
                                    {
                                        id: file.id,
                                        type: ISelectItemType.file,
                                        userIsOwner: file.userIsOwner,
                                        object: file,
                                    },
                                ]}
                                isMe={this.props.isMe}
                                onOpenFile={this.onOpenFile}
                                onDownloadFile={this.onDownloadFile}
                                refreshPage={this.props.refreshPage}
                            />
                        }
                    />
                ))}
            </GridTiles>
        );
    }
}

export default withRouter(UserTeamUserFileList);
