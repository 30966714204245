import PaymentActionApiService from "../PaymentActionApiService";
import { redirectPage } from "../Utils/RedirectPage";

export class PaymentService {
    constructor(private readonly paymentActionApiService: PaymentActionApiService) {}

    public async goToUpdatePaymentMethodUrl(): Promise<void> {
        const data = await this.paymentActionApiService.getUpdatePaymentMethodUrl();
        await redirectPage(data.redirectUrl);
    }
}
