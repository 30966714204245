import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { WedIconAddCirlcePlus } from "../../../../components/wed-icon/generated/WedIconSvg";
import { SizeType, WedIcon } from "../../../../components/wed-icon/WedIcon";
import { WedMenuButtons } from "../../../../components/main-menu/WedMenuButtons/WedMenuButtons";

interface PropsInterface {
    isMe?: boolean;
    openAddFilePopup?: () => void;
}

interface StateInterface {}

class UserTeamUserProfileButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMenuButtons>
                {this.props.isMe && this.props.openAddFilePopup && (
                    <Button
                        onClick={this.props.openAddFilePopup}
                        type="submit"
                        startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconAddCirlcePlus />} />}
                    >
                        <FormattedMessage id="app.UserTeamUserProfileButtons.addFiles" />
                    </Button>
                )}
            </WedMenuButtons>
        );
    }
}

export default UserTeamUserProfileButtons;
