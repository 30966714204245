import { Redirect, Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { AdminPanelContent } from "../components/AdminPanelContent/AdminPanelContent";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import AdminPanelBillingHistory from "./AdminPanelBillingHistory/AdminPanelBillingHistory";
import AdminPanelBillingCurrentPeriod from "./AdminPanelBillingCurrentPeriod/AdminPanelBillingCurrentPeriod";
import AdminPanelBillingPaymentMethod from "./AdminPanelBillingPaymentMethod/AdminPanelBillingPaymentMethod";
import Redirect404 from "../../../components/error/Redirect404/Redirect404";

interface PropsInterface {
    items?: { label: string; path: string; component: React.ReactElement }[];
    content?: React.ReactElement;
}

const MENU_ITEMS = [
    {
        label: "app.AdminPanelNavigation.currentPeriod",
        path: RoutesEnum.ADMIN_PANEL_BILLING_CURRENT_PERIOD,
    },
    {
        label: "app.AdminPanelNavigation.history",
        path: RoutesEnum.ADMIN_PANEL_BILLING_HISTORY,
    },
    {
        label: "app.AdminPanelNavigation.paymentMethod",
        path: RoutesEnum.ADMIN_PANEL_BILLING_PAYMENT_METHOD,
    },
];

export class AdminPanelBilling extends Component<PropsInterface> {
    render() {
        return (
            <AdminPanelContent menuItems={MENU_ITEMS}>
                <Switch>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_BILLING_CURRENT_PERIOD}>
                        <AdminPanelBillingCurrentPeriod />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_BILLING_HISTORY}>
                        <AdminPanelBillingHistory />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_BILLING_PAYMENT_METHOD}>
                        <AdminPanelBillingPaymentMethod />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_BILLING}>
                        <Redirect to={RoutesEnum.ADMIN_PANEL_BILLING_CURRENT_PERIOD} />
                    </Route>
                    <Route component={Redirect404} />
                </Switch>
            </AdminPanelContent>
        );
    }
}
