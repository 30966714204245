import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import UserBaseProfilePopup from "../UserBaseProfilePopup/UserBaseProfilePopup";
import { TextField } from "@material-ui/core";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICountry } from "../../../services/WedApi/Models/Country";
import container from "../../../container";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import { IUserMyProfile, IUserMyProfileRole } from "../../../services/WedApi/Models/User";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import "./UserEditProfilePopup.scss";
import { Dispatch } from "redux";
import { setLoggedUser } from "../../../store/UserReducer";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: () => void;
    setLoggedUser: (user: IUserMyProfile) => void;
    loggedUserId: number;
    userId: number;
    userEmail: string;
    userRole: IUserMyProfileRole;
    fields: {
        firstName: string;
        lastName: string;
        cCode: number;
        phone: string;
    };
}

interface StateInterface {
    fields: {
        firstName: string;
        lastName: string;
        country: ICountry | null;
        phone: string;
    };
    countries: ICountry[];
}

class UserEditProfilePopup extends React.Component<PropsInterface, StateInterface> {
    fieldFormCountry: React.RefObject<HTMLInputElement>;
    schema = {
        type: "object",
        properties: {
            firstName: {
                type: "string",
                minLength: 2,
                maxLength: 30,
            },
            lastName: {
                type: "string",
                minLength: 2,
                maxLength: 50,
            },
            phone: {
                type: "string",
                maxLength: 20,
            },
            country: {
                type: "object",
            },
        },
        required: ["firstName", "lastName"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                firstName: "app.UserEditProfilePopup.error.errUserFirstNameInvalid",
                lastName: "app.UserEditProfilePopup.error.errUserLastNameInvalid",
                phone: "app.UserEditProfilePopup.error.errUserPhoneInvalid",
                country: "app.UserEditProfilePopup.error.errUserCCodeInvalid",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            fields: {
                country: null,
                firstName: props.fields.firstName,
                lastName: props.fields.lastName,
                phone: props.fields.phone,
            },
            countries: [],
        };
        this.doIt = this.doIt.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fieldFormCountry = React.createRef();
    }

    async componentDidMount() {
        const countries = await container.countryService.getCountries();
        this.setState({
            countries,
            fields: {
                ...this.state.fields,
                country: countries.find((country) => country.id === this.props.fields.cCode) || null,
            },
        });
        this.setAutoCompleteValues();
    }

    private setAutoCompleteValues() {
        if (this.fieldFormCountry && this.fieldFormCountry.current) {
            const inputs = this.fieldFormCountry.current.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].autocomplete = getRandomString();
            }
        }
    }

    private async doIt(): Promise<boolean> {
        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: formErrors[firstKey] }), { variant: "error" });
            return false;
        }

        try {
            await container.userService.updateUserProfile(this.props.userId, this.state.fields);
            if (this.props.loggedUserId === this.props.userId) {
                const user = await container.userService.me();
                this.props.setLoggedUser(user);
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserEditProfilePopup.success.message" }), {
                variant: "success",
            });
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_UPDATE_FORM_INVALID) {
                keyTranslate = "app.UserEditProfilePopup.error.errUserUpdateFormInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_NAME_INVALID) {
                keyTranslate = "app.UserEditProfilePopup.error.errUserNameInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_PHONE_INVALID) {
                keyTranslate = "app.UserEditProfilePopup.error.errUserPhoneInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_CCODE_INVALID) {
                keyTranslate = "app.UserEditProfilePopup.error.errUserCCodeInvalid";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }

        return true;
    }

    private validateForm(): ValidationErrors {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    }

    private validate(): { valid: boolean; formErrors: ValidationErrors } {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    }

    private changeCountry(country: ICountry | null) {
        this.setState({ fields: { ...this.state.fields, country: country } });
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        return (
            <UserBaseProfilePopup
                open={true}
                close={this.props.close}
                className={"user-my-profile-popup"}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.UserEditProfilePopup.button.save"} />}
                header={
                    <FormattedMessage
                        id={"app.UserEditProfilePopup.header"}
                        values={{ firstName: this.props.fields.firstName, lastName: this.props.fields.lastName }}
                    />
                }
                content={
                    <>
                        {this.state.countries.length > 0 && (
                            <div className="user-my-profile-popup--content">
                                <div className="user-my-profile-popup--content-user-info">
                                    <div className="user-my-profile-popup--content-user-info-row">
                                        <div className="user-my-profile-popup--content-user-info-row-label">
                                            <FormattedMessage id={"app.UserEditProfilePopup.subHeader.emailAddress"} />
                                        </div>
                                        <div className="user-my-profile-popup--content-user-info-row-value">{this.props.userEmail}</div>
                                    </div>
                                    <div className="user-my-profile-popup--content-user-info-row">
                                        <div className="user-my-profile-popup--content-user-info-row-label">
                                            <FormattedMessage id={"app.UserEditProfilePopup.subHeader.role"} />
                                        </div>
                                        <div className="user-my-profile-popup--content-user-info-row-value">
                                            <FormattedMessage id={`app.UserEditProfilePopup.role.${this.props.userRole}`} />
                                        </div>
                                    </div>
                                </div>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={getRandomString()}
                                    label={this.props.intl.formatMessage({ id: "app.UserEditProfilePopup.label.firstName" })}
                                    name="firstName"
                                    autoComplete={getRandomString()}
                                    autoFocus
                                    value={this.state.fields.firstName}
                                    onChange={this.handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id={getRandomString()}
                                    label={this.props.intl.formatMessage({ id: "app.UserEditProfilePopup.label.lastName" })}
                                    name="lastName"
                                    autoComplete={getRandomString()}
                                    autoFocus
                                    value={this.state.fields.lastName}
                                    onChange={this.handleChange}
                                />
                                <div className="user-my-profile-popup--row">
                                    <div className="user-my-profile-popup--col50">
                                        <Autocomplete
                                            fullWidth
                                            options={this.state.countries}
                                            getOptionLabel={(option: ICountry) =>
                                                // eslint-disable-next-line
                                                this.props.intl.formatMessage({ id: "app.global.countryCode.label" }, option as any)
                                            }
                                            value={this.state.fields.country || undefined}
                                            onChange={(_, value: ICountry | null) => {
                                                this.changeCountry(value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    label={<FormattedMessage id={"app.EditClientPopup.form.country"} />}
                                                    margin="normal"
                                                    variant="outlined"
                                                    ref={this.fieldFormCountry}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className="user-my-profile-popup--col50">
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id={getRandomString()}
                                            label={this.props.intl.formatMessage({ id: "app.UserEditProfilePopup.label.phone" })}
                                            name="phone"
                                            autoComplete={getRandomString()}
                                            autoFocus
                                            value={this.state.fields.phone}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                }
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: Partial<PropsInterface>): PropsInterface => {
    return {
        ...props,
        loggedUserId: state.user.data?.id || 0,
    } as PropsInterface;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLoggedUser: setLoggedUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(injectIntl(UserEditProfilePopup)));
