import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import _size from "lodash/size";
import { ProviderContext, withSnackbar } from "notistack";
import container from "../../../container";
import PopupAction from "../../popup/PopupAction/PopupAction";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    dirId: number;
    handleClose: () => void;
}

interface StateInterface {
    fields: {
        name: string;
        description: string;
    };
    loading: boolean;
    redirect: string;
}

class CreateFolder extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            name: {
                type: "string",
                minLength: 3,
                maxLength: 100,
            },
            description: {
                type: "string",
                maxLength: 500,
            },
        },
        required: ["name"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                name: this.props.intl.formatMessage({ id: "app.createFolder.error.name" }),
                description: this.props.intl.formatMessage({ id: "app.createFolder.error.description" }),
            },
        },
    };

    readonly state = {
        fields: {
            name: "",
            description: "",
        },
        loading: false,
        redirect: "",
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            await container.filesService.createFolder(this.props.dirId, this.state.fields.name, this.state.fields.description || undefined);

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.createFolder.success" }), { variant: "success" });
            this.props.handleClose();
            return this.setState({
                loading: false,
            });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_FOLDER_NAME_INVALID) {
                keyTranslate = "app.createFolder.error.name";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_FOLDER_DESCRIPTION_INVALID) {
                keyTranslate = "app.createFolder.error.description";
            }
            this.setState({ loading: false });
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    };

    render() {
        return (
            <div className="create-folder">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.createFolder.header.title" />
                </PopupHeader>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="createFolderName"
                            label={<FormattedMessage id={"app.createFolder.form.name"} />}
                            name="name"
                            autoComplete="off"
                            autoFocus
                            value={this.state.fields.name}
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="createFolderDescription"
                            label={<FormattedMessage id={"app.createFolder.form.description"} />}
                            name="description"
                            autoComplete="off"
                            multiline
                            rows={5}
                            value={this.state.fields.description}
                            onChange={this.handleChange}
                        />
                    </div>
                    <PopupAction>
                        <Button type="submit" disabled={this.state.loading}>
                            <FormattedMessage id="app.createFolder.button.create" />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(CreateFolder));
