import React, { Component } from "react";
import "./NotLoggedPage.scss";
import container from "../../../container";

interface PropsInterface {
    children?: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {}

class NotLoggedPage extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div style={{ backgroundImage: `url(${container.publicUrlService.getImageUrl("mentry.jpg")})` }} className="not-logged-page">
                {this.props.children}
            </div>
        );
    }
}

export default NotLoggedPage;
