import React, { ReactElement } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";
import InternalTeamCountIcon from "../../count-icon/InternalTeamCountIcon/InternalTeamCountIcon";
import Title from "../components/Title/Title";
import { ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import WedDate from "../../formatter/WedDate";
import Favorite from "../components/Favorite/Favorite";
import ExternalTeamCountIcon from "../../count-icon/ExternalTeamCountIcon/ExternalTeamCountIcon";
import ProjectStatus from "../components/ProjectStatus/ProjectStatus";
import ProjectStat, { ProjectStatType } from "../components/ProjectStat/ProjectStat";
import { SizeType } from "../../wed-icon/WedIcon";
import "../tile.scss";
import "./project-tile.scss";

interface PropsInterface extends WrappedComponentProps, RouteComponentProps {
    projectId: number;
    rejected: number;
    accepted: number;
    project: string;
    client: string;
    date?: Date;
    author: string;
    status: ProjectStatusEnum;
    internalTeam: number;
    externalTeam: number;
    isFavourite: boolean;
    handleChangeFavorite?: (projectId: number) => void;
    selected: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    actionButton?: ReactElement;
}

interface StateInterface {
    isHover: boolean;
}

class ProjectTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["actionButton", "onDoubleClick", "onClick", "key", "get"];

    constructor(props: PropsInterface) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.state = {
            isHover: false,
        };
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick() {
        const { onClick } = this.props;

        onClick && onClick();
    }

    render() {
        const {
            intl,
            rejected,
            accepted,
            project,
            client,
            status,
            date,
            author,
            internalTeam,
            externalTeam,
            isFavourite,
            projectId,
            selected,
            actionButton,
            onDoubleClick,
        } = this.props;

        return (
            <div
                className={classnames("tile", "project-tile", { "tile--active": selected })}
                onMouseEnter={() => {
                    this.setState({ isHover: true });
                }}
            >
                {this.state.isHover && actionButton}
                <div className="tile-container" onClick={this.onClick} onDoubleClick={onDoubleClick}>
                    <div className="project-tile__stats">
                        <ProjectStat count={rejected} title={intl.formatMessage({ id: "app.tile.rejected" })} type={ProjectStatType.REJECTED} />
                        <ProjectStat count={accepted} title={intl.formatMessage({ id: "app.tile.accepted" })} type={ProjectStatType.ACCEPTED} />
                        {this.props.handleChangeFavorite && (
                            <Favorite
                                isFavorite={isFavourite}
                                projectId={projectId}
                                handleChangeFavorite={this.props.handleChangeFavorite}
                                sizeIcon={SizeType.LARGE}
                            />
                        )}
                    </div>
                    <div className="project-tile__title">
                        <Title title={project} />
                    </div>
                    <div className="project-tile__subtitle">{client}</div>
                    <div className="project-tile__details">
                        <FormattedMessage id={"app.tile.created"} />
                        <span className="project-tile__date">{date && <WedDate date={date} />}</span>
                        <FormattedMessage id={"app.global.by"} />
                        <span className="project-tile__user">{author}</span>
                    </div>
                    <div className="project-tile__footer">
                        <ProjectStatus status={status} />
                        <div className="project-tile__icons">
                            <InternalTeamCountIcon count={internalTeam} />
                            <ExternalTeamCountIcon count={externalTeam} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(ProjectTile));
