import React from "react";
import "./SummaryPageLayout.scss";
import classNames from "classnames";

interface PropsInterface {
    className?: string;
    leftSide?: string | React.ReactElement;
    rightSide?: string | React.ReactElement;
    boxInformation?: React.ReactNodeArray;
}

interface StateInterface {}

class SummaryPageLayout extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className={classNames("summary-page-layout", this.props.className)}>
                <div className="summary-page-layout--extra summary-page-layout--extra-3-box">
                    {this.props.boxInformation?.map((item, i) => (
                        <div key={i} className="summary-page-layout--extra-box-content summary-page-layout--extra-3-box-content">
                            {item}
                        </div>
                    ))}
                </div>
                <div className="summary-page-layout--container">
                    <div className="summary-page-layout--box-content">{this.props.leftSide}</div>
                    <div className="summary-page-layout--box-content">{this.props.rightSide}</div>
                </div>
            </div>
        );
    }
}

export default SummaryPageLayout;
