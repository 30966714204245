import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import container from "../../../container";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import LinearProgress from "@material-ui/core/LinearProgress";
import { WedTable } from "../../../components/wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ApplicationState } from "../../../store";
import { IUserTeam, IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { connect } from "react-redux";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { ISelectItem, ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import classNames from "classnames";
import WedDate from "../../../components/formatter/WedDate";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import { Dispatch } from "redux";
import { PageInfoBoxProps, setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { WedMainMenu } from "../../../components/main-menu/WedMainMenu/WedMainMenu";
import LayoutPage from "../../../components/layout/LayoutPage/LayoutPage";
import "./UserTeamListPage.scss";
import UserTeamTile from "../../../components/tile/UserTeamTile/UserTeamTile";
import Initials from "../../../components/tile/components/Initials/Initials";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";

interface BasePropsInterface extends ProjectDetailLoaderProps {}

interface PropsInterface extends SetPageInfoBoxTitlesActionProps, BasePropsInterface, WrappedComponentProps, RouteComponentProps {
    isTableMode: boolean;
}

interface StateInterface {
    filterItems: IUserTeam[];
    selectedItems: ISelectItem[];
    isLoading: boolean;
}

class UserTeamListPage extends React.Component<PropsInterface, StateInterface> {
    private items: IUserTeam[];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            filterItems: [],
            selectedItems: [],
            isLoading: true,
        };
        this.items = [];
    }

    async componentDidMount() {
        this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
        await this.fetchData();
    }

    async componentDidUpdate() {
        this.props.setPageInfoBoxTitlesAction(this.getMenuTitleHeaders());
    }

    private getMenuTitleHeaders(): PageInfoBoxProps {
        const { selectedItems } = this.state;

        if (selectedItems.length === 1) {
            const userTeam = this.items.find((item) => item.id === selectedItems[0].id);
            if (userTeam) {
                return {
                    title: `${userTeam.firstName} ${userTeam.lastName}`,
                    subTitle: `${userTeam.email}`,
                };
            }
        }

        return {
            title: this.props.intl.formatMessage({ id: "app.UserTeamListPage.header.allTeamMembers" }),
            subTitle: undefined,
        };
    }

    private async fetchData() {
        this.setState({ isLoading: true });
        await this.reloadData();
    }

    private async reloadData() {
        this.items = await container.userService.getUsers({ withProjectCounter: true });
        this.filterData();
    }

    private filterData() {
        this.setState({
            filterItems: this.items,
            selectedItems: [],
            isLoading: false,
        });
    }

    private handleSelectItem(item: IUserTeam) {
        let selectedItems = [...this.state.selectedItems];
        const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id == item.id);

        if (selectedItemIndex == -1) {
            const newItem: ISelectItem = {
                id: item.id,
                userIsOwner: false,
                type: ISelectItemType.userTeam,
                object: item,
            };
            selectedItems = [newItem];
        } else {
            selectedItems.splice(selectedItemIndex, 1);
        }

        this.setState({ selectedItems });
    }

    private handleOpenItem(item: IUserTeam) {
        this.props.history.push(generatePath(RoutesEnum.USER_TEAM_DETAILS, { teamId: item.id }));
    }

    isSelected = (id: number) => {
        const { selectedItems } = this.state;

        return !!selectedItems.find((item) => item.id === id);
    };

    render() {
        const { isLoading } = this.state;

        return (
            <LayoutPage>
                <div className="user-team-list-page">
                    <WedMainMenuContainer menu={<WedMainMenu />} sidebar={<MenuControlIcons noDisplayTrash={true} disableSidebarOpen={true} />} />
                    {isLoading ? (
                        <LinearProgress />
                    ) : (
                        <div className="user-team-list-page--container">
                            {this.props.isTableMode ? (
                                <WedTable>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="user-team-list-page--table-cell-left" colSpan={2}>
                                                <FormattedMessage id="app.UserTeamListPage.table.name" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.email" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.phone" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.accountTeamMember" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.activeProjects" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.role" />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedMessage id="app.UserTeamListPage.table.joined" />
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.filterItems.map((item: IUserTeam, i) => {
                                            const userInitialOwner = getInitialsAndOwner(item);
                                            return (
                                                <TableRow
                                                    className={classNames({
                                                        "table-row--active": this.isSelected(item.id),
                                                    })}
                                                    hover
                                                    role="checkbox"
                                                    key={i}
                                                    onClick={() => {
                                                        this.handleSelectItem(item);
                                                    }}
                                                    onDoubleClick={() => {
                                                        this.handleOpenItem(item);
                                                    }}
                                                >
                                                    <TableCell className="user-team-list-page--table-cell-left client-list-page--wed-table-name-cell-initials">
                                                        {userInitialOwner.initials && <Initials title={userInitialOwner.initials} />}
                                                    </TableCell>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.email}</TableCell>
                                                    <TableCell>{item.phone}</TableCell>
                                                    <TableCell>{item.customerAccountIDs.length}</TableCell>
                                                    <TableCell>{item.counters?.projectActive || 0}</TableCell>
                                                    <TableCell>
                                                        <FormattedMessage id={`app.UserTeamListPage.table.role.${item.role}`} />
                                                    </TableCell>
                                                    <TableCell>{item.created && <WedDate date={item.created} />}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </WedTable>
                            ) : (
                                <GridTiles>
                                    {this.state.filterItems.map((item, i) => (
                                        <UserTeamTile
                                            key={i}
                                            userTeam={item}
                                            selected={this.isSelected(item.id)}
                                            onClick={() => {
                                                this.handleSelectItem(item);
                                            }}
                                            onDoubleClick={() => {
                                                this.handleOpenItem(item);
                                            }}
                                        />
                                    ))}
                                </GridTiles>
                            )}
                        </div>
                    )}
                </div>
            </LayoutPage>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(UserTeamListPage)));
