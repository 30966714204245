import { FileTypeEnum } from "../../Type/FileTypeEnum";
import { generatePath } from "react-router-dom";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";
import { LogicError } from "../../../../errors/error-app";

export class CollectionRouteGenerator {
    public static getUrl(fileType: FileTypeEnum, params?: { collectionId?: number; projectId?: number }): string {
        if (fileType === FileTypeEnum.MY_LIBRARY_COLLECTIONS) {
            if (params?.collectionId) {
                return generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES, { collectionId: params?.collectionId });
            }
            return generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS, {});
        } else if (fileType === FileTypeEnum.PROJECT_COLLECTION) {
            if (!params?.projectId) {
                throw new LogicError(`CollectionRouteGenerator not supported ${fileType} without projectId parameters`);
            }
            if (params?.collectionId) {
                return generatePath(RoutesEnum.COLLECTION_FILES, {
                    projectId: params?.projectId,
                    collectionId: params?.collectionId,
                });
            }
            return generatePath(RoutesEnum.COLLECTIONS, { projectId: params?.projectId });
        }
        throw new LogicError(`CollectionRouteGenerator not supported fileType ${fileType}`);
    }
}
