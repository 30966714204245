import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IListResult } from "./Models/Common";
import { IApiNotifsItem } from "./Models/Notifs";

export default class NotifsActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getList(): Promise<IListResult<IApiNotifsItem>> {
        const response = await this.get("/xapi/notifs/getall");

        return response.data;
    }

    public async removeItem(id: number): Promise<void> {
        await this.postWithCSRFJson("/xapi/notifs/delete", { id });
    }

    public async removeAll(): Promise<void> {
        await this.postWithCSRFJson("/xapi/notifs/deleteall", {});
    }
}
