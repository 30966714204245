import React from "react";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconDots } from "../../../wed-icon/generated/WedIconSvg";
import "./TileMenu.scss";

interface PropsInterface {
    sizeIcon: SizeType;
}

interface StateInterface {}

class TileMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <WedIcon className="project__tile-menu" size={this.props.sizeIcon} icon={<WedIconDots />} />;
    }
}

export default TileMenu;
