import React from "react";
import "./InformationSummaryViewDetails.scss";

interface PropsInterface {
    items: {
        label: React.ReactElement | string;
        value: React.ReactElement | string;
    }[];
}

interface StateInterface {}

class InformationSummaryViewDetails extends React.Component<PropsInterface, StateInterface> {
    private item(key: number, label: React.ReactElement | string, value: React.ReactElement | string): React.ReactElement {
        return (
            <li key={key} className="information-summary-view-details--list-item">
                <div className="information-summary-view-details--list-label">
                    <div className="information-summary-view-details--list-label-content">{label}</div>
                </div>
                <div className="information-summary-view-details--list-value">
                    <div className="information-summary-view-details--list-value-content">{value}</div>
                </div>
            </li>
        );
    }

    render() {
        return <ul className="information-summary-view-details">{this.props.items.map((item, i) => this.item(i, item.label, item.value))}</ul>;
    }
}

export default InformationSummaryViewDetails;
