import React, { Component } from "react";
import { ClientDetailLoaderProps } from "../../../components/client/ClientDetailLoader/ClientDetailLoader";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import ClientMenu from "../../../components/main-menu/ClientMenu/ClientMenu";
import ClientSummaryButtons from "./ClientSummaryPageHeader/ClientSummaryButtons/ClientSummaryButtons";
import { IClient, IClientAddress } from "../../../services/WedApi/Models/Client";
import EditClientPopup from "../../../components/client/EditClientPopup/EditClientPopup";
import container from "../../../container";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import DeleteClientPopup from "../../../components/client/DeleteClientPopup/DeleteClientPopup";
import SummaryPageLayout from "../../../components/summary/SummaryPageLayout/SummaryPageLayout";
import ClientInformationSummary from "../../../components/client/Summary/ClientInformationSummary/ClientInformationSummary";
import { ICountry } from "../../../services/WedApi/Models/Country";
import LinearProgress from "@material-ui/core/LinearProgress";
import ClientAccountTeam from "../../../components/client/Summary/ClientAccountTeam/ClientAccountTeam";
import { IAccountTeamUser, IUser } from "../../../services/WedApi/Models/User";
import { IClientContact } from "../../../services/WedApi/Models/ClientContact";
import UserClientEditTeamMembersPopup from "../../../components/user/UserClientEditTeamMembersPopup/UserClientEditTeamMembersPopup";
import { getClientAccountTeamMembers } from "../../../services/WedApi/Utils/UserTeamMembers";
import _ from "lodash";

interface PropsInterface extends ClientDetailLoaderProps, RouteComponentProps {
    fetchClient: () => Promise<void>;
}

interface StateInterface {
    isLoading: boolean;
    clientAddress?: IClientAddress;
    clientCountry?: ICountry;
    teamUsers: IUser[];
    teamContacts: IClientContact[];
    clientUserMembers: IUser[];
    popup?: {
        createClient?: { open: boolean };
        editClient?: { clientAddress: IClientAddress };
        deleteClient?: { client: IClient };
        addClientMembers?: { userMember: IAccountTeamUser[] };
    };
}

class ClientSummaryPage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: false,
            clientAddress: undefined,
            clientCountry: undefined,
            popup: undefined,
            teamUsers: [],
            teamContacts: [],
            clientUserMembers: [],
        };
        this.handleClientChange = this.handleClientChange.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.createClientClick = this.createClientClick.bind(this);
        this.editClientClick = this.editClientClick.bind(this);
        this.deleteClientClick = this.deleteClientClick.bind(this);
        this.addClientMembersClick = this.addClientMembersClick.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!_.isEqual(prevProps, this.props)) {
            await this.fetchData();
        }
    }

    private async closePopup(updated?: boolean) {
        this.setState({ popup: undefined });
        if (updated) {
            this.setState({ isLoading: true });
            await this.fetchData();
        }
    }

    private createClientClick(): void {
        this.setState({ popup: { createClient: { open: true } } });
    }

    private editClientClick(clientAddress: IClientAddress): void {
        this.setState({ popup: { editClient: { clientAddress: clientAddress } } });
    }

    private deleteClientClick(client: IClient): void {
        this.setState({ popup: { deleteClient: { client: client } } });
    }

    private addClientMembersClick(client: IClient): void {
        this.setState({ popup: { addClientMembers: { userMember: getClientAccountTeamMembers(client, this.state.teamUsers || []) } } });
    }

    private async fetchData() {
        const userIds: number[] = ([] as number[]).concat(this.props.client.ownerIDs || [], this.props.client.membersIds || []);
        const contactIds: number[] = this.props.client.contactIDs || [];
        const [clientAddress, countries, users, contacts, clientUserMembers] = await Promise.all([
            container.clientService.getClientAddress(this.props.client.id),
            container.countryService.getCountries(),
            userIds.length > 0 ? container.userService.getByIds(userIds) : [],
            Promise.all(
                contactIds.map((contactId) =>
                    container.clientContactService.getClientContactDetails(this.props.client.id, contactId, {
                        withoutCreatedBy: true,
                        withoutProjects: true,
                    })
                )
            ),
            container.userService.getUsers(),
        ]);

        this.setState({
            isLoading: false,
            clientAddress: clientAddress,
            clientCountry: clientAddress.countryID ? countries.find((country) => clientAddress.countryID === country.id) : undefined,
            teamUsers: users,
            teamContacts: contacts,
            clientUserMembers: clientUserMembers,
        });
    }

    private async handleClientChange() {
        this.setState({
            isLoading: true,
        });
        await this.props.fetchClient();
        await this.fetchData();
    }

    render() {
        const clientId = this.props.client.id;

        return (
            <div className="client-summary-page">
                <UserClientEditTeamMembersPopup
                    clientId={clientId}
                    close={async (updated?: boolean) => {
                        await this.closePopup();
                        if (updated) {
                            await this.handleClientChange();
                        }
                    }}
                    userMember={this.state.popup?.addClientMembers?.userMember}
                    clientUserMembers={this.state.clientUserMembers}
                />
                {this.state.popup?.createClient?.open && (
                    <EditClientPopup
                        onClickClose={async (updated?: boolean) => {
                            await this.closePopup();
                            if (updated) {
                                this.props.history.push(generatePath(RoutesEnum.CLIENTS));
                            }
                        }}
                    />
                )}
                {this.state.popup?.editClient?.clientAddress && (
                    <EditClientPopup
                        clientAddress={this.state.popup?.editClient?.clientAddress}
                        onClickClose={async (updated?: boolean) => {
                            await this.closePopup();
                            if (updated) {
                                await this.handleClientChange();
                            }
                        }}
                    />
                )}
                {this.state.popup?.deleteClient?.client && (
                    <DeleteClientPopup
                        client={this.state.popup.deleteClient.client}
                        onClickClose={async (updated?: boolean) => {
                            await this.closePopup();
                            if (updated) {
                                if (updated) {
                                    this.props.history.push(generatePath(RoutesEnum.CLIENTS));
                                }
                            }
                        }}
                    />
                )}
                <WedMainMenuContainer
                    menu={<ClientMenu clientId={clientId} activeTab="summary" />}
                    buttons={
                        <ClientSummaryButtons
                            client={this.props.client}
                            clientAddress={this.state.clientAddress}
                            createClientClick={this.createClientClick}
                            editClientClick={this.editClientClick}
                            deleteClientClick={this.deleteClientClick}
                            addClientMembersClick={this.addClientMembersClick}
                        />
                    }
                    sidebar={<MenuControlIcons noDisplayTrash={true} disableSidebarOpen={true} disableChangeViewMode={true} />}
                />
                {this.state.isLoading || !this.state.clientAddress ? (
                    <LinearProgress />
                ) : (
                    <SummaryPageLayout
                        className="client-summary-page--container"
                        leftSide={
                            <ClientInformationSummary
                                className="client-summary-page--box-content"
                                client={this.props.client}
                                clientAddress={this.state.clientAddress}
                                clientCountry={this.state.clientCountry}
                            />
                        }
                        rightSide={
                            <ClientAccountTeam
                                client={this.props.client}
                                teamUsers={this.state.teamUsers}
                                teamContacts={this.state.teamContacts}
                                handleClientChange={this.handleClientChange}
                            />
                        }
                    />
                )}
            </div>
        );
    }
}

export default withRouter(ClientSummaryPage);
