// eslint-disable-next-line
export function getQueryIntParam(data: any, name: string): number | null {
    if (data[name]) {
        const val = parseInt(data[name]);
        if (!isNaN(val)) {
            return val;
        }
    }
    return null;
}
