import React, { Component } from "react";
import { FormControl, Input, InputAdornment } from "@material-ui/core";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconArrow, WedIconSearch1 } from "../../wed-icon/generated/WedIconSvg";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import { SearchResultPopup } from "../SearchResultPopup/SearchResultPopup";
import "./SearchInput.scss";

interface PropsInterface {}

interface StateInterface {
    query: string;
    popup: boolean;
}

export default class SearchInput extends Component<PropsInterface, StateInterface> {
    private queryInputRef = React.createRef<HTMLInputElement>();

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            query: "",
            popup: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    private closePopup(linkClicked?: boolean) {
        if (linkClicked) {
            this.setState({
                query: "",
            });
            setTimeout(() => {
                this.blurInput();
            }, 100);
        }

        this.setState({
            popup: false,
        });
    }

    private handleSubmit() {
        if (this.state.query.length > 0) {
            this.setState({ popup: true });
        } else if (this.state.popup) {
            this.setState({ popup: false });
        }
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;
        this.setState({
            query: value,
        });
    }

    private blurInput() {
        if (this.queryInputRef.current) {
            const inputs = this.queryInputRef.current.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].blur();
            }
        }
    }

    render() {
        return (
            <div className="search-input">
                <FormControl fullWidth className="search-input__control">
                    <Input
                        ref={this.queryInputRef}
                        id={`global-search-component-input-${getRandomString()}`}
                        placeholder={"Search"}
                        disableUnderline={true}
                        onChange={this.handleChange}
                        onKeyUp={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                this.handleSubmit();
                            }
                        }}
                        autoComplete={getRandomString()}
                        value={this.state.query}
                        startAdornment={
                            <InputAdornment position="start">
                                <WedIcon icon={<WedIconSearch1 />} size={SizeType.SMALL} />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end" onClick={this.handleSubmit}>
                                <WedIcon icon={<WedIconArrow />} size={SizeType.SMALL} />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {this.state.popup && <SearchResultPopup query={this.state.query} handleClose={this.closePopup} />}
            </div>
        );
    }
}
