import React, { ReactElement } from "react";
import classnames from "classnames";
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import Initials, { InitialsUserType } from "../components/Initials/Initials";
import Title from "../components/Title/Title";
import WedDate from "../../formatter/WedDate";
import "./UserTeamTile.scss";
import "../tile.scss";
import { IUserTeam } from "../../../services/WedApi/Models/User";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";

interface PropsInterface {
    userTeam: IUserTeam;
    selected?: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    actionButton?: ReactElement;
    disableHover?: boolean;
    withInitialsColor?: boolean;
}

interface StateInterface {
    isHover: boolean;
}

class UserTeamTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["onClick", "key", "get", "actionButton"];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isHover: false,
        };
        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick() {
        this.props.onClick && this.props.onClick();
    }

    onDoubleClick() {
        this.props.onDoubleClick && this.props.onDoubleClick();
    }

    render() {
        const { userTeam, selected, disableHover, actionButton, withInitialsColor } = this.props;

        const userInitialOwner = getInitialsAndOwner(userTeam);

        return (
            <div
                className={classnames("tile user-team-tile", {
                    "tile--active": selected,
                    "tile--disable-hover": disableHover,
                })}
                onMouseEnter={
                    disableHover
                        ? undefined
                        : () => {
                              this.setState({ isHover: true });
                          }
                }
            >
                {this.state.isHover && actionButton}
                <div className="tile-container" onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
                    <div className="user-team-tile__header">
                        <div className="user-team-tile__profile-image">
                            {userInitialOwner.initials && (
                                <Initials userType={withInitialsColor ? InitialsUserType.INTERNAL : undefined} title={userInitialOwner.initials} />
                            )}
                        </div>
                        <div className="user-team-tile__profile-info">
                            <div className="user-team-tile__profile-info-name">
                                <Title title={userTeam.name} />
                            </div>
                        </div>
                    </div>
                    <div className="user-team-tile--extra-info">
                        {userTeam.email && (
                            <div className="user-team-tile__label-value">
                                <div className="user-team-tile__label-value-label">
                                    <FormattedMessage id="app.ClientContactTile.email" />
                                </div>
                                <div className="user-team-tile__label-value-value">{userTeam.email}</div>
                            </div>
                        )}
                        {userTeam.phone && (
                            <div className="user-team-tile__label-value">
                                <div className="user-team-tile__label-value-label">
                                    <FormattedMessage id="app.ClientContactTile.phone" />
                                </div>
                                <div className="user-team-tile__label-value-value">{userTeam.phone}</div>
                            </div>
                        )}
                    </div>
                    <div className="user-team-tile__details">
                        <FormattedMessage id={"app.tile.joined"} />
                        <span className="user-team-tile__date">{userTeam.created && <WedDate date={userTeam.created} />}</span>
                    </div>
                    <div className="user-team-tile__projects-info">
                        <FormattedMessage id={"app.ClientContactTile.projects"} />
                        <span className="user-team-tile__projects-info-value">
                            {userTeam.counters?.projectActive || 0}
                            <span>/</span>
                            {userTeam.counters?.projectClosed || 0}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserTeamTile;
