import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupAction from "../../popup/PopupAction/PopupAction";
import { Button } from "@material-ui/core";
import { ProviderContext, withSnackbar } from "notistack";
import { ISelectItem, ISelectItemType } from "../../status-action-button/StatusActionButton";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    items: ISelectItem[];
    open: boolean;
    handleClose: () => void;
    onSuccess: () => void;
}

interface StateInterface {
    loading: boolean;
}

enum REMOVE_TYPE {
    ONLY_FILE = "file",
    ONLY_FILES = "files",
    ONLY_FOLDER = "folder",
    ONLY_FOLDERS = "folders",
    FILES_AND_FOLDER = "files_folder",
    FILE_AND_FOLDERS = "file_folders",
    FILES_AND_FOLDERS = "files_folders",
}

class MoveToTrashPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { loading: false };
    }

    getRemoveType = (filesCount: number, foldersCount: number) => {
        if (filesCount == 1 && foldersCount == 0) {
            return REMOVE_TYPE.ONLY_FILE;
        } else if (filesCount > 1 && foldersCount == 0) {
            return REMOVE_TYPE.ONLY_FILES;
        } else if (filesCount == 0 && foldersCount == 1) {
            return REMOVE_TYPE.ONLY_FOLDER;
        } else if (filesCount == 0 && foldersCount > 1) {
            return REMOVE_TYPE.ONLY_FOLDERS;
        } else if (filesCount > 1 && foldersCount == 1) {
            return REMOVE_TYPE.FILES_AND_FOLDER;
        } else if (filesCount == 1 && foldersCount > 1) {
            return REMOVE_TYPE.FILE_AND_FOLDERS;
        } else {
            return REMOVE_TYPE.FILES_AND_FOLDERS;
        }
    };

    moveToTrash = async () => {
        if (!this.state.loading) {
            this.setState({ loading: true });

            const { items, enqueueSnackbar, intl, onSuccess } = this.props;
            const filesIds = items.filter((item) => item.type == ISelectItemType.file).map((item) => item.id);
            const folderIds = items.filter((item) => item.type == ISelectItemType.directory).map((item) => item.id);
            const suffix = this.getRemoveType(filesIds.length, folderIds.length);

            try {
                if (filesIds.length > 0) {
                    await container.filesService.moveFileToTrash(filesIds);
                }

                if (folderIds.length > 0) {
                    await container.filesService.moveFolderToTrash(folderIds);
                }

                enqueueSnackbar(intl.formatMessage({ id: `app.deleteFiles.delete.success.${suffix}` }), { variant: "success" });
                onSuccess();
                this.setState({ loading: false });
            } catch (e) {
                enqueueSnackbar(intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                this.setState({ loading: false });
            }
        }
    };

    render() {
        const { items, open, handleClose } = this.props;
        const filesNames = items.filter((item) => item.type === ISelectItemType.file).map((item) => item.object?.name);
        const foldersNames = items.filter((item) => item.type === ISelectItemType.directory).map((item) => item.object?.name);
        const suffix = this.getRemoveType(filesNames.length, foldersNames.length);

        return (
            <Popup open={open} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.deleteFiles.title.${suffix}`} />
                    </PopupHeader>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage
                                id={`app.deleteFiles.content.${suffix}`}
                                values={{
                                    files: '"' + filesNames.join('", "') + '"',
                                    folders: '"' + foldersNames.join('", "') + '"',
                                }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <PopupAction>
                        <Button type="submit" onClick={this.moveToTrash} className="button--more-padding" disabled={this.state.loading}>
                            <FormattedMessage id="app.standard.popup.button.remove" />
                        </Button>
                        <Button type="submit" onClick={handleClose} className="button--more-padding">
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(MoveToTrashPopup));
