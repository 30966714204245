import React, {Component} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import container from "../../../container";
import {setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps} from "../../../store/PageInfoBoxReducer";
import {generatePath, Redirect, Route, RouteComponentProps, Switch} from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import LinearProgress from "@material-ui/core/LinearProgress";
import {IClientExtended} from "../../../services/WedApi/Models/Client";
import ClientProjectsPage from "../../../pages/client/ClientProjectsPage/ClientProjectsPage";
import {ProjectStatusEnum} from "../../../services/WedApi/Models/Project";
import ClientSummaryPage from "../../../pages/client/ClientSummaryPage/ClientSummaryPage";
import ClientContactsPage from "../../../pages/client/ClientContactsPage/ClientContactsPage";
import ClientContactsDetailsPage from "../../../pages/client/ClientContactsDetailsPage/ClientContactsDetailsPage";
import Redirect404 from "../../error/Redirect404/Redirect404";
import LayoutPage from "../../layout/LayoutPage/LayoutPage";

export interface ClientDetailLoaderProps {
    client: IClientExtended;
}

interface PropsInterface extends SetPageInfoBoxTitlesActionProps {
    clientId: number;
}

interface StateInterface {
    client?: IClientExtended;
}

class ClientDetailLoader extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            client: undefined,
        };
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (prevProps.clientId !== this.props.clientId) {
            this.setState({client: undefined});
            await this.fetchData();
        }
    }

    private async fetchData() {
        this.props.setPageInfoBoxTitlesAction({});

        const client = await container.clientService.getClientDetail(this.props.clientId);

        this.setState({client});

        this.props.setPageInfoBoxTitlesAction({
            title: client.name,
            subTitle: undefined,
        });
    }

    render() {
        return (
            <LayoutPage>
                {this.state.client ? (
                    <Switch>
                        <Route exact path={RoutesEnum.CLIENTS_SUMMARY}>
                            <ClientSummaryPage client={this.state.client} fetchClient={this.fetchData}/>
                        </Route>
                        <Route exact path={RoutesEnum.CLIENTS_PROJECTS_ACTIVE}>
                            <ClientProjectsPage client={this.state.client} projectStatus={ProjectStatusEnum.active}/>
                        </Route>
                        <Route exact path={RoutesEnum.CLIENTS_PROJECTS_CLOSED}>
                            <ClientProjectsPage client={this.state.client} projectStatus={ProjectStatusEnum.closed}/>
                        </Route>
                        <Route exact path={RoutesEnum.CLIENTS_CONTACTS}>
                            <ClientContactsPage client={this.state.client}/>
                        </Route>
                        <Route
                            exact
                            path={RoutesEnum.CLIENTS_CONTACTS_DETAIL}
                            component={(route: RouteComponentProps<{ clientId?: string, contactId?: string }>) => {
                                if (`${route.match.params.clientId}` === `${this.state.client?.id}`) {
                                    return (
                                        <ClientContactsDetailsPage
                                            client={this.state.client as IClientExtended}
                                            clientContactId={parseInt(route.match.params.contactId as string)}
                                        />
                                    );
                                }
                                return <LinearProgress/>
                            }}
                        />
                        <Route
                            exact
                            path={RoutesEnum.CLIENTS_DETAIL}
                            component={(route: RouteComponentProps<{ clientId: string }>) => {
                                return <Redirect
                                    to={generatePath(RoutesEnum.CLIENTS_PROJECTS_ACTIVE, {clientId: route.match.params.clientId})}/>;
                            }}
                        />
                        <Route component={Redirect404}/>
                    </Switch>
                ) : (
                    <LinearProgress/>
                )}
            </LayoutPage>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(ClientDetailLoader);
