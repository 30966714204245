import React, { Component } from "react";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { WedIconCreditCard6, WedIconPenEditCreate3, WedIconRefreshRotate4, WedIconUsers } from "../../../../components/wed-icon/generated/WedIconSvg";
import { WedIcon } from "../../../../components/wed-icon/WedIcon";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";
import "./AdminPagePrimaryNavigation.scss";

interface PropsInterface extends RouteComponentProps<never, never, { from?: string }> {}

const NAVIGATION_ITEMS = [
    {
        label: "app.AdminPanelNavigation.manageUsers",
        link: RoutesEnum.ADMIN_PANEL_MANAGE_USERS,
        icon: <WedIconUsers />,
    },
    {
        label: "app.AdminPanelNavigation.companyData",
        link: RoutesEnum.ADMIN_PANEL_COMPANY_DATA,
        icon: <WedIconPenEditCreate3 />,
    },
    {
        label: "app.AdminPanelNavigation.billing",
        link: RoutesEnum.ADMIN_PANEL_BILLING,
        icon: <WedIconCreditCard6 />,
    },
    {
        label: "app.AdminPanelNavigation.subscription",
        link: RoutesEnum.ADMIN_PANEL_SUBSCRIPTION,
        icon: <WedIconRefreshRotate4 />,
    },
];

class AdminPagePrimaryNavigation extends Component<PropsInterface> {
    render() {
        return (
            <ul className="admin-panel-nav">
                {NAVIGATION_ITEMS.map((item) => (
                    <li key={item.label} className="admin-panel-nav__item">
                        <NavLink className="admin-panel-nav__link" to={item.link} activeClassName="active">
                            <WedIcon className="admin-panel-nav__icon" icon={item.icon} />
                            <span className="admin-panel-nav__label">
                                <FormattedMessage id={item.label} />
                            </span>
                        </NavLink>
                    </li>
                ))}
            </ul>
        );
    }
}

export default withRouter(AdminPagePrimaryNavigation);
