import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiTrashResponse } from "./Models/Trash";

export default class TrashActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getTrash(projectId: number): Promise<IApiTrashResponse> {
        const response = await this.get(`/xapi/prs/${projectId}/trash`);

        return response.data;
    }

    public async getMyLibraryTrash(): Promise<IApiTrashResponse> {
        const response = await this.get(`/xapi/library/trash`);

        return response.data;
    }
}
