import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import { IFile } from "../../../../services/WedApi/Models/File";
import container from "../../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    open: boolean;
    files: Pick<IFile, "id" | "name">[];
    collectionId: number | undefined;
    handleClose: () => void;
    onSuccess: () => void;
}

interface StateInterface {
    loading: boolean;
}

class RemoveFromCollectionPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { loading: false };
    }

    removeFileFromCollection = async () => {
        if (!this.state.loading && this.props.collectionId) {
            this.setState({ loading: true });

            const { enqueueSnackbar, intl, onSuccess, files } = this.props;

            try {
                await container.collectionService.removeFilesFromCollection(
                    this.props.collectionId,
                    files.map((file) => file.id)
                );

                enqueueSnackbar(intl.formatMessage({ id: `app.removeFromCollectionPopup.success` }), { variant: "success" });
                this.setState({ loading: false });
                onSuccess();
            } catch (e) {
                enqueueSnackbar(intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                this.setState({ loading: false });
            }
        }
    };

    render() {
        const { open, handleClose, files, collectionId } = this.props;

        if (!collectionId) {
            return null;
        }

        return (
            <Popup open={open} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.removeFromCollectionPopup.title`} />
                    </PopupHeader>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage
                                id={"app.removeFromCollectionPopup.content"}
                                values={{ fileName: '"' + files.map((file) => file.name).join('", "') + '"' }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <PopupAction>
                        <Button
                            type="submit"
                            onClick={async () => {
                                await this.removeFileFromCollection();
                            }}
                            className="button--more-padding"
                            disabled={this.state.loading}
                        >
                            <FormattedMessage id="app.standard.popup.button.remove" />
                        </Button>
                        <Button type="submit" onClick={handleClose} className="button--more-padding">
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(RemoveFromCollectionPopup));
