import React from "react";
import { IAccountTeamUser, IAccountTeamUserProjectRole, IUser } from "../../../../services/WedApi/Models/User";
import { IClientContact } from "../../../../services/WedApi/Models/ClientContact";
import { IClient } from "../../../../services/WedApi/Models/Client";
import { getClientAccountTeamMembers } from "../../../../services/WedApi/Utils/UserTeamMembers";
import SummaryPageBoxContainer from "../../../summary/SummaryPageBoxContainer/SummaryPageBoxContainer";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import TeamWedTable from "../../../summary/TeamWedTable/TeamWedTable";
import UserClientChangeRolePopup from "../../../user/UserClientChangeRolePopup/UserClientChangeRolePopup";
import UserClientDennyAccessRolePopup from "../../../user/UserClientDennyAccessRolePopup/UserClientDennyAccessRolePopup";

interface PropsInterface {
    className?: string;
    client: IClient;
    teamUsers: IUser[];
    teamContacts: IClientContact[];
    handleClientChange: () => void;
}

interface StateInterface {
    userClientChangeRolePopup?: { userMember: IAccountTeamUser; newRole: IAccountTeamUserProjectRole };
    userClientDennyAccessRolePopup?: { userMember: IAccountTeamUser };
}

class ClientAccountTeam extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userClientChangeRolePopup: undefined,
            userClientDennyAccessRolePopup: undefined,
        };
        this.closePopup = this.closePopup.bind(this);
        this.changeUserRole = this.changeUserRole.bind(this);
        this.dennyAccessRole = this.dennyAccessRole.bind(this);
    }

    // eslint-disable-next-line
    private changeUserRole(id: number, newRole: any) {
        const userMember = getClientAccountTeamMembers(this.props.client, this.props.teamUsers).find((user) => user.id === id);
        if (userMember) {
            this.setState({
                userClientChangeRolePopup: { userMember, newRole },
            });
        }
    }

    private dennyAccessRole(id: number) {
        const userMember = getClientAccountTeamMembers(this.props.client, this.props.teamUsers).find((user) => user.id === id);
        if (userMember) {
            this.setState({
                userClientDennyAccessRolePopup: { userMember },
            });
        }
    }

    private closePopup(changed?: boolean) {
        this.setState({
            userClientChangeRolePopup: undefined,
            userClientDennyAccessRolePopup: undefined,
        });
        if (changed) {
            this.props.handleClientChange();
        }
    }

    render() {
        const internalTeams = getClientAccountTeamMembers(this.props.client, this.props.teamUsers);

        return (
            <SummaryPageBoxContainer
                className={classNames("client-account-team", this.props.className)}
                header={<FormattedMessage id={"app.ClientAccountTeam.header"} />}
            >
                <UserClientChangeRolePopup
                    clientId={this.props.client.id}
                    userMember={this.state.userClientChangeRolePopup?.userMember}
                    newRole={this.state.userClientChangeRolePopup?.newRole}
                    close={this.closePopup}
                />
                <UserClientDennyAccessRolePopup
                    clientId={this.props.client.id}
                    userMember={this.state.userClientDennyAccessRolePopup?.userMember}
                    close={this.closePopup}
                />
                <div className="project-team-summary--container">
                    <TeamWedTable
                        teamType={"ACCOUNT"}
                        userIsOwner={this.props.client.userIsOwner}
                        users={internalTeams}
                        contacts={this.props.teamContacts as IUser[]}
                        actions={{
                            changeUserRole: this.changeUserRole,
                            dennyAccessRole: this.dennyAccessRole,
                        }}
                    />
                </div>
            </SummaryPageBoxContainer>
        );
    }
}

export default ClientAccountTeam;
