import React, { Component } from "react";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { Dispatch } from "redux";
import { setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";
import CollectionPage from "../../../components/template/CollectionPage/CollectionPage";

interface PropsInterface extends SetPageInfoBoxTitlesActionProps, WrappedComponentProps {}

interface StateInterface {}

class MyLibraryCollectionPage extends Component<PropsInterface, StateInterface> {
    componentDidMount() {
        this.props.setPageInfoBoxTitlesAction({
            title: this.props.intl.formatMessage({ id: "app.myLibraryMenu.collections" }),
        });
    }

    render() {
        return <CollectionPage fileType={FileTypeEnum.MY_LIBRARY_COLLECTIONS} />;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(injectIntl(MyLibraryCollectionPage));
