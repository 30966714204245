import { PropsInterface } from "../../../FilesPage";
import container from "../../../../../../container";
import FetchDataReturnInterface from "./FetchDataReturnInterface";
import DataFilesInterface from "../DataFilesInterface";
import { generatePath } from "react-router-dom";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";
import OpenFileParamsInterface from "./ParamsInterface/OpenFileParamsInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import { BreadcrumbItem } from "../../../../../breadcrumbs/Breadcrumbs";
import { SizeType, WedIcon } from "../../../../../wed-icon/WedIcon";
import { WedIconItemsGroupElements } from "../../../../../wed-icon/generated/WedIconSvg";
import React from "react";
import BreadcrumbsParamsInterface from "./ParamsInterface/BreadcrumbsParamsInterface";
import { IProject, IProjectExtended } from "../../../../../../services/WedApi/Models/Project";
import OpenSectionParamsInterface from "./ParamsInterface/OpenSectionParamsInterface";
import { isEditableProject } from "../../../../../../services/WedApi/Utils/IsEditableProject";
import { AppNotFoundItemError, LogicError } from "../../../../../../errors/error-app";
import { CollectionRouteGenerator } from "../../../../CollectionPage/helper/CollectionRouteGenerator";

export default class MyLibraryCollectionFilesData implements DataFilesInterface {
    async fetch(props: PropsInterface): Promise<FetchDataReturnInterface> {
        if (!props.collectionId) {
            throw new LogicError(`CollectionId is required for type: ${FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}`);
        }

        const collections = await container.collectionService.getCollections();
        const collection = collections.find((collection) => collection.id === props.collectionId);
        if (!collection) {
            throw new AppNotFoundItemError(`Not found collection id ${props.collectionId} in my library`);
        }

        return {
            isLoading: false,
            directories: [],
            files: collection.files,
            collection: {
                item: collection,
                items: collections,
            },
            dir: undefined,
        };
    }

    getFolder(): Promise<FetchDataReturnInterface> {
        throw new LogicError(`Not supported method for type: ${FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}`);
    }

    didUpdate(prevProps: PropsInterface, currentProps: PropsInterface): boolean {
        return currentProps.collectionId !== prevProps.collectionId;
    }

    openFile(params: OpenFileParamsInterface): string {
        if (!params.collectionId) {
            throw new LogicError(`collectionId is required for type: ${FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}`);
        }

        return generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES_MEDIA_GALLERY_DETAILS, {
            collectionId: params.collectionId,
            objectId: params.objectId,
        });
    }

    breadcrumbs(breadcrumbsParams: BreadcrumbsParamsInterface): BreadcrumbItem[] {
        if (!breadcrumbsParams.collection) {
            throw new LogicError(`collection is required for type: ${FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}`);
        }

        const currentCollection = breadcrumbsParams.collection.item;

        return [
            {
                children: <WedIcon size={SizeType.SMALL} icon={<WedIconItemsGroupElements />} />,
                link: CollectionRouteGenerator.getUrl(FileTypeEnum.MY_LIBRARY_COLLECTIONS),
                id: 0,
                subItems: breadcrumbsParams.collection.items
                    .filter((collection) => collection.id !== currentCollection.id)
                    .map((collection) => {
                        return {
                            id: collection.id,
                            label: collection.name,
                            click: () => {
                                const link = CollectionRouteGenerator.getUrl(FileTypeEnum.MY_LIBRARY_COLLECTIONS, { collectionId: collection.id });
                                container.history.push(link);
                            },
                        };
                    }),
            },
            {
                id: currentCollection.id,
                title: currentCollection.name,
                children: undefined,
                link: CollectionRouteGenerator.getUrl(FileTypeEnum.MY_LIBRARY_COLLECTIONS, { collectionId: currentCollection.id }),
            },
        ];
    }

    openSection(params: OpenSectionParamsInterface): string {
        if (!params.collectionId) {
            throw new LogicError(`collectionId is required for type: ${FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES}`);
        }

        return CollectionRouteGenerator.getUrl(FileTypeEnum.MY_LIBRARY_COLLECTIONS, { collectionId: params.collectionId });
    }

    // eslint-disable-next-line
    getRootDirName(project?: IProjectExtended): string {
        return "My library collection";
    }

    showTrash(project?: IProject): boolean {
        return isEditableProject(FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES, project);
    }
}
