import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import {Button, TableBody, TableCell, TableRow, TextField} from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ICountry } from "../../../services/WedApi/Models/Country";
import { getRandomString } from "../../../services/WedApi/Utils/GetRandomString";
import JSONSchemaValidator from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import "./UserBaseCompanyDataForm.scss";
import {WedTable} from "../../wed-table/WedTable";
import Radio from "@material-ui/core/Radio";

export enum UserBaseCompanyDataFormType {
    REGISTER = "REGISTER",
    EDITABLE = "EDITABLE",
}

export interface CompanyDataForm {
    companyName: string;
    companyShortName: string;
    taxId: string;
    street: string;
    street2: string;
    city: string;
    state: string;
    zipCode: string;
    country: ICountry | null;
    selectedPlan?: number;
}

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    companyId?: number;
    formType: UserBaseCompanyDataFormType;
    countries: ICountry[];
    submit: (data: CompanyDataForm) => Promise<boolean | undefined>;
    fields: CompanyDataForm;
    canDisabled?: boolean;
    disabledCompany?: boolean;
}

interface StateInterface {
    loading: boolean;
    fields: CompanyDataForm;
    disabled: boolean;
}

class UserBaseCompanyDataForm extends Component<PropsInterface, StateInterface> {
    fieldFormCountry: React.RefObject<HTMLInputElement>;
    schema = {
        type: "object",
        properties: {
            companyName: {
                type: "string",
                minLength: 2,
                maxLength: 100,
            },
            companyShortName: {
                type: "string",
                maxLength: 100,
            },
            taxId: {
                type: "string",
                minLength: 1,
                maxLength: 100,
            },
            street: {
                type: "string",
                minLength: 1,
                maxLength: 100,
            },
            street2: {
                type: "string",
                maxLength: 100,
            },
            city: {
                type: "string",
                minLength: 2,
                maxLength: 100,
            },
            state: {
                type: "string",
                maxLength: 100,
            },
            zipCode: {
                type: "string",
                minLength: 1,
                maxLength: 20,
            },
            country: {
                type: "object",
            },
        },
        required: ["companyName", "taxId", "street", "city", "state", "zipCode", "country"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                companyName: "app.UserBaseCompanyDataForm.error.companyName",
                companyShortName: "app.UserBaseCompanyDataForm.error.companyShortName",
                taxId: "app.UserBaseCompanyDataForm.error.taxId",
                street: "app.UserBaseCompanyDataForm.error.street",
                street2: "app.UserBaseCompanyDataForm.error.street2",
                city: "app.UserBaseCompanyDataForm.error.city",
                state: "app.UserBaseCompanyDataForm.error.state",
                zipCode: "app.UserBaseCompanyDataForm.error.zipCode",
                country: "app.UserBaseCompanyDataForm.error.country",
                selectedPlan: "app.UserBaseCompanyDataForm.error.selectedPlan",
            },
        },
    };
    schemaPlan = {
        type: "object",
        properties: {
            selectedPlan: {
                type: "number"
            }
        },
        required: ["selectedPlan"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                selectedPlan: "app.UserBaseCompanyDataForm.error.selectedPlan",
            },
        },
    };
    schemaWithPlan = {
        ...this.schema,
        properties: {
            ...this.schema.properties,
            selectedPlan: {
                type: "number"
            }
        },
        required: [
            ...this.schema.required,
            "selectedPlan"
        ],
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            disabled: !!props.canDisabled,
            fields: {
                ...props.fields,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fieldFormCountry = React.createRef();
    }

    async componentDidMount() {
        if (this.fieldFormCountry && this.fieldFormCountry.current) {
            const inputs = this.fieldFormCountry.current.getElementsByTagName("input");
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].autocomplete = getRandomString();
            }
        }
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const errorKey = this.validate();
        if (errorKey) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: `app.UserBaseCompanyDataForm.error.${errorKey}` }), { variant: "error" });
            return;
        }

        this.setState({
            loading: true,
        });

        if ((await this.props.submit(this.state.fields)) !== true) {
            this.setState({ loading: false });
        }
    }

    private validate(): string | undefined {
        if (this.props.disabledCompany) {
            const validResult = JSONSchemaValidator.validate(
                { selectedPlan: this.state.fields.selectedPlan },
                this.schemaPlan
            );
            if (!validResult.valid) {
                const errorKeys = Object.keys(validResult.errors);
                if (errorKeys.length > 0) {
                    return errorKeys[0];
                }
            }
            return;
        }

        const validResult = JSONSchemaValidator.validate(
            this.props.formType === UserBaseCompanyDataFormType.REGISTER
                    ? { ...{ selectedPlan: "" }, ...this.state.fields }
                    : { ...this.state.fields },
            this.props.formType === UserBaseCompanyDataFormType.REGISTER ? this.schemaWithPlan : this.schema,
        );
        if (!validResult.valid) {
            const errorKeys = Object.keys(validResult.errors);
            if (errorKeys.length > 0) {
                return errorKeys[0];
            }
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.changeValue(name, value);
    }

    private changeValue(name: string, value: string | ICountry | number | null) {
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        const onlyReadMode = this.props.disabledCompany || this.state.disabled;

        return (
            <div className="user-base-company-data-form">
                <form onSubmit={this.handleSubmit} noValidate>
                    {this.props.companyId && <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.id" })}
                        name="companyId"
                        value={this.props.companyId}
                        disabled={true}
                    />}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={getRandomString()}
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.companyName" })}
                        name="companyName"
                        autoComplete={getRandomString()}
                        autoFocus
                        value={this.state.fields.companyName}
                        onChange={this.handleChange}
                        disabled={onlyReadMode}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id={getRandomString()}
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.companyShortName" })}
                        name="companyShortName"
                        autoComplete={getRandomString()}
                        autoFocus
                        value={this.state.fields.companyShortName}
                        onChange={this.handleChange}
                        disabled={onlyReadMode}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={getRandomString()}
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.taxId" })}
                        name="taxId"
                        autoComplete={getRandomString()}
                        autoFocus
                        value={this.state.fields.taxId}
                        onChange={this.handleChange}
                        disabled={onlyReadMode}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id={getRandomString()}
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.street" })}
                        name="street"
                        autoComplete={getRandomString()}
                        autoFocus
                        value={this.state.fields.street}
                        onChange={this.handleChange}
                        disabled={onlyReadMode}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id={getRandomString()}
                        label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.street2" })}
                        name="street2"
                        autoComplete={getRandomString()}
                        autoFocus
                        value={this.state.fields.street2}
                        onChange={this.handleChange}
                        disabled={onlyReadMode}
                    />
                    <div className="user-base-company-data-form--form-row">
                        <div className="user-base-company-data-form--form-row-column50">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id={getRandomString()}
                                label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.city" })}
                                name="city"
                                autoComplete={getRandomString()}
                                autoFocus
                                value={this.state.fields.city}
                                onChange={this.handleChange}
                                disabled={onlyReadMode}
                            />
                        </div>
                        <div className="user-base-company-data-form--form-row-column50">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="state"
                                label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.state" })}
                                id={getRandomString()}
                                autoComplete={getRandomString()}
                                value={this.state.fields.state}
                                onChange={this.handleChange}
                                disabled={onlyReadMode}
                            />
                        </div>
                    </div>
                    <div className="user-base-company-data-form--form-row">
                        <div className="user-base-company-data-form--form-row-column50">
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id={getRandomString()}
                                label={this.props.intl.formatMessage({ id: "app.UserBaseCompanyDataForm.label.zipCode" })}
                                name="zipCode"
                                autoComplete={getRandomString()}
                                autoFocus
                                value={this.state.fields.zipCode}
                                onChange={this.handleChange}
                                disabled={onlyReadMode}
                            />
                        </div>
                        <div className="user-base-company-data-form--form-row-column50">
                            <Autocomplete
                                fullWidth
                                options={this.props.countries}
                                getOptionLabel={(option: ICountry) => option.countryName}
                                value={this.state.fields.country}
                                onChange={(_, value: ICountry | null) => {
                                    this.changeValue("country", value);
                                }}
                                disabled={onlyReadMode}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        label={<FormattedMessage id={"app.UserBaseCompanyDataForm.label.country"} />}
                                        margin="normal"
                                        variant="outlined"
                                        ref={this.fieldFormCountry}
                                        disabled={onlyReadMode}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    {this.props.formType === UserBaseCompanyDataFormType.REGISTER && <div className="user-base-company-data-form--select-plan">
                        <div className="user-base-company-data-form--select-plan-header">
                            <FormattedMessage id="app.UserBaseCompanyDataForm.selectPlan.label" />
                        </div>
                        <WedTable>
                            <TableBody>
                                {[1,2,3].map((id, key) => <TableRow key={key}>
                                    <TableCell>
                                        <div className="user-base-company-data-form--select-plan-label">
                                            <FormattedMessage id={`app.UserBaseCompanyDataForm.selectPlan.key${id}.label`} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="user-base-company-data-form--select-plan-description">
                                            <FormattedMessage id={`app.UserBaseCompanyDataForm.selectPlan.key${id}.description`} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="user-base-company-data-form--select-plan-price">
                                            <FormattedMessage id={`app.UserBaseCompanyDataForm.selectPlan.key${id}.price`} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="user-base-company-data-form--select-plan-action">
                                            <Radio
                                                checked={this.state.fields.selectedPlan === id}
                                                onChange={() => {
                                                    this.changeValue('selectedPlan', id);
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                </TableRow>)}
                            </TableBody>
                        </WedTable>
                    </div>}
                    <div className="user-base-company-data-form--buttons">
                        {this.props.formType === UserBaseCompanyDataFormType.REGISTER ? (
                            <Button type="submit" disabled={this.state.loading}>
                                <FormattedMessage id={"app.UserBaseCompanyDataForm.registerSubmit"} />
                            </Button>
                        ) : this.state.disabled ? (
                            <Button
                                type="button"
                                // eslint-disable-next-line
                                onClick={(event: any) => {
                                    event.preventDefault();
                                    this.setState({
                                        disabled: false,
                                    });
                                }}
                            >
                                <FormattedMessage id={"app.UserBaseCompanyDataForm.enableEdit"} />
                            </Button>
                        ) : (
                            <Button type="submit" disabled={this.state.loading}>
                                <FormattedMessage id={"app.UserBaseCompanyDataForm.submit"} />
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(UserBaseCompanyDataForm));
