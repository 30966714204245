export class WedHtmDomElementUtils {
    public static findParentNodeByClassName(element: HTMLElement, className: string, limit: number = 10): HTMLElement | undefined {
        let cnt = 0;
        do {
            if (element.classList.contains(className)) {
                return element;
            }
            if (!element.parentNode) {
                return undefined;
            }
            element = element.parentNode as HTMLElement;
            cnt++;
        } while (cnt < limit);
        return undefined;
    }
}
