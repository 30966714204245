import { IUserBase } from "../services/WedApi/Models/User";
import { getInitialsAndOwner } from "../services/WedApi/Utils/Initials";

export default class User {
    private readonly user: IUserBase;

    constructor(user: IUserBase) {
        this.user = user;
    }

    public getId() {
        return this.user.id;
    }

    public getFullName() {
        return `${this.user.firstName} ${this.user.lastName}`;
    }

    public getEmail() {
        return this.user.email;
    }

    public getInitials() {
        return getInitialsAndOwner(this.user).initials;
    }
}
