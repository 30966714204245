import React from "react";
import WedTable from "../../wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import UserCard from "../../user/UserCard/UserCard";
import WedDate from "../../formatter/WedDate";
import WedInsideFormElement from "../../form/WedInsideFormElement/WedInsideFormElement";
import Select from "@material-ui/core/Select";
import { WedIconDropDown } from "../../wed-icon/WedIconDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import { IAccountTeamUserProjectRole, ITeamUserProjectRole, IUser } from "../../../services/WedApi/Models/User";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconTrashDeleteRemove } from "../../wed-icon/generated/WedIconSvg";
import "./TeamWedTable.scss";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { LogicError } from "../../../errors/error-app";
import { InitialsUserType } from "../../tile/components/Initials/Initials";

interface RoleOptionItemInterface {
    // eslint-disable-next-line
    value: any;
    label: React.ReactElement | string;
}

interface LabelsInterface {
    userTitle?: React.ReactElement | string;
    userJoined?: React.ReactElement | string;
    userRole?: React.ReactElement | string;
    contactsTitle?: React.ReactElement | string;
    contactsJoined?: React.ReactElement | string;
}

// eslint-disable-next-line
type IUserMemberInterface = IUser & { role: any };

interface BasePropsInterface {
    userIsOwner: boolean;
    teamType: "PROJECT" | "ACCOUNT";
    hideTableHeaders?: boolean;
    hideJoinedColumn?: boolean;
    // eslint-disable-next-line
    users?: Array<IUserMemberInterface>;
    contacts?: IUser[];
    actions?: {
        // eslint-disable-next-line
        changeUserRole?: (id: number, newRole: any) => void;
        dennyAccessRole?: (id: number) => void;
        removeCustomerContact?: (id: number) => void;
    };
}

interface PropsInterface extends BasePropsInterface {
    userId: number;
}

class TeamWedTable extends React.Component<PropsInterface> {
    getRoles(): RoleOptionItemInterface[] {
        switch (this.props.teamType) {
            case "PROJECT":
                return [
                    {
                        value: ITeamUserProjectRole.ROLE_MEMBER,
                        label: <FormattedMessage id="app.project.role.member" />,
                    },
                    {
                        value: ITeamUserProjectRole.ROLE_OWNER,
                        label: <FormattedMessage id="app.project.role.owner" />,
                    },
                ];
            case "ACCOUNT":
                return [
                    {
                        value: IAccountTeamUserProjectRole.ROLE_MEMBER,
                        label: <FormattedMessage id="app.account.role.member" />,
                    },
                    {
                        value: IAccountTeamUserProjectRole.ROLE_OWNER,
                        label: <FormattedMessage id="app.account.role.owner" />,
                    },
                ];
        }
        throw new LogicError(`Can set only supported team type, get ${this.props.teamType}`);
    }

    getLabels(): LabelsInterface {
        switch (this.props.teamType) {
            case "PROJECT":
                return {
                    userTitle: <FormattedMessage id="app.TeamWedTable.project.tableHeader.projectTeamMembers" />,
                    userJoined: <FormattedMessage id="app.TeamWedTable.project.tableHeader.joined" />,
                    userRole: <FormattedMessage id="app.TeamWedTable.project.tableHeader.access" />,
                    contactsTitle: <FormattedMessage id="app.TeamWedTable.project.tableHeader.customerContacts" />,
                    contactsJoined: <FormattedMessage id="app.TeamWedTable.project.tableHeader.joined" />,
                };
            case "ACCOUNT":
                return {
                    userTitle: <FormattedMessage id="app.TeamWedTable.account.tableHeader.projectTeamMembers" />,
                    userJoined: <FormattedMessage id="app.TeamWedTable.account.tableHeader.joined" />,
                    userRole: <FormattedMessage id="app.TeamWedTable.account.tableHeader.access" />,
                    contactsTitle: <FormattedMessage id="app.TeamWedTable.account.tableHeader.customerContacts" />,
                    contactsJoined: <FormattedMessage id="app.TeamWedTable.account.tableHeader.joined" />,
                };
        }

        throw new LogicError(`Can set only supported team type, get ${this.props.teamType}`);
    }

    render() {
        const roles = this.getRoles();
        const labels = this.getLabels();

        return (
            <div className="team-wed-table">
                <WedTable>
                    {this.props.users !== undefined && (
                        <>
                            {!this.props.hideTableHeaders && (
                                <TableHead className="team-wed-table--team-members">
                                    <TableRow>
                                        <TableCell>
                                            <div className="team-wed-table--label-container team-wed-table--label-container-first-item">
                                                {labels.userTitle}
                                            </div>
                                        </TableCell>
                                        {!this.props.hideJoinedColumn && (
                                            <TableCell className="team-wed-table--no-display-small-screen">
                                                <div className="team-wed-table--label-container">{labels.userJoined}</div>
                                            </TableCell>
                                        )}
                                        <TableCell className="team-wed-table--no-display-small-screen">
                                            <div className="team-wed-table--label-container">{labels.userRole}</div>
                                        </TableCell>
                                        <TableCell className="team-wed-table--cell-action">&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {this.props.users.map((userMember, i) => {
                                    return (
                                        <TableRow key={`member-${i}`} className="team-wed-table--team-members">
                                            <TableCell>
                                                <UserCard userType={InitialsUserType.INTERNAL} user={userMember} />
                                            </TableCell>
                                            {!this.props.hideJoinedColumn && (
                                                <TableCell className="team-wed-table--no-display-small-screen">
                                                    <div className="team-wed-table--cell-container">
                                                        {userMember.created && <WedDate date={userMember.created} />}
                                                    </div>
                                                </TableCell>
                                            )}
                                            <TableCell className="team-wed-table--no-display-small-screen">
                                                <div className="team-wed-table--cell-container">
                                                    <WedInsideFormElement>
                                                        <Select
                                                            IconComponent={WedIconDropDown}
                                                            disabled={!this.props.userIsOwner || this.props.userId === userMember.id}
                                                            // eslint-disable-next-line
                                                            onChange={(event: React.FormEvent<any>) => {
                                                                this.props.actions?.changeUserRole &&
                                                                    // eslint-disable-next-line
                                                                    this.props.actions.changeUserRole(userMember.id, (event.target as any).value);
                                                            }}
                                                            value={userMember.role}
                                                        >
                                                            {roles.map((role, i) => (
                                                                <MenuItem key={i} value={role.value}>
                                                                    {role.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </WedInsideFormElement>
                                                </div>
                                            </TableCell>
                                            <TableCell className="team-wed-table--cell-action">
                                                <button
                                                    className="wed-icon-button"
                                                    disabled={!this.props.userIsOwner || this.props.userId === userMember.id}
                                                    onClick={() => {
                                                        this.props.actions?.dennyAccessRole && this.props.actions?.dennyAccessRole(userMember.id);
                                                    }}
                                                >
                                                    <WedIcon icon={<WedIconTrashDeleteRemove />} />
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </>
                    )}
                    {this.props.contacts !== undefined && this.props.contacts.length > 0 && (
                        <>
                            {!this.props.hideTableHeaders && (
                                <TableHead>
                                    <TableRow className="team-wed-table--customer-contacts">
                                        <TableCell>
                                            <div className="team-wed-table--label-container team-wed-table--label-container-first-item">
                                                {labels.contactsTitle}
                                            </div>
                                        </TableCell>
                                        {!this.props.hideJoinedColumn && (
                                            <TableCell className="team-wed-table--no-display-small-screen">
                                                <div className="team-wed-table--label-container">{labels.contactsJoined}</div>
                                            </TableCell>
                                        )}
                                        <TableCell className="team-wed-table--no-display-small-screen">&nbsp;</TableCell>
                                        <TableCell className="team-wed-table--cell-action">&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                            )}
                            <TableBody>
                                {this.props.contacts.map((customerContact, i) => {
                                    return (
                                        <TableRow key={`customer-contact-${i}`} className="team-wed-table--customer-contacts">
                                            <TableCell>
                                                <UserCard userType={InitialsUserType.EXTERNAL} user={customerContact} />
                                            </TableCell>
                                            {!this.props.hideJoinedColumn && (
                                                <TableCell className="team-wed-table--no-display-small-screen">
                                                    <div className="team-wed-table--cell-container">
                                                        {customerContact.created && <WedDate date={customerContact.created} />}
                                                    </div>
                                                </TableCell>
                                            )}
                                            <TableCell className="team-wed-table--no-display-small-screen">&nbsp;</TableCell>
                                            <TableCell className="team-wed-table--cell-action">
                                                {this.props.actions?.removeCustomerContact && (
                                                    <button
                                                        className="wed-icon-button"
                                                        disabled={!this.props.userIsOwner}
                                                        onClick={() => {
                                                            this.props.actions?.removeCustomerContact &&
                                                                this.props.actions.removeCustomerContact(customerContact.id);
                                                        }}
                                                    >
                                                        <WedIcon icon={<WedIconTrashDeleteRemove />} />
                                                    </button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </>
                    )}
                </WedTable>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id as number,
    };
};

export default connect(mapStateToProps)(TeamWedTable);
