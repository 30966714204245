import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IAccountTeamUser } from "../../../services/WedApi/Models/User";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    clientId: number;
    userMember?: IAccountTeamUser;
    close: (changed?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
}

class UserClientDennyAccessRolePopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.dennyAccessTeamUser = this.dennyAccessTeamUser.bind(this);
    }

    private async dennyAccessTeamUser() {
        if (this.props.userMember) {
            try {
                await container.clientService.dennyAccessTeamUser(this.props.clientId, this.props.userMember.id);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.user.UserClientDennyAccessRolePopup.success" }), {
                    variant: "success",
                });
            } catch (e) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                return false;
            }
        }
    }

    render() {
        return (
            <PopupConfirmButton
                open={!!this.props.userMember}
                close={this.props.close}
                doIt={this.dennyAccessTeamUser}
                header={<FormattedMessage id={"app.user.UserClientDennyAccessRolePopup.header.title"} />}
                content={
                    this.props.userMember ? (
                        <FormattedMessage
                            id={"app.user.UserClientDennyAccessRolePopup.header.content"}
                            values={{
                                firstName: this.props.userMember.firstName,
                                lastName: this.props.userMember.lastName,
                            }}
                        />
                    ) : undefined
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(UserClientDennyAccessRolePopup));
