import { Action, Dispatch } from "redux";
import { INotifsItem } from "../services/WedApi/Models/Notifs";

export interface SetNotifsProps {
    setNotifsData: (item: INotifsItem[]) => void;
}

export interface NotifsProps {
    notifsItems?: INotifsItem[];
}

const initialState: NotifsProps = {
    notifsItems: undefined,
};

export function setNotifsData(dispatch: Dispatch): (items: INotifsItem[]) => void {
    return (notifsItems: INotifsItem[]) => {
        dispatch({
            type: NotifsReducerAction.SET_NOTIFS_DATA,
            payload: {
                notifsItems,
            },
        });
    };
}

export enum NotifsReducerAction {
    SET_NOTIFS_DATA = "NotifsReducer/SET_NOTIFS_DATA",
}

// eslint-disable-next-line
export const NotifsReducer = (state: NotifsProps = initialState, action: Action & { payload: any }) => {
    switch (action.type) {
        case NotifsReducerAction.SET_NOTIFS_DATA:
            return { ...state, ...action.payload };
    }
    return state;
};
