import React, { Component } from "react";
import "./AdminPanelActionButton.scss";

interface PropsInterface {
    extraAction?: React.ReactNode;
    children?: React.ReactNode | React.ReactNodeArray;
}

export class AdminPanelActionButton extends Component<PropsInterface> {
    render() {
        return <div className="admin-panel-action-button">
            {this.props.extraAction && <div className="admin-panel-action-button--extra-action">{this.props.extraAction}</div>}
            {this.props.children}
        </div>;
    }
}
