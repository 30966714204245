import { IApiObject, IFile } from "./File";
import { IOwner } from "./User";

export enum CollectionCreatedByEnum {
    me = "me",
    sharedWithMe = "shared-with-me",
}

export enum CollectionStatusEnum {
    locked = "locked",
    unlocked = "unlocked",
}

export interface IApiCollection {
    id: number;
    name: string;
    description: string;
    objectIDs: number[];
    projectID: number;
    ownerID: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baUsers: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sharedTo: any[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    publicNotes: any[];
    createdAt: string;
    updatedAt?: string;
    updatedBy?: number;
    userIsOwner: boolean;
    locked: boolean;
    approvalRequired: boolean;
    approvalFlag: number;
}

export interface IApiCollectionDetails extends IApiCollectionListItem {}

export interface IApiCollectionListItem {
    collection: IApiCollection;
    objects: IApiObject[];
}

export interface ICollection {
    id: number;
    name: string;
    description: string;
    status: CollectionStatusEnum;
    createdBy?: IOwner;
    created: Date;
    updatedBy?: IOwner;
    updated?: Date;
    userIsOwner: boolean;
    locked: boolean;
    approvalRequired: boolean;
    approvalFlag: number;
    files: IFile[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    baUsers: any[];
    baUsersItems?: IOwner[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sharedTo: any[];
    sharedToItems?: IOwner[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    publicNotes: any[];
}

export interface ICollectionExtended extends ICollection {}
