import * as React from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMenuButtons } from "../../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import { SizeType, WedIcon } from "../../../../../components/wed-icon/WedIcon";
import { WedIconPlusAdd } from "../../../../../components/wed-icon/generated/WedIconSvg";
import ClientListActionButton from "../ClientListActionButton/ClientListActionButton";
import { ISelectItem } from "../../../../../components/status-action-button/StatusActionButton";

interface PropsInterface extends WrappedComponentProps {
    createClientClick: () => void;
    selectedItems: ISelectItem[];
}

interface StateInterface {}

class ClientListButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { createClientClick, selectedItems } = this.props;

        return (
            <WedMenuButtons>
                <Button onClick={createClientClick} type="submit" startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconPlusAdd />} />}>
                    <FormattedMessage id="app.ClientListButtons.createProject" />
                </Button>
                <ClientListActionButton selectedItems={selectedItems} />
            </WedMenuButtons>
        );
    }
}

export default injectIntl(ClientListButtons);
