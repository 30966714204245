import React from "react";
import classNames from "classnames";
import SummaryPageBoxContainer from "../SummaryPageBoxContainer/SummaryPageBoxContainer";
import "./InformationSummary.scss";

interface PropsInterface {
    className?: string;
    header?: string | React.ReactElement;
    leftSide?: string | React.ReactElement;
    rightSide?: string | React.ReactElement;
    footer?: string | React.ReactElement;
}

interface StateInterface {}

class InformationSummary extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <SummaryPageBoxContainer className={classNames("information-summary", this.props.className)} header={this.props.header}>
                <div className="information-summary--column-grid">
                    <div className="information-summary--column-grid-left">{this.props.leftSide}</div>
                    <div className="information-summary--column-grid-right">{this.props.rightSide}</div>
                </div>
                <div className="information-summary--description">{this.props.footer}</div>
            </SummaryPageBoxContainer>
        );
    }
}

export default InformationSummary;
