import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "@material-ui/core";
import Popup from "../../../../../components/popup/Popup/Popup";
import PopupContent from "../../../../../components/popup/PopupContent/PopupContent";
import PopupHeader, { PositionTitle } from "../../../../../components/popup/PopupHeader/PopupHeader";
import PopupAction from "../../../../../components/popup/PopupAction/PopupAction";
import { IFile } from "../../../../../services/WedApi/Models/File";
import container from "../../../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    files: Pick<IFile, "id" | "name">[];
    handleClose: (updated?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
}

class RemoveFromPortfolioPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { loading: false };
    }

    removeFileFromCollection = async () => {
        if (!this.state.loading) {
            this.setState({ loading: true });

            const { enqueueSnackbar, intl, handleClose } = this.props;

            try {
                await container.userService.removeFromPrivatePortfolio(this.props.files.map((file) => file.id));

                enqueueSnackbar(intl.formatMessage({ id: `app.RemoveFromPortfolioPopup.success` }), { variant: "success" });
                this.setState({ loading: false });
                handleClose(true);
            } catch (e) {
                enqueueSnackbar(intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                this.setState({ loading: false });
                handleClose(false);
            }
        }
    };

    render() {
        const { handleClose, files } = this.props;

        return (
            <Popup open={true} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.RemoveFromPortfolioPopup.title`} />
                    </PopupHeader>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage
                                id={"app.RemoveFromPortfolioPopup.content"}
                                values={{ fileName: '"' + files.map((file) => file.name).join('", "') + '"' }}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <PopupAction>
                        <Button
                            type="submit"
                            onClick={async () => {
                                await this.removeFileFromCollection();
                            }}
                            className="button--more-padding"
                            disabled={this.state.loading}
                        >
                            <FormattedMessage id="app.standard.popup.button.remove" />
                        </Button>
                        <Button
                            type="submit"
                            onClick={() => {
                                handleClose();
                            }}
                            className="button--more-padding"
                        >
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(RemoveFromPortfolioPopup));
