import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { IUserMyProfileRole } from "../../../services/WedApi/Models/User";
import UserEditProfilePopup from "../UserEditProfilePopup/UserEditProfilePopup";

interface BasePropsInterface extends WrappedComponentProps, ProviderContext {
    close: () => void;
}

interface PropsInterface extends BasePropsInterface {
    userId: number;
    userEmail: string;
    userRole: IUserMyProfileRole;
    fields: {
        firstName: string;
        lastName: string;
        cCode: number;
        phone: string;
    };
}

interface StateInterface {}

class UserMyProfilePopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <UserEditProfilePopup
                close={this.props.close}
                userEmail={this.props.userEmail}
                userId={this.props.userId}
                userRole={this.props.userRole}
                fields={{ ...this.props.fields }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id || 0,
        userEmail: state.user.data?.email || "",
        userRole: state.user.data?.role || IUserMyProfileRole.UNDEFINED,
        fields: {
            firstName: state.user.data?.firstName || "",
            lastName: state.user.data?.lastName || "",
            cCode: state.user.data?.cCode || 0,
            phone: state.user.data?.phone || "",
        },
    } as PropsInterface;
};

export default withSnackbar(injectIntl(connect(mapStateToProps)(UserMyProfilePopup)));
