import React, { Component } from "react";
import FilesPage from "../../../components/template/FilesPage/FilesPage";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface PropsInterface extends SetPageInfoBoxTitlesActionProps, WrappedComponentProps {}

interface StateInterface {}

class MyLibraryFilesPage extends Component<PropsInterface, StateInterface> {
    componentDidMount() {
        this.props.setPageInfoBoxTitlesAction({
            title: this.props.intl.formatMessage({ id: "app.myLibraryMenu.files" }),
        });
    }

    render() {
        return <FilesPage fileType={FileTypeEnum.MY_LIBRARY_FILES} />;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(injectIntl(MyLibraryFilesPage));
