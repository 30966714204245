import { createStyles } from "@material-ui/core";

const snackbarProviderStyles = createStyles({
    iconButton: {
        color: "#FFF",
    },
    errorOutlinedIcon: {
        marginRight: "10px",
    },
});

export default snackbarProviderStyles;
