import React from "react";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import CollectionPage from "../../../components/template/CollectionPage/CollectionPage";

interface BasePropsInterface extends ProjectDetailLoaderProps {}

interface PropsInterface extends BasePropsInterface {
    fileType: FileTypeEnum;
}

interface StateInterface {}

class ProjectCollectionPage extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <CollectionPage fileType={this.props.fileType} projectProps={{ project: this.props.project, fetchProject: this.props.fetchProject }} />
        );
    }
}

export default ProjectCollectionPage;
