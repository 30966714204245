import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import CreateCollection from "../CreateCollection/CreateCollection";

interface PropsInterface {
    open: boolean;
    handleClose: (collectionId?: number) => void;
    projectId?: number;
    objectIds?: number[];
    redirectToCollection?: boolean;
}

interface StateInterface {}

export class CreateCollectionPopup extends React.Component<PropsInterface, StateInterface> {
    redirectToCollection = () => {
        const { redirectToCollection } = this.props;

        if (redirectToCollection !== undefined) {
            return redirectToCollection;
        }

        return true;
    };

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose} maxWidth="sm">
                <PopupContent>
                    <CreateCollection
                        projectId={this.props.projectId}
                        close={this.props.handleClose}
                        objectIds={this.props.objectIds}
                        redirectToCollection={this.redirectToCollection()}
                    />
                </PopupContent>
            </Popup>
        );
    }
}
