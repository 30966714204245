import React, { Component } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { AdminPanelTable } from "../../components/AdminPanelTable/AdminPanelTable";
import { Button, LinearProgress } from "@material-ui/core";
import container from "../../../../container";
import { IBillingHistory } from "../../../../services/WedApi/Models/User";
import { WedIconDownloadSaveUpload1 } from "../../../../components/wed-icon/generated/WedIconSvg";
import AdminPanelIconButton from "../../components/AdminPanelIconButton/AdminPanelIconButton";
import { ProviderContext, withSnackbar } from "notistack";
import { downloadFile } from "../../../../services/WedApi/Utils/DownloadFile";
import WedDate from "../../../../components/formatter/WedDate";
import { SizeType, WedIcon } from "../../../../components/wed-icon/WedIcon";
import "./AdminPanelBillingHistory.scss";
import { AdminPanelActionButton } from "../../components/AdminPanelActionButton/AdminPanelActionButton";

const TABLE_HEADERS = [
    "app.AdminPanelBillingHistory.header.date",
    "app.AdminPanelBillingHistory.header.invoiceNumber",
    "app.AdminPanelBillingHistory.header.netValue",
    "app.AdminPanelBillingHistory.header.vatRate",
    "app.AdminPanelBillingHistory.header.vat",
    "app.AdminPanelBillingHistory.header.total",
    "app.AdminPanelBillingHistory.header.download",
];

interface PropsInterface extends WrappedComponentProps, ProviderContext {}

interface StateInterface {
    loading: boolean;
    items: IBillingHistory[];
}

class AdminPanelBillingHistory extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            items: [],
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            loading: false,
            items: await container.adminService.getBillingHistory(),
        });
    }

    private getRow(item: IBillingHistory): Array<string | React.ReactElement | undefined> {
        return [
            <WedDate key={0} date={item.issueDate} />,
            `${item.number}`,
            `${item.netValue}`,
            `${item.vatRate}`,
            `${item.vatValue}`,
            `${item.totalValue}`,
            <AdminPanelIconButton
                key={6}
                icon={<WedIconDownloadSaveUpload1 />}
                onClick={async () => {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelBillingHistory.initDownload" }), {
                        variant: "success",
                        autoHideDuration: 4000,
                    });
                    downloadFile(await container.adminService.getInvoiceUrl(item.id));
                }}
            />,
        ];
    }

    render() {
        return (
            <div className="admin-panel-billing-history">
                {this.state.loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        <AdminPanelTable headers={TABLE_HEADERS} centerIdx={[3, 6]} rows={this.state.items.map((item) => this.getRow(item))} />
                        <AdminPanelActionButton>
                            <Button
                                type="submit"
                                onClick={async () => {
                                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelBillingHistory.initDownload" }), {
                                        variant: "success",
                                        autoHideDuration: 4000,
                                    });
                                    downloadFile(await container.adminService.getAllInvoiceUrl());
                                }}
                                startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconDownloadSaveUpload1 />} />}
                                className="button--more-padding"
                            >
                                <FormattedMessage id="app.AdminPanelBillingHistory.downloadAllButton" />
                            </Button>
                        </AdminPanelActionButton>
                    </>
                )}
            </div>
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelBillingHistory));
