import React from "react";
import { ICommentNote, ICommentNoteType } from "../../../services/WedApi/Models/Note";
import "./CommentNoteSummaryBox.scss";
import Initials from "../../tile/components/Initials/Initials";
import { getInitialsAndOwner, InitialsAndOwnerInterface } from "../../../services/WedApi/Utils/Initials";
import WedDate from "../../formatter/WedDate";
import classNames from "classnames";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface PropsInterface extends WrappedComponentProps {
    object: ICommentNote;
}

interface StateInterface {}

class CommentNoteSummaryBox extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
    }

    private getlabelAndName() {
        let name = "";
        let label = "";

        if (ICommentNoteType.PROJECT_PRIVATE_NOTE === this.props.object.type) {
            name = this.props.object.item.project?.name || "";
            label = this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.recentComments.project" });
        } else if (ICommentNoteType.COLLECTION_NOTE === this.props.object.type) {
            name = this.props.object.item.collection?.name || "";
            label = this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.recentComments.collection" });
        } else if ([ICommentNoteType.OBJECT_PRIVATE_NOTE, ICommentNoteType.OBJECT_PUBLIC_NOTE].includes(this.props.object.type)) {
            name = this.props.object.item.file?.name || "";
            label = this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.recentComments.file" });
        }

        return {
            name,
            label,
        };
    }

    render() {
        const userInitials: InitialsAndOwnerInterface | undefined = this.props.object.item.createdBy
            ? getInitialsAndOwner(this.props.object.item.createdBy)
            : undefined;

        const { label, name } = this.getlabelAndName();

        return (
            <div className={classNames("summary-page-recent-comments-item")}>
                <div className="summary-page-recent-comments-item--container">
                    <div className="summary-page-recent-comments-item--container-line-wrapper summary-page-recent-comments-item--container-line-wrapper-top">
                        <div className="summary-page-recent-comments-item--container-line" />
                    </div>
                    <div className="summary-page-recent-comments-item--wrapper">
                        <div className="summary-page-recent-comments-item--initials">
                            <Initials
                                userType={this.props.object.item.userType}
                                title={userInitials ? userInitials.initials : ""}
                                fullTitle={userInitials ? userInitials.owner : undefined}
                            />
                        </div>
                        <div className="summary-page-recent-comments-item--content">
                            <div className="summary-page-recent-comments-item--content-top">
                                <div className="summary-page-recent-comments-item--content-user">
                                    <div className="summary-page-recent-comments-item--content-header">{userInitials ? userInitials.owner : ""}</div>
                                    <div className="summary-page-recent-comments-item--content-created">
                                        {this.props.object.item.created && <WedDate date={this.props.object.item.created} />}
                                    </div>
                                </div>
                                <div className="summary-page-recent-comments-item--footer">
                                    <span className="summary-page-recent-comments-item--name">{label}:</span><span className="summary-page-recent-comments-item--label">{name}</span>
                                </div>
                            </div>
                            <div className="summary-page-recent-comments-item--content-description">{this.props.object.item.content}</div>
                        </div>
                    </div>
                    <div className="summary-page-recent-comments-item--container-line-wrapper summary-page-recent-comments-item--container-line-wrapper-bottom">
                        <div className="summary-page-recent-comments-item--container-line" />
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(CommentNoteSummaryBox);
