import React from "react";
import ReactDOM from "react-dom";
import configureStore from "./configure-store";
import App from "./App";
import container from "./container";
import "./index.css";

const store = configureStore(container.history);

ReactDOM.render(<App store={store} history={container.history} />, document.getElementById("root"));
