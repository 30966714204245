import { ISelectItem } from "../../../components/status-action-button/StatusActionButton";

type DataObject = ISelectItem["object"];

export function refreshSelectedItems(
    selectedItems: ISelectItem[],
    dataItems: Array<{ type: ISelectItem["type"]; items: DataObject[] }>
): ISelectItem[] {
    const results: ISelectItem[] = [];
    selectedItems.forEach((selectedItem) => {
        if (selectedItem.object) {
            const items = dataItems.find((dataItem) => dataItem.type === selectedItem.type);
            const item = items?.items.find((item) => item && item.id === selectedItem.id);
            if (item) {
                selectedItem.object = item;
                results.push(selectedItem);
            }
        }
    });
    return results;
}
