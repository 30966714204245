import React from "react";
import "./FilterFormBox.scss";
import classnames from "classnames";
import { toReactNodeArray } from "../../../services/WedApi/Utils/ReactNodeArray";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
    className?: string;
}

export class FilterFormBox extends React.Component<PropsInterface> {
    render() {
        return (
            <div className={classnames("filter-form-box", ...(this.props.className ? [this.props.className] : []))}>
                <ul className="filter-form-box__container">
                    {toReactNodeArray(this.props.children).map((item, key) => {
                        return (
                            <li key={key} className="filter-form-box__item">
                                {item}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
