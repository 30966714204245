import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import { ApplicationState } from "./store";
import ErrorBoundary from "./components/layout/ErrorBoundary/ErrorBoundary";
import LocaleProvider from "./services/Locale/services/LocaleProvider/LocaleProvider";
import ThemeProvider from "./components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import SnackbarProvider from "./components/snackbar-provider/SnackbarProvider";
import "./App.css";
import "./styles/global.scss";

interface MainProps {
    store: Store<ApplicationState>;
    history: History;
}

const App: React.FC<MainProps> = (props: MainProps) => {
    return (
        <Provider store={props.store}>
            <ThemeProvider>
                <CssBaseline />
                <SnackbarProvider>
                    <ConnectedRouter history={props.history}>
                        <LocaleProvider>
                            <ErrorBoundary>
                                <Routes />
                            </ErrorBoundary>
                        </LocaleProvider>
                    </ConnectedRouter>
                </SnackbarProvider>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
