import React from "react";
import WedMediaGallery from "../../../components/wed-media-gallery/WedMediaGallery/WedMediaGallery";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import SharedHeader from "../../../components/header/ShareHeader/SharedHeader";
import container from "../../../container";
import { IShareCollection } from "../../../services/WedApi/Models/ShareCollection";
import { LinearProgress } from "@material-ui/core";
import { AppNotFoundItemError } from "../../../errors/error-app";
import "./ShareCollectionFilePage.scss";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { RefreshMediaGalleryParams } from "../../FilesMediaGalleryPage/FilesMediaGalleryPage";
import ShareLayoutPage from "../components/ShareLayoutPage/ShareLayoutPage";

interface PropsInterface {
    token: string;
    collectionId: number;
    fileId: number;
    versionId?: number;
}

interface StateInterface {
    isLoading: boolean;
    collection: IShareCollection | undefined;
    reloadingMediaGallery: boolean;
    reloadingVersionId?: number;
}

class ShareCollectionFilePage extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            collection: undefined,
            reloadingMediaGallery: false,
            reloadingVersionId: this.props.versionId || undefined,
        };
        this.onRefreshMediaGallery = this.onRefreshMediaGallery.bind(this);
        this.triggerRefresh = this.triggerRefresh.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({ isLoading: true });

        try {
            const collection = await container.shareCollectionService.getCollectionDetails(this.props.token, this.props.collectionId);
            this.setState({ collection });
        } finally {
            this.setState({ isLoading: false });
        }
    }

    private onRefreshMediaGallery(params?: RefreshMediaGalleryParams) {
        this.setState({
            reloadingMediaGallery: true,
        });
        container.shareCollectionService
            .getCollectionDetails(this.props.token, this.props.collectionId)
            .then((collection) => {
                this.setState({
                    collection,
                    reloadingMediaGallery: false,
                    reloadingVersionId: params?.versionId,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    private triggerRefresh() {
        this.onRefreshMediaGallery({ versionId: this.state.reloadingVersionId });
    }

    render() {
        const { collectionId, token } = this.props;
        const { isLoading, reloadingMediaGallery, collection, reloadingVersionId } = this.state;

        if (isLoading || reloadingMediaGallery) {
            return <LinearProgress />;
        }

        if (!collection) {
            throw new AppNotFoundItemError("collection", collectionId);
        }

        return (
            <ShareLayoutPage
                className="shared-collection-file"
                header={
                    <SharedHeader
                        token={token}
                        buttons={{ download: true, collectionNavigation: true, makeDecision: true }}
                        collection={collection}
                        refreshHandle={this.triggerRefresh}
                        disableChangeViewMode={true}
                    />
                }
            >
                <div className="shared-collection-file__content">
                    <SidebarWrapper
                        shareSidebar={{
                            token: token,
                            loading: !!reloadingMediaGallery,
                            collection: collection,
                            fileId: this.props.fileId,
                            versionId: this.props.versionId,
                            reducer: true,
                        }}
                        handleSidebarChanges={async () => {
                            await this.fetchData();
                        }}
                    >
                        <WedMediaGallery
                            onRefreshMediaGallery={this.onRefreshMediaGallery}
                            token={token}
                            collectionId={collectionId}
                            fileType={FileTypeEnum.SHARED_COLLECTION_FILES}
                            versionId={reloadingVersionId}
                        />
                    </SidebarWrapper>
                </div>
            </ShareLayoutPage>
        );
    }
}

export default ShareCollectionFilePage;
