import {IProject} from "../Models/Project";
import FilesActionApiService from "../FilesActionApiService";
import {IApiDirectory, IApiFilesResponse, IFolder} from "../Models/File";
import {uniqueValues} from "../Utils/ItemCollection";
import {IOwner} from "../Models/User";
import UserActionApiService from "../UserActionApiService";
import {SizeImageEnum} from "./DataParameters/SizeImageEnum";
import {ConvertApiFileContextEnum, convertIApiFolderToIFolder, convertIApiObjectToIFile} from "./BaseConverter";
import ProjectFilesActionApiService from "../ProjectFilesActionApiService";
import MyLibraryFilesActionApiService from "../MyLibraryFilesActionApiService";
import {FileTypeEnum} from "../../../components/template/Type/FileTypeEnum";
import {LogicError} from "../../../errors/error-app";
import IManagerFolderAccessParameters from "./DataParameters/IManagerFolderAccessParameters";

export interface IProjectList {
    projects: IProject[];
}

export class FilesService {
    constructor(
        private readonly filesActionApiService: FilesActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly projectFilesActionApiService: ProjectFilesActionApiService,
        private readonly myLibraryFilesActionApiService: MyLibraryFilesActionApiService
    ) {
    }

    public getFileUrl(
        id: number,
        vid: number,
        options?: { userId?: number; size?: SizeImageEnum; fileType?: FileTypeEnum; collectionId?: number }
    ): string {
        if (options?.fileType && [FileTypeEnum.MY_LIBRARY_COLLECTIONS, FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES].includes(options?.fileType)) {
            if (!options?.collectionId) {
                throw new LogicError(`getFileUrl with fileType ${options.fileType} required collectionId`);
            }
            return this.filesActionApiService.getMyLibraryCollectionFileUrl(id, vid, options.collectionId, options);
        }
        if (options?.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO) {
            if (!options?.userId) {
                throw new LogicError(`getFileUrl with fileType ${options.fileType} required userId`);
            }
            return this.filesActionApiService.getPrivatePortfolioFileUrl(id, vid, options.userId, options);
        }
        return this.filesActionApiService.getFileUrl(id, vid, options);
    }

    public getSharedFileUrl(token: string, oid: number, vid: number, cid: number, size?: SizeImageEnum): string {
        return this.filesActionApiService.getSharedFileUrl(token, oid, vid, cid, size);
    }

    public getDirectoryDownloadUrl(id: number): string {
        return this.filesActionApiService.getDirectoryDownloadUrl(id);
    }

    public async getFolder(type: FileTypeEnum, folderId?: number, options?: { userId?: number, withAccess?: boolean }): Promise<IFolder> {
        let folder: IApiFilesResponse;
        if ([FileTypeEnum.MY_LIBRARY_FILES, FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME].includes(type)) {
            const myLibraryData = await this.myLibraryFilesActionApiService.getFolder(folderId);
            folder = {
                ...myLibraryData,
                subfolders: myLibraryData.subfolders.filter(subFolder => {
                    return options?.userId &&
                        (((FileTypeEnum.MY_LIBRARY_FILES === type) && subFolder.ownerID === options.userId) ||
                            ((FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME === type) && subFolder.ownerID !== options.userId)
                        );
                })
            };
        } else {
            if (!folderId) {
                throw new LogicError("folderId is required");
            }
            folder = await this.projectFilesActionApiService.getFolder(folderId);
        }

        const userIds: number[] = [];
        folder.objects.forEach((object) => {
            if (object.ownerIDs.length > 0) {
                userIds.push(...object.ownerIDs);
            }
            if (object.updatedByID) {
                userIds.push(object.updatedByID)
            }
            if (object.createdByID) {
                userIds.push(object.createdByID)
            }
        });
        folder.subfolders.forEach((folder) => {
            if (folder.ownerID) {
                userIds.push(folder.ownerID);
            }
        });
        if (folder.currentFolder.ownerID) {
            userIds.push(folder.currentFolder.ownerID);
        }
        if (options?.withAccess) {
            if (folder.currentFolder.writeAccess?.length > 0) {
                userIds.push(...folder.currentFolder.writeAccess);
            }
            if (folder.currentFolder.readAccess?.length > 0) {
                userIds.push(...folder.currentFolder.readAccess);
            }
        }
        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniqueValues(userIds))).items : [];

        return {
            directory: convertIApiFolderToIFolder(folder.currentFolder, users, options),
            directories: folder.subfolders.map((folder) => {
                return convertIApiFolderToIFolder(folder, users);
            }),
            files: folder.objects.map((object) => {
                return convertIApiObjectToIFile(object, users, ConvertApiFileContextEnum.library);
            }),
        };
    }

    public async createFolder(parentDirId: number, name: string, description: string | undefined): Promise<number> {
        const folder: IApiDirectory = await this.filesActionApiService.createFolder(parentDirId, name, description);
        return folder.id;
    }

    public renameFile(id: number, name: string): Promise<void> {
        return this.filesActionApiService.renameFile(id, name);
    }

    public async managerFolderAccess(parameters: IManagerFolderAccessParameters): Promise<void> {
        if (
            parameters.readAccessAdd.length > 0 ||
            parameters.readAccessRemove.length > 0 ||
            parameters.writeAccessAdd.length > 0 ||
            parameters.writeAccessRemove.length > 0
        ) {
            await this.filesActionApiService.managerFolderAccess(parameters);
        }
    }

    public renameFolder(id: number, name: string): Promise<void> {
        return this.filesActionApiService.renameFolder(id, name);
    }

    public changeFileDescription(id: number, name: string, asPublic?: boolean): Promise<void> {
        return this.filesActionApiService.changeFileDescription(id, name, asPublic || false);
    }

    public changeFolderDescription(id: number, description: string): Promise<void> {
        return this.filesActionApiService.changeFolderDescription(id, description);
    }

    public async moveFileToTrash(ids: number[]): Promise<void> {
        return this.filesActionApiService.actionFile(ids, "trash");
    }

    public async moveFolderToTrash(ids: number[]): Promise<void> {
        return this.filesActionApiService.actionFolder(ids, "trash");
    }

    public async moveFolder(ids: number[], moveToID: number): Promise<void> {
        return this.filesActionApiService.moveFolder(ids, moveToID);
    }

    public async moveFile(ids: number[], moveToID: number): Promise<void> {
        return this.filesActionApiService.moveFile(ids, moveToID);
    }

    public async removeFileVersion(objectId: number, versionId: number): Promise<void> {
        return this.filesActionApiService.removeFileVersion(objectId, versionId);
    }

    public async updateObjectTags(objectId: number, tags: string[]): Promise<void> {
        return this.filesActionApiService.updateObjectTags(objectId, tags);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFile(formData: FormData, config: { onProgressHandle: (uploadedBytes: number) => void }): Promise<any> {
        return this.filesActionApiService.uploadFile(formData, config);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFileVersion(formData: FormData, config: { onProgressHandle: (uploadedBytes: number) => void }): Promise<any> {
        return this.filesActionApiService.uploadFileVersion(formData, config);
    }
}
