import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupAction from "../../popup/PopupAction/PopupAction";
import { Button } from "@material-ui/core";
import { ProviderContext, withSnackbar } from "notistack";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    objectId: number;
    versionId: number;
    open: boolean;
    handleClose: () => void;
    onSuccess: () => void;
}

interface StateInterface {
    loading: boolean;
}

class RemoveObjectVersionPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { loading: false };
        this.removeVersion = this.removeVersion.bind(this);
    }

    private async removeVersion() {
        if (!this.state.loading) {
            this.setState({ loading: true });

            try {
                await container.filesService.removeFileVersion(this.props.objectId, this.props.versionId);

                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.RemoveObjectVersionPopup.success" }), { variant: "success" });
                this.props.onSuccess();
                this.setState({ loading: false });
            } catch (e) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                this.setState({ loading: false });
            }
        }
    }

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={"app.RemoveObjectVersionPopup.title"} />
                    </PopupHeader>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage id={"app.RemoveObjectVersionPopup.content"} />
                        </DialogContentText>
                    </DialogContent>
                    <PopupAction>
                        <Button type="submit" onClick={this.removeVersion} className="button--more-padding" disabled={this.state.loading}>
                            <FormattedMessage id="app.RemoveObjectVersionPopup.confirm" />
                        </Button>
                        <Button type="submit" onClick={this.props.handleClose} className="button--more-padding">
                            <FormattedMessage id="app.standard.popup.button.cancel" />
                        </Button>
                    </PopupAction>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(RemoveObjectVersionPopup));
