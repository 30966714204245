import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import NotLoggedPopupPage from "../../../components/template/NotLoggedPopupPage/NotLoggedPopupPage";
import UserCompanyDataForm from "../../../components/user/UserCompanyDataForm/UserCompanyDataForm";

interface PropsInterface extends RouteComponentProps {}

class CompanyDataPage extends Component<PropsInterface> {
    render() {
        return (
            <NotLoggedPopupPage
                maxWidth="lg"
                className="company-data-page"
                header={<FormattedMessage id={"app.UserBaseCompanyDataForm.header"} />}
                withLogo={true}
            >
                <UserCompanyDataForm />
            </NotLoggedPopupPage>
        );
    }
}

export default withRouter(CompanyDataPage);
