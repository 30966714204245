import React, { Component } from "react";
import { INotifsItem } from "../../../services/WedApi/Models/Notifs";
import { WedDropDownMenu } from "../../wed-drop-down-menu/WedDropDownMenu/WedDropDownMenu";
import LinearProgress from "@material-ui/core/LinearProgress";
import MenuItem from "@material-ui/core/MenuItem";
import { connect } from "react-redux";
import { setNotifsData, SetNotifsProps } from "../../../store/NotifsReducer";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { createStyles, Divider, WithStyles, withStyles } from "@material-ui/core";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconDeleteDisabled } from "../../wed-icon/generated/WedIconSvg";
import SidebarScrollbars from "../../sidebar/SidebarScrollbars/SidebarScrollbars";
import classNames from "classnames";
import { Dispatch } from "redux";
import container from "../../../container";
import "./UserNotifs.scss";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface BasePropsInterface {
    notifsItems: INotifsItem[];
}

interface PropsInterface extends BasePropsInterface, SetNotifsProps, WithStyles, WrappedComponentProps, ProviderContext {}

interface StateInterface {
    loading: { [key: string]: boolean | undefined };
}

const styles = createStyles({
    root: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    divider: { margin: "5px 0" },
});

class UserNotifs extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { loading: {} };
    }

    private async removeItem(item: INotifsItem): Promise<void> {
        const key = `removeItem${item.id}`;
        this.setState({ loading: { ...this.state.loading, [key]: true } });
        try {
            await container.notifsService.removeItem(item.id);
            this.props.setNotifsData(await container.notifsService.getList());
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserNotifs.success" }), { variant: "success" });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_NOTIFICATION_INVALID) {
                keyTranslate = "app.UserNotifs.error.errNotificationInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
        const loadingProps = { ...this.state.loading };
        delete loadingProps[key];
        this.setState({ loading: loadingProps });
    }

    private async removeAll() {
        const loadingKeys = { ...this.state.loading };
        this.props.notifsItems.forEach((id) => {
            const key = `removeItem${id}`;
            loadingKeys[key] = true;
        });
        this.setState({ loading: { ...loadingKeys } });
        try {
            await container.notifsService.removeAll();
            this.props.setNotifsData(await container.notifsService.getList());
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserNotifs.successAll" }), { variant: "success" });
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
        }
        this.setState({ loading: {} });
    }

    private getTranslateKey(item: INotifsItem): string {
        switch (item.typeId) {
            case 40:
                return !item.byUserName ? "app.UserNotifs.translateMessage.typeID40b" : "app.UserNotifs.translateMessage.typeID40a";
            case 51:
                return !item.byUserName ? "app.UserNotifs.translateMessage.typeID51b" : "app.UserNotifs.translateMessage.typeID51a";
            default:
                return `app.UserNotifs.translateMessage.typeID${item.typeId}`;
        }
    }

    private renderItem(item: INotifsItem): React.ReactElement {
        // eslint-disable-next-line
        const params = item as any;
        return (
            <MenuItem className={this.props.classes.root}>
                <div className="user-notifs--item">
                    <div className="user-notifs--item-message">
                        <FormattedMessage id={this.getTranslateKey(item)} values={params} />
                    </div>
                    <div className="user-notifs--action">
                        <button
                            className="wed-icon-button"
                            disabled={!!this.state.loading[`removeItem${item.id}`]}
                            onClick={async () => {
                                await this.removeItem(item);
                            }}
                        >
                            <WedIcon icon={<WedIconDeleteDisabled />} />
                        </button>
                    </div>
                </div>
            </MenuItem>
        );
    }

    render() {
        return (
            <WedDropDownMenu className="user-notifs">
                <div key="header" className="user-notifs--header">
                    <FormattedMessage id="app.UserNotifs.header.title" />
                    {this.props.notifsItems.length > 0 && (
                        <span
                            onClick={async () => {
                                await this.removeAll();
                            }}
                        >
                            <FormattedMessage id="app.UserNotifs.header.deleteAll" />
                        </span>
                    )}
                </div>
                <Divider className={this.props.classes.divider} />
                <div
                    key="container"
                    className={classNames(
                        "user-notifs--container",
                        this.props.notifsItems ? `user-notifs--container-cnt${this.props.notifsItems.length}` : "user-notifs--container-empty"
                    )}
                >
                    {this.props.notifsItems && this.props.notifsItems.length > 0 ? (
                        <SidebarScrollbars>
                            {this.props.notifsItems.map((item, i) => (
                                <div key={i}>
                                    {this.renderItem(item)}
                                    {this.props.notifsItems && i + 1 < this.props.notifsItems.length && (
                                        <Divider className={this.props.classes.divider} />
                                    )}
                                </div>
                            ))}
                        </SidebarScrollbars>
                    ) : this.props.notifsItems ? (
                        <div className="user-notifs--empty-box">
                            <FormattedMessage id="app.UserNotifs.body.emptyBox" />
                        </div>
                    ) : (
                        <LinearProgress />
                    )}
                </div>
            </WedDropDownMenu>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setNotifsData: setNotifsData(dispatch),
});

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(withSnackbar(injectIntl(UserNotifs))));
