import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import { IDirectory, IFile } from "../../../services/WedApi/Models/File";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import ChangeDescriptionPopup from "../../../components/project/Files/ChangeDescriptionPopup";
import { ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import SidebarPreview from "../../../components/sidebar/SidebarPreview/SidebarPreview";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import CommentBox from "../../../components/comments/CommentBox/CommentBox";
import { INote, NoteObjectType } from "../../../services/WedApi/Models/Note";
import { AppNotFoundItemError } from "../../../errors/error-app";
import { FileSize } from "../../../components/file/FileSize/FileSize";
import SidebarTags from "../../../components/sidebar/SidebarTags/SidebarTags";
import FileEditTagsPopup from "../../../components/project/Files/FileEditTagsPopup/FileEditTagsPopup";
import WedDate from "../../../components/formatter/WedDate";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import { IActivitiesObjectType } from "../../../services/WedApi/Services/ActivitiesService";

interface PropsInterface extends WrappedComponentProps {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    fileId: number;
    versionId?: number;
    dirId?: number;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    file: IFile | undefined;
    directory: IDirectory | undefined;
    showChangeDescriptionPopup: boolean;
    showTagsEditPopup: boolean;
    notes: INote[];
    activities: IActivities[];
}

class FileSidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        file: undefined,
        directory: undefined,
        showChangeDescriptionPopup: false,
        showTagsEditPopup: false,
        notes: [],
        activities: [],
    };

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate() {
        await this.fetchData();
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.dirId !== this.props.dirId ||
            nextProps.fileId != this.props.fileId ||
            nextProps.versionId !== this.props.versionId ||
            nextState?.file?.id != this.state?.file?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup ||
            nextState.showTagsEditPopup != this.state.showTagsEditPopup
        );
    }

    fetchData = async () => {
        const { fileId, dirId, loadingCallback } = this.props;
        const { file, showChangeDescriptionPopup } = this.state;
        if (!(file?.id != fileId || showChangeDescriptionPopup)) {
            return;
        }

        try {
            loadingCallback(true);
            const [folder, notes, activities] = await Promise.all([
                container.filesService.getFolder(FileTypeEnum.PROJECT_FILES, dirId),
                container.noteService.getFileNotes(fileId),
                container.activitiesService.getActivities(IActivitiesObjectType.OBJECT, fileId),
            ]);
            const file = folder.files.find((file) => file.id == fileId);
            if (!file) {
                throw new AppNotFoundItemError("file", fileId);
            }
            this.setState({ file, activities, notes, directory: folder.directory }, () => {
                if (this.props.fileId == file.id) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ file: undefined });
        }
    };

    handleOpenTagsEditPopup = () => {
        this.setState({ showTagsEditPopup: true });
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseTagsEditPopup = async (update?: boolean) => {
        if (update) {
            this.props.handleSidebarChanges();
        }

        this.setState({ showTagsEditPopup: false });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            this.props.handleSidebarChanges();
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    onUpdateNotes = async (notes: INote[]) => {
        this.setState({ notes });
    };

    render() {
        if (!this.state.file || this.state.file.id != this.props.fileId) {
            return null;
        }

        const { intl, disabled, versionId } = this.props;
        const { file, directory, showChangeDescriptionPopup, showTagsEditPopup } = this.state;
        const fileVersionId = versionId || file?.newestVersionId || undefined;
        const fileVersionNo = fileVersionId ? file?.versions.find((version) => version.id === fileVersionId)?.versionNumber : undefined;

        return (
            <div className="file-sidebar sidebar--container">
                <SidebarTitle
                    title={file.name}
                    suffix={
                        fileVersionNo ? intl.formatMessage({ id: "app.sidebar.menu.fileVersion" }, { fileVersionNo: `${fileVersionNo}` }) : undefined
                    }
                />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarPreview
                                url={container.filesService.getFileUrl(file.id, file.newestVersionId, { size: SizeImageEnum.THUMB })}
                                extension={file?.extension}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.type" }),
                                        values: [
                                            { text: file?.extension.toUpperCase() },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.size" }),
                                                labelFormat: true,
                                            },
                                            {
                                                key: "sidebar-file-size",
                                                text: <FileSize size={file?.size} />,
                                            },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.location" }),
                                        values: [{ text: directory?.name }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.updated" }),
                                        values: [
                                            { text: file?.updated ? <WedDate date={file.updated} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${file?.updatedBy?.firstName} ${file?.updatedBy?.lastName}` },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: file?.created ? <WedDate date={file.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${file?.createdBy?.firstName} ${file?.createdBy?.lastName}` },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.versionsCount" }),
                                        values: [{ text: file?.versions.length.toString() }],
                                    },
                                ]}
                            />
                            <SidebarDescription
                                allowToAdd={!disabled && file?.userIsOwner}
                                description={file?.description}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            <SidebarTags
                                userCanEdit={!disabled && file?.userIsOwner}
                                onOpenTagsEditPopup={this.handleOpenTagsEditPopup}
                                tags={file?.tags || []}
                            />
                            <CommentBox
                                disabled={disabled}
                                onUpdateNotes={this.onUpdateNotes}
                                object={{
                                    id: file.id,
                                    type: NoteObjectType.file,
                                    data: { fileVersionId: this.props.versionId || file.newestVersionId },
                                }}
                                notes={this.state.notes || []}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={this.state.activities} />
                        </>,
                    ]}
                />
                {showChangeDescriptionPopup && (
                    <ChangeDescriptionPopup
                        id={file.id}
                        type={ISelectItemType.file}
                        object={file}
                        handleClose={() => this.onCloseChangeDescriptionPopup(false)}
                        onSuccess={() => this.onCloseChangeDescriptionPopup(true)}
                    />
                )}
                {showTagsEditPopup && file?.id && (
                    <FileEditTagsPopup objectId={file?.id} tags={file?.tags || []} onClickClose={this.onCloseTagsEditPopup} />
                )}
            </div>
        );
    }
}

export default injectIntl(FileSidebar);
