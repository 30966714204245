import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { AdminHeader } from "../../../components/header/AdminHeader/AdminHeader";
import AdminPageContentLoader from "../components/AdminPageContentLoader/AdminPageContentLoader";
import AdminPagePrimaryNavigation from "../components/AdminPagePrimaryNavigation/AdminPagePrimaryNavigation";
import { ApplicationState } from "../../../store";
import { Dispatch } from "redux";
import { setLoggedUser } from "../../../store/UserReducer";
import { connect } from "react-redux";
import "./AdminPanel.scss";
import container from "../../../container";
import { IUserMyProfile, IUserMyProfileRole } from "../../../services/WedApi/Models/User";
import LinearProgress from "@material-ui/core/LinearProgress";
import Redirect404 from "../../../components/error/Redirect404/Redirect404";

interface PropsInterface extends RouteComponentProps<never, never, { from?: string }> {
    isLoggedUser: boolean;
    isAdminUser: boolean;
    setLoggedUser: (user: IUserMyProfile) => void;
}

interface StateInterface {
    isAdmin: boolean;
}

const DEFAULT_RETURN_URL = "/";

class AdminPanel extends Component<PropsInterface, StateInterface> {
    readonly returnUrl: string;

    constructor(props: PropsInterface) {
        super(props);

        this.returnUrl = props.location.state?.from || DEFAULT_RETURN_URL;
    }

    async componentDidMount() {
        if (!this.props.isLoggedUser) {
            const user = await container.userService.me();
            this.props.setLoggedUser(user);
        }
    }

    closePage = () => {
        const { history } = this.props;

        history.push(this.returnUrl);
    };

    render() {
        if (!this.props.isLoggedUser) {
            return <LinearProgress />;
        }
        if (!this.props.isAdminUser) {
            return <Redirect404 />;
        }

        return (
            <div className="admin-panel-page">
                <AdminHeader onCloseClick={this.closePage} />
                <div className="admin-panel-page__container">
                    <AdminPagePrimaryNavigation />
                    <AdminPageContentLoader />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: Partial<PropsInterface>) => {
    return {
        ...props,
        isLoggedUser: !!state.user.data?.id,
        isAdminUser: state.user.data?.role === IUserMyProfileRole.ADMIN_USER,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLoggedUser: setLoggedUser(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminPanel));
