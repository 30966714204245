import React, { Component } from "react";
import { parse } from "query-string";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import NotLoggedPopupPage from "../../../components/template/NotLoggedPopupPage/NotLoggedPopupPage";
import { isString } from "lodash";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { ActionTimer } from "../../../services/WedApi/Utils/ActionTimer";
import "./ActivateUserPage.scss";
import container from "../../../container";

interface PropsInterface extends ProviderContext, WrappedComponentProps, RouteComponentProps {}

interface StateInterface {
    finished: boolean;
}

class ActivateUserPage extends Component<PropsInterface, StateInterface> {
    private actionTimer: ActionTimer;

    constructor(props: PropsInterface) {
        super(props);
        this.state = { finished: false };
        this.actionTimer = new ActionTimer(() => {
            this.goNextPage();
        });
    }

    componentWillUnmount() {
        this.actionTimer.cancel();
    }

    private goNextPagePath(): string {
        return "/";
    }

    private goNextPage() {
        this.props.history.push(this.goNextPagePath());
    }

    private invalidQueryParams() {
        this.props.history.push(RoutesEnum.ERROR_PAGE_NOT_FOUND);
    }

    private activateSuccess() {
        this.setState({ finished: true });
        this.actionTimer.register();
    }

    async componentDidMount() {
        const queryParams = this.props.history.location.search ? parse(this.props.history.location.search) : {};
        const email = queryParams["email"];
        const token = queryParams["token"];
        if (isString(email) && email.length > 0 && isString(token) && token.length > 0) {
            try {
                const activateResult = await container.userService.activateUser(email, token);
                if (activateResult) {
                    this.activateSuccess();
                } else {
                    this.invalidQueryParams();
                }
            } catch (e) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.internalError" }), { variant: "error" });
            }
        } else {
            this.invalidQueryParams();
        }
    }

    render() {
        return (
            <NotLoggedPopupPage
                maxWidth="lg"
                className="activate-user-page"
                header={<FormattedMessage id={"app.ActivateUserPage.header"} />}
                withLogo={true}
            >
                <div className="not-logged-popup-page--text-container">
                    {this.state.finished ? (
                        <div className="activate-user-page--message-continue">
                            <FormattedMessage id={"app.ActivateUserPage.successMessage"} />
                            <br />
                            <Link to={this.goNextPagePath()}>
                                <FormattedMessage id={"app.ActivateUserPage.successContinue"} />
                            </Link>
                        </div>
                    ) : (
                        <LinearProgress />
                    )}
                </div>
            </NotLoggedPopupPage>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(ActivateUserPage)));
