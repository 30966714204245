import React from "react";
import Initials, { InitialsUserType } from "../../tile/components/Initials/Initials";
import SidebarSubContent from "../SidebarSubContent/SidebarSubContent";
import "./SidebarInitials.scss";

interface PropsInterface {
    title: string;
    items: {
        label: string;
        userType?: InitialsUserType;
        data: {
            initials: string;
            fullName: string;
        }[];
    }[];
}

interface StateInterface {}

class SidebarInitials extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { items, title } = this.props;

        return (
            <SidebarSubContent
                className="sidebar-initials"
                title={title}
                content={
                    <>
                        {items.map((item) => (
                            <div key={item.label} className="sidebar-initials__row">
                                <div className="sidebar-initials__label">{item.label}</div>
                                <div className="sidebar-initials__initials">
                                    {item.data.map((data) => (
                                        <Initials userType={item.userType} key={data.initials} title={data.initials} fullTitle={data.fullName} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </>
                }
            />
        );
    }
}

export default SidebarInitials;
