import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { TextField } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import NotLoggedFormPage from "../../../components/template/NotLoggedFormPage/NotLoggedFormPage";
import { generatePath, Link, RouteComponentProps, withRouter } from "react-router-dom";
import { ActionTimer } from "../../../services/WedApi/Utils/ActionTimer";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import { parse } from "query-string";
import { isObject, isString } from "lodash";
import container from "../../../container";
import {PASSWORD_PATTERN} from "../../../services/WedApi/Utils/Password";

interface PropsInterface extends ProviderContext, WrappedComponentProps, RouteComponentProps {}

interface StateInterface {
    success: boolean;
    fields: {
        password: string;
        password2: string;
    };
}

class ResetPasswordPage extends Component<PropsInterface, StateInterface> {
    private actionTimer: ActionTimer;

    schema = {
        type: "object",
        properties: {
            password: {
                type: "string",
                pattern: PASSWORD_PATTERN,
            },
            password2: {
                type: "string",
            },
        },
        required: ["password", "password2"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                password: "app.ResetPasswordPage.errorPassword",
                password2: "app.ResetPasswordPage.errorPassword2",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            success: false,
            fields: {
                password: "",
                password2: "",
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.actionTimer = new ActionTimer(() => {
            this.goNextPage();
        });
    }

    componentDidMount() {
        if (!this.getToken()) {
            this.props.history.push(generatePath(RoutesEnum.ERROR_PAGE_NOT_FOUND));
        }
    }

    componentWillUnmount() {
        this.actionTimer.cancel();
    }

    private getToken(): string | null {
        if (this.props.location.search) {
            const data = parse(this.props.location.search);
            if (data && isObject(data) && data.token && isString(data.token) && data.token.length > 0) {
                return data.token;
            }
        }
        return null;
    }

    private getNextPagePath(): string {
        return generatePath(RoutesEnum.LOGIN);
    }

    private goNextPage() {
        this.props.history.push(this.getNextPagePath());
    }

    private async handleSubmit() {
        try {
            if (this.state.fields.password !== this.state.fields.password2) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.ResetPasswordPage.errorPassword2" }), { variant: "error" });
                return;
            }

            const passwordToken = this.getToken() || "";
            await container.userService.forgotUpdate(passwordToken, this.state.fields.password);

            this.setState({ success: true });
            this.actionTimer.register();
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_PASSWORD_RESET_FORM_INVALID) {
                keyTranslate = "app.ResetPasswordPage.errorUserPasswordResetFormInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_PASSWORD_RESET_TOKEN_INVALID) {
                keyTranslate = "app.ResetPasswordPage.errorUserPasswordResetTokenInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PASSWORD_INVALID) {
                keyTranslate = "app.ResetPasswordPage.errorPassword";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        return (
            <NotLoggedFormPage
                maxWidth="lg"
                className="reset-password-page"
                handleSubmit={this.handleSubmit}
                labelSubmit={"app.ResetPasswordPage.resetPassword"}
                header={<FormattedMessage id={"app.ResetPasswordPage.header"} />}
                withLogo={true}
                formData={this.state.fields}
                formSchema={this.schema}
                noDisplayButtons={this.state.success}
            >
                {this.state.success ? (
                    <div className="not-logged-popup-page--text-container">
                        <div className="not-logged-popup-page--text-container-content">
                            {this.props.intl.formatMessage({ id: "app.ResetPasswordPage.successMessage" })}
                            <br />
                            <Link to={this.getNextPagePath()}>
                                <FormattedMessage id={"app.ResetPasswordPage.successContinue"} />
                            </Link>
                        </div>
                    </div>
                ) : (
                    <>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            name="password"
                            label={this.props.intl.formatMessage({ id: "app.ResetPasswordPage.label.password" })}
                            id="password"
                            autoComplete="password"
                            value={this.state.fields.password}
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            name="password2"
                            label={this.props.intl.formatMessage({ id: "app.ResetPasswordPage.label.password2" })}
                            id="password2"
                            autoComplete="password2"
                            value={this.state.fields.password2}
                            onChange={this.handleChange}
                        />
                    </>
                )}
            </NotLoggedFormPage>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(ResetPasswordPage)));
