import { ICollection } from "../../WedApi/Models/Collection";
import FilesFilterInterface from "./FilesFilterInterface";
import { IDirectory, IFile } from "../../WedApi/Models/File";
import { searchTextFilter } from "../SearchTextFilter";

export default class FilesFilterService {
    public filter(
        items: { files: IFile[]; directories: IDirectory[] },
        filters: FilesFilterInterface
    ): { files: IFile[]; directories: IDirectory[] } {
        let filteredFiles: IFile[] = items.files;
        let filteredDirectories: IDirectory[] = items.directories;
        if (filters.searchText) {
            filteredFiles = searchTextFilter(filters.searchText, filteredFiles, [{ name: "name", weight: 1 }]);
            filteredDirectories = searchTextFilter(filters.searchText, filteredDirectories, [
                {
                    name: "name",
                    weight: 1,
                },
            ]);
        }

        return {
            files: filteredFiles,
            directories: filteredDirectories,
        };
    }

    public findById(collections: ICollection[], id: number): ICollection | undefined {
        return collections.find((collection) => collection.id === id);
    }
}
