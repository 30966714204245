import * as React from "react";
import { generatePath } from "react-router-dom";
import classNames from "classnames";
import { WedMainMenu } from "../WedMainMenu/WedMainMenu";
import { WedMainMenuItem } from "../WedMainMenuItem/WedMainMenuItem";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "./UserTeamMenu.scss";

interface PropsInterface {
    userTeamId: number;
    activeTab: "user-profile" | "customer-accounts" | "project-active" | "project-closed";
}

interface StateInterface {}

class UserTeamMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMainMenu className={"user-team-menu"}>
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "user-profile" })}
                    path={generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE, { teamId: this.props.userTeamId })}
                    label="app.UserTeamMenu.userProfile"
                />
                <WedMainMenuItem
                    className={classNames("user-team-menu--wide", { "main-menu__active": this.props.activeTab === "customer-accounts" })}
                    path={generatePath(RoutesEnum.USER_TEAM_DETAILS_CLIENTS, { teamId: this.props.userTeamId })}
                    label="app.UserTeamMenu.customerAccounts"
                />
                <WedMainMenuItem
                    className={classNames("user-team-menu--wide", { "main-menu__active": this.props.activeTab === "project-active" })}
                    path={generatePath(RoutesEnum.USER_TEAM_DETAILS_PROJECTS_ACTIVE, { teamId: this.props.userTeamId })}
                    label="app.UserTeamMenu.projectActive"
                />
                <WedMainMenuItem
                    className={classNames("user-team-menu--wide", { "main-menu__active": this.props.activeTab === "project-closed" })}
                    path={generatePath(RoutesEnum.USER_TEAM_DETAILS_PROJECTS_CLOSED, { teamId: this.props.userTeamId })}
                    label="app.UserTeamMenu.projectClosed"
                />
            </WedMainMenu>
        );
    }
}

export default UserTeamMenu;
