import React from "react";
import classnames from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { BreadcrumbItem } from "../../Breadcrumbs";
import { DropDownFolderListMenu } from "../../../drop-down-menu/FolderListMenu/FolderListMenu";
import "./collapse.scss";

interface PropsInterface {
    items: BreadcrumbItem[];
}

interface StateInterface {
    showMenu: boolean;
}

export default class Collapse extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            showMenu: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleClickOutside);
    }

    handleClick() {
        const { showMenu } = this.state;

        this.setState({
            ...this.state,
            showMenu: !showMenu,
        });
    }

    handleClickOutside() {
        this.setState({
            showMenu: false,
        });
    }

    render() {
        const { showMenu } = this.state;

        return (
            <ClickAwayListener onClickAway={this.handleClickOutside}>
                <span onClick={this.handleClick} className={classnames("breadcrumb-collapse", { "breadcrumb-collapse--open": showMenu })}>
                    <MoreHorizIcon />
                    <div className={classnames("breadcrumb-collapse__menu", { "breadcrumb-collapse__menu--active": showMenu })}>
                        <DropDownFolderListMenu
                            items={[
                                {
                                    label: "directory 1",
                                },
                            ]}
                        />
                    </div>
                </span>
            </ClickAwayListener>
        );
    }
}
