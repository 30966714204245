import _ from "lodash";
import ProjectsFilterInterface from "./ProjectsFilterInterface";
import { IProject } from "../../WedApi/Models/Project";
import { SortEnum } from "../SortEnum";
import { CreatedByEnum } from "../../WedApi/Models/Common";
import { searchTextFilter } from "../SearchTextFilter";

export default class ProjectsFilterService {
    public filter(projects: IProject[], filters: ProjectsFilterInterface) {
        let filterData = projects;

        if (filters.favourite) {
            filterData = filterData.filter((project) => {
                return project.isFavourite === filters.favourite;
            });
        }

        if (filters.status) {
            filterData = filterData.filter((project) => {
                return project.status === filters.status;
            });
        }

        if (filters.createdBy) {
            filterData = filterData.filter((project) => {
                switch (filters.createdBy) {
                    case CreatedByEnum.me:
                        return filters.userId && project.createdBy?.id && project.createdBy.id === filters.userId;
                    case CreatedByEnum.others:
                        return filters.userId && project.createdBy?.id && project.createdBy.id !== filters.userId;
                    default:
                        return true;
                }
            });
        }

        if (filters.customer) {
            filterData = filterData.filter((project) => {
                return project.ecOrg.id == filters.customer;
            });
        }

        if (filters.searchText) {
            filterData = searchTextFilter(filters.searchText, filterData, [
                { name: "name", weight: 1 },
                { name: "desc", weight: 2 },
            ]);
        }

        if (filters.order === SortEnum.CREATED) {
            filterData = _.orderBy(filterData, ["created"], "desc");
        } else {
            filterData = _.orderBy(filterData, [(project) => project.name.toLowerCase()], "asc");
        }

        return filterData;
    }

    public findById(projects: IProject[], id: number): IProject | undefined {
        return projects.find((project) => project.id === id);
    }
}
