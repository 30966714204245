import React, { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IProjectExtended, ProjectStatusEnum } from "../../../services/WedApi/Models/Project";
import container from "../../../container";
import { setPageInfoBoxTitlesAction, SetPageInfoBoxTitlesActionProps } from "../../../store/PageInfoBoxReducer";
import { Redirect, Route, RouteComponentProps, Switch, generatePath } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import ProjectCollectionPage from "../../../pages/project/ProjectCollectionPage/ProjectCollectionPage";
import ProjectFilesPage from "../../../pages/project/ProjectFilesPage/ProjectFilesPage";
import FilesMediaGalleryPage from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";
import ProjectSummaryPage from "../../../pages/project/ProjectSummaryPage/ProjectSummaryPage";
import ProjectCommentsAndProofingPage from "../../../pages/project/ProjectCommentsAndProofingPage/ProjectCommentsAndProofingPage";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import Redirect404 from "../../error/Redirect404/Redirect404";
import LayoutPage from "../../layout/LayoutPage/LayoutPage";

export interface ProjectDetailLoaderProps {
    project: IProjectExtended;
    fetchProject: () => void;
}

interface PropsInterface extends SetPageInfoBoxTitlesActionProps {
    projectId: number;
}

interface StateInterface {
    project?: IProjectExtended;
}

class ProjectDetailLoader extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            project: undefined,
        };
        this.fetchData = this.fetchData.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (prevProps.projectId !== this.props.projectId) {
            await this.fetchData();
        }
    }

    private async fetchData() {
        this.props.setPageInfoBoxTitlesAction({});
        const project = await container.projectService.getProjectDetail(this.props.projectId);

        this.setState({
            project: project,
        });
        this.props.setPageInfoBoxTitlesAction({
            title: project.name,
            subTitle: project.ecOrg.name,
        });
    }

    render() {
        return (
            <LayoutPage>
                {this.state.project && [ProjectStatusEnum.active, ProjectStatusEnum.closed].includes(this.state.project.status) ? (
                    <Switch>
                        <Route exact path={RoutesEnum.COLLECTIONS}>
                            <ProjectCollectionPage
                                project={this.state.project as IProjectExtended}
                                fetchProject={this.fetchData}
                                fileType={FileTypeEnum.PROJECT_COLLECTION}
                            />
                        </Route>
                        <Route
                            exact
                            path={RoutesEnum.FILES}
                            component={() => {
                                return (
                                    <ProjectFilesPage
                                        project={this.state.project as IProjectExtended}
                                        fileType={FileTypeEnum.PROJECT_FILES}
                                        fetchProject={this.fetchData}
                                    />
                                );
                            }}
                        />
                        <Route
                            exact
                            path={RoutesEnum.COLLECTION_FILES}
                            component={(route: RouteComponentProps<{ collectionId: string }>) => {
                                return (
                                    <ProjectFilesPage
                                        collectionId={parseInt(route.match.params.collectionId)}
                                        project={this.state.project as IProjectExtended}
                                        fileType={FileTypeEnum.PROJECT_COLLECTION_FILES}
                                        fetchProject={this.fetchData}
                                    />
                                );
                            }}
                        />
                        <Route
                            path={RoutesEnum.COLLECTION_FILES_MEDIA_GALLERY}
                            component={(route: RouteComponentProps<{ collectionId?: string }>) => {
                                return (
                                    <FilesMediaGalleryPage
                                        fileType={FileTypeEnum.PROJECT_COLLECTION_FILES}
                                        collectionId={route.match.params.collectionId ? parseInt(route.match.params.collectionId) : undefined}
                                        project={this.state.project as IProjectExtended}
                                    />
                                );
                            }}
                        />
                        <Route
                            path={RoutesEnum.FILES_MEDIA_GALLERY}
                            component={() => {
                                return (
                                    <FilesMediaGalleryPage fileType={FileTypeEnum.PROJECT_FILES} project={this.state.project as IProjectExtended} />
                                );
                            }}
                        />
                        <Route exact path={RoutesEnum.SUMMARY}>
                            <ProjectSummaryPage project={this.state.project as IProjectExtended} fetchProject={this.fetchData} />
                        </Route>
                        <Route exact path={RoutesEnum.COMMENTS_AND_PROOFING}>
                            <ProjectCommentsAndProofingPage project={this.state.project} fetchProject={this.fetchData} />
                        </Route>
                        <Route exact path={RoutesEnum.PROJECTS_DETAIL}>
                            <Redirect to={generatePath(RoutesEnum.SUMMARY, { projectId: this.state.project.id })} />
                        </Route>
                        <Route component={Redirect404} />
                    </Switch>
                ) : this.state.project ? (
                    <Redirect404 />
                ) : (
                    <LinearProgress />
                )}
            </LayoutPage>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPageInfoBoxTitlesAction: setPageInfoBoxTitlesAction(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(ProjectDetailLoader);
