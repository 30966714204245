import React from "react";
import Select from "@material-ui/core/Select";
import { FormattedMessage } from "react-intl";
import { FilterFormBox } from "../FilterFormBox/FilterFormBox";
import FormControl from "@material-ui/core/FormControl";
import { CollectionStatusEnum } from "../../../services/WedApi/Models/Collection";
import { WedIconDropDown } from "../../wed-icon/WedIconDropDown";
import CollectionFilterInterface from "../../../services/Filter/Collection/CollectionFilterInterface";
import { CreatedByEnum } from "../../../services/WedApi/Models/Common";
import MenuItem from "@material-ui/core/MenuItem";
import { FilterFormBoxSearchText } from "../FilterFormBoxSearchText/FilterFormBoxSearchText";

interface PropsInterface {
    onFiltersChange: (filters: CollectionFilterInterface) => void;
}

interface StateInterface {
    status: string;
    createdBy: string;
}

export class FilterFormProjectCollection extends React.Component<PropsInterface, StateInterface> {
    private readonly filters: CollectionFilterInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.changeStatus = this.changeStatus.bind(this);
        this.changeCreatedBy = this.changeCreatedBy.bind(this);
        this.changeFilterSearchText = this.changeFilterSearchText.bind(this);
        this.filters = {};
        this.state = {
            status: "",
            createdBy: "",
        };
    }

    private changeStatus(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const status: string = event.target.value as string;
        this.setState({ status });
        this.changeFilter("status", status);
    }

    private changeCreatedBy(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const createdBy: string = event.target.value as string;
        this.setState({ createdBy });
        this.changeFilter("createdBy", createdBy);
    }

    private changeFilterSearchText(value: string): void {
        this.changeFilter("searchText", value);
    }

    private changeFilter(name: string, value: string | number) {
        this.filters[name] = value;
        this.props.onFiltersChange(this.filters);
    }

    render() {
        return (
            <FilterFormBox className="filter-form-collection">
                <FormControl variant="outlined">
                    <FilterFormBoxSearchText onChange={this.changeFilterSearchText} />
                </FormControl>
                <FormControl variant="outlined">
                    <Select
                        value={this.state.status}
                        style={{ width: 160 }}
                        displayEmpty
                        onChange={this.changeStatus}
                        IconComponent={WedIconDropDown}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="app.FilterFormProjectCollection.status.allStatuses" />
                        </MenuItem>
                        <MenuItem value={CollectionStatusEnum.locked}>
                            <FormattedMessage id="app.collection.status.locked" />
                        </MenuItem>
                        <MenuItem value={CollectionStatusEnum.unlocked}>
                            <FormattedMessage id="app.collection.status.unlocked" />
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined">
                    <Select
                        value={this.state.createdBy}
                        style={{ width: 190 }}
                        displayEmpty
                        onChange={this.changeCreatedBy}
                        IconComponent={WedIconDropDown}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="app.FilterFormProjectCollection.status.allCollections" />
                        </MenuItem>
                        <MenuItem value={CreatedByEnum.me}>
                            <FormattedMessage id="app.FilterFormProjectCollection.status.createdByMe" />
                        </MenuItem>
                        <MenuItem value={CreatedByEnum.others}>
                            <FormattedMessage id="app.FilterFormProjectCollection.status.sharedWithMe" />
                        </MenuItem>
                    </Select>
                </FormControl>
            </FilterFormBox>
        );
    }
}
