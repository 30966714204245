import React from "react";
import { FormattedMessage } from "react-intl";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconItemsGroupElements } from "../../wed-icon/generated/WedIconSvg";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { IUserBase } from "../../../services/WedApi/Models/User";
import { FilterFormShareCollection } from "../../filter-form/FilterFormShareCollection/FilterFormShareCollection";
import FilesFilterInterface from "../../../services/Filter/Files/FilesFilterInterface";
import ProjectsFilterInterface from "../../../services/Filter/Projects/ProjectsFilterInterface";
import "./ShareContentHeader.scss";

interface PropsInterface {
    companyName?: string;
    collectionName?: string;
    sharedBy?: IUserBase;
    breadcrumbs?: React.ReactNode;
    onFiltersChange?: (filters: ProjectsFilterInterface) => void;
}

interface StateInterface {}

export default class ShareContentHeader extends React.Component<PropsInterface, StateInterface> {
    getDefaultHeader = () => {
        const { collectionName, sharedBy, companyName } = this.props;

        return (
            <div className="share-content-header__default">
                {collectionName && sharedBy && (
                    <>
                        {collectionName}
                        <small>
                            <FormattedMessage id="app.sharedCollection.sharedBy" />
                        </small>
                        {` ${getInitialsAndOwner(sharedBy).owner}, `}
                    </>
                )}
                {companyName}
            </div>
        );
    };

    onFiltersChange = (filters: FilesFilterInterface) => {
        const { onFiltersChange } = this.props;
        onFiltersChange && onFiltersChange(filters);
    };

    render() {
        const { breadcrumbs, onFiltersChange } = this.props;

        return (
            <div className="share-content-header">
                <WedIcon size={SizeType.LARGE} icon={<WedIconItemsGroupElements />} />
                {breadcrumbs ? breadcrumbs : this.getDefaultHeader()}
                {!!onFiltersChange && <FilterFormShareCollection onFiltersChange={this.onFiltersChange} />}
            </div>
        );
    }
}
