import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IClientContact } from "../../../services/WedApi/Models/ClientContact";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    clientContact: IClientContact;
    onClickClose: (updated?: boolean) => void;
}

interface StateInterface {}

class DeleteClientAccountPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {};
        this.doIt = this.doIt.bind(this);
    }

    private async doIt() {
        try {
            await container.clientContactService.removeClientCustomerContact(this.props.clientContact.id);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.DeleteClientAccountPopup.success.deleted" }), { variant: "success" });
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            return false;
        }
    }

    render() {
        return (
            <PopupConfirmButton
                maxWidth="sm"
                open={true}
                close={this.props.onClickClose}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.standard.popup.button.delete"} />}
                noDisplayCancel={true}
                header={<FormattedMessage id={"app.DeleteClientAccountPopup.header.title"} />}
                content={<div dangerouslySetInnerHTML={{ __html: this.props.intl.formatMessage({ id: "app.DeleteClientAccountPopup.content" }) }} />}
            />
        );
    }
}

export default withSnackbar(injectIntl(DeleteClientAccountPopup));
