import React from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { Subtract } from "utility-types";
import { ProjectStatusChangeEnum } from "../services/WedApi/Models/Project";
import container from "../container";
import RoutesEnum from "../services/Routes/RoutesEnum";

export interface InjectedProjectActionsProps extends RouteComponentProps {
    goToProject: (id: number) => void;
    goToComments: (id: number) => void;
    goToFiles: (id: number) => void;
    goToCollections: (id: number) => void;
    closeProjects: (projectIds: number[]) => Promise<void>;
    reactivateProjects: (projectIds: number[]) => Promise<void>;
    archiveProjects: (projectIds: number[]) => Promise<void>;
    restoreProjects: (projectIds: number[]) => Promise<void>;
    deleteProjects: (projectIds: number[]) => Promise<void>;
}

export function withProjectActions<T extends InjectedProjectActionsProps>(WrappedComponent: React.ComponentType<T>) {
    class WithProjectActions extends React.Component<Subtract<T, Subtract<InjectedProjectActionsProps, RouteComponentProps>>> {
        goToProject = (id: number) => this.props.history.push(generatePath(RoutesEnum.SUMMARY, { projectId: id }));

        goToComments = (id: number) => this.props.history.push(generatePath(RoutesEnum.COMMENTS_AND_PROOFING, { projectId: id }));

        goToFiles = (id: number) => this.props.history.push(generatePath(RoutesEnum.FILES, { projectId: id }));

        goToCollections = (id: number) => this.props.history.push(generatePath(RoutesEnum.COLLECTIONS, { projectId: id }));

        changeStatuses = async (projectIds: number[], status: ProjectStatusChangeEnum) => {
            return await Promise.all(projectIds.map((id) => container.projectActionApiService.changeStatus(id, status)));
        };

        closeProjects = (projectsIds: number[]) => this.changeStatuses(projectsIds, ProjectStatusChangeEnum.close);

        reactivateProjects = async (projectsIds: number[]) => this.changeStatuses(projectsIds, ProjectStatusChangeEnum.reactivate);

        archiveProjects = async (projectsIds: number[]) => this.changeStatuses(projectsIds, ProjectStatusChangeEnum.archive);

        restoreProjects = async (projectsIds: number[]) => this.changeStatuses(projectsIds, ProjectStatusChangeEnum.restore);

        deleteProjects = async (projectsIds: number[]) => this.changeStatuses(projectsIds, ProjectStatusChangeEnum.delete);

        render() {
            return (
                <WrappedComponent
                    {...(this.props as T)}
                    goToProject={this.goToProject}
                    goToComments={this.goToComments}
                    goToFiles={this.goToFiles}
                    goToCollections={this.goToCollections}
                    closeProjects={this.closeProjects}
                    reactivateProjects={this.reactivateProjects}
                    archiveProjects={this.archiveProjects}
                    restoreProjects={this.restoreProjects}
                    deleteProjects={this.deleteProjects}
                />
            );
        }
    }

    return withRouter(WithProjectActions);
}
