import React, { ReactElement } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classnames from "classnames";
import _ from "lodash";
import InternalTeamCountIcon from "../../count-icon/InternalTeamCountIcon/InternalTeamCountIcon";
import Title from "../components/Title/Title";
import WedDate from "../../formatter/WedDate";
import ExternalTeamCountIcon from "../../count-icon/ExternalTeamCountIcon/ExternalTeamCountIcon";
import ProjectStat, { ProjectStatType } from "../components/ProjectStat/ProjectStat";
import "../tile.scss";
import "./ClientTile.scss";

interface PropsInterface extends WrappedComponentProps, RouteComponentProps {
    numOfClosedProjects: number;
    numOfActiveProjects: number;
    client: string;
    date?: Date;
    author: string;
    internalTeam: number;
    externalTeam: number;
    selected: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    actionButton?: ReactElement;
}

interface StateInterface {
    isHover: boolean;
}

class ClientTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["actionButton", "onDoubleClick", "onClick", "key", "get"];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isHover: false,
        };
        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick() {
        const { onClick } = this.props;

        onClick && onClick();
    }

    onDoubleClick() {
        const { onDoubleClick } = this.props;

        onDoubleClick && onDoubleClick();
    }

    render() {
        const { intl, numOfClosedProjects, numOfActiveProjects, client, date, author, internalTeam, externalTeam, selected, actionButton } =
            this.props;

        return (
            <div
                onClick={this.onClick}
                onDoubleClick={this.onDoubleClick}
                className={classnames("tile", "client-tile", { "tile--active": selected })}
                onMouseEnter={() => {
                    this.setState({ isHover: true });
                }}
            >
                {this.state.isHover && actionButton}
                <div className="client-tile__stats">
                    <ProjectStat
                        count={numOfActiveProjects}
                        title={intl.formatMessage({ id: "app.ClientListPage.grid.numOfActiveProject" })}
                        type={ProjectStatType.ACCEPTED}
                    />
                    <ProjectStat
                        count={numOfClosedProjects}
                        title={intl.formatMessage({ id: "app.ClientListPage.grid.numOfClosedProject" })}
                        type={ProjectStatType.STANDARD}
                    />
                </div>
                <div className="client-tile__title">
                    <Title title={client} />
                </div>
                <div className="client-tile__details">
                    <FormattedMessage id={"app.tile.created"} />
                    <span className="client-tile__date">{date && <WedDate date={date} />}</span>
                    <FormattedMessage id={"app.global.by"} />
                    <span className="client-tile__user">{author}</span>
                </div>
                <div className="client-tile__footer">
                    <div className="client-tile__icons">
                        <InternalTeamCountIcon count={internalTeam} />
                        <ExternalTeamCountIcon count={externalTeam} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(injectIntl(ClientTile));
