import React from "react";
import {
    WedIconDocumentsFileAi,
    WedIconDocumentsFileExcel,
    WedIconDocumentsFilePdf,
    WedIconDocumentsFilePhotoshop,
    WedIconDocumentsFilePowerPoint2,
    WedIconDocumentsFileText1,
    WedIconDocumentsFileWord,
    WedIconImagePngAi,
    WedIconImagePngMsExcelIcon,
    WedIconImagePngMsPowerpointIcon,
    WedIconImagePngMsWordIcon,
    WedIconImagePngPdf,
    WedIconImagePngPs,
    WedIconMediaVideo,
    WedIconPhotoImageSquare,
} from "../../wed-icon/generated/WedIconSvg";
import { isFileHtmlImageExtension, isFileHtmlVideoExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";

interface PropsInterface {
    extension?: string;
    fileName?: string;
    blackIcon?: boolean;
}

interface StateInterface {}

export class FileTypeIcon extends React.Component<PropsInterface, StateInterface> {
    private getExtValueByExtension(): string | undefined {
        if (this.props.extension) {
            return this.props.extension.toLowerCase();
        }
        return undefined;
    }

    private getExtValueByFileName(): string | undefined {
        if (this.props.fileName) {
            const fileExtMatch = this.props.fileName.toLowerCase().match(/\.([^.]+)$/);
            return fileExtMatch ? fileExtMatch[1] : undefined;
        }
        return undefined;
    }

    render() {
        const extValue = this.getExtValueByExtension() || this.getExtValueByFileName() || undefined;
        switch (extValue) {
            case "psd":
                return this.props.blackIcon ? <WedIconDocumentsFilePhotoshop /> : <WedIconImagePngPs />;
            case "ai":
                return this.props.blackIcon ? <WedIconDocumentsFileAi /> : <WedIconImagePngAi />;
            case "pdf":
                return this.props.blackIcon ? <WedIconDocumentsFilePdf /> : <WedIconImagePngPdf />;
            case "xls":
            case "xlsx":
                return this.props.blackIcon ? <WedIconDocumentsFileExcel /> : <WedIconImagePngMsExcelIcon />;
            case "pptx":
            case "ppt":
                return this.props.blackIcon ? <WedIconDocumentsFilePowerPoint2 /> : <WedIconImagePngMsPowerpointIcon />;
            case "doc":
            case "docx":
                return this.props.blackIcon ? <WedIconDocumentsFileWord /> : <WedIconImagePngMsWordIcon />;
        }
        if (extValue && isFileHtmlImageExtension(extValue)) {
            return <WedIconPhotoImageSquare />;
        }
        if (extValue && isFileHtmlVideoExtension(extValue)) {
            return <WedIconMediaVideo />;
        }
        return <WedIconDocumentsFileText1 />;
    }
}
