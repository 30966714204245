import React, { MouseEvent } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../../container";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconHeartFavorite, WedIconHeartFavoriteFilled } from "../../../wed-icon/generated/WedIconSvg";
import "./favorite.scss";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    isFavorite: boolean;
    projectId: number;
    handleChangeFavorite: (projectId: number) => void;
    sizeIcon: SizeType;
}

interface StateInterface {
    isLoading: boolean;
}

class Favorite extends React.Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        isLoading: false,
    };

    constructor(props: PropsInterface) {
        super(props);
        this.changeStateFavorite = this.changeStateFavorite.bind(this);
    }

    public async changeStateFavorite(e: MouseEvent) {
        e.stopPropagation();
        if (this.state.isLoading) {
            return;
        }

        try {
            this.setState({ isLoading: true });
            const { isFavorite, projectId } = this.props;
            await container.projectActionApiService.favourite(projectId, !isFavorite);
            this.props.handleChangeFavorite(projectId);
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
        }
        this.setState({ isLoading: false });
    }

    render() {
        let className = "project__favorite";

        if (this.state.isLoading) {
            className += " project__favorite--loading";
            return <WedIcon className={className} icon={<WedIconHeartFavoriteFilled />} size={this.props.sizeIcon} />;
        } else if (this.props.isFavorite) {
            return (
                <WedIcon
                    className={`${className} project__favorite--active`}
                    icon={<WedIconHeartFavoriteFilled />}
                    onClick={this.changeStateFavorite}
                    size={this.props.sizeIcon}
                />
            );
        } else {
            return <WedIcon className={className} icon={<WedIconHeartFavorite />} onClick={this.changeStateFavorite} size={this.props.sizeIcon} />;
        }
    }
}

export default withSnackbar(injectIntl(Favorite));
