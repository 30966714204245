import React from "react";
import classNames from "classnames";
import "./PhoneNumber.scss";

interface PropsInterface {
    phone: string;
    cCode?: number;
}

interface StateInterface {}

export default class PhoneNumber extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { phone, cCode } = this.props;

        return (
            <span className={classNames("phone-number", { "phone-number--with-country-code": !!cCode })}>
                {!!cCode && <span className="phone-number--country-code">{`+${cCode}`}</span>}
                <span className="phone-number--phone-number">{phone}</span>
            </span>
        );
    }
}
