import React from "react";
import classNames from "classnames";
import "./popup-content.scss";

export enum MarginSizeType {
    SMALL = "small",
}

interface PropsInterface {
    className?: string;
    withBackground?: boolean;
    children: React.ReactNode | React.ReactNodeArray;
    marginSize?: MarginSizeType;
}

interface StateInterface {}

export default class PopupContent extends React.Component<PropsInterface, StateInterface> {
    private getMarginSizeClassName(): string {
        switch (this.props.marginSize) {
            case MarginSizeType.SMALL:
                return "popup-content--margin-small";
        }
        return "initials-size-normal";
    }

    private withBackground(): boolean {
        return !!this.props.withBackground;
    }

    render() {
        return (
            <div
                className={classNames(
                    "popup-content",
                    this.getMarginSizeClassName(),
                    { "popup-content--with-background": this.withBackground() },
                    this.props.className
                )}
            >
                {this.props.children}
            </div>
        );
    }
}
