import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import Collection from "../../../../domain/Collection";
import container from "../../../../container";

interface StateInterface {
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: () => void;
    collection: Collection;
    successDelete: (collectionId: number) => void;
}

class DeleteCollection extends React.Component<PropsInterface, StateInterface> {
    readonly state = {
        loading: false,
    };

    deleteCollection = async () => {
        if (this.state.loading) {
            return;
        }
        this.setState({ loading: true });

        try {
            await container.collectionActionApiService.deleteCollection(this.props.collection.getId());
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.deleteCollection.delete.success" }), { variant: "success" });
            this.props.successDelete(this.props.collection.getId());
            this.props.close();
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
            this.setState({ loading: false });
        }
    };

    render() {
        return (
            <div className="delete-collection">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.deleteCollection.header.title" />
                </PopupHeader>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <br />
                        <FormattedMessage id={"app.deleteCollection.content"} values={{ collectionName: this.props.collection.getName() }} />
                    </DialogContentText>
                </DialogContent>
                <PopupAction>
                    <Button type="submit" onClick={this.deleteCollection} className="button--more-padding" disabled={this.state.loading}>
                        <FormattedMessage id="app.standard.popup.button.delete" />
                    </Button>
                    <Button type="submit" onClick={this.props.close} className="button--more-padding">
                        <FormattedMessage id="app.standard.popup.button.cancel" />
                    </Button>
                </PopupAction>
            </div>
        );
    }
}

export default withSnackbar(injectIntl(DeleteCollection));
