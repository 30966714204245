import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import classNames from "classnames";
import { WedMainMenuItem } from "../../../../components/main-menu/WedMainMenuItem/WedMainMenuItem";
import { WedMainMenu } from "../../../../components/main-menu/WedMainMenu/WedMainMenu";
import "./AdminPageSecondaryNavigation.scss";

interface PropsInterface extends RouteComponentProps {
    menuItems: {
        path: string;
        label: string;
    }[];
}

class AdminPageSecondaryNavigation extends Component<PropsInterface> {
    render() {
        const {
            menuItems,
            location: { pathname },
        } = this.props;

        return (
            <WedMainMenu className={"admin-page-secondary-menu"}>
                {menuItems.map(({ path, label }) => (
                    <WedMainMenuItem className={classNames({ "main-menu__active": pathname === path })} key={label} path={path} label={label} />
                ))}
            </WedMainMenu>
        );
    }
}

export default withRouter(AdminPageSecondaryNavigation);
