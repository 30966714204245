import React, { Component } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { AdminPanelTable } from "../../components/AdminPanelTable/AdminPanelTable";
import { LinearProgress } from "@material-ui/core";
import container from "../../../../container";
import { IOrganizationUser, IUserMyProfileRole } from "../../../../services/WedApi/Models/User";
import WedDate from "../../../../components/formatter/WedDate";
import MenuItem from "@material-ui/core/MenuItem";
import PopupConfirmButton from "../../../../components/popup/PopupConfirmButton/PopupConfirmButton";
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import AdminPanelDropdown from "../../components/AdminPanelDropdown/AdminPanelDropdown";
import AdminPanelSwitchButton from "../../components/AdminPanelSwitchButton/AdminPanelSwitchButton";
import { WedIconSettings6, WedIconTrashDeleteRemove } from "../../../../components/wed-icon/generated/WedIconSvg";
import AdminPanelIconButton from "../../components/AdminPanelIconButton/AdminPanelIconButton";
import AdminPanelUserEditProfilePopup from "./AdminPanelUserEditProfilePopup";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import { ProviderContext, withSnackbar } from "notistack";
import "./AdminPanelManageCurrentUsers.scss";
import classNames from "classnames";

const TABLE_HEADERS = [
    "app.AdminPanelManageCurrentUsers.header.name",
    "app.AdminPanelManageCurrentUsers.header.email",
    "app.AdminPanelManageCurrentUsers.header.joined",
    "app.AdminPanelManageCurrentUsers.header.role",
    "app.AdminPanelManageCurrentUsers.header.status",
    "app.AdminPanelManageCurrentUsers.header.edit",
    "app.AdminPanelManageCurrentUsers.header.delete",
];

interface PopupChangeRole {
    userId: number;
    firstName: string;
    lastName: string;
    userRole: IUserMyProfileRole;
}

interface PopupChangeStatus {
    userId: number;
    firstName: string;
    lastName: string;
    active: boolean;
}

interface PopupEditUser {
    item: IOrganizationUser;
}

interface PopupDeleteUser {
    userId: number;
    firstName: string;
    lastName: string;
}

interface BasePropsInterface {}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    userId: number;
}

interface StateInterface {
    loading: boolean;
    items: IOrganizationUser[];
    popup?: {
        changeRole?: PopupChangeRole;
        changeStatus?: PopupChangeStatus;
        editUser?: PopupEditUser;
        deleteUser?: PopupDeleteUser;
    };
}

class AdminPanelManageCurrentUsers extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            items: [],
        };
        this.handleClose = this.handleClose.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            loading: false,
            items: await container.adminService.getOrganizationUsers(),
        });
    }

    private async handleClose(updated?: boolean) {
        this.setState({ popup: undefined });
        if (updated) {
            this.setState({ loading: true });
            await this.fetchData();
        }
    }

    private async deleteUser(data: PopupDeleteUser): Promise<void> {
        try {
            await container.adminService.removeUser(data.userId);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelManageCurrentUsers.deleteUser.success" }), {
                variant: "success",
            });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_UPDATE_FORM_INVALID) {
                keyTranslate = "app.AdminPanelManageCurrentUsers.deleteUser.errUserUpdateFormInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), {
                variant: "error",
            });
        }
    }

    private async changeRole(data: PopupChangeRole): Promise<void> {
        try {
            await container.adminService.setUserRole(data.userId, data.userRole);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelManageCurrentUsers.changeRole.success" }), {
                variant: "success",
            });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_UPDATE_FORM_INVALID) {
                keyTranslate = "app.AdminPanelManageCurrentUsers.changeRole.errUserUpdateFormInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), {
                variant: "error",
            });
        }
    }

    private async changeStatus(data: PopupChangeStatus): Promise<void> {
        try {
            await container.adminService.setUserStatus(data.userId, data.active);
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.AdminPanelManageCurrentUsers.changeStatus.success" }), {
                variant: "success",
            });
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_UPDATE_FORM_INVALID) {
                keyTranslate = "app.AdminPanelManageCurrentUsers.changeStatus.errUserUpdateFormInvalid";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), {
                variant: "error",
            });
        }
    }

    private showPopupChangeRole(data: PopupChangeRole) {
        return (
            <PopupConfirmButton
                open={true}
                close={this.handleClose}
                doIt={async () => {
                    await this.changeRole(data);
                }}
                header={<FormattedMessage id={"app.AdminPanelManageCurrentUsers.changeRole.header"} />}
                content={
                    <FormattedMessage
                        id={"app.AdminPanelManageCurrentUsers.changeRole.content"}
                        values={{
                            userRole: this.props.intl.formatMessage({ id: `app.AdminPanelManageCurrentUsers.userRole.${data.userRole}` }),
                            firstName: data.firstName,
                            lastName: data.lastName,
                        }}
                    />
                }
            />
        );
    }

    private showPopupChangeStatus(data: PopupChangeStatus) {
        return (
            <PopupConfirmButton
                open={true}
                close={this.handleClose}
                doIt={async () => {
                    await this.changeStatus(data);
                }}
                header={<FormattedMessage id={"app.AdminPanelManageCurrentUsers.changeStatus.header"} />}
                content={
                    <FormattedMessage
                        id={`app.AdminPanelManageCurrentUsers.changeStatus.${data.active ? "contentActivate" : "contentDisabled"}`}
                        values={{
                            firstName: data.firstName,
                            lastName: data.lastName,
                        }}
                    />
                }
            />
        );
    }

    private showPopupEditUser(data: PopupEditUser) {
        return (
            <AdminPanelUserEditProfilePopup
                close={this.handleClose}
                userRole={data.item.role}
                userId={data.item.id}
                userEmail={data.item.email}
                fields={{
                    firstName: data.item.firstName,
                    lastName: data.item.lastName,
                    phone: data.item.phone,
                    cCode: data.item.cCode,
                }}
            />
        );
    }

    private showPopupDeleteUser(data: PopupDeleteUser) {
        return (
            <PopupConfirmButton
                open={true}
                close={this.handleClose}
                doIt={async () => {
                    await this.deleteUser(data);
                }}
                header={<FormattedMessage id={"app.AdminPanelManageCurrentUsers.deleteUser.header"} />}
                content={
                    <FormattedMessage
                        id={"app.AdminPanelManageCurrentUsers.deleteUser.content"}
                        values={{
                            firstName: data.firstName,
                            lastName: data.lastName,
                        }}
                    />
                }
            />
        );
    }

    private getDropDownRole(item: IOrganizationUser): React.ReactElement {
        return (
            <AdminPanelDropdown
                className={"admin-panel-manage-current-users--dropdown-user-role"}
                onChange={(value) =>
                    this.setState({
                        popup: {
                            changeRole: {
                                userId: item.id,
                                userRole: value,
                                firstName: item.firstName,
                                lastName: item.lastName,
                            },
                        },
                    })
                }
                value={item.role}
                disabled={this.props.userId === item.id}
            >
                <MenuItem value={IUserMyProfileRole.REGULAR_USER}>
                    <FormattedMessage id={`app.AdminPanelManageCurrentUsers.userRole.${IUserMyProfileRole.REGULAR_USER}`} />
                </MenuItem>
                <MenuItem value={IUserMyProfileRole.ADMIN_USER}>
                    <FormattedMessage id={`app.AdminPanelManageCurrentUsers.userRole.${IUserMyProfileRole.ADMIN_USER}`} />
                </MenuItem>
            </AdminPanelDropdown>
        );
    }

    private getSwitchStatus(item: IOrganizationUser): React.ReactElement {
        return (
            <div className={"admin-panel-manage-current-users--change-status-container"}>
                <AdminPanelSwitchButton
                    className={"admin-panel-manage-current-users--change-status"}
                    checked={item.active}
                    onChange={(status: boolean) => {
                        this.setState({
                            popup: {
                                changeStatus: {
                                    active: status,
                                    firstName: item.firstName,
                                    lastName: item.lastName,
                                    userId: item.id,
                                },
                            },
                        });
                    }}
                    disabled={this.props.userId === item.id}
                />
                <div
                    className={classNames("admin-panel-manage-current-users--change-status-label", {
                        "admin-panel-manage-current-users--change-status-label-disabled": this.props.userId === item.id,
                    })}
                >
                    {item.active ? (
                        <FormattedMessage id="app.AdminPanelManageCurrentUsers.userStatus.active" />
                    ) : (
                        <FormattedMessage id="app.AdminPanelManageCurrentUsers.userStatus.inactive" />
                    )}
                </div>
            </div>
        );
    }

    private getRow(item: IOrganizationUser): Array<string | React.ReactElement | undefined> {
        return [
            `${item.firstName} ${item.lastName}`,
            item.email,
            <WedDate key={3} date={item.created} />,
            this.getDropDownRole(item),
            this.getSwitchStatus(item),
            <AdminPanelIconButton
                key={6}
                icon={<WedIconSettings6 />}
                onClick={() => {
                    this.setState({ popup: { editUser: { item } } });
                }}
            />,
            <AdminPanelIconButton
                key={7}
                icon={<WedIconTrashDeleteRemove />}
                disabled={this.props.userId === item.id}
                onClick={() => {
                    this.setState({
                        popup: {
                            deleteUser: {
                                firstName: item.firstName,
                                lastName: item.lastName,
                                userId: item.id,
                            },
                        },
                    });
                }}
            />,
        ];
    }

    render() {
        return (
            <div className="admin-panel-manage-current-users">
                {this.state.loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        {this.state.popup?.changeRole && this.showPopupChangeRole(this.state.popup.changeRole)}
                        {this.state.popup?.changeStatus && this.showPopupChangeStatus(this.state.popup.changeStatus)}
                        {this.state.popup?.editUser && this.showPopupEditUser(this.state.popup.editUser)}
                        {this.state.popup?.deleteUser && this.showPopupDeleteUser(this.state.popup.deleteUser)}
                        <AdminPanelTable headers={TABLE_HEADERS} centerIdx={[3, 4, 5, 6]} rows={this.state.items.map((item) => this.getRow(item))} />
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        userId: state.user.data?.id as number,
    };
};

export default connect(mapStateToProps)(withSnackbar(injectIntl(AdminPanelManageCurrentUsers)));
