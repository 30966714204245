import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IListResult } from "./Models/Common";
import { IApiUser, IUser, IUserTeam, IUserTeamRole } from "./Models/User";
import { uniqueValues } from "./Utils/ItemCollection";
import { convertStrToDate } from "./Utils/DateConverter";
import { IApiObject } from "./Models/File";
import { TokenFormData } from "./Services/CaptchaService";

export default class UserActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async me(): Promise<IApiUser> {
        const response = await this.get("/xapi/user/me");

        return response.data;
    }

    public async logout(): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/logout", {});

        return response.data;
    }

    public async getByIds(ids: number[]): Promise<IListResult<IUser>> {
        if (ids.length === 0) {
            return {
                items: [],
            };
        }

        const response = await this.get(`/xapi/user/get?id=${uniqueValues(ids).join(",")}`);

        return {
            ...response.data,
            items: response.data.items.map((user: IUser & { createdAt: string }) => {
                return {
                    ...user,
                    created: convertStrToDate(user.createdAt),
                };
            }),
        };
    }

    public async login(tokenForm: TokenFormData | undefined, email: string, password: string): Promise<void> {
        const response = await this.postWithCSRFXWwwForm("/xapi/login", {
            ...(tokenForm ? tokenForm : {}),
            email,
            password,
        });

        return response.data;
    }

    public async register(tokenForm: TokenFormData | undefined, email: string, password: string, firstName: string, lastName: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/signup", {
            ...(tokenForm ? { ...tokenForm } : {}),
            email,
            password,
            firstName,
            lastName,
        });

        return response.data;
    }

    public async signupInvitation(tokenForm: TokenFormData | undefined, token: string, password: string, firstName: string, lastName: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/user/signup_invitation", {
            ...(tokenForm ? { ...tokenForm } : {}),
            token,
            password,
            firstName,
            lastName,
        });
    }

    public async updateUserPassword(currentPassword: string, newPassword: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/changepassword", { currentPassword, newPassword });

        return response.data;
    }

    public async updateUserProfile(id: number, firsName: string, lastName: string, cCode: number | undefined, phone: string | undefined) {
        const response = await this.postWithCSRFJson("/xapi/user/update", {
            uid: id,
            firstName: firsName,
            lastName: lastName,
            ...(cCode ? { ccode: cCode } : {}),
            ...(phone ? { phone } : {}),
        });

        return response.data;
    }

    public async activateUser(email: string, token: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/activate", { email, token });

        return response.data;
    }

    public async getUsers(): Promise<IUserTeam[]> {
        return (await this.get("/xapi/user")).data.items.map((user: IUser & { createdAt: string; roleID: number }) => ({
            ...user,
            name: `${user.firstName} ${user.lastName}`,
            role: user.roleID === 1 ? IUserTeamRole.ADMIN_USER : user.roleID === 2 ? IUserTeamRole.REGULAR_USER : undefined,
            created: convertStrToDate(user.createdAt),
        }));
    }

    public async forgot(tokenForm: TokenFormData | undefined, email: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/forgot", { ...(tokenForm ? tokenForm : {}), email });

        return response.data;
    }

    public async forgotUpdate(token: string, password: string): Promise<void> {
        const response = await this.postWithCSRFJson("/xapi/user/reset", { token, password });

        return response.data;
    }

    public async registerCompanyData(
        name: string,
        shortName: string | null,
        taxID: string,
        streetAddress1: string,
        streetAddress2: string | null,
        zipCode: string,
        city: string,
        state: string,
        countryID: number,
        planID: number,
    ): Promise<{ redirectUrl: string }> {
        const response = await this.postWithCSRFJson("/xapi/bas/create", {
            name,
            taxID,
            streetAddress1,
            zipCode,
            city,
            state,
            countryID,
            planID,
            ...(shortName ? { shortName } : {}),
            ...(streetAddress2 ? { streetAddress2 } : {}),
        });

        return response.data;
    }

    public async getProjectWorks(userId: number): Promise<IApiObject[]> {
        return (await this.get(`/xapi/user/works?uid=${userId}`)).data.items;
    }

    public async getPrivatePortfolio(userId: number): Promise<IApiObject[]> {
        return (await this.get(`/xapi/user/portfolio?uid=${userId}`)).data.items;
    }

    public async updatePrivatePortfolio(objectsRemove: number[], objectsAdd: number[]): Promise<void> {
        await this.postWithCSRFJson("/xapi/user/portfolio/update", { objectsRemove, objectsAdd });
    }
}
