import React from "react";
import HeaderNavigation from "./Navigation/HeaderNavigation";
import PageInfoBox from "./PageInfoBox/PageInfoBox";
import "./Header.scss";

export class Header extends React.Component {
    render() {
        return (
            <header className="header">
                <div className="header__container">
                    <HeaderNavigation />
                    <PageInfoBox />
                </div>
            </header>
        );
    }
}
