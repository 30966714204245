import ActivitiesActionApiService from "../ActivitiesActionApiService";
import { IListResult } from "../Models/Common";
import {
    IActivities,
    ICollectionActivities,
    ICollectionFileActivities,
    IFileActivities,
    IFolderActivities,
    IProjectActivities,
} from "../Models/Activities";
import { convertStrToDate } from "../Utils/DateConverter";
import { LogicError } from "../../../errors/error-app";

export enum IActivitiesObjectType {
    PROJECT = "project",
    COLLECTION = "collection",
    COLLECTION_OBJECT = "collectionObject",
    FOLDER = "folder",
    OBJECT = "object",
}

export class ActivitiesService {
    constructor(private readonly activitiesActionApiService: ActivitiesActionApiService) {}

    public static convertProject(activity: IProjectActivities): IActivities {
        return ActivitiesService.convertActivities(activity, ActivitiesService.getProjectMessage(activity));
    }

    public static convertCollection(activity: ICollectionActivities): IActivities {
        return ActivitiesService.convertActivities(activity, ActivitiesService.getCollectionMessage(activity));
    }

    public static convertCollectionFile(activity: ICollectionFileActivities): IActivities {
        return ActivitiesService.convertActivities(activity, ActivitiesService.getCollectionFileMessage(activity));
    }

    public static convertFolder(activity: IFolderActivities): IActivities {
        return ActivitiesService.convertActivities(activity, ActivitiesService.getFolderMessage(activity));
    }

    public static convertFile(activity: IFileActivities): IActivities {
        return ActivitiesService.convertActivities(activity, ActivitiesService.getFileMessage(activity));
    }

    private static getProjectMessage(activity: IProjectActivities): string {
        switch (activity.typeID) {
            case 1:
                return "app.ActivitiesService.Project.typeID1";
            case 2:
                return "app.ActivitiesService.Project.typeID2";
            case 3:
                return "app.ActivitiesService.Project.typeID3";
            case 4:
                switch (activity.newStatus) {
                    case 1:
                        return "app.ActivitiesService.Project.typeID4newStatus1";
                    case 2:
                        return "app.ActivitiesService.Project.typeID4newStatus2";
                    case 3:
                        return "app.ActivitiesService.Project.typeID4newStatus3";
                }
        }
        return "";
    }

    private static getCollectionMessage(activity: ICollectionActivities): string {
        switch (activity.typeID) {
            case 1:
                return "app.ActivitiesService.Collection.typeID1";
            case 2:
                return "app.ActivitiesService.Collection.typeID2";
            case 3:
                return "app.ActivitiesService.Collection.typeID3";
            case 24:
                return "app.ActivitiesService.Collection.typeID24";
            case 25:
                return "app.ActivitiesService.Collection.typeID25";
        }

        return "";
    }

    private static getCollectionFileMessage(activity: ICollectionFileActivities): string {
        switch (activity.typeID) {
            case 27:
                return "app.ActivitiesService.CollectionFile.typeID27";
            case 28:
                return "app.ActivitiesService.CollectionFile.typeID28";
            case 29:
                return "app.ActivitiesService.CollectionFile.typeID29";
            case 30:
                return "app.ActivitiesService.CollectionFile.typeID30";
        }
        return "";
    }

    private static getFolderMessage(activity: IFolderActivities): string {
        switch (activity.typeID) {
            case 1:
                return "app.ActivitiesService.Folder.typeID1";
            case 2:
                return "app.ActivitiesService.Folder.typeID2";
            case 3:
                return "app.ActivitiesService.Folder.typeID3";
            case 22:
                return "app.ActivitiesService.Folder.typeID22";
            case 23:
                return "app.ActivitiesService.Folder.typeID23";
        }
        return "";
    }

    private static getFileMessage(activity: IFileActivities): string {
        switch (activity.typeID) {
            case 1:
                return "app.ActivitiesService.File.typeID1";
            case 2:
                return "app.ActivitiesService.File.typeID2";
            case 3:
                return "app.ActivitiesService.File.typeID3";
            case 31:
                return "app.ActivitiesService.File.typeID31";
            case 19:
                return "app.ActivitiesService.File.typeID19";
            case 21:
                return "app.ActivitiesService.File.typeID21";
            case 22:
                return "app.ActivitiesService.File.typeID22";
            case 23:
                return "app.ActivitiesService.File.typeID23";
        }
        return "";
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static convertActivities(activity: any, translateKey: string): IActivities {
        return {
            created: activity.created,
            message: {
                key: translateKey,
                params: activity,
            },
        };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static async convertListResultTime(requestData: Promise<IListResult<any>>): Promise<any[]> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (
            (await requestData).items
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .map((item: any) => {
                    return {
                        ...item,
                        created: convertStrToDate(item.time),
                    };
                })
                .filter((item) => !!item.created)
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .sort((a: any, b: any) => {
                    if (a.created && b.created) {
                        if (a.created.getTime() > b.created.getTime()) {
                            return -1;
                        }
                        if (a.created.getTime() < b.created.getTime()) {
                            return 1;
                        }
                    }
                    return 0;
                })
        );
    }

    public async getActivities(type: IActivitiesObjectType, objectId: number, options?: { parentId?: number }): Promise<IActivities[]> {
        switch (type) {
            case IActivitiesObjectType.PROJECT:
                return (await this.getProject(objectId)).map(ActivitiesService.convertProject);
            case IActivitiesObjectType.COLLECTION:
                return (await this.getCollection(objectId)).map(ActivitiesService.convertCollection);
            case IActivitiesObjectType.COLLECTION_OBJECT:
                if (options?.parentId) {
                    return (await this.getCollectionFile(objectId, options.parentId)).map(ActivitiesService.convertCollectionFile);
                }
                throw new LogicError("Activities collection object required parentId with collection id");
            case IActivitiesObjectType.FOLDER:
                return (await this.getFolder(objectId)).map(ActivitiesService.convertFolder);
            case IActivitiesObjectType.OBJECT:
                return (await this.getFile(objectId)).map(ActivitiesService.convertFile);
        }
        throw new LogicError(`Activities service not supported object type ${type}`);
    }

    private async getProject(projectId: number): Promise<IProjectActivities[]> {
        return ActivitiesService.convertListResultTime(this.activitiesActionApiService.getProject(projectId));
    }

    private async getCollection(collectionId: number): Promise<ICollectionActivities[]> {
        return ActivitiesService.convertListResultTime(this.activitiesActionApiService.getCollection(collectionId));
    }

    private async getCollectionFile(objectId: number, collectionId: number): Promise<ICollectionFileActivities[]> {
        return ActivitiesService.convertListResultTime(this.activitiesActionApiService.getCollectionFile(objectId, collectionId));
    }

    private async getFolder(folderId: number): Promise<IFolderActivities[]> {
        return ActivitiesService.convertListResultTime(this.activitiesActionApiService.getFolder(folderId));
    }

    private async getFile(fileId: number): Promise<IFileActivities[]> {
        return ActivitiesService.convertListResultTime(this.activitiesActionApiService.getFile(fileId));
    }
}
