import { Action, Dispatch } from "redux";

export interface SetPageInfoBoxTitlesActionProps {
    setPageInfoBoxTitlesAction: (data: PageInfoBoxProps) => void;
}

export interface PageInfoBoxProps {
    title?: string;
    subTitle?: string;
}

const initialState: PageInfoBoxProps = {
    title: undefined,
    subTitle: undefined,
};

export function setPageInfoBoxTitlesAction(dispatch: Dispatch): (data?: PageInfoBoxProps) => void {
    return (data?: PageInfoBoxProps) => {
        dispatch({
            type: PageInfoBoxReducerAction.SET_TITLES,
            payload: {
                title: data?.title,
                subTitle: data?.subTitle,
            },
        });
    };
}

export enum PageInfoBoxReducerAction {
    SET_TITLES = "PageInfoBoxReducerAction/SET_TITLES",
}

// eslint-disable-next-line
export const PageInfoBoxReducer = (state: PageInfoBoxProps = initialState, action: Action & { payload: any }) => {
    switch (action.type) {
        case PageInfoBoxReducerAction.SET_TITLES:
            return { ...state, ...action.payload };
    }
    return state;
};
