import * as React from "react";
import { WedMainMenu } from "../../../../../components/main-menu/WedMainMenu/WedMainMenu";
import { WedMainMenuItem } from "../../../../../components/main-menu/WedMainMenuItem/WedMainMenuItem";
import classNames from "classnames";

interface PropsInterface {
    activeTab: "all" | "favorites";
}

interface StateInterface {}

export class ProjectListMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMainMenu>
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "all" })}
                    path="/projects"
                    label="app.projectListMenu.all"
                />
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "favorites" })}
                    path="/projects?favorites=true"
                    label="app.projectListMenu.favorites"
                />
            </WedMainMenu>
        );
    }
}
