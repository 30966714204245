import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { Grid, TextField } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import NotLoggedFormPage from "../../../components/template/NotLoggedFormPage/NotLoggedFormPage";
import { generatePath, Link, RouteComponentProps, withRouter } from "react-router-dom";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import container from "../../../container";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface PropsInterface extends ProviderContext, WrappedComponentProps, RouteComponentProps {}

interface StateInterface {
    success: boolean;
    fields: {
        email: string;
    };
}

const CAPTCHA_ACTION = "forgotpassword";

class ForgotPasswordPage extends Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            email: {
                type: "string",
                // pattern: RegexpPatterns.EMAIL,
            },
        },
        required: ["email"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                email: "app.ForgotPasswordPage.errorEmail",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            success: false,
            fields: {
                email: "",
            },
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // eslint-disable-next-line
    private async handleSubmit(_: any, token: string | undefined) {
        try {
            await container.userService.forgot(token ? { recaptcha_token: token, action: CAPTCHA_ACTION } : undefined, this.state.fields.email);
            this.setState({ success: true });
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_EMAIL_INVALID) {
                keyTranslate = "app.ForgotPasswordPage.errorEmail";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_PASSWORD_RESET_FORM_INVALID) {
                keyTranslate = "app.ForgotPasswordPage.errorPasswordResetFormInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CAPTCHA_VERIFIED) {
                keyTranslate = "app.global.captchaVerifiedError";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        this.setState({
            fields: {
                ...this.state.fields,
                [name]: value,
            },
        });
    }

    render() {
        return (
            <NotLoggedFormPage
                maxWidth="lg"
                className="register-page"
                handleSubmit={this.handleSubmit}
                labelSubmit={"app.ForgotPasswordPage.requestNewPassword"}
                header={<FormattedMessage id={"app.ForgotPasswordPage.header"} />}
                withLogo={true}
                formData={this.state.fields}
                formSchema={this.schema}
                noDisplayButtons={this.state.success}
                withCaptcha={CAPTCHA_ACTION}
                footer={
                    <Grid container>
                        <Grid item xs>
                            <FormattedMessage id={"app.ForgotPasswordPage.rememberPassword"} />
                            <br />
                            <Link to={generatePath(RoutesEnum.LOGIN)}>
                                <FormattedMessage id={"app.ForgotPasswordPage.signIn"} />
                            </Link>
                        </Grid>
                        <Grid item>
                            <FormattedMessage id={"app.ForgotPasswordPage.notRegister"} />
                            <br />
                            <Link to={generatePath(RoutesEnum.REGISTER)}>
                                <FormattedMessage id={"app.ForgotPasswordPage.createNewAccount"} />
                            </Link>
                        </Grid>
                    </Grid>
                }
            >
                {this.state.success ? (
                    <div className="not-logged-popup-page--text-container">
                        {this.props.intl.formatMessage({ id: "app.ForgotPasswordPage.successMessage" })}
                    </div>
                ) : (
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="email"
                        label={this.props.intl.formatMessage({ id: "app.ForgotPasswordPage.label.email" })}
                        id="email"
                        autoComplete="email"
                        value={this.state.fields.email}
                        onChange={this.handleChange}
                    />
                )}
            </NotLoggedFormPage>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(ForgotPasswordPage)));
