import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IUser } from "../../../services/WedApi/Models/User";
import container from "../../../container";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    projectId: number;
    customerContact?: IUser;
    close: (changed?: boolean) => void;
}

interface StateInterface {
    loading: boolean;
}

class UserProjectCustomerContactRemovePopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.removeCustomerContact = this.removeCustomerContact.bind(this);
    }

    private async removeCustomerContact() {
        if (this.props.customerContact) {
            try {
                await container.projectService.denyAccessCustomerContact(this.props.projectId, this.props.customerContact.id);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.user.UserProjectCustomerContactRemovePopup.success" }), {
                    variant: "success",
                });
            } catch (e) {
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
                return false;
            }
        }
    }

    render() {
        return (
            <PopupConfirmButton
                open={!!this.props.customerContact}
                close={this.props.close}
                doIt={this.removeCustomerContact}
                header={<FormattedMessage id={"app.user.UserProjectCustomerContactRemovePopup.header.title"} />}
                content={
                    this.props.customerContact ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: this.props.intl.formatMessage(
                                    {
                                        id: "app.user.UserProjectCustomerContactRemovePopup.header.content",
                                    },
                                    {
                                        firstName: this.props.customerContact.firstName,
                                        lastName: this.props.customerContact.lastName,
                                    }
                                ),
                            }}
                        />
                    ) : undefined
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(UserProjectCustomerContactRemovePopup));
