import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "./ProfileWithProjects.scss";

interface PropsInterface extends RouteComponentProps {
    onChangeTab?: (tabId: string | null) => void;
    profile: React.ReactElement;
    projects: {
        id: string;
        label: React.ReactElement;
        content: React.ReactElement;
    }[];
}

interface StateInterface {
    activeTab: string | null;
}

class ProfileWithProjects extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { activeTab: null };
    }

    componentDidMount() {
        this.onUpdateHash();
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.location.hash !== prevProps.location.hash) {
            this.onUpdateHash();
        }
    }

    private onUpdateHash() {
        const tabId = this.getCurrentHashTab();
        this.setState({
            activeTab: tabId,
        });
        this.props.onChangeTab && this.props.onChangeTab(tabId);
    }

    private getCurrentHashTab(): string | null {
        if (this.props.location.hash) {
            const hashTabParams = /^#([a-zA-Z0-9-_]+)$/.exec(this.props.location.hash);
            if (hashTabParams && !!this.props.projects.find((project) => project.id === hashTabParams[1])) {
                return hashTabParams[1];
            }
        }
        return this.props.projects.length > 0 ? this.props.projects[0].id : null;
    }

    render() {
        const projectContent = this.props.projects.find((project) => project.id === this.state.activeTab);

        return (
            <div className="profile-with-projects">
                <div className="profile-with-projects--profile">{this.props.profile}</div>
                <div className="profile-with-projects--projects">
                    <div className="profile-with-projects--projects-menu main-menu">
                        <ul>
                            {this.props.projects.map((item, i) => (
                                <li key={i} className={this.state.activeTab === item.id ? "main-menu__active" : "client-menu__projects"}>
                                    <a href={`#${item.id}`}>
                                        <span>{item.label}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="client-contacts-page-details-page--projects-list">{projectContent?.content}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(ProfileWithProjects);
