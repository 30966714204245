import React from "react";
import "./TagItem.scss";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconTrashDeleteRemove } from "../../../wed-icon/generated/WedIconSvg";

interface PropsInterface {
    tag: string;
    handleRemoveClick?: (tag: string) => void;
}

interface StateInterface {}

export default class TagItem extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className="tag-item">
                <div className="tag-item--container">
                    <span className="tag-item--value">
                        <span>&nbsp;</span>
                        {this.props.tag}
                        <span>&nbsp;</span>
                    </span>
                    {!!this.props.handleRemoveClick && (
                        <button className="wed-icon-button tag-item--remove-icon">
                            <WedIcon
                                icon={<WedIconTrashDeleteRemove />}
                                size={SizeType.SMALL}
                                onClick={() => {
                                    this.props.handleRemoveClick && this.props.handleRemoveClick(this.props.tag);
                                }}
                            />
                        </button>
                    )}
                </div>
            </div>
        );
    }
}
