import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { IProjectExtended } from "../../../../services/WedApi/Models/Project";
import { ITeamUser, ITeamUserProjectRole, IUser } from "../../../../services/WedApi/Models/User";
import UserProjectChangeRolePopup from "../../../user/UserProjectChangeRolePopup/UserProjectChangeRolePopup";
import UserProjectDennyAccessRolePopup from "../../../user/UserProjectDennyAccessRolePopup/UserProjectDennyAccessRolePopup";
import { getProjectCustomerContacts, getProjectTeamMembers } from "../../../../services/WedApi/Utils/UserTeamMembers";
import UserProjectCustomerContactRemovePopup from "../../../user/UserProjectCustomerContactRemovePopup/UserProjectCustomerContactRemovePopup";
import SummaryPageBoxContainer from "../../../summary/SummaryPageBoxContainer/SummaryPageBoxContainer";
import TeamWedTable from "../../../summary/TeamWedTable/TeamWedTable";

interface PropsInterface {
    className?: string;
    project: IProjectExtended;
    handleProjectChange: () => void;
}

interface StateInterface {
    userProjectChangeRolePopup?: { userMember: ITeamUser; newRole: ITeamUserProjectRole };
    userProjectDennyAccessRolePopup?: { userMember: ITeamUser };
    customerClientRemovePopup?: { customerContact: IUser };
}

class ProjectTeamSummary extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userProjectChangeRolePopup: undefined,
            userProjectDennyAccessRolePopup: undefined,
            customerClientRemovePopup: undefined,
        };
        this.closePopup = this.closePopup.bind(this);
        this.changeUserRole = this.changeUserRole.bind(this);
        this.dennyAccessRole = this.dennyAccessRole.bind(this);
        this.removeCustomerContact = this.removeCustomerContact.bind(this);
    }

    // eslint-disable-next-line
    private changeUserRole(id: number, newRole: any) {
        const userMember = getProjectTeamMembers(this.props.project.owners, this.props.project.members).find((user) => user.id === id);
        if (userMember) {
            this.setState({
                userProjectChangeRolePopup: { userMember, newRole },
            });
        }
    }

    private dennyAccessRole(id: number) {
        const userMember = getProjectTeamMembers(this.props.project.owners, this.props.project.members).find((user) => user.id === id);
        if (userMember) {
            this.setState({
                userProjectDennyAccessRolePopup: { userMember },
            });
        }
    }

    private removeCustomerContact(id: number) {
        const customerClient = getProjectCustomerContacts(this.props.project.ecUsers).find((user) => user.id === id);
        if (customerClient) {
            this.setState({
                customerClientRemovePopup: { customerContact: customerClient },
            });
        }
    }

    private closePopup(changed?: boolean) {
        this.setState({
            userProjectChangeRolePopup: undefined,
            userProjectDennyAccessRolePopup: undefined,
            customerClientRemovePopup: undefined,
        });
        if (changed) {
            this.props.handleProjectChange();
        }
    }

    render() {
        const teamMembers = getProjectTeamMembers(this.props.project.owners, this.props.project.members);
        const customerContacts = getProjectCustomerContacts(this.props.project.ecUsers);

        return (
            <SummaryPageBoxContainer
                className={classNames("project-team-summary", this.props.className)}
                header={<FormattedMessage id={"app.ProjectTeamSummary.header"} />}
            >
                <UserProjectChangeRolePopup
                    projectId={this.props.project.id}
                    userMember={this.state.userProjectChangeRolePopup?.userMember}
                    newRole={this.state.userProjectChangeRolePopup?.newRole}
                    close={this.closePopup}
                />
                <UserProjectDennyAccessRolePopup
                    projectId={this.props.project.id}
                    userMember={this.state.userProjectDennyAccessRolePopup?.userMember}
                    close={this.closePopup}
                />
                <UserProjectCustomerContactRemovePopup
                    projectId={this.props.project.id}
                    customerContact={this.state.customerClientRemovePopup?.customerContact}
                    close={this.closePopup}
                />
                <div className="project-team-summary--container">
                    <TeamWedTable
                        teamType={"PROJECT"}
                        users={teamMembers}
                        userIsOwner={this.props.project.userIsOwner}
                        contacts={customerContacts}
                        actions={{
                            changeUserRole: this.changeUserRole,
                            dennyAccessRole: this.dennyAccessRole,
                            removeCustomerContact: this.removeCustomerContact,
                        }}
                    />
                </div>
            </SummaryPageBoxContainer>
        );
    }
}

export default ProjectTeamSummary;
