import React, { Component } from "react";
import { generatePath, NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { FormattedMessage } from "react-intl";
import SearchInput from "../../search/SearchInput/SearchInput";
import Initials from "../../tile/components/Initials/Initials";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import {
    WedIconBellNotification1,
    WedIconLockRefresh,
    WedIconLoginOgout,
    WedIconSettingsSelect3,
    WedIconSuitcasePortfolio1,
    WedIconUserProfileSquare1,
} from "../../wed-icon/generated/WedIconSvg";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { WedDropDownMenu } from "../../wed-drop-down-menu/WedDropDownMenu/WedDropDownMenu";
import WedDropDownMenuItem from "../../wed-drop-down-menu/WedDropDownMenuItem/WedDropDownMenuItem";
import UserMyProfilePopup from "../../user/UserMyProfilePopup/UserMyProfilePopup";
import WedImageLogo40x40 from "../../wed-image-logo/WedImageLogo40x40";
import UserMyProfileLogout from "../../user/UserMyProfileLogout/UserMyProfileLogout";
import UserMyChangePasswordPopup from "../../user/UserMyChangePasswordPopup/UserMyChangePasswordPopup";
import UserNotifs from "../../user/UserNotifs/UserNotifs";
import { IUserMyProfileRole } from "../../../services/WedApi/Models/User";
import classNames from "classnames";
import "./HeaderNavigation.scss";
import { NotifsProps } from "../../../store/NotifsReducer";
import { Tooltip } from "@material-ui/core";

const headerNavigation = [
    { path: RoutesEnum.PROJECTS, label: "app.headerNavigation.projects" },
    { path: RoutesEnum.CLIENTS, label: "app.headerNavigation.customersAccounts" },
    { path: RoutesEnum.MY_LIBRARY_FILES, label: "app.headerNavigation.myLibrary", urlPrefix: /^\/my-library\// },
    { path: RoutesEnum.USER_TEAM, label: "app.headerNavigation.team" },
];

interface BasePropsInterface extends RouteComponentProps, NotifsProps {}

interface PropsInterface extends BasePropsInterface {
    firstName: string;
    lastName: string;
    userId: number;
    email: string;
    isAdmin: boolean;
}

interface StateInterface {
    open: boolean;
    notifs: boolean;
    popup?: {
        profile?: {
            open: boolean;
        };
        logout?: {
            open: boolean;
        };
        password?: {
            open: boolean;
        };
    };
}

class HeaderNavigation extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            open: false,
            notifs: false,
        };
        this.hideUserMenu = this.hideUserMenu.bind(this);
        this.showUserMenu = this.showUserMenu.bind(this);
        this.clickNotifs = this.clickNotifs.bind(this);
        this.clickAdminPanel = this.clickAdminPanel.bind(this);
        this.hideNotifs = this.hideNotifs.bind(this);
        this.myProfile = this.myProfile.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.privatePortfolio = this.privatePortfolio.bind(this);
        this.adminPanel = this.adminPanel.bind(this);
        this.signOut = this.signOut.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    private hideUserMenu() {
        this.setState({ open: false });
    }

    private showUserMenu() {
        this.setState({ open: true });
    }

    private hideNotifs() {
        this.setState({ notifs: false });
    }

    private clickNotifs() {
        if (this.state.notifs) {
            this.hideNotifs();
        } else {
            this.setState({ notifs: true });
        }
    }

    private clickAdminPanel() {
        this.props.history.push(generatePath(RoutesEnum.ADMIN_PANEL));
    }

    private closePopup() {
        this.hideUserMenu();
        this.setState({ popup: undefined });
    }

    private myProfile() {
        this.hideUserMenu();
        this.setState({ popup: { profile: { open: true } } });
    }

    private changePassword() {
        this.hideUserMenu();
        this.setState({ popup: { password: { open: true } } });
    }

    private privatePortfolio() {
        this.hideUserMenu();
        this.props.history.push(
            generatePath(RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE, {
                teamId: this.props.userId,
            }) + "#private-portfolio"
        );
    }

    private adminPanel() {
        const { pathname, search, hash } = this.props.location;

        this.props.history.push(generatePath(RoutesEnum.ADMIN_PANEL), { from: pathname + search + hash });
    }

    private async signOut() {
        this.hideUserMenu();
        this.setState({ popup: { logout: { open: true } } });
    }

    render() {
        const user = getInitialsAndOwner({
            firstName: this.props.firstName,
            lastName: this.props.lastName,
        });
        const notifsItems = this.props.notifsItems || [];

        return (
            <nav className="navbar">
                <a className="navbar__icon">
                    <WedImageLogo40x40 />
                </a>
                <ul className="navbar__navigation">
                    {headerNavigation.map((item) => (
                        <li key={item.label} className="navbar__item">
                            <NavLink
                                className={classNames(
                                    "navbar__link",
                                    {
                                        "active": !!item.urlPrefix && !!item.urlPrefix.test(this.props.location.pathname)
                                    }
                                )}
                                to={item.path}
                                activeClassName="active"
                            >
                                <FormattedMessage id={item.label} />
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <div className="navbar__search-container">
                    <SearchInput />
                </div>
                <div className={classNames("navbar__user-data", { "navbar__user-data-admin-panel": this.props.isAdmin })}>
                    {this.props.isAdmin && (
                        <div className="navbar__user-data-admin-panel-box">
                            <Tooltip title={<FormattedMessage id={"app.ico.adminPanel.title"} />}>
                                <div>
                                    <WedIcon
                                        className="navbar__user-data-admin-panel-button"
                                        icon={<WedIconSettingsSelect3 />}
                                        size={SizeType.NORMAL}
                                        onClick={this.clickAdminPanel}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                    <div className="navbar__user-data-notification">
                        <Tooltip title={<FormattedMessage id={"app.ico.notification.title"} />}>
                            <div
                                className={classNames("navbar__user-data-notification-icon-container", {
                                    "navbar__user-data-notification-with-counter": notifsItems.length > 0,
                                })}
                            >
                                <WedIcon
                                    className="navbar__user-data-notification-button"
                                    icon={<WedIconBellNotification1 />}
                                    size={SizeType.NORMAL}
                                    onClick={this.clickNotifs}
                                />
                                {notifsItems.length > 0 && (
                                    <span className="navbar__user-data-notification-icon-container-content">{notifsItems.length}</span>
                                )}
                            </div>
                        </Tooltip>
                        {this.state.notifs && (
                            <ClickAwayListener onClickAway={this.hideNotifs}>
                                <div className="navbar__user-notifs">
                                    <UserNotifs notifsItems={notifsItems} />
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                    <div className="navbar__user-data-profile">
                        <span className="navbar__user-data-profile-icon" onClick={this.showUserMenu}>
                            <Tooltip title={<FormattedMessage id={"app.ico.myAccount"} />}>
                                <div>
                                    <Initials title={user.initials} />
                                </div>
                            </Tooltip>
                        </span>
                        {this.state.open && (
                            <ClickAwayListener onClickAway={this.hideUserMenu}>
                                <div className="navbar__user-data-menu">
                                    <WedDropDownMenu>
                                        <div className="navbar__user-information">
                                            <div className="navbar__user-information-name">{`${this.props.firstName} ${this.props.lastName}`}</div>
                                            <div className="navbar__user-information-email">{`${this.props.email}`}</div>
                                        </div>
                                        <WedDropDownMenuItem
                                            item={{
                                                label: "app.HeaderNavigation.myProfile",
                                                icon: <WedIcon icon={<WedIconUserProfileSquare1 />} size={SizeType.SMALL} />,
                                                click: this.myProfile,
                                            }}
                                        />
                                        <WedDropDownMenuItem
                                            item={{
                                                label: "app.HeaderNavigation.changePassword",
                                                icon: <WedIcon icon={<WedIconLockRefresh />} size={SizeType.SMALL} />,
                                                click: this.changePassword,
                                            }}
                                        />
                                        <WedDropDownMenuItem
                                            item={{
                                                label: "app.HeaderNavigation.privatePortfolio",
                                                icon: <WedIcon icon={<WedIconSuitcasePortfolio1 />} size={SizeType.SMALL} />,
                                                click: this.privatePortfolio,
                                            }}
                                        />
                                        <WedDropDownMenuItem
                                            item={{
                                                label: "app.HeaderNavigation.signOut",
                                                icon: <WedIcon icon={<WedIconLoginOgout />} size={SizeType.SMALL} />,
                                                click: this.signOut,
                                            }}
                                        />
                                    </WedDropDownMenu>
                                </div>
                            </ClickAwayListener>
                        )}
                    </div>
                </div>
                {this.state.popup?.profile?.open && <UserMyProfilePopup close={this.closePopup} />}
                {this.state.popup?.password?.open && <UserMyChangePasswordPopup close={this.closePopup} />}
                {this.state.popup?.logout?.open && <UserMyProfileLogout close={this.closePopup} />}
            </nav>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id || 0,
        firstName: state.user.data?.firstName || "",
        lastName: state.user.data?.lastName || "",
        email: state.user.data?.email || "",
        isAdmin: state.user.data?.role === IUserMyProfileRole.ADMIN_USER,
        notifsItems: state.notifs.notifsItems,
    };
};

export default withRouter(connect(mapStateToProps)(HeaderNavigation));
