import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import WedDate from "../../../formatter/WedDate";
import InformationSummaryViewDetails from "../../../summary/InformationSummaryViewDetails/InformationSummaryViewDetails";
import { IClient, IClientAddress } from "../../../../services/WedApi/Models/Client";

interface PropsInterface extends WrappedComponentProps {
    client: IClient;
    clientAddress: IClientAddress;
}

interface StateInterface {}

class ClientInformationSummaryViewDetails extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { client, clientAddress } = this.props;

        return (
            <InformationSummaryViewDetails
                items={[
                    {
                        label: this.props.intl.formatMessage({ id: "app.ClientInformationSummaryViewDetails.info.id" }),
                        value: `${client.id}`,
                    },
                    {
                        label: this.props.intl.formatMessage({ id: "app.ClientInformationSummaryViewDetails.info.created" }),
                        value: (
                            <>
                                {client.created && <WedDate date={client.created} />}
                                {client.createdBy && (
                                    <>
                                        <span className="information-summary-view-details--list-value-content-small">
                                            <FormattedMessage id={"app.global.by"} />
                                        </span>
                                        {`${client.createdBy.firstName} ${client.createdBy.lastName}`}
                                    </>
                                )}
                            </>
                        ),
                    },
                    ...(clientAddress.website
                        ? [
                              {
                                  label: this.props.intl.formatMessage({ id: "app.ClientInformationSummaryViewDetails.info.website" }),
                                  value: (
                                      <>
                                          {/* eslint-disable-next-line react/no-string-refs */}
                                          <a href={clientAddress.website} target={"_blank"} rel="noreferrer">
                                              {clientAddress.website}
                                          </a>
                                      </>
                                  ),
                              },
                          ]
                        : []),
                ]}
            />
        );
    }
}

export default injectIntl(ClientInformationSummaryViewDetails);
