import container from "../../container";

class Redirect {
    static to(path: string) {
        container.history.push({
            pathname: path,
        });
    }
}

export default Redirect;
