import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import { IFile } from "../../../services/WedApi/Models/File";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import ChangeDescriptionPopup from "../../../components/project/Files/ChangeDescriptionPopup";
import { ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import SidebarPreview from "../../../components/sidebar/SidebarPreview/SidebarPreview";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import CommentBox from "../../../components/comments/CommentBox/CommentBox";
import { INote, NoteObjectType } from "../../../services/WedApi/Models/Note";
import { AppNotFoundItemError } from "../../../errors/error-app";
import { ICollectionExtended } from "../../../services/WedApi/Models/Collection";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import SidebarApprovalStatus from "../../../components/sidebar/SidebarApprovalStatus/SidebarApprovalStatus";
import { FileSize } from "../../../components/file/FileSize/FileSize";
import WedDate from "../../../components/formatter/WedDate";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import { IActivitiesObjectType } from "../../../services/WedApi/Services/ActivitiesService";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    getFileVersionProofs,
    showCollectionDotStatusColor,
} from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends WrappedComponentProps {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    fileId: number;
    versionId?: number;
    collectionId?: number;
    fileType?: FileTypeEnum;
    clientId?: number;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    file: IFile | undefined;
    collection: ICollectionExtended | undefined;
    showChangeDescriptionPopup: boolean;
    notes: INote[];
    activities: IActivities[];
}

class CollectionFileSidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        file: undefined,
        collection: undefined,
        showChangeDescriptionPopup: false,
        notes: [],
        activities: [],
    };

    async componentDidMount() {
        await this.fetchData(true);
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>, prevState: Readonly<StateInterface>) {
        const withLoading = prevState.showChangeDescriptionPopup == this.state.showChangeDescriptionPopup;

        await this.fetchData(withLoading);
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.fileId != this.props.fileId ||
            nextProps.versionId !== this.props.versionId ||
            nextProps.collectionId !== this.props.collectionId ||
            nextState?.file?.id != this.state?.file?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup
        );
    }

    checkIsOpenPopupAddFile(): boolean {
        const elements = document.getElementsByClassName('popup-content');
        for (let i = 0; i < elements.length; i++) {
            const subElements = elements[i].getElementsByClassName('add-file-to-data--container');
            return (subElements.length > 0);
        }
        return false;
    }

    fetchData = async (withLoadingCallback: boolean) => {
        if (this.checkIsOpenPopupAddFile()) {
            return;
        }

        const { fileId, collectionId, loadingCallback } = this.props;
        const { file, showChangeDescriptionPopup } = this.state;

        if (!(file?.id != fileId || showChangeDescriptionPopup)) {
            return;
        }

        if (!collectionId) {
            return;
        }

        try {
            if (withLoadingCallback) loadingCallback(true);
            const [collection, notes, activities] = await Promise.all([
                container.collectionService.getCollectionDetail(collectionId, { withProofContactsEcOrgId: this.props.clientId, withFilesDetails: [fileId] }),
                container.noteService.getCollectionFileNotes(fileId, collectionId, this.props.clientId),
                container.activitiesService.getActivities(IActivitiesObjectType.COLLECTION_OBJECT, fileId, { parentId: collectionId }),
            ]);
            const file = collection.files.find((file) => file.id == fileId);
            if (!file) {
                throw new AppNotFoundItemError("file", fileId);
            }

            this.setState({ file, notes, collection, activities }, () => {
                if (withLoadingCallback && this.props.fileId == file.id) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ file: undefined });
        }
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            this.props.handleSidebarChanges();
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    render() {
        if (!this.state.file || this.state.file.id != this.props.fileId) {
            return null;
        }

        const { intl, disabled, versionId } = this.props;
        const { file, collection, showChangeDescriptionPopup } = this.state;
        const fileVersionId = versionId || file?.newestVersionId || undefined;
        const fileVersionNo = fileVersionId ? file?.versions.find((version) => version.id === fileVersionId)?.versionNumber : undefined;
        const approvalStatusOptions = {
            approvalFlag: file?.approvalFlag as number,
            approvalRequired: file?.approvalRequired,
            versionId: this.props.versionId,
            file: file,
        };
        const proofs = getFileVersionProofs(approvalStatusOptions);

        return (
            <div className="collection-file-sidebar sidebar--container">
                <SidebarTitle
                    title={file.name}
                    suffix={
                        fileVersionNo ? intl.formatMessage({ id: "app.sidebar.menu.fileVersion" }, { fileVersionNo: `${fileVersionNo}` }) : undefined
                    }
                />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarPreview
                                url={container.filesService.getFileUrl(file.id, file.newestVersionId, {
                                    size: SizeImageEnum.THUMB,
                                    fileType: this.props.fileType,
                                    collectionId: this.props.collectionId,
                                })}
                                extension={file?.extension}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.type" }),
                                        values: [
                                            { text: file?.extension.toUpperCase() },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.size" }),
                                                labelFormat: true,
                                            },
                                            {
                                                key: "sidebar-collection-file-size",
                                                text: <FileSize size={file?.size} />,
                                            },
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.location" }),
                                        values: [{ text: collection?.name }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.updated" }),
                                        values: [
                                            { text: file?.updated ? <WedDate date={file.updated} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: (file?.updatedBy) ? `${file?.updatedBy?.firstName} ${file?.updatedBy?.lastName}` : 'unknown'},
                                        ],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: file?.created ? <WedDate date={file.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: (file?.createdBy) ? `${file?.createdBy?.firstName} ${file?.createdBy?.lastName}` : 'unknown'},
                                        ],
                                    },
                                ].concat(
                                    ...[
                                        showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, approvalStatusOptions)
                                            ? [
                                                  {
                                                      label: intl.formatMessage({ id: "app.sidebar.details.approval" }),
                                                      values: [
                                                          {
                                                              text: (
                                                                  <ItemDotStatus
                                                                      color={getCollectionDotStatusColor(
                                                                          CollectionDotStatusColorItemType.COLLECTION_FILE,
                                                                          approvalStatusOptions
                                                                      )}
                                                                  />
                                                              ),
                                                          },
                                                      ],
                                                  },
                                              ]
                                            : [],
                                    ]
                                )}
                            />
                            <SidebarDescription
                                allowToAdd={!disabled && !!collection?.userIsOwner}
                                description={file?.description}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            {proofs && proofs.length > 0 && (
                                <SidebarApprovalStatus title={intl.formatMessage({ id: "app.sidebar.approvalStatus.title" })} proofs={proofs} />
                            )}
                            <CommentBox
                                disabled={disabled}
                                object={{
                                    id: file.id,
                                    type: NoteObjectType.file,
                                    data: {
                                        fileVersionId: fileVersionId,
                                        collectionId: this.props.collectionId,
                                        clientId: this.props.clientId,
                                    },
                                }}
                                notes={this.state.notes || []}
                            />
                        </>,
                        <>
                            <SidebarActivities activities={this.state.activities} />
                        </>,
                    ]}
                />
                {showChangeDescriptionPopup && (
                    <ChangeDescriptionPopup
                        id={file.id}
                        type={ISelectItemType.file}
                        extra={{ collection: this.state.collection }}
                        object={file}
                        handleClose={() => this.onCloseChangeDescriptionPopup(false)}
                        onSuccess={() => this.onCloseChangeDescriptionPopup(true)}
                    />
                )}
            </div>
        );
    }
}

export default injectIntl(CollectionFileSidebar);
