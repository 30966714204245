import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import NotLoggedPopupPage from "../NotLoggedPopupPage/NotLoggedPopupPage";
import JSONSchemaValidator, { ValidationResult } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import container from "../../../container";
import "./NotLoggedFormPage.scss";
import classNames from "classnames";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    beforeHeader?: React.ReactNode;
    header?: React.ReactNode;
    children?: React.ReactNode | React.ReactNodeArray;
    footer?: React.ReactNode;
    maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
    className?: string;
    handleSubmit: (validationResult: ValidationResult, token?: string) => Promise<void>;
    iconSubmit?: React.ReactElement;
    labelSubmit: string;
    // eslint-disable-next-line
    formData?: { [key: string]: any };
    // eslint-disable-next-line
    formSchema?: object | string | boolean;
    withLogo?: boolean;
    noDisplayButtons?: boolean;
    withCaptcha?: string;
}

interface StateInterface {
    loading: boolean;
}

class NotLoggedFormPage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    private async handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const validationResult = this.validate();
        if (!validationResult.valid) {
            const firstKey: string = Object.keys(validationResult.errors)[0];
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: validationResult.errors[firstKey] }), { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        let captchaToken: string | undefined = undefined;
        if (this.props.withCaptcha) {
            try {
                const captchaResponse = await container.captchaService.valid(this.props.withCaptcha);
                if (!captchaResponse.valid) {
                    this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.captchaVerifiedError" }), { variant: "error" });
                    this.setState({ loading: false });
                    return;
                } else if (captchaResponse.token) {
                    captchaToken = captchaResponse.token;
                }
            } catch (err) {
                // eslint-disable-next-line
                console.error(err);
                this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.captchaUndefinedError" }), { variant: "error" });
                this.setState({ loading: false });
                return;
            }
        }

        await this.props.handleSubmit(validationResult, captchaToken);

        this.setState({ loading: false });
    }

    private validate(): ValidationResult {
        let valid = true;
        const validateResult = this.validateForm();
        if (_size(validateResult.errors)) {
            valid = false;
        }
        return { valid, errors: validateResult.errors };
    }

    private validateForm(): ValidationResult {
        if (this.props.formSchema) {
            return JSONSchemaValidator.validate({ ...(this.props.formData || {}) }, this.props.formSchema);
        }
        return { valid: true, errors: {} };
    }

    render() {
        return (
            <NotLoggedPopupPage
                maxWidth={this.props.maxWidth}
                className={this.props.className}
                beforeHeader={this.props.beforeHeader}
                header={this.props.header}
                withLogo={this.props.withLogo}
            >
                <div className="not-logged-form-page--form">
                    <form onSubmit={this.handleSubmit} noValidate>
                        <>{this.props.children}</>
                        {!this.props.noDisplayButtons && (
                            <div
                                className={classNames("not-logged-form-page--buttons", {
                                    "not-logged-form-page--buttons-flex": !!this.props.withCaptcha,
                                })}
                            >
                                <Button type="submit" disabled={this.state.loading} startIcon={this.props.iconSubmit}>
                                    <FormattedMessage id={this.props.labelSubmit} />
                                </Button>
                                {this.props.withCaptcha && (
                                    <div className="not-logged-form-page--captcha-term">
                                        <div className="not-logged-form-page--captcha-term-container">
                                            <FormattedMessage id="app.NotLoggedFormPage.captcha.prefix" />
                                            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                                                <FormattedMessage id="app.NotLoggedFormPage.captcha.privacyPolicy" />
                                            </a>
                                            <FormattedMessage id="app.NotLoggedFormPage.captcha.between" />
                                            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
                                                <FormattedMessage id="app.NotLoggedFormPage.captcha.termsOfService" />
                                            </a>
                                            <FormattedMessage id="app.NotLoggedFormPage.captcha.suffix" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {this.props.footer && <div className="not-logged-form-page--footer">{this.props.footer}</div>}
                    </form>
                </div>
            </NotLoggedPopupPage>
        );
    }
}

export default injectIntl(withSnackbar(NotLoggedFormPage));
