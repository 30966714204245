import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../popup/PopupAction/PopupAction";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import { IClientAddress, IClientExtended } from "../../../services/WedApi/Models/Client";
import container from "../../../container";

interface StateInterface {
    fields: {
        description: string;
    };
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    open: boolean;
    initialDescription?: string;
    client: IClientExtended & IClientAddress;
    handleClose: (updated?: boolean) => void;
}

class ChangeClientDescriptionPopup extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            description: {
                type: "string",
                maxLength: 500,
            },
        },
        required: [],
        additionalProperties: false,
        errorMessage: {
            properties: {
                description: this.props.intl.formatMessage({ id: "app.changeDescription.error.description" }),
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            fields: {
                description: this.props.initialDescription || "",
            },
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { loading, fields } = this.state;
        const { client, handleClose, enqueueSnackbar } = this.props;

        if (loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            const { name, website, streetAddress1, streetAddress2, zipCode, city, state, countryID } = client;

            await container.clientService.updateClient(client.id, {
                desc: fields.description,
                name,
                website,
                streetAddress1,
                streetAddress2,
                zipCode,
                city,
                state,
                countryID,
            });
            enqueueSnackbar(this.props.intl.formatMessage({ id: "app.changeDescription.success" }), { variant: "success" });

            handleClose(true);
        } catch (e) {
            enqueueSnackbar(this.props.intl.formatMessage({ id: "app.error.undefined" }), { variant: "error" });
        }

        this.setState({ loading: false });
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    };

    render() {
        const { open, handleClose } = this.props;
        return (
            <Popup open={open} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={"app.changeDescription.client.title"} />
                    </PopupHeader>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="description"
                                label={<FormattedMessage id={"app.changeDescription.form.description"} />}
                                name="description"
                                autoComplete="off"
                                value={this.state.fields.description}
                                onChange={this.handleChange}
                                multiline
                                rows={5}
                            />
                        </div>
                        <PopupAction>
                            <Button type="submit">
                                <FormattedMessage id="app.standard.popup.button.save" />
                            </Button>
                        </PopupAction>
                    </form>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(ChangeClientDescriptionPopup));
