import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { injectIntl, WrappedComponentProps } from "react-intl";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import container from "../../../container";
import { ICountry } from "../../../services/WedApi/Models/Country";
import UserBaseCompanyDataForm, { CompanyDataForm, UserBaseCompanyDataFormType } from "../UserBaseCompanyDataForm/UserBaseCompanyDataForm";
import {redirectPage} from "../../../services/WedApi/Utils/RedirectPage";
import {IApiOrganizationCompanyData} from "../../../services/WedApi/Models/User";

interface PropsInterface extends ProviderContext, WrappedComponentProps {
    reactivateData?: IApiOrganizationCompanyData;
}

interface StateInterface {
    countries: ICountry[];
    loading: boolean;
}

class UserCompanyDataForm extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            countries: [],
            loading: true,
        };
        this.submit = this.submit.bind(this);
        this.submitReactivate = this.submitReactivate.bind(this);
    }

    async componentDidMount() {
        this.setState({
            countries: await container.countryService.getCountries(),
            loading: false,
        });
    }

    private async submitReactivate (data: { selectedPlan?: number }): Promise<boolean | undefined> {
        try {
            const registerResult = await container.adminService.reactivateSubscription(data.selectedPlan as number);
            await redirectPage(registerResult.redirectUrl);
            return true;
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_BA_SUBSCRIPTION_PLAN_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.selectedPlan";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async submit(data: CompanyDataForm): Promise<boolean | undefined> {
        try {
            const registerResult = await container.userService.registerCompanyData(
                data.companyName,
                data.companyShortName || null,
                data.taxId,
                data.street,
                data.street2 || null,
                data.zipCode,
                data.city,
                data.state,
                (data.country as ICountry).id,
                data.selectedPlan as number,
            );
            await redirectPage(registerResult.redirectUrl);
            return true;
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_BA_NAME_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.errBANameInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_BA_TAX_ID_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.taxId";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_STREET_ADDRESS_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.street";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_ZIP_CODE_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.zipCode";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_STATE_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.state";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CITY_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.city";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_BA_SUBSCRIPTION_PLAN_INVALID) {
                keyTranslate = "app.UserBaseCompanyDataForm.error.selectedPlan";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    render() {
        return (
            !this.state.loading && (
                <UserBaseCompanyDataForm
                    formType={UserBaseCompanyDataFormType.REGISTER}
                    disabledCompany={!!this.props.reactivateData}
                    fields={
                        this.props.reactivateData
                            ? {
                                companyName: this.props.reactivateData.name,
                                companyShortName: this.props.reactivateData.shortName,
                                taxId: this.props.reactivateData.taxID,
                                street: this.props.reactivateData.streetAddress1,
                                street2: this.props.reactivateData.streetAddress2,
                                city: this.props.reactivateData.city,
                                state: this.props.reactivateData.state,
                                zipCode: this.props.reactivateData.zipCode,
                                country: this.state.countries.find(
                                    country => this.props.reactivateData && country.id === this.props.reactivateData.countryID
                                ) || null,
                                // eslint-disable-next-line
                                selectedPlan: 2 as any as undefined,
                            }
                            : {
                                companyName: "",
                                companyShortName: "",
                                taxId: "",
                                street: "",
                                street2: "",
                                city: "",
                                state: "",
                                zipCode: "",
                                country: null,
                                // eslint-disable-next-line
                                selectedPlan: 2 as any as undefined,
                            }
                    }
                    countries={this.state.countries}
                    submit={this.props.reactivateData ? this.submitReactivate : this.submit}
                />
            )
        );
    }
}

export default withSnackbar(injectIntl(UserCompanyDataForm));
