import React, { Component } from "react";
import Initials, { SizeType } from "../tile/components/Initials/Initials";
import { WedIconTrashDeleteRemove } from "../wed-icon/generated/WedIconSvg";
import { FunctionIcon, SizeType as WedIconSizeType, WedIcon } from "../wed-icon/WedIcon";
import User from "../../domain/User";
import "./members.scss";

interface PropsInterface {
    users: User[];
    delete: (id: number) => void;
}

interface StateInterface {}

export class Members extends Component<PropsInterface, StateInterface> {
    render() {
        return (
            <table className="members-table">
                <tbody>
                    {this.props.users.map((user: User, i) => (
                        <tr key={i}>
                            <td>
                                <Initials title={user.getInitials()} fullTitle={user.getFullName()} size={SizeType.LARGE} />
                            </td>
                            <td>
                                <div>
                                    <div className="members-name">{user.getFullName()}</div>
                                    <div className="members-mail">{user.getEmail()}</div>
                                </div>
                            </td>
                            <td>
                                <WedIcon
                                    size={WedIconSizeType.NORMAL}
                                    icon={<WedIconTrashDeleteRemove />}
                                    functionIcon={FunctionIcon.ACTION}
                                    onClick={() => {
                                        this.props.delete(user.getId());
                                    }}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}
