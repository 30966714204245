import { noticeLogger } from "./NoticeLogger";
import FrontApiErrorCodeEnum from "../Error/FrontApiErrorCodeEnum";
import RoutesEnum from "../../Routes/RoutesEnum";
import { History } from "history";
import { redirectPage } from "./RedirectPage";

// eslint-disable-next-line
export function getRouteByError(e: any): RoutesEnum | undefined {
    if (e?.response?.status === 404 && !!e?.response?.data && /ErrUserOrganizationNotSet/.test(e.response.data)) {
        noticeLogger("HANDLE ERROR - COMPANY_DATA");
        return RoutesEnum.COMPANY_DATA;
    }
    if (e?.response?.status === 401 && !!e?.response?.data && /ErrInactiveOrganizationAccount/.test(e.response.data)) {
        noticeLogger("HANDLE ERROR - COMPANY_REACTIVATE");
        return RoutesEnum.COMPANY_REACTIVATE;
    }
    if (e?.response?.status === 401) {
        noticeLogger("HANDLE ERROR - LOGIN");
        return RoutesEnum.LOGIN;
    }

    return undefined;
}

// eslint-disable-next-line
export async function redirectErrorPage(history: History, e: any) {
    noticeLogger("HANDLE ERROR - frontApiResponseErrorTransformer", e);
    if (e?.response?.status === 400 && e?.response?.data?.errormessage === FrontApiErrorCodeEnum.ERR_TOKEN_INVALID) {
        noticeLogger("HANDLE ERROR - ERR_TOKEN_INVALID");
        await redirectPage("https://storage.googleapis.com/motif-website/errorTokenInvalid.html");
    }
    if (e?.response?.status === 500 && e?.response?.data?.errormessage === FrontApiErrorCodeEnum.ERR_API_SHARED_INTERNAL) {
        noticeLogger("HANDLE ERROR - ERR_API_SHARED_INTERNAL");
        await redirectPage("https://storage.googleapis.com/motif-website/errorSharedInternal.html");
    }
    const route = getRouteByError(e);
    if (route) {
        throw history.replace(route);
    }
}
