import React from "react";
import { Tooltip } from "@material-ui/core";
import "./initials.scss";
import classNames from "classnames";

export enum SizeType {
    NORMAL = "normal",
    LARGE = "large",
}

export enum InitialsUserType {
    INTERNAL = "internal",
    EXTERNAL = "external",
}

interface PropsInterface {
    title: string;
    fullTitle?: string;
    size?: SizeType;
    userType?: InitialsUserType;
}

interface StateInterface {}

export default class Initials extends React.Component<PropsInterface, StateInterface> {
    private getSizeClassName(): string {
        switch (this.props.size) {
            case SizeType.LARGE:
                return "initials-size-large";
        }
        return "initials-size-normal";
    }

    private getUserTypeClassName(): string | undefined {
        switch (this.props.userType) {
            case InitialsUserType.EXTERNAL:
                return "initials-external-user";
            case InitialsUserType.INTERNAL:
                return "initials-internal-user";
        }
        return "initials-internal-undefined";
    }

    render() {
        const { title, fullTitle } = this.props;
        const className = classNames("initials", this.getUserTypeClassName(), this.getSizeClassName());

        return fullTitle ? (
            <Tooltip title={fullTitle}>
                <div className={className}>{title}</div>
            </Tooltip>
        ) : (
            <div className={className}>{title}</div>
        );
    }
}
