import React from "react";
import { INote, NoteObject } from "../../../services/WedApi/Models/Note";
import CommentNote from "../CommentNote/CommentNote";
import "./CommentThread.scss";
import CommentForm from "../CommentForm/CommentForm";
import classNames from "classnames";

interface PropsInterface {
    token?: string;
    disabled?: boolean;
    reload: () => Promise<void>;
    object: NoteObject;
    note: INote;
}

interface StateInterface {
    commentForm: boolean;
}

class CommentThread extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            commentForm: false,
        };
        this.replyClick = this.replyClick.bind(this);
        this.reload = this.reload.bind(this);
    }

    private async reload() {
        await this.props.reload();
        this.setState({ commentForm: false });
    }

    private replyClick() {
        this.setState({
            commentForm: !this.state.commentForm,
        });
    }

    render() {
        const noteObjectParent: NoteObject = {
            ...this.props.object,
            data: {
                ...(this.props.object.data || {}),
                parentNoteId: this.props.note.id,
            },
        };

        return (
            <div className="comment-thread">
                <CommentNote
                    disabled={this.props.disabled}
                    className={classNames({ "comment-note-with-items": this.props.note.items.length > 0 })}
                    object={this.props.object}
                    note={this.props.note}
                    replyClick={this.props.note.items.length === 0 ? this.replyClick : undefined}
                />
                {this.props.note.items.length > 0 && (
                    <div className="comment-thread--items">
                        {this.props.note.items.map((note, i) => (
                            <CommentNote
                                disabled={this.props.disabled}
                                key={i}
                                object={noteObjectParent}
                                note={note}
                                replyClick={i === this.props.note.items.length - 1 ? this.replyClick : undefined}
                            />
                        ))}
                    </div>
                )}
                {this.state.commentForm && <CommentForm token={this.props.token} object={noteObjectParent} reload={this.reload} />}
            </div>
        );
    }
}

export default CommentThread;
