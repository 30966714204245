import React, { Component } from "react";
import container from "../../../../container";
import { IPaymentMethodInfo } from "../../../../services/WedApi/Models/User";
import { Button, LinearProgress } from "@material-ui/core";
import WedDate from "../../../../components/formatter/WedDate";
import AdminPanelContainerTextTable from "../../components/AdminPanelContainerTextTable/AdminPanelContainerTextTable";
import "./AdminPanelBillingPaymentMethod.scss";
import { AdminPanelActionButton } from "../../components/AdminPanelActionButton/AdminPanelActionButton";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";

interface PropsInterface extends ProviderContext, WrappedComponentProps {}

interface StateInterface {
    paymentInfo?: IPaymentMethodInfo;
}

class AdminPanelBillingPaymentMethod extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            paymentInfo: undefined,
        };
        this.managePaymentMethod = this.managePaymentMethod.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            paymentInfo: await container.adminService.getCurrentPaymentMethod(),
        });
    }

    private async managePaymentMethod() {
        this.setState({ paymentInfo: undefined });
        try {
            await container.paymentService.goToUpdatePaymentMethodUrl();
        } catch (err) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.internalError" }), { variant: "error" });
            await this.fetchData();
        }
    }

    render() {
        return (
            <div className="admin-panel-billing-payment-method">
                {!this.state.paymentInfo ? (
                    <LinearProgress />
                ) : (
                    <>
                        <AdminPanelContainerTextTable>
                            <div className="admin-panel-billing-payment-method-content">
                                <table>
                                    <tr className="admin-panel-billing-payment-method--first-row">
                                        <td colSpan={2}>
                                            <FormattedMessage id="app.AdminPanelBillingPaymentMethod.header" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>
                                                <FormattedMessage
                                                    id="app.AdminPanelBillingPaymentMethod.label.method"
                                                    values={{ method: this.state.paymentInfo.mathod }}
                                                />
                                            </label>
                                        </td>
                                        <td>{this.state.paymentInfo.details}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label>
                                                <FormattedMessage id="app.AdminPanelBillingPaymentMethod.label.expires" />
                                            </label>
                                        </td>
                                        <td>{this.state.paymentInfo.expires}</td>
                                    </tr>
                                    <tr className="admin-panel-billing-payment-method--last-row">
                                        <td>
                                            <label>
                                                <FormattedMessage id="app.AdminPanelBillingPaymentMethod.label.methodUpdated" />
                                            </label>
                                        </td>
                                        <td>
                                            <WedDate date={this.state.paymentInfo.updatedAt} />
                                            <label className="admin-panel-billing-payment-method--label-by">
                                                <FormattedMessage id="app.global.by" />
                                            </label>
                                            {this.state.paymentInfo.updatedBy}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <AdminPanelActionButton>
                                                <Button type="submit" className="button--more-padding" onClick={this.managePaymentMethod}>
                                                    <FormattedMessage id="app.AdminPanelBillingPaymentMethod.action.managePaymentMethod" />
                                                </Button>
                                            </AdminPanelActionButton>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </AdminPanelContainerTextTable>
                    </>
                )}
            </div>
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelBillingPaymentMethod));
