import React, { Component } from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";
import AdminPanelCompanyData from "../../AdminPanelCompanyData/AdminPanelCompanyData";
import Redirect404 from "../../../../components/error/Redirect404/Redirect404";
import { AdminPanelManageUsers } from "../../AdminPanelManageUsers/AdminPanelManageUsers";
import { AdminPanelBilling } from "../../AdminPanelBilling/AdminPanelBilling";
import AdminPanelSubscription from "../../AdminPanelSubscription/AdminPanelSubscription";

interface PropsInterface extends RouteComponentProps {}

class AdminPageContentLoader extends Component<PropsInterface> {
    render() {
        return (
            <div className="admin-page-content-loader">
                <Switch>
                    <Route path={RoutesEnum.ADMIN_PANEL_MANAGE_USERS}>
                        <AdminPanelManageUsers />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_COMPANY_DATA}>
                        <AdminPanelCompanyData />
                    </Route>
                    <Route path={RoutesEnum.ADMIN_PANEL_BILLING}>
                        <AdminPanelBilling />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_SUBSCRIPTION}>
                        <AdminPanelSubscription />
                    </Route>
                    <Route component={Redirect404} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(AdminPageContentLoader);
