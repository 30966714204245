export enum FormatType {
    'website' = 'website'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatFormValues(data: any, fields: { [key: string]: FormatType }) {
    const values = {...data};
    Object.keys(fields).forEach(fieldName => {
        if (data[fieldName]) {
            values[fieldName] = formatValue(data[fieldName], fields[fieldName]);
        }
    });
    return values;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatValue(val: any, type: FormatType): any {
    switch (type) {
        case FormatType.website:
            return formatValueWebsite(val);
    }
    return val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatValueWebsite(val: any): any {
    if (`${val}`.match(/^http(s)?:\/\//i)) {
        return val;
    }
    return `http://${val}`
}
