import React, { Component } from "react";
import { AdminPanelContent } from "../components/AdminPanelContent/AdminPanelContent";
import AdminPanelCompanyDataForm from "./AdminPanelCompanyDataForm";
import { LinearProgress } from "@material-ui/core";
import container from "../../../container";
import { ICountry } from "../../../services/WedApi/Models/Country";
import { IOrganizationCompanyData } from "../../../services/WedApi/Models/User";

interface PropsInterface {}

interface StateInterface {
    loading: boolean;
    countries: ICountry[];
    companyData?: IOrganizationCompanyData;
}

export default class AdminPanelCompanyData extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            countries: [],
            companyData: undefined,
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        const countries: ICountry[] = await container.countryService.getCountries();
        const companyData: IOrganizationCompanyData = await container.adminService.getCompanyData(countries);
        this.setState({
            loading: false,
            companyData: companyData,
            countries: countries,
        });
    }

    render() {
        return (
            <AdminPanelContent className="admin-panel-company-data">
                {this.state.loading ? (
                    <LinearProgress />
                ) : (
                    <AdminPanelCompanyDataForm
                        countries={this.state.countries}
                        companyData={this.state.companyData as IOrganizationCompanyData}
                        companyId={this.state.companyData?.id as number}
                        onFinished={async () => {
                            this.setState({ loading: true });
                            await this.fetchData();
                        }}
                    />
                )}
            </AdminPanelContent>
        );
    }
}
