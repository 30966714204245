import React from "react";
import { WedIcon } from "../../wed-icon/WedIcon";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WedIconLeftArrow } from "../../wed-icon/generated/WedIconSvg";
import { FormattedMessage } from "react-intl";
import "./WedMediaGalleryPreviewMenu.scss";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import DataFilesInterface from "../../template/FilesPage/Service/DataFilesByType/DataFilesInterface";
import DataFilesFactory from "../../template/FilesPage/Service/DataFilesByType/DataFilesFactory";

interface PropsInterface extends RouteComponentProps {
    projectId?: number;
    collectionId?: number;
    userTeamId?: number;
    dirId?: number;
    token?: string;
    children?: React.ReactNode | React.ReactNodeArray;
    fileType: FileTypeEnum;
}

interface StateInterface {}

class WedMediaGalleryPreviewMenu extends React.Component<PropsInterface, StateInterface> {
    private filesDataService: DataFilesInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.filesDataService = DataFilesFactory.getFileType(this.props.fileType);

        this.handleClickBack = this.handleClickBack.bind(this);
    }

    handleClickBack() {
        this.props.history.push(
            this.filesDataService.openSection({
                collectionId: this.props.collectionId,
                projectId: this.props.projectId,
                dirId: this.props.dirId,
                userTeamId: this.props.userTeamId,
                token: this.props.token,
            })
        );
    }

    render() {
        return (
            <div className="wed-media-gallery-preview-menu">
                <div className="wed-media-gallery-preview-menu__background" />
                <div className="wed-media-gallery-preview-menu__content">
                    <div className="wed-media-gallery-preview-menu__left">
                        <a className="wed-icon-button wed-media-gallery-preview-menu__back-to-files" onClick={this.handleClickBack}>
                            <WedIcon icon={<WedIconLeftArrow />} />
                            <span className="wed-media-gallery-preview-menu__back-to-files-label">
                                <FormattedMessage
                                    id={
                                        this.props.collectionId
                                            ? "app.ProjectFilesMediaGalleryButtons.backToCollection"
                                            : "app.ProjectFilesMediaGalleryButtons.backToFiles"
                                    }
                                />
                            </span>
                        </a>
                    </div>
                    <div className="wed-media-gallery-preview-menu__right">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(WedMediaGalleryPreviewMenu);
