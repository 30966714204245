import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import ChangeStatusProject from "../ChangeStatusProject/ChangeStatusProject";
import { ProjectStatusChangeEnum } from "../../../../services/WedApi/Models/Project";

interface PropsInterface {
    open: boolean;
    handleClose: () => void;
    callback?: () => void;
    status: ProjectStatusChangeEnum | null;
}

interface StateInterface {}

export class ChangeStatusProjectPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { callback, open, handleClose, status } = this.props;

        return (
            <Popup open={open} handleClose={handleClose} maxWidth="sm">
                <PopupContent>
                    <ChangeStatusProject close={handleClose} callback={callback} status={status} />
                </PopupContent>
            </Popup>
        );
    }
}
