import React from "react";
import { Header } from "../../header/Header";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ApplicationState } from "../../../store";
import { Dispatch } from "redux";
import { setLoggedUser } from "../../../store/UserReducer";
import { IUserMyProfile } from "../../../services/WedApi/Models/User";
import container from "../../../container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setNotifsData, SetNotifsProps } from "../../../store/NotifsReducer";
import "./LayoutPage.scss";

interface BasePropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

interface PropsInterface extends BasePropsInterface, RouteComponentProps, SetNotifsProps {
    setLoggedUser: (user: IUserMyProfile) => void;
    isLoggedUser: boolean;
}

export class LayoutPage extends React.Component<PropsInterface> {
    async componentDidMount() {
        const user = await container.userService.me();
        this.props.setLoggedUser(user);
        await this.updateNotifsData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (prevProps.location.pathname && prevProps.location.pathname !== this.props.location.pathname) {
            await this.updateNotifsData();
        }
    }

    private async updateNotifsData() {
        this.props.setNotifsData(await container.notifsService.getList());
    }

    render() {
        if (!this.props.isLoggedUser) {
            return <LinearProgress />;
        }
        return (
            <div className="wed-layout-page">
                <Header />
                <div className="wed-layout-page__content">{this.props.children}</div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        isLoggedUser: !!state.user.data?.id,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setLoggedUser: setLoggedUser(dispatch),
    setNotifsData: setNotifsData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutPage));
