const DEFAULT_DELAY_MS = 5000;

export class ActionTimer {
    // eslint-disable-next-line
    private timer: any;

    constructor(private readonly action: () => void, private readonly delayMS?: number) {}

    register() {
        // eslint-disable-next-line
        const func: any = this.action;
        this.timer = setTimeout(() => {
            func();
        }, this.delayMS || DEFAULT_DELAY_MS);
    }

    cancel() {
        try {
            clearTimeout(this.timer);
        } catch (e) {
            // eslint-disable-next-line
        }
        this.timer = undefined;
    }
}
