import React, { Component } from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedIconDocumentsFileQuestion } from "../../wed-icon/generated/WedIconSvg";
import { WedIcon } from "../../wed-icon/WedIcon";
import "./SidebarNoSelected.scss";

interface PropsInterface extends WrappedComponentProps {}

class SidebarNoSelected extends Component<PropsInterface> {
    render() {
        return (
            <div className="sidebar-no-selected">
                <div className="sidebar-no-selected__icon">
                    <WedIcon icon={<WedIconDocumentsFileQuestion />} />
                </div>
                <div className="sidebar-no-selected__title">
                    <FormattedMessage id={"app.sidebar.noSelected.title"} />
                </div>
                <div className="sidebar-no-selected__description">
                    <FormattedMessage id={"app.sidebar.noSelected.description"} />
                </div>
            </div>
        );
    }
}

export default injectIntl(SidebarNoSelected);
