import { Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import { AdminPanelContent } from "../components/AdminPanelContent/AdminPanelContent";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import AdminPanelInviteNewUsers from "./AdminPanelInviteNewUsers/AdminPanelInviteNewUsers";
import AdminPanelPendingInvitations from "./AdminPanelPendingInvitations/AdminPanelPendingInvitations";
import Redirect404 from "../../../components/error/Redirect404/Redirect404";
import { Redirect } from "react-router-dom";
import AdminPanelManageCurrentUsers from "./AdminPanelManageCurrentUsers/AdminPanelManageCurrentUsers";

interface PropsInterface {
    items?: { label: string; path: string; component: React.ReactElement }[];
    content?: React.ReactElement;
}

const MENU_ITEMS = [
    {
        label: "app.AdminPanelNavigation.currentUsers",
        path: RoutesEnum.ADMIN_PANEL_MANAGE_USERS_CURRENT_USERS,
    },
    {
        label: "app.AdminPanelNavigation.inviteNewUsers",
        path: RoutesEnum.ADMIN_PANEL_MANAGE_USERS_INVITE_NEW_USERS,
    },
    {
        label: "app.AdminPanelNavigation.pendingInvitations",
        path: RoutesEnum.ADMIN_PANEL_MANAGE_USERS_PENDING_INVITATIONS,
    },
];

export class AdminPanelManageUsers extends Component<PropsInterface> {
    render() {
        return (
            <AdminPanelContent menuItems={MENU_ITEMS}>
                <Switch>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_MANAGE_USERS_CURRENT_USERS}>
                        <AdminPanelManageCurrentUsers />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_MANAGE_USERS_INVITE_NEW_USERS}>
                        <AdminPanelInviteNewUsers />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_MANAGE_USERS_PENDING_INVITATIONS}>
                        <AdminPanelPendingInvitations />
                    </Route>
                    <Route exact path={RoutesEnum.ADMIN_PANEL_MANAGE_USERS}>
                        <Redirect to={RoutesEnum.ADMIN_PANEL_MANAGE_USERS_CURRENT_USERS} />
                    </Route>
                    <Route component={Redirect404} />
                </Switch>
            </AdminPanelContent>
        );
    }
}
