import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { LocaleStateInterface } from "./services/Locale/store/Types";
import localeReducer from "./services/Locale/store/Reducers";
import { PageInfoBoxProps, PageInfoBoxReducer } from "./store/PageInfoBoxReducer";
import { LoggedUserProps, UserReducer } from "./store/UserReducer";
import { WedImageGalleryDataProps, WedImageGalleryDataReducer } from "./store/WedImageGalleryReducer";
import { SelectedItemsProps, SelectedItemsReducer } from "./store/SelectedItemsReducer";
import { NotifsProps, NotifsReducer } from "./store/NotifsReducer";

export interface ApplicationState {
    router: RouterState;
    locale: LocaleStateInterface;
    pageInfoBox: PageInfoBoxProps;
    user: LoggedUserProps;
    wedImageGalleryData: WedImageGalleryDataProps;
    selectedItems: SelectedItemsProps;
    notifs: NotifsProps;
}

export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        locale: localeReducer,
        pageInfoBox: PageInfoBoxReducer,
        user: UserReducer,
        wedImageGalleryData: WedImageGalleryDataReducer,
        selectedItems: SelectedItemsReducer,
        notifs: NotifsReducer,
    });
