enum FrontApiErrorCodeEnum {
    ERR_API_GENERIC = "ErrApiGeneric",
    ERR_AUTHENTICATION_FAILED = "ErrAuthenticationFailed",
    ERR_INACTIVE_ACCOUNT = "ErrInactiveAccount",
    ERR_COLLECTION_ID_INVALID = "ErrCollectionIDInvalid",
    ERR_COLLECTION_NAME_INVALID = "ErrCollectionNameInvalid",
    ERR_COLLECTION_DESCRIPTION_INVALID = "ErrCollectionDescriptionInvalid",
    ERR_PROJECT_ID_INVALID = "ErrProjectIDInvalid",
    ERR_PROJECT_NAME_INVALID = "ErrProjectNameInvalid",
    ERR_PROJECT_DESCRIPTION_INVALID = "ErrProjectDescriptionInvalid",
    ERR_FOLDER_ID_INVALID = "ErrFolderIDInvalid",
    ERR_FOLDER_NAME_INVALID = "ErrFolderNameInvalid",
    ERR_FOLDER_DESCRIPTION_INVALID = "ErrFolderDescriptionInvalid",
    ERR_OBJECT_INVALID = "ErrObjectInvalid",
    ERR_OBJECT_NAME_INVALID = "ErrObjectNameInvalid",
    ERR_OBJECT_DESCRIPTION_INVALID = "ErrObjectDescriptionInvalid",
    ERR_OBJECT_TAGS_INVALID = "ErrObjectTagsInvalid",
    ERR_CLIENT_ACCOUNT_NAME_INVALID = "ErrECCNameInvalid",
    ERR_CLIENT_ACCOUNT_POSITION_INVALID = "ErrECCPositionInvalid",
    ERR_CLIENT_ACCOUNT_PHONE_INVALID = "ErrECCPhoneInvalid",
    ERR_CLIENT_ACCOUNT_COUNTRY_CODE_INVALID = "ErrECCCCodeInvalid",
    ERR_CLIENT_ACCOUNT_EMAIL_INVALID = "ErrECCEmailInvalid",
    ERR_CLIENT_ACCOUNT_EMAIL_USED = "ErrECCEmailUsed",
    ERR_USER_SIGNUP_FORM_INVALID = "ErrUserSignupFormInvalid",
    ERR_USER_NAME_INVALID = "ErrUserNameInvalid",
    ERR_USER_EMAIL_INVALID = "ErrUserEmalInvalid",
    ERR_PASSWORD_INVALID = "ErrPasswordInvalid",
    ERR_INVITATION_TOKEN_INVALID = "ErrInvitationTokenInvalid",
    ERR_USER_EMAIL_USED = "ErrUserEmailUsed",
    ERR_BA_NAME_INVALID = "ErrBANameInvalid",
    ERR_BA_TAX_ID_INVALID = "ErrBATaxIDInvalid",
    ERR_STREET_ADDRESS_INVALID = "ErrStreetAddressInvalid",
    ERR_ZIP_CODE_INVALID = "ErrZipCodeInvalid",
    ERR_STATE_INVALID = "ErrStateInvalid",
    ERR_CITY_INVALID = "ErrCityInvalid",
    ERR_USER_PASSWORD_RESET_FORM_INVALID = "ErrUserPasswordResetFormInvalid",
    ERR_USER_PASSWORD_RESET_TOKEN_INVALID = "ErrUserPasswordResetTokenInvalid",
    ERR_USER_UPDATE_FORM_INVALID = "ErrUserUpdateFormInvalid",
    ERR_USER_PHONE_INVALID = "ErrUserPhoneInvalid",
    ERR_USER_CCODE_INVALID = "ErrUserCCodeInvalid",
    ERR_NOTE_CONTENT_INVALID = "ErrNoteContentInvalid",
    ERR_FODLER_ACCESS = "ErrFolderAccess",
    ERR_INVITATION_EMAL_INVALID = "ErrInvitationEmalInvalid",
    ERR_BA_SUBSCRIPTION_PLAN_INVALID = "ErrBASubscriptionPlanInvalid",
    ERR_NOTIFICATION_INVALID = "ErrNotificationInvalid",
    ERR_CAPTCHA_VERIFIED = "ErrReCAPTCHAInvalid",
    ERR_API_SHARED_INTERNAL = "ErrApiSharedInternal",
    ERR_TOKEN_INVALID = "ErrTokenInvalid",
}

export default FrontApiErrorCodeEnum;
