import React, { Component } from "react";
import { UserTeamDetailLoaderProps } from "../../../components/user-team/UserTeamDetailLoader/UserTeamDetailLoader";
import ClientsPage from "../../../components/template/ClientsPage/ClientsPage";

interface BasePropsInterface extends UserTeamDetailLoaderProps {}

interface PropsInterface extends BasePropsInterface {}

interface StateInterface {}

class UserTeamUserClientsPage extends Component<PropsInterface, StateInterface> {
    render() {
        return <ClientsPage contextUserTeam={this.props.userTeam} />;
    }
}

export default UserTeamUserClientsPage;
