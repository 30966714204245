import React from "react";

interface PropsInterface {
    value: number;
    className: string;
}

class StatsCollection extends React.Component<PropsInterface> {
    render() {
        return <span className={this.props.value === 0 ? "project-stat__count--warning" : this.props.className}>{this.props.value}</span>;
    }
}

export default StatsCollection;
