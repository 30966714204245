import { IFile, IFileVersionProof } from "../Models/File";
import { LogicError } from "../../../errors/error-app";
import { IShareFile } from "../Models/ShareCollection";
import { ICommentNoteItem } from "../Models/Note";

export enum CollectionDotStatusColorItemType {
    COLLECTION = "COLLECTION",
    COLLECTION_FILE = "COLLECTION_FILE",
}

export enum ItemDotStatusColor {
    REQUESTED = "REQUESTED",
    IN_PROGRESS = "IN_PROGRESS",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
}

interface ApprovalOptions {
    approvalRequired: boolean;
    approvalFlag: number;
    versionId?: number;
    file?: IFile | IShareFile;
}

export function getCollectionFileDotStatusColorByApprovalFlag(flag: number): ItemDotStatusColor | undefined {
    return getColorByNumber(flag);
}

export function showCollectionDotStatusColor(type: CollectionDotStatusColorItemType, item: ApprovalOptions): boolean {
    return !!getCollectionDotStatusColor(type, item);
}

export function getCollectionDotStatusColor(type: CollectionDotStatusColorItemType, item: ApprovalOptions): ItemDotStatusColor | undefined {
    if (!item.approvalRequired) {
        return undefined;
    }
    switch (type) {
        case CollectionDotStatusColorItemType.COLLECTION:
            return getCollectionDotStatusColorForCollection(item);
        case CollectionDotStatusColorItemType.COLLECTION_FILE:
            return getCollectionDotStatusColorForFile(item);
    }
    return undefined;
}

export function getCommentsObjectProofColor(note: ICommentNoteItem): ItemDotStatusColor | undefined {
    return note.flag === undefined ? undefined : getColorByNumber(note.flag);
}

export function getFileVersionProofs(item: ApprovalOptions): IFileVersionProof[] | undefined {
    if (item.versionId && item.approvalRequired && !!getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, item)) {
        const fileVersion = item.file?.versions.find((version) => version.id === item.versionId);
        return fileVersion?.proofs || [];
    }
    return undefined;
}

function getCollectionDotStatusColorForCollection(item: ApprovalOptions): ItemDotStatusColor | undefined {
    return getColorByNumber(item.approvalFlag);
}

function getCollectionDotStatusColorForFile(item: ApprovalOptions): ItemDotStatusColor | undefined {
    if (item.versionId) {
        return getCollectionDotStatusColorForFileVersion(item.versionId, item.file);
    }
    return getColorByNumber(item.approvalFlag);
}

function getCollectionDotStatusColorForFileVersion(versionId: number, file?: IFile | IShareFile): ItemDotStatusColor | undefined {
    if (versionId && !file) {
        throw new LogicError("Not allowed render dot status color for collection file version without file object");
    }

    const fileVersion = file?.versions.find((version) => version.id === versionId);
    if (!fileVersion || fileVersion.approvalFlag === undefined) {
        return undefined;
    }

    return getColorByNumber(fileVersion.approvalFlag);
}

function getColorByNumber(approvalFlag: number): ItemDotStatusColor | undefined {
    if (approvalFlag === 0) {
        return ItemDotStatusColor.REQUESTED;
    }
    if (approvalFlag === 1) {
        return ItemDotStatusColor.IN_PROGRESS;
    }
    if (approvalFlag === 2) {
        return ItemDotStatusColor.APPROVED;
    }
    if (approvalFlag === 3) {
        return ItemDotStatusColor.REJECTED;
    }
    return undefined;
}
