import * as React from "react";
import { generatePath } from "react-router-dom";
import classNames from "classnames";
import { WedMainMenu } from "../WedMainMenu/WedMainMenu";
import { WedMainMenuItem } from "../WedMainMenuItem/WedMainMenuItem";
import RoutesEnum from "../../../services/Routes/RoutesEnum";

interface PropsInterface {
    activeTab: "files" | "sharedWithMe" | "collections";
}

interface StateInterface {}

export class MyLibraryMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMainMenu>
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "files" })}
                    path={generatePath(RoutesEnum.MY_LIBRARY_FILES)}
                    label="app.myLibraryMenu.files"
                />
                <WedMainMenuItem
                    className={classNames("project-detail-menu__comments-proofing", {
                        "main-menu__active": this.props.activeTab === "sharedWithMe",
                    })}
                    path={generatePath(RoutesEnum.MY_LIBRARY_SHARED_WITH_ME)}
                    label="app.myLibraryMenu.sharedWithMe"
                />
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "collections" })}
                    path={generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS)}
                    label="app.myLibraryMenu.collections"
                />
            </WedMainMenu>
        );
    }
}
