import FrontApiErrorInterface from "./FrontApiErrorInterface";
import { StatusCodes } from "http-status-codes";
import FrontApiErrorCodeEnum from "./FrontApiErrorCodeEnum";
import _get from "lodash/get";

export default class FrontApiError implements FrontApiErrorInterface {
    public status: StatusCodes = StatusCodes.INTERNAL_SERVER_ERROR;
    public code: FrontApiErrorCodeEnum | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public response: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public additionalData: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(status: number, response: any) {
        const { code, additionalData } = this.parseError(response);

        this.code = code;
        this.response = response;
        this.status = status || this.status;
        this.additionalData = additionalData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseError(response: any) {
        const code = _get(response, "errormessage", undefined);
        const additionalData = _get(response, "error.additionalData", {});

        return {
            code,
            additionalData,
        };
    }

    toJson() {
        const obj: Omit<FrontApiErrorInterface, "response"> = {
            status: this.status,
            code: this.code,
        };

        return obj;
    }
}
