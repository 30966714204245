import React, {WheelEvent} from "react";
import {IFile} from "../../../services/WedApi/Models/File";
import classNames from "classnames";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Scrollbars} from "react-custom-scrollbars";
import container from "../../../container";
import {SizeImageEnum} from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import {getScrollbarIconXStyle, getScrollbarXStyle} from "../../../services/WedApi/Utils/ScrollbarsStyles";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {getCurrentMediaObjectId} from "../../../services/Routes/GetCurrentMediaObjectId";
import {WedIcon} from "../../wed-icon/WedIcon";
import {WedIconArrow} from "../../wed-icon/generated/WedIconSvg";
import WedMediaGalleryImage from "../WedMediaGalleryImage/WedMediaGalleryImage";
import {FileTypeEnum} from "../../template/Type/FileTypeEnum";
import "./WedMediaGalleryStrip.scss";

interface PropsInterface extends RouteComponentProps {
    files?: IFile[];
    onChangeFile: (fileId: number) => void;
    currentFileId?: number;
    collectionId?: number;
    token?: string;
    userTeamId?: number;
    fileType: FileTypeEnum;
}

interface StateInterface {
    isLoading: boolean;
}

class WedMediaGalleryStrip extends React.Component<PropsInterface, StateInterface> {
    private imageLoaded: number | undefined = undefined;
    private readonly scrollbarRef: React.RefObject<Scrollbars>;
    // eslint-disable-next-line
    private readonly activeElementRef: React.RefObject<any>;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
        };
        this.scrollbarRef = React.createRef();
        this.activeElementRef = React.createRef();
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.onWheel = this.onWheel.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        const prevUrl = prevProps.location.pathname + prevProps.location.search;
        const currentUrl = this.props.location.pathname + this.props.location.search;
        if (prevUrl !== currentUrl) {
            this.scrollToCurrentItem();
        }
    }

    private changeFile(fileId: number) {
        this.props.onChangeFile && this.props.onChangeFile(fileId);
    }

    private onWheel(event: WheelEvent<Scrollbars>) {
        if (!event.deltaY) {
            return;
        }
        const scrollbars: Scrollbars | null = this.scrollbarRef.current;
        if (scrollbars) {
            scrollbars.scrollLeft(scrollbars.getScrollLeft() + event.deltaY);
        }
    }

    private scrollToCurrentItem() {
        const currentElement = document.getElementById(`wed-media-gallery-file-${this.getCurrentFileId()}`);
        if (currentElement) {
            currentElement.scrollIntoView();
        }
    }

    private onImageLoaded() {
        if (this.imageLoaded !== undefined) {
            this.imageLoaded--;
            if (this.imageLoaded === 0) {
                this.imageLoaded = undefined;
                this.setState({ isLoading: false });
                setTimeout(() => {
                    this.scrollToCurrentItem();
                }, 10);
            }
        }
    }

    private increaseImageLoaded() {
        this.imageLoaded = this.imageLoaded === undefined ? 1 : this.imageLoaded + 1;
    }

    private getCurrentFileId(): number {
        if (this.props.currentFileId) {
            return this.props.currentFileId;
        }
        return getCurrentMediaObjectId(this.props.history.location.pathname);
    }

    getFileUrl = (id: number, versionId: number, size: SizeImageEnum) => {
        const { token, collectionId } = this.props;

        if (token && collectionId) {
            return container.filesService.getSharedFileUrl(token, id, versionId, collectionId, size);
        }

        return container.filesService.getFileUrl(
            id,
            versionId,
            {
                size,
                fileType: this.props.fileType,
                collectionId,
                ...(this.props.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO && this.props.userTeamId
                    ? { userId: this.props.userTeamId }
                    : {}
                )
            }
        );
    };

    render() {
        const { files } = this.props;

        let prevFileId: number | undefined = undefined;
        let nextFileId: number | undefined = undefined;
        if (files) {
            const currentFileId = this.getCurrentFileId();
            const currentFileIdx: number | undefined = files?.findIndex((file) => file.id === currentFileId);
            if (currentFileIdx !== undefined && currentFileIdx !== -1) {
                if (currentFileIdx > 0) {
                    prevFileId = files[currentFileIdx - 1].id;
                }
                if (currentFileIdx < files.length - 1) {
                    nextFileId = files[currentFileIdx + 1].id;
                }
            }
        }

        return (
            <div className={classNames("wed-media-gallery-strip", { "wed-media-gallery-strip__loading": this.state.isLoading })}>
                {(this.state.isLoading || files === undefined) && <LinearProgress />}
                {files !== undefined && (
                    <div className="wed-media-gallery-strip__container">
                        <button
                            disabled={!prevFileId}
                            className="wed-icon-button wed-icon-button-prev"
                            onClick={() => {
                                if (prevFileId) {
                                    this.changeFile(prevFileId);
                                }
                            }}
                        >
                            <WedIcon icon={<WedIconArrow />} />
                        </button>
                        <div className="wed-media-gallery-strip__content">
                            <Scrollbars
                                universal
                                ref={this.scrollbarRef}
                                onWheel={this.onWheel}
                                renderTrackHorizontal={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarXStyle({ bottom: 0 }),
                                    });
                                }}
                                renderThumbHorizontal={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarIconXStyle(),
                                    });
                                }}
                            >
                                <ul>
                                    {files.map((file, i) => {
                                        const currentFileId = this.getCurrentFileId();
                                        this.increaseImageLoaded();
                                        return (
                                            <li id={`wed-media-gallery-file-${file.id}`} key={i}>
                                                <WedMediaGalleryImage
                                                    fileType={this.props.fileType}
                                                    className={file.id === currentFileId ? "wed-media-gallery-image-active" : undefined}
                                                    ref={file.id === currentFileId ? this.activeElementRef : undefined}
                                                    extensionFile={file.extension}
                                                    link={this.getFileUrl(file.id, file.newestVersionId, SizeImageEnum.THUMB)}
                                                    onClick={() => {
                                                        this.changeFile(file.id);
                                                    }}
                                                    onComplete={this.onImageLoaded}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Scrollbars>
                        </div>
                        <button
                            disabled={!nextFileId}
                            className="wed-icon-button wed-icon-button-next"
                            onClick={() => {
                                if (nextFileId) {
                                    this.changeFile(nextFileId);
                                }
                            }}
                        >
                            <WedIcon icon={<WedIconArrow />} />
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(WedMediaGalleryStrip);
