import React from "react";
import "./SidebarTitle.scss";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import classNames from "classnames";

interface PropsInterface {
    icon?: React.ReactElement;
    title: string;
    suffix?: string;
}

class SidebarTitle extends React.Component<PropsInterface> {
    render() {
        const { title, icon, suffix } = this.props;

        return (
            <h3 className={classNames("sidebar-title", { "sidebar-title--with-icon": !!icon })}>
                <div className="sidebar-title--wrapper">
                    <div className="sidebar-title--container">
                        {icon && <WedIcon icon={icon} size={SizeType.LARGE} />}
                        <div className="sidebar-title--content">{title}</div>
                    </div>
                    {suffix && <small>{suffix}</small>}
                </div>
            </h3>
        );
    }
}

export default SidebarTitle;
