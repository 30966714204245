import { IProject } from "../Models/Project";
import CollectionActionApiService from "../CollectionActionApiService";
import { CollectionStatusEnum, IApiCollectionDetails, IApiCollectionListItem, ICollection, ICollectionExtended } from "../Models/Collection";
import { convertStrToDate } from "../Utils/DateConverter";
import { IOwner } from "../Models/User";
import { uniqueValues } from "../Utils/ItemCollection";
import UserActionApiService from "../UserActionApiService";
import { ConvertApiFileContextEnum, convertIApiObjectToIFile } from "./BaseConverter";
import ClientActionApiService from "../ClientActionApiService";
import { ClientService } from "./ClientService";
import { AppNotFoundItemError } from "../../../errors/error-app";

export interface IProjectList {
    projects: IProject[];
}

export class CollectionService {
    constructor(
        private readonly collectionActionApiService: CollectionActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly clientActionApiService: ClientActionApiService
    ) {}

    public getDownloadUrl(collectionId: number): string {
        return this.collectionActionApiService.getDownloadUrl(collectionId);
    }

    public async getCollections(projectId?: number): Promise<ICollection[]> {
        const data = projectId
            ? await this.collectionActionApiService.getCollections(projectId)
            : await this.collectionActionApiService.getMyLibraryCollections();

        const userIds: number[] = [];
        data.items.forEach((item) => {
            userIds.push(item.collection.ownerID);
            item.objects.forEach((object) => {
                userIds.push(...object.ownerIDs);
            });
        });

        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniqueValues(userIds))).items : [];

        return data.items.map((item) => {
            return this.convertCollectionApi(item, users) as ICollection;
        });
    }

    public async getCollectionDetail(
        collectionId: number,
        options?: { withFilesDetails?: number[]; withProofContactsEcOrgId?: number }
    ): Promise<ICollection> {
        const [item, contacts] = await Promise.all([
            this.collectionActionApiService.getCollectionDetail(collectionId),
            options?.withProofContactsEcOrgId
                ? this.clientActionApiService.getUsersClientByIdClient(options.withProofContactsEcOrgId)
                : { items: [] },
        ]);

        const userIds: number[] = [];
        if (item.collection.ownerID) {
            userIds.push(item.collection.ownerID);
        }
        item.objects.forEach((object) => {
            userIds.push(...object.ownerIDs);
            if (options?.withFilesDetails?.includes(object.id)) {
                if (object.createdByID) {
                    userIds.push(object.createdByID);
                }
                if (object.updatedByID) {
                    userIds.push(object.updatedByID);
                }
            }
        });

        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniqueValues(userIds))).items : [];

        return this.convertCollectionApi(
            item,
            users,
            contacts.items.map((contact) => ClientService.convertClientContact(contact))
        ) as ICollectionExtended;
    }

    public async getCollectionDetailForSidebar(collectionId: number, ecOrgId: number | undefined): Promise<ICollection> {
        const [item, contacts] = await Promise.all([
            this.collectionActionApiService.getCollectionDetail(collectionId),
            ecOrgId ? this.clientActionApiService.getUsersClientByIdClient(ecOrgId) : { items: [] },
        ]);

        const userIds: number[] = [];
        if (item.collection.ownerID) {
            userIds.push(item.collection.ownerID);
        }
        if (item.collection.updatedBy) {
            userIds.push(item.collection.updatedBy);
        }
        if (item.collection.baUsers?.length > 0) {
            userIds.push(...item.collection.baUsers);
        }

        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniqueValues(userIds))).items : [];

        return this.convertCollectionApi(
            item,
            users,
            contacts.items.map((contact) => ClientService.convertClientContact(contact))
        ) as ICollectionExtended;
    }

    public async addFilesToCollection(collectionId: number, filesIds: number[]) {
        return await this.collectionActionApiService.updateCollectionFiles(collectionId, filesIds);
    }

    public async removeFilesFromCollection(collectionId: number, filesIds: number[]) {
        return await this.collectionActionApiService.updateCollectionFiles(collectionId, [], filesIds);
    }

    public async setFileApprovalRequest(collectionId: number, fileId: number, contactIds: number[]) {
        const collection = await this.getNativeCollectionDetails(collectionId);
        const currentSelectedIds = await this.getFileApprovalRequest(collection, fileId);
        const newContactIds = contactIds.filter((contactId) => collection.collection.sharedTo.includes(contactId));
        const toAddIds = contactIds.filter((contactId) => !currentSelectedIds.includes(contactId));
        const toRemoveIds = currentSelectedIds.filter((contactId) => !newContactIds.includes(contactId));
        if (toRemoveIds.length > 0) {
            await this.collectionActionApiService.removeApprovalRequest(collectionId, fileId, toRemoveIds);
        }
        if (toAddIds.length > 0) {
            await this.collectionActionApiService.createApprovalRequest(collectionId, fileId, toAddIds);
        }
    }

    public async getFileApprovalRequest(collection: IApiCollectionDetails, fileId: number): Promise<number[]> {
        const file = collection.objects.find((object) => object.id === fileId);
        if (!file) {
            throw new AppNotFoundItemError("file", fileId, `Not found file ${fileId} in collection ${collection.collection.id}`);
        }
        const version = file.versions.find((version) => version.id === file.newestVersionID);
        if (!version) {
            throw new AppNotFoundItemError(
                "file",
                fileId,
                `Not found newest version id ${file.newestVersionID} in file ${fileId} collection ${collection.collection.id}`
            );
        }
        return version.proofs?.map((proof) => proof.ecContactID).filter((contactId) => !!contactId) || [];
    }

    public async getNativeCollectionDetails(collectionId: number): Promise<IApiCollectionDetails> {
        return this.collectionActionApiService.getCollectionDetail(collectionId);
    }

    private convertCollectionApi(item: IApiCollectionListItem, users: IOwner[], contacts: IOwner[] = []): ICollection {
        return {
            id: item.collection.id,
            name: item.collection.name,
            description: item.collection.description,
            status: item.collection.locked ? CollectionStatusEnum.locked : CollectionStatusEnum.unlocked,
            createdBy: users.find((user) => user.id === item.collection.ownerID),
            created: convertStrToDate(item.collection.createdAt),
            updatedBy: users.find((user) => user.id === item.collection.updatedBy),
            updated: item.collection.updatedAt ? convertStrToDate(item.collection.updatedAt) : undefined,
            userIsOwner: item.collection.userIsOwner,
            locked: item.collection.locked,
            approvalRequired: item.collection.approvalRequired,
            approvalFlag: item.collection.approvalFlag,
            baUsers: item.collection.baUsers,
            baUsersItems: item.collection.baUsers.map((userId) => users.find((user) => user.id === userId)).filter((user) => !!user) as IOwner[],
            sharedTo: item.collection.sharedTo,
            sharedToItems: item.collection.sharedTo
                .map((contactId) => contacts.find((contact) => contact.id === contactId))
                .filter((contact) => !!contact) as IOwner[],
            publicNotes: item.collection.publicNotes,
            files: item.objects.map((object) => {
                return convertIApiObjectToIFile(object, users, ConvertApiFileContextEnum.collection, contacts);
            }),
        };
    }
}
