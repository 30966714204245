import _ from "lodash";
import ClientFilterInterface from "./ClientFilterInterface";
import { SortEnum } from "../SortEnum";
import { IClient } from "../../WedApi/Models/Client";

export default class ClientFilterService {
    public filter(clients: IClient[], filters: ClientFilterInterface) {
        let filterData = clients;

        if (filters.isOwner !== undefined) {
            filterData = filterData.filter((client) => {
                return (filters.isOwner && client.userIsOwner) || (!filters.isOwner && !client.userIsOwner);
            });
        }

        if (filters.order === SortEnum.CREATED) {
            filterData = _.orderBy(filterData, ["created"], "desc");
        } else {
            filterData = _.orderBy(filterData, [(client) => client.name.toLowerCase()], "asc");
        }

        return filterData;
    }

    public findById(clients: IClient[], id: number): IClient | undefined {
        return clients.find((client) => client.id === id);
    }
}
