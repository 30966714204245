import React from "react";
import classnames from "classnames";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface PropsInterface extends WithStyles {
    id?: string;
    handleClick: () => void;
    title: string;
    showMenu: boolean;
    listMenu: React.ReactElement;
    childRef?: React.RefObject<HTMLDivElement>;
    moveToLeft?: boolean;
}

interface StateInterface {}

const styles = createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
        fontWeight: 300,
        color: "rgba(250, 250, 250, 0.4)",
        padding: "4px 6px",
        margin: "0",
        transition: "color .2s, background-color .2s",
        borderRadius: "3px",
        lineHeight: "1",
        "&:hover": {
            textDecoration: "none",
            color: "rgba(250, 250, 250, 0.8)",
        },
    },
    withDropdown: {
        color: "rgba(250, 250, 250, 0.8)",
        "&:hover": {
            backgroundColor: "#2f434f",
        },
    },
    svg: {
        marginLeft: "10px",
    },
});

class BreadcrumbsActionButtons extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { classes, title, id, childRef, handleClick, showMenu, moveToLeft, listMenu } = this.props;

        return (
            <div
                id={id}
                onClick={() => {
                    handleClick();
                }}
                className={classnames("breadcrumb-item", classes.root, classes.withDropdown, { "breadcrumb-item--open": showMenu })}
            >
                {title}
                <ExpandMoreIcon className={classes.svg} />
                <div
                    ref={childRef}
                    className={classnames(
                        "status-action-button__menu",
                        { "status-action-button__menu--active": showMenu },
                        { "status-action-button__menu--move-to-left": moveToLeft }
                    )}
                >
                    {listMenu}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BreadcrumbsActionButtons);
