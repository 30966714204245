import React from "react";
import { IFile, IFileVersion } from "../../../services/WedApi/Models/File";
import container from "../../../container";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, VariantType, withSnackbar } from "notistack";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import LinearProgress from "@material-ui/core/LinearProgress";
import WedMediaGalleryImage, { WedMediaGalleryImageData } from "../WedMediaGalleryImage/WedMediaGalleryImage";
import classNames from "classnames";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconArrow } from "../../wed-icon/generated/WedIconSvg";
import { Dispatch } from "redux";
import {
    clearWedImageGalleryData,
    setWedImageGalleryData,
    SetWedImageGalleryDataProps,
    WedImageGalleryDataCollectionProps,
} from "../../../store/WedImageGalleryReducer";
import { connect } from "react-redux";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import { AppNotFoundItemError } from "../../../errors/error-app";
import { RefreshMediaGalleryParams } from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";
import "./WedMediaGalleryPreview.scss";
import { FormatXMLElementFn, PrimitiveType } from "intl-messageformat";

interface BasePropsInterface {
    projectId?: number;
    files?: IFile[];
    objectId?: number;
    versionId?: number;
    collectionId?: number;
    collectionData?: WedImageGalleryDataCollectionProps;
    isEditable?: boolean;
    userTeamId?: number;
    dirId?: number;
    token?: string;
    onChangeFile: (fileId: number) => void;
    fileType: FileTypeEnum;
    onRefreshMediaGallery: (params?: RefreshMediaGalleryParams) => void;
    userTeamType?: boolean;
}

interface PropsInterface extends BasePropsInterface, SetWedImageGalleryDataProps, WrappedComponentProps, ProviderContext {}

interface StateInterface {
    url?: string;
    orgUrl?: string;
    extension?: string;
    isLoading: boolean;
    data?: WedMediaGalleryImageData;
    prevFileId?: number;
    nextFileId?: number;
    openBox: boolean;
}

class WedMediaGalleryPreview extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            url: undefined,
            extension: undefined,
            data: undefined,
            openBox: false,
        };
        this.onLoad = this.onLoad.bind(this);
    }

    private onLoad() {
        this.setState({ isLoading: false });
    }

    async componentDidMount() {
        await this.fetchImage();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.objectId !== prevProps.objectId || this.props.versionId !== prevProps.versionId) {
            await this.fetchImage();
        }
    }

    async componentWillUnmount() {
        this.props.clearWedImageGalleryData();
    }

    getFileUrl = (id: number, versionId: number, size: SizeImageEnum) => {
        const { token, collectionId } = this.props;

        if (token && collectionId) {
            return container.filesService.getSharedFileUrl(token, id, versionId, collectionId, size);
        }

        return container.filesService.getFileUrl(
            id,
            versionId,
            {
                size,
                fileType: this.props.fileType,
                collectionId,
                ...(this.props.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO && this.props.userTeamId
                        ? { userId: this.props.userTeamId }
                        : {}
                )
            }
        );
    };

    enqueueSnackbar = (variant: VariantType, id: string, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => {
        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id }, values), { variant });
    };

    async fetchImage() {
        if (this.props.files === undefined) {
            return;
        }

        this.setState({ isLoading: true, url: undefined, extension: undefined, data: undefined });
        const fileIdx: number = this.props.files.findIndex((file) => file.id === this.props.objectId);
        const file: IFile | undefined = fileIdx === -1 ? undefined : this.props.files[fileIdx];
        if (!file) {
            throw new AppNotFoundItemError("file", this.props.objectId);
        }
        const prevFileId: number | undefined = fileIdx > 0 ? this.props.files[fileIdx - 1].id : undefined;
        const nextFileId: number | undefined = fileIdx + 1 < this.props.files.length ? this.props.files[fileIdx + 1].id : undefined;
        const version: IFileVersion | undefined = this.props.versionId
            ? file.versions.find((version) => version.id === this.props.versionId)
            : file.versions.length > 0
            ? file.versions[0]
            : undefined;
        const numOfVersions: number = file.versions.length;
        if (!version) {
            throw new AppNotFoundItemError("version", this.props.versionId);
        }

        setTimeout(() => {
            this.setState({
                url: this.getFileUrl(file.id, version.id, SizeImageEnum.RESIZED),
                orgUrl: this.getFileUrl(file.id, version.id, SizeImageEnum.ORG),
                extension: version.extension,
                nextFileId,
                prevFileId,
                data: { name: version.name },
            });

            if (this.props.files) {
                const mediaData = [];
                for (let i = 0; i < this.props.files.length; i++) {
                    if (this.props.files[i].versions.length === 0) {
                        continue;
                    }
                    const fileVersion =
                        this.props.files[i].id === file.id
                            ? this.props.files[i].versions.find((version) => version.id === version.id)
                            : this.props.files[i].versions[0];
                    if (!fileVersion || !isFileHtmlImageExtension(fileVersion.extension)) {
                        continue;
                    }
                    mediaData.push({
                        objectId: this.props.files[i].id,
                        url: this.getFileUrl(this.props.files[i].id, fileVersion.id, SizeImageEnum.ORG),
                    });
                }

                this.props.setWedImageGalleryData({
                    dirId: this.props.dirId,
                    collectionId: this.props.collectionId,
                    collectionData: this.props.collectionData,
                    objectId: file.id,
                    objectName: file.name,
                    versionId: version.id,
                    numOfVersions: numOfVersions,
                    extension: version.extension,
                    mediaData: mediaData,
                    url: this.getFileUrl(file.id, version.id, SizeImageEnum.ORG),
                    onChangeFile: (fileId: number) => {
                        this.props.onChangeFile(fileId);
                    },
                });
            }
        }, 10);
    }

    render() {
        return (
            <div className={classNames("wed-media-gallery-preview", { "wed-media-gallery-preview__loading": this.state.isLoading })}>
                {this.state.isLoading && <LinearProgress />}
                {this.state.url && this.state.extension && (
                    <div className="wed-media-gallery-preview-container">
                        <button
                            disabled={!this.state.prevFileId}
                            className="wed-icon-button wed-icon-button-prev"
                            onClick={() => {
                                this.state.prevFileId && this.props.onChangeFile(this.state.prevFileId);
                            }}
                        >
                            <WedIcon icon={<WedIconArrow />} />
                        </button>
                        <WedMediaGalleryImage
                            fileType={this.props.fileType}
                            isEditable={this.props.isEditable}
                            projectId={this.props.projectId}
                            mainMedia={true}
                            data={this.state.data}
                            extensionFile={this.state.extension}
                            link={this.state.url}
                            orgLink={this.state.orgUrl}
                            onComplete={this.onLoad}
                            collectionId={this.props.collectionId}
                            userTeamId={this.props.userTeamId}
                            dirId={this.props.dirId}
                            token={this.props.token}
                            onRefreshMediaGallery={this.props.onRefreshMediaGallery}
                            userTeamType={this.props.userTeamType}
                            enqueueSnackbar={this.enqueueSnackbar}
                        />
                        <button
                            disabled={!this.state.nextFileId}
                            className="wed-icon-button wed-icon-button-next"
                            onClick={() => {
                                this.state.nextFileId && this.props.onChangeFile(this.state.nextFileId);
                            }}
                        >
                            <WedIcon icon={<WedIconArrow />} />
                        </button>
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setWedImageGalleryData: setWedImageGalleryData(dispatch),
        clearWedImageGalleryData: clearWedImageGalleryData(dispatch),
    };
};

export default connect(null, mapDispatchToProps)(injectIntl(withSnackbar(WedMediaGalleryPreview)));
