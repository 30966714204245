import { FileTypeEnum } from "../../../Type/FileTypeEnum";
import container from "../../../../../container";
import DataFilesInterface from "./DataFilesInterface";
import { LogicError } from "../../../../../errors/error-app";

export default class DataFilesFactory {
    static getFileType(type: FileTypeEnum): DataFilesInterface {
        switch (type) {
            case FileTypeEnum.PROJECT_FILES: {
                return container.projectFilesData;
            }
            case FileTypeEnum.PROJECT_COLLECTION_FILES: {
                return container.projectCollectionFilesData;
            }
            case FileTypeEnum.MY_LIBRARY_FILES: {
                return container.myLibraryFilesData;
            }
            case FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME: {
                return container.myLibrarySharedWithMeData;
            }
            case FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES: {
                return container.myLibraryCollectionFilesData;
            }
            case FileTypeEnum.SHARED_COLLECTION_FILES: {
                return container.sharedCollectionFilesData;
            }
            case FileTypeEnum.TEAM_USER_PROJECT_WORK: {
                return container.userTeamProjectWorkFilesData;
            }
            case FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO: {
                return container.userTeamPrivatePortfolioFilesData;
            }
            default: {
                throw new LogicError(`Not implemented type: ${type}`);
            }
        }
    }
}
