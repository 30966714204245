import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button } from "@material-ui/core";
import PopupContent from "../../../../components/popup/PopupContent/PopupContent";
import Popup from "../../../../components/popup/Popup/Popup";
import PopupHeader, { PositionTitle } from "../../../../components/popup/PopupHeader/PopupHeader";
import PopupAction from "../../../../components/popup/PopupAction/PopupAction";
import container from "../../../../container";
import "./ShareMakeDecisionPopup.scss";

interface StateInterface {
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    token: string;
    collectionId: number;
    objectId: number;
    versionId: number;
    handleClose: (updated?: boolean) => void;
}

class ShareMakeDecisionPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    private async setApproveStatus(approve: boolean): Promise<void> {
        this.setState({ loading: true });
        try {
            await container.shareCollectionService.makeDecision(
                this.props.token,
                this.props.collectionId,
                this.props.objectId,
                this.props.versionId,
                approve
            );
        } catch (e) {
            const keyTranslate = "app.error.undefined";
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        this.setState({ loading: false });
        this.props.handleClose(true);
    }

    render() {
        return (
            <Popup
                className="shared-make-decision-popup"
                open={true}
                handleClose={() => {
                    this.props.handleClose(false);
                }}
                maxWidth="xs"
            >
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.ShareMakeDecisionPopup.title`} />
                    </PopupHeader>
                    <PopupAction>
                        <Button
                            disabled={this.state.loading}
                            type="button"
                            onClick={async () => {
                                await this.setApproveStatus(false);
                            }}
                        >
                            <FormattedMessage id="app.ShareMakeDecisionPopup.reject" />
                        </Button>
                        <Button
                            disabled={this.state.loading}
                            type="button"
                            onClick={async () => {
                                await this.setApproveStatus(true);
                            }}
                        >
                            <FormattedMessage id="app.ShareMakeDecisionPopup.approve" />
                        </Button>
                    </PopupAction>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(ShareMakeDecisionPopup));
