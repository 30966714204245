import { Action, Dispatch } from "redux";
import { ISelectItem } from "../components/status-action-button/StatusActionButton";

export interface SetSelectedItemsProps {
    setSelectedItems: (data: SelectedItemsProps) => void;
}

export interface SelectedItemsProps {
    selectedItems: ISelectItem[];
}

const initialState: SelectedItemsProps = {
    selectedItems: [],
};

export function setSelectedItems(dispatch: Dispatch): (data?: SelectedItemsProps) => void {
    return (data?: SelectedItemsProps) => {
        dispatch({
            type: SelectedItemsReducerAction.SET_SELECTED_ITEMS,
            payload: {
                selectedItems: data?.selectedItems || [],
            },
        });
    };
}

export enum SelectedItemsReducerAction {
    SET_SELECTED_ITEMS = "SelectedItemsReducerAction/SET_SELECTED_ITEMS",
}

// eslint-disable-next-line
export const SelectedItemsReducer = (state: SelectedItemsProps = initialState, action: Action & { payload: any }) => {
    switch (action.type) {
        case SelectedItemsReducerAction.SET_SELECTED_ITEMS:
            return { ...state, ...action.payload };
    }
    return state;
};
