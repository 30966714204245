import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import container from "../../../container";
import { IProjectExtended } from "../../../services/WedApi/Models/Project";
import SidebarMenu from "../../../components/sidebar/SidebarMenu/SidebarMenu";
import SidebarTitle from "../../../components/sidebar/SidebarTitle/SidebarTitle";
import SidebarInformationFiles from "../../../components/sidebar/SidebarInformationFiles/SidebarInformationFiles";
import SidebarInformationDetails from "../../../components/sidebar/SidebarInformationDetails/SidebarInformationDetails";
import SidebarDescription from "../../../components/sidebar/SidebarDescription/SidebarDescription";
import { ChangeDescriptionProjectPopup } from "../../../components/project/Project/ChangeDescriptionProjectPopup/ChangeDescriptionProjectPopup";
import SidebarInitials from "../../../components/sidebar/SidebarInitials/SidebarInitials";
import CommentBox from "../../../components/comments/CommentBox/CommentBox";
import { INote, NoteObjectType } from "../../../services/WedApi/Models/Note";
import { WedIconInteractionTeamworkGroup } from "../../../components/wed-icon/generated/WedIconSvg";
import WedDate from "../../../components/formatter/WedDate";
import { IActivities } from "../../../services/WedApi/Models/Activities";
import SidebarActivities from "../../../components/sidebar/SidebarActivities/SidebarActivities";
import { IActivitiesObjectType } from "../../../services/WedApi/Services/ActivitiesService";
import { InitialsUserType } from "../../../components/tile/components/Initials/Initials";
import { IOwner } from "../../../services/WedApi/Models/User";

interface PropsInterface extends WrappedComponentProps {
    disabled?: boolean;
    handleSidebarChanges: () => void;
    projectId: number;
    loadingCallback: (loading: boolean) => void;
}

interface StateInterface {
    project: IProjectExtended | undefined;
    notes: INote[] | undefined;
    showChangeDescriptionPopup: boolean;
    activities: IActivities[];
}

class ProjectSidebar extends Component<PropsInterface, StateInterface> {
    readonly state: StateInterface = {
        project: undefined,
        notes: undefined,
        activities: [],
        showChangeDescriptionPopup: false,
    };

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>, prevState: Readonly<StateInterface>) {
        const withLoading = prevState.showChangeDescriptionPopup == this.state.showChangeDescriptionPopup;

        await this.fetchData(withLoading);
    }

    shouldComponentUpdate(nextProps: Readonly<PropsInterface>, nextState: Readonly<StateInterface>): boolean {
        return (
            nextProps.projectId != this.props.projectId ||
            nextState?.project?.id != this.state?.project?.id ||
            nextState.showChangeDescriptionPopup != this.state.showChangeDescriptionPopup
        );
    }

    fetchData = async (withLoadingCallback = true) => {
        const { projectId, loadingCallback } = this.props;
        const { project, showChangeDescriptionPopup } = this.state;

        if (!(projectId != project?.id || showChangeDescriptionPopup)) {
            return;
        }

        try {
            withLoadingCallback && loadingCallback(true);
            const [project, notes, activities] = await Promise.all([
                container.projectService.getProjectDetail(projectId),
                container.noteService.getProjectNotes(projectId),
                container.activitiesService.getActivities(IActivitiesObjectType.PROJECT, projectId),
            ]);
            this.setState({ project, notes, activities }, () => {
                if (this.props.projectId == project.id && withLoadingCallback) {
                    loadingCallback(false);
                }
            });
        } catch (error) {
            this.setState({ project: undefined });
        }
    };

    handleOpenChangeDescriptionPopup = () => {
        this.setState({ showChangeDescriptionPopup: true });
    };

    onCloseChangeDescriptionPopup = async (update?: boolean) => {
        if (update) {
            await this.fetchData(true);
        }

        this.setState({ showChangeDescriptionPopup: false });
    };

    render() {
        if (!this.state.project || this.state.project.id != this.props.projectId) {
            return null;
        }

        const { intl, disabled } = this.props;
        const { project, notes, showChangeDescriptionPopup } = this.state;

        return (
            <div className="project-sidebar sidebar--container">
                <SidebarTitle title={project.name} />
                <SidebarMenu
                    labels={[intl.formatMessage({ id: "app.sidebar.menu.informations" }), intl.formatMessage({ id: "app.sidebar.menu.activities" })]}
                    contents={[
                        <>
                            <SidebarInformationFiles
                                icon={<WedIconInteractionTeamworkGroup />}
                                filesCount={project.rootFiles}
                                totalSize={project.rootSize}
                            />
                            <SidebarInformationDetails
                                data={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.customerName" }),
                                        values: [{ text: project.ecOrg.name }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.id" }),
                                        values: [{ text: project.id.toString() }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.status" }),
                                        values: [{ text: project.status.toUpperCase() }],
                                    },
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.details.created" }),
                                        values: [
                                            { text: project.created ? <WedDate date={project.created} /> : <></> },
                                            {
                                                text: intl.formatMessage({ id: "app.sidebar.details.by" }),
                                                labelFormat: true,
                                            },
                                            { text: `${project.createdBy?.firstName} ${project.createdBy?.lastName}` },
                                        ],
                                    },
                                ]}
                            />
                            <SidebarDescription
                                allowToAdd={!disabled && project.userIsOwner}
                                description={project.desc}
                                onOpenChangeDescriptionPopup={this.handleOpenChangeDescriptionPopup}
                            />
                            <SidebarInitials
                                title={intl.formatMessage({ id: "app.sidebar.team.title" })}
                                items={[
                                    {
                                        label: intl.formatMessage({ id: "app.sidebar.initials.members" }),
                                        userType: InitialsUserType.INTERNAL,
                                        data: ([] as IOwner[]).concat(project.members, project?.owners || []).map((member) => {
                                            return {
                                                fullName: `${member.firstName} ${member.lastName}`,
                                                initials: `${member.firstName?.charAt(0).toUpperCase()}${member.lastName?.charAt(0).toUpperCase()}`,
                                            };
                                        }),
                                    },
                                    ...(project?.ecUsers.length > 0
                                        ? [
                                              {
                                                  label: intl.formatMessage({ id: "app.sidebar.initials.customers" }),
                                                  userType: InitialsUserType.EXTERNAL,
                                                  data: project?.ecUsers.map((user) => {
                                                      return {
                                                          fullName: `${user.firstName} ${user.lastName}`,
                                                          initials: `${user.firstName?.charAt(0).toUpperCase()}${user.lastName
                                                              ?.charAt(0)
                                                              .toUpperCase()}`,
                                                      };
                                                  }),
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                            <CommentBox disabled={disabled} object={{ id: project.id, type: NoteObjectType.project }} notes={notes || []} />
                        </>,
                        <>
                            <SidebarActivities activities={this.state.activities} />
                        </>,
                    ]}
                />
                <ChangeDescriptionProjectPopup open={showChangeDescriptionPopup} project={project} handleClose={this.onCloseChangeDescriptionPopup} />
            </div>
        );
    }
}

export default injectIntl(ProjectSidebar);
