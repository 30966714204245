import React from "react";
import "./WedInsideFormElement.scss";
import classNames from "classnames";

interface PropsInterface {
    className?: string;
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {}

class WedInsideFormElement extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <div className={classNames("wed-inside-form-element", this.props.className)}>{this.props.children}</div>;
    }
}

export default WedInsideFormElement;
