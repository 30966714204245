import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import Collection from "../../../../domain/Collection";
import UnshareCollection from "../UnshareCollection/UnshareCollection";

interface PropsInterface {
    open: boolean;
    handleClose: (isUpdate?: boolean) => void;
    collection: Collection | undefined;
}

interface StateInterface {}

export class UnshareCollectionPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.closePopup = this.closePopup.bind(this);
    }

    private closePopup() {
        this.props.handleClose();
    }

    render() {
        if (!this.props.collection) {
            return "";
        }

        return (
            <Popup open={this.props.open} handleClose={this.closePopup} maxWidth="sm">
                <PopupContent>
                    <UnshareCollection close={this.props.handleClose} collection={this.props.collection} />
                </PopupContent>
            </Popup>
        );
    }
}
