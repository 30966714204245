import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import "./popup-close-icon.scss";

interface PropsInterface {
    onClick: () => void;
}

interface StateInterface {}

export default class PopupCloseIcon extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <div className="popup-close-icon">
                <IconButton onClick={this.props.onClick}>
                    <CloseIcon />
                </IconButton>
            </div>
        );
    }
}
