import Ajv from "ajv";
import AjvErrors from "ajv-errors";
import AjvKeywords from "ajv-keywords";

export interface ValidationErrors {
    [key: string]: string;
}

export interface ValidationResult {
    valid: boolean;
    errors: ValidationErrors;
}

class JSONSchemaValidator {
    // eslint-disable-next-line
    public static validate(data: any, schema: object | string | boolean): ValidationResult {
        const ajv = new Ajv({ allErrors: true, jsonPointers: true, verbose: true, coerceTypes: true, $data: true });
        AjvErrors(ajv);
        AjvKeywords(ajv);

        const valid = ajv.validate(schema, data);

        let errors = {};
        if (!valid && ajv.errors) {
            errors = ajv.errors.reduce((result: ValidationErrors, error) => {
                const field: string = error.dataPath.replace(/^\/+/g, "");
                result[field] = error.message || "";
                return result;
            }, {});
        }

        return { valid: !!valid, errors };
    }
}

export default JSONSchemaValidator;
