// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findParentNode(element: any, tagName: string): any {
    for (let i = 0; i < 10; i++) {
        element = element.parentNode;
        if (!element) {
            return null;
        }
        if (element.tagName.toLowerCase() === tagName.toLowerCase()) {
            return element;
        }
    }
    return null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findBeforeSibling(element: any, tagName: string): any {
    for (let i = 0; i < 10; i++) {
        element = element.previousSibling;
        if (!element) {
            return null;
        }
        if (element.tagName.toLowerCase() === tagName.toLowerCase()) {
            return element;
        }
    }
    return null;
}
