interface AppConfig {
    apiUrl: string;
    notice: boolean;
    gCaptchaSiteKey: string | null;
}

const appConfig: AppConfig = {
    apiUrl: (process.env["REACT_APP_API_URL"] as string).replace(/[/]+$/, ""),
    notice: !(!process.env["REACT_APP_NOTICE"] || (process.env["REACT_APP_NOTICE"] as string).toLowerCase() === "false"),
    gCaptchaSiteKey: process.env["REACT_APP_GOOGLE_CAPTCHA_SITE_KEY"] || null,
};

export default appConfig;
