import React, { ReactElement } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import _ from "lodash";
import classnames from "classnames";
import Initials from "../components/Initials/Initials";
import FilesCountIcon from "../../count-icon/FilesCountIcon/FilesCountIcon";
import Title from "../components/Title/Title";
import { InitialsAndOwnerInterface } from "../../../services/WedApi/Utils/Initials";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { IFileSimple } from "../../../services/WedApi/Models/File";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import container from "../../../container";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { WedImage } from "../../wed-image/WedImage";
import "../tile.scss";
import "./directory-tile.scss";

interface PropsInterface extends RouteComponentProps {
    title: string;
    topObjects: IFileSimple[];
    totalFiles: number;
    initialsAndOwner?: InitialsAndOwnerInterface;
    selected: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    actionButton?: ReactElement;
}

interface StateInterface {
    isHover: boolean;
}

class DirectoryTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["actionButton", "onClick", "key", "get"];

    private maxImageItems = 3;

    constructor(props: PropsInterface) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.state = {
            isHover: false,
        };
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick() {
        const { onClick } = this.props;

        onClick && onClick();
    }

    onDoubleClick() {
        const { onDoubleClick } = this.props;

        onDoubleClick && onDoubleClick();
    }

    render() {
        const { topObjects, totalFiles, title, initialsAndOwner, selected, actionButton } = this.props;

        return (
            <div
                className={classnames("tile directory-tile", {
                    "tile--active": selected,
                })}
                onMouseEnter={() => {
                    this.setState({ isHover: true });
                }}
            >
                {this.state.isHover && actionButton}
                <div className="tile-container" onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
                    <div className="directory-tile__media">
                        {topObjects
                            .filter((obj, i) => i < this.maxImageItems)
                            .map((obj, i) => (
                                <span key={i} className="directory-tile__image">
                                    {isFileHtmlImageExtension(obj.extension) ? (
                                        <WedImage
                                            src={container.filesService.getFileUrl(obj.id, obj.newestVersionId, { size: SizeImageEnum.THUMB })}
                                        />
                                    ) : (
                                        <WedIcon icon={<FileTypeIcon extension={obj.extension} fileName={obj.name} />} size={SizeType.FULL} />
                                    )}
                                </span>
                            ))}
                        {totalFiles > 3 && <span className="directory-tile__more-badge">{totalFiles - 3}</span>}
                    </div>
                    <div className="directory-tile__title">
                        <Title title={title} />
                    </div>
                    <div className="directory-tile__footer">
                        {initialsAndOwner ? <Initials title={initialsAndOwner.initials} fullTitle={initialsAndOwner.owner} /> : ""}
                        <FilesCountIcon count={totalFiles} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(DirectoryTile);
