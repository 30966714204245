import React, { Component } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import LinearProgress from "@material-ui/core/LinearProgress";
import { generatePath, RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { WedMainMenuContainer } from "../../main-menu/WedMainMenuContainer/WedMainMenuContainer";
import SidebarWrapper from "../../sidebar/SidebarWrapper/SidebarWrapper";
import { IDirectory, IFile, IFolder } from "../../../services/WedApi/Models/File";
import { CreateFolderPopup } from "../../folder/CreateFolderPopup/CreateFolderPopup";
import { ApplicationState } from "../../../store";
import { IUserTeamDetail, IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { InjectedCtrlKeyDetectorProps, withCtrlKeyDetector } from "../../../hoc/withCtrlKeyDetector";
import { ISelectItem, ISelectItemType } from "../../status-action-button/StatusActionButton";
import FilesButtons from "./FilesPageHeader/FilesButtons/FilesButtons";
import { ICollection, ICollectionExtended } from "../../../services/WedApi/Models/Collection";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import MoveToTrashPopup from "../../../components/project/Files/MoveToTrashPopup";
import FilesPageContent from "../../../components/project/Files/FilesPageContent/FilesPageContent";
import Collection from "../../../domain/Collection";
import { CreateCollectionPopup } from "../../project/Collection/CreateCollectionPopup/CreateCollectionPopup";
import { AddFileToDataPopup } from "../../file/AddFileToDataPopup/AddFileToDataPopup";
import { SharingCollectionPopup } from "../../project/Collection/SharingCollectionPopup/SharingCollectionPopup";
import { DeleteCollectionPopup } from "../../project/Collection/DeleteCollectionPopup/DeleteCollectionPopup";
import DataFilesFactory from "./Service/DataFilesByType/DataFilesFactory";
import { IProjectExtended } from "../../../services/WedApi/Models/Project";
import { FileTypeEnum, isMyLibraryFileType } from "../Type/FileTypeEnum";
import DataFilesInterface from "./Service/DataFilesByType/DataFilesInterface";
import SubmenuFactory from "../Submenu/Service/SubmenuFactory";
import MenuControlIcons from "../../main-menu/MenuControlIcons/MenuControlIcons";
import { UploadFilePopup } from "../../file/UploadFilePopup/UploadFilePopup";
import { SidebarType } from "../../sidebar/Sidebar/Sidebar";
import "./files-page.scss";
import { Dispatch } from "redux";
import { SelectedItemsProps, setSelectedItems, SetSelectedItemsProps } from "../../../store/SelectedItemsReducer";
import { refreshSelectedItems } from "../../../services/WedApi/Utils/RefreshSelectedItems";
import { isEditableProject } from "../../../services/WedApi/Utils/IsEditableProject";
import { getQueryIntParam } from "../../../services/WedApi/Utils/QueryParameter";
import { parse } from "query-string";

interface BasePropsInterface {
    collection?: ICollection;
    project?: IProjectExtended;
    userTeam?: IUserTeamDetail;
    fileType: FileTypeEnum;
}

export interface PropsInterface
    extends BasePropsInterface,
        SelectedItemsProps,
        SetSelectedItemsProps,
        RouteComponentProps,
        WrappedComponentProps,
        InjectedCtrlKeyDetectorProps,
        ProviderContext {
    isTableMode: boolean;
    collectionId?: number;
    userId?: number;
    overrideDirId?: number;
}

interface StateInterface {
    isLoading: boolean;
    files: IFile[];
    directories: IDirectory[];
    parentFolder?: IDirectory;
    collection?: {
        items: ICollection[];
        item: ICollectionExtended;
    };
    dir?: {
        id: number;
        folder: IFolder;
    };
    openCreateFolderPopup: boolean;
    moveToTrashItems: ISelectItem[];
    deleteCollection: boolean;
    createCollectionOpen: boolean;
    sharingCollection: boolean;
    addFileToCollectionOpen: boolean;
    uploadFileOpen: boolean;
}

class FilesPage extends Component<PropsInterface, StateInterface> {
    private filesDataService: DataFilesInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.filesDataService = DataFilesFactory.getFileType(this.props.fileType);
        this.state = {
            files: [],
            directories: [],
            isLoading: true,
            collection: undefined,
            dir: undefined,
            openCreateFolderPopup: false,
            moveToTrashItems: [],
            deleteCollection: false,
            createCollectionOpen: false,
            addFileToCollectionOpen: false,
            sharingCollection: false,
            uploadFileOpen: false,
        };

        this.handleClickOpenCreateFolderPopup = this.handleClickOpenCreateFolderPopup.bind(this);
        this.handleClickCloseCreateFolderPopup = this.handleClickCloseCreateFolderPopup.bind(this);
        this.handleClickOpenMoveToTrash = this.handleClickOpenMoveToTrash.bind(this);
        this.handleClickCloseMoveToTrash = this.handleClickCloseMoveToTrash.bind(this);
        this.refreshAfterChanges = this.refreshAfterChanges.bind(this);
        this.handleTrashClose = this.handleTrashClose.bind(this);
        this.handleOpenFile = this.handleOpenFile.bind(this);
        this.handleInitActionCallback = this.handleInitActionCallback.bind(this);
        this.handleErrorActionCallback = this.handleErrorActionCallback.bind(this);
        this.handleOpenFolder = this.handleOpenFolder.bind(this);
        this.handleClickOpenCreateCollection = this.handleClickOpenCreateCollection.bind(this);
        this.handleClickOpenDeleteCollection = this.handleClickOpenDeleteCollection.bind(this);
        this.handleClickOpenSharingCollection = this.handleClickOpenSharingCollection.bind(this);
        this.handleOpenAddFileToCollection = this.handleOpenAddFileToCollection.bind(this);
        this.handleCollectionClosePopup = this.handleCollectionClosePopup.bind(this);
        this.handleClickOpenUploadFilePopup = this.handleClickOpenUploadFilePopup.bind(this);
        this.handleCloseUploadFilePopup = this.handleCloseUploadFilePopup.bind(this);
    }

    private handleClickOpenCreateCollection() {
        this.setState({ createCollectionOpen: true });
    }

    private handleClickOpenDeleteCollection() {
        this.setState({ deleteCollection: true });
    }

    private handleClickOpenSharingCollection() {
        this.setState({ sharingCollection: true });
    }

    private handleOpenAddFileToCollection() {
        this.setState({ addFileToCollectionOpen: true });
    }

    private handleClickOpenUploadFilePopup() {
        this.setState({ uploadFileOpen: true });
    }

    private handleCloseUploadFilePopup() {
        this.setState({ uploadFileOpen: false });
    }

    private handleCollectionClosePopup() {
        this.setState({
            deleteCollection: false,
            createCollectionOpen: false,
            sharingCollection: false,
            addFileToCollectionOpen: false,
        });
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: PropsInterface) {
        if (this.filesDataService.didUpdate(prevProps, this.props)) {
            this.setState({ isLoading: true });
            this.props.setSelectedItems({ selectedItems: [] });
            await this.fetchData();
        }
    }

    async componentWillUnmount() {
        this.props.setSelectedItems({ selectedItems: [] });
    }

    private async fetchData() {
        const data = await this.filesDataService.fetch(this.props);

        this.setState(data);
    }

    private async handleTrashClose() {
        this.setState({ isLoading: true });
        await this.fetchData();
    }

    private handleClickOpenCreateFolderPopup() {
        this.setState({
            openCreateFolderPopup: true,
        });
    }

    private async handleClickCloseCreateFolderPopup() {
        this.setState({
            openCreateFolderPopup: false,
        });
        await this.fetchData();
    }

    private handleClickOpenMoveToTrash(items: ISelectItem[]) {
        this.setState({ moveToTrashItems: items });
    }

    private handleClickCloseMoveToTrash() {
        this.setState({ moveToTrashItems: [] });
    }

    private handleOpenFolder(directory: IDirectory) {
        this.props.history.push(
            this.filesDataService.openSection({
                dirId: directory.id,
                projectId: this.props.project?.id,
            })
        );
    }

    private handleOpenFile(file: IFile, dirId?: number) {
        this.props.history.push(
            this.filesDataService.openFile({
                dirId: dirId,
                objectId: file.id,
                projectId: this.props.project?.id || undefined,
                collectionId: this.props.collectionId || undefined,
            })
        );
    }

    private async refreshAfterChanges() {
        this.setState({ isLoading: true });
        await this.fetchData();
        this.props.setSelectedItems({
            selectedItems: refreshSelectedItems(this.props.selectedItems, [
                { type: ISelectItemType.directory, items: this.state.directories },
                { type: ISelectItemType.file, items: this.state.files },
            ]),
        });
    }

    private handleInitActionCallback() {
        this.setState({ isLoading: true });
    }

    private handleErrorActionCallback() {
        this.setState({ isLoading: false });
    }

    render() {
        const { isTableMode, fileType, selectedItems, collectionId } = this.props;
        const { isLoading, dir, collection, moveToTrashItems, files, directories, parentFolder } = this.state;

        const project = this.props.project;
        const firstSelectedItem = selectedItems.length == 1 ? selectedItems[0] : undefined;

        let sidebarType: SidebarType;
        let sidebarDisabled: boolean = !isMyLibraryFileType(this.props.fileType) && !isEditableProject(this.props.fileType, this.props.project);
        let sidebarObjectId: number | undefined = firstSelectedItem?.id || project?.id;
        let sidebarDirId: number | undefined = dir?.id;
        let sidebarCollectionId: number | undefined = collection?.item.id;
        if (firstSelectedItem) {
            if (firstSelectedItem.type == ISelectItemType.directory) {
                sidebarType = SidebarType.folder;
            } else if (collection) {
                sidebarType = SidebarType.collectionFile;
                if (collection.item.locked) {
                    sidebarDisabled = true;
                }
            } else {
                sidebarType = SidebarType.file;
            }
        } else if (collectionId) {
            sidebarType = SidebarType.collection;
            sidebarObjectId = this.props.collectionId;
            sidebarDirId = undefined;
            sidebarCollectionId = undefined;
            if (this.state.collection?.item.locked) {
                sidebarDisabled = true;
            }
        } else {
            if (sidebarDirId && getQueryIntParam(parse(this.props.location.search), "dir")) {
                sidebarType = SidebarType.folder;
                sidebarObjectId = sidebarDirId;
            } else {
                sidebarType = SidebarType.project;
            }
        }

        return (
            <div className="project-list-page">
                {dir && (
                    <CreateFolderPopup dirId={dir.id} open={this.state.openCreateFolderPopup} handleClose={this.handleClickCloseCreateFolderPopup} />
                )}
                {dir && (
                    <MoveToTrashPopup
                        items={moveToTrashItems}
                        open={!!moveToTrashItems.length}
                        handleClose={this.handleClickCloseMoveToTrash}
                        onSuccess={async () => {
                            this.handleClickCloseMoveToTrash();
                            await this.refreshAfterChanges();
                        }}
                    />
                )}
                {dir && (
                    <UploadFilePopup
                        dirId={dir.id}
                        open={this.state.uploadFileOpen}
                        handleClose={this.handleCloseUploadFilePopup}
                        uploaded={async () => {
                            this.handleCloseUploadFilePopup();
                            await this.refreshAfterChanges();
                        }}
                    />
                )}
                {this.state.collection && (
                    <CreateCollectionPopup
                        projectId={project?.id || undefined}
                        open={this.state.createCollectionOpen}
                        handleClose={this.handleCollectionClosePopup}
                    />
                )}
                {!!this.state.collection && this.state.addFileToCollectionOpen && (
                    <AddFileToDataPopup
                        open={true}
                        contextCollection={{
                            collectionId: this.state.collection.item.id,
                            project: project,
                        }}
                        handleClose={async (updated?: boolean) => {
                            this.handleCollectionClosePopup();
                            if (updated) {
                                await this.refreshAfterChanges();
                            }
                        }}
                    />
                )}
                {!!this.state.collection && this.state.sharingCollection && (
                    <SharingCollectionPopup
                        open={true}
                        // eslint-disable-next-line
                        project={this.props.project}
                        collection={new Collection(this.state.collection.item)}
                        handleClose={this.handleCollectionClosePopup}
                        onChange={async () => {
                            this.setState({ isLoading: true });
                            await this.refreshAfterChanges();
                        }}
                    />
                )}
                {!!this.state.collection && this.state.deleteCollection && (
                    <DeleteCollectionPopup
                        open={true}
                        collection={new Collection(this.state.collection.item)}
                        handleClose={this.handleCollectionClosePopup}
                        successDelete={() => {
                            this.props.history.push(generatePath(RoutesEnum.COLLECTIONS, { projectId: this.props.project?.id || 0 }));
                        }}
                    />
                )}
                <WedMainMenuContainer
                    menu={SubmenuFactory.getSubmenu(this.props.fileType, this.props.project?.id)}
                    buttons={
                        (dir || this.state.collection) && (
                            <FilesButtons
                                project={project}
                                projectName={this.filesDataService.getRootDirName(project)}
                                dirId={dir?.id as number}
                                collection={collection?.item}
                                rootDir={dir?.folder.directory.paths[0].id}
                                selectedItems={selectedItems}
                                createFolder={this.handleClickOpenCreateFolderPopup}
                                actionCallback={this.refreshAfterChanges}
                                removeToTrash={this.handleClickOpenMoveToTrash}
                                handleOpenFile={this.handleOpenFile}
                                handleOpenFolder={this.handleOpenFolder}
                                createCollection={this.handleClickOpenCreateCollection}
                                deleteCollection={this.handleClickOpenDeleteCollection}
                                openSharing={this.handleClickOpenSharingCollection}
                                openAddFileToCollection={this.handleOpenAddFileToCollection}
                                openUploadFile={this.handleClickOpenUploadFilePopup}
                                fileType={this.props.fileType}
                                directory={this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME ? this.state.parentFolder : undefined}
                                userId={this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME ? this.props.userId : undefined}
                            />
                        )
                    }
                    sidebar={<MenuControlIcons project={project} onTrashClose={this.handleTrashClose} fileType={this.props.fileType} />}
                />
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <SidebarWrapper
                        handleSidebarChanges={this.refreshAfterChanges}
                        type={sidebarType}
                        id={sidebarObjectId}
                        dirId={sidebarDirId}
                        collectionId={sidebarCollectionId}
                        fileType={fileType}
                        contextProject={[SidebarType.collection, SidebarType.collectionFile].includes(sidebarType) ? this.props.project : undefined}
                        disabled={sidebarDisabled}
                    >
                        <FilesPageContent
                            fileType={fileType}
                            collection={collection}
                            dir={dir}
                            // eslint-disable-next-line
                            project={project || ({} as any)}
                            isTableMode={isTableMode}
                            files={files}
                            directories={directories}
                            parentFolder={parentFolder}
                            userId={this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME ? this.props.userId : undefined}
                            callbacks={{
                                handleRefreshAfterChanges: this.refreshAfterChanges,
                                handleClickOpenMoveToTrash: this.handleClickOpenMoveToTrash,
                                handleOpenFolder: this.handleOpenFolder,
                                handleOpenFile: this.handleOpenFile,
                                handleClickOpenCreateFolderPopup: this.handleClickOpenCreateFolderPopup,
                                handleErrorActionCallback: this.handleErrorActionCallback,
                                handleInitActionCallback: this.handleInitActionCallback,
                                handleOpenAddFileToCollection: this.handleOpenAddFileToCollection,
                                handleClickOpenUploadFilePopup: this.handleClickOpenUploadFilePopup,
                            }}
                        />
                    </SidebarWrapper>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    selectedItems: store.selectedItems.selectedItems || [],
    userId: store.user.data?.id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedItems: setSelectedItems(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCtrlKeyDetector(withSnackbar(withRouter(injectIntl(FilesPage)))));
