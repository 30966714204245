import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import { RouteComponentProps, withRouter, generatePath } from "react-router-dom";
import _size from "lodash/size";
import { ProviderContext, withSnackbar } from "notistack";
import container from "../../../../container";
import PopupAction from "../../../popup/PopupAction/PopupAction";
import JSONSchemaValidator, { ValidationErrors } from "../../../../services/JSONSchemaValidator/JSONSchemaValidator";
import FrontApiErrorCodeEnum from "../../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import PopupHeader, { PositionTitle } from "../../../popup/PopupHeader/PopupHeader";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";
import "./CreateCollection.scss";

interface PropsInterface extends WrappedComponentProps, ProviderContext, RouteComponentProps {
    projectId?: number;
    close: (collectionId?: number) => void;
    objectIds?: number[];
    redirectToCollection: boolean;
}

interface StateInterface {
    fields: {
        collectionName: string;
        collectionDescription: string;
    };
    loading: boolean;
}

class CreateCollection extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            collectionName: {
                type: "string",
                minLength: 3,
                maxLength: 100,
            },
            collectionDescription: {
                type: "string",
                maxLength: 500,
            },
        },
        required: ["collectionName"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                collectionName: this.props.intl.formatMessage({ id: "app.createCollection.error.collectionName" }),
                collectionDescription: this.props.intl.formatMessage({ id: "app.createCollection.error.collectionDescription" }),
            },
        },
    };

    readonly state = {
        fields: {
            collectionName: "",
            collectionDescription: "",
        },
        loading: false,
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    validate = (): { valid: boolean; formErrors: ValidationErrors } => {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    };

    validateForm = (): ValidationErrors => {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            const collectionId: number = await container.collectionActionApiService.createCollection({
                name: this.state.fields.collectionName,
                desc: this.state.fields.collectionDescription || undefined,
                ...(this.props.projectId ? { pid: this.props.projectId } : {}),
                ...(this.props.objectIds && this.props.objectIds.length > 0 ? { objectIDs: this.props.objectIds } : {}),
            });

            this.props.close(collectionId);

            if (this.props.redirectToCollection) {
                if (this.props.projectId) {
                    this.props.history.push(
                        generatePath(RoutesEnum.COLLECTION_FILES, { projectId: this.props.projectId, collectionId: collectionId })
                    );
                    return;
                }

                this.props.history.push(generatePath(RoutesEnum.MY_LIBRARY_COLLECTIONS_FILES, { collectionId: collectionId }));
                return;
            }
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_COLLECTION_NAME_INVALID) {
                keyTranslate = "app.createCollection.error.collectionName";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_COLLECTION_DESCRIPTION_INVALID) {
                keyTranslate = "app.createCollection.error.collectionDescription";
            }
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }

        this.setState({ loading: false });
    };

    render() {
        return (
            <div className="create-collection">
                <PopupHeader positionHeader={PositionTitle.CENTER}>
                    <FormattedMessage id="app.createCollection.header.title" />
                </PopupHeader>
                <form onSubmit={this.handleSubmit} noValidate>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="collectionName"
                            label={<FormattedMessage id={"app.createCollection.form.collectionName"} />}
                            name="collectionName"
                            autoComplete="off"
                            autoFocus
                            value={this.state.fields.collectionName}
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="collectionDescription"
                            label={<FormattedMessage id={"app.createCollection.form.collectionDescription"} />}
                            name="collectionDescription"
                            autoComplete="off"
                            multiline
                            rows={5}
                            value={this.state.fields.collectionDescription}
                            onChange={this.handleChange}
                        />
                    </div>
                    <PopupAction>
                        <Button type="submit">
                            <FormattedMessage id="app.createCollection.button.create" />
                        </Button>
                    </PopupAction>
                </form>
            </div>
        );
    }
}

export default withRouter(withSnackbar(injectIntl(CreateCollection)));
