import React from "react";
import { connect } from "react-redux";

interface PropsInterface {}

const Layout404: React.FC<PropsInterface> = () => {
    return <div>Page Not Found</div>;
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(Layout404);
