import React from "react";
import Title from "../Title/Title";
import "./LabelTitleWrapper.scss";

interface PropsInterface {
    label: string;
    title: string;
}

export default class LabelTitleWrapper extends React.Component<PropsInterface> {
    render() {
        const { label, title } = this.props;

        return (
            <div className="label-title-wrapper">
                <div className="label-title-wrapper__label">{label}</div>
                <Title title={title} />
            </div>
        );
    }
}
