import React from "react";
import "./project-stat.scss";

interface PropsInterface {
    count: number;
    title: string;
    type: ProjectStatType;
}

interface StateInterface {}

export enum ProjectStatType {
    REJECTED = "REJECTED",
    ACCEPTED = "ACCEPTED",
    STANDARD = "STANDARD",
}

export enum VariantType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}

export default class ProjectStat extends React.Component<PropsInterface, StateInterface> {
    getVariant(): VariantType {
        const { count, type } = this.props;
        if (type === ProjectStatType.STANDARD) {
            return VariantType.WARNING;
        }
        if (type === ProjectStatType.ACCEPTED) {
            return count === 0 ? VariantType.WARNING : VariantType.SUCCESS;
        }
        return count === 0 ? VariantType.WARNING : VariantType.ERROR;
    }

    render() {
        const { count, title } = this.props;
        const variant: VariantType = this.getVariant();

        return (
            <div className="project-stat">
                <span className={`project-stat__count project-stat__count--${variant}`}>{count}</span>
                <span className="project-stat__title">{title}</span>
            </div>
        );
    }
}
