import React, { Component } from "react";
import ListProjectPage from "../../../components/template/ListProjectPage/ListProjectPage";
import { ClientDetailLoaderProps } from "../../../components/client/ClientDetailLoader/ClientDetailLoader";
import { ProjectStatusEnum } from "../../../services/WedApi/Models/Project";

interface PropsInterface extends ClientDetailLoaderProps {
    projectStatus: ProjectStatusEnum;
}

interface StateInterface {}

class ClientProjectsPage extends Component<PropsInterface, StateInterface> {
    render() {
        return <ListProjectPage contextClient={{ client: this.props.client, projectStatus: this.props.projectStatus }} />;
    }
}

export default ClientProjectsPage;
