import React from "react";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import {
    IAccountTeamUser,
    IAccountTeamUserProjectRole,
    ITeamUser,
    ITeamUserProjectRole,
    IUser,
    IUserBase,
} from "../../../services/WedApi/Models/User";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import TeamWedTable from "../../summary/TeamWedTable/TeamWedTable";
import "./UserEditTeamMembersPopup.scss";

interface BasePropsInterface extends WrappedComponentProps, ProviderContext {
    close: (changed?: boolean) => void;
    userMember?: Array<ITeamUser | IAccountTeamUser>;
    clientUserMembers: IUser[];
    customerContacts?: IUser[];
    clientCustomerContacts: IUser[];
    addDefaultTeamRole: ITeamUserProjectRole | IAccountTeamUserProjectRole;
    teamType: "PROJECT" | "ACCOUNT";
    doIt: (userMember: Array<ITeamUser | IAccountTeamUser>, customerContacts: IUser[]) => Promise<void>;
    labels: {
        header: React.ReactElement;
        addUser: React.ReactElement;
        addContacts?: React.ReactElement;
    };
}

interface PropsInterface extends BasePropsInterface {
    userId?: number;
}

interface StateInterface {
    userMember: Array<ITeamUser | IAccountTeamUser>;
    customerContacts: IUser[];
}

class UserEditTeamMembersPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userMember: props.userMember || [],
            customerContacts: props.customerContacts || [],
        };
        this.doIt = this.doIt.bind(this);
        this.removeCustomerContact = this.removeCustomerContact.bind(this);
        this.changeUserRole = this.changeUserRole.bind(this);
        this.dennyAccessRole = this.dennyAccessRole.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(this.props.userMember, prevProps.userMember) || !isEqual(this.props.customerContacts, prevProps.customerContacts)) {
            this.setState({
                userMember: this.props.userMember || [],
                customerContacts: this.props.customerContacts || [],
            });
        }
    }

    private addTeamMemberUser(user: IUser) {
        this.setState({
            userMember: [
                {
                    ...user,
                    role: this.props.addDefaultTeamRole,
                },
                ...this.state.userMember,
            ],
        });
    }

    private addCustomerContactUser(user: IUser) {
        this.setState({
            customerContacts: [user, ...this.state.customerContacts],
        });
    }

    // eslint-disable-next-line
    private changeUserRole(id: number, newRole: any) {
        this.setState({
            userMember: this.state.userMember.map((userMember) => {
                if (userMember.id === id) {
                    return {
                        ...userMember,
                        role: newRole,
                    };
                }
                return userMember;
            }),
        });
    }

    private dennyAccessRole(id: number) {
        const idx = this.state.userMember.findIndex((user) => user.id === id);
        if (idx !== -1) {
            this.setState({
                userMember: [...this.state.userMember.slice(0, idx), ...this.state.userMember.slice(idx + 1)],
            });
        }
    }

    private removeCustomerContact(id: number) {
        const idx = this.state.customerContacts.findIndex((user) => user.id === id);
        if (idx !== -1) {
            this.setState({
                customerContacts: [...this.state.customerContacts.slice(0, idx), ...this.state.customerContacts.slice(idx + 1)],
            });
        }
    }

    private async doIt(): Promise<void> {
        await this.props.doIt(this.state.userMember, this.state.customerContacts);
    }

    render() {
        const allowUserMembers = this.props.clientUserMembers.filter(
            (clientUserMembers) => !(this.state.userMember || []).find((userMember) => userMember.id === clientUserMembers.id)
        );
        const allowCustomerContacts = this.props.clientCustomerContacts.filter(
            (clientCustomerContact) => !(this.state.customerContacts || []).find((customerContact) => customerContact.id === clientCustomerContact.id)
        );

        return (
            <PopupConfirmButton
                maxWidth="md"
                open={this.props.userMember !== undefined}
                close={this.props.close}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.standard.popup.button.add"} />}
                noDisplayCancel={true}
                header={this.props.labels.header}
                disableDialogWrapper={true}
                content={
                    <div className="user-edit-team-members-popup">
                        <div className="user-edit-team-members-popup--content">
                            <div className="user-edit-team-members-popup--user-members">
                                <Autocomplete
                                    fullWidth
                                    id={"user-edit--team-members"}
                                    options={allowUserMembers}
                                    // eslint-disable-next-line
                                    value={null}
                                    getOptionLabel={(option: IUserBase) => `${option.firstName} ${option.lastName}`}
                                    // eslint-disable-next-line
                                    onChange={(event: React.ChangeEvent<any>, value: IUser | null) => {
                                        if (value) {
                                            this.addTeamMemberUser(value);
                                            const input = document.getElementById("user-edit--team-members") as HTMLInputElement;
                                            if (input) {
                                                setTimeout(() => {
                                                    input.blur();
                                                }, 25);
                                            }
                                        }
                                    }}
                                    // eslint-disable-next-line
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            label={this.props.labels.addUser}
                                            margin="normal"
                                            variant="outlined"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <TeamWedTable
                                    teamType={this.props.teamType}
                                    hideJoinedColumn={true}
                                    hideTableHeaders={true}
                                    users={this.state.userMember}
                                    userIsOwner={true}
                                    actions={{
                                        changeUserRole: this.changeUserRole,
                                        dennyAccessRole: this.dennyAccessRole,
                                    }}
                                />
                            </div>
                            {!!this.props.labels.addContacts && (
                                <div className="user-edit-team-members-popup--customer-contacts">
                                    <Autocomplete
                                        fullWidth
                                        id={"user-edit--customer-contact"}
                                        options={allowCustomerContacts}
                                        // eslint-disable-next-line
                                        value={null}
                                        getOptionLabel={(option: IUserBase) => `${option.firstName} ${option.lastName}`}
                                        // eslint-disable-next-line
                                        onChange={(event: React.ChangeEvent<any>, value: IUser | null) => {
                                            if (value) {
                                                this.addCustomerContactUser(value);
                                                const input = document.getElementById("user-edit--customer-contact") as HTMLInputElement;
                                                if (input) {
                                                    setTimeout(() => {
                                                        input.blur();
                                                    }, 25);
                                                }
                                            }
                                        }}
                                        // eslint-disable-next-line
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                required
                                                label={this.props.labels.addContacts}
                                                margin="normal"
                                                variant="outlined"
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                    <TeamWedTable
                                        teamType={this.props.teamType}
                                        hideJoinedColumn={true}
                                        hideTableHeaders={true}
                                        contacts={this.state.customerContacts}
                                        userIsOwner={true}
                                        actions={{
                                            removeCustomerContact: this.removeCustomerContact,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id,
    };
};

export default withSnackbar(injectIntl(connect(mapStateToProps)(UserEditTeamMembersPopup)));
