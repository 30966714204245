import React from "react";
import {Scrollbars} from "react-custom-scrollbars";
import {IFile} from "../../../services/WedApi/Models/File";
import container from "../../../container";
import {SizeImageEnum} from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./WedMediaGalleryVersion.scss";
import WedMediaGalleryImage from "../WedMediaGalleryImage/WedMediaGalleryImage";
import classNames from "classnames";
import {getScrollbarIconYStyle, getScrollbarYStyle} from "../../../services/WedApi/Utils/ScrollbarsStyles";
import {WedIconArrow} from "../../wed-icon/generated/WedIconSvg";
import {WedIcon} from "../../wed-icon/WedIcon";
import {FormattedMessage} from "react-intl";
import {FileTypeEnum} from "../../template/Type/FileTypeEnum";
import {AppNotFoundItemError} from "../../../errors/error-app";

interface PropsInterface {
    files?: IFile[];
    objectId?: number;
    collectionId?: number;
    token?: string;
    onChangeFileVersion?: (versionId?: number) => void;
    fileType: FileTypeEnum;
    userTeamId?: number;
}

interface StateInterface {
    isLoading: boolean;
    fileId?: number;
    versionId?: number;
    previews: {
        id: number;
        url: string;
        extension: string;
    }[];
}

export default class WedMediaGalleryVersion extends React.Component<PropsInterface, StateInterface> {
    private imageLoaded: number | undefined = undefined;

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            fileId: undefined,
            versionId: undefined,
            previews: [],
        };
        this.onImageLoaded = this.onImageLoaded.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (this.props.files !== undefined && this.props.objectId !== prevProps.objectId) {
            await this.fetchData();
        }
    }

    async fetchData() {
        if (this.props.files === undefined) {
            return;
        }

        this.setState({
            isLoading: true,
            fileId: undefined,
            previews: [],
        });

        const file: IFile | undefined = this.props.files.find((file) => file.id === this.props.objectId);
        if (!file) {
            throw new AppNotFoundItemError("file", this.props.objectId);
        }

        this.imageLoaded = file.versions.length;
        this.setState({
            fileId: file.id,
            previews: file.versions.map((version) => {
                return {
                    id: version.id,
                    extension: version.extension,
                    url: this.getFileUrl(file.id, version.id, SizeImageEnum.THUMB),
                };
            }),
        });
    }

    private onImageLoaded() {
        if (this.imageLoaded !== undefined) {
            this.imageLoaded--;
            if (this.imageLoaded === 0) {
                this.imageLoaded = undefined;
                this.setState({ isLoading: false });
            }
        }
    }

    private scrollToCurrentVersion() {
        if (this.state.versionId) {
            const currentElement = document.getElementById(`wed-media-gallery-version-${this.state.versionId}`);
            if (currentElement) {
                currentElement.scrollIntoView(false);
            }
        }
    }

    private changeFileVersion(versionId?: number, callback?: () => void) {
        this.props.onChangeFileVersion && this.props.onChangeFileVersion(versionId);
        this.setState({
            versionId,
        });
        if (callback) {
            setTimeout(() => {
                callback();
            }, 50);
        }
    }

    getFileUrl = (id: number, versionId: number, size: SizeImageEnum) => {
        const { token, collectionId } = this.props;

        if (token && collectionId) {
            return container.filesService.getSharedFileUrl(token, id, versionId, collectionId, size);
        }
        return container.filesService.getFileUrl(
            id,
            versionId,
            {
                size,
                fileType: this.props.fileType,
                collectionId,
                ...(this.props.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO && this.props.userTeamId
                    ? { userId: this.props.userTeamId }
                    : {}
                )
            }
        );
    };

    render() {
        const { previews, fileId, isLoading } = this.state;
        let prevVersionId: number | undefined = undefined;
        let nextVersionId: number | undefined = undefined;
        if (previews && previews.length > 1) {
            if (this.state.versionId === undefined) {
                nextVersionId = previews[1].id;
            } else {
                const previewIdx = previews.findIndex((preview) => preview.id === this.state.versionId);
                if (previewIdx > 0) {
                    prevVersionId = previews[previewIdx - 1].id;
                }
                if (previewIdx < previews.length - 1) {
                    nextVersionId = previews[previewIdx + 1].id;
                }
            }
        }
        return (
            <div className={classNames("wed-media-gallery-version", { "wed-media-gallery-version__loading": isLoading })}>
                {isLoading && <LinearProgress />}
                <div className="wed-media-gallery-version__body">
                    {!isLoading && (
                        <div className="wed-media-gallery-version__header">
                            <FormattedMessage id="app.ProjectFilesMediaGalleryButtons.versions" />
                        </div>
                    )}
                    {fileId && previews && (
                        <div className="wed-media-gallery-version__container">
                            <button
                                disabled={!prevVersionId}
                                className="wed-icon-button wed-icon-button-up"
                                onClick={() => {
                                    if (prevVersionId) {
                                        this.changeFileVersion(prevVersionId, () => {
                                            this.scrollToCurrentVersion();
                                        });
                                    }
                                }}
                            >
                                <WedIcon icon={<WedIconArrow />} />
                            </button>
                            <div className="wed-media-gallery-version__content">
                                <Scrollbars
                                    universal
                                    renderTrackVertical={(props) => {
                                        return React.createElement("div", {
                                            ...props,
                                            style: getScrollbarYStyle({ right: 2 }),
                                        });
                                    }}
                                    renderThumbVertical={(props) => {
                                        return React.createElement("div", {
                                            ...props,
                                            style: getScrollbarIconYStyle(),
                                        });
                                    }}
                                    renderTrackHorizontal={() => {
                                        return React.createElement("span", { style: { display: "none" } });
                                    }}
                                >
                                    <ul>
                                        {previews.map((preview, i) => (
                                            <li key={i} id={`wed-media-gallery-version-${preview.id}`}>
                                                <WedMediaGalleryImage
                                                    fileType={this.props.fileType}
                                                    className={
                                                        (!this.state.versionId && i === 0) ||
                                                        (this.state.versionId && this.state.versionId === preview.id)
                                                            ? "wed-media-gallery-image-active"
                                                            : undefined
                                                    }
                                                    extensionFile={preview.extension}
                                                    link={preview.url}
                                                    onClick={() => {
                                                        this.changeFileVersion(i === 0 ? undefined : preview.id);
                                                    }}
                                                    onComplete={this.onImageLoaded}
                                                    token={this.props.token}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </Scrollbars>
                            </div>
                            <button
                                disabled={!nextVersionId}
                                className="wed-icon-button wed-icon-button-down"
                                onClick={() => {
                                    if (nextVersionId) {
                                        this.changeFileVersion(nextVersionId, () => {
                                            this.scrollToCurrentVersion();
                                        });
                                    }
                                }}
                            >
                                <WedIcon icon={<WedIconArrow />} />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
