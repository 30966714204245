import React, { Component } from "react";
import container from "../../../../container";
import { LinearProgress } from "@material-ui/core";
import { AdminPanelTable } from "../../components/AdminPanelTable/AdminPanelTable";
import { IInvitationEmail } from "../../../../services/WedApi/Models/InvitationEmail";
import WedDate from "../../../../components/formatter/WedDate";
import AdminPanelIconButton from "../../components/AdminPanelIconButton/AdminPanelIconButton";
import { WedIconTrashDeleteRemove } from "../../../../components/wed-icon/generated/WedIconSvg";
import PopupConfirmButton from "../../../../components/popup/PopupConfirmButton/PopupConfirmButton";
import { FormattedMessage } from "react-intl";

const TABLE_HEADERS = [
    "app.AdminPanelPendingInvitations.header.invitationDate",
    "app.AdminPanelPendingInvitations.header.sentTo",
    "app.AdminPanelPendingInvitations.header.invitedBy",
    "app.AdminPanelPendingInvitations.header.expirationTime",
    "app.AdminPanelManageCurrentUsers.header.withdrawInvitation",
];

interface PopupDeleteInvitation {
    email: string;
}

interface PropsInterface {}

interface StateInterface {
    loading: boolean;
    items: IInvitationEmail[];
    popup?: {
        deleteInvitation?: PopupDeleteInvitation;
    };
}

export default class AdminPanelPendingInvitations extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: true,
            items: [],
        };
        this.handleClose = this.handleClose.bind(this);
        this.deleteInvitation = this.deleteInvitation.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            loading: false,
            items: await container.adminService.getPendingInvitations(),
        });
    }

    private async handleClose(changed?: boolean) {
        this.setState({ popup: undefined });
        if (changed) {
            this.setState({ loading: true });
            await this.fetchData();
        }
    }

    private async deleteInvitation(): Promise<void> {
        if (this.state.popup?.deleteInvitation?.email) {
            await container.adminService.removePendingInvitation(this.state.popup.deleteInvitation.email);
        }
    }

    private showPopupDeleteInvitation(data: PopupDeleteInvitation) {
        return (
            <PopupConfirmButton
                open={true}
                close={this.handleClose}
                doIt={this.deleteInvitation}
                header={<FormattedMessage id={"app.AdminPanelPendingInvitations.deleteInvitation.header"} />}
                content={
                    <FormattedMessage
                        id={"app.AdminPanelPendingInvitations.deleteInvitation.content"}
                        values={{
                            email: data.email,
                        }}
                    />
                }
            />
        );
    }

    private getRow(item: IInvitationEmail): Array<string | React.ReactElement | undefined> {
        return [
            item.created ? <WedDate date={item.created} /> : undefined,
            item.email,
            item.invitedBy ? `${item.invitedBy.firstName} ${item.invitedBy.lastName}` : undefined,
            item.expirationTime ? <WedDate date={item.expirationTime} /> : undefined,
            <AdminPanelIconButton
                key={4}
                icon={<WedIconTrashDeleteRemove />}
                onClick={() => {
                    this.setState({ popup: { deleteInvitation: { email: item.email } } });
                }}
            />,
        ];
    }

    render() {
        return (
            <div className="admin-panel-pending-invitations">
                {this.state.loading ? (
                    <LinearProgress />
                ) : (
                    <>
                        {this.state.popup?.deleteInvitation && this.showPopupDeleteInvitation(this.state.popup.deleteInvitation)}
                        <AdminPanelTable headers={TABLE_HEADERS} centerIdx={[4]} rows={this.state.items.map((item) => this.getRow(item))} />
                    </>
                )}
            </div>
        );
    }
}
