import React from "react";
import { ICommentNote } from "../../../services/WedApi/Models/Note";
import container from "../../../container";
import { SizeImageEnum } from "../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import { WedImage } from "../../../components/wed-image/WedImage";
import { getCommentsObjectProofColor } from "../../../services/WedApi/Utils/CollectionDotStatusColor";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import "./ProjectSummaryPageProofingBox.scss";

interface PropsInterface {
    proofs: ICommentNote[];
}

class ProjectSummaryPageProofingBox extends React.Component<PropsInterface> {
    constructor(props: PropsInterface) {
        super(props);
    }

    render() {
        return (
            <>
                <div className="summary-page-proofing__container">
                    {this.props.proofs.map((data, key) => (
                        <div key={key} className="summary-page-proofing__item">
                            <WedImage
                                src={container.filesService.getFileUrl(data.item.file?.id as number, data.item.file?.newestVersionId as number, {
                                    size: SizeImageEnum.THUMB,
                                })}
                                radiusBottomLeft={true}
                                radiusTopLeft={true}
                                radiusTopRight={true}
                                radiusBottomRight={true}
                            />
                            <ItemDotStatus color={getCommentsObjectProofColor(data.item)} />
                        </div>
                    ))}
                </div>
            </>
        );
    }
}

export default ProjectSummaryPageProofingBox;
