import React from "react";
import WedImageLogo from "./WedImageLogo";

interface PropsInterface {}

interface StateInterface {}

class WedImageLogo40x40 extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <WedImageLogo width={40} height={40} file={"mlogo-40-40.png"} />;
    }
}

export default WedImageLogo40x40;
