import * as React from "react";
import { generatePath } from "react-router-dom";
import classNames from "classnames";
import { WedMainMenu } from "../WedMainMenu/WedMainMenu";
import { WedMainMenuItem } from "../WedMainMenuItem/WedMainMenuItem";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "./ProjectMenu.scss";

interface PropsInterface {
    projectId: number;
    activeTab: "summary" | "comments-and-proofing" | "files" | "collections";
}

interface StateInterface {}

export class ProjectMenu extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <WedMainMenu>
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "summary" })}
                    path={generatePath(RoutesEnum.SUMMARY, { projectId: this.props.projectId })}
                    label="app.projectDetailMenu.summary"
                />
                <WedMainMenuItem
                    className={classNames("project-detail-menu__comments-proofing", {
                        "main-menu__active": this.props.activeTab === "comments-and-proofing",
                    })}
                    path={generatePath(RoutesEnum.COMMENTS_AND_PROOFING, { projectId: this.props.projectId })}
                    label="app.projectDetailMenu.commentsAndProofing"
                />
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "files" })}
                    path={generatePath(RoutesEnum.FILES, { projectId: this.props.projectId })}
                    label="app.projectDetailMenu.files"
                />
                <WedMainMenuItem
                    className={classNames({ "main-menu__active": this.props.activeTab === "collections" })}
                    path={generatePath(RoutesEnum.COLLECTIONS, { projectId: this.props.projectId })}
                    label="app.projectDetailMenu.collections"
                />
            </WedMainMenu>
        );
    }
}
