import TextField from "@material-ui/core/TextField";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconSearch1 } from "../../wed-icon/generated/WedIconSvg";
import React from "react";
import "./FilterFormBoxSearchText.scss";

interface PropsInterface {
    onChange: (value: string) => void;
}

interface StateInterface {
    opened: boolean;
}

export class FilterFormBoxSearchText extends React.Component<PropsInterface, StateInterface> {
    private textInput: HTMLDivElement | null = null;

    constructor(props: PropsInterface) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = { opened: false };
    }

    private handleClick() {
        const opened = !this.state.opened;
        if (opened) {
            this.tryFocusTextInput();
            setTimeout(() => {
                this.tryFocusTextInput();
            }, 50);
        } else {
            this.props.onChange("");
        }
        this.setState({
            opened,
        });
    }

    private tryFocusTextInput() {
        try {
            this.textInput?.getElementsByTagName("input")[0].focus();
        } catch (err) {
            //
        }
    }

    render() {
        return (
            <div className="filter-form-box-search-text">
                {this.state.opened && (
                    <TextField
                        ref={(input) => {
                            this.textInput = input;
                        }}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                            this.props.onChange((event.target.value || "") as string);
                        }}
                        variant="outlined"
                    />
                )}
                <a onClick={this.handleClick} className="filter-form-box__search-icon">
                    <WedIcon size={SizeType.NORMAL} icon={<WedIconSearch1 />} />
                </a>
            </div>
        );
    }
}
