import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { WedMainMenuContainer } from "../../main-menu/WedMainMenuContainer/WedMainMenuContainer";
import SidebarWrapper from "../../../components/sidebar/SidebarWrapper/SidebarWrapper";
import { WedSubMenuContainer } from "../../main-menu/WedSubMenuContainer/WedSubMenuContainer";
import { FilterFormProjectCollection } from "../../filter-form/FilterFormProjectCollection/FilterFormProjectCollection";
import ActionTile from "../../../components/tile/ActionTile/ActionTile";
import CollectionTile from "../../../components/tile/CollectionTile/CollectionTile";
import { CreateCollectionPopup } from "../../project/Collection/CreateCollectionPopup/CreateCollectionPopup";
import container from "../../../container";
import { ICollection } from "../../../services/WedApi/Models/Collection";
import CollectionFilterInterface from "../../../services/Filter/Collection/CollectionFilterInterface";
import { ProjectDetailLoaderProps } from "../../project/ProjectDetailLoader/ProjectDetailLoader";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { WedTable } from "../../wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { ApplicationState } from "../../../store";
import { IUserViewModeEnum } from "../../../services/WedApi/Models/User";
import { connect } from "react-redux";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import WedDate from "../../../components/formatter/WedDate";
import { WedIconDots, WedIconItemsGroupElements, WedIconLock3, WedIconLockUnlock } from "../../wed-icon/generated/WedIconSvg";
import ItemDotStatus from "../../../components/tile/components/ItemDotStatus/ItemDotStatus";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Collection from "../../../domain/Collection";
import { ISelectItem, ISelectItemType } from "../../status-action-button/StatusActionButton";
import { DeleteCollectionPopup } from "../../project/Collection/DeleteCollectionPopup/DeleteCollectionPopup";
import classNames from "classnames";
import { SharingCollectionPopup } from "../../project/Collection/SharingCollectionPopup/SharingCollectionPopup";
import Breadcrumbs, { BreadcrumbItem } from "../../../components/breadcrumbs/Breadcrumbs";
import TileMenu from "../../../components/tile/components/TileMenu/TileMenu";
import { AddFileToDataPopup } from "../../file/AddFileToDataPopup/AddFileToDataPopup";
import { FileTypeEnum } from "../Type/FileTypeEnum";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import { CollectionShareIcon } from "../../collection/CollectionShareIcon";
import { isEditableProject } from "../../../services/WedApi/Utils/IsEditableProject";
import { SidebarType } from "../../sidebar/Sidebar/Sidebar";
import { CollectionPageButtons } from "./CollectionPageHeader/CollectionPageButtons/CollectionPageButtons";
import CollectionActionButton from "./CollectionPageHeader/CollectionActionButton/CollectionActionButton";
import "./CollectionPage.scss";
import { LogicError } from "../../../errors/error-app";
import SubmenuFactory from "../Submenu/Service/SubmenuFactory";
import { CollectionRouteGenerator } from "./helper/CollectionRouteGenerator";
import { CollectionDotStatusColorItemType, getCollectionDotStatusColor } from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface BasePropsInterface {}

interface PropsInterface extends BasePropsInterface, WrappedComponentProps, RouteComponentProps {
    isTableMode: boolean;
    userId: number;
    fileType: FileTypeEnum;
    projectProps?: ProjectDetailLoaderProps;
}

interface StateInterface {
    createCollectionOpen: boolean;
    deleteCollection: Collection | undefined;
    filterCollections: Collection[];
    addFileToCollectionOpen?: number;
    isLoading: boolean;
    sharingCollection: Collection | undefined;
    selectedItems: ISelectItem[];
    breadcrumbs: BreadcrumbItem[];
    selectedItemBreadcrumb: BreadcrumbItem | undefined;
}

class CollectionPage extends React.Component<PropsInterface, StateInterface> {
    private filters: CollectionFilterInterface;
    private collections: ICollection[];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            createCollectionOpen: false,
            deleteCollection: undefined,
            filterCollections: [],
            isLoading: true,
            sharingCollection: undefined,
            selectedItems: [],
            breadcrumbs: [],
            selectedItemBreadcrumb: undefined,
            addFileToCollectionOpen: undefined,
        };
        this.collections = [];
        this.filters = {};
        this.refreshAfterChanges = this.refreshAfterChanges.bind(this);
        this.refreshAfterSidebarChanges = this.refreshAfterSidebarChanges.bind(this);
        this.handleFiltersChange = this.handleFiltersChange.bind(this);
        this.handleClickOpenCreateCollection = this.handleClickOpenCreateCollection.bind(this);
        this.handleClickOpenDeleteCollection = this.handleClickOpenDeleteCollection.bind(this);
        this.handleClickOpenSharingCollection = this.handleClickOpenSharingCollection.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.successDeleteCollection = this.successDeleteCollection.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleOpenAddFileToCollection = this.handleOpenAddFileToCollection.bind(this);
    }

    async componentDidMount() {
        await this.reloadData();
    }

    private getProjectProps(): ProjectDetailLoaderProps {
        if (this.props.projectProps) {
            return this.props.projectProps;
        }
        throw new LogicError(`CollectionPage not set projectProps, but is required for type ${this.props.fileType}`);
    }

    private generateCollectionLink(collectionId?: number): string {
        return CollectionRouteGenerator.getUrl(this.props.fileType, {
            collectionId,
            projectId: this.props.projectProps?.project.id,
        });
    }

    private async refreshAfterChanges() {
        this.setState({ isLoading: true });
        await this.reloadData();
    }

    private async refreshAfterSidebarChanges() {
        const selectedItems = [...this.state.selectedItems];
        await this.refreshAfterChanges();
        if (selectedItems.length > 0 && selectedItems[0].object) {
            this.handleSelectItem(new Collection(selectedItems[0].object as ICollection));
        }
    }

    private async reloadData() {
        this.collections = await container.collectionService.getCollections(
            this.props.fileType === FileTypeEnum.PROJECT_COLLECTION ? this.getProjectProps().project.id : undefined
        );
        const breadcrumbs: BreadcrumbItem[] = [
            {
                id: 0,
                children: <WedIcon size={SizeType.SMALL} icon={<WedIconItemsGroupElements />} />,
                link: this.generateCollectionLink(),
                subItems: this.collections.map((collection) => ({
                    id: collection.id,
                    label: collection.name,
                    link: this.generateCollectionLink(collection.id),
                })),
            },
        ];

        this.setState({ breadcrumbs });

        this.filterData();
    }

    private async handleChange() {
        await this.refreshAfterChanges();
    }

    private handleFiltersChange(filters: CollectionFilterInterface) {
        this.filters = filters;
        this.filterData();
    }

    private handleClickOpenCreateCollection() {
        this.setState({ createCollectionOpen: true });
    }

    private handleClickOpenDeleteCollection(collection: Collection) {
        this.setState({ deleteCollection: collection });
    }

    private handleClickOpenSharingCollection(collection: Collection) {
        this.setState({ sharingCollection: collection });
    }

    private handleClosePopup() {
        this.setState({
            deleteCollection: undefined,
            createCollectionOpen: false,
            sharingCollection: undefined,
            addFileToCollectionOpen: undefined,
        });
    }

    private handleGoToCollectionFiles = (collectionId: number) => {
        this.goToCollectionFiles(collectionId);
    };

    private filterData() {
        const filterCollections = container.collectionsFilterService.filter(
            this.collections,
            Object.assign({ loggedUserId: this.props.userId }, this.filters)
        );
        this.setState({
            filterCollections: filterCollections.map((collection: ICollection) => {
                return new Collection(collection);
            }),
            isLoading: false,
            selectedItems: [],
        });
    }

    private goToCollectionFiles(collectionId: number) {
        this.props.history.push(this.generateCollectionLink(collectionId));
    }

    private handleSelectItem(item: Collection) {
        let selectedItems = [...this.state.selectedItems];
        let selectedItemBreadcrumb;
        const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.id == item.getId());

        if (selectedItemIndex == -1) {
            const newItem = {
                id: item.getId(),
                userIsOwner: item.userIsOwner(),
                object: item.getData(),
            };
            selectedItems = [newItem];
        } else {
            selectedItems.splice(selectedItemIndex, 1);
        }

        if (selectedItems && selectedItems.length === 1) {
            selectedItemBreadcrumb = {
                title: selectedItems[0].object?.name || "",
            };
        }

        this.setState({ selectedItems, selectedItemBreadcrumb });
    }

    private handleOpenAddFileToCollection(collectionId: number) {
        this.setState({ addFileToCollectionOpen: collectionId });
    }

    private successDeleteCollection(collectionId: number | undefined) {
        if (collectionId) {
            this.collections = container.collectionsFilterService.filterDataWithoutCollectionId(this.collections, collectionId);
            this.filterData();
        }
    }

    private isDisabledSidebar(): boolean {
        if (!isEditableProject(this.props.fileType, this.props.projectProps?.project)) {
            return true;
        }

        const { selectedItems } = this.state;
        if (selectedItems.length === 1) {
            const selectedCollection = this.collections.find((collection) => collection.id === selectedItems[0].id);
            if (selectedCollection && selectedCollection.locked) {
                return true;
            }
            return false;
        }

        return false;
    }

    private getMenuControlIconFileType(): FileTypeEnum {
        if (this.props.fileType === FileTypeEnum.PROJECT_COLLECTION) {
            return FileTypeEnum.PROJECT_FILES;
        }
        if ([FileTypeEnum.MY_LIBRARY_COLLECTIONS, FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES].includes(this.props.fileType)) {
            return FileTypeEnum.MY_LIBRARY_FILES;
        }
        throw new LogicError(`CollectionPage not supported fileType ${this.props.fileType}`);
    }

    isSelected = (id: number) => {
        const { selectedItems } = this.state;

        return !!selectedItems.find((item) => item.id === id);
    };

    render() {
        const { isLoading, selectedItems, selectedItemBreadcrumb } = this.state;
        const firstSelected = container.collectionsFilterService.findById(this.state.filterCollections, selectedItems[0]?.id || 0);
        const oneSelected = selectedItems.length == 1 ? selectedItems[0] : undefined;

        const breadcrumbs = [...this.state?.breadcrumbs] || [];
        if (selectedItemBreadcrumb) {
            breadcrumbs.push(selectedItemBreadcrumb);
        }

        return (
            <div className="collection-page">
                {this.state.createCollectionOpen && (
                    <CreateCollectionPopup
                        projectId={this.props.projectProps?.project.id}
                        open={this.state.createCollectionOpen}
                        handleClose={this.handleClosePopup}
                    />
                )}
                {!!this.state.addFileToCollectionOpen && (
                    <AddFileToDataPopup
                        open={true}
                        contextCollection={{
                            collectionId: this.state.addFileToCollectionOpen,
                            project: this.props.projectProps?.project,
                        }}
                        handleClose={async (updated?: boolean) => {
                            this.handleClosePopup();
                            if (updated) {
                                await this.reloadData();
                            }
                        }}
                    />
                )}
                {this.state.sharingCollection && (
                    <SharingCollectionPopup
                        open={true}
                        project={this.props.projectProps?.project}
                        collection={this.state.sharingCollection}
                        handleClose={this.handleClosePopup}
                        onChange={this.handleChange}
                    />
                )}
                {this.state.deleteCollection && (
                    <DeleteCollectionPopup
                        open={true}
                        collection={this.state.deleteCollection}
                        handleClose={this.handleClosePopup}
                        successDelete={(collectionId) => {
                            this.successDeleteCollection(collectionId);
                        }}
                    />
                )}
                <WedMainMenuContainer
                    menu={SubmenuFactory.getSubmenu(this.props.fileType, this.props.projectProps?.project.id)}
                    buttons={
                        <CollectionPageButtons
                            project={this.props.projectProps?.project}
                            selectedItems={selectedItems}
                            createCollection={this.handleClickOpenCreateCollection}
                            deleteCollection={this.handleClickOpenDeleteCollection}
                            collection={firstSelected}
                            onChange={this.handleChange}
                            goToCollectionFiles={this.handleGoToCollectionFiles}
                            openSharing={this.handleClickOpenSharingCollection}
                            openAddFileToCollection={this.handleOpenAddFileToCollection}
                            fileType={this.props.fileType}
                        />
                    }
                    sidebar={<MenuControlIcons project={this.props.projectProps?.project} fileType={this.getMenuControlIconFileType()} />}
                />
                {isLoading ? (
                    <LinearProgress />
                ) : (
                    <SidebarWrapper
                        handleSidebarChanges={this.refreshAfterSidebarChanges}
                        type={
                            oneSelected
                                ? SidebarType.collection
                                : this.props.fileType !== FileTypeEnum.MY_LIBRARY_COLLECTIONS
                                ? SidebarType.project
                                : undefined
                        }
                        id={
                            oneSelected
                                ? oneSelected.id
                                : this.props.fileType !== FileTypeEnum.MY_LIBRARY_COLLECTIONS
                                ? this.props.projectProps?.project.id
                                : undefined
                        }
                        contextProject={
                            oneSelected && this.props.fileType !== FileTypeEnum.MY_LIBRARY_COLLECTIONS ? this.props.projectProps?.project : undefined
                        }
                        disabled={this.isDisabledSidebar()}
                        fileType={this.props.fileType}
                    >
                        <WedSubMenuContainer
                            left={<Breadcrumbs items={breadcrumbs} selectedItemId={oneSelected ? oneSelected.id : undefined} maxLength={10} />}
                            right={<FilterFormProjectCollection onFiltersChange={this.handleFiltersChange} />}
                        />
                        {this.props.isTableMode ? (
                            <WedTable>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.collectionName" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.owner" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.created" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.locked" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.shared" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.numOfFiles" />
                                        </TableCell>
                                        <TableCell>
                                            <FormattedMessage id="app.collectionList.table.approvalStatus" />
                                        </TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.filterCollections.map((collection: Collection, i) => (
                                        <TableRow
                                            className={classNames({
                                                "table-row--active": this.isSelected(collection.getId()),
                                            })}
                                            hover
                                            role="checkbox"
                                            key={i}
                                            onClick={() => {
                                                this.handleSelectItem(collection);
                                            }}
                                            onDoubleClick={() => {
                                                this.goToCollectionFiles(collection.getId());
                                            }}
                                        >
                                            <TableCell />
                                            <TableCell>{collection.getName()}</TableCell>
                                            <TableCell>{collection.getAuthor()}</TableCell>
                                            <TableCell>
                                                <WedDate date={collection.getCreatedDate()} />
                                            </TableCell>
                                            <TableCell>
                                                {collection.isLocked() ? (
                                                    <WedIcon size={SizeType.SMALL} icon={<WedIconLock3 />} />
                                                ) : (
                                                    <WedIcon size={SizeType.SMALL} icon={<WedIconLockUnlock />} />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <CollectionShareIcon collection={collection} size={SizeType.SMALL} />
                                            </TableCell>
                                            <TableCell>{collection.getFilesCount()}</TableCell>
                                            <TableCell>
                                                <ItemDotStatus
                                                    color={getCollectionDotStatusColor(
                                                        CollectionDotStatusColorItemType.COLLECTION,
                                                        collection.getData()
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <CollectionActionButton
                                                    project={this.props.projectProps?.project}
                                                    icon={<WedIcon size={SizeType.SMALL} icon={<WedIconDots />} />}
                                                    selectedItems={[
                                                        {
                                                            id: collection.getId(),
                                                            type: ISelectItemType.collection,
                                                            userIsOwner: collection.userIsOwner(),
                                                            object: collection.getData(),
                                                        },
                                                    ]}
                                                    collection={collection}
                                                    onChange={this.handleChange}
                                                    goToCollectionFiles={this.handleGoToCollectionFiles}
                                                    openSharing={this.handleClickOpenSharingCollection}
                                                    deleteCollection={this.handleClickOpenDeleteCollection}
                                                    openAddFileToCollection={this.handleOpenAddFileToCollection}
                                                    fileType={this.props.fileType}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </WedTable>
                        ) : (
                            <GridTiles>
                                {this.state.filterCollections.map((collection, i) => (
                                    <CollectionTile
                                        key={i}
                                        collection={collection}
                                        fileType={this.props.fileType}
                                        projectId={this.props.projectProps?.project.id}
                                        selected={this.isSelected(collection.getId())}
                                        onClick={() => {
                                            this.handleSelectItem(collection);
                                        }}
                                        actionButton={
                                            <CollectionActionButton
                                                project={this.props.projectProps?.project}
                                                icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                                selectedItems={[
                                                    {
                                                        id: collection.getId(),
                                                        type: ISelectItemType.collection,
                                                        userIsOwner: collection.userIsOwner(),
                                                        object: collection.getData(),
                                                    },
                                                ]}
                                                collection={collection}
                                                onChange={this.handleChange}
                                                goToCollectionFiles={this.handleGoToCollectionFiles}
                                                openSharing={this.handleClickOpenSharingCollection}
                                                deleteCollection={this.handleClickOpenDeleteCollection}
                                                openAddFileToCollection={this.handleOpenAddFileToCollection}
                                                fileType={this.props.fileType}
                                            />
                                        }
                                    />
                                ))}
                                {isEditableProject(this.props.fileType, this.props.projectProps?.project) && (
                                    <ActionTile
                                        className="collection-tile__create-folder"
                                        title={this.props.intl.formatMessage({ id: "app.tile.createCollection" })}
                                        onClick={this.handleClickOpenCreateCollection}
                                    />
                                )}
                            </GridTiles>
                        )}
                    </SidebarWrapper>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store: ApplicationState, props: BasePropsInterface) => ({
    ...props,
    isTableMode: store.user.viewMode === IUserViewModeEnum.table,
    userId: store.user.data?.id as number,
});

export default connect(mapStateToProps)(withRouter(injectIntl(CollectionPage)));
