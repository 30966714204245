export class AppError extends Error {}

export class AppNoticeError extends Error {}

export class ApiResultError extends Error {}

export class LogicError extends Error {}

export class AppNotFoundItemError extends AppError {
    constructor(private readonly type: string, private readonly id?: number | string, message?: string) {
        super(message);
    }
}
