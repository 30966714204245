import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { getScrollbarIconYStyle, getScrollbarYStyle } from "../../../services/WedApi/Utils/ScrollbarsStyles";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

class SidebarScrollbars extends React.Component<PropsInterface> {
    render() {
        return (
            <Scrollbars
                universal
                renderTrackVertical={(props) => {
                    return React.createElement("div", {
                        ...props,
                        style: getScrollbarYStyle(),
                    });
                }}
                renderThumbVertical={(props) => {
                    return React.createElement("div", {
                        ...props,
                        style: getScrollbarIconYStyle(),
                    });
                }}
            >
                <div className="sidebar-scrollbars--body">{this.props.children}</div>
            </Scrollbars>
        );
    }
}

export default SidebarScrollbars;
