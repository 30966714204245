import React, { Component } from "react";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IUserTeamDetail } from "../../../services/WedApi/Models/User";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import UserTeamMenu from "../../../components/main-menu/UserTeamMenu/UserTeamMenu";
import { UserTeamDetailLoaderProps } from "../../../components/user-team/UserTeamDetailLoader/UserTeamDetailLoader";
import ProfileWithProjects from "../../../components/template/ProfileWithProjects/ProfileWithProjects";
import UserTeamTile from "../../../components/tile/UserTeamTile/UserTeamTile";
import { FormattedMessage } from "react-intl";
import container from "../../../container";
import UserTeamUserFileList from "../UserTeamUserFileList/UserTeamUserFileList";
import { IFile } from "../../../services/WedApi/Models/File";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import UserTeamUserProfileButtons from "./UserTeamUserProfileButtons/UserTeamUserProfileButtons";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { AddFileToDataPopup } from "../../../components/file/AddFileToDataPopup/AddFileToDataPopup";
import "./UserTeamUserProfilePage.scss";

interface BasePropsInterface extends UserTeamDetailLoaderProps {}

interface PropsInterface extends BasePropsInterface, RouteComponentProps {
    isMe: boolean;
}

interface StateInterface {
    isLoading: boolean;
    userTeam: IUserTeamDetail;
    projectWorks: IFile[];
    privatePortfolio: IFile[];
    isPrivatePortfolio: boolean;
    addPrivatePortfolio: boolean;
}

class UserTeamUserProfilePage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            userTeam: props.userTeam,
            projectWorks: [],
            privatePortfolio: [],
            isPrivatePortfolio: false,
            addPrivatePortfolio: false,
        };
        this.setIsLoading = this.setIsLoading.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.closeAddFilePopup = this.closeAddFilePopup.bind(this);
        this.openAddFilePopup = this.openAddFilePopup.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        const [userTeam, projectWorks, privatePortfolio] = await Promise.all([
            container.userService.populateCountersUserDetail(this.props.userTeam),
            container.userService.getProjectWorks(this.props.userTeam.id),
            container.userService.getPrivatePortfolio(this.props.userTeam.id),
        ]);

        this.setState({
            userTeam,
            projectWorks,
            privatePortfolio,
            isLoading: false,
        });
    }

    private setIsLoading() {
        this.setState({ isLoading: true });
    }

    private onChangeTab(tabId: string | null) {
        this.setState({
            isPrivatePortfolio: tabId === "private-portfolio",
        });
    }

    private openAddFilePopup() {
        this.setState({
            addPrivatePortfolio: true,
        });
    }

    private closeAddFilePopup() {
        this.setState({
            addPrivatePortfolio: false,
        });
    }

    private async refreshPage() {
        this.setIsLoading();
        await this.fetchData();
    }

    render() {
        return (
            <div className="user-team-user-profile-page">
                <WedMainMenuContainer
                    menu={<UserTeamMenu userTeamId={this.state.userTeam.id} activeTab="user-profile" />}
                    buttons={
                        <UserTeamUserProfileButtons
                            isMe={this.props.isMe && this.state.isPrivatePortfolio}
                            openAddFilePopup={this.openAddFilePopup}
                        />
                    }
                    sidebar={<MenuControlIcons noDisplayTrash={true} disableSidebarOpen={true} disableChangeViewMode={true} />}
                />
                {this.state.isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className="user-team-user-profile-page--container">
                        <ProfileWithProjects
                            onChangeTab={this.onChangeTab}
                            profile={<UserTeamTile withInitialsColor={true} disableHover={true} userTeam={this.state.userTeam} />}
                            projects={[
                                {
                                    id: "project-work",
                                    label: <FormattedMessage id="app.UserTeamUserProfilePage.projectWork" />,
                                    content: (
                                        <div className="user-team-user-profile-page--file-list-container">
                                            <UserTeamUserFileList
                                                userTeamId={this.props.userTeam.id}
                                                fileType={FileTypeEnum.TEAM_USER_PROJECT_WORK}
                                                files={this.state.projectWorks}
                                            />
                                        </div>
                                    ),
                                },
                                {
                                    id: "private-portfolio",
                                    label: <FormattedMessage id="app.UserTeamUserProfilePage.privatePortfolio" />,
                                    content: (
                                        <div className="user-team-user-profile-page--file-list-container">
                                            <UserTeamUserFileList
                                                isMe={this.props.isMe}
                                                userTeamId={this.props.userTeam.id}
                                                fileType={FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO}
                                                files={this.state.privatePortfolio}
                                                refreshPage={this.refreshPage}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    </div>
                )}
                {this.props.isMe && this.state.isPrivatePortfolio && (
                    <AddFileToDataPopup
                        open={this.state.addPrivatePortfolio}
                        contextMyPrivatePortfolio={{ active: true }}
                        handleClose={async (updated?: boolean) => {
                            this.closeAddFilePopup();
                            if (updated) {
                                this.setState({ isLoading: true });
                                await this.fetchData();
                            }
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        isMe: !!props.userTeam?.id && state.user.data?.id === props.userTeam?.id,
    };
};

export default connect(mapStateToProps)(withRouter(UserTeamUserProfilePage));
