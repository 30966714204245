import React, { Component } from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { Checkbox, Link, TextField } from "@material-ui/core";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import NotLoggedFormPage from "../../../components/template/NotLoggedFormPage/NotLoggedFormPage";
import "./RegisterPage.scss";
import container from "../../../container";
import { getEmailSchemaValidator } from "../../../services/JSONSchemaValidator/SchemaValidators";
import { PASSWORD_PATTERN } from "../../../services/WedApi/Utils/Password";
import { UserRegisterTermsAndConditionsPopup } from "../../../components/user/UserRegisterTermsAndConditionsPopup/UserRegisterTermsAndConditionsPopup";

interface PropsInterface extends ProviderContext, WrappedComponentProps {}

interface StateInterface {
    success: boolean;
    fields: {
        firstName: string;
        lastName: string;
        email: string;
        email2: string;
        password: string;
        password2: string;
        tc: boolean;
    };
    termsAndConditionsPopup: boolean;
}

const CAPTCHA_ACTION = "register";

class RegisterPage extends Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            firstName: {
                type: "string",
                minLength: 2,
                maxLength: 30,
            },
            lastName: {
                type: "string",
                minLength: 2,
                maxLength: 50,
            },
            email: getEmailSchemaValidator(),
            email2: {
                type: "string",
            },
            password: {
                type: "string",
                pattern: PASSWORD_PATTERN,
            },
            password2: {
                type: "string",
            },
            tc: {
                type: "boolean",
            },
        },
        required: ["firstName", "lastName", "email", "email2", "password", "password2", "tc"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                firstName: "app.RegisterPage.errorFirstName",
                lastName: "app.RegisterPage.errorFastName",
                email: "app.RegisterPage.errorEmail",
                email2: "app.RegisterPage.errorEmail2",
                password: "app.RegisterPage.errorPassword",
                password2: "app.RegisterPage.errorPassword2",
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            success: false,
            fields: {
                firstName: "",
                lastName: "",
                email: "",
                email2: "",
                password: "",
                password2: "",
                tc: false,
            },
            termsAndConditionsPopup: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // eslint-disable-next-line
    private async handleSubmit(_: any, token: string | undefined) {
        if (this.state.fields.email !== this.state.fields.email2) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.RegisterPage.errorEmail2" }), { variant: "error" });
            return;
        }
        if (this.state.fields.password !== this.state.fields.password2) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.RegisterPage.errorPassword2" }), { variant: "error" });
            return;
        }
        if (!this.state.fields.tc) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.RegisterPage.errorTC" }), { variant: "error" });
            return;
        }

        try {
            await container.userService.register(
                token ? { recaptcha_token: token, action: CAPTCHA_ACTION } : undefined,
                this.state.fields.email,
                this.state.fields.password,
                this.state.fields.firstName,
                this.state.fields.lastName
            );
            this.setState({ success: true });
        } catch (e) {
            let keyTranslate = "app.global.internalError";
            if (e.code === FrontApiErrorCodeEnum.ERR_USER_SIGNUP_FORM_INVALID) {
                keyTranslate = "app.RegisterPage.errorUserSignupFormInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_NAME_INVALID) {
                keyTranslate = "app.RegisterPage.errorUserNameInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_EMAIL_INVALID) {
                keyTranslate = "app.RegisterPage.errorUserEmalInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_PASSWORD_INVALID) {
                keyTranslate = "app.RegisterPage.errorPasswordInvalid";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_USER_EMAIL_USED) {
                keyTranslate = "app.RegisterPage.errorUserEmailUsed";
            } else if (e.code === FrontApiErrorCodeEnum.ERR_CAPTCHA_VERIFIED) {
                keyTranslate = "app.global.captchaVerifiedError";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }
    }

    private async handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = event.target;
        if (name === "tc") {
            this.setState({
                fields: {
                    ...this.state.fields,
                    tc: !this.state.fields.tc,
                },
            });
        } else {
            this.setState({
                fields: {
                    ...this.state.fields,
                    [name]: value,
                },
            });
        }
    }

    render() {
        return (
            <NotLoggedFormPage
                maxWidth="lg"
                className="register-page"
                handleSubmit={this.handleSubmit}
                labelSubmit={"app.RegisterPage.register"}
                header={<FormattedMessage id={"app.RegisterPage.header"} />}
                withLogo={true}
                formData={this.state.fields}
                formSchema={this.schema}
                noDisplayButtons={this.state.success}
                withCaptcha={CAPTCHA_ACTION}
            >
                {this.state.success ? (
                    <div className="not-logged-popup-page--text-container">
                        {this.props.intl.formatMessage({ id: "app.RegisterPage.successMessage" })}
                    </div>
                ) : (
                    <>
                        <div className="not-logged-form-page--form-row">
                            <div className="not-logged-form-page--form-row-column50">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.firstName" })}
                                    name="firstName"
                                    autoComplete="firstName"
                                    autoFocus
                                    value={this.state.fields.firstName}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="not-logged-form-page--form-row-column50">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="lastName"
                                    label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.lastName" })}
                                    id="lastName"
                                    autoComplete="lastName"
                                    value={this.state.fields.lastName}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.email" })}
                            id="email"
                            autoComplete="email"
                            value={this.state.fields.email}
                            onChange={this.handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="email2"
                            label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.email2" })}
                            id="email2"
                            autoComplete="email2"
                            value={this.state.fields.email2}
                            onChange={this.handleChange}
                        />
                        <div className="not-logged-form-page--form-row">
                            <div className="not-logged-form-page--form-row-column50">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.password" })}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={this.state.fields.password}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="not-logged-form-page--form-row-column50">
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password2"
                                    label={this.props.intl.formatMessage({ id: "app.RegisterPage.label.password2" })}
                                    type="password"
                                    id="password2"
                                    autoComplete="current-password2"
                                    value={this.state.fields.password2}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="register-page--input-tc">
                            <Checkbox required name="tc" id="tc" checked={this.state.fields.tc} onChange={this.handleChange} />
                            <FormattedMessage id={"app.RegisterPage.label.tc"} />
                            <Link
                                className="register-page--label-tc"
                                onClick={() => {
                                    this.setState({ termsAndConditionsPopup: true });
                                }}
                            >
                                <FormattedMessage id={"app.RegisterPage.label.tcText"} />
                            </Link>
                        </div>
                        <UserRegisterTermsAndConditionsPopup
                            open={this.state.termsAndConditionsPopup}
                            handleClose={() => {
                                this.setState({ termsAndConditionsPopup: false });
                            }}
                            handleSuccess={() => {
                                this.setState({
                                    termsAndConditionsPopup: false,
                                    fields: {
                                        ...this.state.fields,
                                        tc: true,
                                    },
                                });
                            }}
                        />
                    </>
                )}
            </NotLoggedFormPage>
        );
    }
}

export default withSnackbar(injectIntl(RegisterPage));
