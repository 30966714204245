import container from "../../../../../../container";
import DataFilesInterface from "../DataFilesInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";
import { IFile } from "../../../../../../services/WedApi/Models/File";
import UserTeamAbstractFilesData from "./UserTeamAbstractFilesData";

export default class UserTeamProjectWorkFilesData extends UserTeamAbstractFilesData implements DataFilesInterface {
    protected getFileTypeEnum(): FileTypeEnum {
        return FileTypeEnum.TEAM_USER_PROJECT_WORK;
    }

    protected getRoutesEnum(): RoutesEnum {
        return RoutesEnum.USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PROJECT_WORK;
    }

    protected getBackRoutesHashParams(): string | undefined {
        return "project-work";
    }

    protected getFiles(userTeamId: number): Promise<IFile[]> {
        return container.userService.getProjectWorks(userTeamId);
    }
}
