import React from "react";
import { TransformComponent } from "react-zoom-pan-pinch";
import "./WedMediaGalleryImage.scss";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { INoteAnnotation } from "../../../services/WedApi/Models/Note";
import "./WedMediaGalleryImageTransformComponent.scss";

interface BasePropsInterface {
    link: string;
    onWrapperImageLoad?: () => void;
    onError?: () => void;
}

interface PropsInterface extends BasePropsInterface {
    annotation?: INoteAnnotation;
}

interface StateInterface {}

const WED_MEDIA_GALLERY_ANNOTATION_RECT_ID = "wed-media-gallery-image-transform-component--rect";

class WedMediaGalleryImageTransformComponent extends React.Component<PropsInterface, StateInterface> {
    // eslint-disable-next-line
    private drawRegisterTimer: any;

    constructor(props: PropsInterface) {
        super(props);
        this.onWrapperImageLoad = this.onWrapperImageLoad.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(prevProps.annotation, this.props.annotation)) {
            if (this.props.annotation) {
                this.drawNewRect(this.props.annotation);
            } else {
                this.removeNewRect();
            }
        }
    }

    private drawNewRect(annotation: INoteAnnotation) {
        this.removeNewRect();
        try {
            clearTimeout(this.drawRegisterTimer);
        } catch (e) {
            //
        }
        this.drawRegisterTimer = setTimeout(() => {
            this.drawAnnotation(annotation);
        }, 50);
    }

    private removeNewRect() {
        const doc = document.getElementById(WED_MEDIA_GALLERY_ANNOTATION_RECT_ID);
        if (doc) {
            doc.innerHTML = "";
        }
    }

    private drawAnnotation(annotation: INoteAnnotation) {
        const doc = document.getElementById(WED_MEDIA_GALLERY_ANNOTATION_RECT_ID);
        if (doc) {
            const html =
                `<svg style="width:${annotation.width}px;height:${annotation.height}px;margin-left:${annotation.x}px;margin-top:${annotation.y}px" ` +
                `width="${annotation.width}" height="${annotation.height}" ` +
                `viewBox="0 0 ${annotation.width} ${annotation.height}" ` +
                `xmlns="http://www.w3.org/2000/svg">` +
                `<rect width="${annotation.width}" height="${annotation.height}" x="0" y="0" />` +
                `</svg>`;
            doc.innerHTML = html;
        }
    }

    onWrapperImageLoad() {
        this.props.onWrapperImageLoad && this.props.onWrapperImageLoad();
    }

    onError() {
        this.props.onError && this.props.onError();
    }

    render() {
        const { link } = this.props;

        return (
            <TransformComponent wrapperStyle={{ width: "100%", height: "100%" }}>
                <div id={WED_MEDIA_GALLERY_ANNOTATION_RECT_ID} />
                <img id="wed-media-gallery-image" src={link} alt="" onLoad={this.onWrapperImageLoad} onError={this.onError} />
            </TransformComponent>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        ...{ annotation: state.wedImageGalleryData.annotation },
    };
};

export default connect(mapStateToProps)(WedMediaGalleryImageTransformComponent);
