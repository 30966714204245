import React from "react";
import { INote, NoteObject, NoteObjectType } from "../../../services/WedApi/Models/Note";
import { FormattedMessage } from "react-intl";
import CommentForm from "../CommentForm/CommentForm";
import container from "../../../container";
import CommentThread from "../CommentThread/CommentThread";
import isEqual from "lodash/isEqual";
import { LogicError } from "../../../errors/error-app";
import "./CommentBox.scss";

interface PropsInterface {
    token?: string;
    disabled?: boolean;
    onUpdateNotes?: (notes: INote[]) => void;
    object: NoteObject;
    notes: INote[];
}

interface StateInterface {
    notes: INote[];
}

class CommentBox extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            notes: this.props.notes,
        };
        this.reload = this.reload.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(prevProps.object, this.props.object)) {
            this.setState({ notes: this.props.notes });
        }
    }

    private async fetchNotes(): Promise<INote[]> {
        if (this.props.token) {
            return this.fetchNotesByToken(this.props.token);
        }
        switch (this.props.object.type) {
            case NoteObjectType.file:
                if (this.props.object.data?.collectionId) {
                    return container.noteService.getCollectionFileNotes(
                        this.props.object.id,
                        this.props.object.data.collectionId,
                        this.props.object.data.clientId
                    );
                }
                return container.noteService.getFileNotes(this.props.object.id);
            case NoteObjectType.project:
                return container.noteService.getProjectNotes(this.props.object.id);
            case NoteObjectType.collection:
                return container.noteService.getCollectionNotes(this.props.object.id, this.props.object.data?.clientId);
        }
        throw new LogicError(`Not supported get comments for object type: ${this.props.object.type}`);
    }

    private async fetchNotesByToken(token: string): Promise<INote[]> {
        if (this.props.object.type === NoteObjectType.collection) {
            const data = await container.shareCollectionService.getCollectionDetails(token, this.props.object.id);
            return data.publicNotes;
        } else if (this.props.object.type === NoteObjectType.file && this.props.object.data?.collectionId) {
            const data = await container.shareCollectionService.getCollectionDetails(token, this.props.object.data.collectionId);
            const fileData = data.files.find((file) => file.id === this.props.object.id);
            return fileData?.publicNotes || [];
        } else {
            throw new LogicError(`Not supported for shared object type ${this.props.object.type}`);
        }
    }

    private async reload() {
        const notes = await this.fetchNotes();
        if (this.props.onUpdateNotes) {
            this.props.onUpdateNotes(notes);
        }
        this.setState({ notes });
    }

    private filterByVersion(notes: INote[], versionId?: number): INote[] {
        if (this.props.object.type !== NoteObjectType.file) {
            return notes;
        }
        if (!versionId) {
            return [];
        }
        return notes
            .filter((note) => note.versionId === versionId)
            .map((note) => {
                if (note.items.length > 0) {
                    return {
                        ...note,
                        items: this.filterByVersion(note.items, versionId),
                    };
                }
                return note;
            });
    }

    render() {
        return (
            <div className="comment-box">
                <div className="comment-box--header">
                    <div className="comment-box--header-text">
                        <FormattedMessage id={"app.CommentBox.header"} />
                    </div>
                </div>
                <div className="comment-box--items">
                    {this.filterByVersion(this.state.notes, this.props.object.data?.fileVersionId).map((note, i) => (
                        <CommentThread
                            token={this.props.token}
                            disabled={this.props.disabled}
                            key={i}
                            object={this.props.object}
                            note={note}
                            reload={this.reload}
                        />
                    ))}
                </div>
                <CommentForm token={this.props.token} disabled={this.props.disabled} object={this.props.object} reload={this.reload} />
            </div>
        );
    }
}

export default CommentBox;
