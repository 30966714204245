import React from "react";
import classnames from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./separator.scss";
import { findBeforeSibling, findParentNode } from "../../../../services/NativeHtml/GetParentNode";
import { DropDownFolderListMenu } from "../../../drop-down-menu/FolderListMenu/FolderListMenu";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface SeparatorItem {
    label: string;
    link?: string;
    click?: () => void;
    id: number;
}

export interface SeparatorParentItems {
    items: SeparatorItem[];
    parentId: number;
}

interface PropsInterface extends RouteComponentProps {
    selectedItemId?: number;
    items: SeparatorParentItems[];
}

interface StateInterface {
    showMenu: boolean;
    position: DropdownPosition;
    items: SeparatorItem[];
    separatorRef?: React.RefObject<HTMLSpanElement>;
}

export enum DropdownPosition {
    LEFT = "left",
    RIGHT = "right",
}

class Separator extends React.Component<PropsInterface, StateInterface> {
    private dropdownRef = React.createRef<HTMLDivElement>();
    private separatorRef = React.createRef<HTMLSpanElement>();

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            showMenu: false,
            position: DropdownPosition.RIGHT,
            items: [],
            separatorRef: this.separatorRef,
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleClickOutside);
        this.checkActiveStatusClassNames();
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>, prevState: Readonly<StateInterface>) {
        if (prevState.separatorRef !== this.separatorRef) {
            this.checkActiveStatusClassNames();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleClickOutside);
    }

    checkActiveStatusClassNames() {
        if (this.separatorRef && this.separatorRef.current) {
            const breadCrumbId = this.findBreadCrumbItemId(this.separatorRef.current);
            if (breadCrumbId) {
                const itemData = this.props.items.find((item) => item.parentId === breadCrumbId);
                if (itemData && itemData.items.length > 0) {
                    return;
                }
            }
            if (this.separatorRef.current.className.toString().indexOf("breadcrumb-separator--disabled") === -1) {
                this.separatorRef.current.className = this.separatorRef.current.className.toString() + " breadcrumb-separator--disabled";
            }
        }
    }

    isDropdownPositionOK() {
        const right = this.dropdownRef.current?.getBoundingClientRect().right;
        const windowWidth = window.innerWidth;

        return right && right < windowWidth;
    }

    setDropdownPosition(position: DropdownPosition) {
        this.setState({
            ...this.state,
            position,
        });
    }

    findBreadCrumbItemId(item: HTMLSpanElement): number | undefined {
        const itemNode = findParentNode(item, "li");
        if (!itemNode) {
            return undefined;
        }
        const beforeItem = findBeforeSibling(itemNode, "li");
        if (!beforeItem) {
            return undefined;
        }
        const linkItems = beforeItem.getElementsByTagName("a");
        if (linkItems.length === 0) {
            return undefined;
        }
        if (!linkItems[0].id) {
            return undefined;
        }
        const linkItemMatch = linkItems[0].id.toString().match(/^breadcrumb-item-id-([0-9]+)$/);
        if (!linkItemMatch) {
            return undefined;
        }
        return parseInt(linkItemMatch[1]);
    }

    handleClick(item: HTMLSpanElement) {
        const { showMenu } = this.state;
        const breadCrumbId = this.findBreadCrumbItemId(item);
        const subItems: SeparatorItem[] = this.props.items
            .filter((item) => item.parentId === breadCrumbId && item.items.length > 0)
            .reduce((items: SeparatorItem[], item: SeparatorParentItems) => {
                return [...items, ...item.items.filter((item) => !this.props.selectedItemId || item.id !== this.props.selectedItemId)];
            }, [] as SeparatorItem[]);

        this.setState(
            {
                ...this.state,
                showMenu: subItems.length === 0 ? false : !showMenu,
                items: subItems,
            },
            () => {
                if (!showMenu) {
                    if (!this.isDropdownPositionOK()) {
                        this.setDropdownPosition(DropdownPosition.LEFT);
                    }
                } else {
                    this.setDropdownPosition(DropdownPosition.RIGHT);
                }
            }
        );
    }

    handleClickOutside() {
        this.setState({
            ...this.state,
            showMenu: false,
        });
    }

    render() {
        const { showMenu, position, items } = this.state;
        return (
            <ClickAwayListener onClickAway={this.handleClickOutside}>
                <span
                    onClick={(event) => {
                        this.handleClick(event.currentTarget);
                    }}
                    className={classnames("breadcrumb-separator", { "breadcrumb-separator--open": showMenu })}
                    ref={this.separatorRef}
                >
                    <div
                        ref={this.dropdownRef}
                        className={classnames(
                            "breadcrumb-separator__menu",
                            { "breadcrumb-separator__menu--active": showMenu },
                            { [`breadcrumb-separator__menu--${position}`]: true }
                        )}
                    >
                        <DropDownFolderListMenu
                            items={items.map((item) => ({
                                label: item.label,
                                click: (event) => {
                                    event.preventDefault();
                                    if (item.click) {
                                        item.click();
                                    } else if (item.link) {
                                        this.props.history.push(item.link);
                                    }
                                },
                            }))}
                        />
                    </div>
                </span>
            </ClickAwayListener>
        );
    }
}

export default withRouter(Separator);
