import React from "react";
import Popup from "../../../popup/Popup/Popup";
import PopupContent from "../../../popup/PopupContent/PopupContent";
import CreateProject from "../CreateProject/CreateProject";
import { IClientSimple } from "../../../../services/WedApi/Models/Client";

interface PropsInterface {
    customerAccount?: IClientSimple;
    open: boolean;
    handleClose: () => void;
}

interface StateInterface {}

export class CreateProjectPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose} maxWidth="sm">
                <PopupContent>
                    <CreateProject close={this.props.handleClose} customerAccount={this.props.customerAccount} />
                </PopupContent>
            </Popup>
        );
    }
}
