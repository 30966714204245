import React from "react";
import { FormattedMessage } from "react-intl";
import { TextField } from "@material-ui/core";
import InformationSummary from "../../../summary/InformationSummary/InformationSummary";
import { IClient, IClientAddress } from "../../../../services/WedApi/Models/Client";
import { ICountry } from "../../../../services/WedApi/Models/Country";
import ClientInformationSummaryViewDetails from "../ClientInformationSummaryViewDetails/ClientInformationSummaryViewDetails";

interface PropsInterface {
    className?: string;
    client: IClient;
    clientAddress: IClientAddress;
    clientCountry?: ICountry;
}

interface StateInterface {}

class ClientInformationSummary extends React.Component<PropsInterface, StateInterface> {
    render() {
        const address = [
            this.props.clientAddress.streetAddress1,
            this.props.clientAddress.streetAddress2,
            [this.props.clientAddress.zipCode, this.props.clientAddress.city].filter((value) => !!value && value.length > 0).join(" "),
            [this.props.clientAddress.state, this.props.clientCountry?.countryName].filter((value) => !!value && value.length > 0).join(" "),
        ]
            .filter((value) => !!value && value.length > 0)
            .join("\n");

        return (
            <InformationSummary
                className={this.props.className}
                header={<FormattedMessage id={"app.ClientInformationSummary.header"} />}
                leftSide={
                    <>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={<FormattedMessage id={"app.ClientInformationSummary.label.accountName"} />}
                            value={this.props.client.name || " "}
                            inputProps={{ readOnly: true }}
                            disabled={true}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            multiline={true}
                            rows={5}
                            fullWidth
                            label={<FormattedMessage id={"app.ClientInformationSummary.label.address"} />}
                            value={address || " "}
                            inputProps={{ readOnly: true }}
                            disabled={true}
                        />
                    </>
                }
                rightSide={<ClientInformationSummaryViewDetails client={this.props.client} clientAddress={this.props.clientAddress} />}
                footer={
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="renameCollection"
                        label={<FormattedMessage id={"app.ClientInformationSummary.label.description"} />}
                        autoComplete="off"
                        value={this.props.clientAddress.desc || " "}
                        multiline
                        inputProps={{ readOnly: true }}
                        rows={7}
                        disabled={true}
                    />
                }
            />
        );
    }
}

export default ClientInformationSummary;
