import { PropsInterface } from "../../../../FilesPage/FilesPage";
import container from "../../../../../../container";
import FetchDataReturnInterface from "./FetchDataReturnInterface";
import DataFilesInterface from "../DataFilesInterface";
import { generatePath } from "react-router-dom";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";
import OpenFileParamsInterface from "./ParamsInterface/OpenFileParamsInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import { BreadcrumbItem } from "../../../../../breadcrumbs/Breadcrumbs";
import { SizeType, WedIcon } from "../../../../../wed-icon/WedIcon";
import { WedIconItemsGroupElements } from "../../../../../wed-icon/generated/WedIconSvg";
import React from "react";
import BreadcrumbsParamsInterface from "./ParamsInterface/BreadcrumbsParamsInterface";
import { IProject, IProjectExtended } from "../../../../../../services/WedApi/Models/Project";
import OpenSectionParamsInterface from "./ParamsInterface/OpenSectionParamsInterface";
import { isEditableProject } from "../../../../../../services/WedApi/Utils/IsEditableProject";
import { LogicError } from "../../../../../../errors/error-app";

export default class ProjectCollectionFilesData implements DataFilesInterface {
    async fetch(props: PropsInterface): Promise<FetchDataReturnInterface> {
        if (!props.project) {
            throw new LogicError(`Project is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }

        if (!props.collectionId) {
            throw new LogicError(`CollectionId is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }

        const [collections, collection] = await Promise.all([
            container.collectionService.getCollections(props.project.id),
            container.collectionService.getCollectionDetail(props.collectionId),
        ]);

        return {
            isLoading: false,
            directories: [],
            files: collection.files,
            collection: {
                item: collection,
                items: collections,
            },
            dir: undefined,
        };
    }

    getFolder(): Promise<FetchDataReturnInterface> {
        throw new LogicError(`Not supported method for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
    }

    didUpdate(prevProps: PropsInterface, currentProps: PropsInterface): boolean {
        return currentProps.collectionId !== prevProps.collectionId;
    }

    openFile(params: OpenFileParamsInterface): string {
        if (!params.collectionId) {
            throw new LogicError(`collectionId is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }
        if (!params.projectId) {
            throw new LogicError(`projectId is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }

        return generatePath(RoutesEnum.COLLECTION_FILES_MEDIA_GALLERY_DETAILS, {
            projectId: params.projectId,
            collectionId: params.collectionId,
            objectId: params.objectId,
        });
    }

    breadcrumbs(breadcrumbsParams: BreadcrumbsParamsInterface): BreadcrumbItem[] {
        if (!breadcrumbsParams.project) {
            throw new LogicError(`project is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }
        if (!breadcrumbsParams.collection) {
            throw new LogicError(`collection is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }
        if (!breadcrumbsParams.collection) {
            throw new LogicError(`collection is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }

        const currentCollection = breadcrumbsParams.collection.item;
        const projectId = breadcrumbsParams.project.id;

        return [
            {
                children: <WedIcon size={SizeType.SMALL} icon={<WedIconItemsGroupElements />} />,
                link: `/projects/${projectId}/collections`,
                id: 0,
                subItems: breadcrumbsParams.collection.items
                    .filter((collection) => collection.id !== currentCollection.id)
                    .map((collection) => {
                        return {
                            id: collection.id,
                            label: collection.name,
                            click: () => {
                                const link = generatePath(RoutesEnum.COLLECTION_FILES, {
                                    projectId: projectId,
                                    collectionId: collection.id,
                                });
                                container.history.push(link);
                            },
                        };
                    }),
            },
            {
                id: currentCollection.id,
                title: currentCollection.name,
                children: undefined,
                link: generatePath(RoutesEnum.COLLECTION_FILES, {
                    projectId: projectId,
                    collectionId: currentCollection.id,
                }),
            },
        ];
    }

    openSection(params: OpenSectionParamsInterface): string {
        if (!params.collectionId) {
            throw new LogicError(`collectionId is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }
        if (!params.projectId) {
            throw new LogicError(`projectId is required for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
        }

        return generatePath(RoutesEnum.COLLECTION_FILES, {
            projectId: params.projectId,
            collectionId: params.collectionId,
        });
    }

    getRootDirName(project?: IProjectExtended): string {
        if (!project) {
            throw new LogicError(`project is required for type: ${FileTypeEnum.PROJECT_FILES}`);
        }

        return project.name;
    }

    showTrash(project?: IProject): boolean {
        return isEditableProject(FileTypeEnum.PROJECT_COLLECTION_FILES, project);
    }
}
