import React from "react";
import { Subtract } from "utility-types";
import _ from "lodash";

export interface InjectedCtrlKeyDetectorProps {
    ctrlKeyPressed: boolean;
}

interface StateInterface {
    ctrlKeyPressed: boolean;
}

export function withCtrlKeyDetector<T extends InjectedCtrlKeyDetectorProps>(WrappedComponent: React.ComponentType<T>) {
    class CtrlKeyDetector extends React.Component<Subtract<T, InjectedCtrlKeyDetectorProps>, StateInterface> {
        private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["dispatch"];

        shouldComponentUpdate(nextProps: Subtract<T, InjectedCtrlKeyDetectorProps>, nextState: StateInterface): boolean {
            return (
                !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
                !_.isEqual(nextState, this.state)
            );
        }

        state: StateInterface = {
            ctrlKeyPressed: false,
        };

        async componentDidMount() {
            document.addEventListener("keydown", this.handleKeyEvent, false);
            document.addEventListener("keyup", this.handleKeyEvent, false);
        }

        componentWillUnmount() {
            document.removeEventListener("keydown", this.handleKeyEvent, false);
            document.removeEventListener("keyup", this.handleKeyEvent, false);
        }

        handleKeyEvent = (event: KeyboardEvent) => {
            this.setState({
                ...this.state,
                ctrlKeyPressed: event.ctrlKey || event.metaKey,
            });
        };

        render() {
            return <WrappedComponent {...(this.props as T)} ctrlKeyPressed={this.state.ctrlKeyPressed} />;
        }
    }

    return CtrlKeyDetector;
}
