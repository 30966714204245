import React, { Component, RefObject } from "react";
import { SnackbarProvider as NotiStackSnackbarProvider } from "notistack";
import IconButton from "@material-ui/core/IconButton";
import { Close as CloseIcon, ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import { WithStyles, withStyles } from "@material-ui/core";
import snackbarProviderStyles from "./snackbarProviderStyles";

interface PropsInterface extends WithStyles<typeof snackbarProviderStyles> {
    children?: React.ReactNode | React.ReactNodeArray;
}

class SnackbarProvider extends Component<PropsInterface> {
    protected notistackRef: RefObject<NotiStackSnackbarProvider> = React.createRef();
    render() {
        const { classes } = this.props;
        return (
            <NotiStackSnackbarProvider
                ref={this.notistackRef}
                maxSnack={5}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                action={(key) => (
                    <IconButton
                        className={classes.iconButton}
                        onClick={() => {
                            if (this.notistackRef.current) this.notistackRef.current.closeSnackbar(key);
                        }}
                        aria-label="close notification"
                        component="span"
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                iconVariant={{
                    error: <ErrorOutlineIcon className={classes.errorOutlinedIcon} />,
                }}
            >
                {this.props.children}
            </NotiStackSnackbarProvider>
        );
    }
}

export default withStyles(snackbarProviderStyles)(SnackbarProvider);
