import React, { ReactElement } from "react";
import _ from "lodash";
import classnames from "classnames";
import Title from "../components/Title/Title";
import Initials from "../components/Initials/Initials";
import { FileSize } from "../../file/FileSize/FileSize";
import { FileExtension } from "../../file/FileExtension/FileExtension";
import WedDate from "../../formatter/WedDate";
import { InitialsAndOwnerInterface } from "../../../services/WedApi/Utils/Initials";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import ItemDotStatus from "../components/ItemDotStatus/ItemDotStatus";
import { ICollectionExtended } from "../../../services/WedApi/Models/Collection";
import { WedImage } from "../../wed-image/WedImage";

import "../tile.scss";
import "./file-tile.scss";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    showCollectionDotStatusColor,
} from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface {
    url?: string;
    preview: boolean;
    title: string;
    initialsAndOwner: InitialsAndOwnerInterface[];
    extension: string;
    size: number;
    date?: Date;
    approvalRequired: boolean;
    approvalFlag: number;
    selected: boolean;
    onClick?: () => void;
    onDoubleClick?: () => void;
    collection?: ICollectionExtended;
    actionButton?: ReactElement;
}

interface StateInterface {
    isHover: boolean;
}

export default class FileTile extends React.Component<PropsInterface, StateInterface> {
    private readonly EXCLUDE_PROPS_WHEN_UPDATING: string[] = ["actionButton", "onDoubleClick", "onClick", "key", "get"];

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isHover: false,
        };
    }

    shouldComponentUpdate(nextProps: PropsInterface, nextState: StateInterface): boolean {
        return (
            !_.isEqual(_.omit(nextProps, this.EXCLUDE_PROPS_WHEN_UPDATING), _.omit(this.props, this.EXCLUDE_PROPS_WHEN_UPDATING)) ||
            !_.isEqual(nextState, this.state)
        );
    }

    onClick = () => {
        const { onClick } = this.props;

        onClick && onClick();
    };

    onDoubleClick = () => {
        const { onDoubleClick } = this.props;

        onDoubleClick && onDoubleClick();
    };

    render() {
        const { url, preview, title, initialsAndOwner, extension, size, date, approvalRequired, approvalFlag, selected, collection, actionButton } =
            this.props;

        return (
            <div
                onMouseEnter={() => {
                    this.setState({ isHover: true });
                }}
                className={classnames("tile", "file-tile", { "tile--active": selected })}
            >
                {this.state.isHover && actionButton}
                <div className="tile-container" onClick={this.onClick} onDoubleClick={this.onDoubleClick}>
                    {preview && url ? (
                        <div className="file-tile__media-preview">
                            <WedImage className="file-tile__media-preview-image" src={url} />
                        </div>
                    ) : (
                        <div className="file-tile__media-preview">
                            <div className="file-tile__media-preview-icon">
                                <WedIcon icon={<FileTypeIcon extension={extension} fileName={title} />} />
                            </div>
                        </div>
                    )}
                    <div className="file-tile__container">
                        <div className="file-tile__title">
                            <Title title={title} />
                        </div>
                        <div className="file-tile__footer">
                            {initialsAndOwner.map((row, i) => (
                                <Initials key={i} title={row.initials} fullTitle={row.owner} />
                            ))}
                            {collection &&
                                showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, {
                                    approvalFlag,
                                    approvalRequired,
                                }) && (
                                    <div className="file-tile__dot-status">
                                        <ItemDotStatus
                                            color={getCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, {
                                                approvalFlag,
                                                approvalRequired,
                                            })}
                                        />
                                    </div>
                                )}
                            <div className="file-tile__meta">
                                <div className="file-tile__meta--top">
                                    <FileExtension extension={this.props.extension} />
                                    &nbsp;
                                    <FileSize size={size} />
                                </div>
                                <div className="file-tile__meta--bottom">{date && <WedDate date={date} />}</div>
                            </div>
                            <div className="file-tile__icon">
                                <WedIcon size={SizeType.NORMAL} icon={<FileTypeIcon extension={extension} fileName={title} blackIcon={true} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
