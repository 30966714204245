import React from "react";
import { DialogActions } from "@material-ui/core";
import "./popup-action.scss";
import classNames from "classnames";

interface PropsInterface {
    className?: string;
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {}

export default class PopupAction extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <DialogActions className={classNames("popup-action", this.props.className)}>{this.props.children}</DialogActions>;
    }
}
