import React from "react";
import { WedImage } from "../../../wed-image/WedImage";
import "./CollectionPreviewMedia.scss";

interface PropsInterface {
    urls: string[];
}

export default class CollectionPreviewMedia extends React.Component<PropsInterface> {
    render() {
        const { urls } = this.props;

        return (
            <div className="collection-preview-media">
                {urls.length > 1 ? (
                    <>
                        <WedImage className={"collection-preview-media__image-main"} src={urls[0]} radiusTopLeft={true} />
                        <WedImage className={"collection-preview-media__image-small"} src={urls[1]} radiusTopRight={true} />
                        {urls.length > 2 && <WedImage className={"collection-tile__image-small"} src={urls[2]} />}
                    </>
                ) : urls.length > 0 ? (
                    <WedImage className={"collection-tile__image-large"} src={urls[0]} radiusTopLeft={true} radiusTopRight={true} />
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
