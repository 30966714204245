import React from "react";
import ClientsPage from "../../../components/template/ClientsPage/ClientsPage";

interface PropsInterface {}

interface StateInterface {}

class ClientListPage extends React.Component<PropsInterface, StateInterface> {
    render() {
        return <ClientsPage />;
    }
}

export default ClientListPage;
