import React from "react";
import { WedSubMenuContainer } from "../../../main-menu/WedSubMenuContainer/WedSubMenuContainer";
import Breadcrumbs, { BreadcrumbItem } from "../../../breadcrumbs/Breadcrumbs";
import { ISelectItem, ISelectItemType } from "../../../status-action-button/StatusActionButton";
import { FilterFormProjectFiles } from "../../../filter-form/FilterFormProjectFiles/FilterFormProjectFiles";
import { WedTable } from "../../../wed-table/WedTable";
import { TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { IDirectory, IFile, IFolder } from "../../../../services/WedApi/Models/File";
import classNames from "classnames";
import { SizeType, WedIcon } from "../../../wed-icon/WedIcon";
import { WedIconDots, WedIconFolderBlank } from "../../../wed-icon/generated/WedIconSvg";
import WedDate from "../../../formatter/WedDate";
import FilesActionButton from "../../../template/FilesPage/FilesPageHeader/FilesActionButton/FilesActionButton";
import { FileTypeIcon } from "../../../file/FileTypeIcon/FileTypeIcon";
import { FileSize } from "../../../file/FileSize/FileSize";
import GridTiles from "../../../grid/GridTiles/GridTiles";
import DirectoryTile from "../../../tile/DirectoryTile/DirectoryTile";
import { getInitialsAndOwner } from "../../../../services/WedApi/Utils/Initials";
import { isFileHtmlImageExtension } from "../../../../services/WedApi/Utils/HtmlImageUrl";
import container from "../../../../container";
import { SizeImageEnum } from "../../../../services/WedApi/Services/DataParameters/SizeImageEnum";
import TileMenu from "../../../tile/components/TileMenu/TileMenu";
import ActionTile from "../../../tile/ActionTile/ActionTile";
import FileTile from "../../../tile/FileTile/FileTile";
import FilesFilterInterface from "../../../../services/Filter/Files/FilesFilterInterface";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ICollection, ICollectionExtended } from "../../../../services/WedApi/Models/Collection";
import { IProjectExtended } from "../../../../services/WedApi/Models/Project";
import ProjectsFilterInterface from "../../../../services/Filter/Projects/ProjectsFilterInterface";
import { InjectedCtrlKeyDetectorProps, withCtrlKeyDetector } from "../../../../hoc/withCtrlKeyDetector";
import isEqual from "lodash/isEqual";
import DataFilesInterface from "../../../template/FilesPage/Service/DataFilesByType/DataFilesInterface";
import DataFilesFactory from "../../../template/FilesPage/Service/DataFilesByType/DataFilesFactory";
import { FileTypeEnum } from "../../../template/Type/FileTypeEnum";
import { isEditableProject } from "../../../../services/WedApi/Utils/IsEditableProject";
import ItemDotStatus from "../../../tile/components/ItemDotStatus/ItemDotStatus";
import { ApplicationState } from "../../../../store";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { SelectedItemsProps, setSelectedItems, SetSelectedItemsProps } from "../../../../store/SelectedItemsReducer";
import {
    CollectionDotStatusColorItemType,
    getCollectionDotStatusColor,
    showCollectionDotStatusColor,
} from "../../../../services/WedApi/Utils/CollectionDotStatusColor";
import { hasWriteAccessToSharedMyLibraryFolder } from "../../../../services/WedApi/Utils/AccessMyLibrary";

interface PropsInterface
    extends BasePropsInterface,
        SetSelectedItemsProps,
        SelectedItemsProps,
        InjectedCtrlKeyDetectorProps,
        WrappedComponentProps,
        RouteComponentProps {}

interface BasePropsInterface {
    fileType: FileTypeEnum;
    collection?: {
        items: ICollection[];
        item: ICollectionExtended;
    };
    dir?: {
        id: number;
        folder: IFolder;
    };
    project: IProjectExtended;
    isTableMode: boolean;
    files: IFile[];
    directories: IDirectory[];
    parentFolder?: IDirectory;
    disabledActionButtons?: boolean;
    userId?: number;
    callbacks?: {
        handleBreadcrumbsClick?: (link?: string, dirId?: number) => void;
        handleRefreshAfterChanges?: () => void;
        handleClickOpenMoveToTrash?: (items: ISelectItem[]) => void;
        handleOpenFolder?: (directory: IDirectory) => void;
        handleOpenFile?: (file: IFile, dirId?: number) => void;
        handleClickOpenCreateFolderPopup?: () => void;
        handleInitActionCallback?: () => void;
        handleErrorActionCallback?: () => void;
        handleOpenAddFileToCollection?: () => void;
        handleClickOpenUploadFilePopup?: () => void;
    };
}

interface StateInterface {
    breadcrumbs: BreadcrumbItem[];
    selectedItemBreadcrumb: BreadcrumbItem | undefined;
    filterFiles: IFile[];
    filterDirectories: IDirectory[];
}

class FilesPageContent extends React.Component<PropsInterface, StateInterface> {
    private filters: ProjectsFilterInterface;
    private filesDataService: DataFilesInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.filesDataService = DataFilesFactory.getFileType(this.props.fileType);
        this.state = {
            breadcrumbs: [],
            selectedItemBreadcrumb: undefined,
            filterFiles: [],
            filterDirectories: [],
        };

        this.filters = {};

        this.handleFiltersChange = this.handleFiltersChange.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.isAllSelected = this.isAllSelected.bind(this);
        this.handleRefreshAfterChanges = this.handleRefreshAfterChanges.bind(this);
        this.handleClickOpenMoveToTrash = this.handleClickOpenMoveToTrash.bind(this);
        this.handleOpenFolder = this.handleOpenFolder.bind(this);
        this.handleOpenFile = this.handleOpenFile.bind(this);
        this.handleClickOpenCreateFolderPopup = this.handleClickOpenCreateFolderPopup.bind(this);
        this.handleInitActionCallback = this.handleInitActionCallback.bind(this);
        this.handleErrorActionCallback = this.handleErrorActionCallback.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(prevProps.dir, this.props.dir) || !isEqual(prevProps.collection, this.props.collection)) {
            await this.fetchData();
        }
    }

    async componentWillUnmount() {
        this.props.setSelectedItems({ selectedItems: [] });
    }

    private async fetchData() {
        const { fileType, collection, dir, project } = this.props;

        this.setState({
            breadcrumbs: this.filesDataService.breadcrumbs({ fileType, collection, dir, project }),
        });
        this.filterData();
    }

    private handleRefreshAfterChanges() {
        this.props.callbacks?.handleRefreshAfterChanges && this.props.callbacks?.handleRefreshAfterChanges();
    }

    private handleClickOpenMoveToTrash(items: ISelectItem[]) {
        this.props.callbacks?.handleClickOpenMoveToTrash && this.props.callbacks?.handleClickOpenMoveToTrash(items);
    }

    private handleOpenFolder(directory: IDirectory) {
        this.props.callbacks?.handleOpenFolder && this.props.callbacks?.handleOpenFolder(directory);
    }

    private handleOpenFile(file: IFile, dirId?: number) {
        this.props.callbacks?.handleOpenFile && this.props.callbacks?.handleOpenFile(file, dirId);
    }

    private handleClickOpenCreateFolderPopup() {
        this.props.callbacks?.handleClickOpenCreateFolderPopup && this.props.callbacks?.handleClickOpenCreateFolderPopup();
    }

    private handleInitActionCallback() {
        this.props.callbacks?.handleInitActionCallback && this.props.callbacks?.handleInitActionCallback();
    }

    private handleErrorActionCallback() {
        this.props.callbacks?.handleErrorActionCallback && this.props.callbacks?.handleErrorActionCallback();
    }

    private isAllSelected() {
        const { filterFiles, filterDirectories } = this.state;
        const { selectedItems } = this.props;

        return filterFiles.length + filterDirectories.length === selectedItems.length;
    }

    private isSelected(id: number, type: ISelectItemType) {
        const { selectedItems } = this.props;

        return !!selectedItems.find((item) => item.id === id && item.type === type);
    }

    private handleSelectAll() {
        const { filterFiles, filterDirectories } = this.state;
        const { parentFolder } = this.props;

        let selectedItems: ISelectItem[] = [];

        if (!this.isAllSelected()) {
            selectedItems = [
                ...filterFiles.map((file) => {
                    return {
                        id: file.id,
                        type: ISelectItemType.file,
                        userIsOwner: file.userIsOwner,
                        object: file,
                        parentFolder: parentFolder,
                    };
                }),
                ...filterDirectories.map((dir) => {
                    return {
                        id: dir.id,
                        type: ISelectItemType.directory,
                        userIsOwner: dir.userIsOwner,
                        object: dir,
                    };
                }),
            ];
        }

        this.props.setSelectedItems({ selectedItems });
    }

    private handleSelectItem(item: IDirectory | IFile, type: ISelectItemType, multiplySelect = false) {
        let selectedItems = [...this.props.selectedItems];
        let selectedItemBreadcrumb;

        const { ctrlKeyPressed, parentFolder } = this.props;

        const selectedItemIndex = selectedItems.findIndex((selectedItem) => selectedItem.type === type && selectedItem.id == item.id);
        if (selectedItemIndex == -1) {
            const newItem = {
                id: item.id,
                type,
                userIsOwner: item.userIsOwner,
                object: item,
                parentFolder: parentFolder,
            };
            selectedItems = multiplySelect || ctrlKeyPressed ? selectedItems.concat([newItem]) : [newItem];
        } else {
            selectedItems.splice(selectedItemIndex, 1);
        }
        if (selectedItems.length == 1 && selectedItems[0].type === ISelectItemType.file) {
            selectedItemBreadcrumb = {
                title: selectedItems[0].object?.name || "",
            };
        } else if (selectedItems.length == 1 && selectedItems[0].type === ISelectItemType.directory) {
            selectedItemBreadcrumb = {
                title: selectedItems[0].object?.name || "",
            };
        }

        this.setState({ selectedItemBreadcrumb });
        this.props.setSelectedItems({ selectedItems });
    }

    private handleFiltersChange(filters: FilesFilterInterface) {
        this.filters = filters;
        this.filterData();
    }

    private filterData() {
        const filterProjects = container.filesFilterService.filter(
            { files: this.props.files, directories: this.props.directories },
            Object.assign({}, this.filters)
        );
        this.setState({
            filterFiles: filterProjects.files,
            filterDirectories: filterProjects.directories,
        });
    }

    private getBreadcrumbActionButtons(): React.ReactElement | undefined {
        const { project, dir, collection, parentFolder } = this.props;
        const selectedOneItem = this.props.selectedItems.length === 1 && !!this.props.selectedItems[0].object;

        if (
            (!!dir && !collection && !selectedOneItem) ||
            (selectedOneItem && this.props.selectedItems[0].type === ISelectItemType.directory && this.props.selectedItems[0].object)
        ) {
            const directory: IDirectory = (selectedOneItem ? this.props.selectedItems[0].object : dir?.folder.directory) as IDirectory;

            return (
                <FilesActionButton
                    icon={<WedIcon size={SizeType.SMALL} icon={<WedIconDots />} />}
                    selectedItems={[
                        {
                            id: directory.id,
                            type: ISelectItemType.directory,
                            userIsOwner: directory.userIsOwner,
                            object: directory,
                            parentFolder: parentFolder,
                        },
                    ]}
                    project={project}
                    dirId={directory.id}
                    rootDir={dir?.folder.directory.paths[0].id}
                    rootDirName={this.filesDataService.getRootDirName(project)}
                    removeToTrash={this.handleClickOpenMoveToTrash}
                    actionCallback={this.handleRefreshAfterChanges}
                    onInitActionCallback={this.handleInitActionCallback}
                    onErrorActionCallback={this.handleErrorActionCallback}
                    handleOpenFolder={this.handleOpenFolder}
                    breadcrumbItem={{
                        id: `breadcrumb-item-id-${directory.id}`,
                        title: directory.name,
                    }}
                    noDisplay={{
                        openFolder: !selectedOneItem,
                    }}
                    fileType={this.props.fileType}
                />
            );
        }

        if (selectedOneItem && this.props.selectedItems[0].type === ISelectItemType.file && this.props.selectedItems[0].object) {
            const file = this.props.selectedItems[0].object as IFile;

            return (
                <FilesActionButton
                    icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                    selectedItems={[
                        {
                            id: file.id,
                            type: ISelectItemType.file,
                            userIsOwner: file.userIsOwner,
                            object: file,
                            parentFolder: parentFolder,
                        },
                    ]}
                    rootDir={dir?.folder.directory.paths[0].id}
                    rootDirName={this.filesDataService.getRootDirName(project)}
                    dirId={dir?.id as number}
                    project={project}
                    collection={collection?.item}
                    removeToTrash={this.handleClickOpenMoveToTrash}
                    actionCallback={this.handleRefreshAfterChanges}
                    handleOpenFile={this.handleOpenFile}
                    onInitActionCallback={this.handleInitActionCallback}
                    onErrorActionCallback={this.handleErrorActionCallback}
                    breadcrumbItem={{
                        id: `breadcrumb-item-id-${file.id}`,
                        title: file.name,
                    }}
                    fileType={this.props.fileType}
                />
            );
        }
    }

    render() {
        const { selectedItems, collection, dir, isTableMode, project, disabledActionButtons, parentFolder } = this.props;
        const { filterDirectories, filterFiles, selectedItemBreadcrumb } = this.state;

        const breadcrumbs = [...this.state.breadcrumbs];

        if (selectedItemBreadcrumb) {
            breadcrumbs.push(selectedItemBreadcrumb);
        }

        return (
            <>
                <WedSubMenuContainer
                    left={
                        <Breadcrumbs
                            items={breadcrumbs}
                            disabledActionButtons={disabledActionButtons}
                            handleBreadcrumbsClick={this.props.callbacks?.handleBreadcrumbsClick}
                            selectedItemId={
                                !collection && selectedItems.length == 1 && selectedItems[0].type === ISelectItemType.directory
                                    ? selectedItems[0].id
                                    : undefined
                            }
                            maxLength={10}
                            actionButtons={this.getBreadcrumbActionButtons()}
                        />
                    }
                    right={<FilterFormProjectFiles onFiltersChange={this.handleFiltersChange} />}
                />
                {isTableMode ? (
                    <>
                        <WedTable>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox onChange={this.handleSelectAll} checked={this.isAllSelected()} />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="app.filesList.table.folderFileName" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="app.filesList.table.created" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="app.filesList.table.createdBy" />
                                    </TableCell>
                                    {collection && (
                                        <TableCell>
                                            <FormattedMessage id="app.filesList.table.approvalStatus" />
                                        </TableCell>
                                    )}
                                    <TableCell>
                                        <FormattedMessage id="app.filesList.table.fileType" />
                                    </TableCell>
                                    <TableCell>
                                        <FormattedMessage id="app.filesList.table.numOfFiles" />
                                    </TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(!collection ? filterDirectories : []).map((directory: IDirectory, i) => (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        key={`dir${i}`}
                                        onClick={() => {
                                            this.handleSelectItem(directory, ISelectItemType.directory);
                                        }}
                                        onDoubleClick={() => {
                                            this.handleOpenFolder(directory);
                                        }}
                                        className={classNames({
                                            "table-row--active": this.isSelected(directory.id, ISelectItemType.directory),
                                        })}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onChange={() => {
                                                    this.handleSelectItem(directory, ISelectItemType.directory, true);
                                                }}
                                                checked={this.isSelected(directory.id, ISelectItemType.directory)}
                                            />
                                            <WedIcon className="wed-icon-file-type-icon" size={SizeType.SMALL} icon={<WedIconFolderBlank />} />
                                        </TableCell>
                                        <TableCell>{directory.name}</TableCell>
                                        <TableCell>{directory.created && <WedDate date={directory.created} />}</TableCell>
                                        <TableCell>
                                            {directory.createdBy ? `${directory.createdBy.firstName} ${directory.createdBy.lastName}` : ""}
                                        </TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>{directory.numberOfFiles}</TableCell>
                                        <TableCell align="right">
                                            {!disabledActionButtons && (
                                                <FilesActionButton
                                                    icon={<WedIcon size={SizeType.SMALL} icon={<WedIconDots />} />}
                                                    selectedItems={[
                                                        {
                                                            id: directory.id,
                                                            type: ISelectItemType.directory,
                                                            userIsOwner: directory.userIsOwner,
                                                            object: directory,
                                                            parentFolder: parentFolder,
                                                        },
                                                    ]}
                                                    project={project}
                                                    dirId={directory.id}
                                                    rootDir={dir?.folder.directory.paths[0].id}
                                                    rootDirName={this.filesDataService.getRootDirName(project)}
                                                    removeToTrash={this.handleClickOpenMoveToTrash}
                                                    actionCallback={this.handleRefreshAfterChanges}
                                                    onInitActionCallback={this.handleInitActionCallback}
                                                    onErrorActionCallback={this.handleErrorActionCallback}
                                                    handleOpenFolder={this.handleOpenFolder}
                                                    fileType={this.props.fileType}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {filterFiles.map((file: IFile, i) => (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        key={`file${i}`}
                                        onClick={() => {
                                            this.handleSelectItem(file, ISelectItemType.file);
                                        }}
                                        onDoubleClick={() => {
                                            this.handleOpenFile(file, collection ? undefined : dir?.id);
                                        }}
                                        className={classNames({ "table-row--active": this.isSelected(file.id, ISelectItemType.file) })}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onChange={() => {
                                                    this.handleSelectItem(file, ISelectItemType.file, true);
                                                }}
                                                checked={this.isSelected(file.id, ISelectItemType.file)}
                                            />
                                            <WedIcon
                                                className="wed-icon-file-type-icon"
                                                size={SizeType.SMALL}
                                                icon={<FileTypeIcon extension={file.extension} fileName={file.name} />}
                                            />
                                        </TableCell>
                                        <TableCell>{file.name}</TableCell>
                                        <TableCell>{file.created && <WedDate date={file.created} />}</TableCell>
                                        <TableCell>{file.createdBy ? `${file.createdBy.firstName} ${file.createdBy.lastName}` : ""}</TableCell>
                                        {collection && (
                                            <TableCell>
                                                {showCollectionDotStatusColor(CollectionDotStatusColorItemType.COLLECTION_FILE, file) && (
                                                    <div className="file-tile__dot-status">
                                                        <ItemDotStatus
                                                            color={getCollectionDotStatusColor(
                                                                CollectionDotStatusColorItemType.COLLECTION_FILE,
                                                                file
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                            </TableCell>
                                        )}
                                        <TableCell>{file.extension}</TableCell>
                                        <TableCell>
                                            <FileSize size={file.size} />
                                        </TableCell>
                                        <TableCell align="right">
                                            {!disabledActionButtons && (
                                                <FilesActionButton
                                                    icon={<WedIcon size={SizeType.SMALL} icon={<WedIconDots />} />}
                                                    selectedItems={[
                                                        {
                                                            id: file.id,
                                                            type: ISelectItemType.file,
                                                            userIsOwner: file.userIsOwner,
                                                            object: file,
                                                            parentFolder: parentFolder,
                                                        },
                                                    ]}
                                                    rootDir={dir?.folder.directory.paths[0].id}
                                                    rootDirName={this.filesDataService.getRootDirName(project)}
                                                    dirId={dir?.id as number}
                                                    project={project}
                                                    collection={collection?.item}
                                                    removeToTrash={this.handleClickOpenMoveToTrash}
                                                    actionCallback={this.handleRefreshAfterChanges}
                                                    handleOpenFile={this.handleOpenFile}
                                                    onInitActionCallback={this.handleInitActionCallback}
                                                    onErrorActionCallback={this.handleErrorActionCallback}
                                                    fileType={this.props.fileType}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </WedTable>
                    </>
                ) : (
                    <>
                        {!collection && (
                            <GridTiles title="FOLDERS">
                                {filterDirectories.map((directory, i) => (
                                    <DirectoryTile
                                        key={i}
                                        initialsAndOwner={directory.createdBy ? getInitialsAndOwner(directory.createdBy) : undefined}
                                        title={directory.name}
                                        totalFiles={directory.numberOfFiles}
                                        topObjects={directory.topObjects}
                                        selected={this.isSelected(directory.id, ISelectItemType.directory)}
                                        onClick={() => {
                                            this.handleSelectItem(directory, ISelectItemType.directory);
                                        }}
                                        onDoubleClick={() => {
                                            this.handleOpenFolder(directory);
                                        }}
                                        actionButton={
                                            !disabledActionButtons ? (
                                                <FilesActionButton
                                                    icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                                    selectedItems={[
                                                        {
                                                            id: directory.id,
                                                            type: ISelectItemType.directory,
                                                            userIsOwner: directory.userIsOwner,
                                                            object: directory,
                                                            parentFolder: parentFolder,
                                                        },
                                                    ]}
                                                    project={project}
                                                    dirId={dir?.id as number}
                                                    rootDir={dir?.folder.directory.paths[0].id}
                                                    rootDirName={this.filesDataService.getRootDirName(project)}
                                                    removeToTrash={this.handleClickOpenMoveToTrash}
                                                    actionCallback={this.handleRefreshAfterChanges}
                                                    onInitActionCallback={this.handleInitActionCallback}
                                                    onErrorActionCallback={this.handleErrorActionCallback}
                                                    handleOpenFolder={this.handleOpenFolder}
                                                    fileType={this.props.fileType}
                                                />
                                            ) : undefined
                                        }
                                    />
                                ))}
                                {!this.props.disabledActionButtons &&
                                    !!this.props.callbacks?.handleClickOpenCreateFolderPopup &&
                                    ((project &&
                                        isEditableProject(this.props.fileType, project) &&
                                        this.props.fileType !== FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME) ||
                                        (this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME &&
                                            hasWriteAccessToSharedMyLibraryFolder(this.props.parentFolder, this.props.userId))) && (
                                        <ActionTile
                                            className="directory-tile__create-folder"
                                            title={this.props.intl.formatMessage({ id: "app.tile.createFolder" })}
                                            onClick={this.handleClickOpenCreateFolderPopup}
                                        />
                                    )}
                            </GridTiles>
                        )}
                        <GridTiles title="FILES">
                            {filterFiles.map((file: IFile, i) => (
                                <FileTile
                                    key={i}
                                    title={file.name}
                                    url={container.filesService.getFileUrl(file.id, file.newestVersionId, {
                                        size: SizeImageEnum.THUMB,
                                        fileType: this.props.fileType,
                                        collectionId: collection?.item.id,
                                    })}
                                    preview={isFileHtmlImageExtension(file.extension)}
                                    initialsAndOwner={file.owners.map((owner) => getInitialsAndOwner(owner))}
                                    size={file.size}
                                    date={file.created}
                                    extension={file.extension}
                                    approvalRequired={file.approvalRequired}
                                    approvalFlag={file.approvalFlag}
                                    selected={this.isSelected(file.id, ISelectItemType.file)}
                                    collection={collection?.item}
                                    onClick={() => {
                                        this.handleSelectItem(file, ISelectItemType.file);
                                    }}
                                    onDoubleClick={() => {
                                        this.handleOpenFile(file, dir?.id);
                                    }}
                                    actionButton={
                                        !disabledActionButtons ? (
                                            <FilesActionButton
                                                icon={<TileMenu sizeIcon={SizeType.NORMAL} />}
                                                selectedItems={[
                                                    {
                                                        id: file.id,
                                                        type: ISelectItemType.file,
                                                        userIsOwner: file.userIsOwner,
                                                        object: file,
                                                        parentFolder: parentFolder,
                                                    },
                                                ]}
                                                rootDir={dir?.folder.directory.paths[0].id}
                                                rootDirName={this.filesDataService.getRootDirName(project)}
                                                dirId={dir?.id as number}
                                                project={project}
                                                collection={collection?.item}
                                                removeToTrash={this.handleClickOpenMoveToTrash}
                                                actionCallback={this.handleRefreshAfterChanges}
                                                handleOpenFile={this.handleOpenFile}
                                                onInitActionCallback={this.handleInitActionCallback}
                                                onErrorActionCallback={this.handleErrorActionCallback}
                                                fileType={this.props.fileType}
                                            />
                                        ) : undefined
                                    }
                                />
                            ))}
                            {!this.props.disabledActionButtons &&
                                !!this.props.callbacks?.handleOpenAddFileToCollection &&
                                project &&
                                isEditableProject(this.props.fileType, project) &&
                                collection &&
                                !collection.item.locked &&
                                collection.item.userIsOwner && (
                                    <ActionTile
                                        className="directory-tile__upload-files"
                                        title={this.props.intl.formatMessage({ id: "app.tile.addFiles" })}
                                        onClick={this.props.callbacks?.handleOpenAddFileToCollection}
                                    />
                                )}
                            {!this.props.disabledActionButtons &&
                                !!this.props.callbacks?.handleClickOpenUploadFilePopup &&
                                ((project &&
                                    isEditableProject(this.props.fileType, project) &&
                                    !collection &&
                                    this.props.fileType !== FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME) ||
                                    (this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME &&
                                        hasWriteAccessToSharedMyLibraryFolder(this.props.parentFolder, this.props.userId))) && (
                                    <ActionTile
                                        className="directory-tile__upload-files"
                                        title={this.props.intl.formatMessage({ id: "app.tile.uploadFiles" })}
                                        onClick={this.props.callbacks?.handleClickOpenUploadFilePopup}
                                    />
                                )}
                        </GridTiles>
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        selectedItems: state.selectedItems.selectedItems || [],
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setSelectedItems: setSelectedItems(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(withCtrlKeyDetector(FilesPageContent))));
