import React from "react";
import classnames from "classnames";
import { ItemDotStatusColor } from "../../../../services/WedApi/Utils/CollectionDotStatusColor";
import "./ItemDotStatus.scss";

interface PropsInterface {
    color?: ItemDotStatusColor;
}

interface StateInterface {}

export default class ItemDotStatus extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <span
                className={classnames("item-dot-status", this.props.color ? `item-dot-status--color-${this.props.color.toLowerCase()}` : undefined)}
            />
        );
    }
}
