export enum FileTypeEnum {
    PROJECT_FILES = "PROJECT_FILES",
    PROJECT_COLLECTION_FILES = "PROJECT_COLLECTION_FILES",
    PROJECT_COLLECTION = "PROJECT_COLLECTION",
    TEAM_USER_PROJECT_WORK = "TEAM_USER_PROJECT_WORK",
    TEAM_USER_PRIVATE_PORTFOLIO = "TEAM_USER_PRIVATE_PORTFOLIO",
    MY_LIBRARY_FILES = "MY_LIBRARY_FILES",
    MY_LIBRARY_SHARED_WITH_ME = "MY_LIBRARY_SHARED_WITH_ME",
    MY_LIBRARY_COLLECTIONS = "MY_LIBRARY_COLLECTIONS",
    MY_LIBRARY_COLLECTIONS_FILES = "MY_LIBRARY_COLLECTIONS_FILES",
    SHARED_COLLECTION_FILES = "SHARED_COLLECTION_FILES",
}

export function isMyLibraryFileType(fileType: FileTypeEnum): boolean {
    return [FileTypeEnum.MY_LIBRARY_FILES, FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME, FileTypeEnum.MY_LIBRARY_COLLECTIONS].includes(fileType);
}

export function isTeamUserFileType(fileType: FileTypeEnum): boolean {
    return [FileTypeEnum.TEAM_USER_PROJECT_WORK, FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO].includes(fileType);
}
