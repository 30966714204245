export enum IApiSearchResultType {
    PROJECT = "Project",
    PROJECT_FOLDER = "Project Folder",
    PROJECT_FILE = "Project File",
    PROJECT_COLLECTION = "Project Collection",
    MY_LIBRARY_FOLDER = "MyLibrary Folder",
    MY_LIBRARY_FILE = "MyLibrary File",
    MY_LIBRARY_COLLECTION = "MyLibrary Collection",
    CUSTOMER_ACCOUNT = "Customer Account",
    CUSTOMER_CONTACT = "Customer Contact",
    USER = "User",
}

export interface IApiSearchObject {
    id: number;
    vid: number;
    firstName: string;
    lastName: string;
    email: string;
    name: string;
    desc: string;
    date: string;
    parentID: number;
    projectID: number;
    projectName: string;
    customerAccountName: string;
    customerAccountID: number;
    type?: { extension: string; category: string; mimeType: string };
}

export interface IApiSearchResult {
    Type: IApiSearchResultType;
    Value: IApiSearchObject;
}

export type ISearchResultType = IApiSearchResultType;

export interface ISearchObject extends Omit<IApiSearchObject, "date" | "parentID"> {
    date?: Date;
    dirId?: number;
}

export interface ISearchResult {
    type: ISearchResultType;
    value: ISearchObject;
}
