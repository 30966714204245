import { IOwner, IUser } from "./User";
import { IClientSimple } from "./Client";

export enum ProjectStatusEnum {
    noStatus = "noStatus",
    active = "active",
    closed = "closed",
    archived = "archived",
}

export enum ProjectStatusChangeEnum {
    close = "close",
    reactivate = "reactivate",
    archive = "archive",
    restore = "restore",
    delete = "delete",
}

export interface IApiProject {
    id: number;
    name: string;
    desc: string;
    libraryID: number;
    ecOrgID: number;
    status: number;
    tags: string[];
    ownerIDs: number[];
    members: number[];
    ecContactIDs: number[];
    createTS: string;
    collectionIDs: number[];
    createdBy: number;
    updatedTS: string;
    updatedBy: number;
    userIsOwner: boolean;
    privateNotes: number[];
    isFavourite: boolean;
    collectionsSummary: {
        all: number;
        approved: number;
        rejected: number;
    };
}

export interface IApiProjectDetail {
    Pr: IApiProject;
    Lib: {
        fRootID: number;
        id: number;
        size: number;
        files: number;
    };
}

export interface IProject {
    id: number;
    name: string;
    desc: string;
    ecOrg: Partial<IClientSimple> & { id: number };
    created?: Date;
    createdBy?: IOwner;
    updated?: Date;
    updatedBy?: IOwner;
    status: ProjectStatusEnum;
    isFavourite: boolean;
    approvedCollections: number;
    rejectedCollections: number;
    allCollections: number;
    numOfOwners: number;
    numOfMembers: number;
    numOfECUsers: number;
    userIsOwner: boolean;
    membersIds: number[];
    ownersIds: number[];
    ecUsersIds: number[];
}

export interface IProjectExtended extends IProject {
    rootDirectory: number;
    rootSize: number;
    rootFiles: number;
    members: IOwner[];
    owners: IOwner[];
    ecUsers: IUser[];
}

export interface IManagerProjectAccessParameters {
    pid: number;
    ownersRemove?: number[];
    ownersAdd?: number[];
    membersRemove?: number[];
    membersAdd?: number[];
    ecContactsRemove?: number[];
    ecContactsAdd?: number[];
}
