import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { TableBody, TableCell, TableRow, TextField } from "@material-ui/core";
import { IAccessFolderUser, IFolderAccessAction, IUser, IUserBase } from "../../../services/WedApi/Models/User";
import container from "../../../container";
import { IDirectory } from "../../../services/WedApi/Models/File";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PopupConfirmButton from "../../popup/PopupConfirmButton/PopupConfirmButton";
import WedTable from "../../wed-table/WedTable";
import UserCard from "../../user/UserCard/UserCard";
import { InitialsUserType } from "../../tile/components/Initials/Initials";
import WedInsideFormElement from "../../form/WedInsideFormElement/WedInsideFormElement";
import Select from "@material-ui/core/Select";
import { WedIconDropDown } from "../../wed-icon/WedIconDropDown";
import MenuItem from "@material-ui/core/MenuItem";
import { WedIcon } from "../../wed-icon/WedIcon";
import { WedIconTrashDeleteRemove } from "../../wed-icon/generated/WedIconSvg";
import IManagerFolderAccessParameters from "../../../services/WedApi/Services/DataParameters/IManagerFolderAccessParameters";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";

interface StateInterface {
    loading: boolean;
    clientUserMembers: IUser[];
    userMember: Array<IAccessFolderUser>;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    close: (isUpdate?: boolean) => void;
    object: IDirectory;
    handleClose: () => void;
    onSuccess: () => void;
}

class ManagerFolderAccessPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            userMember: [],
            clientUserMembers: [],
        };
        this.closePopup = this.closePopup.bind(this);
        this.doIt = this.doIt.bind(this);
    }

    async componentDidMount() {
        const users = await container.userService.getUsers();

        const userMemberReadAccess = [];
        for (const user of users) {
            if (this.props.object.readAccessIds.includes(user.id)) {
                userMemberReadAccess.push({ ...user, access: IFolderAccessAction.ACCESS_READ });
            }
        }
        const userMemberWriteAccess = [];
        for (const user of users) {
            if (this.props.object.writeAccessIds.includes(user.id)) {
                userMemberWriteAccess.push({ ...user, access: IFolderAccessAction.ACCESS_WRITE });
            }
        }

        this.setState({
            clientUserMembers: users,
            userMember: userMemberReadAccess.concat(userMemberWriteAccess),
        });
    }

    private closePopup() {
        this.props.close();
    }

    private async doIt(): Promise<boolean> {
        const newReadAccessIds = this.state.userMember
            .filter((userTeamRole) => userTeamRole.access === IFolderAccessAction.ACCESS_READ)
            .map((userTeamRole) => userTeamRole.id);
        const newWriteAccessIds = this.state.userMember
            .filter((userTeamRole) => userTeamRole.access === IFolderAccessAction.ACCESS_WRITE)
            .map((userTeamRole) => userTeamRole.id);

        const parameters: IManagerFolderAccessParameters = {
            id: this.props.object.id,
            readAccessAdd: newReadAccessIds.filter((readAccessId) => !this.props.object.readAccessIds.includes(readAccessId)),
            readAccessRemove: this.props.object.readAccessIds.filter((readAccessId) => !newReadAccessIds.includes(readAccessId)),
            writeAccessAdd: newWriteAccessIds.filter((writeAccessId) => !this.props.object.writeAccessIds.includes(writeAccessId)),
            writeAccessRemove: this.props.object.writeAccessIds.filter((writeAccessId) => !newWriteAccessIds.includes(writeAccessId)),
        };

        try {
            await container.filesService.managerFolderAccess(parameters);
        } catch (e) {
            let keyTranslate = "app.error.undefined";
            if (e.code === FrontApiErrorCodeEnum.ERR_FODLER_ACCESS) {
                keyTranslate = "aapp.user.ManageFolderAccessPopup.error.errFolderAccess";
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
            return false;
        }

        this.props.onSuccess();
        return true;
    }

    private addAccessForUser(user: IUser) {
        this.setState({
            userMember: [
                {
                    ...user,
                    access: IFolderAccessAction.ACCESS_READ,
                },
                ...this.state.userMember,
            ],
        });
    }

    private changeUserAccess(id: number, access: IFolderAccessAction) {
        this.setState({
            userMember: this.state.userMember.map((userMember) => {
                if (userMember.id === id) {
                    return {
                        ...userMember,
                        access: access,
                    };
                }
                return userMember;
            }),
        });
    }

    private dennyAccess(id: number) {
        const idx = this.state.userMember.findIndex((user) => user.id === id);
        if (idx !== -1) {
            this.setState({
                userMember: [...this.state.userMember.slice(0, idx), ...this.state.userMember.slice(idx + 1)],
            });
        }
    }

    render() {
        const allowUserMembers = this.state.clientUserMembers.filter(
            (clientUserMembers) => !(this.state.userMember || []).find((userMember) => userMember.id === clientUserMembers.id)
        );
        return (
            <PopupConfirmButton
                maxWidth="md"
                open={true}
                close={this.props.close}
                doIt={this.doIt}
                confirmButtonLabel={<FormattedMessage id={"app.standard.popup.button.add"} />}
                noDisplayCancel={true}
                header={<FormattedMessage id={"app.user.ManageFolderAccessPopup.header.title"} />}
                disableDialogWrapper={true}
                content={
                    <div className="user-edit-team-members-popup">
                        <div className="user-edit-team-members-popup--content">
                            <div className="user-edit-team-members-popup--user-members">
                                <Autocomplete
                                    fullWidth
                                    id={"user-edit--team-members"}
                                    options={allowUserMembers}
                                    // eslint-disable-next-line
                                    value={null}
                                    getOptionLabel={(option: IUserBase) => `${option.firstName} ${option.lastName}`}
                                    // eslint-disable-next-line
                                    onChange={(event: React.ChangeEvent<any>, value: IUser | null) => {
                                        if (value) {
                                            this.addAccessForUser(value);
                                            const input = document.getElementById("user-edit--team-members") as HTMLInputElement;
                                            if (input) {
                                                setTimeout(() => {
                                                    input.blur();
                                                }, 25);
                                            }
                                        }
                                    }}
                                    // eslint-disable-next-line
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            label={<FormattedMessage id={"app.user.ManageFolderAccessPopup.select.label"} />}
                                            margin="normal"
                                            variant="outlined"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <div className="team-wed-table">
                                    <WedTable>
                                        {this.state.userMember !== undefined && (
                                            <>
                                                <TableBody>
                                                    {this.state.userMember.map((userMember, i) => {
                                                        return (
                                                            <TableRow key={`member-${i}`} className="team-wed-table--team-members">
                                                                <TableCell>
                                                                    <UserCard userType={InitialsUserType.INTERNAL} user={userMember} />
                                                                </TableCell>
                                                                <TableCell className="team-wed-table--no-display-small-screen">
                                                                    <div className="team-wed-table--cell-container">
                                                                        <WedInsideFormElement>
                                                                            <Select
                                                                                IconComponent={WedIconDropDown}
                                                                                // eslint-disable-next-line
                                                                                onChange={(event: React.FormEvent<any>) => {
                                                                                    // eslint-disable-next-line
                                                                                    this.changeUserAccess(userMember.id, (event.target as any).value);
                                                                                }}
                                                                                value={userMember.access}
                                                                            >
                                                                                <MenuItem value={IFolderAccessAction.ACCESS_READ}>
                                                                                    {IFolderAccessAction.ACCESS_READ}
                                                                                </MenuItem>
                                                                                <MenuItem value={IFolderAccessAction.ACCESS_WRITE}>
                                                                                    {IFolderAccessAction.ACCESS_WRITE}
                                                                                </MenuItem>
                                                                            </Select>
                                                                        </WedInsideFormElement>
                                                                    </div>
                                                                </TableCell>
                                                                <TableCell className="team-wed-table--cell-action">
                                                                    <button
                                                                        className="wed-icon-button"
                                                                        onClick={() => {
                                                                            this.dennyAccess(userMember.id);
                                                                        }}
                                                                    >
                                                                        <WedIcon icon={<WedIconTrashDeleteRemove />} />
                                                                    </button>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </>
                                        )}
                                    </WedTable>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

export default withSnackbar(injectIntl(ManagerFolderAccessPopup));
