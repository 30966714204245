import React from "react";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import "./SidebarSummaryInfo.scss";
import classNames from "classnames";

interface PropsInterface {
    statusWedIcon?: React.ReactElement<React.Component>;
    icon: React.ReactElement<React.Component>;
    text: string | React.ReactNode;
}

interface StateInterface {}

class SidebarSummaryInfo extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { statusWedIcon, icon, text } = this.props;

        return (
            <div className={classNames("sidebar-summary-info", { "sidebar-summary-info__with-status-icon": !!statusWedIcon })}>
                {statusWedIcon && <span className="sidebar-summary-info__status-icon">{statusWedIcon}</span>}
                <WedIcon className="sidebar-summary-info__icon" icon={icon} size={SizeType.LARGE} />
                <span className="sidebar-summary-info__text">{text}</span>
            </div>
        );
    }
}

export default SidebarSummaryInfo;
