import React from "react";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import { ProjectMenu } from "../../../components/main-menu/ProjectMenu/ProjectMenu";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import { generatePath } from "react-router-dom";
import GridTiles from "../../../components/grid/GridTiles/GridTiles";
import CommentsAndProofingTile from "../../../components/tile/CommentsAndProofingTile/CommentsAndProofingTile";
import { getInitialsAndOwner } from "../../../services/WedApi/Utils/Initials";
import { CommentsAndProofingDocTypeEnum } from "../../../services/WedApi/Models/CommentsAndProofing";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import container from "../../../container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { LogicError } from "../../../errors/error-app";
import { ICommentNote, ICommentNoteType, INoteFile } from "../../../services/WedApi/Models/Note";
import RoutesEnum from "../../../services/Routes/RoutesEnum";
import "./ProjectCommentsAndProofingPage.scss";
import { getCommentsObjectProofColor } from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface extends ProjectDetailLoaderProps {}

interface StateInterface {
    isLoading: boolean;
    commentsAndProofs: ICommentNote[];
}

class ProjectCommentsAndProofingPage extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: true,
            commentsAndProofs: [],
        };
    }

    async componentDidMount() {
        await this.fetchData();
    }

    private async fetchData() {
        this.setState({
            commentsAndProofs: await container.noteService.getCommentNotes(this.props.project.id, {
                ecUsers: this.props.project.ecUsers,
                ecOrgId: this.props.project.ecOrg.id,
            }),
            isLoading: false,
        });
    }

    private getTile(projectEvent: ICommentNote): React.ReactElement {
        switch (projectEvent.type) {
            case ICommentNoteType.PROJECT_PRIVATE_NOTE:
                return (
                    <CommentsAndProofingTile
                        docType={CommentsAndProofingDocTypeEnum.project}
                        name={projectEvent.item.project?.name || ""}
                        initialsAndOwner={projectEvent.item.createdBy ? getInitialsAndOwner(projectEvent.item.createdBy) : undefined}
                        initialsUserType={projectEvent.item.userType}
                        text={projectEvent.item.content}
                        created={projectEvent.item.created}
                        link={generatePath(RoutesEnum.FILES, { projectId: projectEvent.item.pid as number })}
                    />
                );
            case ICommentNoteType.COLLECTION_NOTE:
                return (
                    <CommentsAndProofingTile
                        docType={CommentsAndProofingDocTypeEnum.collection}
                        name={projectEvent.item.collection?.name || ""}
                        initialsAndOwner={projectEvent.item.createdBy ? getInitialsAndOwner(projectEvent.item.createdBy) : undefined}
                        initialsUserType={projectEvent.item.userType}
                        text={projectEvent.item.content}
                        created={projectEvent.item.created}
                        link={generatePath(RoutesEnum.COLLECTION_FILES, {
                            projectId: projectEvent.item.pid as number,
                            collectionId: projectEvent.item.cid as number,
                        })}
                    />
                );
            case ICommentNoteType.OBJECT_PRIVATE_NOTE:
                return (
                    <CommentsAndProofingTile
                        docType={CommentsAndProofingDocTypeEnum.file}
                        name={projectEvent.item.file?.name || ""}
                        file={projectEvent.item.file as INoteFile}
                        initialsAndOwner={projectEvent.item.createdBy ? getInitialsAndOwner(projectEvent.item.createdBy) : undefined}
                        initialsUserType={projectEvent.item.userType}
                        text={projectEvent.item.content}
                        created={projectEvent.item.created}
                        link={`${generatePath(RoutesEnum.FILES_MEDIA_GALLERY_DETAILS, {
                            projectId: projectEvent.item.pid as number,
                            objectId: projectEvent.item.file?.id as number,
                        })}?dir=${projectEvent.item.file?.dirId as number}`}
                    />
                );
            case ICommentNoteType.OBJECT_PUBLIC_NOTE:
                return (
                    <CommentsAndProofingTile
                        docType={CommentsAndProofingDocTypeEnum.file}
                        name={projectEvent.item.file?.name || ""}
                        subName={projectEvent.item.collection?.name || ""}
                        file={projectEvent.item.file as INoteFile}
                        initialsAndOwner={projectEvent.item.createdBy ? getInitialsAndOwner(projectEvent.item.createdBy) : undefined}
                        initialsUserType={projectEvent.item.userType}
                        text={projectEvent.item.content}
                        created={projectEvent.item.created}
                        link={generatePath(RoutesEnum.COLLECTION_FILES_MEDIA_GALLERY_DETAILS, {
                            projectId: projectEvent.item.pid as number,
                            collectionId: projectEvent.item.cid as number,
                            objectId: projectEvent.item.file?.id as number,
                        })}
                    />
                );
            case ICommentNoteType.OBJECT_PROOF:
                // eslint-disable-next-line
                const initialsOwner = projectEvent.item.createdBy ? getInitialsAndOwner(projectEvent.item.createdBy) : undefined;
                // eslint-disable-next-line
                const approvedStatusColor = getCommentsObjectProofColor(projectEvent.item);

                return (
                    <CommentsAndProofingTile
                        docType={CommentsAndProofingDocTypeEnum.file}
                        name={projectEvent.item.file?.name || ""}
                        subName={projectEvent.item.collection?.name || ""}
                        file={projectEvent.item.file as INoteFile}
                        initialsAndOwner={initialsOwner}
                        initialsUserType={projectEvent.item.userType}
                        approvedBy={approvedStatusColor === undefined ? undefined : initialsOwner?.owner || ""}
                        approvalStatusColor={approvedStatusColor}
                        approvedVersion={projectEvent.item.file?.versionNumber as number}
                        created={projectEvent.item.created}
                        link={generatePath(RoutesEnum.COLLECTION_FILES_MEDIA_GALLERY_DETAILS, {
                            projectId: projectEvent.item.pid as number,
                            collectionId: projectEvent.item.cid as number,
                            objectId: projectEvent.item.file?.id as number,
                        })}
                    />
                );
        }
        throw new LogicError(`Not supported comments and profs type: ${projectEvent.type}`);
    }

    render() {
        return (
            <div className="project-comments-and-proofing-page">
                <WedMainMenuContainer
                    menu={<ProjectMenu projectId={this.props.project.id} activeTab="comments-and-proofing" />}
                    sidebar={
                        <MenuControlIcons
                            project={this.props.project}
                            disableChangeViewMode={true}
                            disableSidebarOpen={true}
                            fileType={FileTypeEnum.PROJECT_FILES}
                        />
                    }
                />
                {this.state.isLoading ? (
                    <LinearProgress />
                ) : (
                    <div className="project-comments-and-proofing-page--layout">
                        <GridTiles className="container-grid__wide">
                            {this.state.commentsAndProofs.map((data, key) => (
                                <div className="project-comments-and-proofing-page--tile-container" key={key}>
                                    {this.getTile(data)}
                                </div>
                            ))}
                        </GridTiles>
                    </div>
                )}
            </div>
        );
    }
}

export default ProjectCommentsAndProofingPage;
