import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import UserEditProfilePopup from "../../../../components/user/UserEditProfilePopup/UserEditProfilePopup";
import { IUserMyProfileRole } from "../../../../services/WedApi/Models/User";

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    userId: number;
    userEmail: string;
    userRole: IUserMyProfileRole;
    close: () => void;
    fields: {
        firstName: string;
        lastName: string;
        cCode: number;
        phone: string;
    };
}

interface StateInterface {}

class AdminPanelUserEditProfilePopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <UserEditProfilePopup
                close={this.props.close}
                userEmail={this.props.userEmail}
                userId={this.props.userId}
                userRole={this.props.userRole}
                fields={{ ...this.props.fields }}
            />
        );
    }
}

export default withSnackbar(injectIntl(AdminPanelUserEditProfilePopup));
