import React from "react";
import {Button} from "@material-ui/core";
import {WedMenuButtons} from "../../../../components/main-menu/WedMenuButtons/WedMenuButtons";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import {SizeType, WedIcon} from "../../../../components/wed-icon/WedIcon";
import {
    WedIconDownloadSaveUpload1,
    WedIconShare,
    WedIconTrashDeleteRemove
} from "../../../../components/wed-icon/generated/WedIconSvg";
import {generatePath, RouteComponentProps, withRouter} from "react-router-dom";
import {ApplicationState} from "../../../../store";
import {connect} from "react-redux";
import {WedImageGalleryDataProps} from "../../../../store/WedImageGalleryReducer";
import {UploadFilePopup} from "../../../../components/file/UploadFilePopup/UploadFilePopup";
import RemoveObjectVersionPopup from "../../../../components/project/Files/RemoveObjectVersionPopup";
import {downloadFile} from "../../../../services/WedApi/Utils/DownloadFile";
import {ProviderContext, withSnackbar} from "notistack";
import ButtonAddToCollection
    from "../../../../components/project/Collection/ButtonAddToCollection/ButtonAddToCollection";
import {IProject} from "../../../../services/WedApi/Models/Project";
import {isEditableProject} from "../../../../services/WedApi/Utils/IsEditableProject";
import RemoveFromCollectionPopup
    from "../../../../components/project/Collection/RemoveFromCollectionPopup/RemoveFromCollectionPopup";
import RoutesEnum from "../../../../services/Routes/RoutesEnum";
import "./FilesMediaGalleryButtons.scss";
import {FileTypeEnum} from "../../../../components/template/Type/FileTypeEnum";

interface BasePropsInterface {
    project?: IProject;
    refreshMediaGallery?: () => void;
    fileType: FileTypeEnum;
}

interface PropsInterface extends BasePropsInterface, WedImageGalleryDataProps, WrappedComponentProps, RouteComponentProps, ProviderContext {}

interface StateInterface {
    openUploadVersion: boolean;
    openRemoveObjectVersion: boolean;
    openRemoveFromCollection: boolean;
}

class FilesMediaGalleryButtons extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            openUploadVersion: false,
            openRemoveObjectVersion: false,
            openRemoveFromCollection: false,
        };
        this.handleClickOpenUploadVersionPopup = this.handleClickOpenUploadVersionPopup.bind(this);
        this.handleCloseUploadVersionPopup = this.handleCloseUploadVersionPopup.bind(this);
        this.handleCloseRemoveFromCollectionPopup = this.handleCloseRemoveFromCollectionPopup.bind(this);
        this.handleClickOpenRemoveFromCollectionPopup = this.handleClickOpenRemoveFromCollectionPopup.bind(this);
        this.handleClickOpenRemoveObjectVersionPopup = this.handleClickOpenRemoveObjectVersionPopup.bind(this);
        this.handleCloseRemoveObjectVersionPopup = this.handleCloseRemoveObjectVersionPopup.bind(this);
    }

    private handleClickOpenUploadVersionPopup() {
        this.setState({
            openUploadVersion: true,
        });
    }

    private handleCloseUploadVersionPopup() {
        this.setState({
            openUploadVersion: false,
        });
    }

    private handleClickOpenRemoveFromCollectionPopup() {
        this.setState({
            openRemoveFromCollection: true,
        });
    }

    private handleCloseRemoveFromCollectionPopup() {
        this.setState({
            openRemoveFromCollection: false,
        });
    }

    private handleClickOpenRemoveObjectVersionPopup() {
        this.setState({
            openRemoveObjectVersion: true,
        });
    }

    private handleCloseRemoveObjectVersionPopup() {
        this.setState({
            openRemoveObjectVersion: false,
        });
    }

    render() {
        return (
            <WedMenuButtons>
                {this.props.objectId && this.props.dirId && !this.props.collectionId && (
                    <UploadFilePopup
                        dirId={this.props.dirId}
                        objectId={this.props.objectId}
                        open={this.state.openUploadVersion}
                        handleClose={this.handleCloseUploadVersionPopup}
                        uploaded={() => {
                            this.handleCloseUploadVersionPopup();
                            this.props.refreshMediaGallery && this.props.refreshMediaGallery();
                        }}
                    />
                )}
                {this.props.objectId &&
                    this.props.versionId &&
                    this.props.numOfVersions &&
                    this.props.numOfVersions > 1 &&
                    this.props.dirId &&
                    !this.props.collectionId && (
                        <RemoveObjectVersionPopup
                            objectId={this.props.objectId}
                            versionId={this.props.versionId}
                            open={this.state.openRemoveObjectVersion}
                            handleClose={this.handleCloseRemoveObjectVersionPopup}
                            onSuccess={() => {
                                this.handleCloseRemoveObjectVersionPopup();
                                this.props.refreshMediaGallery && this.props.refreshMediaGallery();
                            }}
                        />
                    )}
                {!this.props.dirId && this.props.collectionId && this.props.objectId && this.props.project && (
                    <RemoveFromCollectionPopup
                        open={this.state.openRemoveFromCollection}
                        handleClose={this.handleCloseRemoveFromCollectionPopup}
                        onSuccess={() => {
                            this.handleCloseRemoveFromCollectionPopup();
                            this.props.history.push(
                                generatePath(RoutesEnum.COLLECTION_FILES, {
                                    projectId: (this.props.project as IProject).id,
                                    collectionId: this.props.collectionId as number,
                                })
                            );
                        }}
                        files={[
                            {
                                id: this.props.objectId,
                                name: this.props.objectName || "",
                            },
                        ]}
                        collectionId={this.props.collectionId}
                    />
                )}
                {this.props.dirId && !this.props.collectionId && this.props.project && (
                    <ButtonAddToCollection
                        disabled={!isEditableProject(this.props.fileType, this.props.project)}
                        objectItems={this.props.objectId ? [{ type: "file", id: this.props.objectId }] : []}
                        projectId={this.props.project.id}
                        fileType={this.props.fileType}
                    />
                )}
                <Button
                    type="submit"
                    disabled={!this.props.url}
                    startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconDownloadSaveUpload1 />} />}
                    onClick={() => {
                        this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: `app.projectFilesActionButton.download` }), {
                            variant: "success",
                            autoHideDuration: 4000,
                        });
                        downloadFile(this.props.url as string);
                    }}
                >
                    <FormattedMessage id="app.ProjectFilesMediaGalleryButtons.download" />
                </Button>
                {this.props.dirId && !this.props.collectionId && (
                    <>
                        <Button
                            type="submit"
                            disabled={!this.props.objectId ||
                                (this.props.project && !isEditableProject(this.props.fileType, this.props.project)) ||
                                (this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME)
                            }
                            startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare />} />}
                            onClick={this.handleClickOpenUploadVersionPopup}
                        >
                            <FormattedMessage id="app.ProjectFilesMediaGalleryButtons.uploadNewVersion" />
                        </Button>
                        <Button
                            type="submit"
                            disabled={
                                (this.props.project && !isEditableProject(this.props.fileType, this.props.project)) ||
                                (this.props.fileType === FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME) ||
                                !this.props.objectId ||
                                !this.props.versionId ||
                                !this.props.numOfVersions ||
                                this.props.numOfVersions <= 1
                            }
                            startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconTrashDeleteRemove />} />}
                            onClick={this.handleClickOpenRemoveObjectVersionPopup}
                        >
                            <FormattedMessage id="app.ProjectFilesMediaGalleryButtons.removeVersion" />
                        </Button>
                    </>
                )}
                {!this.props.dirId && this.props.collectionId && this.props.project && (
                    <>
                        <Button
                            type="submit"
                            disabled={
                                !this.props.objectId ||
                                (this.props.project && !isEditableProject(this.props.fileType, this.props.project)) ||
                                (!!this.props.collectionId && (!this.props.collectionData || this.props.collectionData?.locked))
                            }
                            startIcon={<WedIcon size={SizeType.SMALL} icon={<WedIconShare />} />}
                            onClick={this.handleClickOpenRemoveFromCollectionPopup}
                        >
                            <FormattedMessage id="app.ProjectFilesMediaGalleryButtons.removeFromCollection" />
                        </Button>
                    </>
                )}
            </WedMenuButtons>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface) => {
    return {
        ...props,
        ...(state.wedImageGalleryData || {}),
    };
};

export default connect(mapStateToProps)(withRouter(withSnackbar(injectIntl(FilesMediaGalleryButtons))));
