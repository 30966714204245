import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { IProjectExtended, ProjectStatusEnum } from "../../../../services/WedApi/Models/Project";
import WedDate from "../../../formatter/WedDate";
import InformationSummaryViewDetails from "../../../summary/InformationSummaryViewDetails/InformationSummaryViewDetails";

interface PropsInterface extends WrappedComponentProps {
    project: IProjectExtended;
}

interface StateInterface {}

class ProjectInformationSummaryViewDetails extends React.Component<PropsInterface, StateInterface> {
    private getProjectLabelStatus(status: ProjectStatusEnum): string {
        switch (status) {
            case ProjectStatusEnum.active:
                return this.props.intl.formatMessage({ id: "app.project.status.active" });
            case ProjectStatusEnum.closed:
                return this.props.intl.formatMessage({ id: "app.project.status.closed" });
            case ProjectStatusEnum.archived:
                return this.props.intl.formatMessage({ id: "app.project.status.archived" });
        }
        return status as string;
    }

    render() {
        const { project } = this.props;

        return (
            <InformationSummaryViewDetails
                items={[
                    {
                        label: this.props.intl.formatMessage({ id: "app.ProjectInformationSummaryViewDetails.info.id" }),
                        value: `${project.id}`,
                    },
                    {
                        label: this.props.intl.formatMessage({ id: "app.ProjectInformationSummaryViewDetails.info.created" }),
                        value: (
                            <>
                                {project.created && <WedDate date={project.created} />}
                                {project.createdBy && (
                                    <>
                                        <span className="information-summary-view-details--list-value-content-small">
                                            <FormattedMessage id={"app.global.by"} />
                                        </span>
                                        {`${project.createdBy.firstName} ${project.createdBy.lastName}`}
                                    </>
                                )}
                            </>
                        ),
                    },
                    {
                        label: this.props.intl.formatMessage({ id: "app.ProjectInformationSummaryViewDetails.info.modified" }),
                        value: (
                            <>
                                {project.updated && project.updatedBy && <WedDate date={project.updated} />}
                                {project.updated && project.updatedBy && (
                                    <>
                                        <span className="information-summary-view-details--list-value-content-small">
                                            <FormattedMessage id={"app.global.by"} />
                                        </span>
                                        {`${project.updatedBy.firstName} ${project.updatedBy.lastName}`}
                                    </>
                                )}
                            </>
                        ),
                    },
                    {
                        label: this.props.intl.formatMessage({ id: "app.ProjectInformationSummaryViewDetails.info.status" }),
                        value: (
                            <>
                                <span className="information-summary-view-details--status-label">{this.getProjectLabelStatus(project.status)}</span>
                            </>
                        ),
                    },
                ]}
            />
        );
    }
}

export default injectIntl(ProjectInformationSummaryViewDetails);
