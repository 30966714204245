import { IOwner } from "../Models/User";
import UserActionApiService from "../UserActionApiService";
import TrashActionApiService from "../TrashActionApiService";
import { ITrash } from "../Models/Trash";
import { ConvertApiFileContextEnum, convertIApiFolderToIFolder, convertIApiObjectToIFile } from "./BaseConverter";
import { uniqueValues } from "../Utils/ItemCollection";
import FilesActionApiService from "../FilesActionApiService";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import { LogicError } from "../../../errors/error-app";

export class TrashService {
    constructor(
        private readonly trashActionApiService: TrashActionApiService,
        private readonly filesActionApiService: FilesActionApiService,
        private readonly userActionApiService: UserActionApiService
    ) {}

    async getTrash(type: FileTypeEnum, projectId?: number): Promise<ITrash> {
        let trashResponse;
        if (
            [
                FileTypeEnum.MY_LIBRARY_FILES,
                FileTypeEnum.MY_LIBRARY_SHARED_WITH_ME,
                FileTypeEnum.MY_LIBRARY_COLLECTIONS,
                FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES,
            ].includes(type)
        ) {
            trashResponse = await this.trashActionApiService.getMyLibraryTrash();
        } else {
            if (!projectId) {
                throw new LogicError("projectId is required.");
            }
            trashResponse = await this.trashActionApiService.getTrash(projectId);
        }

        const userIds: number[] = [];
        trashResponse.objects.forEach((object) => {
            if (object.moveToBinBy) {
                userIds.push(object.moveToBinBy);
            }
        });
        trashResponse.subfolders.forEach((folder) => {
            if (folder.moveToBinBy) {
                userIds.push(folder.moveToBinBy);
            }
        });
        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniqueValues(userIds))).items : [];

        return {
            directories: trashResponse.subfolders.map((folder) => {
                return convertIApiFolderToIFolder(folder, users);
            }),
            files: trashResponse.objects.map((object) => {
                return convertIApiObjectToIFile(object, users, ConvertApiFileContextEnum.library);
            }),
        };
    }

    async restoreFromTrash(directoryIds: number[], fileIds: number[]): Promise<void> {
        return this.doItAction("restore", directoryIds, fileIds);
    }

    async deleteFromTrash(directoryIds: number[], fileIds: number[]): Promise<void> {
        return this.doItAction("delete", directoryIds, fileIds);
    }

    private async doItAction(action: "restore" | "delete", directoryIds: number[], fileIds: number[]): Promise<void> {
        const promises: Promise<void>[] = [];
        if (directoryIds.length > 0) {
            promises.push(this.filesActionApiService.actionFolder(directoryIds, action));
        }
        if (fileIds.length > 0) {
            promises.push(this.filesActionApiService.actionFile(fileIds, action));
        }
        return Promise.all(promises).then(() => {
            return undefined;
        });
    }
}
