import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Members } from "../../../members/members";
import { IUserBase } from "../../../../services/WedApi/Models/User";
import User from "../../../../domain/User";
import { ProviderContext, withSnackbar } from "notistack";
import "./SharingTeamMembersCollection.scss";

export enum typeSharingCollection {
    MEMBERS = "members",
    EXTERNAL_MEMBERS = "externalMembers",
}

interface StateInterface {
    fields: {
        teamMembers: IUserBase[];
    };
    teamMembersSharing: IUserBase[];
    teamMembersNoSharing: IUserBase[];
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    setFieldsMembers: (userIds: number[]) => void;
    selectedMembersIds: number[];
    items: IUserBase[];
    clientId?: number;
    type: typeSharingCollection;
    labelName: string;
}

class SharingTeamMembersCollection extends React.Component<PropsInterface, StateInterface> {
    private usersIdSharing: number[] = [];

    readonly state = {
        teamMembersSharing: [],
        teamMembersNoSharing: [],
        fields: {
            teamMembers: [],
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.deleteMember = this.deleteMember.bind(this);
    }

    async componentDidMount() {
        this.usersIdSharing = this.props.selectedMembersIds;
        this.setStateTeamMembers();
    }

    private setStateTeamMembers() {
        const teamMembersSharing: IUserBase[] = [];
        const teamMembersNoSharing: IUserBase[] = [];

        for (const user of this.props.items) {
            if (this.usersIdSharing.includes(user.id)) {
                teamMembersSharing.push(user);
            } else {
                teamMembersNoSharing.push(user);
            }
        }

        this.setState({
            teamMembersSharing: teamMembersSharing,
            teamMembersNoSharing: teamMembersNoSharing,
        });
    }

    private deleteMember(id: number) {
        this.usersIdSharing = this.usersIdSharing.filter((userIdSharing) => userIdSharing !== id);
        this.setStateTeamMembers();
        this.props.setFieldsMembers([...this.usersIdSharing, ...this.state.fields.teamMembers]);
    }

    private setBaUsers(users: IUserBase[]) {
        const usersIds = users.map((user) => {
            return user.id;
        });

        this.props.setFieldsMembers([...usersIds, ...this.usersIdSharing]);
    }

    render() {
        return (
            <div className={"sharing-team-members-collection"}>
                <Autocomplete
                    multiple
                    fullWidth
                    id={`teamMembers-${this.props.type}`}
                    options={this.state.teamMembersNoSharing}
                    getOptionLabel={(option: IUserBase) => `${option.firstName} ${option.lastName}`}
                    // eslint-disable-next-line
                    onChange={(event: React.ChangeEvent<any>, value: IUserBase[]) => {
                        this.setBaUsers(value);
                        this.setState({
                            fields: {
                                teamMembers: value,
                            },
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            required
                            label={<FormattedMessage id={this.props.labelName} />}
                            name={`teamMembers-${this.props.type}`}
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                        />
                    )}
                />
                <Members
                    users={this.state.teamMembersSharing.map((user: IUserBase) => {
                        return new User(user);
                    })}
                    delete={this.deleteMember}
                />
            </div>
        );
    }
}

export default withSnackbar(injectIntl(SharingTeamMembersCollection));
