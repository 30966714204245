// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function onlyUnique(value: any, index: any, self: any) {
    return self.indexOf(value) === index;
}

export function findInCollection<T extends { id: number }>(id: number, items: T[]): T | undefined {
    return items.find((item) => item.id === id);
}

export function uniqueValues<T>(arr: T[]): T[] {
    return arr.filter(onlyUnique);
}
