import WedMediaGalleryVersion from "../WedMediaGalleryVersion/WedMediaGalleryVersion";
import React from "react";
import { IFile } from "../../../services/WedApi/Models/File";
import WedMediaGalleryPreview from "../WedMediaGalleryPreview/WedMediaGalleryPreview";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import { WedImageGalleryDataCollectionProps } from "../../../store/WedImageGalleryReducer";
import { RefreshMediaGalleryParams } from "../../../pages/FilesMediaGalleryPage/FilesMediaGalleryPage";

interface PropsInterface {
    projectId?: number;
    isEditable?: boolean;
    files?: IFile[];
    fileId: number;
    versionId?: number;
    collectionId?: number;
    collectionData?: WedImageGalleryDataCollectionProps;
    userTeamId?: number;
    dirId?: number;
    token?: string;
    onChangeFile: (fileId: number) => void;
    fileType: FileTypeEnum;
    onRefreshMediaGallery: (params?: RefreshMediaGalleryParams) => void;
    userTeamType?: boolean;
}

interface StateInterface {
    versionId?: number;
}

export default class WedMediaGalleryMainContent extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = { versionId: props.versionId };
    }

    render() {
        return (
            <div className="wed-media-gallery__main-content">
                <div className="wed-media-gallery__box wed-media-gallery__version">
                    <WedMediaGalleryVersion
                        fileType={this.props.fileType}
                        onChangeFileVersion={(versionId?: number) => {
                            this.setState({ versionId: versionId });
                        }}
                        files={this.props.files}
                        objectId={this.props.fileId}
                        collectionId={this.props.collectionId}
                        token={this.props.token}
                        userTeamId={this.props.userTeamId}
                    />
                </div>
                <div className="wed-media-gallery__box wed-media-gallery__preview">
                    <WedMediaGalleryPreview
                        fileType={this.props.fileType}
                        projectId={this.props.projectId}
                        isEditable={this.props.isEditable}
                        onChangeFile={this.props.onChangeFile}
                        files={this.props.files}
                        objectId={this.props.fileId}
                        collectionId={this.props.collectionId}
                        collectionData={this.props.collectionData}
                        dirId={this.props.dirId}
                        userTeamId={this.props.userTeamId}
                        versionId={this.state.versionId}
                        token={this.props.token}
                        onRefreshMediaGallery={this.props.onRefreshMediaGallery}
                        userTeamType={this.props.userTeamType}
                    />
                </div>
            </div>
        );
    }
}
