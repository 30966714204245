import React from "react";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { ProjectStatusEnum } from "../../../../services/WedApi/Models/Project";
import "./project-status.scss";

interface PropsInterface extends WrappedComponentProps {
    status: ProjectStatusEnum;
}

interface StateInterface {}

class ProjectStatus extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { status } = this.props;
        const statusColor = ProjectStatusEnum.active === status ? "project-status--active" : "";

        return (
            <div className={`project-status ${statusColor}`}>
                <FormattedMessage id={`app.project.status.${status}`} />
            </div>
        );
    }
}

export default injectIntl(ProjectStatus);
