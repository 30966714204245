import _ from "lodash";
// unstable_createMuiStrictModeTheme fix for warnings in strict mode, https://github.com/mui-org/material-ui/issues/13394
import { Theme as MuiTheme, unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";
import { Shadows as MuiShadows } from "@material-ui/core/styles/shadows";
import { Palette as MuiPalette, TypeBackground as MuiTypeBackground } from "@material-ui/core/styles/createPalette";
import { strongShadows } from "./shadows";
import typography from "./typography";
import { Overrides } from "@material-ui/core/styles/overrides";
import { Localization } from "@material-ui/core/locale";

interface TypeBackground extends MuiTypeBackground {
    dark: string;
}

interface Palette extends MuiPalette {
    background: TypeBackground;
}

export interface Theme extends MuiTheme {
    name: string;
    palette: Palette;
}

interface ThemeConfig {
    theme?: string;
}

interface ThemeOptions {
    name?: string;
    typography?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    overrides?: Overrides;
    palette?: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
    shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
    typography,
    overrides: {
        MuiFormControlLabel: {
            root: {
                userSelect: "none",
            },
        },
        MuiLinearProgress: {
            root: {
                borderRadius: 3,
                overflow: "hidden",
            },
        },
        MuiListItemIcon: {
            root: {
                minWidth: 32,
            },
        },
        MuiChip: {
            root: {
                backgroundColor: "rgba(0,0,0,0.075)",
            },
        },
        MuiSelect: {
            select: {
                "&:focus": {
                    "background-color": "none",
                },
            },
        },
        MuiTextField: {
            root: {
                "& .MuiInputBase-root": {
                    "min-height": "48px",
                },
                "& .MuiInputLabel-outlined": {
                    transform: "translate(17px, 17px) scale(1)",
                },
                "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
                    transform: "translate(17px, -6px) scale(0.75)",
                },
            },
        },
        MuiInputBase: {
            root: {
                "& .MuiSelect-select": {
                    height: "32px",
                    "font-size": "16px",
                    color: "rgba(255, 255, 255, 0.65)",
                },
                "& fieldset": {
                    borderRadius: "6px",
                },
                "& .MuiSelect-select option": {
                    "background-color": "#0f0f0f !important",
                },
                "& .MuiOutlinedInput-input": {
                    padding: "0 41px 0px 18px",
                    "font-size": "16px",
                    "font-weight": 500,
                    "font-stretch": "normal",
                    "font-style": "normal",
                    "letter-spacing": "0.4px",
                },
            },
        },
        MuiPaper: {
            root: {
                "&": {
                    "background-color": "#454545",
                },
            },
        },
        MuiButton: {
            root: {
                "&": {
                    "min-width": "auto",
                },
            },
        },
        MuiOutlinedInput: {
            multiline: {
                "padding-left": 0,
            },
            notchedOutline: {
                "&": {
                    border: "1px solid rgba(255, 255, 255, 0.15)",
                },
            },
            root: {
                "& $notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.15)",
                },
                "&:hover $notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.65)",
                },
                "&$focused $notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.15)",
                },
            },
        },
        MuiTooltip: {
            tooltip: {
                "font-size": "0.75rem",
            },
        },
    },
};

const AppThemeEnumOptions: ThemeOptions[] = [
    {
        name: "DARK",
        palette: {
            type: "dark",
            action: {
                active: "rgba(255, 255, 255, 0.54)",
                hover: "rgba(255, 255, 255, 0.04)",
                selected: "rgba(255, 255, 255, 0.08)",
                disabled: "rgba(255, 255, 255, 0.26)",
                disabledBackground: "rgba(255, 255, 255, 0.12)",
                focus: "rgba(255, 255, 255, 0.12)",
            },
            background: {
                default: "#171717",
                dark: "#1c2025",
                paper: "#282C34",
            },
            primary: {
                main: "rgba(255, 255, 255, 0.65)",
            },
            secondary: {
                main: "rgba(255, 255, 255, 0.65)",
            },
            text: {
                primary: "#e6e5e8",
                secondary: "#9f9f9f",
            },
        },
        overrides: {
            MuiPaper: {
                root: {
                    "&.MuiAlert-standardInfo": {
                        "background-color": "#3498db",
                        color: "#fff",
                    },
                    "&.MuiAlert-standardSuccess": {
                        "background-color": "#00bc8c",
                        color: "#fff",
                    },
                },
            },
            MuiButton: {
                root: {
                    background: "linear-gradient(44deg, #353535 30%, #292929 90%)",
                    "border-radius": "6px",
                    border: "solid 1px rgba(255, 255, 255, 0.11)",
                    "font-size": "14px",
                    "font-weight": 400,
                    padding: "4px 10px",
                    "letter-spacing": "0.35px",
                    "&.button--more-padding": {
                        "padding-left": "31px",
                        "padding-right": "31px",
                    },
                },
                text: {
                    padding: "3px 12px",
                },
                startIcon: {
                    "margin-left": "-4px",
                    "margin-right": "13px",
                },
                iconSizeMedium: {
                    "& > *:first-child": {
                        "font-size": "24px",
                    },
                },
            },
            MuiBackdrop: {
                root: {
                    "backdrop-filter": "blur(6px)",
                },
            },
            MuiDialog: {
                paper: {
                    border: "solid 1px rgba(176, 184, 200, 0.1)",
                    "border-radius": "6px",
                    opacity: "0.9",
                    "box-shadow": "0px 3px 24px 0 rgba(0, 0, 0, 0.64)",
                    "min-height": "100px",
                    "background-color": "#232323",
                },
                paperWidthSm: {
                    "max-width": "790px",
                },
            },
            MuiDialogActions: {
                root: {
                    "margin-right": "-8px",
                },
            },
        },
        shadows: strongShadows,
        typography: {
            fontFamily: ["Montserrat", "sans-serif"].join(","),
        },
    },
];

export const createTheme = (config: ThemeConfig = {}, locale: Localization): Theme => {
    const themeOptions = AppThemeEnumOptions.find((theme) => theme.name === config.theme);
    const theme = createMuiTheme(_.merge({}, baseOptions, themeOptions), locale);

    return theme as Theme;
};
