import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import TermsAndConditionsText from "./TermsAndConditionsText";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import { FormattedMessage } from "react-intl";
import { getScrollbarIconYStyle, getScrollbarYStyle } from "../../../services/WedApi/Utils/ScrollbarsStyles";
import { Scrollbars } from "react-custom-scrollbars";
import { Button } from "@material-ui/core";
import PopupAction from "../../popup/PopupAction/PopupAction";
import "./UserRegisterTermsAndConditionsPopup.scss";

interface PropsInterface {
    open: boolean;
    handleClose: (isUpdate?: boolean) => void;
    handleSuccess: () => void;
}

interface StateInterface {}

export class UserRegisterTermsAndConditionsPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
    }

    private handleClose() {
        this.props.handleClose(false);
    }

    private handleSuccess() {
        this.props.handleClose(true);
    }

    render() {
        return (
            <Popup open={this.props.open} handleClose={this.handleClose} maxWidth="md" className="terms-and-conditions-popup">
                <PopupContent withBackground={true}>
                    <div className="terms-and-conditions">
                        <PopupHeader positionHeader={PositionTitle.LEFT}>
                            <FormattedMessage id="app.register.terms.popup.header" />
                        </PopupHeader>
                        <div className="popup-content-margin terms-and-conditions--container">
                            <Scrollbars
                                universal
                                renderTrackVertical={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarYStyle(),
                                    });
                                }}
                                renderThumbVertical={(props) => {
                                    return React.createElement("div", {
                                        ...props,
                                        style: getScrollbarIconYStyle(),
                                    });
                                }}
                            >
                                <div className="terms-and-conditions--container-body">
                                    <div className="terms-and-conditions--body">
                                        <div dangerouslySetInnerHTML={{ __html: TermsAndConditionsText.html }}></div>
                                    </div>
                                </div>
                            </Scrollbars>
                        </div>
                        <PopupAction>
                            <Button
                                type="submit"
                                onClick={() => {
                                    this.props.handleSuccess();
                                }}
                            >
                                <FormattedMessage id={"app.register.terms.popup.button.ok"} />
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => {
                                    this.props.handleClose();
                                }}
                            >
                                <FormattedMessage id={"cancel"} />
                            </Button>
                        </PopupAction>
                    </div>
                </PopupContent>
            </Popup>
        );
    }
}
