import { FilesService } from "./FilesService";
import { CollectionService } from "./CollectionService";
import { IFolder } from "../Models/File";
import { ICollection } from "../Models/Collection";
import { delay } from "../Utils/Delay";
import Uniq from "lodash/uniq";
import { ISelectItem, ISelectItemType } from "../../../components/status-action-button/StatusActionButton";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";

export interface IObjectItem {
    id: number;
    type: "directory" | "file";
}

export interface ICollectionForObjectItem {
    collection: Array<{
        id: number;
        name: string;
        filesToAdd: number[];
        locked: boolean;
        userIsOwner: boolean;
    }>;
    selectedItems: IObjectItem[];
}

export class CollectionAddFilesService {
    constructor(private readonly collectionService: CollectionService, private readonly filesService: FilesService) {}

    convertSelectItemsToObjectItems(selectedItems: ISelectItem[]): IObjectItem[] {
        return selectedItems.reduce((items: IObjectItem[], selectedItem) => {
            if (selectedItem.type === ISelectItemType.directory) {
                return [...items, { type: "directory", id: selectedItem.id }];
            } else if (selectedItem.type === ISelectItemType.file) {
                return [...items, { type: "file", id: selectedItem.id }];
            }
            return items;
        }, [] as IObjectItem[]);
    }

    async getCollectionForObjectItems(objectItems: IObjectItem[], fileType: FileTypeEnum, projectId?: number): Promise<ICollectionForObjectItem> {
        const [fileIds, collections] = await Promise.all([
            this.getFileIdsByObjectItems(objectItems, fileType),
            this.collectionService.getCollections(projectId),
        ]);

        return {
            selectedItems: objectItems,
            collection: collections.map((collection) => {
                return {
                    id: collection.id,
                    name: collection.name,
                    locked: collection.locked,
                    userIsOwner: collection.userIsOwner,
                    filesToAdd: this.getFileIdsNotExistedInCollection(collection, fileIds),
                };
            }),
        };
    }

    async updateCollectionByObjectItems(collectionId: number, selectedItems: IObjectItem[], fileType: FileTypeEnum): Promise<void> {
        const fileIds = await this.getFileIdsByObjectItems(selectedItems, fileType);
        const collection = await this.collectionService.getCollectionDetail(collectionId);
        const filesToAdd = this.getFileIdsNotExistedInCollection(collection, fileIds);
        if (filesToAdd.length === 0) {
            await delay(100);
        } else {
            await this.collectionService.addFilesToCollection(collection.id, filesToAdd);
        }
    }

    private getFileIdsNotExistedInCollection(collection: ICollection, fileIds: number[]): number[] {
        const collectionFileIds = collection.files.map((file) => file.id);
        return fileIds.filter((fileId) => !collectionFileIds.includes(fileId));
    }

    private async getFileIdsByObjectItems(selectedItems: IObjectItem[], fileType: FileTypeEnum): Promise<number[]> {
        const folders = await Promise.all(
            selectedItems
                .filter((selectedItem) => selectedItem.type === "directory")
                .map((selectedItem) => this.filesService.getFolder(fileType, selectedItem.id))
        );

        return Uniq([
            ...folders.reduce((fileIds: number[], folder: IFolder) => {
                return [...fileIds, ...folder.files.map((file) => file.id)];
            }, [] as number[]),
            ...selectedItems.filter((selectedItem) => selectedItem.type === "file").map((selectedItem) => selectedItem.id),
        ]);
    }
}
