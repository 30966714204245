import React from "react";
import { ProjectMenu } from "../../../components/main-menu/ProjectMenu/ProjectMenu";
import { WedMainMenuContainer } from "../../../components/main-menu/WedMainMenuContainer/WedMainMenuContainer";
import { ProjectDetailLoaderProps } from "../../../components/project/ProjectDetailLoader/ProjectDetailLoader";
import ProjectSummaryButtons from "./ProjectSummaryButtons/ProjectSummaryButtons";
import ProjectInformationSummary from "../../../components/project/Summary/ProjectInformationSummary/ProjectInformationSummary";
import ProjectTeamSummary from "../../../components/project/Summary/ProjectTeamSummary/ProjectTeamSummary";
import LinearProgress from "@material-ui/core/LinearProgress";
import container from "../../../container";
import UserProjectEditTeamMembersPopup from "../../../components/user/UserProjectEditTeamMembersPopup/UserProjectEditTeamMembersPopup";
import { ITeamUser, IUser } from "../../../services/WedApi/Models/User";
import { getProjectTeamMembers } from "../../../services/WedApi/Utils/UserTeamMembers";
import uniq from "lodash/uniq";
import _ from "lodash";
import { FileTypeEnum } from "../../../components/template/Type/FileTypeEnum";
import MenuControlIcons from "../../../components/main-menu/MenuControlIcons/MenuControlIcons";
import { CreateProjectPopup } from "../../../components/project/Project/CreateProjectPopup/CreateProjectPopup";
import SummaryPageLayout from "../../../components/summary/SummaryPageLayout/SummaryPageLayout";
import SummaryPageBoxContainer from "../../../components/summary/SummaryPageBoxContainer/SummaryPageBoxContainer";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import ProjectStat, { ProjectStatType } from "../../../components/tile/components/ProjectStat/ProjectStat";
import ProjectSummaryPageProofingBox from "./ProjectSummaryPageProofingBox";
import { ICommentNote, ICommentNoteType } from "../../../services/WedApi/Models/Note";
import ProjectSummaryPageRecentCommentsBox from "./ProjectSummaryPageRecentCommentsBox";

interface PropsInterface extends WrappedComponentProps, ProjectDetailLoaderProps {}

interface StateInterface {
    isLoading: boolean;
    userProjectEditTeamMembersPopup?: { userMember: ITeamUser[]; customerContacts: IUser[] };
    createProjectPopup: boolean;
    clientUserMembers: IUser[];
    clientUserContacts: IUser[];
    commentsAndProofs: ICommentNote[];
}

class ProjectSummaryPage extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            isLoading: false,
            userProjectEditTeamMembersPopup: undefined,
            createProjectPopup: false,
            clientUserMembers: [],
            clientUserContacts: [],
            commentsAndProofs: [],
        };
        this.handleClickCreateProject = this.handleClickCreateProject.bind(this);
        this.handleClickAddProjectMembersClick = this.handleClickAddProjectMembersClick.bind(this);
        this.handleProjectChange = this.handleProjectChange.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.handleCloseCreateProject = this.handleCloseCreateProject.bind(this);
    }

    async componentDidMount() {
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!_.isEqual(prevProps, this.props)) {
            await this.fetchData();
        }
    }

    private handleClickCreateProject() {
        this.setState({ createProjectPopup: true });
    }

    private handleClickAddProjectMembersClick() {
        this.setState({
            userProjectEditTeamMembersPopup: {
                userMember: getProjectTeamMembers(this.props.project.owners, this.props.project.members),
                customerContacts: this.props.project.ecUsers,
            },
        });
    }

    private async handleCloseCreateProject() {
        await this.closePopup(false);
    }

    private async closePopup(changed?: boolean) {
        this.setState({
            userProjectEditTeamMembersPopup: undefined,
            createProjectPopup: false,
        });
        if (changed) {
            await this.handleProjectChange();
        }
    }

    private async fetchData() {
        const [clientUserMembers, customerClientContacts, commentsAndProofs] = await Promise.all([
            container.userService.getByIds(
                uniq(([] as number[]).concat(this.props.project.ecOrg.membersIds || [], this.props.project.ecOrg.ownerIDs || []))
            ),
            container.clientContactService.getList(this.props.project.ecOrg.id),
            await container.noteService.getCommentNotesSummaryPage(this.props.project.id, {
                ecUsers: this.props.project.ecUsers,
                ecOrgId: this.props.project.ecOrg.id,
            }),
        ]);

        this.setState({
            isLoading: false,
            clientUserMembers,
            clientUserContacts: customerClientContacts.filter(
                (contact) => this.props.project.ecOrg.contactIDs && this.props.project.ecOrg.contactIDs.includes(contact.id)
            ) as IUser[],
            commentsAndProofs,
        });
    }

    private async handleProjectChange() {
        this.setState({
            isLoading: true,
        });
        this.props.fetchProject();
    }

    render() {
        const { isLoading } = this.state;
        const { project } = this.props;
        if (isLoading) {
            return <LinearProgress />;
        }

        return (
            <div className="project-summary-page">
                <UserProjectEditTeamMembersPopup
                    projectId={project.id}
                    close={this.closePopup}
                    userMember={this.state.userProjectEditTeamMembersPopup?.userMember}
                    clientUserMembers={this.state.clientUserMembers}
                    clientCustomerContacts={this.state.clientUserContacts}
                    customerContacts={this.state.userProjectEditTeamMembersPopup?.customerContacts}
                />
                <CreateProjectPopup open={this.state.createProjectPopup} handleClose={this.handleCloseCreateProject} />
                <WedMainMenuContainer
                    menu={<ProjectMenu projectId={project.id} activeTab="summary" />}
                    buttons={
                        <ProjectSummaryButtons
                            project={project}
                            createProjectClick={this.handleClickCreateProject}
                            addProjectMembersClick={project.userIsOwner ? this.handleClickAddProjectMembersClick : undefined}
                            setIsLoading={() => {
                                this.setState({ isLoading: true });
                            }}
                            actionCallback={async () => {
                                await this.props.fetchProject();
                            }}
                        />
                    }
                    sidebar={
                        <MenuControlIcons
                            project={project}
                            disableChangeViewMode={true}
                            disableSidebarOpen={true}
                            fileType={FileTypeEnum.PROJECT_FILES}
                        />
                    }
                />
                <SummaryPageLayout
                    className="project-summary-page--container"
                    leftSide={<ProjectInformationSummary className="project-summary-page--box-content" project={project} />}
                    rightSide={
                        <ProjectTeamSummary
                            handleProjectChange={this.handleProjectChange}
                            className="project-summary-page--box-content"
                            project={project}
                        />
                    }
                    boxInformation={[
                        <SummaryPageBoxContainer key={0} header={<FormattedMessage id={"app.ProjectInformationSummary.box.collection.title"} />}>
                            <div className="project-tile__stats">
                                <ProjectStat
                                    count={this.props.project.rejectedCollections}
                                    title={this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.collection.rejected" })}
                                    type={ProjectStatType.REJECTED}
                                />
                                <ProjectStat
                                    count={this.props.project.approvedCollections}
                                    title={this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.collection.approved" })}
                                    type={ProjectStatType.ACCEPTED}
                                />
                                <ProjectStat
                                    count={this.props.project.allCollections}
                                    title={this.props.intl.formatMessage({ id: "app.ProjectInformationSummary.box.collection.all" })}
                                    type={ProjectStatType.STANDARD}
                                />
                            </div>
                        </SummaryPageBoxContainer>,
                        <SummaryPageBoxContainer key={1} header={<FormattedMessage id={"app.ProjectInformationSummary.box.approvals.title"} />}>
                            <ProjectSummaryPageProofingBox
                                proofs={this.state.commentsAndProofs.filter((item) => item.type === ICommentNoteType.OBJECT_PROOF)}
                            />
                        </SummaryPageBoxContainer>,
                        <SummaryPageBoxContainer key={2} header={<FormattedMessage id={"app.ProjectInformationSummary.box.recentComments.title"} />}>
                            <ProjectSummaryPageRecentCommentsBox
                                comments={this.state.commentsAndProofs.filter((item) => item.type !== ICommentNoteType.OBJECT_PROOF)}
                            />
                        </SummaryPageBoxContainer>,
                    ]}
                />
            </div>
        );
    }
}

export default injectIntl(ProjectSummaryPage);
