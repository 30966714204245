import React from "react";
import SidebarSubContent from "../SidebarSubContent/SidebarSubContent";
import { FormattedMessage } from "react-intl";
import TagList from "../../tile/components/TagList/TagList";
import { WedIconSettings6 } from "../../wed-icon/generated/WedIconSvg";
import "./SidebarTags.scss";

interface PropsInterface {
    tags: string[];
    userCanEdit?: boolean;
    onOpenTagsEditPopup: () => void;
}

interface StateInterface {}

class SidebarTags extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <SidebarSubContent
                className="sidebar-initials"
                rightIcon={<WedIconSettings6 />}
                handleClickIcon={this.props.onOpenTagsEditPopup}
                disabledClickIcon={!this.props.userCanEdit}
                title={<FormattedMessage id="app.sidebar.tags.title" />}
                content={<TagList tags={this.props.tags} />}
            />
        );
    }
}

export default SidebarTags;
