import { ICollection } from "../../WedApi/Models/Collection";
import CollectionFilterInterface from "./CollectionFilterInterface";
import { CreatedByEnum } from "../../WedApi/Models/Common";
import { searchTextFilter } from "../SearchTextFilter";
import Collection from "../../../domain/Collection";

export default class CollectionFilterService {
    public filter(collections: ICollection[], filters: CollectionFilterInterface) {
        let filterData = collections;
        if (filters.status) {
            filterData = filterData.filter((collection) => {
                return collection.status === filters.status;
            });
        }

        if (filters.createdBy) {
            filterData = filterData.filter((collection) => {
                if (CreatedByEnum.me === filters.createdBy) {
                    return filters["loggedUserId"] && collection.createdBy?.id && collection.createdBy.id === filters["loggedUserId"];
                } else if (CreatedByEnum.others === filters.createdBy) {
                    return filters["loggedUserId"] && collection.createdBy?.id && collection.createdBy.id !== filters["loggedUserId"];
                } else {
                    return true;
                }
            });
        }

        if (filters.searchText) {
            filterData = searchTextFilter(filters.searchText, filterData, [{ name: "name", weight: 1 }]);
        }

        return filterData;
    }

    public findById(collections: Collection[], id: number): Collection | undefined {
        return collections.find((collection) => collection.getId() === id);
    }

    public filterDataWithoutCollectionId(collections: ICollection[], collectionId: number) {
        return collections.filter((collection) => {
            return collection.id !== collectionId;
        });
    }
}
