import React from "react";
import classnames from "classnames";
import { Collapse } from "@material-ui/core";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedIconArrow } from "../../wed-icon/generated/WedIconSvg";
import WedDate from "../../formatter/WedDate";
import { FormattedMessage } from "react-intl";
import { IFileVersionProof } from "../../../services/WedApi/Models/File";
import "./SidebarApprovalStatus.scss";
import ItemDotStatus from "../../tile/components/ItemDotStatus/ItemDotStatus";
import { getCollectionFileDotStatusColorByApprovalFlag } from "../../../services/WedApi/Utils/CollectionDotStatusColor";

interface PropsInterface {
    title: string;
    proofs: IFileVersionProof[];
}

interface StateInterface {
    hide: boolean;
}

class SidebarApprovalStatus extends React.Component<PropsInterface> {
    readonly state: StateInterface = {
        hide: false,
    };

    toggleCollapse = () => {
        const { hide } = this.state;

        this.setState({ hide: !hide });
    };

    render() {
        const { title, proofs } = this.props;
        const { hide } = this.state;

        return (
            <div className="sidebar-approval">
                <h3
                    onClick={this.toggleCollapse}
                    className={classnames("sidebar-approval__title", {
                        "sidebar-approval__title--hide": hide,
                    })}
                >
                    {title}
                    <WedIcon icon={<WedIconArrow />} size={SizeType.NORMAL} />
                </h3>
                <Collapse className="sidebar-approval__container" in={!hide} timeout="auto" unmountOnExit>
                    {proofs.map((proof, i) => {
                        return (
                            <div key={i} className="sidebar-approval__item">
                                <ItemDotStatus color={getCollectionFileDotStatusColorByApprovalFlag(proof.flag)} />
                                <span className="sidebar-approval__item-date">
                                    {proof.created && <WedDate date={proof.created} fullTime={true} />}
                                </span>
                                <span className="sidebar-approval__item-by">
                                    <FormattedMessage id={"app.SidebarApprovalStatus.ecUserBy"} />
                                </span>
                                <span className="sidebar-approval__item-username">{`${proof.firstName} ${proof.lastName}`}</span>
                            </div>
                        );
                    })}
                </Collapse>
            </div>
        );
    }
}

export default SidebarApprovalStatus;
