import React, { Component } from "react";
import { Container, Typography } from "@material-ui/core";
import classNames from "classnames";
import NotLoggedPage from "../NotLoggedPage/NotLoggedPage";
import "./NotLoggedPopupPage.scss";
import WedImageLogo160x160 from "../../wed-image-logo/WedImageLogo160x160";

interface PropsInterface {
    beforeHeader?: React.ReactNode;
    header?: React.ReactNode;
    children?: React.ReactNode | React.ReactNodeArray;
    maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
    className?: string;
    withLogo?: boolean;
}

interface StateInterface {}

class NotLoggedPopupPage extends Component<PropsInterface, StateInterface> {
    componentDidMount() {
        this.getBodyElement()?.classList.add("not-logged-popup-page--body-container");
    }

    componentWillUnmount() {
        this.getBodyElement()?.classList.remove("not-logged-popup-page--body-container");
    }

    private getBodyElement(): HTMLBodyElement | undefined {
        const bodyElement = document.getElementsByTagName("body");
        if (bodyElement.length > 0) {
            return bodyElement[0];
        }
        return undefined;
    }

    render() {
        return (
            <NotLoggedPage>
                <Container
                    component="main"
                    maxWidth={this.props.maxWidth}
                    className={classNames("not-logged-popup-page", { "not-logged-popup-page--with-logo": this.props.withLogo }, this.props.className)}
                >
                    <div className="not-logged-popup-page--container">
                        <div className="not-logged-popup-page--content">
                            {this.props.beforeHeader}
                            {this.props.header && (
                                <div className="not-logged-popup-page--header">
                                    <Typography component="h1" variant="h5" className="login-header">
                                        {this.props.header}
                                    </Typography>
                                </div>
                            )}
                            {this.props.children}
                        </div>
                        {this.props.withLogo && (
                            <div className="not-logged-popup-page--logo">
                                <WedImageLogo160x160 />
                            </div>
                        )}
                    </div>
                </Container>
            </NotLoggedPage>
        );
    }
}

export default NotLoggedPopupPage;
