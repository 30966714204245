import * as React from "react";
import "./WedMenuButtons.scss";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

export class WedMenuButtons extends React.Component<PropsInterface> {
    render() {
        return <div className="wed-menu-buttons">{this.props.children}</div>;
    }
}
