import { IFileSimple } from "../Models/File";

export function isPdfFileExtension(extension: string): boolean {
    if (extension) {
        extension = extension.toLowerCase();
        return extension === "pdf";
    }
    return false;
}

export function isFileHtmlImageExtension(extension: string | undefined): boolean {
    if (extension) {
        extension = extension.toLowerCase();
        switch (extension) {
            case "gif":
            case "png":
            case "jpg":
            case "tiff":
            case "bmp":
            case "jpeg":
            case "webp":
            case "svg":
                return true;
        }
    }
    return false;
}

export function isFileHtmlVideoExtension(extension: string): boolean {
    if (extension) {
        extension = extension.toLowerCase();
        switch (extension) {
            case "mp4":
            case "webm":
                return true;
        }
    }
    return false;
}

export function getFileHtmlImages(files: IFileSimple[], limit?: number): IFileSimple[] {
    return files.reduce((acc: IFileSimple[], file: IFileSimple) => {
        return isFileHtmlImageExtension(file.extension) && (!limit || acc.length < limit) ? [...acc, file] : acc;
    }, [] as IFileSimple[]);
}
