import React from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "./item.scss";

interface PropsInterface extends WithStyles, RouteComponentProps {
    id?: string;
    title?: string;
    children?: React.ReactNode | React.ReactNodeArray;
    href?: string;
    click?: () => void;
    withDropdown: boolean;
    disabledActionButtons?: boolean;
    actionButtons?: React.ReactElement;
}

interface StateInterface {}

const styles = createStyles({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
        fontWeight: 300,
        color: "rgba(250, 250, 250, 0.4)",
        padding: "4px 6px",
        margin: "0",
        transition: "color .2s, background-color .2s",
        borderRadius: "3px",
        lineHeight: "1",
        "&:hover": {
            textDecoration: "none",
            color: "rgba(250, 250, 250, 0.8)",
        },
    },
});

class Item extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
    }

    render() {
        const { classes, children, title, click, href, id, withDropdown, actionButtons } = this.props;

        if (!withDropdown) {
            if (click) {
                return (
                    <a
                        id={id}
                        className={classnames("breadcrumb-item", classes.root)}
                        onClick={click}
                    >
                        {children}
                        {title}
                    </a>
                );
            }
            if (href) {
                return (
                    <Link
                        id={id}
                        className={classnames("breadcrumb-item", classes.root)}
                        to={href || `${this.props.location.hash ? this.props.location.hash : "#"}`}
                    >
                        {children}
                        {title}
                    </Link>
                );
            }
            return (
                <span id={id} className={classnames("breadcrumb-item", classes.root)}>
                    {children}
                    {title}
                </span>
            );
        }

        if (this.props.disabledActionButtons) {
            return (
                <div id={id} className={classnames("breadcrumb-item", classes.root)}>
                    {children}
                    {title}
                </div>
            );
        }

        return actionButtons ? (
            actionButtons
        ) : (
            <div id={id} className={classnames("breadcrumb-item", classes.root)}>
                {children}
                {title}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Item));
