import * as React from "react";

interface PropsInterface {
    extension: string;
}

interface StateInterface {}

export class FileExtension extends React.Component<PropsInterface, StateInterface> {
    render() {
        return this.props.extension ? this.props.extension.toUpperCase() : "";
    }
}
