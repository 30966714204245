import React from "react";
import {FormattedMessage} from "react-intl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {FilterFormBox} from "../FilterFormBox/FilterFormBox";
import {ProjectStatusEnum} from "../../../services/WedApi/Models/Project";
import {SortEnum} from "../../../services/Filter/SortEnum";
import ProjectsFilterInterface from "../../../services/Filter/Projects/ProjectsFilterInterface";
import {SizeType, WedIcon} from "../../wed-icon/WedIcon";
import {WedIconArrow} from "../../wed-icon/generated/WedIconSvg";
import {WedIconDropDown} from "../../wed-icon/WedIconDropDown";
import {CreatedByEnum} from "../../../services/WedApi/Models/Common";
import {FilterFormBoxSearchText} from "../FilterFormBoxSearchText/FilterFormBoxSearchText";
import {IListProjectPageContextClient} from "../../template/ListProjectPage/ListProjectPageContextClient";
import {IListProjectPageContextUserTeam} from "../../template/ListProjectPage/ListProjectPageContextUserTeam";

interface PropsInterface {
    currentStatus?: string;
    contextClient?: IListProjectPageContextClient;
    contextUserTeam?: IListProjectPageContextUserTeam;
    onFiltersChange: (filters: ProjectsFilterInterface) => void;
    filterClients: { id: number, name: string }[];
}

interface StateInterface {
    status: string;
    createdBy: string;
    orderBy: SortEnum;
}

export class FilterFormProjectList extends React.Component<PropsInterface, StateInterface> {
    private readonly filters: ProjectsFilterInterface;

    constructor(props: PropsInterface) {
        super(props);
        this.changeStatus = this.changeStatus.bind(this);
        this.changeOrder = this.changeOrder.bind(this);
        this.changeCreatedBy = this.changeCreatedBy.bind(this);
        this.changeFilterCustomer = this.changeFilterCustomer.bind(this);
        this.changeFilterSearchText = this.changeFilterSearchText.bind(this);
        this.filters = {status: props.currentStatus || ProjectStatusEnum.active};
        this.state = {
            status: props.currentStatus || ProjectStatusEnum.active,
            createdBy: "",
            orderBy: SortEnum.NAME,
        };
    }

    private changeStatus(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const status: string = event.target.value as string;
        this.setState({status});
        this.changeFilter("status", status);
    }

    private changeOrder(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const orderBy: SortEnum = event.target.value as SortEnum;
        this.setState({orderBy});
        this.changeFilter("order", orderBy);
    }

    private changeCreatedBy(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
        const createdBy: string = event.target.value as string;
        this.setState({createdBy});
        this.changeFilter("createdBy", createdBy);
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    private changeFilterCustomer(event: React.ChangeEvent<{}>, value: { id: number } | null): void {
        this.changeFilter("customer", value?.id || "");
    }

    private changeFilterSearchText(value: string): void {
        this.changeFilter("searchText", value);
    }

    private changeFilter(name: string, value: string | number) {
        this.filters[name] = value;
        this.props.onFiltersChange(this.filters);
    }

    render() {
        return (
            <FilterFormBox className="filter-form-project">
                <FormControl variant="outlined">
                    <FilterFormBoxSearchText onChange={this.changeFilterSearchText}/>
                </FormControl>
                {!this.props.contextClient && !this.props.contextUserTeam && (
                    <FormControl variant="outlined">
                        <Select
                            value={this.state.status}
                            style={{width: 140}}
                            displayEmpty
                            IconComponent={WedIconDropDown}
                            onChange={this.changeStatus}
                        >
                            <MenuItem value="">
                                <FormattedMessage id="app.filterFormProjectList.status.allStatuses"/>
                            </MenuItem>
                            <MenuItem value={ProjectStatusEnum.active}>
                                <FormattedMessage id="app.project.status.active"/>
                            </MenuItem>
                            <MenuItem value={ProjectStatusEnum.closed}>
                                <FormattedMessage id="app.project.status.closed"/>
                            </MenuItem>
                            <MenuItem value={ProjectStatusEnum.archived}>
                                <FormattedMessage id="app.project.status.archived"/>
                            </MenuItem>
                        </Select>
                    </FormControl>
                )}
                <FormControl variant="outlined">
                    <Select
                        value={this.state.createdBy}
                        displayEmpty
                        style={{width: 140}}
                        IconComponent={WedIconDropDown}
                        onChange={this.changeCreatedBy}
                    >
                        <MenuItem value="">
                            <FormattedMessage id="app.filterFormProjectList.createdBy.allProjects"/>
                        </MenuItem>
                        <MenuItem value={CreatedByEnum.me}>
                            <FormattedMessage id="app.filterFormProjectList.createdBy.createdByMe"/>
                        </MenuItem>
                        <MenuItem value={CreatedByEnum.others}>
                            <FormattedMessage id="app.filterFormProjectList.createdBy.allOther"/>
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined">
                    <Select
                        value={this.state.orderBy}
                        style={{width: 140}}
                        displayEmpty
                        IconComponent={WedIconDropDown}
                        onChange={this.changeOrder}
                    >
                        <MenuItem value={SortEnum.NAME}>
                            <FormattedMessage id="app.filterFormProjectList.orderBy.az"/>
                        </MenuItem>
                        <MenuItem value={SortEnum.CREATED}>
                            <FormattedMessage id="app.filterFormProjectList.orderBy.createdAt"/>
                        </MenuItem>
                    </Select>
                </FormControl>
                {!this.props.contextClient && !this.props.contextUserTeam && (
                    <FormControl variant="outlined">
                        <Autocomplete
                            className="filter-form-box__autocomplete"
                            options={this.props.filterClients}
                            onChange={this.changeFilterCustomer}
                            getOptionLabel={(option) => option.name}
                            style={{width: 190}}
                            popupIcon={<WedIcon icon={<WedIconArrow/>} size={SizeType.SMALL}/>}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label={<FormattedMessage id="app.filterFormProjectList.customers.allCustomers"/>}
                                />
                            )}
                        />
                    </FormControl>
                )}
            </FilterFormBox>
        );
    }
}
