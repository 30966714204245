import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { UpdatePaymentMethodApi } from "./Models/Payment";

export default class PaymentActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public async getUpdatePaymentMethodUrl(): Promise<UpdatePaymentMethodApi> {
        const response = await this.postWithCSRFJson("/xapi/bas/customer-portal-session", {});
        return response.data;
    }
}
