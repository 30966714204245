import React, { Component } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { enUS } from "@material-ui/core/locale";
import { createTheme } from "../../services/Theme/theme";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

class ThemeProvider extends Component<PropsInterface> {
    render() {
        const locale = enUS;

        const theme = createTheme(
            {
                theme: "DARK",
            },
            locale
        );

        return <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>;
    }
}

export default ThemeProvider;
