import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ProviderContext, withSnackbar } from "notistack";
import { Dialog } from "@material-ui/core";
import container from "../../../container";
import "./UserMyProfileLogout.scss";

interface PropsInterface extends WrappedComponentProps, ProviderContext, RouteComponentProps {
    close: () => void;
}

interface StateInterface {}

class UserMyProfileLogout extends React.Component<PropsInterface, StateInterface> {
    async componentDidMount() {
        try {
            await container.userService.logout();
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.UserMyProfileLogout.success.message" }), {
                variant: "success",
            });
            this.props.history.push("/");
        } catch (e) {
            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: "app.global.internalError" }), { variant: "error" });
            this.props.close();
        }
    }

    render() {
        return (
            <Dialog
                className="user-my-profile-logout"
                open={true}
                onClose={() => {
                    return undefined;
                }}
            />
        );
    }
}

export default withRouter(withSnackbar(injectIntl(UserMyProfileLogout)));
