import React from "react";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { ProviderContext, withSnackbar } from "notistack";
import { IAccountTeamUser, ITeamUser, ITeamUserProjectRole, IUser } from "../../../services/WedApi/Models/User";
import isEqual from "lodash/isEqual";
import container from "../../../container";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import UserEditTeamMembersPopup from "../UserEditTeamMembersPopup/UserEditTeamMembersPopup";

interface BasePropsInterface extends WrappedComponentProps, ProviderContext {
    clientId: number;
    close: (changed?: boolean) => void;
    userMember?: IAccountTeamUser[];
    clientUserMembers: IUser[];
}

interface PropsInterface extends BasePropsInterface {
    userId?: number;
}

interface StateInterface {
    userMember: IAccountTeamUser[];
}

class UserClientEditTeamMembersPopup extends React.Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            userMember: props.userMember || [],
        };
        this.doIt = this.doIt.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<PropsInterface>) {
        if (!isEqual(this.props.userMember, prevProps.userMember) || !isEqual(this.props.clientUserMembers, prevProps.clientUserMembers)) {
            this.setState({
                userMember: this.props.userMember || [],
            });
        }
    }

    private async doIt(userMember: IAccountTeamUser[]): Promise<void> {
        await container.clientService.setTeamUsersRole(this.props.clientId, userMember);
    }

    render() {
        return (
            <UserEditTeamMembersPopup
                close={this.props.close}
                userMember={this.props.userMember}
                clientUserMembers={this.props.clientUserMembers}
                customerContacts={[]}
                clientCustomerContacts={[]}
                addDefaultTeamRole={ITeamUserProjectRole.ROLE_MEMBER}
                teamType={"PROJECT"}
                doIt={this.doIt as (userMember: Array<ITeamUser | IAccountTeamUser>, customerContacts: IUser[]) => Promise<void>}
                labels={{
                    header: <FormattedMessage id={"app.user.UserClientEditTeamMembersPopup.header.title"} />,
                    addUser: <FormattedMessage id={"app.user.UserClientEditTeamMembersPopup.buttons.addUser"} />,
                }}
            />
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: BasePropsInterface): PropsInterface => {
    return {
        ...props,
        userId: state.user.data?.id,
    };
};

export default withSnackbar(injectIntl(connect(mapStateToProps)(UserClientEditTeamMembersPopup)));
