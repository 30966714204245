import React from "react";
import { isFileHtmlImageExtension } from "../../../services/WedApi/Utils/HtmlImageUrl";
import "./SidebarPreview.scss";
import { FileTypeIcon } from "../../file/FileTypeIcon/FileTypeIcon";
import { SizeType, WedIcon } from "../../wed-icon/WedIcon";
import { WedImage } from "../../wed-image/WedImage";

interface PropsInterface {
    url?: string;
    extension?: string;
}

class SidebarPreview extends React.Component<PropsInterface> {
    render() {
        const { url, extension } = this.props;
        const preview = isFileHtmlImageExtension(extension);

        return (
            <div className="sidebar-preview">
                {preview ? (
                    url && <WedImage src={url} />
                ) : (
                    <div className="sidebar-preview__icon">
                        <WedIcon icon={<FileTypeIcon extension={extension} />} size={SizeType.LARGE} />
                    </div>
                )}
            </div>
        );
    }
}

export default SidebarPreview;
