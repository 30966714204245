import React from "react";
import { ProviderContext, withSnackbar } from "notistack";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import { Button, TextField } from "@material-ui/core";
import PopupHeader, { PositionTitle } from "../../popup/PopupHeader/PopupHeader";
import PopupAction from "../../popup/PopupAction/PopupAction";
import FrontApiErrorCodeEnum from "../../../services/WedApi/Error/FrontApiErrorCodeEnum";
import JSONSchemaValidator, { ValidationErrors } from "../../../services/JSONSchemaValidator/JSONSchemaValidator";
import _size from "lodash/size";
import Popup from "../../popup/Popup/Popup";
import PopupContent from "../../popup/PopupContent/PopupContent";
import { INoteAnnotation } from "../../../services/WedApi/Models/Note";
import "./WedMediaGalleryImageAnnotationPopup.scss";
import { delay } from "../../../services/WedApi/Utils/Delay";
import container from "../../../container";
import { LogicError } from "../../../errors/error-app";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";

interface StateInterface {
    fields: {
        description: string;
    };
    loading: boolean;
}

interface PropsInterface extends WrappedComponentProps, ProviderContext {
    fileType: FileTypeEnum;
    objectId: number;
    versionId: number;
    annotation: INoteAnnotation;
    handleClose: (updated?: boolean) => void;
    collectionId?: number;
    token?: string;
}

const MAX_TRIES = 20;

class WedMediaGalleryImageAnnotationPopup extends React.Component<PropsInterface, StateInterface> {
    schema = {
        type: "object",
        properties: {
            description: {
                type: "string",
                minLength: 1,
                maxLength: 1000,
            },
        },
        required: ["description"],
        additionalProperties: false,
        errorMessage: {
            properties: {
                description: this.props.intl.formatMessage({ id: "app.WedMediaGalleryImageAnnotationPopup.error.description" }),
            },
        },
    };

    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            loading: false,
            fields: {
                description: "",
            },
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({ fields: { ...prevState.fields, [name]: value } }));
    };

    handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { loading } = this.state;

        if (loading) {
            return;
        }

        this.setState({ loading: true });

        const { valid, formErrors } = this.validate();
        if (!valid) {
            const firstKey: string = Object.keys(formErrors)[0];
            this.props.enqueueSnackbar(formErrors[firstKey], { variant: "error" });
            this.setState({ loading: false });
            return;
        }

        try {
            await this.addFileNote();
            this.props.handleClose(true);
        } catch (e) {
            if (e instanceof LogicError) {
                throw e;
            }

            let keyTranslate = "app.error.undefined";
            switch (e.code) {
                case FrontApiErrorCodeEnum.ERR_NOTE_CONTENT_INVALID:
                    keyTranslate = "app.WedMediaGalleryImageAnnotationPopup.error.description";
                    break;
            }

            this.props.enqueueSnackbar(this.props.intl.formatMessage({ id: keyTranslate }), { variant: "error" });
        }

        this.setState({ loading: false });
    };

    private async addFileNote() {
        if (this.props.token) {
            if (this.props.collectionId) {
                await container.shareCollectionService.addCollectionFileNote(
                    this.props.token,
                    this.props.objectId,
                    this.props.versionId,
                    this.props.collectionId,
                    this.state.fields.description,
                    { annotation: this.props.annotation }
                );
            } else {
                throw new LogicError(`Cannot add comment as external user without collection context`);
            }
        } else if ([FileTypeEnum.PROJECT_FILES, FileTypeEnum.MY_LIBRARY_FILES].includes(this.props.fileType)) {
            if (this.props.collectionId) {
                throw new LogicError(`Cannot add private comments with collection id`);
            }
            await container.noteService.addFileNote(this.props.objectId, this.props.versionId, this.state.fields.description, {
                annotation: this.props.annotation,
            });
        } else if ([FileTypeEnum.PROJECT_COLLECTION_FILES, FileTypeEnum.MY_LIBRARY_COLLECTIONS_FILES].includes(this.props.fileType)) {
            if (!this.props.collectionId) {
                throw new LogicError(`Cannot add public comments without collection id`);
            }
            await container.noteService.addCollectionFileNote(
                this.props.objectId,
                this.props.versionId,
                this.props.collectionId,
                this.state.fields.description,
                {
                    annotation: this.props.annotation,
                }
            );
        } else {
            throw new LogicError(`Cannot send component which not declare any type`);
        }
    }

    private validate(): { valid: boolean; formErrors: ValidationErrors } {
        let valid = true;
        const formErrors = this.validateForm();
        if (_size(formErrors)) {
            valid = false;
        }
        return { valid, formErrors };
    }

    private validateForm(): ValidationErrors {
        const { errors } = JSONSchemaValidator.validate({ ...this.state.fields }, this.schema);

        return errors;
    }

    async componentDidMount() {
        for (let i = 0; i < MAX_TRIES; i++) {
            const input = document.getElementById("wed-media-gallery-image-annotation-popup-description");
            if (input) {
                input.focus();
                break;
            }
            await delay(100);
        }
    }

    render() {
        return (
            <Popup
                className="wed-media-gallery-image-annotation-popup"
                open={true}
                handleClose={() => {
                    this.props.handleClose(false);
                }}
                maxWidth="xs"
            >
                <PopupContent>
                    <PopupHeader positionHeader={PositionTitle.CENTER}>
                        <FormattedMessage id={`app.WedMediaGalleryImageAnnotationPopup.title`} />
                    </PopupHeader>
                    <form onSubmit={this.handleSubmit} noValidate>
                        <div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="wed-media-gallery-image-annotation-popup-description"
                                label={<FormattedMessage id={"app.WedMediaGalleryImageAnnotationPopup.description"} />}
                                name="description"
                                autoComplete="off"
                                value={this.state.fields.description}
                                onChange={this.handleChange}
                                multiline
                                rows={5}
                            />
                        </div>
                        <PopupAction>
                            <Button
                                disabled={this.state.loading}
                                type="button"
                                onClick={() => {
                                    this.props.handleClose(false);
                                }}
                            >
                                <FormattedMessage id="app.WedMediaGalleryImageAnnotationPopup.cancel" />
                            </Button>
                            <Button disabled={this.state.loading} type="submit">
                                <FormattedMessage id="app.WedMediaGalleryImageAnnotationPopup.save" />
                            </Button>
                        </PopupAction>
                    </form>
                </PopupContent>
            </Popup>
        );
    }
}

export default withSnackbar(injectIntl(WedMediaGalleryImageAnnotationPopup));
