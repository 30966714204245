import React from "react";
import classNames from "classnames";
import "./SummaryPageBoxContainer.scss";

interface PropsInterface {
    children?: React.ReactNode | React.ReactNodeArray;
    header?: string | React.ReactNode | React.ReactNodeArray;
    className?: string;
}

class SummaryPageBoxContainer extends React.Component<PropsInterface> {
    render() {
        return (
            <div className={classNames("summary-page-box-container", this.props.className)}>
                <div className="summary-page-box-container--body">
                    {this.props.header && <div className="summary-page-box-container--header">{this.props.header}</div>}
                    {this.props.children && <div className="summary-page-box-container--children">{this.props.children}</div>}
                </div>
            </div>
        );
    }
}

export default SummaryPageBoxContainer;
