enum RoutesEnum {
    // anonymous user
    LOGIN = "/login",
    REGISTER = "/register",
    FORGOT_PASSWORD = "/forgot-password",
    ACTIVATE_USER = "/activate",
    RESET_PASSWORD = "/reset",
    COMPANY_DATA = "/company-data",
    COMPANY_REACTIVATE = "/company-reactivate",
    SIGNUP_INVITATION = "/signup_invitation",
    // shared
    SHARE_COLLECTIONS = "/share/collections",
    SHARE_COLLECTION_FILES = "/share/collections/:collectionId(\\d+)",
    SHARE_COLLECTION_FILE_MEDIA_GALLERY_DETAILS = "/share/collections/:collectionId(\\d+)/files/media-gallery/:objectId(\\d+)",
    // logged user
    PROJECTS = "/projects",
    PROJECTS_DETAIL = "/projects/:projectId(\\d+)",
    COLLECTIONS = "/projects/:projectId(\\d+)/collections",
    COLLECTION_FILES = "/projects/:projectId(\\d+)/collections/:collectionId(\\d+)",
    COLLECTION_FILES_MEDIA_GALLERY = "/projects/:projectId(\\d+)/collections/:collectionId(\\d+)/files/media-gallery",
    COLLECTION_FILES_MEDIA_GALLERY_DETAILS = "/projects/:projectId(\\d+)/collections/:collectionId(\\d+)/files/media-gallery/:objectId(\\d+)",
    FILES = "/projects/:projectId(\\d+)/files",
    FILES_MEDIA_GALLERY = "/projects/:projectId(\\d+)/files/media-gallery",
    FILES_MEDIA_GALLERY_DETAILS = "/projects/:projectId(\\d+)/files/media-gallery/:objectId(\\d+)",
    COMMENTS_AND_PROOFING = "/projects/:projectId(\\d+)/comments-proofing",
    SUMMARY = "/projects/:projectId(\\d+)/summary",
    MY_LIBRARY = "/my-library",
    MY_LIBRARY_FILES = "/my-library/files",
    MY_LIBRARY_SHARED_WITH_ME = "/my-library/shared-with-me",
    MY_LIBRARY_COLLECTIONS = "/my-library/collections",
    MY_LIBRARY_COLLECTIONS_FILES = "/my-library/collections/:collectionId(\\d+)",
    MY_LIBRARY_COLLECTIONS_FILES_MEDIA_GALLERY_DETAILS = "/my-library/collections/:collectionId(\\d+)/files/media-gallery/:objectId(\\d+)",
    MY_LIBRARY_MEDIA_GALLERY_DETAILS = "/my-library/files/media-gallery/:objectId(\\d+)",
    MY_LIBRARY_SHARED_WITH_ME_MEDIA_GALLERY_DETAILS = "/my-library/shared-with-me/media-gallery/:objectId(\\d+)",
    CLIENTS = "/customer-accounts",
    CLIENTS_DETAIL = "/customer-accounts/:clientId(\\d+)",
    CLIENTS_SUMMARY = "/customer-accounts/:clientId(\\d+)/summary",
    CLIENTS_PROJECTS_ACTIVE = "/customer-accounts/:clientId(\\d+)/projects/active",
    CLIENTS_PROJECTS_CLOSED = "/customer-accounts/:clientId(\\d+)/projects/closed",
    CLIENTS_CONTACTS = "/customer-accounts/:clientId(\\d+)/contacts",
    CLIENTS_CONTACTS_DETAIL = "/customer-accounts/:clientId(\\d+)/contacts/:contactId(\\d+)",
    USER_TEAM = "/team",
    USER_TEAM_DETAILS = "/team/:teamId(\\d+)",
    USER_TEAM_DETAILS_USER_PROFILE = "/team/:teamId(\\d+)/user-profile",
    USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PROJECT_WORK = "/team/:teamId(\\d+)/user-profile/project-work/media-gallery/:objectId(\\d+)",
    USER_TEAM_DETAILS_USER_PROFILE_MEDIA_GALLERY_PRIVATE_PORTFOLIO = "/team/:teamId(\\d+)/user-profile/private-portfolio/media-gallery/:objectId(\\d+)",
    USER_TEAM_DETAILS_CLIENTS = "/team/:teamId(\\d+)/customer-accounts",
    USER_TEAM_DETAILS_PROJECTS_ACTIVE = "/team/:teamId(\\d+)/projects/active",
    USER_TEAM_DETAILS_PROJECTS_CLOSED = "/team/:teamId(\\d+)/projects/closed",
    ADMIN_PANEL = "/admin-panel",
    ADMIN_PANEL_MANAGE_USERS = "/admin-panel/manage-users",
    ADMIN_PANEL_MANAGE_USERS_CURRENT_USERS = "/admin-panel/manage-users/current-users",
    ADMIN_PANEL_MANAGE_USERS_INVITE_NEW_USERS = "/admin-panel/manage-users/invite-new-users",
    ADMIN_PANEL_MANAGE_USERS_PENDING_INVITATIONS = "/admin-panel/manage-users/pending-invitations",
    ADMIN_PANEL_COMPANY_DATA = "/admin-panel/company-data",
    ADMIN_PANEL_BILLING = "/admin-panel/billing",
    ADMIN_PANEL_BILLING_CURRENT_PERIOD = "/admin-panel/billing/current-period",
    ADMIN_PANEL_BILLING_HISTORY = "/admin-panel/billing/history",
    ADMIN_PANEL_BILLING_PAYMENT_METHOD = "/admin-panel/billing/payment-method",
    ADMIN_PANEL_SUBSCRIPTION = "/admin-panel/subscription",
    // errors page
    ERROR_INTERNAL = "/_error",
    ERROR_PAGE_NOT_FOUND = "/_error/page-not-found",
}

export default RoutesEnum;
