import { AxiosError } from "axios";
import FrontApiErrorDto from "../Error/FrontApiErrorDto";
import { History } from "history";
import { noticeLogger } from "../Utils/NoticeLogger";
import { redirectErrorPage } from "../Utils/RedirectErrorTransform";

const frontApiResponseErrorTransformer = (history: History) => {
    return async (e: AxiosError) => {
        await redirectErrorPage(history, e);

        const error = FrontApiErrorDto.httpErrorToFrontApiError(e);
        noticeLogger("HANDLE ERROR - httpErrorToFrontApiError");
        throw error;
    };
};

export default frontApiResponseErrorTransformer;
