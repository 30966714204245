import axios from "axios";
import { History } from "history";
import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import { IApiBaseFile, IApiDirectory } from "./Models/File";
import { SizeImageEnum } from "./Services/DataParameters/SizeImageEnum";
import _ from "lodash";
import { FileTypeEnum } from "../../components/template/Type/FileTypeEnum";
import IManagerFolderAccessParameters from "./Services/DataParameters/IManagerFolderAccessParameters";
import { LogicError } from "../../errors/error-app";

export default class FilesActionApiService extends AbstractRestApi<AbstractApiConstructorInterface> {
    protected history: History;

    constructor(options: AbstractApiConstructorInterface, history: History) {
        super(options);
        this.history = history;
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(this.history), frontApiRequestTransformer());
    }

    public getFileUrl(id: number, vid: number, options?: { size?: SizeImageEnum; fileType?: FileTypeEnum; uid?: number }): string {
        if (options?.fileType === FileTypeEnum.TEAM_USER_PRIVATE_PORTFOLIO) {
            if (!options.uid) {
                throw new LogicError(`Private portfolio file type required user id`);
            }
            const queryStr = `oid=${id}&vid=${vid}&uid=${options.uid}` + (options?.size ? `&size=${options.size}` : "");
            return this.getUrl(`/xapi/user/portfolio/cast?${queryStr}`);
        }
        const queryStr = `id=${id}&vid=${vid}` + (options?.size ? `&size=${options.size}` : "");
        return this.getUrl(`/xapi/obj/cast?${queryStr}`);
    }

    public getMyLibraryCollectionFileUrl(id: number, vid: number, collectionId: number, options?: { size?: SizeImageEnum }): string {
        const queryStr = `cid=${collectionId}&oid=${id}&vid=${vid}` + (options?.size ? `&size=${options.size}` : "");
        return this.getUrl(`/xapi/library/col/obj/cast?${queryStr}`);
    }

    public getPrivatePortfolioFileUrl(id: number, vid: number, uid: number, options?: { size?: SizeImageEnum; fileType?: FileTypeEnum }): string {
        const queryStr = `oid=${id}&vid=${vid}&uid=${uid}` + (options?.size ? `&size=${options.size}` : "");
        return this.getUrl(`/xapi/user/portfolio/cast?${queryStr}`);
    }

    public getSharedFileUrl(token: string, oid: number, vid: number, cid: number, size?: SizeImageEnum): string {
        const searchParams = new URLSearchParams({
            token: token,
            oid: oid.toString(),
            vid: vid.toString(),
            cid: cid.toString(),
        });

        if (size) {
            searchParams.append("size", size);
        }

        return this.getUrl(`/xapi/share/cast?${searchParams.toString()}`);
    }

    public async getNoteFileByIds(objectIds: number[]): Promise<IApiBaseFile[]> {
        if (objectIds.length === 0) {
            return [];
        }

        const response = await this.get(`/xapi/obj/get?oid=${objectIds.join(",")}`);

        return response.data.items;
    }

    public getDirectoryDownloadUrl(id: number): string {
        return this.getUrl(`/xapi/lib/${id}/download`);
    }

    public async createFolder(parentDirId: number, name: string, description: string | undefined): Promise<IApiDirectory> {
        const response = await this.postWithCSRFJson("/xapi/lib/folder/create", {
            parentID: parentDirId,
            name: name,
            desc: description,
        });
        return response.data;
    }

    public async renameFile(id: number, name: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/obj/rename", { id, name });
    }

    public async renameFolder(id: number, name: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/lib/folder/rename", { id, name });
    }

    public async managerFolderAccess(parameters: IManagerFolderAccessParameters): Promise<void> {
        await this.postWithCSRFJson("/xapi/lib/folder/ams", parameters);
    }

    public async changeFileDescription(id: number, desc: string, asPublic: boolean): Promise<void> {
        await this.postWithCSRFJson("/xapi/obj/desc", { id, desc, public: asPublic });
    }

    public async changeFolderDescription(id: number, desc: string): Promise<void> {
        await this.postWithCSRFJson("/xapi/lib/folder/desc", { id, desc });
    }

    public async actionFile(ids: number[], action: "trash" | "restore" | "delete"): Promise<void> {
        await this.postWithCSRFJson(`/xapi/obj/${action}`, { ids });
    }

    public async actionFolder(ids: number[], action: "trash" | "restore" | "delete"): Promise<void> {
        await this.postWithCSRFJson(`/xapi/lib/folder/${action}`, { ids });
    }

    public async moveFolder(ids: number[], moveToID: number): Promise<void> {
        await this.postWithCSRFJson("/xapi/lib/folder/move", { ids, moveToID });
    }

    public async moveFile(ids: number[], moveToID: number): Promise<void> {
        await this.postWithCSRFJson("/xapi/obj/move", { ids, moveToID });
    }

    public async removeFileVersion(objectId: number, versionId: number): Promise<void> {
        await this.postWithCSRFJson("/xapi/obj/deleteversion", { oid: objectId, vid: versionId });
    }

    public async updateObjectTags(objectId: number, tags: string[]): Promise<void> {
        await this.postWithCSRFJson("/xapi/obj/tags", { id: objectId, tags: tags });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async uploadFile(formData: FormData, config: { onProgressHandle: (uploadedBytes: number) => void }): Promise<void> {
        const response = await this.get("/xapi/csrf", _.merge({ withCredentials: true }));
        const CSRFToken = response.headers["x-csrf-token"];

        if (!CSRFToken) {
            throw Error("Header x-csrf-token is missing.");
        }

        return new Promise((resolve, reject) => {
            axios
                .post(this.getUrl("/xapi/obj/upload"), formData, {
                    // eslint-disable-next-line
                    onUploadProgress: function (progressEvent: any) {
                        const maxPercentage = 95;
                        const maxLoadedBytes = Math.round(progressEvent.total * (maxPercentage / 100));
                        if (progressEvent.loaded < maxLoadedBytes) {
                            config.onProgressHandle(progressEvent.loaded);
                        }
                    },
                    timeout: 30 * 60 * 1000,
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": CSRFToken,
                        "content-type": "multipart/form-data",
                    },
                })
                .then(() => {
                    resolve(undefined);
                })
                .catch(reject);
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async uploadFileVersion(formData: FormData, config: { onProgressHandle: (uploadedBytes: number) => void }): Promise<void> {
        const response = await this.get("/xapi/csrf", _.merge({ withCredentials: true }));
        const CSRFToken = response.headers["x-csrf-token"];

        if (!CSRFToken) {
            throw Error("Header x-csrf-token is missing.");
        }

        return new Promise((resolve, reject) => {
            axios
                .post(this.getUrl("/xapi/obj/addversion"), formData, {
                    // eslint-disable-next-line
                    onUploadProgress: function (progressEvent: any) {
                        const maxPercentage = 95;
                        const maxLoadedBytes = Math.round(progressEvent.total * (maxPercentage / 100));
                        if (progressEvent.loaded < maxLoadedBytes) {
                            config.onProgressHandle(progressEvent.loaded);
                        }
                    },
                    timeout: 30 * 60 * 1000,
                    withCredentials: true,
                    headers: {
                        "x-csrf-token": CSRFToken,
                        "content-type": "multipart/form-data",
                    },
                })
                .then(() => {
                    resolve(undefined);
                })
                .catch(reject);
        });
    }
}
