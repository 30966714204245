import { IApiProjectDetail, IProject, ProjectStatusEnum } from "../Models/Project";
import ClientActionApiService from "../ClientActionApiService";
import { IClientContact, IClientContactExtended } from "../Models/ClientContact";
import { convertIApiUserClientToIClientContact } from "./BaseConverter";
import uniq from "lodash/uniq";
import UserActionApiService from "../UserActionApiService";
import { IOwner, IUser } from "../Models/User";
import ProjectActionApiService from "../ProjectActionApiService";
import { ProjectService } from "./ProjectService";
import { IApiClient, IApiUserClient } from "../Models/Client";

export interface IProjectList {
    projects: IProject[];
}

export type ICreateUpdateClientCustomerContact = Omit<
    IClientContact,
    "id" | "countryCode" | "name" | "initials" | "numOfClosedProjects" | "numOfActiveProjects"
> & { countryPhoneCode?: number };

export class ClientContactService {
    constructor(
        private readonly clientActionApiService: ClientActionApiService,
        private readonly userActionApiService: UserActionApiService,
        private readonly projectActionApiService: ProjectActionApiService
    ) {}

    public async getList(clientId: number, options?: { withCountProjects?: boolean }): Promise<IClientContact[]> {
        const apiClientContacts = await this.clientActionApiService.getUsersClientByIdClient(clientId);

        const userIds: number[] = [];
        const projectIds: number[] = [];
        apiClientContacts.items.forEach((item) => {
            if (item.createdBy) {
                userIds.push(item.createdBy);
            }
            if (item.projectIDs && options?.withCountProjects) {
                projectIds.push(...item.projectIDs);
            }
        });
        const users: IOwner[] = userIds.length > 0 ? (await this.userActionApiService.getByIds(uniq(userIds))).items : [];
        const projects: IApiProjectDetail[] = options?.withCountProjects
            ? await Promise.all(uniq(projectIds).map((projectId) => this.projectActionApiService.getProject(projectId)))
            : [];

        return apiClientContacts.items.map((item) => {
            return convertIApiUserClientToIClientContact(item, users, projects);
        });
    }

    public async getClientContactDetails(
        clientId: number,
        contactId: number,
        options?: { withoutProjects?: boolean; withoutCreatedBy?: boolean }
    ): Promise<IClientContactExtended> {
        const clientContacts = await this.clientActionApiService.getClientCustomerContact(clientId, contactId);
        const data = await this.getClientContactDetailsGetDependencies(clientContacts, options);

        const projects = data.projects.map((apiProject) => {
            return ProjectService.convertProjectApi(apiProject.Pr, data.clients, data.users);
        });

        const item = convertIApiUserClientToIClientContact(clientContacts, data.users, data.projects);
        return {
            ...item,
            activeProjects: projects.filter((project) => project.status === ProjectStatusEnum.active),
            closedProjects: projects.filter((project) => project.status === ProjectStatusEnum.closed),
        };
    }

    public async createClientCustomerContact(ecOrgID: number, data: ICreateUpdateClientCustomerContact): Promise<void> {
        await this.clientActionApiService.createClientCustomerContact({
            ecOrgID: ecOrgID,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            position: data.position,
            ccode: data.countryPhoneCode,
            phone: data.phone,
        });
    }

    public async updateClientCustomerContact(id: number, data: ICreateUpdateClientCustomerContact): Promise<void> {
        await this.clientActionApiService.updateClientCustomerContact({
            id: id,
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            position: data.position,
            ccode: data.countryPhoneCode,
            phone: data.phone,
        });
    }

    public async removeClientCustomerContact(id: number): Promise<void> {
        return this.clientActionApiService.removeClientCustomerContact(id);
    }

    private async getClientContactDetailsGetDependencies(
        clientContacts: IApiUserClient,
        options?: { withoutProjects?: boolean; withoutCreatedBy?: boolean }
    ): Promise<{
        projects: IApiProjectDetail[];
        users: IUser[];
        clients: IApiClient[];
    }> {
        const result = {
            projects: [] as IApiProjectDetail[],
            users: [] as IUser[],
            clients: [] as IApiClient[],
        };

        const userIds: number[] = [];

        if (!options?.withoutProjects) {
            const [apiProjects, clients] = await Promise.all([
                Promise.all(clientContacts.projectIDs.map((projectId) => this.projectActionApiService.getProject(projectId))),
                this.clientActionApiService.getClients(),
            ]);

            apiProjects.forEach((project) => {
                if (project.Pr.updatedBy) {
                    userIds.push(project.Pr.updatedBy);
                }
                if (project.Pr.createdBy) {
                    userIds.push(project.Pr.createdBy);
                }
            });

            result.projects = apiProjects;
            result.clients = clients.items;
        }
        if (!options?.withoutCreatedBy && clientContacts.createdBy) {
            userIds.push(clientContacts.createdBy);
        }
        if (userIds.length > 0) {
            const userResult = await this.userActionApiService.getByIds(
                uniq(([] as number[]).concat(userIds, clientContacts.createdBy ? [clientContacts.createdBy] : []))
            );
            result.users = userResult.items;
        }

        return result;
    }
}
