import { generatePath } from "react-router-dom";
import { parse } from "query-string";
import container from "../../../../../../container";
import DataFilesInterface from "../DataFilesInterface";
import { BreadcrumbItem } from "../../../../../breadcrumbs/Breadcrumbs";
import { PropsInterface } from "../../../FilesPage";
import FetchDataReturnInterface from "./FetchDataReturnInterface";
import OpenFileParamsInterface from "./ParamsInterface/OpenFileParamsInterface";
import OpenSectionParamsInterface from "./ParamsInterface/OpenSectionParamsInterface";
import { FileTypeEnum } from "../../../../Type/FileTypeEnum";
import { LogicError } from "../../../../../../errors/error-app";
import RoutesEnum from "../../../../../../services/Routes/RoutesEnum";

export default class SharedCollectionFilesData implements DataFilesInterface {
    async fetch(props: PropsInterface): Promise<FetchDataReturnInterface> {
        if (!props.collectionId) {
            throw new LogicError(`CollectionId is required for type: ${FileTypeEnum.SHARED_COLLECTION_FILES}`);
        }

        const { token } = parse(props.location.search);
        const collection = await container.shareCollectionService.getCollectionDetails(token as string, props.collectionId);

        return {
            isLoading: false,
            directories: [],
            files: collection.files,
            collection: undefined,
            dir: undefined,
        };
    }

    getFolder(): Promise<FetchDataReturnInterface> {
        throw new LogicError(`Not supported method for type: ${FileTypeEnum.PROJECT_COLLECTION_FILES}`);
    }

    openFile(params: OpenFileParamsInterface): string {
        const { collectionId, objectId, token } = params;

        if (!(collectionId && objectId && token)) {
            throw new LogicError(`incomplete params for open file in type: ${FileTypeEnum.SHARED_COLLECTION_FILES}`);
        }

        return `${generatePath(RoutesEnum.SHARE_COLLECTION_FILE_MEDIA_GALLERY_DETAILS, {
            collectionId,
            objectId,
        })}?token=${token}`;
    }

    openSection(params: OpenSectionParamsInterface): string {
        const { collectionId, token } = params;

        if (!(collectionId && token)) {
            throw new LogicError(`incomplete params for open section in type: ${FileTypeEnum.SHARED_COLLECTION_FILES}`);
        }

        return `${generatePath(RoutesEnum.SHARE_COLLECTION_FILES, {
            collectionId,
        })}?token=${token}`;
    }

    breadcrumbs(): BreadcrumbItem[] {
        return [];
    }

    didUpdate(prevProps: PropsInterface, currentProps: PropsInterface): boolean {
        return !!prevProps.userTeam?.id && !!currentProps.userTeam?.id && prevProps.userTeam?.id !== currentProps.userTeam?.id;
    }

    getRootDirName(): string {
        return "";
    }

    showTrash(): boolean {
        return false;
    }
}
