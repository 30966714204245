import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import NotLoggedPopupPage from "../../../components/template/NotLoggedPopupPage/NotLoggedPopupPage";
import UserCompanyDataForm from "../../../components/user/UserCompanyDataForm/UserCompanyDataForm";
import container from "../../../container";
import {IApiOrganizationCompanyData} from "../../../services/WedApi/Models/User";

interface PropsInterface extends RouteComponentProps {}

interface StateInterface {
    companyData?: IApiOrganizationCompanyData;
}

class CompanyReactivatePage extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            companyData: undefined
        };
    }

    async componentDidMount() {
        this.setState({
            companyData: await container.adminService.getApiCompanyData()
        });
    }

    render() {
        return (
            <NotLoggedPopupPage
                maxWidth="lg"
                className="company-reactivate-page"
                header={<FormattedMessage id={"app.UserBaseCompanyDataForm.headerReactivate"} />}
                withLogo={true}
            >
                {this.state.companyData && <UserCompanyDataForm reactivateData={this.state.companyData} />}
            </NotLoggedPopupPage>
        );
    }
}

export default withRouter(CompanyReactivatePage);
