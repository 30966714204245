import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent, { MarginSizeType } from "../../popup/PopupContent/PopupContent";
import { IProject } from "../../../services/WedApi/Models/Project";
import { FileTypeEnum } from "../../template/Type/FileTypeEnum";
import Trash from "../Trash/Trash";

interface PropsInterface {
    project?: IProject;
    open: boolean;
    handleClose: () => void;
    fileType: FileTypeEnum;
}

interface StateInterface {}

export class TrashPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup open={this.props.open} handleClose={this.props.handleClose}>
                <PopupContent marginSize={MarginSizeType.SMALL}>
                    <Trash project={this.props.project} fileType={this.props.fileType} />
                </PopupContent>
            </Popup>
        );
    }
}
