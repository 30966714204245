import React from "react";
import Popup from "../../popup/Popup/Popup";
import PopupContent, { MarginSizeType } from "../../popup/PopupContent/PopupContent";
import SearchResult from "../SearchResult/SearchResult";
import "./SearchResultPopup.scss";

interface PropsInterface {
    query: string;
    handleClose: (linkClicked?: boolean) => void;
}

interface StateInterface {}

export class SearchResultPopup extends React.Component<PropsInterface, StateInterface> {
    render() {
        return (
            <Popup
                open={true}
                handleClose={() => {
                    this.props.handleClose();
                }}
                maxWidth={"xl"}
                className="search-result-popup"
            >
                <PopupContent marginSize={MarginSizeType.SMALL} withBackground={true}>
                    <SearchResult query={this.props.query} handleClose={this.props.handleClose} />
                </PopupContent>
            </Popup>
        );
    }
}
