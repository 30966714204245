import React, { MouseEvent } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { createStyles, Divider, LinearProgress, WithStyles, withStyles } from "@material-ui/core";
import "./WedDropDownMenuItem.scss";

export interface IMenuItem {
    icon?: string | Element | React.ReactElement;
    label: string;
    disabled?: boolean;
    click?: (event: MouseEvent) => void;
    divider?: boolean;
    loading?: boolean;
    level?: number;
    disabledLabel?: boolean;
    keyPrefix?: string;
}

interface PropsInterface extends WithStyles {
    item: IMenuItem;
    noTranslate?: boolean;
    onClick?: () => void;
}

interface StateInterface {}

const styles = createStyles({
    root: {
        "&:hover": {
            backgroundColor: "#2f434f",
            color: "#fafafa",
        },
    },
    divider: { margin: "5px 0" },
});

class WedDropDownMenuItem extends React.Component<PropsInterface, StateInterface> {
    render() {
        const { item, noTranslate, classes, onClick } = this.props;

        return (
            <>
                <MenuItem
                    onClickCapture={onClick}
                    disabled={item.disabled}
                    onClick={item.click}
                    className={classes.root}
                    style={{ paddingLeft: `${(item.level || 0) * 10 + 16}px` }}
                >
                    <ListItemIcon>{item.icon ? item.icon : ""}</ListItemIcon>
                    <span
                        className={classnames("wed-drop-down-menu-item__label", { "wed-drop-down-menu-item__label--disabled": item.disabledLabel })}
                    >
                        {noTranslate ? item.label : <FormattedMessage id={item.label} />}
                    </span>
                </MenuItem>
                {item.loading && <LinearProgress />}
                {!!item.divider && <Divider className={classes.divider} />}
            </>
        );
    }
}

export default withStyles(styles)(WedDropDownMenuItem);
