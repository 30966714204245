import React from "react";
import { connect } from "react-redux";
import { Table, TableContainer } from "@material-ui/core";
import "./WedTable.scss";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

export class WedTable extends React.Component<PropsInterface> {
    render() {
        return (
            <TableContainer>
                <Table
                    className="wed-table"
                    aria-labelledby="tableTitle"
                    size="medium"
                    // aria-label="enhanced table"
                >
                    {this.props.children}
                </Table>
            </TableContainer>
        );
    }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(WedTable);
