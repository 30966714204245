export interface InitialsAndOwnerInterface {
    initials: string;
    owner: string;
}

interface InputParams {
    firstName?: string;
    lastName?: string;

    // eslint-disable-next-line
    [key: string]: any;
}

export function getInitialsByName(name: string): InitialsAndOwnerInterface | undefined {
    const parts: string[] = name.split(" ");
    if (parts.length > 1) {
        return getInitialsAndOwner({
            firstName: parts[0],
            lastName: parts.slice(1).join(" "),
        });
    }
    return undefined;
}

export function getInitialsAndOwner(user?: InputParams): InitialsAndOwnerInterface {
    return {
        initials: [user?.firstName ? user.firstName.substr(0, 1) : "", user?.lastName ? user.lastName.substr(0, 1) : ""].join("").toUpperCase(),
        owner: `${user?.firstName} ${user?.lastName}`,
    };
}
